import { bindable, inject } from 'aurelia-framework';
import Chart from 'chart.js';

Chart.defaults.global.responsive = true;
Chart.defaults.global.defaultFontFamily = 'Raleway,sans-serif';

@inject(Element)
export class DoughnutChart {
    @bindable header;
    @bindable footnote;
    @bindable chartData;
    @bindable linkUrl;

    constructor(element) {
        this.element = element;
    }

    chartDataChanged() {
        this.applyChartData();
    }

    attached() {
        this.chartCanvas = this.element.querySelector('canvas');
        this.doughnutChart = new Chart(this.chartCanvas.getContext('2d'), {
            type: 'doughnut',
            options: {
                animation: {
                    duration: 2000
                },
                cutoutPercentage: 75,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false,
                }
            }
        });

        this.applyChartData();
    }

    applyChartData() {
        if (!this.chartData || !this.doughnutChart)
            return;

        var data = this.chartData.datapoints && this.chartData.datapoints.map(dp => dp.value);
        var noData = !!this.chartData.errorMessage || !data.some(value => value);

        this.doughnutChart.data = {
            labels: noData ? [] : this.chartData.datapoints.map(dp => dp.label),
            datasets: [{
                backgroundColor: noData ? [] : this.chartData.datapoints.map(dp => dp.backgroundColor),
                borderWidth: 1,
                borderColor: '#543c44',
                data: noData ? [] : data
            }] 
        };

        this.doughnutChart.update();
    }

    detached() {
        if (this.doughnutChart)
            this.doughnutChart.destroy();
    }
};
