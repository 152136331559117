import DataGridSelectAllItem from 'infrastructure/components/data-grid-select-all-item';
import Organization from 'organizations/organization';
import { ExportTaskCollectionTemplateConfiguration } from './export-task-collection-template-configuration';

export default class UiViewExportTaskCollectionTemplate implements DataGridSelectAllItem {
    private _id: number;
    private _displayName: string;
    private _isActive;
    private _organizationConfigurations: ExportTaskCollectionTemplateConfiguration[];
    private _organizations: Organization[];

    isSelected: boolean;

    constructor(
        id: number,
        organizationConfigurations: ExportTaskCollectionTemplateConfiguration[],
        isActive: boolean,
        organizations: Organization[]) {
        if(organizationConfigurations.length === 0){
            throw new Error('There must be at least one organizationConfiguration for each UiViewExportTaskCollectionTemplate.');
        }
        if(organizations.length === 0) {
            throw new Error('There must be at least one organization for each UiViewExportTaskCollectionTemplate.');
        }
        this._id = id;
        this._organizationConfigurations = organizationConfigurations;
        this._isActive = isActive;
        this._organizations = organizations;
    }

    public get id() {
        return this._id;
    }

    public get displayName() {
        const join = (arr, sep=', ') => 
            arr.reduce((acc, item, i) => `${acc}${i ? sep :''}${this._organizations.find(o => o.id === item.organizationId).name} (${item.templateName})`, ''); 
        this._displayName = join(this._organizationConfigurations);
        
        return this._displayName;
    }

    public get isActive() {
        return this._isActive;
    }

    public set isActive(isActive: boolean) {
        this._isActive = isActive;
    }
}
