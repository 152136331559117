import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import RoleService from './role-service';
import OrganizationService from '../organizations/organization-service';
import SecurityService from '../security/security-service';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import Logger from '../infrastructure/logger';
import PageContext from '../infrastructure/page-context';
import OrganizationUtility from '../infrastructure/organization-utility';

@inject(Router, Logger, PageContext, DialogPresenter, RoleService, OrganizationService, SecurityService)
export default class RoleList {

    constructor(router, logger, pageContext, dialogPresenter, roleService, organizationService, securityService) {
        this.router = router;

        this.logger = logger;
        this.logger.name = 'role-list';

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.roleService = roleService;
        this.organizationService = organizationService;
        this.securityService = securityService;
        this.organizations = [];
        this.organizationsView = [];
        this.roles = [];
        this.searchFilter = '';
        this.sortDirection = 'asc';
        this.showPermissions = false;
        this.canViewRoles = this.securityService.hasPermission('ViewRoles');
        this.canEditRoles = this.securityService.hasPermission('EditRoles') && !this.securityService.isImpersonating();
    }

    activate() {
        (async () => {

            this.pageContext.isLoading = true;
            try {
                const isWorkingUnderOrganization = this.securityService.isWorkingUnderOrganization();

                this.roles = await this.roleService.getRoles();
                this.organizations = OrganizationUtility.flattenOrganization(isWorkingUnderOrganization ? 
                    await this.organizationService.getImpersonatingOrganizations() : await this.organizationService.getOrganizations());
                this.organizationsView = JSON.parse(JSON.stringify(this.organizations));
                this.updateView();

                this.pageContext.isLoading = false;

            } catch (error) {
                this.logger.error('Error loading roles.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Roles',
                    'An error occurred while loading roles. Please try again later.');
            }

        })();

    }

    updateView() {
        for (var i = 0; i < this.organizationsView.length; i++) {
            var organizationId = this.organizationsView[i].id;
            this.organizationsView[i].roles = this.roles.filter(r => r.organization.id === organizationId);
        }
    }

    createRole(org) {
        this.router.navigateToRoute('role-detail', { id: 'create', orgId: org.id, orgName: org.name });
    }

    editRole(id) {
        this.router.navigateToRoute('role-detail', { id: id });
    }

    deleteRole(roleId) {
        this.dialogPresenter
            .showConfirmation('Delete Role', 'Deleting a role cannot be undone. Are you sure?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                this.roleService
                    .deleteRole(roleId)
                    .then(() => {
                        for (var i = 0, iLen = this.roles.length; i < iLen; i++) {
                            if (this.roles[i].id === roleId) {
                                this.roles = this.roles.filter(role => role.id !== roleId);
                                this.updateView();
                                break;
                            }
                        }
                        this.pageContext.showSuccessOverlay('Role deleted successfully.');
                    })
                    .catch(error => {
                        if (error.apiErrorCode === 400) {
                            this.dialogPresenter
                                .showConfirmation(
                                    'Additional action required',
                                    'Deleting this role requires reassigning users to a new role. Continue to reassign users now.')
                                .then(confirmed => {
                                    if (confirmed)
                                        this.router.navigateToRoute('role-move-users', { id: roleId });
                                });
                        } else {
                            this.logger.error('Error deleting role.', error, { roleId });
                            this.dialogPresenter.showAlert(
                                'Error Deleting Role',
                                'An error occurred while deleting the role. Please try again later.');
                        }
                    });
            });
    }

    toggleSort() {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    }
}
