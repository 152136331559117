import { ManageTestMethodDataRow } from 'location-testing/test-method-management/manage-test-method-data-row';
import TestMethod from './test-method';

const testMethodNameFormatting = (
    testMethod: TestMethod,
    isImpersonatingUser: boolean,
    isImpersonatingOrg: boolean,
): string => {
    return isImpersonatingUser || isImpersonatingOrg
        ? `${testMethod.billCode} - ${testMethod.name} - ${testMethod.externalId}`
        : `${testMethod.billCode} - ${testMethod.name}`;
};

const testMethodManagementNameFormatting = (
    testMethod: ManageTestMethodDataRow,
    isImpersonatingUser: boolean,
    isImpersonatingOrg: boolean,
): string => {
    return isImpersonatingUser || isImpersonatingOrg
        ? `${testMethod.billCode} - ${testMethod.testMethodName} - ${testMethod.externalId}`
        : `${testMethod.billCode} - ${testMethod.testMethodName}`;
};

// This method evaluates each object is a TestMethods, but are treated like plain js object
// Used for the pop up on plan management
const testMethodPopUpManagementNameFormatting = (
    nonDefinedTestMethod: any,
    isImpersonatingUser: boolean,
    isImpersonatingOrg: boolean,
): string => {
    return isImpersonatingUser || isImpersonatingOrg
        ? `${nonDefinedTestMethod.billCode} - ${nonDefinedTestMethod.name} - ${nonDefinedTestMethod.externalId}`
        : `${nonDefinedTestMethod.billCode} - ${nonDefinedTestMethod.name}`;
};

const isImpersonatingUserOrOrg = (
    isImpersonatingUser: boolean,
    isImpersonatingOrg: boolean,
): boolean => {
    return isImpersonatingUser || isImpersonatingOrg ? true : false;
};

export {
    testMethodNameFormatting,
    isImpersonatingUserOrOrg,
    testMethodManagementNameFormatting,
    testMethodPopUpManagementNameFormatting,
};
