function isNullOrWhitespace(input) {
    if (typeof input === 'undefined' || input == null)
        return true;

    return String(input).replace(/\s/g, '').length < 1;
}

export class FilterValueConverter {
    toView(array, field, search, match = true) {
        if (isNullOrWhitespace(search) || isNullOrWhitespace(field))
            return array;

        var result = [];

        if (match)
            result = array.filter(p => String(p[field]).toLowerCase().indexOf(String(search).toLowerCase()) !== -1);
        else
            result = array.filter(p => !String(p[field]).toLowerCase().indexOf(String(search).toLowerCase()) !== -1);

        return result;
    }
}
