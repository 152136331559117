export class NumberValueConverter {
    fromView(value) {
        if (value === '' || value === null || value === undefined)
            return null;

        if (Number.isNaN(value))
            return null;

        if (typeof value === 'number')
            return value;

        if (typeof value === 'string') {
            var numberValue = Number(value.trim());
            if (Number.isNaN(numberValue))
                return null;

            return numberValue;
        }

        return value;
    }
}
