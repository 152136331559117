import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class LabService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getLabs(searchText) {
        return this.httpClient.get('labs/');
    }

    getAdminLabs() {
        return this.httpClient.get('labs/admin/labs/');
    }

    getLab(id) {
        return this.httpClient.get(`labs/${id}`);
    }

    saveLab(lab) {
        return this.httpClient.post('labs/', lab);
    }
}
