import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import SelectorOption from 'infrastructure/selector-option';
import PointRoomService from '../../point-rooms/point-room-service';

@autoinject
export class PointRoomSelectorCustomElement {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) room: string;
    @bindable organizationId: number;
    roomTypeOptions: SelectorOption[];

    constructor(private readonly pointRoomService: PointRoomService) {}

    private async getOrganizationPointTypes() {
        const organizationPointTypes = await this.pointRoomService.getOrganizationPointRooms(
            this.organizationId,
        );
        this.roomTypeOptions = organizationPointTypes.map((pt: any) => {
            return {
                title: pt,
                value: pt,
            } as SelectorOption;
        });
    }

    async organizationIdChanged() {
        await this.getOrganizationPointTypes();
    }

    async bind() {
        await this.getOrganizationPointTypes();
    }
}
