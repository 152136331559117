import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import {
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
} from 'aurelia-validation';
import ExcelCellEditor from 'infrastructure/components/excel-cell-editor.js';
import ExcelCellRenderer from 'infrastructure/components/excel-cell-renderer.js';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import { trim } from 'jquery';
import moment from 'moment';
import SegmentationTemplate from 'segmentation-templates/segmentation-template';
import SegmentationTemplateSegment from '../segmentation-templates/segmentation-template-segment';
import Sample from './request-detail-sample-info-validation';

@autoinject
export class RequestDetailSampleInfo {
    @bindable samples: any[];
    @bindable organizationSampleTemplates;
    @bindable createMode: boolean;
    @bindable segmentationTemplates: SegmentationTemplate[];
    @bindable enableCompositing: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) orderedSamples: Sample;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) formChanged: boolean;
    @bindable isLabWare: boolean;

    validationController: ValidationController;

    gridOptions: any;
    samplesGridFullHeightViewModel: any;

    allSelected: boolean;
    selectedSamples: any[];
    compositeOptions: any[];
    enableRemoveButton: boolean;
    enableCloneButton: boolean;
    enableCopySelectionButton: boolean;
    agGridSuppressRangeSelection = 'ag-cell-disable-selection';

    sampleTypeTestTemplates: any;

    segmentationConfiguration: any;

    gridRenderPendingCount = 0;
    pendingRefreshRowNodes = [];

    maxCollectionDate: Date;

    constructor(
        private element: Element,
        validationControllerFactory: ValidationControllerFactory,
        private logger: Logger,
        private dialogPresenter: DialogPresenter,
    ) {
        this.logger.name = 'request-detail-samples';

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.compositeOptions = [
            { title: 'None', value: 'None' },
            { title: '2-3', value: '2-3' },
            { title: '4-5', value: '4-5' },
            { title: '>5', value: '>5' },
        ];

        this.setGridOptions();
        this.allSelected = false;
        this.selectedSamples = [];

        this.sampleTypeTestTemplates = {};
        this.maxCollectionDate = moment(new Date()).startOf('day').add(2, 'days').toDate();
    }

    async showReorderSamplesDialog() {
        var columnOptions = [
            { title: 'Sample Description', value: 'sample-description' },
            ...this.gridOptions.columnDefs
                .filter((c) => c.metadata?.segment)
                .map((c) => ({ title: c.headerName, value: c.colId })),
        ];

        const { wasCancelled, output } = await this.dialogPresenter.showDataGridReorderDialog({
            title: 'Reorder Samples',
            columnOptions,
        });

        if (wasCancelled) {
            return;
        }

        this.gridOptions.columnApi.applyColumnState({
            state: output.map((columnOrder) => ({
                colId: columnOrder.id,
                sort: columnOrder.direction,
            })),
            defaultState: { sort: null },
        });
    }

    resetSampleOrder() {
        this.gridOptions.columnApi.applyColumnState({
            defaultState: { sort: null },
        });
    }

    ignoreLeftAndRightKeys(event) {
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') event.stopPropagation();

        return true;
    }

    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    suppressKeyEvent(params: any) {
        const KEY_ENTER = 13;
        const KEY_DELETE = 46;
        const event = params.event;
        const key = event.which;

        switch (key) {
            case KEY_ENTER:
                if (event.type === 'keydown') {
                    //this will attempt to render input or select ddl if not suppressed.
                    return true;
                }
                if (!params.node.lastChild) {
                    this.gridOptions.api.clearRangeSelection();
                    this.gridOptions.api.addCellRange({
                        rowStartIndex: params.node.rowIndex + 1,
                        rowEndIndex: params.node.rowIndex + 1,
                        columnStart: params.column.colId,
                        columnEnd: params.column.colId,
                    });
                    this.gridOptions.api.setFocusedCell(
                        params.node.rowIndex + 1,
                        params.column.colId,
                    );
                } else {
                    this.gridOptions.api.stopEditing();
                }
                return true;
            case KEY_DELETE:
                this.gridOptions.api.clearRangeSelection();
                return true;
            default:
                return false;
        }
    }

    processDataFromClipboard(params: any) {
        const data = params.data;
        const [lastItem] = data.slice(-1);
        if (lastItem.every((x) => x.length === 0)) {
            data.pop();
        }

        return data;
    }

    segmentationTemplatesChanged() {
        this.segmentationConfiguration = {};

        if (this.segmentationTemplates) {
            for (let segmentationTemplate of this.segmentationTemplates) {
                this.segmentationConfiguration[segmentationTemplate.id] =
                    segmentationTemplate.segments.reduce((keyedSegments, segment) => {
                        keyedSegments[segment.name] = segment;
                        return keyedSegments;
                    }, {});
            }
        }

        this.setGridOptions();
    }

    enableCompositingChanged() {
        this.setGridOptions();
    }

    createModeChanged() {
        this.setGridOptions();
    }

    handleSegmentationTemplateIdChanged(sample, rowNode, defer: boolean) {
        // Use this flag to batch a set of segmentation template changes (i.e. filldown).
        this.gridRenderPendingCount++;
        this.pendingRefreshRowNodes.push(rowNode);

        var handleChange = () => {
            if (sample.segments) this.tearDownSegmentValidation(sample.segments);

            sample.description = null;
            sample.segments = sample.segmentationTemplateId ? {} : null;

            if (sample.segmentationTemplateId)
                this.setupSegmentValidation(sample.segmentationTemplateId, sample.segments);

            if (--this.gridRenderPendingCount === 0) {
                this.ensureSegmentColumnDefs();

                // this.gridOptions.api is not available until the next binding cycle.
                setTimeout(() => {
                    // Refresh row in order to update the disabled-cell class using the class rules.
                    this.gridOptions.api.refreshCells({
                        rowNodes: this.pendingRefreshRowNodes,
                        force: true,
                    });
                    this.pendingRefreshRowNodes.splice(0);
                });
            }
        };

        if (defer) setTimeout(handleChange);
        else handleChange();
    }

    isLabWareChanged() {
        this.gridOptions.columnApi?.setColumnVisible('composite', !this.isLabWare);
        if (this.isLabWare) {
            this.samples.forEach((s: any) => (s.composite = null));
        }
    }

    ensureSegmentColumnDefs() {
        let existingSegmentColumnDefs = this.gridOptions.columnDefs.filter(
            (c) => c.metadata?.segment,
        );
        let newSegmentColumnDefs = this.getSegmentColumnDefs();

        if (!this.checkSegmentColumnDefsMatch(existingSegmentColumnDefs, newSegmentColumnDefs))
            this.setGridOptions();
    }

    checkSegmentColumnDefsMatch(existingSegmentColumnDefs, newSegmentColumnDefs) {
        if (existingSegmentColumnDefs.length !== newSegmentColumnDefs.length) return false;

        for (var i = 0; i < existingSegmentColumnDefs.length; i++)
            if (existingSegmentColumnDefs[i].headerName !== newSegmentColumnDefs[i].headerName)
                return false;

        return true;
    }

    getEditorProperties = ({ viewTemplate, editTemplate }) => ({
        cellRenderer: ExcelCellRenderer,
        cellRendererParams: {
            viewTemplate,
        },
        cellEditor: ExcelCellEditor,
        cellEditorParams: {
            editTemplate,
        },
    });

    fixupSegmentNames(sample: any, segmentationTemplate: any) {
        if (!sample.segments) return;

        var segmentNames = Object.keys(sample.segments);

        for (let segment of segmentationTemplate.segments) {
            // Find match ignoring case.
            let segmentName = segmentNames.find(
                (n) => n.localeCompare(segment.name, 'en', { sensitivity: 'base' }) === 0,
            );

            // If casing is different, copy value to correct naming of property and delete old property.
            if (segment.name !== segmentName) {
                sample.segments[segment.name] = sample.segments[segmentName];
                delete sample.segments[segmentName];
            }
        }
    }

    getSegmentColumnDefs() {
        let segmentColumDefs = [];

        for (let sample of this.samples) {
            let segmentationTemplate = sample.segmentationTemplateId
                ? this.segmentationTemplates.find((t) => t.id === sample.segmentationTemplateId)
                : null;

            // This checks the sample's segments to see if they were populated outside request submission.
            if (!segmentationTemplate) continue;

            // HACK: This is needed due to the serialization of dictionaries in .NET.
            //       The global configuration (camel-cased key names) in Startup.cs cannot be overridden for dictionarys that are sub-objects.
            this.fixupSegmentNames(sample, segmentationTemplate);

            for (let segment of segmentationTemplate.segments) {
                if (segmentColumDefs.some((c) => c.headerName === segment.name)) continue;

                segmentColumDefs.push(this.createSegmentColumnDef(segment));
            }
        }

        return segmentColumDefs;
    }

    private createSegmentColumnDef(segment: SegmentationTemplateSegment): any {
        return {
            colId: `segment_${segment.name}`,
            headerName: segment.name,
            metadata: {
                segment: true,
            },
            editable: (params) => {
                // Determines if the current cell supports the given segment for the sample (row).
                return segment.name in params.data.segments;
            },
            ...this.getEditorProperties({
                viewTemplate: `<span if.bind="'${segment.name}' in data.segments" textcontent.bind="data.segments['${segment.name}'] & validate"></span>`,
                editTemplate: `<div data-dynamic-error-message>
                        <input 
                            disabled.bind="!createMode"
                            style="width: calc(100% - 10px); display: block"
                            if.bind="segmentationConfiguration[data.segmentationTemplateId]['${segment.name}']" 
                            keydown.trigger="ignoreLeftAndRightKeys($event)"
                            type="text" 
                            value.bind="data.segments['${segment.name}'] & validate" />
                        <error-tooltip></error-tooltip>
                    </div>`,
            }),
            valueGetter: (params) => params.data.segments?.[segment.name],
            valueSetter: (params) => {
                let segmentationTemplate = this.segmentationTemplates.find(
                    (t) => t.id === params.data.segmentationTemplateId,
                );
                if (
                    !segmentationTemplate ||
                    !segmentationTemplate.segments.some((s) => s.name === segment.name)
                )
                    return false;

                params.data.segments[segment.name] = params.newValue;
                return true;
            },
            cellClassRules: {
                'disabled-cell': (params) =>
                    !params.data.segments || !(segment.name in params.data.segments),
            },
            suppressMenu: true,
            suppressSizeToFit: true,
            suppressMovable: true,
        };
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    setGridOptions() {
        this.gridOptions = {
            defaultColDef: {
                suppressKeyboardEvent: (params: any) => this.suppressKeyEvent(params),
                enableCellChangeFlash: true,
                resizable: true,
            },
            columnDefs: [
                ...(this.createMode
                    ? [
                          {
                              colId: 'checkbox',
                              suppressMenu: true,
                              template:
                                  '<label><input type="checkbox" checked.bind="data.isSelected" disabled.bind="!createMode" change.delegate="isSelectedChanged()"></label>',
                              headerCellTemplate:
                                  '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                              headerClass: 'checkbox',
                              cellClass: this.agGridSuppressRangeSelection,
                              width: 80,
                              sortable: false,
                              suppressSizeToFit: true,
                              suppressMovable: true,
                          },
                      ]
                    : []),
                {
                    colId: '#',
                    suppressMenu: true,
                    headerName: '#',
                    valueGetter: 'node.rowIndex + 1',
                    cellClass: this.agGridSuppressRangeSelection,
                    width: 80,
                    suppressSizeToFit: true,
                },
                {
                    colId: 'sample-template',
                    suppressMenu: true,
                    headerName: 'Sample Template',
                    headerCellTemplate: '<span required>Sample Template</span>',
                    editable: true,
                    ...this.getEditorProperties({
                        viewTemplate: `
                            <span
                                textcontent.bind="data.sampleTypeId | lookupAndMap:organizationSampleTemplates:'sampleTypeId':'description' & validate">
                            </span>`,
                        editTemplate:
                            '<select2 style="display: block; width: calc(100% - 10px)" minimum-results-for-search="2"' +
                            '    disabled.bind="!createMode"' +
                            '    allow-clear.bind="true"' +
                            '    items.bind="organizationSampleTemplates"' +
                            '    title-property-name="description"' +
                            '    value-property-name="sampleTypeId"' +
                            '    filter.bind="matchOrganizationSampleType"' +
                            '    selected-value.bind="data.sampleTypeId & validate">' +
                            '    <select2-item-template>' +
                            '        <div title="${item.description + \'\n\n\' + formatTestMethods(item.testTemplates, true)}">' +
                            '            <div>${item.description}</div>' +
                            '            <div class="small">${formatTestMethods(item.testTemplates, false)}</div>' +
                            '        </div>' +
                            '    </select2-item-template>' +
                            '</select2>' +
                            '<error-tooltip message="A sample template must be selected."></error-tooltip>',
                    }),
                    valueGetter: (params) => params.data.sampleTypeId,
                    valueSetter: (params) => {
                        params.data.sampleTypeId =
                            this.organizationSampleTemplates.find(
                                (t) =>
                                    t.sampleTypeId.toLowerCase().trim() ===
                                    params.newValue?.toLowerCase().trim(),
                            )?.sampleTypeId ??
                            this.organizationSampleTemplates.find(
                                (t) =>
                                    t.description.toLowerCase().trim() ===
                                    params.newValue?.toLowerCase().trim(),
                            )?.sampleTypeId ??
                            params.oldValue;

                        return true;
                    },
                    width: 240,
                    suppressSizeToFit: true,
                    suppressMovable: true,
                },
                {
                    colId: 'collection-date',
                    suppressMenu: true,
                    headerName: 'Collection Date',
                    headerCellTemplate: '<span required>Collection Date</span>',
                    editable: true,
                    ...this.getEditorProperties({
                        viewTemplate:
                            '<span textcontent.bind="data.collectionDate | dateFormat & validate"></span>',
                        editTemplate:
                            '<datepicker ' +
                            '    style="display: block; width: calc(100% - 10px)" ' +
                            '    pick-time.bind="true" ' +
                            '    value.bind="data.collectionDate & validate" ' +
                            '    max-selectable-date.bind="maxCollectionDate" ' +
                            '    disabled.bind="!createMode">' +
                            '</datepicker>' +
                            '<error-tooltip message="A collection date is required."></error-tooltip>',
                    }),
                    valueGetter: (params) => params.data.collectionDate,
                    valueSetter: (params) => {
                        let date = moment(params.newValue);
                        params.data.collectionDate = date.isValid() ? date.toDate() : null;

                        return true;
                    },
                    width: 225,
                    suppressSizeToFit: true,
                    suppressMovable: true,
                },
                ...(this.segmentationTemplates && this.segmentationTemplates.length !== 0
                    ? [
                          {
                              colId: 'segmentation-template',
                              suppressMenu: true,
                              headerName: 'Segmentation Template',
                              editable: true,
                              ...this.getEditorProperties({
                                  viewTemplate:
                                      '${getSegmentationTemplateName(data.segmentationTemplateId)}',
                                  editTemplate:
                                      '<select2 ' +
                                      '    disabled.bind="!createMode"' +
                                      '    allow-clear.bind="true"' +
                                      '    style="width: calc(100% - 10px); display: block"' +
                                      '    items.bind="segmentationTemplates"' +
                                      '    selected-value.bind="data.segmentationTemplateId"' +
                                      '    select2change.delegate="handleSegmentationTemplateIdChanged(data, node, true)"' +
                                      '    value-property-name="id"' +
                                      '    title-property-name="name"' +
                                      '    disabled.bind="!createMode">' +
                                      '</select2>',
                              }),
                              valueGetter: (params: any) => {
                                  return this.getSegmentationTemplateName(
                                      params.data.segmentationTemplateId,
                                  );
                              },
                              valueSetter: (params) => {
                                  params.data.segmentationTemplateId = !params.newValue
                                      ? null
                                      : this.isNumeric(params.newValue)
                                      ? this.segmentationTemplates.find(
                                            (t) => t.id === Number(params.newValue),
                                        )?.id
                                      : this.segmentationTemplates.find(
                                            (t) =>
                                                t.name.toLowerCase() ===
                                                params.newValue.toLowerCase(),
                                        )?.id;

                                  this.handleSegmentationTemplateIdChanged(
                                      params.data,
                                      params.node,
                                      false,
                                  );
                                  return true;
                              },
                              width: 225,
                              suppressSizeToFit: true,
                              suppressMovable: true,
                          },
                          ...this.getSegmentColumnDefs(),
                      ]
                    : []),
                {
                    colId: 'sample-description',
                    suppressMenu: true,
                    headerName: 'Sample Description',
                    headerCellTemplate: '<span required>Sample Description</span>',
                    cellClassRules: {
                        'disabled-cell': (params) => params.data.segmentationTemplateId,
                    },
                    editable: true,
                    ...this.getEditorProperties({
                        viewTemplate:
                            '<span textcontent.bind="data.description & validate"></span>',
                        editTemplate:
                            '<div data-dynamic-error-message>' +
                            '    <input ' +
                            '        if.bind="!data.segmentationTemplateId"' +
                            '        style="width: calc(100% - 10px)" ' +
                            '        type="text" ' +
                            '        value.bind="data.description & validate" ' +
                            '        keydown.trigger="ignoreLeftAndRightKeys($event)"' +
                            '        disabled.bind="!createMode" />' +
                            '    <error-tooltip></error-tooltip>' +
                            '</div>',
                    }),
                    valueGetter: (params) => params.data.description,
                    valueSetter: (params) => {
                        if (params.data.segmentationTemplateId) {
                            return false;
                        }
                        params.data.description = params.newValue;
                        return true;
                    },
                    width: 500,
                    suppressSizeToFit: true,
                    suppressMovable: true,
                },
                ...(this.enableCompositing
                    ? [
                          {
                              colId: 'composite',
                              suppressMenu: true,
                              headerName: 'Composite',
                              editable: true,
                              ...this.getEditorProperties({
                                  viewTemplate: '<span textcontent.bind="data.composite & validate"></span>',
                                  editTemplate:
                                      '<select2 ' +
                                      '    disabled.bind="!createMode"' +
                                      '    style="width: calc(100% - 10px); display: block"' +
                                      '    items.bind="compositeOptions"' +
                                      '    selected-value.bind="data.composite & validate">' +
                                      '</select2> ' +
                                      '<error-tooltip message="A composite field must be selected."></error-tooltip>',
                              }),
                              valueGetter: (params) => params.data.composite,
                              valueSetter: (params) => {
                                  params.data.composite = this.compositeOptions.some(
                                      (o) => o.value === params.newValue,
                                  )
                                      ? params.newValue
                                      : null;
                                  return true;
                              },
                              width: 120,
                              suppressSizeToFit: true,
                              suppressMovable: true,
                          },
                      ]
                    : []),
                {
                    colId: 'test-methods',
                    suppressMenu: true,
                    headerName: 'Test Methods',
                    template:
                        '<span title="${formatTestMethods(sampleTypeTestTemplates[data.sampleTypeId], true)}">${formatTestMethods(sampleTypeTestTemplates[data.sampleTypeId], false)}</span>',
                    cellClass: this.agGridSuppressRangeSelection,
                    width: 325,
                    suppressSizeToFit: true,
                    suppressMovable: true,
                },
            ],
            enableRangeSelection: true,
            processDataFromClipboard: this.processDataFromClipboard,
            rowBuffer: 500,
            onPasteEnd: () => {
                this.element.dispatchEvent(new CustomEvent('formchange', { bubbles: true }));
            },
            postSort: (rowNodes) => {
                if (rowNodes.length === 0) return;

                this.orderedSamples = rowNodes.map((rn) => rn.data);
            },
            onGridReady: () => this.isLabWareChanged(),
        };
    }

    getSegmentationTemplateName(segmentationTemplateId) {
        if (!this.segmentationTemplates) return '';

        return this.segmentationTemplates.find((t) => t.id === segmentationTemplateId)?.name ?? '';
    }

    organizationSampleTemplatesChanged() {
        this.sampleTypeTestTemplates = {};
        this.organizationSampleTemplates.forEach((ost) => {
            this.sampleTypeTestTemplates[ost.sampleTypeId] = ost.testTemplates;
        });
    }

    formatTestMethods(testTemplates: any[], tooltip: boolean) {
        if (!testTemplates) return '';

        let filteredTestTemplates = testTemplates.filter((tt) => !tt.group);

        if (tooltip)
            return filteredTestTemplates
                .map(
                    (tt) =>
                        `${tt.testMethod.code} - ${
                            tt.testMethod.customerSpecificName || tt.testMethod.name
                        }`,
                )
                .join('\n');

        return filteredTestTemplates.map((tt) => `${tt.testMethod.code}`).join(', ');
    }

    matchOrganizationSampleType(filterText, organizationSampleType) {
        return (
            (organizationSampleType.description || '').toLowerCase().indexOf(filterText) !== -1 ||
            organizationSampleType.testTemplates.some(
                (tt) => tt.testMethod.code.toLowerCase().indexOf(filterText) !== -1,
            )
        );
    }

    // this was noticed when saving request templates and drafts.   won't deserizalize on server-side
    fixUpSampleCollectionDates() {
        this.samples.forEach((s) => {
            if (
                s.collectionDate !== null &&
                s.collectionDate !== undefined &&
                trim(s.collectionDate).length === 0
            )
                s.collectionDate = null;
        });
    }

    setSelectedSamples() {
        this.selectedSamples = this.samples.filter((s) => s.isSelected);
    }

    isSelectedChanged() {
        this.allSelected = this.samples.length && this.samples.every((s) => s.isSelected);
        this.setSelectedSamples();
        this.toggleButtonsDependentOnSelections();
    }

    allSelectedClicked() {
        if (this.samples) {
            this.allSelected = !this.samples.every((s) => s.isSelected);

            for (let sample of this.samples) sample.isSelected = this.allSelected;
        }

        this.setSelectedSamples();
        this.toggleButtonsDependentOnSelections();

        return true;
    }

    createSamples(count) {
        for (let i = 0; i < count; i++) {
            let sample = {
                sampleTypeId: null,
                collectionDate: null,
                description: null,
                composite: 'None',
                segmentationTemplateId: null,
                segments: null,
            } as Sample;

            this.addSampleInternal(sample);
        }

        this.ensureNewGridFunctionAlertShown();
    }

    async ensureNewGridFunctionAlertShown() {
        try {
            let newGridFunctionAlertShown = localStorage.getItem('newGridFunctionAlertShown');
            if (newGridFunctionAlertShown) return;

            await this.dialogPresenter.showAlert(
                'Improved Sample Grid Functions',
                `<div style="margin-bottom: 10px">Our sample grid has been improved for a better user experience. Here are some of the new features:</div>
                <ul>
                    <li><strong>Double-click</strong> a cell or select a cell and start typing to enter information.</li>
                    <li>Select, <strong>copy and paste data</strong> between cells - Ctrl + C to copy and Ctrl + V to paste.</li>
                    <li>Copy and paste <strong>data between the grid and Excel</strong> - Ctrl + C to copy and Ctrl + V to paste.</li>
                    <li><strong>Improved fill-down</strong> when selecting cells having data in the first row of the selection (use button above grid or Ctrl + D).</li>
                </ul>`,
            );

            localStorage.setItem('newGridFunctionAlertShown', 'true');
        } catch (error) {
            this.logger.error('Error ensuring new grid function alert shown.');
        }
    }

    cloneSelectedSamples(count) {
        var samplesToClone = this.samples.filter((s) => s.isSelected);

        if (!samplesToClone || samplesToClone.length === 0) return;

        for (let i = 0; i < count; i++) {
            samplesToClone.forEach((s) => {
                let sample = {
                    sampleTypeId: s.sampleTypeId,
                    collectionDate: s.collectionDate,
                    description: s.description,
                    composite: s.composite,
                    segmentationTemplateId: s.segmentationTemplateId,
                    segments: !s.segments
                        ? null
                        : Object.keys(s.segments).reduce((clonedSegments, segmentName) => {
                              clonedSegments[segmentName] = s.segments[segmentName];
                              return clonedSegments;
                          }, {}),
                } as Sample;

                this.addSampleInternal(sample);
            });
        }
    }

    removeSelectedSamples() {
        this.gridOptions.api.deselectAll();
        var samplesToRemove = this.samples.filter((s) => s.isSelected);

        this.removeSamples(samplesToRemove);

        this.toggleButtonsDependentOnSelections();
        this.isSelectedChanged();
    }

    fillSelectedRange() {
        this.gridOptions?.api?.copySelectedRangeDown();
    }

    // NOTE: entry put to allow setup of validation when samples already exist and pushed into view model
    configureWithExistingSamples() {
        this.samples.forEach((s) => this.addSampleInternal(s, false));
    }

    addSamples(samples: Sample[]) {
        for (let sample of samples) this.addSampleInternal(sample);

        this.ensureSegmentColumnDefs();
    }

    addSampleInternal(sample: Sample, addToCollection = true) {
        this.gridOptions.api.deselectAll();

        if (addToCollection) this.samples.push(sample);

        this.setupSampleValidation(sample);
    }

    removeSamples(samples: Sample[]) {
        if (!samples) return;

        for (let sample of samples) {
            this.samples.splice(this.samples.indexOf(sample), 1);
            this.tearDownSampleValidation(sample);
        }

        this.ensureSegmentColumnDefs();
    }

    toggleButtonsDependentOnSelections() {
        let selectedSamples = this.samples.filter((s) => s.isSelected);

        this.enableRemoveButton = selectedSamples.length !== 0;
        this.enableCloneButton = selectedSamples.length !== 0;
    }

    resetView() {
        this.samplesGridFullHeightViewModel.update();
        window.scrollTo(0, 0);
        if (!this.createMode) {
            // HACK: in case the sample type no longer exists
            this.organizationSampleTemplates.push({
                description: 'Unknown',
                sampleTypeId: 'Unknown',
            });

            this.samples.forEach((sample) => {
                let masterSampleTemplate = this.organizationSampleTemplates.find(
                    (st) => st.sampleTypeId === sample.sampleTypeId,
                );
                if (!masterSampleTemplate) sample.sampleTypeId = 'Unknown';
            });
        } else {
            if (this.samples.length !== 0) this.ensureNewGridFunctionAlertShown();
        }
    }

    async exitSampleInfo() {
        this.element.dispatchEvent(
            new CustomEvent('exitsampleinfo', { bubbles: true, detail: {} }),
        );
    }

    async validate(reset = false): Promise<boolean> {
        var result = await this.validationController.validate();

        if (reset) this.validationController.reset();

        return result.valid;
    }

    setupSampleValidation(sample: Sample) {
        ValidationRules.ensure((s: Sample) => s.sampleTypeId)
            .required()
            .ensure((s: Sample) => s.collectionDate)
            .required()
            .satisfies((collectionDate: Date) => {
                if (moment(collectionDate).isBefore(moment().add({ years: -10 }), 'day'))
                    return false;

                if (moment(collectionDate).isAfter(moment().add({ years: 10 }), 'day'))
                    return false;

                return true;
            })
            .ensure((s: Sample) => s.description)
            .required()
            .withMessage('A description is required.')
            .when((s) => s.segmentationTemplateId === null)
            .maxLength(265)
            .withMessage('Description must be less than 265 characters.')
            .ensure((s: Sample) => s.composite)
            .required()
            .withMessage('A composite is required')
            .on(sample);

        this.validationController.addObject(sample);

        if (sample.segmentationTemplateId)
            this.setupSegmentValidation(sample.segmentationTemplateId, sample.segments);
    }

    tearDownSampleValidation(sample: Sample) {
        this.validationController.removeObject(sample);
        ValidationRules.off(sample);

        if (sample.segmentationTemplateId) this.tearDownSegmentValidation(sample.segments);
    }

    setupSegmentValidation(segmentationTemplateId: number, segments: any) {
        let segmentRules;
        let keyedSegments = this.segmentationConfiguration[segmentationTemplateId] as {
            [key: string]: SegmentationTemplateSegment;
        };
        if (keyedSegments) {
            for (let segmentName in keyedSegments) {
                let segment = keyedSegments[segmentName];
                // HACK: The if.bind makes the list of segments the same across all templates, however, the segment object is undefined for the templates
                //       that don't actually contain the segment. Therefore, all segments must be checked for existence.
                if (!segment) continue;

                if (segment.required)
                    segmentRules = (segmentRules || ValidationRules)
                        .ensure(segmentName)
                        .required()
                        .withMessage(`${segmentName} is required.`);
            }
        }

        if (segmentRules) {
            segmentRules.on(segments);
            this.validationController.addObject(segments);
        }
    }

    tearDownSegmentValidation(segments: any) {
        if (!segments) return;

        this.validationController.removeObject(segments);
        ValidationRules.off(segments);
    }

    preventDefaultFormSubmit(event: KeyboardEvent) {
        if (event.key === 'Enter') event.preventDefault();

        return true;
    }
}
