import { BaseQueryFilter } from 'infrastructure/components/query-filters/base-query-filter';
import {
    CollectionTypeQuery,
    CollectionTypesQueryFilters,
} from 'infrastructure/components/query-filters/collection-types-query-filter';
import {
    DateRangeQuery,
    DateRangeQueryFilter,
} from 'infrastructure/components/query-filters/date-range-query-filter';
import {
    IsAssignedQuery,
    IsAssignedQueryFilters,
} from 'infrastructure/components/query-filters/is-assigned-query-filter';
import {
    MapPlanQuery,
    MapPlanQueryFilter,
} from 'infrastructure/components/query-filters/map-plan-query-filter';
import { QueryFiltered } from 'infrastructure/components/query-filters/query-filtered';
import {
    RecordStatusQuery,
    RecordStatusQueryFilters,
} from 'infrastructure/components/query-filters/record-status-query-filter';
import CollectionType from 'location-testing/collection-type';
import RecordStatus from 'record-status';

export interface TaskListQuery {
    dateRangeOption?: string;
    targetStartDate: string;
    targetEndDate: string;
    dateRangeOverride?: boolean;
    enableTimeFilter?: boolean;
    mapId?: number;
    planId?: number;
    mapIds: number[];
    planIds: number[];
    recordStatus?: RecordStatus;
    isAssigned?: string;
    onlyAssignedToMe?: boolean;
    pointMobile?: any[];
    pointName?: string;
    pointRisks?: string[];
    pointRooms?: string[];
    pointTypes?: string[];
    pointZones?: string[];
    taskStatusIds?: number[];
    testMethodIds?: number[];
    collectionTypes?: CollectionType[];
}
export class TaskListQueryFilters extends BaseQueryFilter implements QueryFiltered<TaskListQuery> {
    private _filterCaption: string;
    dateRangeQueryFilters: DateRangeQueryFilter;
    mapPlanQueryFilters: MapPlanQueryFilter;
    recordStatusQueryFilters: RecordStatusQueryFilters;
    isAssignedQueryFilters: IsAssignedQueryFilters;
    collectionTypesQueryFilters: CollectionTypesQueryFilters;

    onlyAssignedToMe: boolean;
    pointMobile: any[];
    pointName: string;
    pointRisks: string[];
    pointRooms: string[];
    pointTypes: string[];
    pointZones: string[];
    taskStatusIds: number[];
    testMethodIds: number[];

    constructor() {
        super();

        this.dateRangeQueryFilters = new DateRangeQueryFilter();
        this.mapPlanQueryFilters = new MapPlanQueryFilter();
        this.recordStatusQueryFilters = new RecordStatusQueryFilters();
        this.isAssignedQueryFilters = new IsAssignedQueryFilters();
        this.collectionTypesQueryFilters = new CollectionTypesQueryFilters();

        this.onlyAssignedToMe = false;
        this.pointMobile = [];
        this.pointRisks = [];
        this.pointRooms = [];
        this.pointTypes = [];
        this.pointZones = [];
        this.taskStatusIds = [];
        this.testMethodIds = [];
    }

    public get count(): number {
        return (
            0 +
            this.mapPlanQueryFilters.count +
            this.recordStatusQueryFilters.count +
            this.isAssignedQueryFilters.count +
            this.collectionTypesQueryFilters.count +
            Number(!!(this.testMethodIds && this.testMethodIds.length)) +
            Number(!!(this.pointName && this.pointName.length)) +
            Number(!!(this.pointRooms && this.pointRooms.length)) +
            Number(!!(this.pointZones && this.pointZones.length)) +
            Number(!!(this.pointMobile && this.pointMobile.length)) +
            Number(!!(this.pointTypes && this.pointTypes.length)) +
            Number(!!(this.pointRisks && this.pointRisks.length)) +
            Number(!!(this.taskStatusIds && !!this.taskStatusIds.length)) +
            Number(!!this.onlyAssignedToMe)
        );
    }

    public get filterCaption(): string {
        return this._filterCaption;
    }

    reset(): void {
        this.dateRangeQueryFilters.reset();
        this.mapPlanQueryFilters.reset();
        this.recordStatusQueryFilters.reset();
        this.isAssignedQueryFilters.reset();
        this.collectionTypesQueryFilters.reset();

        this.onlyAssignedToMe = false;
        this.pointMobile = [];
        this.pointRisks = [];
        this.pointRooms = [];
        this.pointTypes = [];
        this.pointZones = [];
        this.taskStatusIds = [];
        this.testMethodIds = [];
    }

    getQueryParams(): TaskListQuery {
        return {
            ...this.dateRangeQueryFilters.getQueryParams(),
            ...this.mapPlanQueryFilters.getQueryParams(),
            ...this.recordStatusQueryFilters.getQueryParams(),
            ...this.isAssignedQueryFilters.getQueryParams(),
            ...this.collectionTypesQueryFilters.getQueryParams(),
            testMethodIds: this.testMethodIds,
            pointName: this.pointName,
            pointRooms: this.pointRooms,
            pointZones: this.pointZones,
            pointTypes: this.pointTypes,
            pointRisks: this.pointRisks,
            taskStatusIds: this.taskStatusIds,
            pointMobile: this.pointMobile,
            onlyAssignedToMe: this.onlyAssignedToMe,
        };
    }

    setFilterValues(params: TaskListQuery): void {
        const dateRangeQuery: DateRangeQuery = {
            dateRangeOverride: params.dateRangeOverride,
            dateRangeOption: params.dateRangeOption,
            targetStartDate: params.targetStartDate,
            targetEndDate: params.targetEndDate,
            enableTimeFilter: params.enableTimeFilter,
        };
        const mapPlanQuery: MapPlanQuery = {
            mapId: params.mapId,
            planId: params.planId,
            mapIds: params.mapIds,
            planIds: params.planIds,
        };
        const recordStatusQuery: RecordStatusQuery = { recordStatus: params.recordStatus };
        const isAssignedQuery: IsAssignedQuery = { isAssigned: params.isAssigned };
        const collectionTypeQuery: CollectionTypeQuery = {
            collectionTypes: params.collectionTypes,
        };
        const {
            onlyAssignedToMe,
            pointMobile,
            pointName,
            pointRisks,
            pointRooms,
            pointTypes,
            pointZones,
            taskStatusIds,
            testMethodIds,
        } = { ...params };

        this.dateRangeQueryFilters.setFilterValues(dateRangeQuery);
        this.mapPlanQueryFilters.setFilterValues(mapPlanQuery);
        this.recordStatusQueryFilters.setFilterValues(recordStatusQuery);
        this.isAssignedQueryFilters.setFilterValues(isAssignedQuery);
        this.collectionTypesQueryFilters.setFilterValues(collectionTypeQuery);

        super.setQueryFilteredValues(
            {
                onlyAssignedToMe,
                pointMobile,
                pointName,
                pointRisks,
                pointRooms,
                pointTypes,
                pointZones,
                taskStatusIds,
                testMethodIds: Array.isArray(testMethodIds) ? testMethodIds : [testMethodIds],
            },
            this,
        );

        this._filterCaption = super.getFilterCaption(
            this.count,
            'Target Date Range',
            this.dateRangeQueryFilters.dateRangeCaption,
        );
    }
}
