import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class PointRoomService {
    constructor(private httpClient: HttpClient) {}

    getOrganizationPointRooms(organizationId?: number): Promise<string[]> {
        return this.httpClient.get(
            `location-testing/point-rooms${
                organizationId ? '?organizationId=' + organizationId : ''
            }`,
        ) as any as Promise<string[]>;
    }
}
