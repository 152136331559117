import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { ActiveTestMethodPlans } from './contracts/active-test-method-plans';
import { ReplaceTestMethodRequest } from './contracts/replace-test-method-request';
import { TestMethodReplacemantImpactCounts } from './contracts/test-method-replacement-impact-counts';

@autoinject
export default class PlanManagementService {
    constructor(private httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    fetchActiveTestMethodPlans(): Promise<ActiveTestMethodPlans[]> {
        return this.httpClient.get('location-testing/plan-management') as any as Promise<
            ActiveTestMethodPlans[]
        >;
    }

    getTestMethodReplacementImpactCounts(
        testMethodId: number,
        organizationId?: number,
    ): Promise<TestMethodReplacemantImpactCounts> {
        const url = `location-testing/plan-management/test-methods/${testMethodId}/replacement-impact-count${
            organizationId ? '?organizationId=' + organizationId : ''
        }`;
        return this.httpClient.get(url) as any as Promise<TestMethodReplacemantImpactCounts>;
    }

    replaceTestMethod(replaceTestMethodRequest: ReplaceTestMethodRequest) {
        return this.httpClient.put('location-testing/plan-management', replaceTestMethodRequest);
    }
}
