import { agGridGetPreferences } from 'infrastructure/ag-grid/ag-localstorage-helper';
import AgPreferences from 'infrastructure/ag-grid/ag-preferences';
import { AgDateTimeEditorTypes } from './ag-datetime-editor-types';

export class SampleSegmentationGridPreferences implements AgPreferences {
    constructor() {
        const preferences = agGridGetPreferences(this);
        this.dateTimeEditor = preferences.dateTimeEditor || AgDateTimeEditorTypes.PICKER;
    }

    gridName = 'sample-segmentation';
    dateTimeEditor: string;
}
