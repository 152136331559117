import { inject, bindable } from 'aurelia-framework';

@inject(Element)
export class ScrollIntoViewCustomAttribute {
    @bindable enabled;

    constructor(element) {
        this.element = element;
    }

    enabledChanged() {
        if (!this.enabled)
            return;

        var child = this.element;
        var parent = child.parentElement;
        if (!parent)
            return;

        var firstChild = parent.children[0];

        var parentBounds = parent.getBoundingClientRect();
        var windowTop = firstChild.getBoundingClientRect().top;
        var childBounds = child.getBoundingClientRect();

        if (childBounds.bottom - parentBounds.top > parentBounds.height)
            parent.scrollTop = childBounds.top - parentBounds.top;
        else if (childBounds.top < parentBounds.top) {
            parent.scrollTop = childBounds.top - windowTop;
        }
    }
};
