import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@autoinject
export default class RequestDefaultsService {
    constructor(private httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getRequestDefaults(organizationId) {
        return this.httpClient.get(`request-defaults?organizationId=${organizationId}`);
    }
}
