import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import CollectionType from 'location-testing/collection-type';
import moment from 'moment';
import svgPanZoom from 'svg-pan-zoom';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import TestResultSummary from '../../tests/test-result-summary';
import TestService from '../../tests/test-service';
import MapService from '../maps/map-service';
import operationsTimingOptions from '../operations-timing-options';
import PointService from '../points/point-service';
import Task from './task';
import TaskService from './task-service';

@autoinject
export class TaskDetail {
    validationController: ValidationController;
    panZoom: any;
    mapSvg: any;
    scale: number;

    formChanged: boolean;
    loadPromise: Promise<void>;

    failedTestResultSummary: TestResultSummary;
    failedTestPoints: any[];
    mapId: number;
    map: any;
    points: any[];

    operationsTimingOptions: any;
    pointSampleDescriptionValidationRules: any;

    pointSampleDescriptions: { point: any; sampleDescription: string }[];
    startDate: Date;
    endDate: Date;
    collectionTime: any;
    operationsTiming: string;

    constructor(
        private router: Router,
        validationControllerFactory: ValidationControllerFactory,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private testService: TestService,
        private mapService: MapService,
        private pointService: PointService,
        private taskService: TaskService
    ) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.logger.name = 'create-vector-tasks';

        this.scale = 1;
        this.operationsTimingOptions = operationsTimingOptions;
        this.pointSampleDescriptions = [];
        this.pointSampleDescriptionValidationRules = ValidationRules
            .ensure('sampleDescription').required().maxLength(265)
            .rules;
    }

    activate(params) {
        this.loadPromise = (async () => {
            let failedTestId = parseInt(params.failedTestId);
            if (!failedTestId) {
                this.logger.error('Cannot load Create Vector Tasks screen because no failed test ID was specified.');
                this.cancel();
            }

            this.pageContext.isLoading = true;

            try {
                let failedTestResultSummaries = await this.testService.getTestResultSummaries({ testIds: [failedTestId] });
                if (failedTestResultSummaries.length === 0) {
                    this.logger.error(`Cannot load Create Vector Tasks screen because the failed test with ID ${failedTestId} couldn't be found.`);
                    this.cancel();
                }

                this.failedTestResultSummary = failedTestResultSummaries[0];
                this.failedTestPoints = await this.pointService.getPoints({ sampleIds: [this.failedTestResultSummary.sampleId] });

                this.mapId = this.failedTestPoints[0].mapId;

                let [map, points] = await Promise.all([
                    this.mapService.getMap(this.mapId),
                    this.pointService.getPoints({ mapIds: [this.mapId], recordStatus: 'Active' })
                ]);

                this.map = map;
                this.points = points;

                for (let point of this.points)
                    if (this.failedTestPoints.some(p => p.id === point.id))
                        this.togglePointSelection(point);

                ValidationRules
                    .ensure('planId').required().when((t: any) => !t.id)
                    .ensure('startDate').required()
                    .ensure('endDate').required()
                        .satisfies(_ => this.endDate.getTime() - this.startDate.getTime() >= 0)
                        .when(_ => !!this.endDate && !!this.startDate)
                    .ensure('collectionTime').required()
                    .ensure('operationsTiming').required()
                    .on(this);
            } catch (error) {
                this.logger.error('Error loading task', error, { taskId: params.id });

                await (error.apiErrorCode === 1
                    ? this.dialogPresenter.showAlert(
                        'Error Loading Task',
                        'The current task doesn\'t exist.')
                    : this.dialogPresenter.showAlert(
                        'Error Loading Task',
                        'An error occurred while loading the current task. Please try again later.')
                );

                this.router.navigateToRoute('task-list', { mapId: this.mapId });
            }

            this.pageContext.isLoading = false;
        })();
    }

    togglePointSelection(point) {
        if (point.selected) {
            let index = this.pointSampleDescriptions.findIndex(e => e.point === point);
            let pointSampleDescription = this.pointSampleDescriptions.splice(index, 1);
            this.validationController.removeObject(pointSampleDescription[0]);
        } else {
            let pointSampleDescription = { point, sampleDescription: point.name };
            this.pointSampleDescriptions.push(pointSampleDescription);
            this.validationController.addObject(pointSampleDescription, this.pointSampleDescriptionValidationRules);
        }

        point.selected = !point.selected;
    }

    handleFormChange() {
        this.formChanged = true;
    }

    zoomIn() {
        this.panZoom?.zoomIn();
    }

    zoomOut() {
        this.panZoom?.zoomOut();
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('task-list', { mapId: this.map.id });
    }

    async createVectorTasks() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        let tasks: Task[] = [];

        try {
            let days = 1 + (this.endDate.getTime() - this.startDate.getTime()) / (1000 * 3600 * 24);

            for (var i = 0; i < days; i++)
                for (let pointSampleDescription of this.pointSampleDescriptions)
                    tasks.push({
                        failedTestId: this.failedTestResultSummary.id,
                        pointId: pointSampleDescription.point.id,
                        testMethods: [{
                            id: this.failedTestResultSummary.testMethodId,

                            // Intentionally null properties.
                            externalId: null,
                            externalSource: 'Unknown',
                            name: null,
                            billCode: null,
                            category: null,
                        }],
                        sampleDescription: pointSampleDescription.sampleDescription,
                        collectionType: CollectionType.Vector,
                        isActive: true,
                        targetDateTime: moment(this.startDate)
                            .add({
                                days: i,
                                hours: parseInt(this.collectionTime.split(':')[0]),
                                minutes: parseInt(this.collectionTime.split(':')[1])
                            })
                            .toDate(),
                        operationsTiming: this.operationsTiming,
                        taskStatusId: 1,

                        // Intentionally null properties.
                        id: 0,
                        assignedUserId: null,
                        assigningUserId: null,
                        planId: null,
                        comments: null,
                        collectedDateTime: null,
                    });

            await this.taskService.createTasks(tasks);
            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Tasks created successfully.');
            this.router.navigateToRoute('task-list', { mapId: this.mapId });
        } catch (error) {
            this.logger.error('Error creating tasks.', error, { tasks });
            this.dialogPresenter.showAlert(
                'Error Creating Vector Tasks',
                this.getApiErrorMessage(error.apiErrorCode));
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        return 'An error occurred while creating tasks. Please try again later.';
    }

    async attached() {
        this.panZoom = svgPanZoom('#svg-id', {
            fit: false,
            center: false,
            zoomEnabled: true,
            dblClickZoomEnabled: false,
            minZoom: .001,
            onZoom: (scale) => {
                this.scale = scale;
            }
        });

        // Ensure data is loaded.
        await this.loadPromise;

        if (!this.failedTestPoints || !this.failedTestPoints.length)
            return;

        this.tryBringPointIntoView();
    }

    tryBringPointIntoView() {
        // Ensure UI has rendered based on bindings from loaded data.
        var interval = setInterval(() => {
            var mapImage = this.mapSvg && this.mapSvg.querySelector('image');
            if (!mapImage)
                return;

            clearInterval(interval);

            var mapImageBoundingRectangle = mapImage.getBoundingClientRect();

            // If the image has loaded (by checking width and height), perform pan, otherwise, perform pan on image load event.
            if (mapImageBoundingRectangle.width && mapImageBoundingRectangle.height)
                this.bringPointIntoView(mapImageBoundingRectangle);
            else
                mapImage.onload = () => {
                    // Requery image size after image is loaded.
                    mapImageBoundingRectangle = mapImage.getBoundingClientRect();
                    this.bringPointIntoView(mapImageBoundingRectangle);
                };
        }, 100);
    }

    bringPointIntoView(mapImageBoundingRectangle) {
        var point = this.failedTestPoints[0];

        // Pan x and y only if the image is larger than the container SVG in that dimension.

        // Using loose equivalency due to binding for validation on x which makes it a string.
        const x = point.x != 0 && mapImageBoundingRectangle.width > this.mapSvg.clientWidth ?
            this.mapSvg.clientWidth / 2 - point.x :
            0;

        const y = point.y != 0 && mapImageBoundingRectangle.height > this.mapSvg.clientHeight ?
            this.mapSvg.clientHeight / 2 - point.y :
            0;

        this.panZoom.pan({ x, y });
    }
}
