import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class NewsItemService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getNewsItems({ forDashboard = true } = {}) {
        var query = forDashboard ? '?forDashboard=true' : '';
        return this.httpClient.get(`news-items/${query}`);
    }

    getNewsItem(id) {
        return this.httpClient.get(`news-items/${id}`);
    }

    deleteNewsItems(ids) {
        var query = ids.map(id => `id=${id}`).join('&');
        return this.httpClient.delete(`news-items?${query}`);
    }

    saveNewsItem(newsItem) {
        if (!newsItem.id)
            return this.httpClient.post('news-items/', newsItem);

        return this.httpClient.put(`news-items/${newsItem.id}`, newsItem);
    }
}
