import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, BindingEngine, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
} from 'aurelia-validation';
import { getTimeOptions } from 'infrastructure/components/time-selector';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import SelectorOption from 'infrastructure/selector-option';
import TimeZoneService from 'infrastructure/time-zone-service';
import Lab from 'labs/lab';
import LabService from 'labs/lab-service';
import {
    getCollectionTypeOptions,
    planTypeIdToCollectionTypes,
} from 'location-testing/collection-type';
import moment from 'moment';
import SecurityService from 'security/security-service';
import TestMethodService from 'test-methods/test-method-service';
import MapService from '../maps/map-service';
import operationsTimingOptions from '../operations-timing-options';
import PointAttributeService from '../point-attributes/point-attribute-service.js';
import PointSelectorFilter from '../point-selector-filter';
import PointService from '../points/point-service';
import RemediationService from '../remediations/remediation-service';
import Plan from './plan';
import PlanService from './plan-service';
import PlanType from './plan-type';
import TaskDistributionMethod from './task-distribution-method';
import UiSchedule from './ui-schedule';
import { testMethodNameFormatting } from 'test-methods/test-method-helper';

@autoinject
export class PlanDetail {
    @observable selectedTargetCollectionTime: any;
    @observable blackoutDate: any;

    validationController: ValidationController;

    // filters
    applyFiltersSubscription: Subscription;
    clearFiltersSubscription: Subscription;

    // subscriptions
    planLabIdChangedSubscription: Subscription;
    taskDistributionMethodSubscription: Subscription;
    planTypeSubscription: Subscription;

    // Security
    canEditPlans: boolean;
    canViewAudit: boolean;
    currentUser: any;

    organizationId: any;
    mapId: number;
    remediationId: any;
    plan: Plan;
    schedule: UiSchedule;
    recordChanges: any;
    pausedPointCount: number;

    planTypes: any;
    labs: Lab[];
    testMethods: any;
    points: any;
    remediations: any;
    timeZones: any;
    operationsTimingOptions: any;
    pointAttributes: any;
    collectionTypeOptions: SelectorOption[];

    @observable view: 'default' | 'edit-points';
    isPaused: boolean;
    wasPausedAtLoad: boolean;
    isLocked: boolean;
    propertyDisplayNames: any;
    timeCaptions: any;
    scheduleMinDate: Date;
    canEditStartDate: boolean;
    targetCollectionTimesInvalid: boolean;

    formChanged: boolean;
    planPointSelectorViewModel: any;
    showScheduleSection: boolean;
    showCollectionTimesSection: boolean;
    showBlackoutSection: boolean;
    selectedOnly: boolean;

    taskDistributionMethodWeekBased = TaskDistributionMethod.WEEK_BASED;
    taskDistributionMethodEven = TaskDistributionMethod.EVEN;
    taskDistributionMethodRandom = TaskDistributionMethod.RANDOM;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private bindingEngine: BindingEngine,
        validationControllerFactory: ValidationControllerFactory,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private timeZoneService: TimeZoneService,
        private mapService: MapService,
        private pointService: PointService,
        private remediationService: RemediationService,
        private planService: PlanService,
        private testMethodService: TestMethodService,
        private filters: PointSelectorFilter,
        private pointAttributeService: PointAttributeService,
        private labService: LabService,
    ) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.logger.name = 'plan-detail';

        this.canEditPlans =
            this.securityService.hasPermission('EditPlans') &&
            !this.securityService.isImpersonating();
        this.canViewAudit =
            this.securityService.isCurrentUserInternal() && this.securityService.isImpersonating();

        this.isLocked = false;
        this.wasPausedAtLoad = false;
        this.showScheduleSection = false;
        this.showCollectionTimesSection = false;
        this.showBlackoutSection = false;

        this.operationsTimingOptions = operationsTimingOptions;
        this.collectionTypeOptions = getCollectionTypeOptions();

        this.propertyDisplayNames = {
            Name: 'Name',
            Description: 'Description',
            PlanType: 'Plan Type',
            Remediation: 'Remediation',
            Schedule: 'Schedule',
            IsActive: 'Active',
            IsPaused: 'Paused',
        };

        this.timeCaptions = getTimeOptions().reduce((mapping, time) => {
            mapping[time.value] = time.title;
            return mapping;
        }, {});

        this.view = 'default';

        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
    }

    viewChanged() {
        if (this.view === 'edit-points')
            this.planPointSelectorViewModel?.resetView(this.pointAttributes);
    }

    async loadTestMethods() {
        if (!this.plan?.labId) return;

        var lab = this.labs.find((l) => l.id === this.plan.labId);
        var testMethods = await this.testMethodService.getTestMethods(this.organizationId, {
            externalSource: lab.externalSource,
        });
        this.testMethods = testMethods.map((tm) => {
            tm.title = testMethodNameFormatting(
                tm,
                this.securityService.isImpersonating(),
                this.securityService.isWorkingUnderOrganization(),
            );
            return tm;
        });
        
    }

    handlePlanLabIdChanged(labId, oldLabId) {
        if (!this.plan) return;

        var lab = this.labs.find((l) => l.id === labId);
        var oldLab = this.labs.find((l) => l.id === oldLabId);

        if (lab?.externalSource !== oldLab?.externalSource) {
            this.loadTestMethods();
            this.plan.testMethods = [];
        }
    }

    handlePlanTypeLevelChanged() {
        if (!this.plan) return;

        this.collectionTypeOptions = planTypeIdToCollectionTypes(this.plan.planTypeId);
        this.plan.collectionType = null;
        this.updateSections();
    }

    async handleTaskDistributionMethodChanged() {
        if (!this.schedule) return;

        if (this.schedule.taskDistributionMethod == TaskDistributionMethod.WEEK_BASED)
            this.schedule.skipDays = 0;

        await this.validationController.validate();
    }

    updateSections() {
        let planTypesUsingSchedule = [PlanType.SCHEDULED];
        this.showScheduleSection = planTypesUsingSchedule.includes(this.plan.planTypeId);

        let planTypesUsingCollectionTimes = [
            PlanType.SCHEDULED,
            PlanType.RISK_BASED_PRIORITY,
            PlanType.ADHOC,
        ];
        this.showCollectionTimesSection = planTypesUsingCollectionTimes.includes(
            this.plan.planTypeId,
        );

        let planTypesUsingBlackout = [
            PlanType.SCHEDULED,
            PlanType.RISK_BASED_PRIORITY,
            PlanType.ADHOC,
        ];
        this.showBlackoutSection = planTypesUsingBlackout.includes(this.plan.planTypeId);
    }

    async handleApplyFilters() {
        await this.planPointSelectorViewModel.updateGridPoints();
    }

    handleClearFilters() {
        this.filters.reset();
        this.planPointSelectorViewModel.updateGridPoints();
    }

    activate(params) {
        this.applyFiltersSubscription = this.eventAggregator.subscribe(
            'filters.apply',
            this.handleApplyFilters,
        );
        this.clearFiltersSubscription = this.eventAggregator.subscribe(
            'filters.clear',
            this.handleClearFilters,
        );

        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.currentUser = this.securityService.getEffectiveUser();
                this.mapId = parseInt(params.mapId);
                this.remediationId = params.remediationId;

                var results = await Promise.all([
                    this.planService.getPlanTypes(),
                    params.id === 'create'
                        ? Promise.resolve({
                              mapId: this.mapId,
                              isActive: true,
                              remediationId: this.remediationId,
                              planType: PlanType.DISCRETIONARY,
                              points: [],
                          })
                        : this.planService.getPlan(parseInt(params.id)),
                    this.timeZoneService.getTimeZones(),
                ]);

                this.planTypes = results[0];

                this.plan = results[1];
                if (params.id !== 'create') this.mapId = this.plan.mapId;

                this.pausedPointCount = this.plan.points.filter(
                    (p) => p.isPausedPendingRemediation,
                ).length;
                this.isLocked = !this.plan.isActive;

                this.timeZones = results[2].map((tz) => ({ title: tz.displayName, value: tz.id }));

                const [remediations, points, map, pointAttributes, labs] = (await Promise.all([
                    this.remediationService.getRemediations({ mapId: this.plan.mapId }),
                    this.pointService.getPoints({ mapIds: [this.plan.mapId] }),
                    this.mapService.getMap(this.plan.mapId),
                    this.pointAttributeService.getPointAttributes(),
                    this.labService.getLabs(),
                ])) as any;

                this.organizationId = map.organizationId;
                this.remediations = remediations.filter((p) => p.isActive);
                this.points = points.filter((p) => p.isActive);
                this.labs = labs;

                await this.loadTestMethods();
                this.pointAttributes = pointAttributes;

                this.schedule = new UiSchedule();
                this.schedule.loadViewFrom(this.plan.schedule);
                this.canEditStartDate =
                    params.id === 'create' ||
                    (this.plan.planTypeId === PlanType.SCHEDULED &&
                        new Date(this.plan.schedule.startDateView).getTime() >
                            new Date().getTime());

                this.wasPausedAtLoad = this.plan.isPaused;

                if (this.plan.id) this.updateSections();

                this.setupValidation();

                this.planLabIdChangedSubscription = this.bindingEngine
                    .propertyObserver(this.plan, 'labId')
                    .subscribe(this.handlePlanLabIdChanged.bind(this));

                this.planTypeSubscription = this.bindingEngine
                    .propertyObserver(this.plan, 'planTypeId')
                    .subscribe(this.handlePlanTypeLevelChanged.bind(this));

                this.taskDistributionMethodSubscription = this.bindingEngine
                    .propertyObserver(this.schedule, 'taskDistributionMethod')
                    .subscribe(this.handleTaskDistributionMethodChanged.bind(this));
            } catch (error) {
                this.logger.error('Error loading plan', error, { planId: params.id });

                await (error.apiErrorCode === 1
                    ? this.dialogPresenter.showAlert(
                          'Error Loading Plan',
                          'The current plan doesn\'t exist.',
                      )
                    : this.dialogPresenter.showAlert(
                          'Error Loading Plan',
                          'An error occurred while loading the current plan. Please try again later.',
                      ));

                const mapId = this.plan ? this.plan.mapId || this.mapId : this.mapId;
                if (mapId) this.router.navigateToRoute('plan-list', { mapId });
            }
            this.pageContext.isLoading = false;
        })();
    }

    deactivate() {
        this.planLabIdChangedSubscription?.dispose();
        this.planTypeSubscription?.dispose();
        this.taskDistributionMethodSubscription?.dispose();

        this.applyFiltersSubscription?.dispose();
        this.clearFiltersSubscription?.dispose();
    }

    setupValidation() {
        ValidationRules.ensure('name')
            .required()
            .ensure('planTypeId')
            .required()
            .ensure('labId')
            .required()
            .ensure('collectionType')
            .required()
            .on(this.plan);

        ValidationRules.ensure('startDateView')
            .required()
            .satisfies((value, o: any) => {
                if (
                    this.plan.planTypeId === PlanType.SCHEDULED &&
                    o.taskDistributionMethod === TaskDistributionMethod.WEEK_BASED
                )
                    return moment(value).weekday() === 1; // must start on a Monday

                return true;
            })
            .ensure('endDateView')
            .satisfies((value, o: any) => {
                // End date is optional
                if (value === null || value === '' || value === undefined) return true;

                if (
                    this.plan.planTypeId === PlanType.SCHEDULED &&
                    value.setHours(0, 0, 0, 0) >=
                        new Date(this.schedule.startDate).setHours(0, 0, 0, 0)
                )
                    return moment(value).isValid();

                return false;
            })
            .ensure('startTime')
            .required()
            .ensure('endTime')
            .required()
            .satisfies((v, o: any) => {
                if (!v || !o.startTime) return false;
                const endTime = parseInt(v.replace(':', ''));
                const startTime = parseInt(o.startTime.replace(':', ''));
                return endTime > startTime;
            })
            .ensure('cycleDurationDays')
            .required()
            .satisfies((value, o: any) => {
                if (
                    this.plan.planTypeId === PlanType.SCHEDULED &&
                    o.taskDistributionMethod === TaskDistributionMethod.WEEK_BASED
                )
                    return value % 7 === 0 && value > 0;

                return value > 0;
            })
            .ensure('frequencyMinutes')
            .required()
            .ensure('skipDays')
            .required()
            .satisfies((value, o: any) => {
                let skipDays = typeof value === 'string' ? parseInt(value) : value;
                if (
                    this.plan.planTypeId === PlanType.SCHEDULED &&
                    o.taskDistributionMethod === TaskDistributionMethod.WEEK_BASED
                )
                    return skipDays === 0;

                return skipDays >= 0;
            })
            .ensure('targetCollectionTimes')
            .satisfies((targetCollectionTimes) => {
                return !!targetCollectionTimes.length;
            })
            .on(this.schedule);
    }

    handleFormChange() {
        this.formChanged = true;
    }

    selectedTargetCollectionTimeChanged() {
        if (!this.selectedTargetCollectionTime) return;

        if (!this.schedule.targetCollectionTimes) this.schedule.targetCollectionTimes = [];

        if (
            this.schedule.targetCollectionTimes.find(
                (t) => t.time === this.selectedTargetCollectionTime,
            )
        ) {
            setTimeout(() => {
                this.selectedTargetCollectionTime = null;
            });
            return;
        }

        this.schedule.targetCollectionTimes.push({
            time: this.selectedTargetCollectionTime,
            operationsTiming: 'NotSpecified',
        });

        this.schedule.targetCollectionTimes.sort(function (b, a) {
            var bParts = b.time.split(':').map((n) => parseInt(n));
            var aParts = a.time.split(':').map((n) => parseInt(n));

            if (bParts[0] > aParts[0]) return 1;

            if (bParts[0] < aParts[0]) return -1;

            if (bParts[1] > aParts[1]) return 1;

            return -1;
        });

        setTimeout(() => {
            this.selectedTargetCollectionTime = null;
        });
    }

    removeTargetCollectionTime(targetCollectionTimeToRemove) {
        this.schedule.targetCollectionTimes.splice(
            this.schedule.targetCollectionTimes.indexOf(targetCollectionTimeToRemove),
            1,
        );
    }

    blackoutDateChanged() {
        if (!this.blackoutDate) return;

        let blackoutDate = moment
            .utc([
                this.blackoutDate.getFullYear(),
                this.blackoutDate.getMonth(),
                this.blackoutDate.getDate(),
                0,
            ])
            .toDate();

        if (!this.schedule.blackoutDatesView.some((d) => d.getTime() === blackoutDate.getTime())) {
            const blackoutDates = this.schedule.blackoutDatesView;
            blackoutDates.push(blackoutDate);
            this.schedule.blackoutDatesView = blackoutDates;
            this.schedule.blackoutDatesView.sort((a, b) => a.getTime() - b.getTime());
        }

        setTimeout(() => {
            this.blackoutDate = null;
        });
    }

    removeBlackoutDate(dateToRemove) {
        this.schedule.blackoutDatesView.splice(
            this.schedule.blackoutDatesView.indexOf(dateToRemove),
            1,
        );
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('plan-list', { mapId: this.plan.mapId || this.mapId });
    }

    async save() {
        var aggregateResult = await this.validationController.validate();

        var targetCollectionTimesValidationResult = aggregateResult.results.find(
            (r) => r.propertyName === 'targetCollectionTimes',
        );
        this.targetCollectionTimesInvalid =
            targetCollectionTimesValidationResult && !targetCollectionTimesValidationResult.valid;

        if (!aggregateResult.valid) return;

        this.pageContext.isLoading = true;

        try {
            if (
                !this.plan.isActive &&
                !(await this.dialogPresenter.showConfirmation(
                    'Warning',
                    'Once a Plan is deactivated, it cannot be reactivated. Are you sure you want to deactivate this plan?',
                    'Yes',
                    'No',
                ))
            ) {
                this.pageContext.isLoading = false;
                return;
            }

            if (this.plan.planTypeId === PlanType.DISCRETIONARY) {
                delete this.plan.schedule;
                this.schedule = new UiSchedule();
            }

            this.plan.schedule = this.schedule.toEntity();
            this.isPaused = this.wasPausedAtLoad;
            let updatedPlan = (await this.planService.savePlan(this.plan)) as any;
            this.plan.id = updatedPlan.id;
            this.wasPausedAtLoad = this.plan.isPaused;
            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Plan saved successfully.');

            if (!this.plan.isActive)
                this.router.navigateToRoute('plan-list', { mapId: this.plan.mapId || this.mapId });
            else
                this.router.navigateToRoute('plan-detail', { id: this.plan.id }, { replace: true });
        } catch (error) {
            this.logger.error('Error saving plan.', error, { plan: this.plan });
            this.dialogPresenter.showAlert(
                'Error Saving Plan',
                this.getApiErrorMessage(error.apiErrorCode),
            );
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        if (errorCode === 800)
            return 'The specified plan name is already in use. Please change the plan name.';

        return 'An error occurred while saving the current plan. Please try again later.';
    }

    async loadRecordChanges() {
        try {
            this.pageContext.isLoading = true;
            this.recordChanges = await this.planService.getPlanRecordChanges(this.plan.id);
        } catch (error) {
            this.dialogPresenter.showAlert(
                'Error Loading Plan Audit',
                'An error occurred while loading the current plan audit. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }
}
