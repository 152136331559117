import AuthorizationCheckStep from './navigation/authorization-check-step';

export class UnauthenticatedApp {
    configureRouter(config, router) {
        this.router = router;
        this.router.pipelineProvider.removeStep('authorize', AuthorizationCheckStep);
        
        config.mapUnknownRoutes((instruction) => {
            return { route: '', redirect: 'login' };
        });

        config.map([
            { route: ['', 'login'], name: 'login', moduleId: PLATFORM.moduleName('security/login'), title: 'Login' },
            { route: 'forgot-password', name: 'forgot-password', moduleId: PLATFORM.moduleName('security/forgot-password'), title: 'Forgot Password' },
            { route: 'reset-password', name: 'reset-password', moduleId: PLATFORM.moduleName('security/reset-password'), title: 'Reset Password' },
            { route: 'privacy', name: 'privacy', moduleId: PLATFORM.moduleName('legal/privacy'), title: 'Privacy Policy' },
            { route: 'terms', name: 'terms', moduleId: PLATFORM.moduleName('legal/terms'), title: 'Terms of Use' },
        ]);
    }
}
