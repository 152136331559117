import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@autoinject
export default class DistributionGroupService {
    constructor(private httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getDistributionGroups(organizationId: number) {
        return this.httpClient.get(`distribution-groups?organizationId=${organizationId}`);
    }
}
