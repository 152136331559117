import { Subscription } from 'aurelia-event-aggregator';
import { autoinject, BindingEngine } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
} from 'aurelia-validation';
import FormViewModel from 'infrastructure/contracts/form-view-model';
import SelectorOption from 'infrastructure/selector-option';
import Lab from 'labs/lab';
import LabService from 'labs/lab-service';
import CollectionType, { getCapPlanCollectionTypeOptions } from 'location-testing/collection-type';
import Organization from 'organizations/organization';
import OrganizationService from 'organizations/organization-service';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import PageContext from '../../infrastructure/page-context';
import SecurityService from '../../security/security-service';
import operationsTimingOptions from '../operations-timing-options';
import CapPlanService from './cap-plan-service';
import CapPlanDetailRouteParams from './contracts/cap-plan-detail-route-params';
import FormSubmittedResult from './contracts/form-submitted-result';
import { UiCapPlan } from './ui-cap-plan';

@autoinject
export class CapPlanDetail implements FormViewModel {
    //activation
    organizationOptions: Readonly<SelectorOption[]>;
    labOptions: Readonly<SelectorOption[]>;
    collectionTypeOptions: Readonly<SelectorOption[]>;
    operationsTimingOptions: Readonly<SelectorOption[]>;
    validateOnBind: boolean;
    form: UiCapPlan;

    //Subscriptions
    formLabIdSubscription: Subscription;

    //other
    validationController: ValidationController;
    tenantId: number;
    labExternalSource: string;
    formChanged: boolean;

    //permissions and auditing
    canEditCapPlans: boolean;
    canViewAudit: boolean;
    recordChanges: any;
    propertyDisplayNames: any;

    constructor(
        private bindingEngine: BindingEngine,
        private capPlanService: CapPlanService,
        private readonly dialogPresenter: DialogPresenter,
        private labService: LabService,
        private organizationService: OrganizationService,
        private pageContext: PageContext,
        private readonly router: Router,
        private securityService: SecurityService,
        validationControllerFactory: ValidationControllerFactory,
    ) {
        this.collectionTypeOptions = getCapPlanCollectionTypeOptions();
        this.operationsTimingOptions = operationsTimingOptions;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.canEditCapPlans =
            this.securityService.hasPermission('EditCapPlans') &&
            !this.securityService.isImpersonating();
        this.canViewAudit =
            this.securityService.isCurrentUserInternal() && this.securityService.isImpersonating();

        this.propertyDisplayNames = {
            TestMethods: 'Test Methods',
            SampleDescriptionsJson: 'Sample Descriptions',
            TestingDayCount: 'Testing Day Count',
            IsActive: 'Active',
        };
    }

    handleFormChange(): void {
        this.formChanged = true;
    }

    labIdChanged() {
        const selectedLab = this.labOptions.find(
            (x: SelectorOption) => x.value === this.form.labId,
        );
        this.labExternalSource = selectedLab?.group;
    }

    handleSampleDescriptionRemoved(event: CustomEvent) {
        const { detail: index }: { detail: number } = event;
        this.form.sampleDescriptions.splice(index, 1);
    }

    handleFormSubmitting() {
        this.pageContext.isLoading = true;
    }

    handleFormSubmitted(event: CustomEvent) {
        const { detail }: { detail: FormSubmittedResult } = event;
        this.pageContext.isLoading = false;
        if (!detail.succeeded) {
            const { message } = detail;
            this.dialogPresenter.showAlert('Error Saving CAP Plan', message);
            return;
        }

        this.formChanged = false;
        this.pageContext.showSuccessOverlay('CAP plan saved successfully');
        this.router.navigateToRoute('cap-plan-list');
    }

    // LIFECYCLES
    async canActivate(params: CapPlanDetailRouteParams) {
        const { tenantId, id } = params;
        this.tenantId = tenantId;
        const [organizations, labs, capPlan] = await Promise.all([
            this.organizationService.getOrganizations(),
            this.labService.getLabs(),
            id ? this.capPlanService.getCapPlan(tenantId, id) : null,
        ]);

        this.organizationOptions = organizations.map((x: Organization) => ({
            title: x.name,
            value: x.id,
        }));
        this.labOptions = labs.map((x: Lab) => ({
            title: x.displayName,
            value: x.id,
            group: x.externalSource,
        }));
        this.collectionTypeOptions = getCapPlanCollectionTypeOptions();
        this.operationsTimingOptions = operationsTimingOptions;

        this.form = new UiCapPlan(this.tenantId);
        this.form.loadViewFrom(capPlan);
    }

    async bind(): Promise<void> {
        this.formLabIdSubscription = this.bindingEngine
            .propertyObserver(this.form, 'labId')
            .subscribe(this.labIdChanged.bind(this));

        ValidationRules.customRule(
            'collectionTypeRule',
            (v: CollectionType, uiCapPlan: UiCapPlan) =>
                (v && uiCapPlan.collectionType == CollectionType.Vector) ||
                uiCapPlan.collectionType === CollectionType.CorrectiveAction,
            '',
        );
        ValidationRules.ensure((uiCapPlan: UiCapPlan) => uiCapPlan.name)
            .required()
            .ensure((uiCapPlan: UiCapPlan) => uiCapPlan.organizationId)
            .satisfies((v) => v > 0)
            .ensure((uiCapPlan: UiCapPlan) => uiCapPlan.collectionType)
            .satisfiesRule('collectionTypeRule')
            .ensure((uiCapPlan: UiCapPlan) => uiCapPlan.testingDayCount)
            .satisfies((v) => v > 0)
            .ensure((uiCapPlan: UiCapPlan) => uiCapPlan.sampleDescriptions)
            .minItems(1)
            .ensure((uiCapPlan: UiCapPlan) => uiCapPlan.labId)
            .satisfies((id: number) => id > 0)
            .on(this.form);

        if (this.validateOnBind) {
            await this.validationController.validate();
        }
    }

    deactivate(): void {
        this.formLabIdSubscription?.dispose();
    }

    async loadRecordChanges() {
        try {
            this.pageContext.isLoading = true;
            this.recordChanges = await this.capPlanService.getCapPlanRecordChanges(
                this.tenantId,
                this.form.id,
            );
        } catch (error) {
            this.dialogPresenter.showAlert(
                'Error Loading CAP Plan Audit',
                'An error occurred while loading the current CAP plan audit. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }
}
