import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import SelectorOption from 'infrastructure/selector-option';
import PointTypeService from '../../point-types/point-type-service';

@autoinject
export class PointTypeSelectorCustomElement {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) pointType: string;
    @bindable organizationId: number;
    pointTypeOptions: SelectorOption[];

    constructor(private readonly pointTypeService: PointTypeService) {}

    private async getOrganizationPointTypes() {
        const organizationPointTypes = await this.pointTypeService.getOrganizationPointTypes(
            this.organizationId,
        );
        this.pointTypeOptions = organizationPointTypes.map((pt: any) => {
            return {
                title: pt,
                value: pt,
            } as SelectorOption;
        });
    }

    async organizationIdChanged() {
        await this.getOrganizationPointTypes();
    }

    async bind() {
        await this.getOrganizationPointTypes();
    }
}
