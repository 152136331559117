import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import PageContext from '../../infrastructure/page-context';
import Logger from '../../infrastructure/logger';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import CompareUtility from '../../infrastructure/compare-utility';
import SecurityService from '../../security/security-service';
import FaqGroupService from './faq-group-service';

@inject(Router, ValidationControllerFactory, PageContext, Logger, DialogPresenter, SecurityService, FaqGroupService)
export default class FaqGroupList {
    @observable filterText;

    constructor(router, validationControllerFactory, pageContext, logger, dialogPresenter, securityService, faqGroupService) {
        this.router = router;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.pageContext = pageContext;
        this.logger = logger;
        this.logger.name = 'faq-group-list';
        this.dialogPresenter = dialogPresenter;
        this.securityService = securityService;
        this.faqGroupService = faqGroupService;
        this.filterText = '';
        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template: '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate: '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: 'Ordinal',
                    field: 'ordinal',
                    template: '<input type="number" min="1" step="1" data-ordinal value.bind="data.ordinal & validate"><error-tooltip message="Ordinal is required and must be an integer greater than zero."></error-tooltip>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                    sort: 'asc'
                },
                { suppressMenu: true, headerName: 'Name', field: 'name', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                { suppressMenu: true, headerName: 'Description', field: 'description', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                {
                    suppressMenu: true,
                    headerName: 'Active',
                    field: 'isActive',
                    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button click.delegate="navigateToFaqGroupDetail(data.id)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button click.trigger="deleteFaqGroup(data)" disabled.bind="!data.isActive">
                            <i class="fa fa-trash-o"></i>
                        </button>
                        <button click.trigger="navigateToFaqs(data.id)">
                            <i class="fa fa-arrow-circle-right"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 150,
                    sortable: false,
                    suppressSizeToFit: true
                }
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.allSelected = false;
        this.selectedActiveFaqGroups = [];
    }

    navigateToFaqs(faqGroupId) {
        this.router.navigateToRoute('faq-list', { id: faqGroupId });
    }

    navigateToFaqGroupDetail(id) {
        this.router.navigateToRoute('faq-group-detail', { id });
    }

    setSelectedActiveFaqGroups() {
        this.selectedActiveFaqGroups = this.faqGroupsView.filter(faqGroup => faqGroup.isSelected && faqGroup.isActive);
    }

    isSelectedChanged() {
        this.allSelected = this.faqGroupsView.every(faqGroup => faqGroup.isSelected);
        this.setSelectedActiveFaqGroups();
    }

    allSelectedClicked() {
        if (this.faqGroupsView) {
            this.allSelected = !this.faqGroupsView.every(faqGroup => faqGroup.isSelected);

            for (let faqGroup of this.faqGroupsView)
                faqGroup.isSelected = this.allSelected;
        }

        this.setSelectedActiveFaqGroups();

        return true;
    }

    filterTextChanged() {
        this.updateFaqGroupsView();
    }

    updateFaqGroupsView() {
        if (!this.faqGroups)
            return;

        var lowerCasedFilterText = this.filterText.toLowerCase();
        this.faqGroupsView = this.faqGroups.filter(u =>
            (u.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
            (u.description || '').toLowerCase().indexOf(lowerCasedFilterText) > -1
        );
    }

    activate() {
        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.faqGroups = await this.faqGroupService.getFaqGroups();

                for (let faqGroup of this.faqGroups) {
                    // Store original ordinal to compare later during save.
                    faqGroup.orginalOrdinal = faqGroup.ordinal;

                    ValidationRules
                        .ensure('ordinal').displayName('Ordinal').required().matches(/^\d+$/)
                        .on(faqGroup);

                    this.validationController.addObject(faqGroup);
                }

                this.updateFaqGroupsView();
            } catch (error) {
                this.logger.error('Error while loading FAQ groups', error);
                this.dialogPresenter.showAlert(
                    'Error Loading FAQ Groups',
                    'An error occurred while loading the FAQ groups. Please try again later.');
            }

            this.pageContext.isLoading = false;
        })();
    }

    deleteFaqGroup(faqGroup) {
        this.dialogPresenter
            .showConfirmation('Delete FAQ Group', 'Are you sure you want to delete this FAQ group?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                this.pageContext.isLoading = true;
                this.faqGroupService
                    .deleteFaqGroups([faqGroup.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('FAQ group deleted successfully.');

                        faqGroup.isSelected = false;
                        faqGroup.isActive = false;

                        this.setSelectedActiveFaqGroups();
                    })
                    .catch(error => {
                        this.logger.error('Error deleting FAQ group.', error, { faqGroupId: faqGroup.id });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error);
                    });
            });
    }

    deleteSelectedFaqGroups() {
        var faqGroupsToDelete = this.faqGroupsView.filter(u => u.isSelected);
        if (!faqGroupsToDelete.length)
            return;

        this.dialogPresenter
            .showConfirmation('Delete Selected FAQ Groups', 'Are you sure you want to delete the selected FAQ groups?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                var faqGroupIds = faqGroupsToDelete.map(faqGroup => faqGroup.id);

                this.pageContext.isLoading = true;
                this.faqGroupService
                    .deleteFaqGroups(faqGroupIds)
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Selected FAQ groups deleted successfully.');

                        for (let faqGroup of faqGroupsToDelete) {
                            faqGroup.isSelected = false;
                            faqGroup.isActive = false;

                            this.setSelectedActiveFaqGroups();
                        }
                    })
                    .catch(error => {
                        this.logger.error('Error deleting FAQ groups.', error, { faqGroupIds });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, true);
                    });
            });
    }

    handleDeleteError(error, multipleFaqGroups) {
        this.dialogPresenter
            .showAlert(
            'Error Deleting FAQ Group' + (multipleFaqGroups ? 's' : ''),
            'An unexpected error occurred while trying to delete FAQ group' + (multipleFaqGroups ? 's' : '') + '. Please try again later.');
    }

    handleFormChange($event) {
        // Only set the 'form changed' flag if one of the ordinal inputs is modified. (Ignore all selection checkboxes.)
        if ($event.detail.formElement.hasAttribute('data-ordinal'))
            this.formChanged = true;
    }

    async applyOrdinals() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        // Copy FAQ groups in order to ensure the sequence of ordinals makes sense.
        var faqGroups = [...this.faqGroups];

        // Sort the list by ordinals and break ties by comparing the FAQ group names.
        faqGroups.sort((a, b) => {
            var value = a.ordinal - b.ordinal;
            if (value !== 0)
                return value;

            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });

        // Re-assign the ordinals based on the new order.
        for (var i = 0; i < faqGroups.length; i++)
            faqGroups[i].ordinal = i + 1;

        // Get a list of ID/ordinal mappings where the ordinal has changed from its original ordinal.
        var idOrdinalMap = faqGroups
            .filter(g => g.ordinal !== g.orginalOrdinal)
            .map(g => ({ id: g.id, ordinal: g.ordinal }));

        this.pageContext.isLoading = true;

        try {
            await this.faqGroupService.updateFaqGroupOrdinals(idOrdinalMap);
            this.formChanged = false;

            // Store original ordinal to compare later during next save.
            for (let faqGroup of this.faqGroups)
                faqGroup.orginalOrdinal = faqGroup.ordinal;
        } catch (error) {
            this.logger.error('Error while saving FAQ group ordinals', error);
            this.pageContext.isLoading = false;
            this.dialogPresenter.showAlert(
                'Error Saving FAQ Group Ordinals',
                'An error occurred while savig the FAQ group ordinals. Please try again later.');
        }

        // Re-sort data grid and view.
        this.faqGroupsView.sort((a, b) => a.ordinal - b.ordinal);
        this.faqGroupDataGridViewModel.gridApi.setSortModel([{ colId: 'ordinal', sort: 'asc' }])
        this.pageContext.isLoading = false;
    }
};
