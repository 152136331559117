import { FirstDataRenderedEvent } from 'ag-grid-community';
import { Grid, GridOptions } from 'ag-grid-enterprise/dist/ag-grid-enterprise';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { setGroupByColumnDefinitions } from 'infrastructure/ag-grid/ag-grouping-helper';
import { expandRowAtIndex } from 'infrastructure/ag-grid/ag-onfirstdatarendered-handler';
import SelectorOption from 'infrastructure/selector-option';
import ValidationErrorFormatter from 'infrastructure/validation/validation-error-formatter';
import TestMethodService from 'test-methods/test-method-service';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import SecurityService from '../../security/security-service';
import { ActiveTestMethodPlans } from './contracts/active-test-method-plans';
import { ReplaceTestMethodRequest } from './contracts/replace-test-method-request';
import { TestMethodReplacemantImpactCounts } from './contracts/test-method-replacement-impact-counts';
import {
    generatePlanManagementColumnDefs,
    generatePlanManagementGridOptions,
} from './plan-management-grid';
import PlanManagementService from './plan-management-service';
import {
    isImpersonatingUserOrOrg,
    testMethodPopUpManagementNameFormatting,
} from 'test-methods/test-method-helper';

const planManagementViewBySelectorOptions: SelectorOption[] = [
    { title: 'Test Method Name', value: 'testMethodName' },
    { title: 'Organization', value: 'organizationName' },
];
const replacementDescription = (impactCounts: TestMethodReplacemantImpactCounts): string => {
    return `This action will impact
    ${impactCounts.organizationCount} ${
        impactCounts.organizationCount === 1 ? 'organization' : 'organizations'
    }, 
    ${impactCounts.planCount} ${impactCounts.planCount === 1 ? 'plan' : 'plans'} and 
    ${impactCounts.unassignedTaskCount} unassigned ${
        impactCounts.unassignedTaskCount === 1 ? 'task' : 'tasks'
    }.  
    The current test method is also related to 
    ${impactCounts.specificationCount} ${
        impactCounts.specificationCount === 1 ? 'specification' : 'specifications'
    }.`;
};

@autoinject
export class PlanManagementList {
    @observable filterText: string;
    viewBySelectorOptions: SelectorOption[];

    private _selectedViewByOption: SelectorOption;

    gridOptions: GridOptions;
    allSelected: boolean;
    testMethodPlans: ActiveTestMethodPlans[];
    canEditPlanManagement: boolean;
    planManagementGrid: any;

    constructor(
        private dialogPresenter: DialogPresenter,
        private logger: Logger,
        private pageContext: PageContext,
        private router: Router,
        private securityService: SecurityService,
        private planManagementService: PlanManagementService,
        private testMethodService: TestMethodService,
    ) {
        this.logger = logger;
        this.router = router;

        this.viewBySelectorOptions = planManagementViewBySelectorOptions;
        this._selectedViewByOption = this.viewBySelectorOptions[0];

        this.canEditPlanManagement =
            this.securityService.hasPermission('EditPlanManagement') &&
            !this.securityService.isImpersonating();
    }

    groupByColumnDefinitions() {
        setGroupByColumnDefinitions(
            this.gridOptions,
            this._selectedViewByOption,
            generatePlanManagementColumnDefs(
                this.canEditPlanManagement,
                this.replace.bind(this),
                this._selectedViewByOption,
                isImpersonatingUserOrOrg(
                    this.securityService.isImpersonating(),
                    this.securityService.isWorkingUnderOrganization(),
                ),
            ),
            null,
        );
    }

    public get selectedViewByOption(): SelectorOption {
        return this._selectedViewByOption;
    }

    public set selectedViewByOption(selectedOption: SelectorOption) {
        this._selectedViewByOption = selectedOption;
        this.groupByColumnDefinitions();
    }

    filterTextChanged(newValue: string) {
        this.gridOptions.api.setQuickFilter(newValue);
    }

    async replace(data: ActiveTestMethodPlans) {
        this.pageContext.isLoading = true;
        const { testMethodId, testMethodName, testMethodBillCode } = data;
        const impactCounts = await this.planManagementService.getTestMethodReplacementImpactCounts(
            testMethodId,
            data.organizationId,
        );
        const testMethods = await this.testMethodService.getTestMethods(data.organizationId, {
            externalSource: 'LabVantage',
        });
        const replacementTestMethods = testMethods
            .filter((tm) => tm.id !== testMethodId)
            .map((tm) => ({
                ...tm,
                name: testMethodPopUpManagementNameFormatting(
                    tm,
                    this.securityService.isImpersonating(),
                    this.securityService.isWorkingUnderOrganization(),
                ),
            }));

        const { output, wasCancelled } = await this.dialogPresenter.showNameValueItemSelector(
            `Replace: ${testMethodName} - (${testMethodBillCode})`,
            replacementDescription(impactCounts),
            'Select replacement test method',
            replacementTestMethods,
            'name',
            'id',
        );
        if (wasCancelled) {
            this.pageContext.isLoading = false;
            return;
        }

        var replaceTestMethodRequest: ReplaceTestMethodRequest = {
            organizationId: data.organizationId,
            testMethodId,
            replacementTestMethodId: output.id,
        };

        try {
            this.pageContext.isLoading = true;
            await this.planManagementService.replaceTestMethod(replaceTestMethodRequest);
            await this.loadActiveTestMethods();
        } catch (e) {
            this.logger.error('Error replacing test method.', e);
            const errorMessage = ValidationErrorFormatter.format(
                'An error occurred while replacing a test method. If the problem persists, please contact customer service.',
                e,
            );
            this.dialogPresenter.showAlert('Error Replacing Test Method', errorMessage);
            // if an error happens, the spinner neeed to fade
            this.pageContext.isLoading = false;
        }

        this.pageContext.isLoading = false;
    }

    async loadActiveTestMethods() {
        this.testMethodPlans = await this.planManagementService.fetchActiveTestMethodPlans();
        this.gridOptions?.api.setRowData(this.testMethodPlans);
    }

    async canActivate() {
        await this.loadActiveTestMethods();
    }

    bind() {
        this.gridOptions = generatePlanManagementGridOptions(
            this.canEditPlanManagement,
            this.replace.bind(this),
            this._selectedViewByOption,
            this.securityService.isImpersonating(),
        );
        this.gridOptions.rowData = this.testMethodPlans;
        this.gridOptions.onFirstDataRendered = (params: FirstDataRenderedEvent) =>
            expandRowAtIndex(params, 1);
        new Grid(this.planManagementGrid, this.gridOptions);
        this.groupByColumnDefinitions();
    }
}
