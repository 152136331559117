export default class SrtingUtility {
    /**
     *
     * @param {Number} [length='random between 5 and 10'] - Length of string to generate.
     * @returns {string} - A random string with the length specified.
     */
    static getRandomString(length = Math.floor(Math.random() * 6) + 5) {
        var value = '';
        var chars = 'abcdefghijklmnopqrstuvwxyz0123456789';

        for (var i = 0; i < length; i++)
            value += chars[Math.floor(Math.random() * chars.length)];

        return value;
    }
};
