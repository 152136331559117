import { LocalStorageConstants } from 'infrastructure/constants/localstorage.constants';
import AgPreferences from './ag-preferences';

export const agGridGetPreferences = <T extends AgPreferences>(preferences: T): T => {
    const agPreferences =
        JSON.parse(localStorage.getItem(LocalStorageConstants.AG_PREFERENCES)) || {};
    const hasTPreferences = Object.prototype.hasOwnProperty.call(
        agPreferences,
        preferences.gridName,
    );

    if (!hasTPreferences) {
        agPreferences[preferences.gridName] = preferences;
        localStorage.setItem(LocalStorageConstants.AG_PREFERENCES, JSON.stringify(agPreferences));
    }

    return agPreferences[preferences.gridName];
};

export const agGridSetPreferences = <T extends AgPreferences>(preferences: T): T => {
    const agPreferences =
        JSON.parse(localStorage.getItem(LocalStorageConstants.AG_PREFERENCES)) || {};

    agPreferences[preferences.gridName] = preferences;
    localStorage.setItem(LocalStorageConstants.AG_PREFERENCES, JSON.stringify(agPreferences));

    return agPreferences[preferences.gridName];
};
