import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import PageContext from '../../infrastructure/page-context';
import Logger from '../../infrastructure/logger';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import CompareUtility from '../../infrastructure/compare-utility';
import SecurityService from '../../security/security-service';
import FaqService from './faq-service';

@inject(Router, ValidationControllerFactory, PageContext, Logger, DialogPresenter, SecurityService, FaqService)
export default class FaqList {
    @observable filterText;

    constructor(router, validationControllerFactory, pageContext, logger, dialogPresenter, securityService, faqService) {
        this.router = router;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.pageContext = pageContext;
        this.logger = logger;
        this.logger.name = 'faq-list';
        this.dialogPresenter = dialogPresenter;
        this.securityService = securityService;
        this.faqService = faqService;
        this.filterText = '';
        
        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template: '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate: '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: 'Ordinal',
                    field: 'ordinal',
                    template: '<input type="number" min="1" step="1" data-ordinal value.bind="data.ordinal & validate"><error-tooltip message="Ordinal is required and must be an integer greater than zero."></error-tooltip>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                    sort: 'asc'
                },
                { suppressMenu: true, headerName: 'Question', field: 'question', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                { suppressMenu: true, headerName: 'Answer', field: 'answer', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                {
                    suppressMenu: true,
                    headerName: 'Active',
                    field: 'isActive',
                    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                    <button click.delegate="navigateToFaqDetail(data.id)">
                        <i class="fa fa-pencil-square-o"></i>
                    </button>
                    <button click.trigger="deleteFaq(data)" disabled.bind="!data.isActive">
                        <i class="fa fa-trash-o"></i>
                    </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 150,
                    sortable: false,
                    suppressSizeToFit: true
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.allSelected = false;
        this.selectedActiveFaqs = [];
    }

    navigateToFaqDetail(id) {
        this.router.navigateToRoute('faq-detail', { id });
    }

    setSelectedActiveFaqs() {
        this.selectedActiveFaqs = this.faqsView.filter(faq => faq.isSelected && faq.isActive);
    }

    isSelectedChanged() {
        this.allSelected = this.faqsView.every(faq => faq.isSelected);
        this.setSelectedActiveFaqs();
    }

    allSelectedClicked() {
        if (this.faqsView) {
            this.allSelected = !this.faqsView.every(faq => faq.isSelected);

            for (let faq of this.faqsView)
                faq.isSelected = this.allSelected;
        }

        this.setSelectedActiveFaqs();

        return true;
    }

    activate(params) {
        this.faqGroupId = params.id;
        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.faqs = await this.faqService.getFaqs(params.id);

                for (let faq of this.faqs) {
                    // Store original ordinal to compare later during save.
                    faq.orginalOrdinal = faq.ordinal;

                    ValidationRules
                        .ensure('ordinal').displayName('Ordinal').required().matches(/^\d+$/)
                        .on(faq);

                    this.validationController.addObject(faq);
                }

                this.updateView();
            } catch (error) {
                this.logger.error('Error loading FAQs.', error);
                this.dialogPresenter.showAlert(
                    'Error Loading FAQs',
                    'An error occurred while loading the FAQs. Please try again later.');
            }

            this.pageContext.isLoading = false;
        })();
    }

    filterTextChanged() {
        this.updateView();
    }

    updateView() {
        if (!this.faqs)
            return;

        var lowerCasedFilterText = this.filterText.toLowerCase();
        this.faqsView = this.faqs.filter(faq =>
            (faq.question || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
            (faq.answer || '').toLowerCase().indexOf(lowerCasedFilterText) > -1
        );
    }

    deleteFaq(faq) {
        this.dialogPresenter
            .showConfirmation('Delete FAQ', 'Are you sure you want to delete this FAQ?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                this.pageContext.isLoading = true;
                this.faqService
                    .deleteFaqs([faq.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('FAQ deleted successfully.');

                        faq.isSelected = false;
                        faq.isActive = false;

                        this.setSelectedActiveFaqs();
                    })
                    .catch(error => {
                        this.logger.error('Error deleting FAQ.', error, { faqId: faq.id });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error);
                    });
            });
    }

    deleteSelectedFaqs() {
        var faqsToDelete = this.faqsView.filter(u => u.isSelected);
        if (!faqsToDelete.length)
            return;

        this.dialogPresenter
            .showConfirmation('Delete Selected FAQs', 'Are you sure you want to delete the selected FAQs?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                var faqIds = faqsToDelete.map(faq => faq.id);

                this.pageContext.isLoading = true;
                this.faqService
                    .deleteFaqs(faqIds)
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Selected FAQs deleted successfully.');

                        for (let faq of faqsToDelete) {
                            faq.isSelected = false;
                            faq.isActive = false;

                            this.setSelectedActiveFaqs();
                        }
                    })
                    .catch(error => {
                        this.logger.error('Error deleting FAQs.', error, { faqIds });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, true);
                    });
            });
    }

    handleDeleteError(error, multipleFaqs) {
        this.dialogPresenter
            .showAlert(
            'Error Deleting FAQ' + (multipleFaqs ? 's' : ''),
            'An unexpected error occurred while trying to delete FAQ' + (multipleFaqs ? 's' : '') + '. Please try again later.');
    }

    handleFormChange($event) {
        // Only set the 'form changed' flag if one of the ordinal inputs is modified. (Ignore all selection checkboxes.)
        if ($event.detail.formElement.hasAttribute('data-ordinal'))
            this.formChanged = true;
    }

    async applyOrdinals() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        // Copy FAQs in order to ensure the sequence of ordinals makes sense.
        var faqs = [...this.faqs];

        // Sort the list by ordinals and break ties by comparing the FAQ question text.
        faqs.sort((a, b) => {
            var value = a.ordinal - b.ordinal;
            if (value !== 0)
                return value;

            return a.question.toLowerCase().localeCompare(b.question.toLowerCase());
        });

        // Re-assign the ordinals based on the new order.
        for (var i = 0; i < faqs.length; i++)
            faqs[i].ordinal = i + 1;

        // Get a list of ID/ordinal mappings where the ordinal has changed from its original ordinal.
        var idOrdinalMap = faqs
            .filter(g => g.ordinal !== g.orginalOrdinal)
            .map(g => ({ id: g.id, ordinal: g.ordinal }));

        this.pageContext.isLoading = true;

        try {
            await this.faqService.updateFaqOrdinals(idOrdinalMap);
            this.formChanged = false;

            // Store original ordinal to compare later during next save.
            for (let faq of this.faqs)
                faq.orginalOrdinal = faq.ordinal;
        } catch (error) {
            this.logger.error('Error while saving FAQ ordinals', error);
            this.pageContext.isLoading = false;
            this.dialogPresenter.showAlert(
                'Error Saving FAQ Ordinals',
                'An error occurred while savig the FAQ ordinals. Please try again later.');
        }

        // Re-sort data grid and view.
        this.faqsView.sort((a, b) => a.ordinal - b.ordinal);
        this.faqDataGridViewModel.gridApi.setSortModel([{ colId: 'ordinal', sort: 'asc' }])
        this.pageContext.isLoading = false;
    }
};
