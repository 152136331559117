import { inject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import NavigationAuthorizer from '../security/navigation/navigation-authorizer';

@inject(Router, NavigationAuthorizer)
export class HelpNavigationMenu {
    @bindable colSize;

    constructor(router, navigationAuthorizer) {
        this.router = router;
        this.navigationAuthorizer = navigationAuthorizer;
    }

    attached() {
        (async () => {
            let navItems = [];

            for (let item of this.router.navigation)
                if (item.config.group === 'help' && await this.navigationAuthorizer.hasAccess(item.config))
                    navItems.push(item);

            this.navItems = navItems;
        })();
    }
};
