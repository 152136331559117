import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import TestMethod from 'test-methods/test-method';
import OrganizationPlan from './organization-plan';
import PlanCoverage from './plan-coverage';
import PlanType from './plan-type';

@autoinject
export default class PlanService {
    constructor(private httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getPlans(mapId: number[]) {
        let queryParameters = {
            mapId: mapId,
        };

        var query = buildQueryString(queryParameters, true);

        return this.httpClient.get(`location-testing/plans${query ? `?${query}` : ''}`);
    }

    getOrganizationPlans(organizationId: number): Promise<OrganizationPlan[]> {
        return this.httpClient.get(
            `location-testing/plans/organization/${organizationId}`,
        ) as unknown as Promise<OrganizationPlan[]>;
    }

    getPlansByPoint(pointId, view) {
        return this.httpClient.get(`location-testing/plans?pointId=${pointId}&view=${view}`);
    }

    getPlanTypes() {
        let planTypes = [
            { id: PlanType.DISCRETIONARY, token: 'Discretionary' },
            { id: PlanType.SCHEDULED, token: 'Scheduled' },
            { id: PlanType.RISK_BASED_PRIORITY, token: 'Risk Based Priority' },
            { id: PlanType.ADHOC, token: 'Adhoc' },
        ];
        return Promise.resolve(planTypes);
    }

    getPlan(id): Promise<any> {
        return this.httpClient.get(`location-testing/plans/${id}`);
    }

    deletePlans(ids) {
        var query = ids.map((id) => `id=${id}`).join('&');
        return this.httpClient.delete(`location-testing/plans?${query}`);
    }

    savePlan(plan) {
        if (!plan.id) return this.httpClient.post('location-testing/plans', plan);

        return this.httpClient.put(`location-testing/plans/${plan.id}`, plan);
    }

    getPlanRecordChanges(id) {
        return this.httpClient.get(`location-testing/plans/${id}/record-changes`);
    }

    getTestMethods(mapIds: number[]): TestMethod[] {
        let queryParameters = {
            mapIds: mapIds,
        };

        var query = buildQueryString(queryParameters, true);

        return this.httpClient.get(
            `location-testing/plans/all/test-methods${query ? `?${query}` : ''}`,
        ) as unknown as TestMethod[];
    }

    getPlanCoverage(
        id: number,
        taskTargetDateTimeStart: string,
        taskTargetDateTimeEnd: string,
    ): Promise<PlanCoverage> {
        const params = {
            taskTargetDateTimeStart,
            taskTargetDateTimeEnd,
        };
        const query = buildQueryString(params);

        return this.httpClient.get(
            `location-testing/plans/${id}/coverage?${query}`,
        ) as unknown as Promise<PlanCoverage>;
    }
    exportPlanCoveragePoints(
        id: number,
        coverageType: string,
        taskTargetDateTimeStart: string,
        taskTargetDateTimeEnd: string,
    ): string {
        const queryParams = {
            coverageType,
            taskTargetDateTimeStart,
            taskTargetDateTimeEnd,
            format: 'CSV',
        };
        const queryString = buildQueryString(queryParams, true);

        return `api/location-testing/plans/${id}/points-export?${queryString}`;
    }
}
