import moment from 'moment';
import { inject, observable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

@inject(DialogController)
export default class TaskStatusSelectorDialog {
    @observable selectedItem;
    @observable collectedDate;
    @observable collectedTime;
    @observable comments;

    statuses: any[];
    collectedDateTime: any;
    okEnabled: boolean;

    model: any;

    constructor(private controller: DialogController) {
        this.controller = controller;
        this.statuses = [
            { title: 'Not Started', value: 'NotStarted' },
            { title: 'In Progress', value: 'InProgress' },
            { title: 'Completed', value: 'Completed' },
            { title: 'Did Not Complete', value: 'DidNotComplete' },
        ];

        this.selectedItem = null;
        this.collectedDateTime = null;
        this.okEnabled = false;
    }

    activate(model) {
        this.model = model;

        if (this.model.tasks && this.model.tasks.some(x => x.assignedUserEmail === null)) {
            let disabledMessage = 'This option is disabled because some tasks have not been previously assigned.';
            let completedStatusOption = this.statuses.find(s => s.value === 'Completed');
            completedStatusOption.disabled = true;
            completedStatusOption.tooltip = disabledMessage;

            let inProgressStatusOption = this.statuses.find(s => s.value === 'InProgress');
            inProgressStatusOption.disabled = true;
            inProgressStatusOption.tooltip = disabledMessage;
        }
    }

    selectedItemChanged() {
        if (!this.selectedItem)
            return;

        this.toggleOkEnabled();
    }

    collectedDateChanged() {
        this.updateCollectedDateTime();
        this.toggleOkEnabled();
    }

    collectedTimeChanged() {
        this.updateCollectedDateTime();
        this.toggleOkEnabled();
    }

    commentsChanged() {
        this.toggleOkEnabled();
    }

    updateCollectedDateTime() {
        this.collectedDateTime = this.collectedDate && this.collectedTime ?
            new Date(`${moment(this.collectedDate).format('YYYY-MM-DD')}T${this.collectedTime}${moment().format('Z')}`).toISOString() :
            null;
    }

    toggleOkEnabled() {
        let status = this.selectedItem?.value;

        this.okEnabled = (
            status === 'Completed' && this.collectedDate && this.collectedTime ||
            status === 'DidNotComplete' && this.comments ||
            status === 'NotStarted' ||
            status === 'InProgress'
        );
    }

    ok() {
        this.controller.ok({
            statusName: this.selectedItem.title,
            statusId: this.selectedItem.value,
            collectedDateTime: this.selectedItem.value === 'Completed' ?
                this.collectedDateTime :
                null,
            comments: this.comments || null
        });
    }

    cancel() {
        this.controller.cancel();
    }
}
