import { ValidationRules } from 'aurelia-validation';
import moment from 'moment';
import { FilterOperator } from './filter-operator';

interface Item {
    title: string;
    value: boolean;
}

export class DateFilter {
    operators: FilterOperator[];
    items: Item[];
    filterField: any;

    constructor() {
        this.operators = [
            { title: 'is', value: 'equals' },
            { title: 'is between', value: 'between' },
            { title: 'is today', value: 'is-today' },
            { title: 'is this week', value: 'is-this-week' },
            { title: 'is this month', value: 'is-this-month' },
        ];

        this.items = [
            { title: 'Yes', value: true },
            { title: 'No', value: false },
        ];
    }

    get isFixedRange(): boolean {
        return (
            this.filterField.operator &&
            (this.filterField.operator === 'is-today' ||
                this.filterField.operator === 'is-this-week' ||
                this.filterField.operator === 'is-this-month')
        );
    }
    getFixedMinValue(): string {
        switch (this.filterField.operator) {
            case 'is-this-week':
                return moment().startOf('week').add(1, 'days').format('L');
            case 'is-this-month':
                return moment().startOf('month').format('L');
            default:
                return moment().format('L');
        }
    }
    getFixedMaxValue(): string {
        switch (this.filterField.operator) {
            case 'is-this-week':
                return moment().startOf('week').add(8, 'days').format('L');
            case 'is-this-month':
                return moment().startOf('month').add(1, 'months').format('L');
            default:
                return moment().add(1, 'days').format('L');
        }
    }

    get minValue() {
        return this.filterField && this.isFixedRange
            ? this.getFixedMinValue()
            : this.filterField.values[0];
    }
    set minValue(value) {
        if (this.filterField)
            this.filterField.values[0] = !value
                ? value
                : moment(value).startOf('day').format('M/D/YYYY');
    }

    get maxValue() {
        return this.filterField && this.isFixedRange
            ? this.getFixedMaxValue()
            : this.filterField.values[1];
    }
    set maxValue(value) {
        if (this.filterField)
            this.filterField.values[1] = !value
                ? value
                : moment(value).startOf('day').format('M/D/YYYY');
    }

    activate(filterField) {
        this.filterField = filterField;

        // When switching field types, the operator may not be available. If not, default to the first one.
        if (!this.operators.some((o) => o.value === this.filterField.operator))
            this.filterField.operator = this.operators[0].value;

        ValidationRules.ensure('minValue')
            .required()
            .satisfies(() => new Date(this.minValue) < new Date(this.maxValue))
            .when(() => this.maxValue)
            .ensure('maxValue')
            .required()
            .satisfies(() => new Date(this.minValue) < new Date(this.maxValue))
            .when(() => this.minValue)
            .on(this);
    }

    detached() {
        ValidationRules.off(this);
    }
}
