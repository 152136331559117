import SelectorOption from 'infrastructure/selector-option';
export interface SubscriptionFrequency {
    minDeliveryDay: number;
    maxDeliveryDay: number;
    deliveryDayValidationErrorMessage: string;
    subscriptionFrequencyTypeValidationErrorMessage: string;
}

export enum SubscriptionFrequencyType {
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
}

export const subscriptionFrequencyTypes: Record<SubscriptionFrequencyType, SelectorOption> = {
    1: { value: SubscriptionFrequencyType.Daily, title: 'Daily' },
    2: { value: SubscriptionFrequencyType.Weekly, title: 'Weekly' },
    3: { value: SubscriptionFrequencyType.Monthly, title: 'Monthly' },
};

export function getSubscriptionFrequencyTypes(): SelectorOption[] {
    return [
        subscriptionFrequencyTypes[1],
        subscriptionFrequencyTypes[2],
        subscriptionFrequencyTypes[3],
    ];
}

export const subscriptionFrequency: Record<SubscriptionFrequencyType, SubscriptionFrequency> = {
    1: {
        minDeliveryDay: 0,
        maxDeliveryDay: 0,
        deliveryDayValidationErrorMessage:
            'The delivery day for a daily subscription must be zero(0).',
        subscriptionFrequencyTypeValidationErrorMessage:
            'A daily subscription must have a delivery day of zero(0).',
    },
    2: {
        minDeliveryDay: 1,
        maxDeliveryDay: 7,
        deliveryDayValidationErrorMessage:
            'The delivery day for a weekly subscription must be between 1 and 7.',
        subscriptionFrequencyTypeValidationErrorMessage:
            'A weekly subscription must have a delivery day between 1 and 7.',
    },
    3: {
        minDeliveryDay: 1,
        maxDeliveryDay: 31,
        deliveryDayValidationErrorMessage:
            'The delivery day for a monthly subscription must be between 1 and 31.',
        subscriptionFrequencyTypeValidationErrorMessage:
            'A monthly subscription must have a delivery day between 1 and 31.',
    },
};
