import { inject } from 'aurelia-framework';
import $ from 'jquery';

@inject(Element)
export class DataDropdownCustomAttribute {
    constructor(element) {
        this.element = element;
    }

    attached() {
        this.$dropdown = $(this.element);
        this.$dropdown.foundation();
    }

    detached() {
        if (!this.$dropdown)
            return;
            
        var dropdown =this.$dropdown.data('zfPlugin')
        if (dropdown)
            dropdown.destroy();

        this.$dropdown = null;
    }

    open(anchorElement) {
        if (!this.$dropdown)
            return;

        var dropdown = this.$dropdown.data('zfPlugin');
        if (dropdown) {
            var $anchor = $(anchorElement);
            
            dropdown.$anchors = $anchor;
            dropdown.$currentAnchor = $anchor;
            dropdown.open();
        }
    }

    get isOpen() {
        if (!this.$dropdown)
            return false;
        else
            return this.$dropdown.hasClass('is-open');
    }

    close() {
        var dropdown = this.$dropdown.data('zfPlugin');
        if (dropdown) 
            dropdown.close();
    }
};
