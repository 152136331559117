import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class MapAttributeService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getMapAttributes() {
        return this.httpClient.get(`location-testing/map-attributes`);
    }

    getMapAttributeValues(name) {
        return this.httpClient.get(`location-testing/map-attributes/${name}/values`);
    }
}
