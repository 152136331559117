import { inject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

@inject(EventAggregator)
export class FilterButtons {
    constructor(eventAggregator) {
        this.eventAggregator = eventAggregator;
    }

    applyFilters() {
        this.eventAggregator.publish('filters.apply');
    }

    clearFilters() {
        this.eventAggregator.publish('filters.clear');
    }
};
