import { inject } from 'aurelia-framework';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import Logger from '../infrastructure/logger';
import SecurityService from './security-service';

@inject(ValidationControllerFactory, Logger, SecurityService)
export class ForgotPassword {
    constructor(validationControllerFactory, logger, securityService) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        ValidationRules
            .ensure('username')
            .displayName('Username')
            .required()
            .on(this);

        this.logger = logger;
        this.logger.name = 'forgot-password';

        this.securityService = securityService;
    }

    initResetPassword() {
        this.errorMessage = '';
        this.successMessage = '';

        this.requestIsPending = true;

        this.validationController
            .validate()
            .then(aggregateResult => {
                if (!aggregateResult.valid) {
                    this.requestIsPending = false;
                    return;
                }

                this.securityService
                    .initResetPassword(this.username)
                    .then((result) => {
                        this.successMessage = 'Password reset initiated. If the email you specified matches a valid account, you will receive an email with instructions on setting a new password.';
                    })
                    .catch(error => {
                        this.logger.error('Error initializing password reset.', error, { email: this.username });
                        switch (error.apiErrorCode) {
                            case 212:
                                this.errorMessage = 'You must specify an email address.';
                                break;
                            case 213:
                                this.errorMessage = 'Passwords can only be reset for external users.';
                                break;
                            default:
                                this.errorMessage = 'An unexpected error occurred while attempting to reset your password. Please try again later.';
                        }

                        this.requestIsPending = false;
                    });
            });
    }
}
