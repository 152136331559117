import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import RecordStatus from 'record-status';

@autoinject

export default class RemediationService {
    constructor(private httpClient: HttpClient) {
    }

    getRemediations(remediationFilters, view?: string, recordStatus?: RecordStatus) {
        var queryParameters = remediationFilters;

        if (view)
            queryParameters.view = view;

        if (recordStatus)
            queryParameters.recordStatus = recordStatus;

        var query = buildQueryString(queryParameters, true);

        return this.httpClient.get(`location-testing/remediations${(query ? `?${query}` : '')}`);
    }

    getRemediation(id) {
        return this.httpClient.get(`location-testing/remediations/${id}`);
    }

    deleteRemediations(ids) {
        var query = ids.map(id => `id=${id}`).join('&');
        return this.httpClient.delete(`location-testing/remediations?${query}`);
    }

    getRemediationCounts(organizationIds, assignedToMe) {
        var query = buildQueryString({
            // Map organization IDs to multiple 'organizationId' query string variables.
            organizationId: organizationIds,
            assignedToMe: !!assignedToMe
        },
            true);
        return this.httpClient.get(`location-testing/remediation-counts/${(query ? `?${query}` : '')}`);
    }

    saveRemediation(remediation) {
        if (!remediation.id)
            return this.httpClient.post('location-testing/remediations', remediation);

        return this.httpClient.put(`location-testing/remediations/${remediation.id}`, remediation);
    }

    getRemediationStatuses() {
        return [
            { id: 1, code: 'NotStarted', name: 'Not Started' },
            { id: 2, code: 'InProgress', name: 'In Progress' },
            { id: 3, code: 'Completed', name: 'Completed' },
            { id: 4, code: 'Reviewed', name: 'Reviewed' },
            { id: 5, code: 'Rejected', name: 'Rejected' }
        ];
    }

    saveRemediationFile(remediationFileDetails, remediationFile) {

        if (!remediationFileDetails.remediationId)
            throw 'Remediation must be saved before creating a file.';

        const formData = new FormData();
        formData.append('remediationId', remediationFileDetails.remediationId);
        formData.append('isPostRemediation', remediationFileDetails.isPostRemediation);

        if (remediationFile)
            formData.append('file', remediationFile);

        return this
            .httpClient
            .createRequest(`location-testing/remediations/${remediationFileDetails.remediationId}/files`)
            .asPost()
            .withContent(formData)
            .send();
    }

    deleteRemediationFile(id, remediationFileId) {
        return this.httpClient.delete(`location-testing/remediations/${id}/files/${remediationFileId}`);
    }

    getRemediationRecordChanges(id) {
        return this.httpClient.get(`location-testing/remediations/${id}/record-changes`);
    }
}
