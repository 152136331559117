import { inject, TargetInstruction, noView, processContent } from 'aurelia-framework';

function parseElement(compiler, resources, element, instruction) {
    var html = element.innerHTML;
    if (html !== '')
        instruction.template = html;

    element.innerHTML = '';
}

@inject(TargetInstruction)
@noView
@processContent(parseElement)
export class Select2ItemTemplate {
    constructor(targetInstruction) {
        this.template = '<template>' + targetInstruction.elementInstruction.template + '</template>';
    }

    bind(bindingContext, overrideContext) {
        this.parentBindingContext = overrideContext.bindingContext ?? overrideContext;
    }
}
