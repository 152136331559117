import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, Disposable, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import SelectorOption from 'infrastructure/selector-option';
import RecordStatus, { getRecordStatusOptions } from 'record-status';
import CompareUtility from '../../infrastructure/compare-utility';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import SecurityService from '../../security/security-service';
import RemediationService from './remediation-service';

@autoinject
export class RemediationList {
    @observable filterText: any;

    mapId: number;

    remediationStatuses: any[];
    assignedFilterOptions: any[];
    assignedFilter: any;
    onlyAssignedToMeFilterOptions: any[];
    onlyAssignedToMeFilter: boolean;

    selectedActiveRemediations: any[];
    allSelected: boolean;
    canViewAllRemediations: boolean;
    canEditRemediations: boolean;

    gridOptions: any;

    remediations: any[];
    remediationsView: any[];

    remediationStatusesFilter: any;

    filterCount: number;

    recordStatusOptions: SelectorOption[];
    selectedRecordStatuses: RecordStatus[];

    applyFiltersSubscription: Disposable;
    clearFiltersSubscription: Disposable;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private remediationService: RemediationService,
    ) {
        this.logger.name = 'remediation-list';

        this.remediationStatuses = this.remediationService.getRemediationStatuses();

        this.filterText = '';
        this.assignedFilterOptions = [
            { value: -1, title: 'Do Not Filter' },
            { value: true, title: 'Yes' },
            { value: false, title: 'No' },
        ];
        this.assignedFilter = -1;

        this.onlyAssignedToMeFilterOptions = [
            { value: false, title: 'No' },
            { value: true, title: 'Yes' },
        ];
        this.onlyAssignedToMeFilter = false;

        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);

        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template:
                        '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate:
                        '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Name',
                    field: 'name',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                    sort: 'asc',
                },
                {
                    suppressMenu: true,
                    headerName: 'Description',
                    field: 'description',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                },
                {
                    suppressMenu: true,
                    headerName: 'Pre-remediation Files',
                    field: 'preFileCount',
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                },
                {
                    suppressMenu: true,
                    headerName: 'Post-remediation Files',
                    field: 'postFileCount',
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                },
                { suppressMenu: true, headerName: 'Assigned User', field: 'assignedUserEmail' },
                { suppressMenu: true, headerName: 'Approval User', field: 'approvalUserEmail' },
                { suppressMenu: true, headerName: 'Status', field: 'statusCaption' },
                { suppressMenu: true, headerName: 'Root Cause', field: 'remediationRootCauseName' },
                {
                    suppressMenu: true,
                    headerName: 'Active',
                    field: 'isActive',
                    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button click.delegate="navigateToRemediationDetail(data.id)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button if.bind="canEditRemediations" click.trigger="deleteRemediation(data)" disabled.bind="!data.isActive">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 150,
                    sortable: false,
                    suppressSizeToFit: true,
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.allSelected = false;
        this.selectedActiveRemediations = [];
        this.selectedRecordStatuses = [RecordStatus.ACTIVE];

        this.canViewAllRemediations = this.securityService.hasPermission('ViewAllRemediations');
        this.canEditRemediations =
            this.securityService.hasPermission('EditRemediations') &&
            !this.securityService.isImpersonating();
    }

    navigateToRemediationDetail(id) {
        this.router.navigateToRoute('remediation-detail', { id });
    }

    navigateToPlan(planId) {
        this.router.navigateToRoute('plan-detail', { id: planId });
    }

    setSelectedActiveRemediations() {
        this.selectedActiveRemediations = this.remediationsView.filter(
            (remediation) => remediation.isSelected && remediation.isActive,
        );
    }

    isSelectedChanged() {
        this.allSelected = this.remediationsView.every((remediation) => remediation.isSelected);
        this.setSelectedActiveRemediations();
    }

    allSelectedClicked() {
        if (this.remediationsView) {
            this.allSelected = !this.remediationsView.every(
                (remediation) => remediation.isSelected,
            );

            for (let remediation of this.remediationsView)
                remediation.isSelected = this.allSelected;
        }

        this.setSelectedActiveRemediations();

        return true;
    }

    filterTextChanged() {
        this.updateRemediationsView();
    }

    updateRemediationsView() {
        var lowerCasedFilterText = this.filterText.toLowerCase();

        if (this.remediations) {
            this.remediationsView = this.remediations.filter(
                (p) =>
                    (p.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (p.description || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (p.assignedUserEmail || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (p.approvalUserEmail || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (p.statusCaption || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (p.planName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1,
            );

            this.allSelected = this.remediationsView.every((remediation) => remediation.isSelected);
        }

        this.filterCount =
            0 +
            Number(this.assignedFilter !== -1) +
            Number(!!this.remediationStatusesFilter && !!this.remediationStatusesFilter.length) +
            Number(!!this.onlyAssignedToMeFilter) +
            Number(
                !!this.selectedRecordStatuses &&
                    this.selectedRecordStatuses[0] !== RecordStatus.ACTIVE,
            );
    }

    updateFilterDefaults(params) {
        if (params.remediationStatusIds && params.remediationStatusIds.length)
            this.remediationStatusesFilter = params.remediationStatusIds.map((s) => parseInt(s));

        if (params.isAssigned && params.isAssigned.length)
            this.assignedFilter = params.isAssigned === 'true';

        if (params.onlyAssignedToMe && params.onlyAssignedToMe.length)
            this.onlyAssignedToMeFilter = params.onlyAssignedToMe === 'true';
    }

    getFilters() {
        return {
            mapId: this.mapId,
            isAssigned: this.assignedFilter === -1 ? null : this.assignedFilter,
            onlyAssignedToMe: this.onlyAssignedToMeFilter,
            remediationStatusIds: this.remediationStatusesFilter,
        };
    }

    activate(params) {
        this.mapId = params.mapId;
        this.applyFiltersSubscription = this.eventAggregator.subscribe(
            'filters.apply',
            this.handleApplyFilters,
        );
        this.clearFiltersSubscription = this.eventAggregator.subscribe(
            'filters.clear',
            this.handleClearFilters,
        );

        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.updateFilterDefaults(params);
                this.recordStatusOptions = getRecordStatusOptions();
                await this.loadRemediations();
                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error loading remediations.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Remediations',
                    'An error occurred while loading the remediations. Please try again later.',
                );
            }
        })();
    }

    deactivate() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
        this.clearFiltersSubscription && this.clearFiltersSubscription.dispose();
    }

    async loadRemediations() {
        let selectedRecordStatus = this.selectedRecordStatuses?.[0];
        this.remediations = (await this.remediationService.getRemediations(
            this.getFilters(),
            'summary',
            selectedRecordStatus,
        )) as unknown as any[];
        this.setStatusCaptions();
        this.updateRemediationsView();
    }

    async handleApplyFilters() {
        await this.loadRemediations();
        this.updateRemediationsView();
    }

    async handleClearFilters() {
        this.remediationStatusesFilter = [];
        this.assignedFilter = -1;
        this.onlyAssignedToMeFilter = false;
        this.selectedRecordStatuses = [RecordStatus.ACTIVE];

        await this.loadRemediations();
        this.updateRemediationsView();
    }

    setStatusCaptions() {
        this.remediations.forEach((r) => {
            switch (r.status) {
                case 'InProgress':
                    r.statusCaption = 'In Progress';
                    break;
                case null:
                case undefined:
                case '':
                    r.statusCaption = null;
                    break;
                default:
                    r.statusCaption = r.status;
                    break;
            }
        });

        this.remediations.forEach((r) => {
            var remediationStatus = this.remediationStatuses.find((rs) => rs.code === r.status);
            r.statusCaption = remediationStatus ? remediationStatus.name : '';
        });
    }

    deleteRemediation(remediation) {
        this.dialogPresenter
            .showConfirmation(
                'Delete Remediation',
                'Are you sure you want to delete this remediation?',
            )
            .then((confirmed) => {
                if (!confirmed) return;

                this.pageContext.isLoading = true;
                this.remediationService
                    .deleteRemediations([remediation.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Remediation deleted successfully.');

                        remediation.isSelected = false;
                        remediation.isActive = false;

                        this.setSelectedActiveRemediations();
                    })
                    .catch((error) => {
                        this.logger.error('Error deleting remediation.', error, {
                            remediationId: remediation.id,
                        });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, false);
                    });
            });
    }

    deleteSelectedRemediations() {
        var remediationsToDelete = this.remediationsView.filter((u) => u.isSelected);
        if (!remediationsToDelete.length) return;

        this.dialogPresenter
            .showConfirmation(
                'Delete Selected Remediations',
                'Are you sure you want to delete the selected remediations?',
            )
            .then((confirmed) => {
                if (!confirmed) return;

                var remediationIds = remediationsToDelete.map((remediation) => remediation.id);

                this.pageContext.isLoading = true;
                this.remediationService
                    .deleteRemediations(remediationIds)
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay(
                            'Selected remediations deleted successfully.',
                        );

                        for (let remediation of remediationsToDelete) {
                            remediation.isSelected = false;
                            remediation.isActive = false;

                            this.setSelectedActiveRemediations();
                        }

                        this.updateRemediationsView();
                    })
                    .catch((error) => {
                        this.logger.error('Error deleting remediations.', error, {
                            remediationIds,
                        });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, true);
                    });
            });
    }

    handleDeleteError(error, multipleRemediations: boolean) {
        this.dialogPresenter.showAlert(
            'Error Deleting Remediation' + (multipleRemediations ? 's' : ''),
            this.getApiErrorMessage(error.apiErrorCode, multipleRemediations),
        );
    }

    getApiErrorMessage(errorCode, multipleRemediations?) {
        const tense = multipleRemediations ? 's' : '';
        if (errorCode === 2) return 'You do not have access to delete the remediation' + tense;

        return 'An unexpected error occurred while trying to delete remediations. Please try again later.';
    }
}
