import { autoinject, bindable } from 'aurelia-framework';
import Chart from 'chart.js';
import PlanCoverage from './plan-coverage';
import { planCoverageTypes } from './plan-coverage-type';

@autoinject
export class PlanCoveragePieChart {
    @bindable chartData: PlanCoverage;
    chartCanvas: any;
    chart: any;

    constructor(private element: Element) {
    }

    chartDataChanged() {
        this.applyChartData();
    }


    handleChartClick(coverageType: string) {
        const event = new CustomEvent('handle-chart-click', {
            detail: { coverageType },
            bubbles: true,
        });

        this.element.dispatchEvent(event);
    }

    attached() {
        this.chartCanvas = this.element.querySelector('canvas');
        this.chart = new Chart(this.chartCanvas.getContext('2d'), {
            type: 'pie',
            options: {
                legend: {
                    display: true,
                    position: 'bottom',
                },
                onClick: (e: PointerEvent, chartElements: any[]) => {
                    const { _model: { label }} = chartElements[0];
                    this.handleChartClick(label);
                }
            },
        });
        this.applyChartData();
    }

    applyChartData() {
        if (!this.chartData || !this.chart) {
            return;
        }

        this.chart.data = {
            labels: [
                planCoverageTypes.Collected,
                planCoverageTypes.Scheduled,
                planCoverageTypes.CollectedAndScheduled,
                planCoverageTypes.Unused
            ],
            datasets: [{
                data: [
                    this.chartData.collectedCount,
                    this.chartData.scheduledCount,
                    this.chartData.collectedAndScheduledCount,
                    this.chartData.unusedCount,
                ],
                backgroundColor: [
                    '#1a9869',
                    '#b6d825',
                    '#e16127',
                    '#cc2350',
                ]
            }]
        };

        this.chart.update();
    }

    detached() {
        if (this.chart) {
            this.chart.destroy();
        }
    }
}
