import { BaseQueryFilter } from '../infrastructure/components/query-filters/base-query-filter';
import { QueryFiltered } from '../infrastructure/components/query-filters/query-filtered';
import OutcomeStatus from './outcome-status';

export interface OutcomeStatusQuery {
    outcomeStatus: OutcomeStatus;
}

/* eslint-disable brace-style */
export class OutcomeStatusQueryFilters
    extends BaseQueryFilter
    implements OutcomeStatusQuery, QueryFiltered<OutcomeStatusQuery>
{
    private _outcomeStatus: OutcomeStatus;

    constructor() {
        super();
        this._outcomeStatus = OutcomeStatus.ALL;
    }

    public get outcomeStatus() {
        return this._outcomeStatus;
    }

    public set outcomeStatus(outcomeStatuses: OutcomeStatus) {
        this._outcomeStatus = outcomeStatuses || OutcomeStatus.ALL;
    }

    public get count(): number {
        return Number(!!this._outcomeStatus && this._outcomeStatus !== OutcomeStatus.ALL);
    }

    reset(): void {
        this._outcomeStatus = OutcomeStatus.ALL;
    }

    getQueryParams(): OutcomeStatusQuery {
        return {
            outcomeStatus: this._outcomeStatus,
        };
    }

    setFilterValues(params: OutcomeStatusQuery): void {
        super.setQueryFilteredValues(params, this);
    }
}
