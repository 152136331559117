import {
    GridOptions,
    ValueFormatterParams,
    ValueGetterParams,
} from 'ag-grid-enterprise/dist/ag-grid-enterprise';
import moment from 'moment';
import 'moment-timezone';
import { findIana } from 'windows-iana';
import { SubscriptionFrequencyType } from './contracts/subscription-frequency-type';

const getWeekday = (dayOfWeek: number): string => {
    const weekdays = new Array(7);
    weekdays[0] = 'Sunday';
    weekdays[1] = 'Monday';
    weekdays[2] = 'Tuesday';
    weekdays[3] = 'Wednesday';
    weekdays[4] = 'Thursday';
    weekdays[5] = 'Friday';
    weekdays[6] = 'Saturday';

    return weekdays[dayOfWeek - 1];
};

const formatDayOfMonth = (deliveryDay: number): string => {
    return deliveryDay >= 28 ? `${deliveryDay} or last day of month.` : deliveryDay.toString();
};

const formatSubscriptionFreqencyType = (params: ValueFormatterParams): string => {
    return SubscriptionFrequencyType[params.value];
};

const formatDeliveryDay = (params: ValueGetterParams): string => {
    switch (params.data.subscriptionFrequencyType) {
        case SubscriptionFrequencyType.Daily:
            return 'Every Day';
        case SubscriptionFrequencyType.Weekly:
            return getWeekday(params.data.deliveryDay) + 's';
        case SubscriptionFrequencyType.Monthly:
            return formatDayOfMonth(params.data.deliveryDay);
    }
};

const formatIsStarted = (params: ValueFormatterParams): string => {
    return params.value ? 'Yes' : 'No';
};

const formatLastDeliveryDate = (params: ValueGetterParams): string => {
    const { timeZone, lastDelivered } = params.data;
    const tz = findIana(timeZone);
    const currentUsersTimeZone = tz.length ? tz[0] : '';
    const isValid = moment(lastDelivered, moment.ISO_8601).isValid();

    return isValid
        ? (moment.utc(lastDelivered).clone() as any).tz(currentUsersTimeZone).format('llll')
        : 'Never Delivered';
};

const formatNextDeliveryDate = (params: ValueGetterParams): string => {
    const { timeZone, nextDeliveryStart, isStarted } = params.data;
    if (!isStarted) {
        return '';
    }

    const tz = findIana(timeZone);
    const currentUsersTimeZone = tz.length ? tz[0] : '';
    const isValid = moment(nextDeliveryStart, moment.ISO_8601).isValid();

    return isValid
        ? (moment.utc(nextDeliveryStart).clone() as any).tz(currentUsersTimeZone).format('llll')
        : 'Error';
};

export const adhocReportSubscriptionGrid: Readonly<GridOptions> = {
    columnDefs: [
        {
            headerName: 'Report',
            field: 'adhocReportName',
        },
        {
            headerName: 'Frequency',
            field: 'subscriptionFrequencyType',
            valueFormatter: formatSubscriptionFreqencyType,
        },
        {
            headerName: 'Delivery Day',
            field: 'deliveryDay',
            valueGetter: formatDeliveryDay,
        },
        {
            headerName: 'Delivery Time of Day',
            field: 'deliveryTimeOfDay',
        },
        {
            headerName: 'Time Zone',
            field: 'timeZone',
        },
        {
            headerName: 'Started',
            field: 'isStarted',
            valueFormatter: formatIsStarted,
        },
        {
            headerName: 'Last Delivery Date',
            field: 'lastDelivered',
            valueGetter: formatLastDeliveryDate,
        },
        {
            headerName: 'Next Delivery Date',
            field: 'nextDeliveryStart',
            valueGetter: formatNextDeliveryDate,
        },
        {
            suppressMenu: true,
            template: `
            <div class="row-actions-flex">
                <button click.delegate="stop(data.id)" if.bind="data.isStarted">
                    <i class="fa fa-stop"></i>
                </button>
                <button click.delegate="start(data.id)" if.bind="!data.isStarted">
                    <i class="fa fa-play"></i>
                </button>
                <a route-href="route: edit-adhoc-report-subscription; params.bind: { id: data.id }">
                    <i class="fa fa-pencil-square-o"></i>
                </a>
                <button click.trigger="delete(data.id)">
                    <i class="fa fa-trash-o"></i>
                </button>
            </div>
            `,
            cellClass: 'medium-text-right',
            width: 150,
            sortable: false,
            suppressSizeToFit: true,
        },
    ],
    defaultColDef: { sortable: true, resizable: true },
};
