import { ColDef, Grid, GridOptions, SelectionChangedEvent } from 'ag-grid-community';
import { autoinject, observable } from 'aurelia-framework';
import SelectorOption from 'infrastructure/selector-option';
import ValidationErrorFormatter from 'infrastructure/validation/validation-error-formatter';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import OrganizationService from '../../organizations/organization-service';
import SecurityService from '../../security/security-service';
import { ManageTestMethodDataRow } from './manage-test-method-data-row';
import { testMethodManagementGridOptions } from './test-method-management-grid';
import { TestMethodManagementService } from './test-method-management-service';
import { testMethodManagementNameFormatting } from 'test-methods/test-method-helper';

@autoinject
export class TestMethodManagement {
    @observable organizationId: number;
    @observable actionId: number;
    organizationOptions: SelectorOption[];
    actionOptions: SelectorOption[];

    gridOptions: GridOptions;
    testMethodManagementGrid: any;
    rowData: ManageTestMethodDataRow[];
    dataGridRowSelected: boolean;

    constructor(
        private dialogPresenter: DialogPresenter,
        private logger: Logger,
        private organizationService: OrganizationService,
        private pageContext: PageContext,
        private securityService: SecurityService,
        private testMethodManagementService: TestMethodManagementService,
    ) {
        this.logger.name = 'test-method-management-logger';
        this.organizationId = 0;
        this.actionId = 0;
        this.actionOptions = [
            { value: 1, title: 'None' },
            { value: 2, title: 'Hide' },
            { value: 3, title: 'Show' },
        ];
        this.rowData = [];
    }

    async loadGridData() {
        this.pageContext.isLoading = true;
        try {
            this.rowData = (
                await this.testMethodManagementService.fetchOrganizationTestMethods(
                    this.organizationId,
                )
            ).map((tms) => {
                tms.testMethodName = testMethodManagementNameFormatting(
                    tms,
                    this.securityService.isImpersonating(),
                    this.securityService.isWorkingUnderOrganization(),
                );
                return tms;
            });
        } catch (error) {
            this.logger.error('Error loading manage test methods data', error);
            this.dialogPresenter.showAlert(
                'Error Loading Test Management Data',
                'An error occurred while loading test result data. Please try again later.',
            );
        }

        this.gridOptions?.api.setRowData(this.rowData);
        this.pageContext.isLoading = false;
    }

    async onSave() {
        this.pageContext.isLoading = true;
        const selectedNodes = this.gridOptions?.api?.getSelectedNodes();
        const selectectedIds = selectedNodes.map((x) => x.data.organizationTestMethodId);
        try {
            if (this.actionId === 2) {
                await this.testMethodManagementService.hideOrganizationTestMethods(
                    this.organizationId,
                    selectectedIds,
                );
            }
            if (this.actionId === 3) {
                await this.testMethodManagementService.showOrganizationTestMethods(
                    this.organizationId,
                    selectectedIds,
                );
            }
            await this.loadGridData();
            this.actionId = 1;
            this.pageContext.showSuccessOverlay('Changes successful');
        } catch (error) {
            const action = this.actionId === 2 ? 'Hiding' : 'Showing';
            const title = `Error ${action} Test Methods`;
            this.logger.error(title, error);
            const errorMessage = ValidationErrorFormatter.format(
                `An error occured while ${action} test methods. If the problem persists, please contact customer service`,
                error,
            );
            this.dialogPresenter.showAlert(title, errorMessage);
        }

        this.pageContext.isLoading = false;
    }

    async organizationIdChanged(organizationId: number) {
        if (organizationId === 0) {
            return;
        }

        this.loadGridData();
    }

    async actionIdChanged(actionId: number) {
        this.gridOptions?.api?.deselectAll();

        const setCheckboxVisibility = (hide: boolean) => {
            const colDefs = this.gridOptions?.api?.getColumnDefs();
            if (colDefs && colDefs.length) {
                colDefs.forEach((cd: ColDef) => {
                    if (cd.colId === 'select_checkbox') {
                        cd.hide = hide;
                    }
                });
            }

            this.gridOptions?.api?.setColumnDefs(colDefs);
        };

        if (actionId < 2) {
            setCheckboxVisibility(true);
            this.gridOptions?.api?.setFilterModel(null);

            return;
        }

        // filter by IsActive
        // display hideable records (2)
        // display showable records (3)
        setCheckboxVisibility(false);
        this.gridOptions?.api?.setFilterModel({
            isActive: {
                filterType: 'text',
                type: 'equals',
                filter: actionId === 2,
            },
        });
    }

    onGridSelectionChanged(evt: SelectionChangedEvent) {
        this.dataGridRowSelected = evt.api.getSelectedNodes().length > 0;
    }

    async canActivate() {
        this.pageContext.isLoading = true;
        const isWorkingUnderOrganization = this.securityService.isWorkingUnderOrganization();
        const organizations = isWorkingUnderOrganization
            ? await this.organizationService.getImpersonatingOrganizations()
            : await this.organizationService.getOrganizations();

        this.organizationOptions = organizations.map((x: any) => ({
            title: x.name,
            value: x.id,
        }));
    }

    bind() {
        this.gridOptions = testMethodManagementGridOptions;
        this.gridOptions.rowData = this.rowData;
        this.gridOptions.onSelectionChanged = this.onGridSelectionChanged.bind(this);
        new Grid(this.testMethodManagementGrid, this.gridOptions);
        this.pageContext.isLoading = false;
    }
}
