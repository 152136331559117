import Attribute from './attribute';

export function buildAttributeQuery(attributes: Attribute[], parameterName) {
    if (!attributes || !attributes.length)
        return '';

    var validIndex = 0;
    var query = '';

    for (var i = 0; i < attributes.length; i++) {
        let attribute = attributes[i];
        if (!attribute.name || !attribute.value)
            continue;

        query += `${parameterName}[${validIndex}].name=${attribute.name}&${parameterName}[${validIndex}].value=${attribute.value}&`;
        validIndex++;
    }

    return query;
}
