import { inject, bindable } from 'aurelia-framework';
import $ from 'jquery';

@inject(Element)
export class ContentSizeChangeCustomAttribute {
    @bindable eventName;

    constructor(element) {
        this.element = element;
        this.handleSizeChange = this.handleSizeChange.bind(this);
    }

    attached() {
        this.$element = $(this.element);
        this.$element.on('on.zf.toggler', this.handleSizeChange);
        this.$element.on('off.zf.toggler', this.handleSizeChange);
    }

    detached() {
        if (!this.$element)
            return;

        this.$element.off('on.zf.toggler');
        this.$element.off('off.zf.toggler');
    }

    handleSizeChange() {
        this.element.dispatchEvent(new CustomEvent(this.eventName, { bubbles: true, detail: {} }));
    }
};
