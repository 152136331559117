import SimpleMDE from 'simplemde';
import { inject, bindable, bindingMode } from 'aurelia-framework';
import PageContext from '../../infrastructure/page-context';
import Logger from '../../infrastructure/logger';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import UploadedFileService from '../../infrastructure/uploaded-files/uploaded-file-service';

@inject(PageContext, Logger, DialogPresenter, UploadedFileService)
export class MarkdownEditor {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value;
    @bindable imageUploadPath;

    constructor(pageContext, logger, dialogPresenter, uploadedFileService) {
        this.pageContext = pageContext;
        this.logger = logger;
        this.dialogPresenter = dialogPresenter;
        this.uploadedFileService = uploadedFileService;
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    valueChanged(newValue, oldValue) {
        if (oldValue === newValue)
            return;

        this.setEditorValue(newValue);
    }

    setEditorValue(value) {
        if (!this.editor)
            return;

        var editorValue = this.editor.value();
        if (value === editorValue)
            return;

        this.editor.value(value || '');
    }

    handleEditorChange() {
        this.value = this.editor && this.editor.value();
    }

    attached() {
        this.editor = new SimpleMDE({
            element: this.editorTextarea,
            toolbar: this.buildToolbar(),
            insertTexts: {
                image: [`![`, `](#url#)`],
                link: ['[', '](https://)'],
            },
        });

        if (this.value)
            this.setEditorValue(value);

        this.editor.codemirror.on('change', this.handleEditorChange);
    }

    detached() {
        this.editor.codemirror.off('change', this.handleEditorChange);
        this.editor && this.editor.toTextArea();
        this.editor = null;
    }

    buildToolbar() {
        return [
            'bold', 'italic', 'heading',
            '|',
            'quote', 'unordered-list', 'ordered-list',
            '|',
            'link', this.createImageToolbarItem(), 
            '|',
            'preview', 'side-by-side', 'fullscreen',
            '|',
            'guide'
        ];
    }

    createImageToolbarItem() {
        var markdownEditor = this;
        return {
            name: 'custom',
            title: 'Insert Image',
            className: 'fa fa-picture-o',
            action: () => markdownEditor.showFileDialog()
        };
    }

    async uploadImage() {
        if (!this.imageFileInputElement.value)
            return;

        var files = Array.from(this.imageFileInputElement.files);
        this.pageContext.isLoading = true;

        try {
            var url = await this.uploadedFileService.uploadFiles(this.imageUploadPath, files, progress => {
                this.uploadPercent = progress.loaded / progress.total
            });
            
            if (/editor-preview-active/.test(this.editor.codemirror.getWrapperElement().lastChild.className))
                return;

            var text;
            var start = this.editor.options.insertTexts.image[0];
            var end = this.editor.options.insertTexts.image[1];
            var startPoint = this.editor.codemirror.getCursor("start");
            var endPoint = this.editor.codemirror.getCursor("end");
            if (url) {
                end = end.replace("#url#", url);
            }
            if (this.editor.getState(this.editor.codemirror).image) {
                text = this.editor.codemirror.getLine(startPoint.line);
                start = text.slice(0, startPoint.ch);
                end = text.slice(startPoint.ch);
                this.editor.codemirror.replaceRange(start + end, {
                    line: startPoint.line,
                    ch: 0
                });
            } else {
                text = this.editor.codemirror.getSelection();
                this.editor.codemirror.replaceSelection(start + text + end);

                startPoint.ch += start.length;
                if (startPoint !== endPoint) {
                    endPoint.ch += start.length;
                }
            }
            this.editor.codemirror.setSelection(startPoint, endPoint);
            this.editor.codemirror.focus();
        } catch (error) {
            this.logger.error('Error uploading image', error);
            this.dialogPresenter.showAlert(
                'Error Uploading Image',
                'An error occurred while uploading the image. Please try again later.');
        }

        this.imageFileInputElement.value = '';
        this.pageContext.isLoading = false;
    }

    showFileDialog() {
        this.imageFileInputElement.click();
    }
};
