import marked from 'marked';
import SimpleMDE from 'simplemde';
import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import PageContext from '../../infrastructure/page-context';
import Logger from '../../infrastructure/logger';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import FaqGroupService from './faq-group-service';
import FaqService from './faq-service';

@inject(Router, ValidationControllerFactory, PageContext, Logger, DialogPresenter, FaqGroupService, FaqService)
export default class FaqDetail {
    @observable selectedFaqGroups;

    constructor(router, validationControllerFactory, pageContext, logger, dialogPresenter, faqGroupService, faqService) {
        this.router = router;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.pageContext = pageContext;
        this.logger = logger;
        this.logger.name = 'faq-detail';
        this.dialogPresenter = dialogPresenter;
        this.faqGroupService = faqGroupService;
        this.faqService = faqService;
        this.selectedFaqGroups = [];
    }

    selectedFaqGroupsChanged() {
        if (!this.faq)
            return;

        if (this.selectedFaqGroups && this.selectedFaqGroups.length) {
            this.faq.faqGroupId = this.selectedFaqGroups[0].id;
            this.faq.faqGroupName = this.selectedFaqGroups[0].name;
        } else {
            this.faq.faqGroupId = 0;
            this.faq.faqGroupName = '';
        }
    }

    activate(params) {
        this.faqGroupId = parseInt(params.faqGroupId);

        (async () => {
            this.pageContext.isLoading = true;

            try {
                var results = await Promise.all([
                    params.id === 'create' ?
                        Promise.resolve({ isActive: true }) :
                        this.faqService.getFaq(params.id),
                    this.faqGroupService.getFaqGroups()
                ]);

                this.faq = results[0];

                if (this.faq.faqGroupId)
                    this.faqGroupId = this.faq.faqGroupId;

                this.faqGroups = results[1];

                var selectedFaqGroup = this.faqGroups.find(faqGroup => faqGroup.id === (this.faq.faqGroupId || this.faqGroupId));

                this.selectedFaqGroups = selectedFaqGroup ? [selectedFaqGroup] : [];
                
                ValidationRules
                    .ensure('question').displayName('Question').required().maxLength(512)
                    .ensure('answer').displayName('Answer').required()
                    .on(this.faq);

                this.validationController.addObject(this.faq);

                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error while loading FAQ', error);
                this.pageContext.isLoading = false;

                if (error.apiErrorCode === 1) {
                    this.handleNotFound();
                    return;
                }

                this.dialogPresenter.showAlert(
                    'Error Loading FAQ',
                    'An error occurred while loading the FAQ. Please try again later.');
            }
        })();
    }

    handleNotFound() {
        this.dialogPresenter
            .showAlert(
                'FAQ Not Found',
                'We sorry, but the FAQ you are trying to view does not exist.')
            .then(() => this.router.navigateToRoute('faq-list'));
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('faq-list', { id: this.faqGroupId });
    }

    async save() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        try {
            var savedFaq = await this.faqService.saveFaq(this.faq);

            this.faq.id = savedFaq.id;
            this.faq.ordinal = savedFaq.ordinal; // Update ordinal in view model in case the backend set it to another value.
            this.faqGroupId = savedFaq.faqGroupId; // Update the FAQ group ID in case the user changed groups. This ensures the link back to the list is to the correct one.

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('FAQ saved successfully.');
            this.router.navigateToRoute('faq-detail', { id: this.faq.id }, { replace: true });
        } catch (error) {
            this.logger.error('Error saving FAQ.', error, { faq: this.faq });
            this.dialogPresenter.showAlert(
                'Error Saving FAQ',
                this.getApiErrorMessage(error.apiErrorCode));
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        switch (errorCode) {
            default:
                return 'An error occurred while saving the current FAQ. Please try again later.';
        }
    }
};
