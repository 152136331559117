import { ChartRef, Column, CsvExportParams, GridOptions, RowNode } from 'ag-grid-community';
import { Grid } from 'ag-grid-enterprise/dist/ag-grid-enterprise';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import PagedList from 'infrastructure/contracts/paged-list';
import SelectorOption from 'infrastructure/selector-option';
import getPointZoneOptions from 'location-testing/points/point-zones';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import Logger from '../infrastructure/logger';
import { getOutcomeStatusOptions } from './outcome-status';
import { TestResult } from './test-result';
import { testResultGrid } from './test-results-grid';
import { TestResultListQuery, TestResultsQueryFilters } from './test-results-query-filters';
import { TestResultService } from './test-results-service';

@autoinject
export class TestResultList {
    gridOptions: GridOptions;
    testResultsGrid: any;
    currentChartRef: ChartRef;
    pagedTestResults: PagedList<TestResult>;

    testResultQueryFilter: TestResultsQueryFilters;
    filteredByListeria: boolean;

    applyFiltersSubscription: Subscription;
    clearFiltersSubscription: Subscription;

    outcomeStatusOptions: SelectorOption[];
    pointZoneOptions: SelectorOption[];

    constructor(
        private router: Router,
        private testResultService: TestResultService,
        private logger: Logger,
        private dialogPresenter: DialogPresenter,
        private eventAggregator: EventAggregator,
    ) {
        this.outcomeStatusOptions = getOutcomeStatusOptions();
        this.pointZoneOptions = getPointZoneOptions();
        this.testResultQueryFilter = new TestResultsQueryFilters();
        this.logger.name = 'test-results-list';
        this.applyFiltersSubscription = this.eventAggregator.subscribe('filters.apply', () =>
            this.handleApplyFilters(),
        );
        this.clearFiltersSubscription = this.eventAggregator.subscribe('filters.clear', () =>
            this.handleClearFilters(),
        );
    }

    async loadTestResultsData() {
        const queryParams = this.testResultQueryFilter.getQueryParams();
        try {
            this.pagedTestResults = await this.testResultService.getTestResults(queryParams);
        } catch (error) {
            this.logger.error('Error loading maps.', error);
            this.dialogPresenter.showAlert(
                'Error Loading Test Results Data',
                'An error occurred while loading test result data. Please try again later.',
            );
        }

        this.gridOptions?.api.setRowData(this.pagedTestResults.data);
    }

    handleApplyFilters() {
        const query = this.testResultQueryFilter.getQueryParams();
        this.router.navigateToRoute('test-results-list', query);
    }

    handleClearFilters() {
        this.testResultQueryFilter.reset();
        const query = this.testResultQueryFilter.getQueryParams();
        this.router.navigateToRoute('test-results-list', query);
    }

    private filterAndSortListeriaResults() {
        var listeriaFilter = {
            testCode: {
                filterType: 'text',
                operator: 'OR',
                condition1: {
                    filterType: 'text',
                    type: 'startsWith',
                    filter: 'LS',
                },
                condition2: {
                    filterType: 'text',
                    type: 'startsWith',
                    filter: 'LM',
                },
            },
        };
        this.gridOptions?.api.setFilterModel(listeriaFilter);
        this.gridOptions?.columnApi.applyColumnState({
            state: [
                { colId: 'sampleExternalId', sort: 'asc', sortIndex: 0 },
                { colId: 'testCreateDateTime', sort: 'asc', sortIndex: 1 },
            ],
            defaultState: { sort: null },
        });
        this.gridOptions?.api.onFilterChanged();
    }

    private combineListeriaResults() {
        const rowDataMap: Record<string, TestResult> = {};
        this.gridOptions?.api.forEachNodeAfterFilterAndSort((node: RowNode) => {
            rowDataMap[node.data.sampleId] = node.data;
        });
        this.gridOptions?.api.setRowData(Object.values(rowDataMap));
    }

    async handleListeriaFinal(checked: boolean) {
        if (checked) {
            this.filterAndSortListeriaResults();
            this.combineListeriaResults();
        } else {
            this.gridOptions?.api.setFilterModel(null);
            this.gridOptions?.columnApi.applyColumnState({
                defaultState: { sort: null },
            });
            await this.loadTestResultsData();
        }
    }

    async downloadTestResultsCSV() {
        const params: CsvExportParams = {
            skipColumnGroupHeaders: true,
            skipRowGroups: true,
            allColumns: true,
            columnKeys: this.gridOptions.columnApi
                .getAllColumns()
                .filter((c: Column) => !c.isPinnedRight()),
        };
        this.gridOptions.api.exportDataAsCsv(params);
    }

    async canActivate(params: TestResultListQuery) {
        this.testResultQueryFilter.setFilterValues(params);
        await this.loadTestResultsData();
    }

    bind() {
        this.gridOptions = testResultGrid;
        this.gridOptions.rowData = this.pagedTestResults.data;
        new Grid(this.testResultsGrid, this.gridOptions);
    }

    canDeactivate() {
        this.currentChartRef?.destroyChart();
    }

    deactivate() {
        this.applyFiltersSubscription?.dispose();
        this.clearFiltersSubscription?.dispose();
    }
}
