export default class PageContext {
    constructor() {
        this.showLoader = false;
        this.isLoadingCount = 0;
        this.loaderFadedOut = false;
        this.overlay = null;
    }

    get isLoading() {
        return !!this.showLoader;
    }

    set isLoading(value) {
        this.isLoadingCount += value ? 1 : -1;
        if (this.isLoadingCount === 0) {
            this.loaderFadeOut = true;

            setTimeout(() => {
                this.loaderFadeOut = false;

                // Check again since this is happening in a timeout.
                if (this.isLoadingCount !== 0) return;

                this.showLoader = false;
            }, 250);
        } else {
            this.showLoader = true;
        }
    }

    showErrorOverlay(message, autoClose = true) {
        this.showMessageOverlay('error', message, 'exclamation-circle', autoClose);
    }

    showSuccessOverlay(message, autoClose = true) {
        this.showMessageOverlay(
            'success',
            message || 'Action successful.',
            'check-circle-o',
            autoClose,
        );
    }

    showInfoOverlay(message, autoClose = true) {
        this.showMessageOverlay('info', message, 'info-circle', autoClose);
    }

    showMessageOverlay(type, message, icon, autoClose) {
        this.overlay = {
            type,
            message,
            icon,
            autoClose,
            animation: 'slide-in',
        };

        if (autoClose) setTimeout(this.closeOverlay.bind(this), 5000); // Stay open for 5 seconds.
    }

    closeOverlay() {
        if (!this.overlay || this.overlay.isClosing) return;

        this.overlay.isClosing = true;
        this.overlay.animation = 'slide-out';

        setTimeout(() => {
            this.overlay = null;
        }, 1000); // Allow slide-out animation to complete before removing overlay.
    }
}
