import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import Logger from '../infrastructure/logger';
import PageContext from '../infrastructure/page-context';
import Organization from '../organizations/organization';
import RoleService from '../roles/role-service';
import SecurityService from '../security/security-service';
import UserService from './user-service';

@autoinject
export default class UserDetail {
    @observable selectedOrganization: Organization;
    @observable selectedRole;

    validationController: ValidationController;

    noOrgSelectedRolesPlaceholderText: string;
    orgSelectedRolesPlaceholderText: string;
    rolesPlaceholderText: string;
    propertyDisplayNames: any;

    // data
    permissionGroups: Map<any, any>;
    user: any;
    roles: any;
    currentUserOrganizations: Organization[];
    otherOrganizations: Organization[];
    selectedOtherOrganizations: Organization[];
    organizationRoles: any;
    recordChanges: any;

    rolesEnabled: boolean;
    formChanged: boolean;

    // permissions
    canViewRoles: boolean;
    canEditUsers: boolean;
    canAssignRoles: boolean;
    canViewAudit: boolean;

    constructor(private router: Router,
        validationControllerFactory: ValidationControllerFactory,
        private pageContext: PageContext,
        private logger: Logger,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private userService: UserService,
        private roleService: RoleService
    ) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.pageContext = pageContext;

        this.logger = logger;
        this.logger.name = 'user-detail';

        this.noOrgSelectedRolesPlaceholderText = 'Select an organization first';
        this.orgSelectedRolesPlaceholderText = 'Select an role';
        this.rolesPlaceholderText = this.noOrgSelectedRolesPlaceholderText;

        this.dialogPresenter = dialogPresenter;
        this.securityService = securityService;
        this.userService = userService;
        this.roleService = roleService;
        this.canViewRoles = this.securityService.hasPermission('ViewRoles');
        this.canEditUsers = this.securityService.hasPermission('EditUsers') && !this.securityService.isImpersonating();
        this.canAssignRoles = this.securityService.hasPermission('AssignRoles') && !this.securityService.isImpersonating();
        this.canViewAudit = this.securityService.isCurrentUserInternal() && this.securityService.isImpersonating();
        this.selectedOrganization = null;
        this.selectedRole = null;
        this.permissionGroups = new Map();
        this.propertyDisplayNames = {
            'FirstName': 'First Name',
            'LastName': 'Last Name',
            'Email': 'Email',
            'IsActive': 'Active',
            'IsLocked': 'Locked',
            'OrganizationName': 'Organization Name'
        };

        this.otherOrganizations = [];
    }

    canActivate(params) {
        if (params.id === 'create' && (!this.canEditUsers || !this.canAssignRoles))
            return false;

        return true;
    }

    activate(params) {
        (async () => {
            try {
                this.pageContext.isLoading = true;

                var results = await Promise.all([
                    params.id === 'create' ?
                        Promise.resolve({ isActive: true }) :
                        this.userService.getUser(parseInt(params.id)),
                    this.userService.getCurrentUserOrganizations(),
                    this.canAssignRoles || this.canViewRoles ?
                        this.roleService.getRoles() :
                        Promise.resolve([])
                ]);

                this.user = results[0];
                this.currentUserOrganizations = results[1];
                this.selectedOrganization = this.user.organization || null;
                this.selectedOtherOrganizations = this.selectedOrganization?.parentId ?
                    this.user.organizations.filter(o => o.parentId === this.selectedOrganization.parentId && o.id !== this.selectedOrganization.id) :
                    [];
                this.updateOtherOrganizations();

                this.roles = results[2];
                this.updateOrganizationRoles();

                this.selectedRole = this.roles.find(r => r.id === this.user.roleId) || null;
                this.updatePermissionGroups();

                // Remove the computed organization property to eliminate confusion.
                delete this.user.organization;

                ValidationRules
                    .ensure('firstName')
                    .displayName('First Name')
                    .required()
                    .ensure('lastName')
                    .displayName('Last Name')
                    .required()
                    .ensure('email')
                    .displayName('Email')
                    .required()
                    .email()
                    .on(this.user);

                this.validationController.addObject(this.user);

                ValidationRules
                    .ensure('selectedOrganization')
                    .displayName('Organization')
                    .satisfies(selectedOrganization => !!selectedOrganization)
                    .ensure('selectedRole')
                    .displayName('Role')
                    .satisfies(selectedRole => !this.canAssignRoles || !!selectedRole)
                    .on(this);
            } catch (error) {
                this.logger.error('Error loading user', error, { userId: params.id });

                var errorAlertPromise = error.apiErrorCode === 1 ?
                    this.dialogPresenter.showAlert(
                        'Error Loading User',
                        'The current user doesn\'t exist.') :
                    this.dialogPresenter.showAlert(
                        'Error Loading User',
                        'An error occurred while loading the current user. Please try again later.');

                errorAlertPromise.then(() => {
                    this.router.navigateToRoute('user-list');
                });
            }

            this.pageContext.isLoading = false;
        })();
    }

    updateOtherOrganizations() {
        this.otherOrganizations = this.selectedOrganization == null ?
            [] :
            this.selectedOrganization.parentId ?
                this.currentUserOrganizations.filter(o => o.parentId === this.selectedOrganization.parentId && o.id !== this.selectedOrganization.id) : // Siblings
                this.currentUserOrganizations.filter(o => o.parentId === this.selectedOrganization.id); // Children
    }

    selectedOrganizationChanged() {
        if (!this.user)
            return;

        this.user.organizationId = this.selectedOrganization ? this.selectedOrganization.id : 0;

        this.toggleRoleState(!!this.selectedOrganization);
        this.updateOrganizationRoles();

        this.selectedOtherOrganizations = [];
        this.updateOtherOrganizations();
    }

    updateOrganizationRoles() {
        if (this.selectedRole) {
            let validRole = this.checkRoleIsUnderOrganization(this.selectedRole);
            if (!validRole)
                this.selectedRole = null;
        }

        if (this.roles && this.roles.length)
            this.organizationRoles = this.roles.filter(this.checkRoleIsUnderOrganization.bind(this));
        else
            this.organizationRoles = [];
    }

    toggleRoleState(isOrgSelected) {
        this.rolesPlaceholderText = isOrgSelected ? this.orgSelectedRolesPlaceholderText : this.noOrgSelectedRolesPlaceholderText;
        this.rolesEnabled = isOrgSelected;
    }

    checkRoleIsUnderOrganization(role) {
        if (!this.selectedOrganization)
            return false;

        return role.organization.id === this.selectedOrganization.id;
    }

    selectedRoleChanged() {
        if (!this.user)
            return;

        if (this.canAssignRoles)
            this.user.roleId = this.selectedRole ? this.selectedRole.id : 0;

        this.updatePermissionGroups();
    }

    updatePermissionGroups() {
        this.permissionGroups.clear();

        var role = !this.user.roleId ? null : this.roles.find(r => r.id === this.user.roleId);
        if (!role)
            return;

        for (let permission of role.permissions) {
            let permissions = this.permissionGroups.get(permission.group);
            if (permissions)
                permissions.push(permission.name);
            else
                this.permissionGroups.set(permission.group, [permission.name]);
        }
    }

    async unlock() {
        this.pageContext.isLoading = true;

        try {
            await this.userService.unlockUser(this.user.id);
            this.user.isLocked = false;
            this.pageContext.showSuccessOverlay('User unlocked successfully.');
        } catch (error) {
            this.logger.error('Error unlocking user.', error, { userId: this.user.id });
            this.dialogPresenter.showAlert(
                'Error Unlocking User',
                'An error occurred while unlocking the current user. Please try again later.');
        }

        this.pageContext.isLoading = false;
    }

    async resetPassword() {
        this.pageContext.isLoading = true;

        try {
            await this.securityService.initResetPassword(this.user.email);
            this.pageContext.showSuccessOverlay('"Password Reset" email sent.');
        } catch (error) {
            this.logger.error('Error resetting user password.', error, { userId: this.user.id });
            var message;
            switch (error.apiErrorCode) {
                case 212:
                    message = 'Invalid email address.';
                    break;
                case 213:
                    message = 'Passwords can only be reset for external users.';
                    break;
                default:
                    message = 'An error occurred while resetting the current user\'s password. Please try again later.';
            }
            this.dialogPresenter.showAlert('Error Resetting Password', message);
        }

        this.pageContext.isLoading = false;
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('user-list');
    }

    async save() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        try {
            this.user.organizations = this.selectedOtherOrganizations;
            this.user.id = ((await this.userService.saveUser(this.user)) as any).id;

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('User saved successfully.');
            this.router.navigateToRoute('user-detail', { id: this.user.id }, { replace: true });
        } catch (error) {
            this.logger.error('Error saving user.', error, { user: this.user });
            this.dialogPresenter.showAlert(
                'Error Saving User',
                this.getApiErrorMessage(error.apiErrorCode));
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        if (errorCode === 103)
            return 'The email specified is already in use.';

        return 'An error occurred while saving the current user. Please try again later.';
    }

    async loadRecordChanges() {
        try {
            this.pageContext.isLoading = true;
            this.recordChanges = await this.userService.getUserRecordChanges(this.user.id);
        } catch (error) {
            this.dialogPresenter.showAlert(
                'Error Loading User Audit',
                'An error occurred while loading the current user audit. Please try again later.');
        }

        this.pageContext.isLoading = false;
    }
}
