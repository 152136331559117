import SelectorOption from 'infrastructure/selector-option';
import MapSummary from 'location-testing/maps/map-summary';
import PlanSummary from './plan-summary';

export default class PlanSummaryConverter {
    static toSelectorOptions(planSummaries: PlanSummary[], mapSummaries: MapSummary[], activeOnly = true): SelectorOption[] {
        if (!planSummaries || !mapSummaries)
            return [];

        let plans = activeOnly ? planSummaries.filter(p => p.isActive) : planSummaries;

        let options = [];
        plans.forEach(ps => {
            let mapSummary = mapSummaries.find(m => m.id == ps.mapId);
            if (mapSummary) {
                let option = {
                    title: ps.name,
                    value: ps.id,
                    group: `Map: ${mapSummary.name}`
                } as unknown as SelectorOption;

                options.push(option);
            }
        });

        return options;
    }
}
