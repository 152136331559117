import { GridOptions } from 'ag-grid-community';
import { AgBoolCellRenderer } from 'infrastructure/components/cell-renderers/ag-bool-cell-renderer';

export const testMethodManagementGridOptions: GridOptions = {
    columnDefs: [
        { field: 'testMethodId', headerName: 'Test Method Id', initialHide: true },
        {
            colId: 'select_checkbox',
            suppressMenu: true,
            headerName: 'Select',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: (params) =>
                params.data && (params.data.canHide || !params.data.isActive),
            hide: true,
        },
        {
            field: 'testMethodName',
            headerName: 'Test Method',
        },
        {
            field: 'planCount',
            headerName: 'Plan Count',
        },
        { field: 'unassignedTaskCount', headerName: 'Unassigned Task Count' },
        { field: 'specificationCount', headerName: 'Specification Count' },
        { field: 'capPlanCount', headerName: 'Cap Plan Count' },
        { field: 'isActive', headerName: 'Visible', cellRenderer: AgBoolCellRenderer },
    ],
    defaultColDef: {
        flex: 1,
        minWidth: 100,
        sortable: true,
        filter: true,
        resizable: true,
    },
    suppressRowClickSelection: true,
    animateRows: true,
    rowSelection: 'multiple',
};
