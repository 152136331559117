import { autoinject } from 'aurelia-framework';
import { observable } from 'aurelia-framework';
import CompareUtility from '../infrastructure/compare-utility';
import OrganizationUtility from '../infrastructure/organization-utility';
import OrganizationService from './organization-service';

@autoinject
export default class OrganizationList {
    @observable filterText;

    organizations: any;
    organizationsView: any;

    gridOptions: any;

    constructor(private organizationService: OrganizationService) {
        this.organizations = [];
        this.organizationsView = [];
        this.filterText = '';

        this.gridOptions = {
            columnDefs: [
                { suppressMenu: true, headerName: 'LIMS Client ID', field: 'externalId', comparator: CompareUtility.compareStringsInsensitive },
                { suppressMenu: true, headerName: 'Organization Name', field: 'name', comparator: CompareUtility.compareStringsInsensitive, template: '<a route-href="route: organization-detail; params.bind: { id: data.id }">${data.name}</a>', sort: 'asc' },
                { suppressMenu: true, headerName: 'Parent Organization Name', field: 'parentOrganizationName', comparator: CompareUtility.compareStringsInsensitive, template: '<a route-href="route: organization-detail; params.bind: { id: data.parentId || 0 }">${data.parentOrganizationName}</a>', sort: 'asc' },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };
    }

    filterTextChanged() {
        this.updateView();
    }

    updateView() {
        var lowerCasedFilterText = this.filterText.toLowerCase();

        if (!this.organizations)
            return;

        this.organizationsView =
            this.organizations.filter(o =>
                (o.externalId || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (o.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (o.parentOrganizationName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1
            );
    }

    activate() {
        this.organizationService
            .getOrganizations()
            .then(organizations => {
                this.organizations = OrganizationUtility.flattenOrganization(organizations);
                this.updateView();

            });
    }
}
