import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class VideoService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getVideos() {
        return this.httpClient.get('help/videos');
    }

    getVideo(id) {
        return this.httpClient.get(`help/videos/${id}`);
    }

    deleteVideos(ids){
        var query = ids.map(id => `id=${id}`).join('&');
        return this.httpClient.delete(`help/videos?${query}`);
    
    }

    saveVideo(video) {
        if (!video.id)
            return this.httpClient.post('help/videos/', video);

        return this.httpClient.put(`help/videos/${video.id}`, video);
    }

    getVideoExternalMetadata(externalVideoId) {
        return this.httpClient.get(`help/videos/external/${externalVideoId}`);
    }
}
