import { bindable } from 'aurelia-framework';

export class InfoTip {
    @bindable message;
    tooltipContainer;

    messageChanged() {
        this.initialize();
    }

    destroy() {
        let $element = $(this.tooltipContainer);
        let plugin = $element.data('zfPlugin');
        plugin?.destroy();
    }

    initialize() {
        if (!this.tooltipContainer)
            return;

        this.destroy();
        
        new Foundation.Tooltip($(this.tooltipContainer), { tipText: this.message });
    }

    attached() {
        this.initialize();
    }

    detached() {
        this.destroy();
    }
}
