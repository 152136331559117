import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import SelectorOption from 'infrastructure/selector-option';
import CollectionType, {
    getCollectionTypeOptions,
    toCollectionTypeTitle,
} from 'location-testing/collection-type';
import RecordStatus, { getRecordStatusOptions } from 'record-status';
import CompareUtility from '../../infrastructure/compare-utility';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import SecurityService from '../../security/security-service';
import PlanService from './plan-service';
import PlanType from './plan-type';

@autoinject
export class PlanList {
    @observable filterText;
    @observable selectedPlanTypes;
    @observable selectedPausedStates;

    // filters
    applyFiltersSubscription: Subscription;
    clearFiltersSubscription: Subscription;

    recordStatusOptions: SelectorOption[];
    selectedRecordStatuses: RecordStatus[];
    collectionTypeOptions: SelectorOption[];
    selectedCollectionTypes: SelectorOption[];

    allSelected: boolean;
    selectedActivePlans: any[];

    // filter status
    isFilteringPlanTypes: boolean;
    isFilteringPausedStates: boolean;
    isFilteringRecords: boolean;
    isFilteringCollectionType: boolean;
    filterRecordStatusState: string;

    // data
    mapId: any;
    plans: any;
    plansView: any;
    planTypes: any;

    // ui
    gridOptions: any;

    // security
    canEditPlans: boolean;
    canGenerateTasks: boolean;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private planService: PlanService,
    ) {
        this.logger.name = 'plan-list';

        this.selectedPlanTypes = [];
        this.selectedPausedStates = [];
        this.collectionTypeOptions = getCollectionTypeOptions();
        this.selectedCollectionTypes = [];

        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template:
                        '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()" data-cy="datagrid-list-row-checkbox"></label>',
                    headerCellTemplate:
                        '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true,
                    pinned: 'left',
                },
                {
                    suppressMenu: true,
                    headerName: 'Plan Name',
                    field: 'name',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                    sort: 'asc',
                },
                {
                    suppressMenu: true,
                    headerName: 'Remediation',
                    field: 'remediationName',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                },
                {
                    suppressMenu: true,
                    headerName: 'Plan Type',
                    field: 'planTypeId',
                    template: '<span>${decodePlanType(data.planTypeId)}</span>',
                    width: 155,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-left',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Collection Type',
                    field: 'collectionType',
                    template: '<span>${decodeCollectionType(data.collectionType)}</span>',
                    headerClass: 'text-center',
                    cellClass: 'medium-text-left',
                },
                {
                    suppressMenu: true,
                    headerName: 'Cycle Duration (Days)',
                    field: 'scheduleCycleDurationDays',
                    template:
                        '<span>${decodeCycleDays(data.planTypeId, data.scheduleCycleDurationDays)}</span>',
                },
                {
                    suppressMenu: true,
                    headerName: 'Test Methods',
                    field: 'testMethodCount',
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                },
                {
                    suppressMenu: true,
                    headerName: 'Sample Points',
                    field: 'pointCount',
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                },
                {
                    suppressMenu: true,
                    headerName: 'Open Tasks',
                    field: 'openTaskCount',
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                },
                {
                    suppressMenu: true,
                    headerName: 'Unassigned Tasks',
                    field: 'unassignedTaskCount',
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                },
                {
                    suppressMenu: true,
                    headerName: 'Paused',
                    field: 'isPaused',
                    template: '<i class="fa fa-check" if.bind="data.isPaused"></i>',
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                },
                {
                    suppressMenu: true,
                    headerName: 'Active',
                    field: 'isActive',
                    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button click.delegate="navigateToPlanDetail(data.id)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button if.bind="canEditPlans" click.trigger="deletePlan(data)" disabled.bind="!data.isActive">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 150,
                    sortable: false,
                    suppressSizeToFit: true,
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.allSelected = false;
        this.selectedActivePlans = [];
        this.selectedRecordStatuses = [RecordStatus.ACTIVE];
        this.canEditPlans =
            this.securityService.hasPermission('EditPlans') &&
            !this.securityService.isImpersonating();
        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
    }

    decodePlanType(planTypeId: number) {
        return this.planTypes.find((t) => t.id === planTypeId).token;
    }

    decodeCycleDays(planTypeId: number, scheduleCycleDays: number) {
        return planTypeId === PlanType.SCHEDULED ? scheduleCycleDays.toString() : '';
    }

    decodeCollectionType(collectionType: CollectionType): string {
        return toCollectionTypeTitle(collectionType);
    }

    navigateToPlanDetail(id) {
        this.router.navigateToRoute('plan-detail', { id });
    }

    navigateToGenerateTasks(id) {
        this.router.navigateToRoute('plan-generate-tasks', { id });
    }

    setSelectedActivePlans() {
        this.selectedActivePlans = this.plansView.filter(
            (plan) => plan.isSelected && plan.isActive,
        );
        this.canGenerateTasks =
            this.selectedActivePlans.length === 1 &&
            (this.selectedActivePlans[0].planTypeId === PlanType.RISK_BASED_PRIORITY ||
                this.selectedActivePlans[0].planTypeId === PlanType.ADHOC) &&
            this.selectedActivePlans[0].pointCount > 0;
    }

    isSelectedChanged() {
        this.allSelected = this.plansView.every((plan) => plan.isSelected);
        this.setSelectedActivePlans();
    }

    allSelectedClicked() {
        if (this.plansView) {
            this.allSelected = !this.plansView.every((plan) => plan.isSelected);

            for (let plan of this.plansView) plan.isSelected = this.allSelected;
        }

        this.setSelectedActivePlans();

        return true;
    }

    filterTextChanged() {
        this.updatePlansView();
    }

    handleApplyFilters() {
        this.updatePlansView();
    }

    handleClearFilters() {
        this.selectedPlanTypes = [];
        this.selectedPausedStates = [];
        this.selectedRecordStatuses = [RecordStatus.ACTIVE];
        this.selectedCollectionTypes = [];
        this.updatePlansView();
    }

    handleGenerateTasks() {
        let selectedPlanId = this.plansView.find((plan) => plan.isSelected).id;
        this.navigateToGenerateTasks(selectedPlanId);
    }

    updatePlansView() {
        var lowerCasedFilterText = this.filterText ? this.filterText.toLowerCase() : '';

        if (this.plans) {
            this.plansView = this.plans.filter(
                (p) =>
                    this.matchSelectedPlanTypes(p) &&
                    this.matchSelectedPausedStates(p) &&
                    ((p.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                        (p.remediationStatus || '').toLowerCase().indexOf(lowerCasedFilterText) >
                            -1 ||
                        (p.planType || '').toLowerCase().indexOf(lowerCasedFilterText) > -1) &&
                    this.matchSelectedRecordStatus(p) &&
                    this.matchSelectedCollectionType(p),
            );

            this.allSelected = this.plansView.every((plan) => plan.isSelected);
        }

        this.isFilteringPlanTypes = !!this.selectedPlanTypes && !!this.selectedPlanTypes.length;
        this.isFilteringPausedStates =
            !!this.selectedPausedStates && !!this.selectedPausedStates.length;
        this.isFilteringRecords =
            !!this.selectedRecordStatuses && this.selectedRecordStatuses.length > 0;
        this.filterRecordStatusState = this.isFilteringRecords
            ? this.recordStatusOptions.find((o) => o.value === this.selectedRecordStatuses[0]).title
            : '';
        this.isFilteringCollectionType = this.selectedCollectionTypes.length > 0;
    }

    matchSelectedPlanTypes(plan) {
        if (!this.selectedPlanTypes || !this.selectedPlanTypes.length) return true;

        return this.selectedPlanTypes.some((o) => o.id === plan.planTypeId);
    }

    matchSelectedPausedStates(plan) {
        if (!this.selectedPausedStates || !this.selectedPausedStates.length) return true;

        return this.selectedPausedStates.some((o) => o.isPaused === plan.isPaused);
    }

    matchSelectedRecordStatus(plan) {
        if (
            this.selectedRecordStatuses.length === 1 &&
            this.selectedRecordStatuses[0] === RecordStatus.ALL
        )
            return true;

        let filteredIsActiveValue = this.selectedRecordStatuses[0] === RecordStatus.ACTIVE;
        return plan.isActive === filteredIsActiveValue;
    }

    matchSelectedCollectionType(plan) {
        if (this.selectedCollectionTypes.length === 0) return true;

        return this.selectedCollectionTypes.some((ct) => ct.value === plan.collectionType);
    }

    activate(params) {
        this.applyFiltersSubscription = this.eventAggregator.subscribe(
            'filters.apply',
            this.handleApplyFilters,
        );
        this.clearFiltersSubscription = this.eventAggregator.subscribe(
            'filters.clear',
            this.handleClearFilters,
        );

        this.mapId = params.mapId;

        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.recordStatusOptions = getRecordStatusOptions();

                var [plans, planTypes] = await Promise.all([
                    this.planService.getPlans(this.mapId),
                    this.planService.getPlanTypes(),
                ]);

                this.plans = plans;
                this.updatePlansView();

                this.planTypes = planTypes;

                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error loading plans.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Plans',
                    'An error occurred while loading the plans. Please try again later.',
                );
            }
        })();
    }

    deactivate() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
        this.clearFiltersSubscription && this.clearFiltersSubscription.dispose();
    }

    deletePlan(plan) {
        this.dialogPresenter
            .showConfirmation('Delete Plan', 'Are you sure you want to delete this plan?')
            .then((confirmed) => {
                if (!confirmed) return;

                this.pageContext.isLoading = true;
                this.planService
                    .deletePlans([plan.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Plan deleted successfully.');

                        plan.isSelected = false;
                        plan.isActive = false;

                        this.setSelectedActivePlans();
                    })
                    .catch((error) => {
                        this.logger.error('Error deleting plan.', error, { planId: plan.id });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, false);
                    });
            });
    }

    deleteSelectedPlans() {
        var plansToDelete = this.plansView.filter((u) => u.isSelected);
        if (!plansToDelete.length) return;

        this.dialogPresenter
            .showConfirmation(
                'Delete Selected Plans',
                'Are you sure you want to delete the selected plans?',
            )
            .then((confirmed) => {
                if (!confirmed) return;

                var planIds = plansToDelete.map((plan) => plan.id);

                this.pageContext.isLoading = true;
                this.planService
                    .deletePlans(planIds)
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Selected plans deleted successfully.');

                        for (let plan of plansToDelete) {
                            plan.isSelected = false;
                            plan.isActive = false;

                            this.setSelectedActivePlans();
                        }

                        this.updatePlansView();
                    })
                    .catch((error) => {
                        this.logger.error('Error deleting plans.', error, { planIds });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, true);
                    });
            });
    }

    handleDeleteError(error, multiplePlans) {
        this.dialogPresenter.showAlert(
            'Error Deleting Plan' + (multiplePlans ? 's' : ''),
            this.getApiErrorMessage(error.apiErrorCode, multiplePlans),
        );
    }

    getApiErrorMessage(errorCode, multiplePlans) {
        if (errorCode === 2)
            return 'You dont have access to delete the plan' + (multiplePlans ? 's' : '');

        return 'An unexpected error occurred while trying to delete plans. Please try again later.';
    }
}
