import SelectorOption from 'infrastructure/selector-option';
import Sample from 'requests/sample';
import SegmentationTemplate from 'segmentation-templates/segmentation-template';
import SegmentationTemplateSegment from 'segmentation-templates/segmentation-template-segment';

export default class SegmentationSampleGridColDataFactory {
    private _segmentationTemplates: SegmentationTemplate[];
    private _samples: Sample[];
    private _requiredSegmentationTemplates: SegmentationTemplate[];
    private _segmentationTemplateSelectorOptions: SelectorOption[];

    private _lookupSegmenationTemplates: SegmentationTemplate[];
    private _nonLookupSegmentationTemplates: SegmentationTemplate[];
    private _groupedLookupSegments: Record<number, SegmentationTemplateSegment[]>;

    constructor(segmenationTemplates: SegmentationTemplate[], samples: Sample[]) {
        this._segmentationTemplates = segmenationTemplates || [];
        this._samples = samples || [];
        this._requiredSegmentationTemplates = [];
        this._lookupSegmenationTemplates = [];
        this._nonLookupSegmentationTemplates = [];
        this._groupedLookupSegments = {};

        this.init();
    }

    public get samples(): Sample[] {
        return this._samples;
    }

    public get segmentationTemplateSelectorOptions(): SelectorOption[] {
        return this._segmentationTemplateSelectorOptions;
    }

    public get lookupSegmentationTemplates(): SegmentationTemplate[] {
        return this._lookupSegmenationTemplates;
    }

    public get nonLookupSegmentationTemplates(): SegmentationTemplate[] {
        return this._nonLookupSegmentationTemplates;
    }

    public get groupedLookupSegmentationTemplates(): Record<number, SegmentationTemplateSegment[]> {
        return this._groupedLookupSegments;
    }

    public lookupParentSegment(
        key: string,
        groupedLookupSegments?: Record<number, SegmentationTemplateSegment[]>,
    ): SegmentationTemplateSegment {
        const groups = groupedLookupSegments || this._groupedLookupSegments;
        return groups[key].find((sts: SegmentationTemplateSegment) => sts.parentSegmentId === null);
    }

    public lookupChildSegment(
        key: string,
        groupedLookSegments?: Record<number, SegmentationTemplateSegment[]>,
    ): SegmentationTemplateSegment {
        const groups = groupedLookSegments || this._groupedLookupSegments;
        return groups[key].find(
            (sts: SegmentationTemplateSegment) =>
                sts.parentSegmentId !== null && sts.parentSegmentId >= 0,
        );
    }

    public getSegmentationTemplateById(id: number): SegmentationTemplate {
        return this._segmentationTemplates.find((x) => x.id === id);
    }

    public getSegmentationTemplateByName(name: string): SegmentationTemplate {
        return this._segmentationTemplates.find((x) => x.name === name);
    }

    public getLookupSegmentationTemplateById(id: number): SegmentationTemplate {
        return this.filterSegmentationTemplatesByType(this._segmentationTemplates, true).find(
            (x) => x.id === id,
        );
    }

    public getNonLookupSegmentationTemplateById(id: number): SegmentationTemplate {
        return this.filterSegmentationTemplatesByType(this._segmentationTemplates, false).find(
            (x) => x.id === id,
        );
    }

    private init() {
        //HACK: This is a serialization issue and should be fixed on server side
        this._segmentationTemplates.forEach((st) => {
            this._samples.forEach((s) => this.fixupSegmentNames(s, st));
        });

        this.getRequiredSegmentationSegments();
        this._lookupSegmenationTemplates = this.filterSegmentationTemplatesByType(
            this._requiredSegmentationTemplates,
            true,
        );
        this._nonLookupSegmentationTemplates = this.filterSegmentationTemplatesByType(
            this._requiredSegmentationTemplates,
            false,
        );
        this._groupedLookupSegments = this.groupLookupSegments();
        this._segmentationTemplateSelectorOptions = this._segmentationTemplates.map((o) => {
            return {
                value: o.id,
                title: o.name,
            } as SelectorOption;
        });
        this._segmentationTemplateSelectorOptions.unshift({ value: null, title: 'none' });
    }

    private fixupSegmentNames(sample: any, segmentationTemplate: any) {
        if (!sample.segments) return;

        var segmentNames = Object.keys(sample.segments);

        for (let segment of segmentationTemplate.segments) {
            // Find match ignoring case.
            let segmentName = segmentNames.find(
                (n) => n.localeCompare(segment.name, 'en', { sensitivity: 'base' }) === 0,
            );

            // If casing is different, copy value to correct naming of property and delete old property.
            if (segment.name !== segmentName) {
                sample.segments[segment.name] = sample.segments[segmentName];
                delete sample.segments[segmentName];
            }
        }
    }

    private getRequiredSegmentationSegments(): void {
        const segmentKeys: string[] = [];
        const segmentKey = (key: string, id: number) => `${key}_${id}`;

        this._samples.forEach((sample) => {
            if (sample.segments) {
                for (let k in sample.segments) {
                    segmentKeys.push(segmentKey(k, sample.segmentationTemplateId));
                }
            }
        });
        const uniqueSampleSegmentKeys = [...new Set(segmentKeys)];

        this._segmentationTemplates.forEach((st) => {
            const matchedTemplateSegments = st.segments.filter((seg) =>
                uniqueSampleSegmentKeys.includes(segmentKey(seg.name, seg.segmentationTemplateId)),
            );
            if (matchedTemplateSegments.length === 0) {
                return;
            }

            const clone = Object.assign({}, st);
            clone.segments = matchedTemplateSegments;
            this._requiredSegmentationTemplates.push(clone);
        });
    }

    private filterSegmentationTemplatesByType(
        segmenationTemplates: SegmentationTemplate[],
        lookupType: boolean,
    ): SegmentationTemplate[] {
        const filteredTemplates: SegmentationTemplate[] = [];
        segmenationTemplates.forEach((st) => {
            let segments: SegmentationTemplateSegment[];
            if (lookupType) {
                segments = st.segments.filter((sts) => sts.values);
            } else {
                segments = st.segments.filter((sts) => !sts.values);
            }

            if (!segments.length) {
                return;
            }

            const clone = Object.assign({}, st);
            clone.segments = segments;
            filteredTemplates.push(clone);
        });

        return filteredTemplates;
    }

    public groupLookupSegments(
        segments?: SegmentationTemplateSegment[],
    ): Record<number, SegmentationTemplateSegment[]> {
        segments =
            segments ||
            this._lookupSegmenationTemplates.concat.apply(
                [],
                this._lookupSegmenationTemplates.map((lst) => lst.segments),
            );

        const groups: Record<number, SegmentationTemplateSegment[]> = {};
        segments.forEach((segment: SegmentationTemplateSegment) => {
            let segmentId = null;

            if (segment.parentSegmentId === undefined && !groups[segment.id]) {
                groups[segment.id] = [];
                segmentId = segment.id;
            } else if (
                segment.parentSegmentId !== null &&
                segment.parentSegmentId !== undefined &&
                !groups[segment.parentSegmentId]
            ) {
                groups[segment.parentSegmentId] = [];
                segmentId = segment.parentSegmentId;
            } else if (segment.parentSegmentId === null || segment.parentSegmentId === undefined) {
                segmentId = segment.id;
            } else {
                segmentId = segment.parentSegmentId;
            }

            if (groups[segmentId] === undefined) {
                groups[segmentId] = [];
            }
            groups[segmentId].push(segment);
        });

        return groups;
    }
}
