import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class UserGuideService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getUserGuides(code) {
        if (code)
            return this.httpClient.get(`help/user-guides/?code=${code}`);

        return this.httpClient.get('help/user-guides');
    }

    getUserGuide(id) {
        return this.httpClient.get(`help/user-guides/${id}`);
    }

    deleteUserGuides(ids) {
        var query = ids.map(id => `id=${id}`).join('&');
        return this.httpClient.delete(`help/user-guides?${query}`);
    }

    saveUserGuide(userGuide) {
        if (!userGuide.id)
            return this.httpClient.post('help/user-guides/', userGuide);

        return this.httpClient.put(`help/user-guides/${userGuide.id}`, userGuide);
    }
}
