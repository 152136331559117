import moment from 'moment';

function* generateDateRange(startDate, endDate) {
    var currentDate = moment(startDate);

    while (currentDate < endDate) {
        yield currentDate;
        currentDate = moment(currentDate).add(1, 'd');
    }

    yield endDate;
}

export function createPlaybackFrames(startDate, endDate, dailyResults) {
    if (!startDate) throw new Error('The startDate parameter is required.');

    if (!endDate) throw new Error('The endDate parameter is required.');

    var dailyResultGroups = [];

    for (let date of generateDateRange(moment(startDate), moment(endDate)))
        dailyResultGroups.push({
            sampleCollectionDate: date,
            pointResults: {},
        });

    if (!dailyResults || dailyResults.length === 0) return dailyResultGroups;

    for (let dailyResult of dailyResults) {
        let sampleCollectionDate = moment(dailyResult.sampleCollectionDate);
        let dailyResultGroup = dailyResultGroups.find(
            (g) => g.sampleCollectionDate.toString() === sampleCollectionDate.toString(),
        );
        if (dailyResultGroup)
            dailyResultGroup.pointResults[dailyResult.pointId] = {
                allTestsPassed: dailyResult.allTestsPassed,
                anyTestWarned: dailyResult.anyTestWarned,
            };
    }

    let currentPointResults = null;
    for (let [i, dailyResultGroup] of dailyResultGroups.entries()) {
        if (currentPointResults)
            for (let [pointId, resultInfo] of Object.entries(dailyResultGroup.pointResults))
                resultInfo.highlightTransition =
                    (!currentPointResults[pointId] ||
                        currentPointResults[pointId].allTestsPassed) &&
                    !resultInfo.allTestsPassed;

        currentPointResults = dailyResultGroup.pointResults;
    }

    return dailyResultGroups;
}
