import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import CreateOrEditModel from 'infrastructure/contracts/create-or-edit-model';
import SelectorOption from 'infrastructure/selector-option';
import TimeZoneService from 'infrastructure/time-zone-service';
import moment from 'moment';
import 'moment-timezone';
import { findWindows } from 'windows-iana';
import DialogPresenter from '../../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../../infrastructure/logger';
import PageContext from '../../../infrastructure/page-context';
import AdhocReportService from '../adhoc-report-service';
import { AdhocReportSubscriptionService } from './adhoc-report-subscription-service';
import { AdhocReportSubscription } from './contracts/adhoc-report-subscription';
import { AdhocReportSubscriptionDetailActivation } from './contracts/adhoc-report-subscription-detail-activation';
import { SubscriptionFrequencyType } from './contracts/subscription-frequency-type';

@autoinject
export class AdhocReportSubscriptionCreate implements CreateOrEditModel {
    activation: AdhocReportSubscriptionDetailActivation;
    currentUsersTimeZone: string;

    constructor(
        private adhocReportService: AdhocReportService,
        private adhocReportSubscriptionService: AdhocReportSubscriptionService,
        private dialogPresenter: DialogPresenter,
        private logger: Logger,
        private pageContext: PageContext,
        private router: Router,
        private timeZoneService: TimeZoneService,
    ) {
        const tz = findWindows((moment as any).tz.guess());
        this.currentUsersTimeZone = tz.length ? tz[0] : '';
    }

    async save(event: CustomEvent) {
        let { detail }: { detail: AdhocReportSubscription } = event;
        this.pageContext.isLoading = true;
        try {
            detail = await this.adhocReportSubscriptionService.createAdhocReportSubscription(
                detail,
            );
            this.pageContext.showSuccessOverlay('Ad-Hoc Report Subscription successfully saved.');
            this.router.navigateToRoute('edit-adhoc-report-subscription', {
                id: detail.id,
            });
        } catch (error) {
            this.logger.error('Error creating Ad-hoc Report Subscription', error, {
                adhocReportSubscription: detail,
            });
            this.dialogPresenter.showAlert(
                'Error Creating Ad-hoc Report Subscription',
                'Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    cancel(event: CustomEvent) {
        this.router.navigateToRoute('adhoc-report-subscription-list');
    }

    async canActivate(params: any) {
        this.pageContext.isLoading = true;
        const [userAdhocReports, timeZones] = await Promise.all([
            (await this.adhocReportService.getCurrentUserAdhocReports()).filter((r) => r.isActive),
            this.timeZoneService.getTimeZones(),
        ]);
        this.activation = {
            userAdhocReports,
            timeZones: timeZones.map(
                (tz: any) => ({ title: tz.displayName, value: tz.id } as SelectorOption),
            ),
            entity: {
                adhocReportId: 0,
                subscriptionFrequencyType: SubscriptionFrequencyType.Daily,
                deliveryDay: 0,
                deliveryTimeOfDay: '00:00',
                timeZone: this.currentUsersTimeZone,
                isStarted: false,
                emailTo: '',
                emailCC: '',
                emailBCC: '',
                emailSubject: '',
                emailComment: '',
                renderFormat: 'pdf',
            },
            validateOnBind: false,
        };
    }

    bind() {
        this.pageContext.isLoading = false;
    }
}
