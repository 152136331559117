export default class OrganizationUtility {
    static flattenOrganization(array, parentName) {
        var result = [];
        array.forEach(function (node) {
            node.parentOrganizationName = parentName;
            result.push(node);
            if (Array.isArray(node.children)) {

                result = result.concat(OrganizationUtility.flattenOrganization(node.children, node.name));
            }
        });
        return result;
    }
}
