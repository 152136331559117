import marked from 'marked';
import SimpleMDE from 'simplemde';
import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import PageContext from '../../infrastructure/page-context';
import Logger from '../../infrastructure/logger';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import SecurityService from '../../security/security-service';
import GlossaryTermService from './glossary-term-service';

@inject(Element, Router, ValidationControllerFactory, PageContext, Logger, DialogPresenter, SecurityService, GlossaryTermService)
export default class GlossaryTermDetail {
    constructor(element, router, validationControllerFactory, pageContext, logger, dialogPresenter, securityService, glossaryTermService) {
        this.element = element;
        this.router = router;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.pageContext = pageContext;
        this.logger = logger;
        this.logger.name = 'glossary-term-detail';
        this.dialogPresenter = dialogPresenter;
        this.glossaryTermService = glossaryTermService;
        this.securityService = securityService;
    }

    activate(params) {
        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.glossaryTerm = params.id === 'create' ?
                    { isActive: true } :
                    await this.glossaryTermService.getGlossaryTerm(params.id);

                ValidationRules
                    .ensure('name').displayName('Name').required().maxLength(100)
                    .ensure('description').displayName('Description').required().maxLength(512)
                    .on(this.glossaryTerm);

                this.validationController.addObject(this.glossaryTerm);

                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error while loading glossary term', error);
                this.pageContext.isLoading = false;

                if (error.apiErrorCode === 1) {
                    this.handleNotFound();
                    return;
                }

                this.dialogPresenter.showAlert(
                    'Error Loading Glossary Term',
                    'An error occurred while loading the glossary term. Please try again later.');
            }
        })();
    }

    handleNotFound() {
        this.dialogPresenter
            .showAlert(
                'Glossary Term Not Found',
                'We sorry, but the glossary term you are trying to view does not exist.')
            .then(() => this.router.navigateToRoute('glossary-term-list'));
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('glossary-term-list');
    }

    async save() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        try {
            this.glossaryTerm.id = (await this.glossaryTermService.saveGlossaryTerm(this.glossaryTerm)).id;

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Glossary term saved successfully.');
            this.router.navigateToRoute('glossary-term-detail', { id: this.glossaryTerm.id }, { replace: true });
        } catch (error) {
            this.logger.error('Error saving glossary term.', error, { glossaryTerm: this.glossaryTerm });
            this.dialogPresenter.showAlert(
                'Error Saving Glossary Term',
                this.getApiErrorMessage(error.apiErrorCode));
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        switch (errorCode) {
            default:
                return 'An error occurred while saving the current glossary term. Please try again later.';
        }
    }
};
