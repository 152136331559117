import {
    ColDef,
    ColGroupDef,
    GridOptions,
    IAggFuncParams,
    ValueFormatterParams,
    ValueGetterParams,
} from 'ag-grid-community';
import moment from 'moment';
import { renderAgGridCellForAurelia } from '../infrastructure/aurelia-cell-renderer';

const getEmmaValue = (params: ValueGetterParams, field: string) => {
    return (params.data && params.data[field]) || 'Standard Submission';
};

const formatIsMobile = (params: ValueFormatterParams): string => {
    switch (params.value) {
        case true:
            return 'Yes';
        case false:
            return 'No';
        default:
            return 'Blank';
    }
};

const formatResult = (params: ValueFormatterParams): string => {
    if (typeof params.value === 'undefined') {
        return '';
    }

    switch (params.value) {
        case 'True':
            return 'Pass';
        case 'False':
            return 'Fail';
        default:
            return 'Incomplete';
    }
};

// This gets called BEFORE valueFormatter
const countResultAggFunction = (params: IAggFuncParams, type?: string): number => {
    let count = 0;
    params.values.forEach((v) => {
        if (typeof v === 'string' && v === type) {
            count++;
        } else if (typeof v === 'number') {
            count += v;
        }
    });

    return count;
};

// This gets called AFTER aggFunc
const resultValueFormatter = (params: ValueFormatterParams, type?: string): string => {
    if (typeof params.value === 'number') {
        return params.value.toString();
    }

    return params.value === type ? '1' : '0';
};

const formatDateToLocal = (params: ValueFormatterParams): string => {
    if (!params.value) {
        return;
    }

    return moment(params.value).local().format('L LT');
};

const formatCollectionType = (params: ValueFormatterParams): string => {
    if (!params.value) {
        return;
    }

    return params.value === 'CorrectiveAction' ? 'Corrective Action' : params.value;
};

export const generateTestResultsColumnDefs = (): (ColDef | ColGroupDef)[] => [
    {
        headerName: 'Request Id',
        field: 'requestExternalId',
        chartDataType: 'category',
    },
    {
        headerName: 'Sample Id',
        field: 'sampleExternalId',
        chartDataType: 'category',
    },
    {
        headerName: 'Test',
        field: 'testMethodDescription',
        chartDataType: 'category',
    },
    {
        headerName: 'count(Pass)',
        field: 'result',
        colId: 'result-count-pass',
        toolPanelClass: 'tp-pass',
        aggFunc: (params: IAggFuncParams) => countResultAggFunction(params, 'True'),
        valueFormatter: (params: ValueFormatterParams) => resultValueFormatter(params, 'True'),
        chartDataType: 'series',
    },
    {
        headerName: 'count(Fail)',
        field: 'result',
        colId: 'result-count-fail',
        toolPanelClass: 'tp-fail',
        aggFunc: (params: IAggFuncParams) => countResultAggFunction(params, 'False'),
        valueFormatter: (params: ValueFormatterParams) => resultValueFormatter(params, 'False'),
        chartDataType: 'series',
    },
    {
        headerName: 'count(Incomplete)',
        field: 'result',
        colId: 'result-count-incomplete',
        toolPanelClass: 'tp-incomplete',
        aggFunc: (params: IAggFuncParams) => countResultAggFunction(params, 'Incomplete'),
        valueFormatter: (params: ValueFormatterParams) =>
            resultValueFormatter(params, 'Incomplete'),
        chartDataType: 'series',
    },
    {
        headerName: 'Root Cause',
        field: 'remediationRootCauseName',
        chartDataType: 'category',
    },
    {
        headerName: 'Map',
        field: 'mapName',
        valueGetter: (params: ValueGetterParams) => getEmmaValue(params, 'mapName'),
        chartDataType: 'category',
    },
    {
        headerName: 'Plan',
        field: 'planName',
        valueGetter: (params: ValueGetterParams) => getEmmaValue(params, 'planName'),
        chartDataType: 'category',
    },
    {
        headerName: 'Test Created',
        field: 'testCreateDateTime',
        valueFormatter: formatDateToLocal,
        initialHide: true,
    },
    {
        headerName: 'Sample Detail',
        marryChildren: true,
        children: [
            {
                headerName: 'Sample Description',
                field: 'sampleDescription',
                chartDataType: 'category',
            },
            {
                headerName: 'Result',
                field: 'result',
                valueFormatter: formatResult,
            },
            {
                headerName: 'Test Code',
                field: 'testCode',
                columnGroupShow: 'open',
                chartDataType: 'category',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Quantitative Result',
                field: 'quantitativeResult',
                columnGroupShow: 'open',
                chartDataType: 'category',
            },
            {
                headerName: 'Qualitative Result',
                field: 'qualitativeResult',
                columnGroupShow: 'open',
            },
        ],
    },
    {
        headerName: 'Point Detail',
        marryChildren: true,
        children: [
            {
                headerName: 'Point Name',
                field: 'pointName',
                chartDataType: 'category',
            },
            {
                headerName: 'Point Description',
                field: 'pointDescription',
                columnGroupShow: 'open',
                chartDataType: 'category',
            },
            {
                headerName: 'Point Zone',
                field: 'pointZone',
                columnGroupShow: 'open',
                chartDataType: 'category',
            },
            {
                headerName: 'Point Room',
                field: 'pointRoom',
                columnGroupShow: 'open',
                chartDataType: 'category',
            },

            {
                headerName: 'Point Type',
                field: 'pointType',
                columnGroupShow: 'open',
                chartDataType: 'category',
            },
            {
                headerName: 'Point Risk',
                field: 'pointRisk',
                columnGroupShow: 'open',
                chartDataType: 'category',
            },
            {
                headerName: 'Mobility',
                field: 'pointIsMobile',
                valueFormatter: formatIsMobile,
                columnGroupShow: 'open',
                chartDataType: 'category',
            },
        ],
    },
    {
        headerName: 'Task Detail',
        marryChildren: true,
        children: [
            {
                headerName: 'Task Id',
                field: 'taskInternalId',
                chartDataType: 'excluded',
            },
            {
                headerName: 'Task Collection Type',
                field: 'taskCollectionType',
                columnGroupShow: 'open',
                chartDataType: 'category',
                valueFormatter: formatCollectionType,
            },
            {
                headerName: 'Task Collection DateTime',
                field: 'taskCollectionDateTime',
                columnGroupShow: 'open',
                chartDataType: 'series',
                valueFormatter: formatDateToLocal,
            },
        ],
    },
    {
        headerName: 'View in EMMA',
        suppressMenu: true,
        cellClass: 'medium-text-center',
        width: 120,
        sortable: false,
        suppressSizeToFit: true,
        chartDataType: 'excluded',
        enableRowGroup: false,
        enablePivot: false,
        pinned: 'right',
        valueGetter: (params: ValueGetterParams) => {
            if (!params.data) {
                return;
            }
            const { data } = params;

            return {
                data,
                template: `
            <div>
                <a 
                    target="_blank"
                    if.bind="data.mapId > 0" 
                    route-href="route:map-dashboard; params.bind: { id: data.mapId, pointId: data.pointId, testMethodIds: data.testMethodId }">
                    <i class="fa fa-external-link"></i>
                </a>
            </div>
                `,
            };
        },
        cellRenderer: renderAgGridCellForAurelia,
    },
];

export const testResultGrid: GridOptions = {
    columnDefs: generateTestResultsColumnDefs(),
    defaultColDef: {
        flex: 1,
        minWidth: 100,
        sortable: true,
        filter: true,
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true,
    },
    autoGroupColumnDef: {
        minWidth: 200,
    },
    sideBar: 'columns',
    animateRows: true,
    statusBar: {
        statusPanels: [
            { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
            { statusPanel: 'agTotalRowCountComponent', align: 'center' },
            { statusPanel: 'agFilteredRowCountComponent' },
            { statusPanel: 'agSelectedRowCountComponent' },
            { statusPanel: 'agAggregationComponent' },
        ],
    },
    suppressAggFuncInHeader: true,
    enableRangeSelection: true,
    enableCharts: true,
    popupParent: document.body,
    customChartThemes: {
        passFailCustomTheme: {
            baseTheme: 'ag-default',
            palette: {
                fills: ['#25d925', '#c00', '#9fd0cb'],
                strokes: ['#24d824', '#cc0000', '#9ed1cc'],
            },
        },
    },
    chartThemes: ['passFailCustomTheme', 'ag-default', 'ag-pastel', 'ag-vivid'],
};
