import { BaseQueryFilter } from 'infrastructure/components/query-filters/base-query-filter';
import { OrganizationsQueryFilters } from 'infrastructure/components/query-filters/organizations-query-filter';
import { QueryFiltered } from 'infrastructure/components/query-filters/query-filtered';

export interface ExportTaskCollectionTemplateListQuery {
    organizationIds: number[];
    timeStamp?: number;
}
export class ExportTaskCollectionTemplateListQueryFilters extends BaseQueryFilter
    implements QueryFiltered<ExportTaskCollectionTemplateListQuery> {

    organizationsQueryFilters: OrganizationsQueryFilters;

    constructor() {
        super();
        this.organizationsQueryFilters = new OrganizationsQueryFilters();
    }

    reset(): void {
        this.organizationsQueryFilters.reset();
    }

    public get count() {
        return this.organizationsQueryFilters.count;
    }

    getQueryParams(): ExportTaskCollectionTemplateListQuery {
        return {
            ...this.organizationsQueryFilters.getQueryParams(),
            timeStamp: Math.floor((new Date()).getTime() / 1000),
        };
    }

    setFilterValues(params: ExportTaskCollectionTemplateListQuery): void {
        super.setQueryFilteredValues(params, this);
    }
}
