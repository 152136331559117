export default class SegmentationTemplateSegmentUsages {
    static None = new SegmentationTemplateSegmentUsages('None');
    static SampleLabels = new SegmentationTemplateSegmentUsages('SampleLabels');
    static RequestCoas = new SegmentationTemplateSegmentUsages('RequestCoas');

    name: string;

    constructor(name: string) {
        this.name = name;
    }

    toString() {
        return this.name;
    }

    in(usages: string) {
        let names = (usages ?? '').split(',').map(v => v.trim());
        return names.includes(this.name);
    }

    static combine(usages: SegmentationTemplateSegmentUsages[]) {
        return usages
            .filter(u => u.name !== SegmentationTemplateSegmentUsages.None.name)
            .map(u => u.name).join(', ')
            || SegmentationTemplateSegmentUsages.None.name;
    }
}
