import { ApplicationInsights, SeverityLevel, IExceptionTelemetry, ITraceTelemetry } from '@microsoft/applicationinsights-web';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import ApplicationInsightsErrorData from './application-insights-error-data';

var trackingAttributeKey = 'data-appinsights';

function getAttributesLike(partial, attributes) {
    const results = [];
    for (let x = 0; x < attributes.length; ++x) {
        if (attributes[x].name.indexOf(partial) >= 0) {
            results.push(attributes[x]);
        }
    }
    return results;
}

const domHelper = {
    isElement: function (e) {
        return e instanceof HTMLElement;
    },
    hasClass: function (cls) {
        return function (e) {
            return domHelper.isElement(e) && e.classList.contains(cls);
        };
    },
    hasTrackingInfo: function (e) {
        return domHelper.isElement(e) && (getAttributesLike(trackingAttributeKey, e.attributes).length > 0);
    },
    isOfType: function (e, type) {
        return domHelper.isElement(e) &&
            e.nodeName.toLowerCase() === type.toLowerCase();
    },
    isAnchor: function (e) {
        return domHelper.isOfType(e, 'a');
    },
    isButton: function (e) {
        return domHelper.isOfType(e, 'button');
    }
};

const defaultOptions = {
    pageTracking: {
        enabled: true,
        getTitle: function (payload) {
            return payload.instruction.config.title;
        }
    },
    clickTracking: {
        enabled: true,
        filter: function (element) {
            return domHelper.isElement(element); //  && (criteria.isAnchor(element) || criteria.isButton(element));
        }
    }
};

const delegate = function (criteria, listener) {
    return function (evt) {
        let el = evt.target;

        if (criteria && !criteria(el)) 
            return;
            
        evt.delegateTarget = el;
        listener.call(this, evt);
    };
};

// NOTE: https://github.com/microsoft/ApplicationInsights-JS

@autoinject
export default class ApplicationInsightsService {
    private loaded: boolean;
    private appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: ''
        }
    });

    private options: any;

    constructor(private eventAggregator: EventAggregator) {
        this.loaded = false;
        this.options = defaultOptions;

        this.trackClick = this.trackClick.bind(this);
        this.trackPageView = this.trackPageView.bind(this);
    }

    registerKey(key: string) {
        if (!key || this.loaded)
            return;

        this.appInsights.config.instrumentationKey = key;
        this.appInsights.loadAppInsights();

        this.appInsights.addTelemetryInitializer((item) => {
            const ipAddress = item.tags && item.tags['ai.location.ip'];
            if (ipAddress) {
                item.baseData.properties = {
                    ...item.baseData.properties,
                    'client-ip': ipAddress
                };
            }
        });        

        this._attachPageTracker();
        this._attachClickTracker();

        this.loaded = true;
    }

    setUserId(userId: string, accountId: string) {
        if (!this.loaded)
            return;

        this.appInsights.setAuthenticatedUserContext(userId, accountId, true);
    }

    clearUserId() {
        if (!this.loaded)
            return;

            this.appInsights.clearAuthenticatedUserContext();
    }

    trackPageView(name?: string, uri?: string) {
        if (!this.loaded)
            return;

            this.appInsights.trackPageView({ name, uri });
    }

    trackClick(evt) {
        if (!this.loaded)
            return;

        if (!evt || !evt.delegateTarget || !domHelper.hasTrackingInfo(evt.delegateTarget))
            return;

        const dimensions = {};
        const element = evt.delegateTarget;
        const matches = getAttributesLike(trackingAttributeKey, element.attributes);
        matches.forEach(match => {
            var dataItems = match.value.split(';');
            dataItems.forEach(di => {
                var components = di.split(':');
                dimensions[components[0].toLowerCase()] = components[1];
            });
        });

        this.appInsights.trackEvent({ name: 'click', properties: dimensions });
    }

    trackEvent(logLevel: number, error: Error, message: string, data: ApplicationInsightsErrorData, state: string) {
        function getSeverityLevel(logLevel: number): SeverityLevel {
            let level: SeverityLevel = SeverityLevel.Critical;
            
            switch (logLevel) {
                case 2:
                    level = SeverityLevel.Information
                    break;
                case 3:
                    level = SeverityLevel.Warning;
                    break;
                case 4:
                    level = SeverityLevel.Error;
                    break;
                default:
                    level = SeverityLevel.Warning;
            }

            return level;
        }

        if (!this.loaded)
            return;

        if (error)
            this.trackError(error, message, getSeverityLevel(logLevel), data, state);
        else
            this.trackTrace(message, getSeverityLevel(logLevel), data, state)
    }

    trackError(error: Error, message: string, level: SeverityLevel, errorData: ApplicationInsightsErrorData, state: string) {
        if (!this.loaded)
            return;

        let telemetry: IExceptionTelemetry = {};
        telemetry.exception = error ?? new Error(message);
        telemetry.severityLevel = level;

        telemetry.properties = this._convertDataToDictionary(errorData);

        if (error && message) 
            telemetry.properties['message'] = message;

        if (state)
            telemetry.properties['state'] = state;

        this.appInsights.trackException(telemetry);
    }

    trackTrace(message: string, level: SeverityLevel, errorData: ApplicationInsightsErrorData, state: string) {
        if (!this.loaded)
            return;

        let telemetry: ITraceTelemetry = {
            message: message,
            severityLevel: level            
        };

        let properties = this._convertDataToDictionary(errorData);

        if (state)
            properties['state'] = state;

        this.appInsights.trackTrace(telemetry, properties);    
    }

    _attachPageTracker() {
        if (!this.options.pageTracking.enabled)
            return;

        this.eventAggregator.subscribe('router:navigation:success', payload => {
            this.trackPageView(
                payload.instruction.fragment,
                this.options.pageTracking.getTitle(payload)
            );
        });
    }

    _attachClickTracker() {
        if (!this.options.clickTracking.enabled)
            return;

        document.querySelector('body').addEventListener(
            'click',
            delegate(this.options.clickTracking.filter, this.trackClick),
            false
        );
    }

    _convertDataToDictionary(errorData: ApplicationInsightsErrorData) {
        let properties = {};

        if (errorData.loggerName)
            properties['loggerName'] = errorData.loggerName;

        if (errorData.apiErrorCode)
            properties['apiErrorCode'] = errorData.apiErrorCode;

        if (errorData.exception)
            properties['exception'] = errorData.exception;

        if (errorData.httpMethod)
            properties['httpMethod'] = errorData.httpMethod;

        if (errorData.requestId)
            properties['requestId'] = errorData.requestId;

        if (errorData.statusCode)
            properties['statusCode'] = errorData.statusCode;

        if (errorData.url)
            properties['url'] = errorData.url;

        return properties;
    }
}
