import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import SelectorOption from 'infrastructure/selector-option';
import { getRecordStatusOptions } from 'record-status';
import CompareUtility from '../../infrastructure/compare-utility';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import SecurityService from '../../security/security-service';
import UserService from '../../users/user-service';
import { MapListQuery, MapListQueryFilters } from './map-list-query-filters';
import MapService from './map-service';

@autoinject
export class MapList {
    @observable filterText;
    @observable selectedMaps;

    // configuration
    gridOptions: any;
    applyFiltersSubscription: any;
    clearFiltersSubscription: any;

    // permissions
    canEditMaps: any;

    // state
    maps: any;
    mapsView: any;
    attributes: any[];

    allSelected: boolean;
    selectedActiveMaps: any[];
    organizations: any;
    recordStatusOptions: SelectorOption[];

    mapListQueryFilters: MapListQueryFilters;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private userService: UserService,
        private mapService: MapService
    ) {
        this.router = router;
        this.eventAggregator = eventAggregator;

        this.logger = logger;
        this.logger.name = 'map-list';

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.securityService = securityService;
        this.userService = userService;
        this.mapService = mapService;
        this.filterText = '';
        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template: '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate: '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true
                },
                { suppressMenu: true, headerName: 'Map Name', field: 'name', comparator: CompareUtility.compareStringsInsensitive, width: 150, sort: 'asc' },
                { suppressMenu: true, headerName: 'Organization', field: 'organizationName', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                { suppressMenu: true, headerName: 'Sample Points', field: 'pointCount' },
                { suppressMenu: true, headerName: 'Sampling Plans', field: 'planCount' },
                { suppressMenu: true, headerName: 'Open Remediations', field: 'openRemediationCount' },
                { suppressMenu: true, headerName: 'Open Tasks', field: 'openTaskCount' },
                { suppressMenu: true, headerName: 'Unassigned Tasks', field: 'unassignedTaskCount' },
                {
                    suppressMenu: true,
                    headerName: 'Active',
                    field: 'isActive',
                    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button click.delegate="navigateToMapDashboard(data.id)">
                            <i class="fa fa-map-o"></i>
                        </button>
                        <button click.delegate="navigateToMapDetail(data.id)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button if.bind="canEditMaps" click.trigger="deleteMap(data)" disabled.bind="!data.isActive">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 150,
                    sortable: false,
                    suppressSizeToFit: true
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.allSelected = false;
        this.selectedActiveMaps = [];
        this.mapListQueryFilters = new MapListQueryFilters();
        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
        this.attributes = [];

        this.canEditMaps = this.securityService.hasPermission('EditMaps') && !this.securityService.isImpersonating();
    }

    navigateToMapDashboard(id) {
        this.router.navigateToRoute('map-dashboard', { id });
    }

    navigateToMapDetail(id) {
        this.router.navigateToRoute('map-detail', { id });
    }

    setSelectedActiveMaps() {
        this.selectedActiveMaps = this.mapsView.filter(map => map.isSelected && map.isActive);
    }

    isSelectedChanged() {
        this.allSelected = this.mapsView.every(map => map.isSelected);
        this.setSelectedActiveMaps();
    }

    allSelectedClicked() {
        if (this.mapsView) {
            this.allSelected = !this.mapsView.every(map => map.isSelected);

            for (let map of this.mapsView)
                map.isSelected = this.allSelected;
        }

        this.setSelectedActiveMaps();

        return true;
    }

    filterTextChanged() {
        this.updateMapsView();
    }

    handleApplyFilters() {
        const query = this.mapListQueryFilters.getQueryParams();
        this.router.navigateToRoute('map-list', this.mapListQueryFilters.getQueryParams());
    }

    handleClearFilters() {
        this.mapListQueryFilters.reset();
        this.router.navigateToRoute('map-list', this.mapListQueryFilters.getQueryParams());
    }

    updateMapsView() {
        var lowerCasedFilterText = this.filterText.toLowerCase();

        if (this.maps) {
            this.mapsView = this.maps.filter(u =>
                this.matchSelectedOrganizations(u) && (
                    (u.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (u.organizationName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1
                )
            );

            this.allSelected = this.mapsView.every(map => map.isSelected);
        }
    }

    matchSelectedOrganizations(map) {
        if (!this.mapListQueryFilters.organizationQueryFilters.organizationIds?.length)
            return true;

        return this.mapListQueryFilters
            .organizationQueryFilters
            .organizationIds
            .some((id: number) => id === map.organizationId);
    }

    async loadMaps() {
        this.maps = await this.mapService.getMaps(this.mapListQueryFilters.getQueryParams());
        this.updateMapsView();
    }

    activate(params: MapListQuery) {
        this.mapListQueryFilters.setFilterValues(params);
        this.applyFiltersSubscription = this.eventAggregator.subscribe('filters.apply', this.handleApplyFilters);
        this.clearFiltersSubscription = this.eventAggregator.subscribe('filters.clear', this.handleClearFilters);

        (async () => {
            this.pageContext.isLoading = true;

            try {
                const [userOrganizations, recordStatusOptions] = await Promise.all([
                    this.userService.getCurrentUserOrganizations(),
                    getRecordStatusOptions(),
                    this.loadMaps()
                ]);

                this.organizations = userOrganizations;
                this.recordStatusOptions = recordStatusOptions;
            } catch (error) {
                this.logger.error('Error loading maps.', error);
                this.dialogPresenter.showAlert(
                    'Error Loading Maps',
                    'An error occurred while loading the maps. Please try again later.');
            }

            this.pageContext.isLoading = false;
        })();
    }

    deactivate() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
        this.clearFiltersSubscription && this.clearFiltersSubscription.dispose();
    }

    deleteMap(map) {
        this.dialogPresenter
            .showConfirmation('Delete Map', 'Are you sure you want to delete this map?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                this.pageContext.isLoading = true;
                this.mapService
                    .deleteMaps([map.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Map deleted successfully.');

                        map.isSelected = false;
                        map.isActive = false;

                        this.setSelectedActiveMaps();
                    })
                    .catch(error => {
                        this.logger.error('Error deleting map.', error, { mapId: map.id });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, false);
                    });
            });
    }

    deleteSelectedMaps() {
        var mapsToDelete = this.mapsView.filter(u => u.isSelected);
        if (!mapsToDelete.length)
            return;

        this.dialogPresenter
            .showConfirmation('Delete Selected Maps', 'Are you sure you want to delete the selected maps?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                var mapIds = mapsToDelete.map(map => map.id);

                this.pageContext.isLoading = true;
                this.mapService
                    .deleteMaps(mapIds)
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Selected maps deleted successfully.');

                        for (let map of mapsToDelete) {
                            map.isSelected = false;
                            map.isActive = false;

                            this.setSelectedActiveMaps();
                        }
                    })
                    .catch(error => {
                        this.logger.error('Error deleting maps.', error, { mapIds });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, true);
                    });
            });
    }

    handleDeleteError(error, multipleMaps) {
        this.dialogPresenter
            .showAlert(
                'Error Deleting Map' + (multipleMaps ? 's' : ''),
                this.getApiErrorMessage(error.apiErrorCode, multipleMaps));
    }

    getApiErrorMessage(errorCode, multipleMaps) {
        if (errorCode === 2)
            return 'You dont have access to delete the map' + (multipleMaps ? 's' : '');
        else
            return 'An unexpected error occurred while trying to delete maps. Please try again later.';
    }
}
