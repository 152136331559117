import { BaseQueryFilter } from './base-query-filter';
import { QueryFiltered } from './query-filtered';

export interface MapPlanQuery {
    mapId?: number;
    planId?: number;
    mapIds: number[];
    planIds: number[];
}

export class MapPlanQueryFilter extends BaseQueryFilter
    implements MapPlanQuery, QueryFiltered<MapPlanQuery> {

    private _mapPlanIds: number[];
    private _mapPlanFilterType: string;

    constructor() {
        super();

        this._mapPlanIds = [];
        this._mapPlanFilterType = 'map';
    }
    mapId: number;
    planId: number;

    public get mapPlanFilterType() {
        return this._mapPlanFilterType;
    }

    public set mapPlanFilterType(mpFilterType: string) {
        this._mapPlanFilterType = mpFilterType;
        this._mapPlanIds = [];
    }

    public get mapIds() {
        return this._mapPlanFilterType === 'map' ? this._mapPlanIds : [];
    }

    public set mapIds(ids: number[]) {
        this._mapPlanFilterType = 'map';
        this._mapPlanIds = ids || [];
    }

    public get planIds() {
        return this._mapPlanFilterType === 'plan' ? this._mapPlanIds : [];
    }

    public set planIds(ids: number[]) {
        this._mapPlanFilterType = 'plan';
        this._mapPlanIds = ids || [];
    }

    public get mapPlanIds() {
        return this._mapPlanIds;
    }

    public set mapPlanIds(ids: number[]) {
        this._mapPlanIds = ids || [];
    }

    public get count(): number {
        return (Number(!!this._mapPlanIds.length));
    }

    reset(): void {
        this._mapPlanIds = [];
        this._mapPlanFilterType = 'map';
    }

    getQueryParams(): MapPlanQuery {
        return {
            mapIds: this.mapIds,
            planIds: this.planIds,
        };
    }

    setFilterValues(params: MapPlanQuery): void {
        if (!params) {
            return;
        }

        // NOTE: multiple mapId or plansId may be specified via the query string.
        //       if planId is specified (contrained), it takes precedence over the mapId      
        if (params.mapId) {
            params.mapIds = Array.isArray(params.mapId) ? params.mapId : [params.mapId];
        }
        if (params.planId) {
            params.planIds = Array.isArray(params.planId) ? params.planId : [params.planId];
        }

        super.setQueryFilteredValues(params, this);
    }
}
