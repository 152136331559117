import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import CompareUtility from 'infrastructure/compare-utility';
import DataGridSelectAll from 'infrastructure/components/data-grid-select-all';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import SelectorOption from 'infrastructure/selector-option';
import Organization from 'organizations/organization';
import RecordStatus, { getRecordStatusOptions } from 'record-status';
import SecurityService from 'security/security-service';
import UserService from 'users/user-service';
import { ExportTaskCollectionTemplate } from './export-task-collection-template';
import { ExportTaskCollectionTemplateListQuery, ExportTaskCollectionTemplateListQueryFilters } from './export-task-collection-template-list-query-filters';
import ExportTaskCollectionTemplateService from './export-task-collection-template-service';
import UiViewExportTaskCollectionTemplate from './ui-view-export-task-collection-template';

@autoinject
export class ExportTaskCollectionTemplateList {
    @observable filterText;

    gridOptions: any;
    dataGridSelectAll: DataGridSelectAll;
    exportTaskCollectionTemplateListQueryFilters: ExportTaskCollectionTemplateListQueryFilters

    applyFiltersSubscription: Subscription;
    clearFiltersSubscription: Subscription;

    recordStatusOptions: SelectorOption[];
    selectedRecordStatus: RecordStatus;

    organizations: Organization[];
    selectedOrganizations: any[] = [];
    filterCount: number;
    canEditExportTaskCollectionTemplates: boolean;

    constructor(
        private dialogPresenter: DialogPresenter,
        private eventAggregator: EventAggregator,
        private exportTaskCollectionTemplateService: ExportTaskCollectionTemplateService,
        private logger: Logger,
        private pageContext: PageContext,
        private router: Router,
        private securityService: SecurityService,
        private userService: UserService
    ) {
        this.dataGridSelectAll = new DataGridSelectAll([], ['_displayName']);
        this.logger.name = 'export-task-collection-template-list';
        this.filterText = '';

        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template: '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="dataGridSelectAll.isSelectedChanged()"></label>',
                    headerCellTemplate: '<label><input type="checkbox" checked.bind="dataGridSelectAll.allSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: 'Configured For (Template Name)',
                    field: 'displayName',
                    template: '<span title="${data.displayName}">${data.displayName}</span>',
                    comparator: CompareUtility.compareStringsInsensitive,
                },
                {
                    suppressMenu: true,
                    headerName: 'Active',
                    field: 'isActive',
                    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button click.delegate="navigateToDetail(data.id)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button if.bind="canEditExportTaskCollectionTemplates" click.trigger="deleteExportTaskCollectionTemplate(data)" disabled.bind="!data.isActive">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerClass: 'row-actions',
                    width: 150,
                    sortable: false,
                    suppressSizeToFit: true
                },
            ],
            defaultColDef: {
                sortable: true
            }
        };

        this.exportTaskCollectionTemplateListQueryFilters = new ExportTaskCollectionTemplateListQueryFilters();
        this.selectedRecordStatus = RecordStatus.ACTIVE;
        this.canEditExportTaskCollectionTemplates = this.securityService.hasPermission('EditExportTaskCollectionTemplates') && !this.securityService.isImpersonating();
        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
    }

    handleApplyFilters() {
        this.router.navigateToRoute(
            'export-task-collection-template-list',
            this.exportTaskCollectionTemplateListQueryFilters.getQueryParams());
    }

    handleClearFilters() {
        this.dataGridSelectAll.allSelected = false;
        this.exportTaskCollectionTemplateListQueryFilters.reset();
        this.router.navigateToRoute(
            'export-task-collection-template-list',
            this.exportTaskCollectionTemplateListQueryFilters.getQueryParams());
    }

    filterTextChanged() {
        this.dataGridSelectAll.filterDataGridItems(this.filterText);
    }

    navigateToDetail(id: number) {
        this.router.navigateToRoute('export-task-collection-template-detail', { id });
    }

    private async deleteExportTaskCollectionTemplates(ids: number[]) {
        if (!ids.length || ids.length === 0) {
            return;
        }
        const itemsToDelete = this.dataGridSelectAll.dataGridItems.filter(x => ids.includes(x.id));
        const s: string = ids.length > 1 ? 's' : '';
        const confirmed = await this.dialogPresenter.showConfirmation(
            `Delete Export Task Collection Template${s}`,
            `Are you sure you want to delete ${ids.length > 1 ? 'these' : 'this'} export task collection template${s}?`);
        if (!confirmed) {
            return;
        }

        this.pageContext.isLoading = true;
        try {
            await this.exportTaskCollectionTemplateService.deleteExportTaskCollectionTemplates(ids);
            this.pageContext.showSuccessOverlay(`Export task collection template${s} deleted successfully.`);

            itemsToDelete.forEach(item => {
                item.isActive = false;
                item.isSelected = false;
                var index = this.dataGridSelectAll.dataGridItems.indexOf(item);
                const dataItems = this.dataGridSelectAll.dataGridItems.splice(index, 1);
                this.dataGridSelectAll.dataGridItems = dataItems;
            });
        } catch (error) {
            this.logger.error(`Error deleting export task collection template${s}.`, error, {
                exportTaskCollectionTemplateIds: ids,
            });
            this.handleDeleteError(error, s.length);
        }

        this.pageContext.isLoading = false;
    }

    async deleteExportTaskCollectionTemplate(item: UiViewExportTaskCollectionTemplate) {
        await this.deleteExportTaskCollectionTemplates([item.id]);
    }

    async deleteSelectedTemplates() {
        const idsToDelete = this.dataGridSelectAll.selectedDataGridItems.map(i => i.id);
        await this.deleteExportTaskCollectionTemplates(idsToDelete);
    }

    handleDeleteError(error, multiple) {
        this.dialogPresenter
            .showAlert(
                'Error Deleting Label Template' + (multiple ? 's' : ''),
                this.getApiErrorMessage(error.apiErrorCode, multiple));
    }

    getApiErrorMessage(errorCode, multiple) {
        if (errorCode === 2)
            return 'You dont have access to delete the label template' + (multiple ? 's' : '');

        return 'An unexpected error occurred while trying to delete label templates. Please try again later.';
    }

    activate(params: ExportTaskCollectionTemplateListQuery) {
        this.exportTaskCollectionTemplateListQueryFilters.setFilterValues(params);
        this.applyFiltersSubscription = this.eventAggregator.subscribe('filters.apply', this.handleApplyFilters);
        this.clearFiltersSubscription = this.eventAggregator.subscribe('filters.clear', this.handleClearFilters);

        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.recordStatusOptions = getRecordStatusOptions();

                const [organizations, exportTaskCollectionTemplates] = await Promise.all([
                    this.userService.getCurrentUserOrganizations(),
                    this.exportTaskCollectionTemplateService.getExportTaskCollectionTemplates(params),
                ]);

                this.organizations = organizations;
                const dataGridItems = exportTaskCollectionTemplates.map((t: ExportTaskCollectionTemplate) =>
                    new UiViewExportTaskCollectionTemplate(t.id, t.organizationConfigurations, t.isActive, organizations));

                this.dataGridSelectAll.dataGridItems = dataGridItems;
                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error loading export task collection templates.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Export Task Collection Templates',
                    'An error occurred while loading the export task collection templates. Please try again later.');
            }
        })();
    }

    deactivate() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
        this.clearFiltersSubscription && this.clearFiltersSubscription.dispose();
    }
}
