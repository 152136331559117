import { Container } from 'aurelia-framework';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import Logger from '../infrastructure/logger';
import PageContext from '../infrastructure/page-context';

// NOTE: This must be submitted manually as the try/catch will not handle any server side errors.
export function submitCustomCertiticateForm(customCoaForm: HTMLFormElement) {
    const dialogPresenter = Container.instance.get(DialogPresenter);
    const logger = Container.instance.get(Logger);
    const pageContext = Container.instance.get(PageContext);

    pageContext.isLoading = true;

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.open('POST', '/api/results/custom-certificates', true);
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                const type = xhr.getResponseHeader('Content-Type');
                const contentDispositionHeader = xhr.getResponseHeader('Content-Disposition');
                const blob = new Blob([xhr.response], { type });

                const regExpFileName = /filename=(?<filename>.*)/;
                const fileName: string | null =
                    regExpFileName.exec(contentDispositionHeader)?.groups?.filename ?? null;

                let a = document.createElement('a');
                document.body.appendChild(a);
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);

                pageContext.isLoading = false;
                pageContext.showSuccessOverlay('Certificate download started successfully.');
            } else {
                pageContext.isLoading = false;
                logger.error(
                    'Error downloading certificate.',
                    'A server side error occured while trying to download custom cert',
                );
                dialogPresenter.showAlert(
                    'Error Downloading Certificate',
                    'An error occurred while downloading the certificate. Please try again later.',
                );
            }
        }
    };

    const formData = new FormData(customCoaForm);
    xhr.send(formData);
}
