import { autoinject, transient } from 'aurelia-framework';
import { Guid } from 'guid-typescript';
import SelectorOption from 'infrastructure/selector-option';
import Point from './points/point';
import PointAttribute from './points/point-attribute';
import PointService from './points/point-service';

interface PointAttributeOptions {
    id: Guid;
    nameOptions: SelectorOption[];
    valueOptions: SelectorOption[];
    selectedValues: string[];
}

@transient()
@autoinject
export default class PointSelectorFilter {
    name: string;
    description: string;
    selectedRooms: string[];
    selectedZones: number[];
    selectedMobileValues: boolean[];
    selectedPausedValues: boolean[];
    selectedTypes: string[];
    selectedRisks: string[];
    selectedAttributes: PointAttribute[];   // these also have the properties defined in the PointAttributeOptions interface grafted in

    filterCount: number;
 
    roomOptions: SelectorOption[];
    zoneOptions: SelectorOption[];
    mobileOptions: SelectorOption[];
    pauseOptions: SelectorOption[];
    typeOptions: SelectorOption[];
    riskOptions: SelectorOption[];
    attributeNameOptions: SelectorOption[];

    constructor(
        private pointService: PointService
    ) {
        this.roomOptions = [];
        this.zoneOptions = [];
        this.mobileOptions = [];
        this.pauseOptions = [];
        this.typeOptions = [];
        this.riskOptions = [];

        this.attributeNameOptions = [];

        this.reset();
    }

    reset() {
        this.name = '';
        this.description = '';
        
        this.selectedRooms = [];
        this.selectedZones = [];
        this.selectedMobileValues = [];
        this.selectedPausedValues = [];
        this.selectedTypes = [];
        this.selectedRisks = [];
        this.selectedAttributes = [];

        this.filterCount = 0;
    }

    setupFilterOptions(points: Point[], pointAttributes: PointAttribute[] = []) {
        this.roomOptions = Array.from(new Set((points?.filter(p => p.room !== null) ?? [])
            .map(p => p.room)).values())
            .map(v => ({ title: v, value: v })) as SelectorOption[];
        this.zoneOptions = Array.from(new Set((points ?? []).map(p => p.zone)).values()).map(v => ({ title: v.toString(), value: v })) as SelectorOption[];
        this.mobileOptions = [{ title: 'Yes', value: true }, { title: 'No', value: false }] as unknown[] as SelectorOption[];
        this.pauseOptions = [{ title: 'Yes', value: true }, { title: 'No', value: false }] as unknown[] as SelectorOption[];
        this.typeOptions = Array.from(new Set((points ?? []).filter(p => !!p.type).map(p => p.type)).values()).map(v => ({ title: v, value: v })) as SelectorOption[];
        this.riskOptions = Array.from(new Set((points ?? []).map(p => p.risk)).values()).map(v => ({ title: this.pointService.getRiskCaption(v), value: v })) as SelectorOption[];

        // determine list of in-use values for each attribute name
        let pointAttributeValuesMap = new Map();
        pointAttributes.forEach(a => {
            if (pointAttributeValuesMap.has(a.name)) {
                let group = pointAttributeValuesMap.get(a.name) as string[];
                if (!group.some(s => s === a.value))
                    group.push(a.value);
            } else
                pointAttributeValuesMap.set(a.name, [a.value]);
        });

        // determine unique list of in-use attribute names
        let pointAttributeNames = pointAttributes.reduce((attributes, attribute) => {
            if (!attributes.some(a => a.name.toLowerCase() === attribute.name.toLowerCase()))
                attributes.push({ name: attribute.name, value: attribute.name });

            return attributes;
        }, []);

        // create the corresponding options for attribute names and values
        this.attributeNameOptions = pointAttributeNames.map(a => ({ title: a.name, value: a.value })) as SelectorOption[];
        this.attributeNameOptions.forEach(attributeNameOption => {
            let attributeValues = (pointAttributeValuesMap.get(attributeNameOption.value) ?? []) as string[];
            
            let pointAttribute = attributeNameOption as any;
            pointAttribute.valueOptions = attributeValues.map(v => ({ title: v, value: v})) as SelectorOption[];
        });
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    applyFilters(points: Point[], attributeFiltersApplied: boolean): Point[] {
        if (!points)
            return [];

        // NOTE: point attribute filters are not performed client-side
        this.filterCount =
            ((this.name ?? '').length > 0 ? 1 : 0) +
            ((this.description ?? '').length > 0 ? 1 : 0) +
            ((this.selectedRooms?.length ?? 0) > 0  ? 1 : 0) +
            ((this.selectedZones?.length ?? 0) > 0  ? 1 : 0) +
            ((this.selectedMobileValues?.length ?? 0) > 0 ? 1 : 0) +            
            ((this.selectedPausedValues?.length ?? 0) > 0 ? 1 : 0) +
            ((this.selectedTypes?.length ?? 0) > 0  ? 1 : 0) +
            ((this.selectedRisks?.length ?? 0) > 0  ? 1 : 0) +
            (attributeFiltersApplied ? 1 : 0);

        return points.filter(p =>
            p.name.toLowerCase().indexOf((this.name ?? '').toLowerCase()) !== -1 &&
            (p.description ?? '').toLowerCase().indexOf((this.description ?? '').toLowerCase()) !== -1 &&
            ((this.selectedRooms?.length ?? 0) === 0 || this.selectedRooms.some(v => v.toLowerCase() === (p.room ?? '').toLowerCase())) &&
            ((this.selectedZones?.length ?? 0) === 0 || this.selectedZones.some(v => v === p.zone)) &&
            ((this.selectedMobileValues?.length ?? 0) === 0 || this.selectedMobileValues.some(v => v === p.mobile)) &&
            ((this.selectedPausedValues?.length ?? 0) === 0 || this.selectedPausedValues.some(v => v === p.isPausedPendingRemediation)) &&
            ((this.selectedTypes?.length ?? 0) === 0 || this.selectedTypes.some(v => v.toLowerCase() === (p.type ?? '').toLowerCase())) &&
            ((this.selectedRisks?.length ?? 0) === 0 || this.selectedRisks.some(v => v === p.risk))
        );
    }

    getAttributeValueSelectorOptions(attributeName: string): SelectorOption[] {
        let matchingAttributes = this.attributeNameOptions.filter(o => o.value === attributeName);
        if (matchingAttributes.length > 0) {
            let attribute = matchingAttributes[0] as any;
            return attribute.valueOptions as SelectorOption[];
        }
        
        return [];
    }

    convertPointAttributeSelectionsToPointAttributes(): PointAttribute[] {
        let selectedAttributes = this.selectedAttributes.filter(selectedAttribute => selectedAttribute.name?.length > 0) as (PointAttribute & PointAttributeOptions)[];
        selectedAttributes = selectedAttributes.filter(selectedAttribute => selectedAttribute.selectedValues?.length > 0);

        let pointAttributes = [];
        selectedAttributes.forEach(selectedAttribute => {
            selectedAttribute.selectedValues.forEach(selectedValue => {
                pointAttributes.push({name: selectedAttribute.name, value: selectedValue });
            });
        })

        return pointAttributes as PointAttribute[];
    }

    addPointAttributeFilter() {
        // determine attribute name options not in use
        let attributeNamesInUse = this.selectedAttributes.map(a => a.name);
        let attributeNameOptions = this.attributeNameOptions;
        attributeNameOptions.forEach(o => {
            o.disabled = attributeNamesInUse.includes( (o.value) as string);
        });

        // graft in properties used for filtering
        let pointAttribute = {
            id: Guid.create(),
            nameOptions: attributeNameOptions,
            valueOptions: null,     // this is determined after a selection is made
            selectedValues: null
        } as PointAttributeOptions;

        this.selectedAttributes.push(pointAttribute as unknown as PointAttribute);
    }

    informAttributeNameInUse(attribute: PointAttribute, attributeName: string) {
        attribute.name = attributeName;

        // get the set of attribute names now in use and consider new one
        let attributeNamesInUse = this.selectedAttributes.map(a => a.name);
        attributeNamesInUse.push(attributeName);

        let pointAttributeOption = attribute as PointAttribute &PointAttributeOptions;
        pointAttributeOption.selectedValues = null;
        pointAttributeOption.valueOptions = this.getAttributeValueSelectorOptions(pointAttributeOption.name);

        // exclude this option as choice for the other attributes
        let otherSelectedAttributes = (this.selectedAttributes as (PointAttribute & PointAttributeOptions)[]).filter(a => a.id !== pointAttributeOption.id);
        otherSelectedAttributes.forEach(selectedAttribute => {
            let pointAttributeNameOptions = selectedAttribute.nameOptions as SelectorOption[];

            let option = pointAttributeNameOptions.find(o => (o.value as string) === attributeName);
            if (option)
                option.disabled = true;
        });
    }

    informAttributeNameNoLongerInUse(index: number) {
        let pointAttributeOption = this.selectedAttributes[index] as PointAttribute & PointAttributeOptions;

        // get the attribute name option that we are adding back
        let attributeName = pointAttributeOption.name;

        let otherSelectedAttributes = this.selectedAttributes as (PointAttribute & PointAttributeOptions)[];
        otherSelectedAttributes.forEach(selectedAttribute => {
            let attributeNameOptions = selectedAttribute.nameOptions as SelectorOption[];
            let option = attributeNameOptions.find(o => (o.value as string) === attributeName);
            if (option)
                option.disabled = false;
        });

        this.selectedAttributes.splice(index, 1)
    }
}
