import dragula from 'dragula';
import { autoinject } from 'aurelia-framework';

@autoinject
export class DraggableFieldContainerCustomAttribute {
    dragula: any;
    sourceIndex: number;

    constructor(private element: Element) {
    }

    attached() {
        this.dragula = dragula([this.element], {
            revertOnSpill: true,
            moves: (el, source, handle, sibling) => {
                return Array.prototype.some.call(handle.attributes, a => a.name === 'drag-handle');
            },
            accepts: (el, target, source, sibling) => {
                // This ensures that the target will be followed by a sibling and not be last (since the last field is the blank key field).
                return !!sibling;
            },
        });

        this.dragula.on('drag', el => {
            var children = Array.from(this.element.children);
            this.sourceIndex = children.indexOf(el);
        });

        this.dragula.on('drop', (el, container, source, sibling) => {
            var children = Array.from(container.children);
            var targetIndex = children.indexOf(sibling) - 1;

            this.dragula.cancel();
            this.element.dispatchEvent(new CustomEvent('fielddragged', { bubbles: true, detail: { sourceIndex: this.sourceIndex, targetIndex } }));
        });
    }

    detached() {
        this.dragula && this.dragula.destroy();
    }
}
