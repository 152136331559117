import { inject } from 'aurelia-framework';
import $ from 'jquery';

@inject(Element)
export class DataEqualizerCustomAttribute {
    constructor(element) {
        this.element = element;
        this.initializeEqualizer = this.initializeEqualizer.bind(this);
    }

    attached() {
        this.$equalizer = $(this.element);

        window.addEventListener('optimizedresize', this.initializeEqualizer);
        this.observer = new MutationObserver(this.initializeEqualizer);
        this.observer.observe(
            this.element,
            { attributes: false, childList: true, characterData: false }
        );

        this.initializeEqualizer();
    }

    detached() {
        window.removeEventListener('optimizedresize', this.initializeEqualizer);
        this.observer && this.observer.disconnect();
        
        var equalizer = this.$equalizer.data('zfPlugin');
        if (equalizer)
            equalizer.destroy();
    }

    initializeEqualizer() {
        if (!this.$equalizer)
            return;

        var equalizer = this.$equalizer.data('zfPlugin');
        if (equalizer)
            Foundation.reInit(this.$equalizer);
        else
            this.$equalizer.foundation();
    }
};
