import {
    ChartRef,
    FirstDataRenderedEvent,
    GridOptions,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { Grid } from 'ag-grid-enterprise/dist/ag-grid-enterprise';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PLATFORM, autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { expandRowAtIndex } from 'infrastructure/ag-grid/ag-onfirstdatarendered-handler';
import { CustomError } from 'infrastructure/custom-error';
import { downloadSsrsReport } from 'reporting/reports/report-downloader';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import Logger from '../infrastructure/logger';
import { PartialTestResult } from './partial-test-result';
import { partialTestResultGrid } from './partial-test-results-grid';
import {
    PartialTestResultListQuery,
    PartialTestResultsQueryFilters,
} from './partial-test-results-query-filters';
import { PartialTestResultService } from './partial-test-results-service';

@autoinject
export class PartialTestResultList {
    @observable filterText: string;

    gridOptions: GridOptions;
    partialTestResultsGrid: any;
    currentChartRef: ChartRef;
    partialTestResults: PartialTestResult[];
    dataGridRowSelected: boolean;

    partialTestResultQueryFilter: PartialTestResultsQueryFilters;
    selectedOutcomeView: any;

    applyFiltersSubscription: Subscription;
    clearFiltersSubscription: Subscription;

    constructor(
        private router: Router,
        private partialTestResultService: PartialTestResultService,
        private logger: Logger,
        private dialogPresenter: DialogPresenter,
        private dialogService: DialogService,
        private eventAggregator: EventAggregator,
    ) {
        this.partialTestResultQueryFilter = new PartialTestResultsQueryFilters();
        this.logger.name = 'partial-test-results-list';
        this.applyFiltersSubscription = this.eventAggregator.subscribe('filters.apply', () =>
            this.handleApplyFilters(),
        );
        this.clearFiltersSubscription = this.eventAggregator.subscribe('filters.clear', () =>
            this.handleClearFilters(),
        );
    }

    filterTextChanged(newValue: string) {
        this.gridOptions.api.setQuickFilter(newValue);
    }

    async loadTestResultsData() {
        const queryParams = this.partialTestResultQueryFilter.getQueryParams();
        try {
            this.partialTestResults = await this.partialTestResultService.getTestResults(
                queryParams,
            );
        } catch (error) {
            this.logger.error('Error loading partial test results', error);
            this.dialogPresenter.showAlert(
                'Error Loading Partial Test Results Data',
                'An error occurred while loading test result data. Please try again later.',
            );
        }

        this.gridOptions?.api.setRowData(this.partialTestResults);
    }

    handleApplyFilters() {
        const query = this.partialTestResultQueryFilter.getQueryParams();
        this.router.navigateToRoute('partial-test-results-list', query);
    }

    handleClearFilters() {
        this.partialTestResultQueryFilter.reset();
        const query = this.partialTestResultQueryFilter.getQueryParams();
        this.router.navigateToRoute('partial-test-results-list', query);
    }

    async downloadCustomCOA() {
        const rows = this.gridOptions.api.getSelectedRows();
        const sampleIds = [...new Set(rows.map((s: PartialTestResult) => s.sampleId))];
        if (sampleIds.length > 2100) {
            throw new CustomError(
                `The maximum number of samples allowed is 2100.  You selected '${sampleIds.length}'.  Please try again.`,
                4,
            );
        }

        this.dialogService
            .open({
                viewModel: PLATFORM.moduleName('status/custom-coa-dialog'),
                model: {},
            })
            .whenClosed((result) => {
                if (result.wasCancelled) {
                    return;
                }

                downloadSsrsReport('api/partial-test-results/custom-certificates', {
                    ids: sampleIds,
                    customKeyValues: result.output.values,
                });
            });
    }

    async canActivate(params: PartialTestResultListQuery) {
        this.partialTestResultQueryFilter.setFilterValues(params);
        await this.loadTestResultsData();
    }

    bind() {
        this.gridOptions = partialTestResultGrid;
        this.gridOptions.rowData = this.partialTestResults;
        this.gridOptions.onFirstDataRendered = (evt: FirstDataRenderedEvent) =>
            expandRowAtIndex(evt, 1);
        this.gridOptions.onSelectionChanged = (evt: SelectionChangedEvent) => {
            this.dataGridRowSelected = evt.api.getSelectedNodes().length > 0;
        };
        new Grid(this.partialTestResultsGrid, this.gridOptions);
    }

    canDeactivate() {
        this.currentChartRef?.destroyChart();
    }

    deactivate() {
        this.applyFiltersSubscription?.dispose();
        this.clearFiltersSubscription?.dispose();
    }
}
