import { ICellRendererParams } from 'ag-grid-community';

export const AgBoolCellRenderer = (params: ICellRendererParams) => {
    const { value }: { value: boolean } = params;
    if (typeof value !== 'boolean') {
        throw new Error('AgBoolRenderer requires a cell value of type boolean');
    }

    return value ? '<i class="fa fa-check"></i>' : '<i class="fa fa-times"></i>';
};
