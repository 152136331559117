import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class UploadedFileService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async uploadFiles(path, files, reportProgress) {
        var fileUrls = [];
        var formData = new FormData();

        for (let file of files) {
            formData.append('files', file);
            fileUrls.push(`${path}/${file.name}`);
        }

        await this
            .httpClient
            .createRequest(path)
            .asPut()
            .withContent(formData)
            .withProgressCallback(reportProgress)
            .send();
        
        return fileUrls;
    }
}
