import { autoinject, buildQueryString } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { ExportTaskCollectionTemplate } from './export-task-collection-template';

@autoinject
export default class ExportTaskCollectionTemplateService {
    constructor(private httpClient: HttpClient) {

    }

    getExportTaskCollectionTemplates(params?: any): Promise<ExportTaskCollectionTemplate[]> {
        const query = buildQueryString(params, true);
        return this.httpClient.get(`export-task-collection-templates${query ? '?' + query : ''}`) as any as Promise<ExportTaskCollectionTemplate[]>;
    }

    getExportTaskCollectionTemplate(id: number): Promise<ExportTaskCollectionTemplate>  {
        return this.httpClient.get(`export-task-collection-templates/${id}`) as any as Promise<ExportTaskCollectionTemplate>;
    }

    createExportTaskCollectionTemplates(exportTaskCollectionTemplate: ExportTaskCollectionTemplate): Promise<ExportTaskCollectionTemplate> {
        return this.httpClient.post('export-task-collection-templates', exportTaskCollectionTemplate) as any as Promise<ExportTaskCollectionTemplate>;
    }

    updateExportTaskCollectionTemplates(exportTaskCollectionTemplate: ExportTaskCollectionTemplate): Promise<ExportTaskCollectionTemplate> {
        return this.httpClient.put('export-task-collection-templates', exportTaskCollectionTemplate) as any as Promise<ExportTaskCollectionTemplate>;
    }    
    
    deleteExportTaskCollectionTemplates(ids: number[]) {
        var query = ids.map(id => `id=${id}`).join('&');
        return this.httpClient.delete(`export-task-collection-templates?${query}`) as any as Promise<ExportTaskCollectionTemplate[]>;
    }

    getExportTaskCollectionTemplateColumns(): Record<string, string> {
        return this.httpClient.get('export-task-collection-templates/columns') as any as Record<string, string>;
    }
}
