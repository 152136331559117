export default class SegmentType {
    static Unknown = new SegmentType('Unknown');
    static Text = new SegmentType('Text');
    static Selection = new SegmentType('Selection');

    name: string;

    constructor(name: string) {
        this.name = name;
    }

    toString() {
        return this.name;
    }

    static getOptions() {
        return [
            { title: 'Text', value: SegmentType.Text.name },
            { title: 'Selection', value: SegmentType.Selection.name },
        ];
    }
}
