let httpCodesToHandle = [
    500,
    404,
    403,
    400,
];

export default {
    responseError(message) {
        if (httpCodesToHandle.indexOf(message.statusCode) !== -1) {
            // Throw an "API" error object that contains the error code and data that the UI can switch on.
            if (message.content && typeof message.content.apiErrorCode !== 'undefined') {
                let error = new Error(`API Error: ${message.content.apiErrorCode}`);
                error.apiErrorCode = message.content.apiErrorCode;
                error.apiErrorData = message.content.apiErrorData;

                if (message.requestMessage) {
                    error.url = window.location.origin + message.requestMessage.baseUrl + message.requestMessage.url;
                    error.httpMethod = message.requestMessage.method;
                }

                if (message.headers)
                    error.httpRequestId = message.headers.get('x-http-request-id');

                if (message.statusCode)
                    error.httpStatusCode = message.statusCode;

                throw error;
            }
        }

        throw message;
    }
};
