
import $ from 'jquery';

let dates = $.fn.fdatepicker.dates;

function UTCDate() {
    return new Date(Date.UTC.apply(Date, arguments));
}

export function parseDate(date, format, language) {
    if (date instanceof Date) return new Date(date.valueOf() - date.getTimezoneOffset() * 60000);
    if (/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(date)) {
        format = this.parseFormat('yyyy-mm-dd');
    }
    if (/^\d{4}\-\d{1,2}\-\d{1,2}[T ]\d{1,2}\:\d{1,2}$/.test(date)) {
        format = this.parseFormat('yyyy-mm-dd hh:ii');
    }
    if (/^\d{4}\-\d{1,2}\-\d{1,2}[T ]\d{1,2}\:\d{1,2}\:\d{1,2}[Z]{0,1}$/.test(date)) {
        format = this.parseFormat('yyyy-mm-dd hh:ii:ss');
    }
    if (/^[-+]\d+[dmwy]([\s,]+[-+]\d+[dmwy])*$/.test(date)) {
        let part_re = /([-+]\d+)([dmwy])/,
            parts = date.match(/([-+]\d+)([dmwy])/g),
            part, dir;
        date = new Date();
        for (var i = 0; i < parts.length; i++) {
            part = part_re.exec(parts[i]);
            dir = parseInt(part[1]);
            switch (part[2]) {
                case 'd':
                    date.setUTCDate(date.getUTCDate() + dir);
                    break;
                case 'm':
                    date = Datetimepicker.prototype.moveMonth.call(Datetimepicker.prototype, date, dir);
                    break;
                case 'w':
                    date.setUTCDate(date.getUTCDate() + dir * 7);
                    break;
                case 'y':
                    date = Datetimepicker.prototype.moveYear.call(Datetimepicker.prototype, date, dir);
                    break;
            }
        }
        return UTCDate(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }
    let parts = date && date.match(this.nonpunctuation) || [];
    date = new Date();
    let parsed = {};
    let setters_order = ['hh', 'h', 'ii', 'i', 'ss', 's', 'yyyy', 'yy', 'M', 'MM', 'm', 'mm', 'd', 'dd'];
    let setters_map = {
        hh: function(d, v) {
            return d.setUTCHours(v);
        },
        h: function(d, v) {
            return d.setUTCHours(v);
        },
        ii: function(d, v) {
            return d.setUTCMinutes(v);
        },
        i: function(d, v) {
            return d.setUTCMinutes(v);
        },
        ss: function(d, v) {
            return d.setUTCSeconds(v);
        },
        s: function(d, v) {
            return d.setUTCSeconds(v);
        },
        yyyy: function(d, v) {
            return d.setUTCFullYear(v);
        },
        yy: function(d, v) {
            return d.setUTCFullYear(2000 + v);
        },
        m: function(d, v) {
            v -= 1;
            while (v < 0) v += 12;
            v %= 12;
            d.setUTCMonth(v);
            while (d.getUTCMonth() != v)
                d.setUTCDate(d.getUTCDate() - 1);
            return d;
        },
        d: function(d, v) {
            return d.setUTCDate(v);
        }
    };
    
    let val, filtered, part;
    setters_map['M'] = setters_map['MM'] = setters_map['mm'] = setters_map['m'];
    setters_map['dd'] = setters_map['d'];
    date = UTCDate(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0); //date.getHours(), date.getMinutes(), date.getSeconds());
    if (parts.length == format.parts.length) {
        for (let i = 0, cnt = format.parts.length; i < cnt; i++) {
            val = parseInt(parts[i], 10);
            part = format.parts[i];
            if (isNaN(val)) {
                switch (part) {
                    case 'MM':
                        filtered = $(dates[language].months).filter(function() {
                            var m = this.slice(0, parts[i].length),
                                p = parts[i].slice(0, m.length);
                            return m == p;
                        });
                        val = $.inArray(filtered[0], dates[language].months) + 1;
                        break;
                    case 'M':
                        filtered = $(dates[language].monthsShort).filter(function() {
                            var m = this.slice(0, parts[i].length),
                                p = parts[i].slice(0, m.length);
                            return m == p;
                        });
                        val = $.inArray(filtered[0], dates[language].monthsShort) + 1;
                        break;
                }
            }

            if (part === 'a' && parsed['hh']) {
                if (parts[i] == 'AM' && parsed['hh'] === 12)
                    parsed['hh'] = 0;

                if (parts[i] === 'PM' && parsed['hh'] !== 12)
                    parsed['hh'] += 12;
            }

            parsed[part] = val;
        }
        for (let i = 0, s; i < setters_order.length; i++) {
            s = setters_order[i];
            if (s in parsed && !isNaN(parsed[s]))
                setters_map[s](date, parsed[s])
        }
    }
    return date;
}

export function formatDate(date, format, language) {
    if (date == null) {
        return '';   //'';   //'';
    }
    var val = {
        h: date.getUTCHours(),
        i: date.getUTCMinutes(),
        s: date.getUTCSeconds(),
        d: date.getUTCDate(),
        m: date.getUTCMonth() + 1,
        M: dates[language].monthsShort[date.getUTCMonth()],
        MM: dates[language].months[date.getUTCMonth()],
        yy: date.getUTCFullYear().toString().substring(2),
        yyyy: date.getUTCFullYear(),
        a: date.getUTCHours() >= 12 ? 'PM' : 'AM'
    };

    // adjust the date to render w/o military time
    if (val.h == 0)
        val.hh = 12;
    else {
        val.hh = (format.parts.includes('a') && val.h > 12) ?
            val.h - 12 : 
            val.h;
        val.hh = (val.hh < 10 ? '0' : '') + val.hh;
    }

    val.ii = (val.i < 10 ? '0' : '') + val.i;
    val.ss = (val.s < 10 ? '0' : '') + val.s;
    val.dd = (val.d < 10 ? '0' : '') + val.d;
    val.mm = (val.m < 10 ? '0' : '') + val.m;
    var dateParts = [],
        seps = $.extend([], format.separators);
    for (var i = 0, cnt = format.parts.length; i < cnt; i++) {
        if (seps.length)
            dateParts.push(seps.shift())
    
        dateParts.push(val[format.parts[i]]);
    }
    return dateParts.join('');
}
