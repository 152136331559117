import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import { PartialTestResult } from './partial-test-result';
import { PartialTestResultListQuery } from './partial-test-results-query-filters';

@autoinject
export class PartialTestResultService {
    private baseRoute: string;

    constructor(private httpClient: HttpClient) {
        this.baseRoute = 'partial-test-results';
    }

    getTestResults(
        partialTestResultListQuery: PartialTestResultListQuery,
    ): Promise<PartialTestResult[]> {
        var query = buildQueryString(partialTestResultListQuery, true);
        return this.httpClient.get(
            `${this.baseRoute}${query ? `?${query}` : ''}`,
        ) as unknown as Promise<PartialTestResult[]>;
    }
}
