import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class SupportTopicService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    createSupportRequest(supportRequest) {
        return this.httpClient.post('help/support-requests', supportRequest);
    }
}
