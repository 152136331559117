enum OrganizationConfiguration {
    ALLOW_STANDARD_REQUEST_SUBMISSION = 'allowStandardRequestSubmission',
    ALLOW_COMPOSITING = 'allowCompositing',
    DEFAULT_LAB_CODE = 'defaultLabCode',
    REQUEST_SUBMISSION_EMAIL_RECIPIENTS = 'requestSubmissionEmailRecipients',
    DISABLE_TASK_RELATED_EMAILS = 'disableTaskRelatedEmails',
    ENABLE_SEGMENTATION = 'hasSegmentAccess',
    PARTIAL_TEST_RESULT_LABS = 'partialTestResultLabs',
}

export default OrganizationConfiguration;
