import moment from 'moment';
import SampleDescriptionField from './sample-description-field';

export default class SampleDescriptionBuilder {
    static fields: SampleDescriptionField[] = [
        { name: 'id', caption: 'Task ID', getValue: task => `Task# ${task.id}` },
        { name: 'organizationInternalId', caption: 'Internal ID', getValue: task => `Internal# ${task.organizationInternalId ?? ''}` },
        { name: 'collectedDateTime', caption: 'Collection Date', getValue: task => moment(task.collectedDateTime).format('M/D/YYYY h:mm a') },

        { name: 'mapName', caption: 'Map Name' },
        { name: 'mapDescription', caption: 'Map Description' },

        { name: 'pointName', caption: 'Point Name' },
        { name: 'pointDescription', caption: 'Point Description' },
        { name: 'pointRoom', caption: 'Point Room' },
        { name: 'pointZone', caption: 'Point Zone', getValue: task => `Zone ${task.pointZone}` },
        { name: 'pointMobile', caption: 'Point Mobile', getValue: task => `Mobile: ${task.pointMobile ? 'Y' : 'N'}` },
        { name: 'pointType', caption: 'Point Type' },
        { name: 'pointRisk', caption: 'Point Risk', getValue: task => task.pointRisk === 'NotSpecified' ? '' : task.pointRisk },

        { name: 'planName', caption: 'Plan Name', getValue: task => task.plan?.name },
        { name: 'planDescription', caption: 'Plan Description', getValue: task => task.plan?.description },

        { name: 'sampleDescription', caption: 'Sample Description'}
    ];

    static getFields(...names: string[]) {
        return names.map(SampleDescriptionBuilder.getField);
    }

    static getField(name: string) {
        let field = SampleDescriptionBuilder.fields.find(f => f.name === name);
        if (!field)
            throw new Error(`Cannot find sample description field '${name}'.`);

        return field;
    }

    static build(tasks: any[], fields: SampleDescriptionField[]) {
        return tasks
            .map(task => fields
                .map(field => field.getValue ? field.getValue(task) : task[field.name])
                .join(', '))
            .join('; ');
    }
}
