import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';

@inject(HttpClient)
export default class PointTypeService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getPointTypes(filters) {
        var query = buildQueryString(filters);
        return this.httpClient.get(`location-testing/point-types${(query ? `?${query}` : '')}`);
    }

    getOrganizationPointTypes(organizationId) {
        return this.httpClient.get(`location-testing/point-types/organization/${organizationId}`);
    }
}
