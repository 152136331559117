import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)

export default class GlossaryTermService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getGlossaryTerms() {
        return this
            .httpClient
            .get(`help/glossary-terms`);
    }

    getGlossaryTerm(id) {
        return this.httpClient.get(`help/glossary-terms/${id}`);
    }

    deleteGlossaryTerms(ids) {
        var query = ids.map(id => `id=${id}`).join('&');
        return this.httpClient.delete(`help/glossary-terms?${query}`);
    }

    saveGlossaryTerm(glossaryTerm) {
        if (!glossaryTerm.id)
            return this.httpClient.post('help/glossary-terms/', glossaryTerm);

        return this.httpClient.put(`help/glossary-terms/${glossaryTerm.id}`, glossaryTerm);
    }
}
