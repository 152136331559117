import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@autoinject
export default class ParameterOptionService {
    constructor(
        private httpClient: HttpClient
    ) {
    }

    getParameterOptions(parameterName) {
        return this.httpClient.get(`reporting/parameter-options?parameterName=${parameterName}`);
    }
}
