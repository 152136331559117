import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ExportTaskCollectionTemplate } from 'export-task-collection-templates/export-task-collection-template';
import { ExportTaskCollectionTemplateConfiguration } from 'export-task-collection-templates/export-task-collection-template-configuration';
import ExportTaskCollectionTemplateService from 'export-task-collection-templates/export-task-collection-template-service';
import CompareUtility from 'infrastructure/compare-utility';
import { FullCalendar, FullCalendarEvent } from 'infrastructure/components/full-calendar';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import SelectorOption from 'infrastructure/selector-option';
import LabelTemplateService from 'label-templates/label-template-service';
import CollectionType, {
    getCollectionTypeOptions,
    toCollectionTypeTitle,
} from 'location-testing/collection-type';
import MapService from 'location-testing/maps/map-service';
import MapSummary from 'location-testing/maps/map-summary';
import MapSummaryConverter from 'location-testing/maps/map-summary-converter';
import PlanService from 'location-testing/plans/plan-service';
import PlanSummary from 'location-testing/plans/plan-summary';
import PlanSummaryConverter from 'location-testing/plans/plan-summary-converter';
import PointService from 'location-testing/points/point-service';
import moment from 'moment';
import Organization from 'organizations/organization';
import RecordStatus, { getRecordStatusOptions } from 'record-status';
import SecurityService from 'security/security-service';
import TestMethod from 'test-methods/test-method';
import UserService from 'users/user-service';
import { TaskListQuery, TaskListQueryFilters } from './task-list-query-filters';
import TaskListViewOption from './task-list-view-option';
import TaskService from './task-service';
import TaskStatusSelectorDialog from './task-status-selector-dialog';
import { testMethodNameFormatting } from 'test-methods/test-method-helper';

@autoinject
export class TaskList {
    // view
    @observable selectedViewOption: TaskListViewOption;
    dataGridFullHeightViewModel: any;
    calendarFullHeightViewModel: any;

    calendarViewModel: FullCalendar;

    // filters
    @observable filterText;

    applyFiltersSubscription: any;
    clearFiltersSubscription: any;

    gridOptions: any;

    targetDateTimeDateRangeOptions: any;
    defaultTargetDateTimeDateRangeOption: any;
    selectedTargetDateTimeDateRangeOption: any;

    filterCount: number;
    assignedFilterOptions: any;
    assignedFilter: any;
    onlyAssignedToMeFilterOptions: any;
    onlyAssignedToMeFilter: any;
    testMethods: TestMethod[] = [];
    pointRoomOptions: { title: string; value: string }[];
    pointZoneOptions: { title: string; value: number }[];
    pointMobileOptions: { title: string; value: boolean }[];
    pointTypeOptions: { title: string; value: string }[];
    pointRiskOptions: { title: string; value: string }[];

    mapSelectorOptions: SelectorOption[];
    planSelectorOptions: SelectorOption[];
    collectionTypeOptions: SelectorOption[];

    allSelected: boolean;
    selectedActiveTasks: any[];
    taskStatusesFilter: any;
    canUnassignTasks: boolean;
    canSubmitRequests: boolean;
    selectedOrganizationId: number;

    recordStatusOptions: SelectorOption[];

    // security
    canViewAllTasks: boolean;
    canEditTasks: boolean;
    canExportLabelsPdf: any;

    // data
    tasks: any;
    taskCalendarEvents: FullCalendarEvent[];
    taskLabelTemplates: any;
    taskStatuses: any;
    planSummaries: PlanSummary[];

    // UI components
    tasksView: any;
    enableTimeFilter: boolean;

    taskListQueryFilters: TaskListQueryFilters;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private exportTaskCollectionTemplateService: ExportTaskCollectionTemplateService,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private dialogService: DialogService,
        private labelTemplateService: LabelTemplateService,
        private securityService: SecurityService,
        private pointService: PointService,
        private planService: PlanService,
        private mapService: MapService,
        private taskService: TaskService,
        private userService: UserService,
    ) {
        this.logger.name = 'task-list';
        this.taskStatuses = this.taskService.getTaskStatuses();
        this.filterText = '';

        this.assignedFilterOptions = [
            { value: -1, title: 'Do Not Filter' },
            { value: 0, title: 'No' },
            { value: 1, title: 'Yes' },
        ];
        this.assignedFilter = -1;

        this.onlyAssignedToMeFilterOptions = [
            { value: false, title: 'No' },
            { value: true, title: 'Yes' },
        ];
        this.onlyAssignedToMeFilter = false;

        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template:
                        '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()" data-cy="datagrid-list-row-checkbox"></label>',
                    headerCellTemplate:
                        '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true,
                    pinned: 'left',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Target Date/Time',
                    field: 'targetDateTime',
                    template: '${data.targetDateTime | dateFormat}',
                    sort: 'desc',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Collected Date/Time',
                    field: 'collectedDateTime',
                    template: '${data.collectedDateTime | dateFormat}',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Operations Timing',
                    field: 'operationsTimingCaption',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Assigned User',
                    field: 'assignedUserEmail',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Plan',
                    field: 'planName',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Test Methods',
                    field: 'testMethodsCaption',
                    comparator: CompareUtility.compareStringsInsensitive,
                    template:
                        '<span title="${data.testMethodsHoverCaption}">${data.testMethodsCaption}</span>',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Collection Type',
                    field: 'collectionType',
                    template: '${decodeCollectionType(data.collectionType)}',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Sample Point Name',
                    field: 'pointName',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Room',
                    field: 'pointRoom',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Zone',
                    field: 'pointZone',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Sample Point Type',
                    field: 'pointType',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Risk',
                    field: 'pointRiskCaption',
                },
                { suppressMenu: true, suppressSizeToFit: true, headerName: 'Task Id', field: 'id' },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Map',
                    field: 'mapName',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Organization',
                    field: 'organizationName',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Status',
                    field: 'statusCaption',
                },
                {
                    suppressMenu: true,
                    headerName: 'Sample Submitted',
                    field: 'sampleId',
                    template: '<i class="fa fa-check" if.bind="data.sampleId"></i>',
                    width: 160,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                    pinned: 'right',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Sample Description',
                    field: 'sampleDescription',
                },
                {
                    suppressMenu: true,
                    suppressSizeToFit: true,
                    headerName: 'Mobile',
                    field: 'pointMobile',
                    template: '${data.pointMobile | boolean}',
                },
                {
                    suppressMenu: true,
                    headerName: 'Active',
                    field: 'isActive',
                    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button click.delegate="navigateToTaskDetail(data.id)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button if.bind="canEditTasks" click.trigger="deleteTask(data)" disabled.bind="!data.isActive">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 150,
                    sortable: false,
                    suppressSizeToFit: true,
                    pinned: 'right',
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.allSelected = false;
        this.selectedActiveTasks = [];
        this.selectedViewOption = TaskListViewOption.LIST;
        this.collectionTypeOptions = getCollectionTypeOptions();

        this.canViewAllTasks = this.securityService.hasPermission('ViewAllTasks');
        this.canEditTasks =
            this.securityService.hasPermission('EditTasks') &&
            !this.securityService.isImpersonating();

        this.applyFiltersSubscription = this.eventAggregator.subscribe('filters.apply', () =>
            this.handleApplyFilters(),
        );
        this.clearFiltersSubscription = this.eventAggregator.subscribe('filters.clear', () =>
            this.handleClearFilters(),
        );
        this.taskListQueryFilters = new TaskListQueryFilters();
    }

    navigateToTaskDetail(id) {
        this.router.navigateToRoute('task-detail', { id });
    }

    decodeCollectionType(collectionType: CollectionType): string {
        return toCollectionTypeTitle(collectionType);
    }

    setUnassignAbility() {
        this.canUnassignTasks =
            this.selectedActiveTasks.length > 0 &&
            this.selectedActiveTasks.every(
                (task: any) =>
                    (task.status === 'NotStarted' || task.status === 'InProgress') &&
                    task.assignedUserEmail &&
                    task.assignedUserEmail.length > 0,
            );
    }

    setRequestSubmissionAbility() {
        const organizationIds = this.selectedActiveTasks.reduce((organizationIds, task) => {
            if (!organizationIds.includes(task.organizationId))
                organizationIds.push(task.organizationId);

            return organizationIds;
        }, []);

        this.canSubmitRequests = organizationIds.length === 1;
        this.selectedOrganizationId = organizationIds.length === 1 ? organizationIds[0] : null;
    }

    setSelectedActiveTasks() {
        this.selectedActiveTasks = this.tasksView.filter(
            (task) => task.isSelected && task.isActive,
        );
    }

    isSelectedChanged() {
        this.allSelected = this.tasksView.every((task) => task.isSelected);
        this.setSelectedActiveTasks();
        this.setUnassignAbility();
        this.setRequestSubmissionAbility();
    }

    deselectAll() {
        this.tasksView.forEach((t) => (t.isSelected = false));
        this.isSelectedChanged();
    }

    allSelectedClicked() {
        if (this.tasksView) {
            this.allSelected = !this.tasksView.every((task) => task.isSelected);

            for (let task of this.tasksView) task.isSelected = this.allSelected;
        }

        this.isSelectedChanged();

        return true;
    }

    filterTextChanged() {
        this.updateTasksView();
    }

    selectedViewOptionChanged() {
        this.dataGridFullHeightViewModel?.update();
        this.calendarFullHeightViewModel?.update();
        setTimeout(() => this.calendarViewModel?.render());
    }

    handleApplyFilters() {
        this.router.navigateToRoute('task-list', this.taskListQueryFilters.getQueryParams());
    }

    handleClearFilters() {
        this.taskListQueryFilters.reset();
        this.router.navigateToRoute('task-list', this.taskListQueryFilters.getQueryParams());
    }

    handleCalendarEventClick(event: FullCalendarEvent) {
        const { start, end, extendedProps } = event;
        const targetStartDate = moment(start).format('MM/DD/YYYY');
        const targetEndDate = end ? moment(end).format('MM/DD/YYYY') : targetStartDate;
        const params = {
            onlyAssignedToMe: false,
            planId: extendedProps.planId,
            recordStatus: [RecordStatus.ACTIVE],
            targetEndDate,
            targetStartDate,
            view: 'summary',
            timeStamp: moment().unix(),
        };

        this.router.navigateToRoute('task-list', params);
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    updateTasksView() {
        var lowerCasedFilterText = this.filterText.toLowerCase();

        if (this.tasks) {
            this.tasksView = this.tasks.filter(
                (t) =>
                    (t.id.toString() || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (t.assignedUserEmail || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (t.planName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (t.pointName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (t.mapName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (t.organizationName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (t.statusCaption || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (t.operationsTimingCaption || '').toLowerCase().indexOf(lowerCasedFilterText) >
                        -1 ||
                    (t.pointRoom || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (t.pointType || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (t.pointRiskCaption || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (t.testMethodsCaption || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (t.testMethodsHoverCaption || '').toLowerCase().indexOf(lowerCasedFilterText) >
                        -1,
            );

            this.allSelected = this.tasksView.every((task) => task.isSelected);
        }
    }

    activate(params: TaskListQuery) {
        this.taskListQueryFilters.setFilterValues(params);

        (async () => {
            this.pageContext.isLoading = true;
            this.selectedViewOption = TaskListViewOption.LIST;
            try {
                this.recordStatusOptions = getRecordStatusOptions();
                await this.loadFilterOptions();

                // NOTE: requires filter options to have loaded data
                await Promise.all([this.loadTasks(), this.loadTaskLabelTemplates()]);

                this.updateTasksView();
                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error loading tasks.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Tasks',
                    'An error occurred while loading the tasks. Please try again later.',
                );
            }
        })();
    }

    async loadFilterOptions() {
        // details for map/plan filter
        let mapSummaries = (await this.mapService.getMaps([])) as unknown as MapSummary[];
        let mapIds = mapSummaries.map((m) => m.id);
        let planSummaries = (await this.planService.getPlans(mapIds)) as unknown as PlanSummary[];

        this.mapSelectorOptions = MapSummaryConverter.toSelectorOptions(mapSummaries);
        this.planSelectorOptions = PlanSummaryConverter.toSelectorOptions(
            planSummaries,
            mapSummaries,
        );
        this.planSummaries = planSummaries;

        // build filters for points
        let mapIdsForPoints =
            this.taskListQueryFilters.mapPlanQueryFilters.mapPlanFilterType === 'map'
                ? this.taskListQueryFilters.mapPlanQueryFilters.mapPlanIds
                : null;
        const [points, testMethods] = await Promise.all([
            this.pointService.getPoints({ mapIds: mapIdsForPoints }),
            this.planService.getTestMethods(mapIdsForPoints),
        ]);

        this.pointRoomOptions = Array.from(new Set((points ?? []).map((p) => p.room)).values()).map(
            (v: string) => ({ title: v, value: v }),
        );
        this.pointZoneOptions = Array.from(new Set((points ?? []).map((p) => p.zone)).values()).map(
            (v: number) => ({ title: v.toString(), value: v }),
        );
        this.pointMobileOptions = [
            { title: 'Yes', value: true },
            { title: 'No', value: false },
        ];
        this.pointTypeOptions = Array.from(
            new Set((points ?? []).filter((p) => !!p.type).map((p) => p.type)).values(),
        ).map((v: string) => ({ title: v, value: v }));
        this.pointRiskOptions = Array.from(new Set((points ?? []).map((p) => p.risk)).values()).map(
            (v: string) => ({ title: this.pointService.getRiskCaption(v), value: v }),
        );

        this.testMethods = testMethods;
    }

    async loadTasks() {
        let tasks = (await this.taskService.getTasks(
            this.taskListQueryFilters.getQueryParams(),
            'summary',
        )) as unknown as any[];
        await this.setTasks(tasks);
    }

    async setTasks(tasks) {
        this.tasks = tasks;
        this.setDates();
        this.setCaptions();

        // convert the tasks so that they are usable to the calendar control
        let planColorMap = await this.loadPlanColorMap();

        let calendarTasks: FullCalendarEvent[] = [];
        const groupedTasks = this.tasks
            .map((t) => {
                return {
                    date: moment(t.targetDateTime).format('MM/DD/YYYY HH'),
                    planId: t.planId,
                    planName: t.planName,
                    targetDateTime: t.targetDateTime,
                };
            })
            .reduce((g, i) => {
                (g[i['date'] + ',' + i['planId']] = g[i['date'] + ',' + i['planId']] || []).push(i);
                return g;
            }, {});

        Object.keys(groupedTasks).forEach((key) => {
            const tasks = groupedTasks[key].sort((d1, d2) => d1.targetDateTime - d2.targetDateTime);
            const len = tasks.length;
            const firstTask = tasks[0];
            const lastTask = tasks[len - 1];
            const planName = firstTask.planName ? firstTask.planName : 'Vector';
            calendarTasks.push({
                id: key,
                title: planName,
                start: moment(firstTask.targetDateTime).format(),
                end: moment(lastTask.targetDateTime).add(15, 'minutes').format(),
                backgroundColor: planColorMap.get(firstTask.planId),
                extendedProps: {
                    planName: planName,
                    planId: firstTask.planId,
                    tooltip: `Plan: ${planName} - ${len} task${len > 1 ? 's' : ''}`,
                },
            } as FullCalendarEvent);
        });

        this.taskCalendarEvents = calendarTasks;
    }

    async loadPlanColorMap(): Promise<Map<number, string>> {
        // grab the colors associated with each plan
        let planIds = [...new Set(this.tasks.map((t) => t.planId))];
        let planColorMap = new Map();

        planIds.forEach((planId) => {
            let planSummary = this.planSummaries.find((p) => p.id === planId);
            if (!planSummary) {
                return;
            }
            planColorMap.set(planId, planSummary.colorHex);
        });

        return planColorMap;
    }

    async loadTaskLabelTemplates() {
        this.taskLabelTemplates = await this.labelTemplateService.getLabelTemplates(
            { type: 'Task' },
            'Selector',
        );
        this.canExportLabelsPdf = this.taskLabelTemplates.length !== 0;
    }

    updateTasks(tasks) {
        for (let task of tasks) {
            let existingTask = this.tasks.find((t) => t.id === task.id);
            if (existingTask) Object.assign(existingTask, task);
        }

        this.setDates();
        this.setCaptions();
    }

    setDates() {
        // Convert UTC into local time
        this.tasks.forEach((t) => {
            if (t.targetDateTime && typeof t.targetDateTime === 'string')
                t.targetDateTime = new Date(
                    `${t.targetDateTime}${t.targetDateTime.endsWith('Z') ? '' : 'Z'}`,
                );
            if (t.collectedDateTime && typeof t.collectedDateTime === 'string')
                t.collectedDateTime = new Date(
                    `${t.collectedDateTime}${t.collectedDateTime.endsWith('Z') ? '' : 'Z'}`,
                );
        });
    }

    setCaptions() {
        this.tasks.forEach((t) => {
            var taskStatus = this.taskStatuses.find((ts) => ts.code === t.status);
            t.statusCaption = taskStatus ? taskStatus.name : '';
            t.operationsTimingCaption = this.getOperationsTimingCaption(t.operationsTiming);
            t.pointRiskCaption = this.pointService.getRiskCaption(t.pointRisk);
            t.testMethodsCaption = t.testMethods.map((tm) => tm.billCode).join(', ');
            t.testMethodsHoverCaption = t.testMethods
                .map((tm) =>
                    testMethodNameFormatting(
                        tm,
                        this.securityService.isImpersonating(),
                        this.securityService.isWorkingUnderOrganization(),
                    ),
                )
                .join('\n');
        });
    }

    getOperationsTimingCaption(operationsTiming) {
        switch (operationsTiming) {
            case 'NotSpecified':
                return 'Not Specified';

            case 'PreOperations':
                return 'Pre Operations';

            case 'DuringOperations':
                return 'During Operations';

            case 'PostOperations':
                return 'Post Operations';

            default:
                return '';
        }
    }

    unbind() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
        this.clearFiltersSubscription && this.clearFiltersSubscription.dispose();
    }

    deleteTask(task) {
        this.dialogPresenter
            .showConfirmation('Delete Task', 'Are you sure you want to delete this task?')
            .then((confirmed) => {
                if (!confirmed) return;

                this.pageContext.isLoading = true;
                this.taskService
                    .deleteTasks([task.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Task deleted successfully.');

                        task.isSelected = false;
                        task.isActive = false;

                        this.setSelectedActiveTasks();
                    })
                    .catch((error) => {
                        this.logger.error('Error deleting task.', error, { taskId: task.id });
                        this.pageContext.isLoading = false;
                        this.handleActionError('delete', error, 'Error Deleting Task', false);
                    });
            });
    }

    async unassign() {
        const taskIds = this.selectedActiveTasks.map((x) => x.id);
        if (taskIds.length < 1) {
            return;
        }

        const tasksPlurality: string = taskIds.length > 1 ? 's' : '';
        if (
            !(await this.dialogPresenter.showConfirmation(
                `Unassign Task${tasksPlurality}`,
                `Unassign ${taskIds.length} task${tasksPlurality}?`,
            ))
        ) {
            return;
        }

        try {
            this.pageContext.isLoading = true;
            const tasks = await this.taskService.unassignTasks(taskIds);
            this.updateTasks(tasks);
            this.pageContext.isLoading = false;
            this.pageContext.showSuccessOverlay(
                `${taskIds.length} Task${tasksPlurality} Unassigned`,
            );
            this.updateTasksView();
            this.deselectAll();
        } catch (error) {
            this.pageContext.isLoading = false;
            this.logger.error('An error occured while unassigning tasks.', error, { taskIds });
            this.dialogPresenter.showAlert(
                `Error Unassigning Task${tasksPlurality}`,
                `An error occured while unassigning your task${tasksPlurality}.  Please try again later.`,
            );
        }
    }

    async actOnSelectedTasks(action: string) {
        var tasksToActOn = this.tasksView.filter((u) => u.isSelected);
        if (!tasksToActOn.length) return;

        var messagingOptions = this.getActionMessagingOptions(action);

        var actionData;
        if (messagingOptions.preActionFunction) {
            var result = await messagingOptions.preActionFunction(tasksToActOn);
            if (!result || result.wasCancelled || result.output?.wasCancelled) return;

            actionData = result.output;
            if (result.tasks) tasksToActOn = result.tasks;
        }

        if (
            messagingOptions.confirmationTextFunction &&
            !(await this.dialogPresenter.showConfirmation(
                messagingOptions.caption,
                messagingOptions.confirmationTextFunction(actionData),
            ))
        )
            return;

        var taskIds = tasksToActOn.map((task) => task.id);

        var toggleLoader = messagingOptions.waitingText
            ? (start) =>
                  start
                      ? this.pageContext.showInfoOverlay(messagingOptions.waitingText, false)
                      : this.pageContext.closeOverlay()
            : (start) => (this.pageContext.isLoading = start);

        toggleLoader(true);

        try {
            var response = await messagingOptions.actionFunction(taskIds, actionData);
            toggleLoader(false);
            this.pageContext.showSuccessOverlay(messagingOptions.completedText);

            if (messagingOptions.successFunction)
                messagingOptions.successFunction(response, tasksToActOn);

            this.updateTasksView();
        } catch (error) {
            this.logger.error(messagingOptions.errorLogText, error, { taskIds });
            toggleLoader(false);
            this.handleActionError(action, error, messagingOptions.errorMessageCaption, true);
        }
    }

    navigateToRequestDetail() {
        if (this.selectedActiveTasks.some((t) => t.status !== 'Completed')) {
            this.dialogPresenter.showAlert(
                'Some tasks are not completed',
                'Some of the selected tasks have not been completed and therefore cannot be added to a request. Please mark those tasks as Completed or unselect them before submitting a request.',
            );
            return;
        }

        if (this.selectedActiveTasks.some((t) => t.sampleId)) {
            this.dialogPresenter.showAlert(
                'Some tasks have been submitted',
                'Some of the selected tasks have had their samples submitted and therefore cannot be added to a request. Please unselect them before submitting a request.',
            );
            return;
        }

        var externalSources = [];

        for (let task of this.selectedActiveTasks)
            externalSources.push(...task.testMethods.map((tm) => tm.externalSource));

        if (new Set(externalSources).size > 1) {
            this.dialogPresenter.showAlert(
                'Tasks With Incompatible Test Methods',
                'Some of the selected tasks have test methods that cannot be combined into the same request submission.',
            );
            return;
        }

        this.router.navigateToRoute('location-testing-request-detail', {
            id: 'create',
            taskIds: this.selectedActiveTasks.map((t) => t.id),
            organizationId: this.selectedOrganizationId,
        });
    }

    /* eslint-disable sonarjs/cognitive-complexity */
    getActionMessagingOptions(action) {
        switch (action) {
            case 'delete':
                return {
                    caption: 'Delete Selected Tasks',
                    confirmationTextFunction: () =>
                        'Are you sure you want to delete the selected tasks?',
                    completedText: 'Selected tasks deleted successfully.',
                    errorLogText: 'Error deleting tasks.',
                    errorMessageCaption: 'Error Deleting Tasks',
                    actionFunction: (taskIds) => this.taskService.deleteTasks(taskIds),
                    successFunction: async (response, tasksActedOn) => {
                        for (let task of tasksActedOn) {
                            task.isSelected = false;
                            task.isActive = false;
                        }

                        await this.handleApplyFilters();
                    },
                };

            case 'assign':
                return {
                    caption: 'Assign Selected Tasks',
                    confirmationTextFunction: (user) =>
                        `Are you sure you want to assign the selected tasks to user ${user.email}?`,
                    completedText: 'Selected tasks assigned successfully.',
                    errorLogText: 'Error assigning tasks.',
                    errorMessageCaption: 'Error Assigning Tasks',
                    preActionFunction: () => this.selectUser(),
                    actionFunction: (taskIds, user) =>
                        this.taskService.assignTasks(taskIds, user.id),
                    successFunction: (tasks) => this.updateTasks(tasks),
                };

            case 'export-labels-csv':
                return {
                    caption: 'Export Labels CSV',
                    confirmationTextFunction: null, // No need for user to confirm
                    waitingText: 'Labels exporting...',
                    errorLogText: 'Error exporting labels CSV.',
                    errorMessageCaption: 'Error Exporting Labels CSV',
                    preActionFunction: (taskIds) => this.selectExportableFields(taskIds),
                    actionFunction: (taskIds, fields) => {
                        return this.taskService.exportLabelsCsv(taskIds, fields?.output);
                    },
                    successFunction: (response) => {
                        // Push file from response into memory and generate a URL to it
                        var bin = [];
                        bin.push(response);
                        var blob = new Blob(bin, { type: 'text/csv' });
                        var blobUrl = window.URL.createObjectURL(blob);

                        // Prompt the user to save the file
                        var link = document.createElement('a');
                        link.href = blobUrl;
                        var dateString = new Date()
                            .toISOString()
                            .replace(new RegExp('[-:.Z]', 'g'), '');
                        link.download = `tasks_${dateString}.csv`; // Default filename
                        link.click();
                    },
                };

            case 'export-labels-pdf':
                return {
                    caption: 'Export Labels PDF',
                    confirmationTextFunction: null, // No need for user to confirm
                    waitingText: 'Labels exporting...',
                    errorLogText: 'Error exporting labels PDF.',
                    errorMessageCaption: 'Error Exporting Labels PDF',
                    preActionFunction: () => this.selectTaskLabelTemplate(),
                    actionFunction: (taskIds, labelTemplate) => {
                        try {
                            var url = this.taskService.getTaskLabelPdfUrl(
                                taskIds,
                                labelTemplate.id,
                            );
                            if (!this.isDownloadUrlValid(url, taskIds)) {
                                return;
                            }

                            window.location.href = url;
                        } catch (error) {
                            this.logger.error('Error exporting labels PDF.', error, { taskIds });
                            this.handleActionError(
                                'print-labels',
                                error,
                                'Error Exporting Labels PDF',
                                true,
                            );
                        }
                    },
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    successFunction: () => {},
                };

            case 'update-status':
                return {
                    caption: 'Update Tasks Status',
                    confirmationTextFunction: (statusInfo) =>
                        `Are you sure you want to set the selected tasks to ${statusInfo.statusName}?`,
                    completedText: 'Status set successfully for selected tasks.',
                    errorLogText: 'Error setting status for tasks.',
                    errorMessageCaption: 'Error Setting Status',
                    preActionFunction: (tasks) => this.selectStatus(tasks),
                    actionFunction: (taskIds, statusInfo) =>
                        this.taskService.updateTaskStatus(
                            taskIds,
                            statusInfo.statusId,
                            statusInfo.collectedDateTime,
                            statusInfo.comments,
                        ),
                    successFunction: (tasks) => this.updateTasks(tasks),
                };

            case 'export-task-collection-template':
                return {
                    caption: 'Export Task Collection Template',
                    confirmationTextFunction: null,
                    waitingText: 'Tasks exporting...',
                    errorLogText: 'Error exporting task collection template.',
                    errorMessageCaption: 'Error Exporting Task Collection Template',
                    preActionFunction: () => this.selectExportTaskCollectionTemplate(),
                    actionFunction: (taskIds: number[], exportTaskCollectionTemplate: any) => {
                        try {
                            var url = this.taskService.getExporTaskCollectionTemplate(
                                taskIds,
                                exportTaskCollectionTemplate.value,
                                exportTaskCollectionTemplate.name,
                            );
                            if (!this.isDownloadUrlValid(url, taskIds)) {
                                return;
                            }
                            window.location.href = url;
                        } catch (error) {
                            this.logger.error('Error exporting task collection template.', error, {
                                taskIds,
                            });
                            this.handleActionError(
                                'export-task-collection-template',
                                error,
                                'Error Exporting Task Collection Template',
                                true,
                            );
                        }
                    },
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    successFunction: () => {},
                };
        }
    }

    selectUser() {
        return this.dialogPresenter.showUserSelector('Find a user to assign tasks to');
    }

    async selectExportableFields(tasks) {
        let taskIds = tasks.map((t) => t.id);

        let exportableFields = (await this.taskService.getExportableLabelsCsvFields(
            taskIds,
        )) as unknown as any;
        var orgDefaultFields = JSON.parse(exportableFields.selected);

        let selectedFields = await this.dialogPresenter.showExportableFieldSelector(
            'Select fields to be included with the export',
            exportableFields.all,
            orgDefaultFields,
        );

        var result = {
            output: selectedFields,
        };
        return Promise.resolve(result);
    }

    async selectTaskLabelTemplate() {
        var organizationIds = this.tasksView
            .filter((u) => u.isSelected)
            .map((t) => t.organizationId);
        if (Array.from(new Set(organizationIds)).length > 1) {
            await this.dialogPresenter.showAlert(
                'Cannot Export Labels PDF',
                'There are tasks from more than one organization selected. Please ensure that tasks from a single organziation are selected.',
            );
            return;
        }

        return this.dialogPresenter.showLabelSelector(
            'Select a task label',
            'Task',
            organizationIds[0],
        );
    }

    isDownloadUrlValid(url, taskIds): boolean {
        //  IIS Max
        //  https://stackoverflow.com/questions/812925/what-is-the-maximum-possible-length-of-a-query-string
        if (url.length > 4096) {
            this.logger.error(
                'Max URL exceeded.',
                new Error('Exporting labels PDF URL length is greater than 4096'),
                { taskCount: taskIds.length, urlLength: url.length },
            );
            this.dialogPresenter.showAlert(
                'Error exporting labels PDF.',
                `The number of tasks (${taskIds.length}) selected is not supported.  Please select a lesser (< ~300) amount.`,
            );

            return false;
        }

        return true;
    }

    async selectExportTaskCollectionTemplate() {
        const [organizations, exportTaskCollectionTemplates] = await Promise.all([
            this.userService.getCurrentUserOrganizations(),
            this.exportTaskCollectionTemplateService.getExportTaskCollectionTemplates(),
        ]);

        const dialogItems = [];
        exportTaskCollectionTemplates.forEach((t: ExportTaskCollectionTemplate) => {
            t.organizationConfigurations.forEach(
                (oc: ExportTaskCollectionTemplateConfiguration) => {
                    const organization = organizations.find(
                        (o: Organization) => o.organizationId === oc.organizationId,
                    );
                    if (organization) {
                        dialogItems.push({
                            name: `${oc.templateName} (${organization.name})`,
                            value: t.id,
                        });
                    }
                },
            );
        });
        return this.dialogPresenter.showNameValueItemSelector(
            'Select an Export Task Collection Template',
            '',
            'Template Name',
            dialogItems,
            'name',
            'value',
        );
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    async selectStatus(tasks) {
        var dialogResult = (await this.showTaskStatusSelectorDialog(tasks)) as any;
        if (!dialogResult || !dialogResult.output) return Promise.resolve(null);

        dialogResult.tasks = tasks;

        var statusInfo = dialogResult.output;
        if (statusInfo.statusId == 1) return Promise.resolve(dialogResult);

        var validTasks =
            statusInfo.statusId === 2 || statusInfo.statusId === 3 // In Progress or Completed
                ? tasks.filter((t) => !!t.assignedUserEmail)
                : tasks;

        var diff = tasks.length - validTasks.length;
        if (validTasks.length === 0) {
            await this.dialogPresenter.showAlert(
                'No Valid Tasks Selected',
                `The selected task${diff > 1 ? 's' : ''} cannot be updated to status ${
                    statusInfo.statusName
                } because ${diff > 1 ? 'they have' : 'it has'} no assigned user.`,
            );
            return Promise.resolve(null);
        }

        if (validTasks.length < tasks.length) {
            dialogResult.tasks = validTasks;
            var confirmed = await this.dialogPresenter.showConfirmation(
                'Not All Selected Tasks Valid',
                `${diff} task${diff > 1 ? 's' : ''} cannot be updated to status ${
                    statusInfo.statusName
                } because ${
                    diff > 1 ? 'they have' : 'it has'
                } no assigned user. Do you want to continue updating the other task(s)?`,
            );
            if (!confirmed) return Promise.resolve(null);
        }

        return Promise.resolve(dialogResult);
    }

    showTaskStatusSelectorDialog(tasks) {
        return new Promise((resolve) => {
            this.dialogService
                .open({
                    viewModel: TaskStatusSelectorDialog,
                    model: { tasks },
                })
                .whenClosed((response) => resolve(response));
        });
    }

    handleActionError(action, error, caption, multipleTasks) {
        this.dialogPresenter.showAlert(
            caption,
            this.getApiErrorMessage(action, error.apiErrorCode, multipleTasks),
        );
    }

    getApiErrorMessage(action, errorCode, multipleTasks) {
        var actionMsg;
        switch (action) {
            case 'export-labels-csv':
                actionMsg = 'export CSV';
                break;
            case 'export-labels-pdf':
                actionMsg = 'export PDF';
                break;
            case 'update-status':
                actionMsg = 'update';
                break;
            case 'export-task-collection-template':
                actionMsg = 'export task collection template';
                break;
            default:
                actionMsg = action;
        }

        if (errorCode === 2)
            return `You don't have access to ${actionMsg} the task${multipleTasks ? 's' : ''}`;

        return `An unexpected error occurred while trying to ${actionMsg} the task${
            multipleTasks ? 's' : ''
        }. Please try again later.`;
    }
}
