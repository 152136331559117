import {
    CellClassParams,
    CellValueChangedEvent,
    ColDef,
    EditableCallbackParams,
    ICellEditorParams,
    IRichCellEditorParams,
    ValueGetterParams,
    ValueSetterParams,
} from 'ag-grid-community';
import { CustomError } from 'infrastructure/custom-error';
import DateUtility from 'infrastructure/date-utility.js';
import LvOrganizationSampleTemplate from 'lab-vantage/lv-organization-sample-type';
import LvTestTemplate from 'lab-vantage/lv-test-template';
import moment from 'moment';
import SegmentLookup from 'segmentation-templates/segment-lookup';
import SegmentationTemplate from 'segmentation-templates/segmentation-template';
import SegmentationTemplateSegment from 'segmentation-templates/segmentation-template-segment';
import { AgDateMaskEditor } from './ag-grid-components/ag-datemask-editor';
import { AgDatePickerEditor } from './ag-grid-components/ag-datepicker-editor';
import { AgDateTimeEditorTypes } from './ag-grid-components/ag-datetime-editor-types';
import { SampleSegmentationGridPreferences } from './ag-grid-components/sample-segmentation-grid-preferences';
import SegmentationSampleGridColDataFactory from './sample-segmentation-grid-col-data-factory';

const segmentColdId = (segment: SegmentationTemplateSegment): string =>
    `segment_${segment.name}_${segment.segmentationTemplateId}`;

const getMatchingLookupValue = (segmentLookups: SegmentLookup[], segmentName: string): string => {
    const matchingLookup = segmentLookups.find((sl) => sl.parent === segmentName);
    if (!matchingLookup) {
        throw new CustomError(`No matching lookup for ${segmentName} was found.`, 2);
    }

    return matchingLookup.child;
};

const formatTestMethodNames = (lvTestTemplates: LvTestTemplate[]): string => {
    return lvTestTemplates.map((tt) => `${tt.testMethod.code}`).join(', ');
};

const createLookupTypeSegmentationColumns = (
    parentSegment: SegmentationTemplateSegment,
    childSegment: SegmentationTemplateSegment,
    isEditable: boolean,
): ColDef[] => {
    const parentSegmentValues = JSON.parse(parentSegment.values) as string[];
    const childSegmentLookupValues = JSON.parse(childSegment.values) as SegmentLookup[];

    if (parentSegment.parentSegmentId || !parentSegmentValues.length) {
        throw new CustomError('A lookup segmentation template column requires a valid parent', 1);
    }
    if (!childSegment.parentSegmentId || !childSegmentLookupValues.length) {
        throw new CustomError('A lookup segmentation template col requires a valid child', 1);
    }

    const colDefs: ColDef[] = [];

    const parentColDef: ColDef = {
        colId: segmentColdId(parentSegment),
        headerName: parentSegment.name,
        editable: (params: EditableCallbackParams | CellClassParams) =>
            parentSegment.segmentationTemplateId === params.data.segmentationTemplateId &&
            isEditable,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
            values: parentSegmentValues,
        },
        valueGetter: (params) =>
            params.data.segments ? params.data.segments[parentSegment.name] : null,
        valueSetter: (params) => {
            const { newValue } = params;
            const childValue = getMatchingLookupValue(
                childSegmentLookupValues,
                newValue.toString(),
            );

            params.data.segments[parentSegment.name] = newValue.toString();
            params.data.segments[childSegment.name] = childValue.toString();

            return true;
        },
        cellClassRules: {
            'disabled-cell': (params) =>
                parentSegment.segmentationTemplateId !== params.data.segmentationTemplateId,
            'segmentation-parent-cell': () => true,
        },
    };
    const childColDef: ColDef = {
        colId: segmentColdId(childSegment),
        headerName: childSegment.name,
        editable: (params: EditableCallbackParams | CellClassParams) =>
            childSegment.segmentationTemplateId === params.data.segmentationTemplateId &&
            isEditable,
        valueGetter: (params) =>
            params.data.segments ? params.data.segments[childSegment.name] : null,
        valueSetter: (params) => {
            params.data.segments[childSegment.name] = params.newValue.toString();

            return true;
        },
        cellClassRules: {
            'disabled-cell': (params) =>
                childSegment.segmentationTemplateId !== params.data.segmentationTemplateId,
            'segmentation-child-cell': () => true,
        },
    };

    colDefs.push(parentColDef);
    colDefs.push(childColDef);

    return colDefs;
};

const createNonLookupTypeSegmentationColumns = (
    segmentationTemplates: SegmentationTemplate[],
    isEditable: boolean,
): ColDef[] => {
    const colDefs: ColDef[] = [];
    segmentationTemplates.forEach((st) => {
        st?.segments.forEach((segment: SegmentationTemplateSegment) => {
            const colDef: ColDef = {
                colId: segmentColdId(segment),
                headerName: segment.name,
                editable: (params: EditableCallbackParams) =>
                    st.id === params.data.segmentationTemplateId && isEditable,
                cellEditorPopup: true,
                valueGetter: (params: ValueGetterParams) => {
                    return st.id === params.data.segmentationTemplateId
                        ? params.data.segments
                            ? params.data.segments[segment.name]
                            : null
                        : null;
                },
                valueSetter: (params) => {
                    if (st.id !== params.data.segmentationTemplateId) {
                        return false;
                    }

                    params.data.segments[segment.name] = params.newValue.toString();
                    return true;
                },
                cellClassRules: {
                    'disabled-cell': (params) => st.id !== params.data.segmentationTemplateId,
                },
            };

            colDefs.push(colDef);
        });
    });

    return colDefs;
};

export class SegmentationSampleGridColBuilder {
    private _colDataFactory: SegmentationSampleGridColDataFactory;
    private _colDefs: ColDef[];

    constructor(colDataFactory: SegmentationSampleGridColDataFactory) {
        this._colDataFactory = colDataFactory;
        this._colDefs = [];
    }

    public get colDefs(): ColDef[] {
        return this._colDefs;
    }

    public addSampleTemplateCol(
        lvOrganizationSampleTemplates: LvOrganizationSampleTemplate[],
        isEditable: boolean,
    ) {
        const indexedValues = new Map(
            lvOrganizationSampleTemplates?.map((o) => [o.sampleTypeId, o.description]),
        );

        const col: ColDef = {
            headerName: 'Sample Template',
            colId: 'sample_template',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            editable: isEditable,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                values: [...indexedValues.values()],
            } as IRichCellEditorParams,
            valueGetter: (params: ValueGetterParams) => indexedValues.get(params.data.sampleTypeId),
            valueSetter: (params: ValueSetterParams) => {
                indexedValues.forEach((v, k) => {
                    if (v === params.newValue) {
                        params.data.sampleTypeId = k;
                    }
                });

                return true;
            },
            cellClassRules: {
                'invalid-cell': (params) => !params.data.sampleTypeId,
            },
        };

        this._colDefs.push(col);

        return this;
    }

    public addCollectionDateCol(isEditable: boolean) {
        const dateFormat = 'MM/DD/YYYY HH:mm A';
        const minCollectionDate = new Date(1950, 0, 1);
        const maxCollectionDate = moment(new Date()).startOf('day').add(2, 'days').toDate();
        const col: ColDef = {
            headerName: 'Collection Date',
            colId: 'collection_date',
            editable: isEditable,
            cellEditorPopup: true,
            valueGetter: (params) => DateUtility.toView(params.data.collectionDate),
            valueSetter: (params) => {
                let date = moment(params.newValue, dateFormat);
                params.data.collectionDate = date.isBetween(minCollectionDate, maxCollectionDate)
                    ? date.toDate()
                    : null;

                return true;
            },
            cellEditorParams: {
                maxCollectionDate,
            },
            cellEditorSelector: (params: ICellEditorParams) => {
                const { context } = params;
                const { preferences }: { preferences: SampleSegmentationGridPreferences } = context;

                return {
                    component:
                        preferences.dateTimeEditor === AgDateTimeEditorTypes.PICKER
                            ? AgDatePickerEditor
                            : AgDateMaskEditor,
                };
            },
            cellClassRules: {
                'invalid-cell': (params) => !params.data.collectionDate,
            },
        };

        this.colDefs.push(col);

        return this;
    }

    public addSegmentationTemplateCol(isEditable: boolean) {
        const col: ColDef = {
            headerName: 'Segmentation Template',
            colId: 'segmentation_template',
            editable: isEditable,
            cellEditor: 'agRichSelectCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                values: [
                    ...this._colDataFactory.segmentationTemplateSelectorOptions.map((x) => x.title),
                ],
            } as IRichCellEditorParams,
            valueGetter: (params: ValueGetterParams) => {
                const selectorOption =
                    this._colDataFactory.segmentationTemplateSelectorOptions.find(
                        (x) => x.value === params.data.segmentationTemplateId,
                    );

                return selectorOption?.value && Number(selectorOption?.value) > 0 
                ? selectorOption.title 
                : null;
            },
            valueSetter: (params: ValueSetterParams) => {
                const templateName = params.newValue;

                const segmentationTemplate =
                    this._colDataFactory.getSegmentationTemplateByName(templateName);
                const segmentationTemplateId = segmentationTemplate?.id;

                const newSegments = {};
                segmentationTemplate?.segments.forEach((s) => (newSegments[s.name] = null));

                params.data.segmentationTemplateId = segmentationTemplateId;
                params.data.segments = newSegments;

                if (segmentationTemplateId) {
                    params.data.description = null;
                }

                return true;
            },
            onCellValueChanged: (event: CellValueChangedEvent) => {
                const { oldValue, newValue, api } = event;
                if (oldValue === newValue) {
                    return;
                }

                const oldSegmentationTemplate =
                    this._colDataFactory.getSegmentationTemplateByName(oldValue);
                const newSegmentationTemplate =
                    this._colDataFactory.getSegmentationTemplateByName(newValue);
                const oldSegmentationTemplateId = oldSegmentationTemplate?.id;
                const newSegmentationTemplateId = newSegmentationTemplate?.id;

                const currColDefs = api.getColumnDefs();
                const updatedColDefs: ColDef[] = this.removeUnusedSegmentationCols(
                    oldSegmentationTemplateId,
                    currColDefs,
                );

                const newColDefs: ColDef[] = this.createNewSegmentationCols(
                    newSegmentationTemplateId,
                    currColDefs,
                    isEditable,
                );

                updatedColDefs.splice(updatedColDefs.length - 3, 0, ...newColDefs);
                api.setColumnDefs(updatedColDefs);
            },
        };

        this.colDefs.push(col);

        return this;
    }

    public addDescriptionCol(isEditable: boolean) {
        const colDef: ColDef = {
            headerName: 'Sample Description',
            field: 'description',
            editable: (params: EditableCallbackParams | CellClassParams) =>
                !params.data.segmentationTemplateId && isEditable,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true,
            cellEditorParams: {
                maxLength: 265,
                rows: 10,
                cols: 50,
            },
            cellClassRules: {
                'disabled-cell': (params) => params.data.segmentationTemplateId,
                'invalid-cell': (params) =>
                    !params.data.segmentationTemplateId && !params.data.description,
            },
        };

        this.colDefs.push(colDef);

        return this;
    }

    public addLookupCols(areEditable: boolean) {
        for (let key in this._colDataFactory.groupedLookupSegmentationTemplates) {
            this._colDefs.push(
                ...createLookupTypeSegmentationColumns(
                    this._colDataFactory.lookupParentSegment(key),
                    this._colDataFactory.lookupChildSegment(key),
                    areEditable,
                ),
            );
        }

        return this;
    }

    public addNonLookupCols(areEditable: boolean) {
        this._colDefs.push(
            ...createNonLookupTypeSegmentationColumns(
                this._colDataFactory.nonLookupSegmentationTemplates,
                areEditable,
            ),
        );

        return this;
    }

    public addLastCols(
        lvOrganizationSampleTemplates: LvOrganizationSampleTemplate[],
        areEditable: boolean,
    ) {
        const lastCols: ColDef[] = [
            {
                headerName: 'Composite',
                field: 'composite',
                editable: areEditable,
                cellEditor: 'agRichSelectCellEditor',
                cellEditorPopup: true,
                cellEditorParams: {
                    values: ['None', '2-3', '4-5', '>5'],
                } as IRichCellEditorParams,
            },
            {
                headerName: 'Test Methods',
                editable: false,
                valueGetter: (params: ValueGetterParams) => {
                    const sampleTemplate = lvOrganizationSampleTemplates?.find(
                        (st) => st.sampleTypeId === params.data.sampleTypeId,
                    );

                    return sampleTemplate
                        ? formatTestMethodNames(sampleTemplate.testTemplates)
                        : null;
                },
            },
        ];

        this.colDefs.push(...lastCols);

        return this;
    }

    private removeUnusedSegmentationCols(
        segmentationTemplateId: number,
        currColDefs: ColDef[],
    ): ColDef[] {
        const oldValueSampleSegments = this._colDataFactory.samples.find(
            (s) => s.segmentationTemplateId === segmentationTemplateId,
        )?.segments;

        if (oldValueSampleSegments && Object.keys(oldValueSampleSegments).length) {
            return currColDefs;
        }
        const oldValueColIds = this._colDataFactory
            .getSegmentationTemplateById(segmentationTemplateId)
            ?.segments.map((ss) => segmentColdId(ss));

        return currColDefs.filter((cd) => !oldValueColIds?.includes(cd['colId']));
    }

    private createNewSegmentationCols(
        segmentationTemplateId: number,
        currColDefs: ColDef[],
        areEditable: boolean,
    ): ColDef[] {
        if (!segmentationTemplateId) {
            return [];
        }

        const newColDefExists = currColDefs.find((x) =>
            x['colId'].endsWith(`_${segmentationTemplateId}`),
        );

        if (newColDefExists) {
            return [];
        }

        const newColDefs: ColDef[] = [];
        const lookupSegments =
            this._colDataFactory.getLookupSegmentationTemplateById(segmentationTemplateId);
        const nonLookupSegments =
            this._colDataFactory.getNonLookupSegmentationTemplateById(segmentationTemplateId);

        if (lookupSegments && lookupSegments.segments.length) {
            const groups = this._colDataFactory.groupLookupSegments(lookupSegments.segments);
            for (let g in groups) {
                const parent = this._colDataFactory.lookupParentSegment(g, groups);
                const child = this._colDataFactory.lookupChildSegment(g, groups);
                newColDefs.push(...createLookupTypeSegmentationColumns(parent, child, areEditable));
            }
        }

        if (nonLookupSegments && nonLookupSegments.segments.length) {
            newColDefs.push(
                ...createNonLookupTypeSegmentationColumns([nonLookupSegments], areEditable),
            );
        }

        return newColDefs;
    }
}

export {
    createLookupTypeSegmentationColumns,
    createNonLookupTypeSegmentationColumns,
    SegmentationSampleGridColDataFactory,
};
