import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class FaqGroupService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getFaqGroups(includeFaqs) {
        return this.httpClient.get(`help/faq-groups/?includeFaqs=${!!includeFaqs}`);
    }

    getFaqGroup(id) {
        return this.httpClient.get(`help/faq-groups/${id}`);
    }

    deleteFaqGroups(ids) {
        var query = ids.map(id => `id=${id}`).join('&');
        return this.httpClient.delete(`help/faq-groups?${query}`);
    }

    saveFaqGroup(faqGroup) {
        if (!faqGroup.id)
            return this.httpClient.post('help/faq-groups/', faqGroup);

        return this.httpClient.put(`help/faq-groups/${faqGroup.id}`, faqGroup);
    }

    updateFaqGroupOrdinals(idOrdinalMap) {
        return this.httpClient.patch(`help/faq-groups/`, idOrdinalMap);
    }
}
