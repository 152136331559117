function findAncestor(element, matchingClass, maxGenerations = 5) {
    var currentElement = element;
    for (let i = 0; i < maxGenerations; i++) {
        currentElement = currentElement.parentElement;
        if (!currentElement)
            return null;

        if (currentElement.classList.contains(matchingClass))
            return currentElement;
    }

    return null;
}

export default class FoundationValidationRenderer {
    render(renderInstruction) {
        for (let { result, elements } of renderInstruction.unrender)
            for (let element of elements)
                this.remove(element, result);

        for (let { result, elements } of renderInstruction.render)
            for (let element of elements)
                this.add(element, result);
    }

    add(element, result) {
        if (result.valid)
            return;

        if (element.nodeType === 8) // Comment node
            return;

        // Highlight "input" element.
        switch (element.tagName) {
            case 'DATEPICKER':
                element.querySelector('input').classList.add('is-invalid-input');
                break;

            case 'SELECT2':
            case 'SELECTOR':
            case 'TIME-SELECTOR':
                element.querySelector('select').classList.add('is-invalid-select2');
                break;

            case 'INPUT':
            case 'TEXTAREA':
            default:
                element.classList.add('is-invalid-input');
                break;
        }

        // Swap in a dynamic error message where needed.
        if (element.parentElement.hasAttribute('data-dynamic-error-message'))
        {
            let formErrorElement = element.parentElement.querySelector('.form-error');
            if (formErrorElement)
                formErrorElement.innerText = result.message;
            else {
                let errorTooltip = element.parentElement.querySelector('error-tooltip');
                if (errorTooltip)
                    errorTooltip.au.controller.viewModel.message = result.message;
            }
        }        

        // Highlight label.
        if (element.previousElementSibling)
            element.previousElementSibling.classList.add('is-invalid-label');

        // Display error messaging.
        if (element.nextElementSibling) {
            if (element.nextElementSibling.tagName === 'ERROR-TOOLTIP')
                element.nextElementSibling.au['error-tooltip'].viewModel.isVisible = true;
            else
                element.nextElementSibling.classList.add('is-visible');
        }

        // Highlight data-grid row if within a data-grid.
        var dataGridRow = findAncestor(element, 'ag-row');
        if (dataGridRow && dataGridRow.classList.contains('ag-row-inline-editing')) {
            dataGridRow.classList.add('error-row');
            if (!dataGridRow.errorCount) {
                dataGridRow.errorCount = 1;
            } else {
                dataGridRow.errorCount = dataGridRow.errorCount + 1;
            }
        }

        // Highlight data-grid cell if within a data-grid.
        var dataGridCell = findAncestor(element, 'ag-cell');
        dataGridCell?.classList.add('error-cell');
    }

    remove(element, result) {
        if (result.valid) {
            let dataGridCell = findAncestor(element, 'ag-cell');
            if (dataGridCell && dataGridCell.__valid)
                dataGridCell.classList.remove('error-cell');

            return;
        }

        if (element.nodeType === 8) // Comment node
            return;

        // Remove highlight on "input" element.
        switch (element.tagName) {
            case 'DATEPICKER':
                element.querySelector('input').classList.remove('is-invalid-input');
                break;

            case 'SELECT2':
            case 'SELECTOR':
            case 'TIME-SELECTOR':
                element.querySelector('select').classList.remove('is-invalid-select2');
                break;

            case 'INPUT':
            case 'TEXTAREA':
            default:
                element.classList.remove('is-invalid-input');
                break;
        }

        // Remove highlight on label.
        if (element.previousElementSibling)
            element.previousElementSibling.classList.remove('is-invalid-label');

        // Hide error messaging.
        if (element.nextElementSibling) {
            if (element.nextElementSibling.tagName === 'ERROR-TOOLTIP')
                element.nextElementSibling.au['error-tooltip'].viewModel.isVisible = false;
            else
                element.nextElementSibling.classList.remove('is-visible');
        }

        // Remove highlight on data-grid row if within a data-grid.
        var dataGridRow = findAncestor(element, 'ag-row');
        if (dataGridRow) {
            dataGridRow.errorCount = dataGridRow.errorCount - 1;
            if (dataGridRow.errorCount === 0)
                dataGridRow.classList.remove('error-row');
        }

        // Remove highlight on data-grid cell if within a data-grid.
        let dataGridCell = findAncestor(element, 'ag-cell');
        if (dataGridCell?.__valid)
            dataGridCell?.classList.remove('error-cell');
    }
}
