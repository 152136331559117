import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationController } from 'aurelia-validation';
import ValidationErrorFormatter from 'infrastructure/validation/validation-error-formatter';
import Logger from '../../../infrastructure/logger';
import CapPlanService from '../cap-plan-service';
import FormSubmittedResult from '../contracts/form-submitted-result';
import { UiCapPlan } from '../ui-cap-plan';

@autoinject
export class SubmitButtonsCustomElement {
    @bindable form: UiCapPlan;
    @bindable editable: boolean;

    constructor(
        private readonly capPlanService: CapPlanService,
        private element: Element,
        private readonly logger: Logger,
        private readonly router: Router,
        private readonly validationController: ValidationController,
    ) {}

    async save() {
        const { valid } = await this.validationController.validate();
        if (!valid) {
            return;
        }

        const submittingEvent = new CustomEvent('form-submitting', { bubbles: true });
        const formSubmittedResult: FormSubmittedResult = { succeeded: false };
        const submittedEvent = new CustomEvent('form-submitted', {
            bubbles: true,
            detail: formSubmittedResult,
        });
        this.element.dispatchEvent(submittingEvent);

        try {
            let capPlan = this.form.toEntity();
            if (capPlan.id === 0) {
                const { id } = await this.capPlanService.createCapPlan(
                    capPlan.organizationId,
                    capPlan,
                );
                capPlan.id = id;
            } else {
                capPlan = await this.capPlanService.updateCapPlan(capPlan.organizationId, capPlan);
            }

            formSubmittedResult.succeeded = true;
            formSubmittedResult.tenantId = capPlan.organizationId;
            formSubmittedResult.entityId = capPlan.id;
        } catch (error) {
            this.logger.error('Error saving CAP Plan.', error);
            formSubmittedResult.message = ValidationErrorFormatter.format(
                'An error occured.  Please try again later.',
                error,
            );
        }

        this.element.dispatchEvent(submittedEvent);
    }

    cancel() {
        this.router.navigateToRoute('cap-plan-list');
    }
}
