import { bindable, inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import SelectorOption from 'infrastructure/selector-option';
import { getCollectionTypeOptions } from 'location-testing/collection-type';
import svgPanZoom from 'svg-pan-zoom';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import RequestService from '../../requests/request-service';
import SecurityService from '../../security/security-service';
import TestResultSummary from '../../tests/test-result-summary';
import TestService from '../../tests/test-service';
import UserService from '../../users/user-service';
import MapService from '../maps/map-service';
import operationsTimingOptions from '../operations-timing-options';
import PlanService from '../plans/plan-service';
import PointService from '../points/point-service';
import TaskService from './task-service';

@inject(Router, ValidationControllerFactory, Logger, PageContext, DialogPresenter, SecurityService, TaskService, PlanService, UserService, PointService, MapService, RequestService, TestService)
export class TaskDetail {
    @bindable assignedUser;
    @observable searchText;
    @bindable taskStatusId;
    @bindable plan;
    @bindable point;

    validationController: ValidationController;
    panZoom: any;
    mapSvg: any;

    usersLoading: boolean;
    canEditTasks: boolean;
    canViewAudit: boolean;
    isLocked: boolean;
    createNew: boolean;
    formChanged: boolean;
    sampleSubmitted: boolean;

    plans: any[];
    mapId: number;
    planId: number;
    points: any[];
    task: any;
    taskStatuses: any[];
    discretionaryPlans: any[];
    tests: TestResultSummary[];
    request: any;
    map: any;
    recordChanges: any[];
    collectionTypeOptions: SelectorOption[];

    matchingUsers: any[];
    operationsTimingOptions: any;
    scale: number;
    propertyDisplayNames: any;
    loadPromise: any;

    constructor(
        private router: Router,
        validationControllerFactory: ValidationControllerFactory,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private taskService: TaskService,
        private planService: PlanService,
        private userService: UserService,
        private pointService: PointService,
        private mapService: MapService,
        private requestService: RequestService,
        private testService: TestService
    ) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.logger.name = 'task-detail';

        this.searchText = '';
        this.usersLoading = true;
        this.matchingUsers = [];
        this.collectionTypeOptions = getCollectionTypeOptions();

        this.sampleSubmitted = false;
        this.canEditTasks = this.securityService.hasPermission('EditTasks') && !this.securityService.isImpersonating();
        this.canViewAudit = this.securityService.isCurrentUserInternal() && this.securityService.isImpersonating();
        this.isLocked = false;

        this.operationsTimingOptions = operationsTimingOptions;

        this.scale = 1;

        this.propertyDisplayNames = {
            'AssignedUserFullName': 'Assigned User',
            'AssigningUserFullName': 'Assigning User',
            'Description': 'Description',
            'PointName': 'Point',
            'PlanName': 'Plan',
            'TaskStatus': 'Status',
            'SampleName': 'Sample',
            'TargetDateTime': 'Target Date',
            'CollectedDateTime': 'Collected Date',
            'OperationsTiming': 'Operations Timing',
            'CollectionType': 'Collection Type',
            'IsActive': 'Active'
        };
    }

    activate(params) {
        // eslint-disable-next-line sonarjs/cognitive-complexity
        this.loadPromise = (async () => {

            this.pageContext.isLoading = true;

            try {
                var paramsPlanId = parseInt(params.planId);

                this.createNew = params.id === 'create';

                if (this.createNew) {
                    if (paramsPlanId) {
                        let plan = await this.planService.getPlan(paramsPlanId) as any;

                        this.plans = await this.planService.getPlans(plan.mapId) as any;
                        this.plan = plan;
                        this.mapId = this.plan.mapId;
                    } else {
                        if (!params.mapId)
                            throw new Error('Expected mapId because planId was not specified');

                        let mapId = parseInt(params.mapId);
                        this.plans = await this.planService.getPlans([mapId]) as any;
                        this.plan = null;
                        this.mapId = mapId;
                    }

                    this.task = { planId: this.planId, isActive: true, taskStatusId: 1, operationsTiming: 'NotSpecified' };
                } else {
                    this.task = await this.taskService.getTask(parseInt(params.id)) as any;
                    this.plans = [];
                    this.plan = this.task.planId ?
                        await this.planService.getPlan(this.task.planId) :
                        null;

                    if (this.plan) {
                        this.mapId = this.plan.mapId;
                    } else {
                        let point = await this.pointService.getPoint(this.task.pointId);
                        this.mapId = point.mapId;
                    }

                    this.sampleSubmitted = !!this.task.sampleId;
                }

                this.taskStatuses = this.taskService.getTaskStatuses();
                this.discretionaryPlans = this.plans.filter(p => p.planTypeId === 1);
                this.isLocked = !this.task.isActive;

                this.task.targetDate = this.setDate(this.task.targetDateTime);
                this.task.targetTime = this.setTime(this.task.targetDate);
                this.task.collectedDate = this.setDate(this.task.collectedDateTime);
                this.task.collectedTime = this.setTime(this.task.collectedDate);

                this.taskStatusId = this.task.taskStatusId;

                this.assignedUser = null;
                if (this.task && this.task.assignedUserId)
                    this.assignedUser = await this.userService.getUser(this.task.assignedUserId);

                if (this.task.pointId) {
                    this.point = await this.pointService.getPoint(this.task.pointId);
                }

                this.map = await this.mapService.getMap(this.mapId);

                this.request = null;
                this.tests = null;
                if (this.task.sampleId) {
                    this.request = (await this.requestService.getRequests({ taskId: this.task.id }))[0];
                    if (this.request) {
                        if (this.request.requestDate)
                            this.request.requestDate = this.setDate(this.request.requestDate);
                        if (this.request.receiveDate)
                            this.request.receiveDate = this.setDate(this.request.receiveDate);
                        if (this.request.startOfTestingDate)
                            this.request.startOfTestingDate = this.setDate(this.request.startOfTestingDate);
                    }

                    this.tests = await this.testService.getTestResultSummaries({ sampleIds: [this.task.sampleId] });
                }

                ValidationRules
                    .ensure('planId').required().when((t: any) => !t.id)
                    .ensure('pointId').required()
                    .ensure('assignedUserId').required().when((t: any) => t.taskStatusId === 2 || t.taskStatusId === 3)  // when InProgress or Completed
                    .ensure('taskStatusId').required()
                    .ensure('comments').required().when((t: any) => t.taskStatusId === 4) // when DidNotComplete
                    .ensure('targetDate').required()
                    .ensure('targetTime').required()
                    .ensure('collectedDate').required().when((t: any) => t.taskStatusId === 3) // when Completed
                    .ensure('collectedTime').required().when((t: any) => t.taskStatusId === 3) // when Completed
                    .ensure('sampleDescription').maxLength(265)
                    .ensure('collectionType').required()
                    .on(this.task);
            } catch (error) {
                this.logger.error('Error loading task', error, { taskId: params.id });

                await (error.apiErrorCode === 1
                    ? this.dialogPresenter.showAlert(
                        'Error Loading Task',
                        'The current task doesn\'t exist.')
                    : this.dialogPresenter.showAlert(
                        'Error Loading Task',
                        'An error occurred while loading the current task. Please try again later.')
                );

                this.router.navigateToRoute('task-list', { mapId: this.mapId });
            }

            this.pageContext.isLoading = false;
        })();
    }

    getTestResultCaption(test) {
        if (test.passed)
            return 'Pass';

        if (test.passed === null) {
            return test.hasSpecification ?
                'Incomplete' :
                'Spec Required';
        }

        return 'Fail';
    }

    async planChanged() {
        if (!this.plan) {
            this.task.planId = null;
            this.planId = null;
            this.points = null;
            return;
        }

        this.task.planId = this.plan.id;
        this.planId = this.plan.id;

        let points = await this.pointService.getPoints({
            mapIds: [this.plan.mapId],
            planIds: [this.plan.id]
        });

        this.points = points.filter(p => p.isActive);

        if (this.createNew) {
            // Clear out the selected point if the point is not part of the newly selected plan.
            if (this.point && !this.points.find(p => p.id === this.point.id))
                this.point = null;

            // Notify user that the newly selected plan does not have any points.
            if (this.points.length === 0)
                await this.dialogPresenter.showAlert('No Points', 'The selected plan does not have any associated points. Please select another plan.');
        }
    }

    pointChanged() {
        this.task.pointId = this.point ? this.point.id : undefined;
        this.tryBringPointIntoView();
    }

    async taskStatusIdChanged(statusId) {
        this.task.taskStatusId = statusId;
        if (statusId)
            await this.validationController.validate(); // Explicitly revalidating to accomodate the custom validations linked to status
    }

    setDate(date) {
        if (date && typeof date === 'string')
            // Convert UTC+00:00 date string into date object @ local time
            date = new Date(`${date}${date.endsWith('Z') ? '' : 'Z'}`);
        return date;
    }

    setTime(dateTime) {
        if (!dateTime)
            return null;
        var hours = dateTime.getHours();
        var mins = dateTime.getMinutes();
        return `${this.getPaddingDateTimePart(hours)}:${this.getPaddingDateTimePart(mins)}`;
    }

    getPaddingDateTimePart(part) {
        return `${part < 10 ? '0' : ''}${part}`;
    }

    handleFormChange() {
        this.formChanged = true;
    }

    async searchTextChanged() {
        if (this.searchText.trim() === '')
            return;
        try {
            this.usersLoading = true;
            var results = await this.userService.getUsers(this.searchText) as any;
            this.matchingUsers = results;
            this.usersLoading = false;
        } catch (error) {
            this.logger.error('Error searching for users.', error, { searchText: this.searchText });
            this.usersLoading = false;
        }
    }

    setAssignedUser(user) {
        this.assignedUser = user;
        this.task.assignedUserId = user.id;
        this.searchText = '';
    }

    clearAssignedUser() {
        this.assignedUser = null;
        this.task.assignedUserId = null;
    }

    zoomIn() {
        this.panZoom?.zoomIn();
    }

    zoomOut() {
        this.panZoom?.zoomOut();
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('task-list', { mapId: this.mapId });
    }

    async save() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        // NOTE: we are doing this due to the input binding
        var assignedUserId = parseInt(this.task.assignedUserId);
        this.task.assignedUserId = assignedUserId ? assignedUserId : null;

        if (this.task.taskStatusId != 3) {
            // Set collected date/time to blank for non-Completed statuses
            this.task.collectedDate = null;
            this.task.collectedTime = null;
            this.task.collectedDateTime = null;
        }

        this.task.targetDateTime = this.combineDateAndTimeToUtcString(this.task.targetDate, this.task.targetTime);
        if (this.task.collectedDate && this.task.collectedTime)
            this.task.collectedDateTime = this.combineDateAndTimeToUtcString(this.task.collectedDate, this.task.collectedTime);

        try {
            this.task.id = ((await this.taskService.saveTask(this.task)) as any).id;
            this.formChanged = false;
            this.createNew = false;
            this.pageContext.showSuccessOverlay('Task saved successfully.');
            this.router.navigateToRoute('task-detail', { id: this.task.id }, { replace: true });
        } catch (error) {
            this.logger.error('Error saving task.', error, { task: this.task });
            this.dialogPresenter.showAlert(
                'Error Saving Task',
                this.getApiErrorMessage(error.apiErrorCode));
        }

        this.isLocked = !this.task.isActive;
        this.pageContext.isLoading = false;
    }

    combineDateAndTimeToUtcString(date, timeString) {
        var localDateTimeString = `${date.getFullYear()}-${this.getPaddingDateTimePart(date.getMonth() + 1)}-${this.getPaddingDateTimePart(date.getDate())}T${timeString}`;
        var localDateTime = new Date(localDateTimeString);
        var utcDateString = `${localDateTime.getUTCFullYear()}-${this.getPaddingDateTimePart(localDateTime.getUTCMonth() + 1)}-${this.getPaddingDateTimePart(localDateTime.getUTCDate())}`;
        var utcTimeString = `${this.getPaddingDateTimePart(localDateTime.getUTCHours())}:${this.getPaddingDateTimePart(localDateTime.getUTCMinutes())}`;
        return `${utcDateString}T${utcTimeString}`;
    }

    getApiErrorMessage(errorCode) {
        return 'An error occurred while saving the current task. Please try again later.';
    }

    async loadRecordChanges() {
        try {
            this.pageContext.isLoading = true;
            this.recordChanges = await this.taskService.getTaskRecordChanges(this.task.id) as unknown as any[];
        } catch (error) {
            this.dialogPresenter.showAlert(
                'Error Loading Task Audit',
                'An error occurred while loading the current task audit. Please try again later.');
        }

        this.pageContext.isLoading = false;
    }

    tryBringPointIntoView() {
        // Ensure UI has rendered based on bindings from loaded data.
        var interval = setInterval(() => {
            var mapImage = this.mapSvg && this.mapSvg.querySelector('image');
            if (!mapImage)
                return;

            clearInterval(interval);

            var mapImageBoundingRectangle = mapImage.getBoundingClientRect();

            // If the image has loaded (by checking width and height), perform pan, otherwise, perform pan on image load event.
            if (mapImageBoundingRectangle.width && mapImageBoundingRectangle.height)
                this.bringPointIntoView(mapImageBoundingRectangle);
            else
                mapImage.onload = () => {
                    // Requery image size after image is loaded.
                    mapImageBoundingRectangle = mapImage.getBoundingClientRect();
                    this.bringPointIntoView(mapImageBoundingRectangle);
                };
        }, 100);
    }

    bringPointIntoView(mapImageBoundingRectangle) {
        // Pan x and y only if the image is larger than the container SVG in that dimension.

        // Using loose equivalency due to binding for validation on x which makes it a string.
        const x = this.point.x != 0 && mapImageBoundingRectangle.width > this.mapSvg.clientWidth ?
            this.mapSvg.clientWidth / 2 - this.point.x :
            0;

        const y = this.point.y != 0 && mapImageBoundingRectangle.height > this.mapSvg.clientHeight ?
            this.mapSvg.clientHeight / 2 - this.point.y :
            0;

        this.panZoom.pan({ x, y });
    }

    async attached() {
        this.panZoom = svgPanZoom('#svg-id', {
            fit: false,
            center: false,
            zoomEnabled: true,
            dblClickZoomEnabled: false,
            minZoom: .001,
            onZoom: (scale) => {
                this.scale = scale;
            }
        });

        // Ensure data is loaded.
        await this.loadPromise;

        if (!this.point)
            return;

        this.tryBringPointIntoView();
    }
}
