import { inject } from 'aurelia-framework';
import SecurityService from '../security-service';

@inject(SecurityService)
export default class NavigationAuthorizer {
    constructor(securityService) {
        this.securityService = securityService;
    }

    async hasAccess(routeConfig) {
        // Default allowedUserTypes to 'external'.
        var isCurrentUserInternal = this.securityService.isCurrentUserInternal();
        var isCurrentUserImpersonating = this.securityService.isImpersonating();
        var isCurrentUserWorkingUnderOrganization = this.securityService.isWorkingUnderOrganization();
        var allowedUserTypes = routeConfig.allowedUserTypes || 'external';

        if ('authorize' in routeConfig) {
            let authorized = await routeConfig.authorize();
            if (!authorized)
                return false;
        }

        // Short circuit and block access if the current user not is not allowed.
        var hasAccessBasedOnAllowedUserTypes = this.hasAccessBasedOnAllowedUserTypes(allowedUserTypes, isCurrentUserInternal, isCurrentUserImpersonating, isCurrentUserWorkingUnderOrganization);
        if (!hasAccessBasedOnAllowedUserTypes)
            return false;

        // Allow internal users if not impersonating since internal users do not have permissions assinged.
        if (isCurrentUserInternal && !isCurrentUserImpersonating)
            return true;

        // At this point the user is either external or an impersonated external user.
        // If required permissions are not specified, check to see if permissions are explicitly ignored, if not, block access.
        if (!routeConfig.requiredPermission)
            return routeConfig.ignorePermissions;

        // Check permissions of the external or impersonated external user. If the user has at least one of the permissions, allow access.
        if (routeConfig.requiredPermission)
            return routeConfig.requiredPermission.split(',').some(p => this.securityService.hasPermission(p));

        // If permission checks succeed, allow access.
        return true;
    }

    hasAccessBasedOnAllowedUserTypes(allowedUserTypes, isCurrentUserInternal, isCurrentUserImpersonating, isCurrentUserWorkingUnderOrganization) {
        switch (allowedUserTypes) {
            case 'internal':
                return isCurrentUserInternal && !isCurrentUserImpersonating;

            case 'external':
                return !isCurrentUserInternal || isCurrentUserImpersonating || isCurrentUserWorkingUnderOrganization;

            case 'both':
            default:
                return true;
        }
    }
};
