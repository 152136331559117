import {
    ColDef,
    ColGroupDef,
    GetQuickFilterTextParams,
    GridOptions,
    ICellRendererParams,
    ValueFormatterParams,
    ValueGetterParams,
} from 'ag-grid-community';
import moment from 'moment';

const checkbox = (params: ICellRendererParams): boolean => {
    return params.node.group === true;
};

const formatApproved = (params: ValueFormatterParams): string => {
    if (typeof params.value === 'undefined') {
        return '';
    }

    switch (params.value) {
        case 'True':
        case 'TRUE':
        case true:
            return 'Yes';
        case 'False':
        case 'FALSE':
        case false:
            return 'No';
        default:
            return 'Incomplete';
    }
};

const formatDateToLocal = (params: ValueFormatterParams): string => {
    if (!params.value) {
        return;
    }

    return moment(params.value).local().format('L');
};

const ignoreQuickFilter = (params: GetQuickFilterTextParams): string => '';

export const generatePartialTestResultsColumnDefs = (): (ColDef | ColGroupDef)[] => [
    {
        headerName: 'Lab',
        field: 'labCode',
        chartDataType: 'category',
        getQuickFilterText: ignoreQuickFilter,
    },
    {
        headerName: 'Request Id',
        field: 'requestId',
        chartDataType: 'category',
        rowGroup: true,
        hide: true,
    },
    {
        headerName: 'Sample Id',
        field: 'sampleId',
        chartDataType: 'category',
        rowGroup: true,
        hide: true,
    },
    {
        headerName: 'Sample Received Date',
        field: 'sampleReceivedDate',
        chartDataType: 'series',
        valueFormatter: formatDateToLocal,
        getQuickFilterText: ignoreQuickFilter,
    },
    {
        headerName: 'Sample Description',
        field: 'sampleDescription',
        chartDataType: 'category',
    },
    {
        headerName: 'Composite',
        field: 'sampleComposite',
        chartDataType: 'category',
        getQuickFilterText: ignoreQuickFilter,
    },
    {
        headerName: 'Bill Code',
        field: 'billCode',
        chartDataType: 'category',
    },

    {
        headerName: 'Start of Testing Date',
        field: 'requestStartOfTestingDate',
        chartDataType: 'series',
        valueFormatter: formatDateToLocal,
        getQuickFilterText: ignoreQuickFilter,
    },
    {
        headerName: 'Collection Date',
        field: 'sampleCollectionDate',
        chartDataType: 'series',
        valueFormatter: formatDateToLocal,
        getQuickFilterText: ignoreQuickFilter,
    },
    {
        headerName: 'Result',
        field: 'qualitativeResult',
        valueGetter: (params: ValueGetterParams) => {
            return params.data?.qualitativeResult === 'Unknown'
                ? 'Pending'
                : params.data?.qualitativeResult;
        },
    },
    {
        headerName: 'Units',
        field: 'units',
        chartDataType: 'category',
        getQuickFilterText: ignoreQuickFilter,
    },

    {
        headerName: 'Approved',
        field: 'approved',
        chartDataType: 'category',
        valueFormatter: formatApproved,
        getQuickFilterText: ignoreQuickFilter,
    },
];

export const partialTestResultGrid: GridOptions = {
    columnDefs: generatePartialTestResultsColumnDefs(),
    defaultColDef: {
        flex: 1,
        minWidth: 100,
        sortable: true,
        filter: true,
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true,
    },
    autoGroupColumnDef: {
        minWidth: 250,
        headerName: 'Test',
        field: 'testMethodDescription',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            checkbox,
        } as any,
    },
    rowSelection: 'multiple',
    groupSelectsChildren: true,
    suppressRowClickSelection: true,
    sideBar: 'columns',
    animateRows: true,
    statusBar: {
        statusPanels: [
            { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
            { statusPanel: 'agTotalRowCountComponent', align: 'center' },
            { statusPanel: 'agFilteredRowCountComponent' },
            { statusPanel: 'agSelectedRowCountComponent' },
            { statusPanel: 'agAggregationComponent' },
        ],
    },
    suppressAggFuncInHeader: true,
    enableRangeSelection: true,
    enableCharts: true,
    popupParent: document.body,
    customChartThemes: {
        passFailCustomTheme: {
            baseTheme: 'ag-default',
            palette: {
                fills: ['#25d925', '#c00', '#9fd0cb'],
                strokes: ['#24d824', '#cc0000', '#9ed1cc'],
            },
        },
    },
    chartThemes: ['passFailCustomTheme', 'ag-default', 'ag-pastel', 'ag-vivid'],
};
