import { autoinject, bindable, BindingEngine, bindingMode, Disposable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
} from 'aurelia-validation';
import OrganizationService from 'organizations/organization-service';
import SegmentationTemplateService from 'segmentation-templates/segmentation-template-service';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import SecurityService from '../../security/security-service';
import AdhocReportOptionsService from './adhoc-report-options-service';
import AdhocReportService from './adhoc-report-service';

@autoinject
export default class AdhocReportDetail {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterFields;
    @bindable filterComponents;

    validationController: ValidationController;
    queryLevelChangedSubscription: Disposable;

    segmentsEnabled: boolean;
    sampleSegments: any[];

    allQueryLevelItems: any[];
    queryLevelItems: any[];

    adhocReport: any;
    reportResults: any[];

    sortDirections: any;
    gridOptions: any;

    formChanged: boolean;
    validateExecutionOnly: boolean;
    noValidOutputColumns: boolean;
    duplicateSortFieldsPresent: boolean;
    canSaveAdhocReports: boolean;

    constructor(
        private bindingEngine: BindingEngine,
        private router: Router,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private organizationService: OrganizationService,
        private segmentationTemplateService: SegmentationTemplateService,
        private adhocReportOptionsService: AdhocReportOptionsService,
        private adhocReportService: AdhocReportService,
        securityService: SecurityService,
        validationControllerFactory: ValidationControllerFactory,
    ) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.logger.name = 'adhoc-report-detail';

        this.sortDirections = [
            { title: 'Ascending', value: 'Ascending' },
            { title: 'Descending', value: 'Descending' },
        ];

        this.noValidOutputColumns = false;
        this.duplicateSortFieldsPresent = false;

        this.canSaveAdhocReports = !securityService.isWorkingUnderOrganization();
    }

    atLeastOneValidField(fields) {
        return !!fields && !!fields.filter((f) => f.key).length;
    }

    fieldsAreUnique(fields) {
        if (!fields) return true;

        var counts = fields
            .filter((f) => f.key)
            .reduce((counts, field) => {
                counts[field.key] = counts[field.key] ? counts[field.key] + 1 : 1;
                return counts;
            }, {});

        for (let key in counts) if (counts[key] > 1) return false;

        return true;
    }

    activate(params) {
        (async () => {
            this.pageContext.isLoading = true;

            try {
                var [organizationConfigurations, adhocReportOptions, adhocReport] =
                    await Promise.all([
                        this.organizationService.getConfiguration('hasSegmentAccess'),
                        this.adhocReportOptionsService.getAdhocReportOptions(),
                        params.id === 'create'
                            ? Promise.resolve({
                                  settings: {
                                      queryLevel: 'Request',
                                      filterFields: [{ values: [] }],
                                      outputFields: [{}],
                                      sortFields: [{}],
                                  },
                              })
                            : (this.adhocReportService.getAdhocReport(params.id) as any),
                    ]);

                this.segmentsEnabled = Object.keys(organizationConfigurations)
                    .map((k) => organizationConfigurations[k].hasSegmentAccess)
                    .some((v) => v === 'True');
                this.sampleSegments = await this.getSampleSegments();
                this.allQueryLevelItems = adhocReportOptions.queryLevelItems;
                this.queryLevelItems = this.allQueryLevelItems;
                this.adhocReport = adhocReport;

                if (params.id !== 'create') {
                    this.adhocReport.settings.filterFields.push({ values: [] });
                    this.adhocReport.settings.outputFields.push({});
                    this.adhocReport.settings.sortFields.push({});
                }

                this.handleQueryLevelChanged();
                this.queryLevelChangedSubscription = this.bindingEngine
                    .propertyObserver(this.adhocReport.settings, 'queryLevel')
                    .subscribe(this.handleQueryLevelChanged.bind(this));

                for (let filterField of this.adhocReport.settings.filterFields) {
                    let field = this.findField(filterField.key);
                    if (field) {
                        filterField.dataType =
                            filterField.key === 'SampleSegment'
                                ? // TODO: Set the data type appropriately based on the segment's data type once implemented.
                                  'Text'
                                : field.dataType;
                        filterField.supportsFullTextSearch = field.supportsFullTextSearch;
                    }

                    this.setupSegmentNameValidation(filterField);
                }

                for (let outputField of this.adhocReport.settings.outputFields)
                    this.setupSegmentNameValidation(outputField);

                for (let sortField of this.adhocReport.settings.sortFields)
                    this.setupSegmentNameValidation(sortField);

                ValidationRules.ensure('name')
                    .required()
                    .when(() => !this.validateExecutionOnly)
                    .on(this.adhocReport);

                ValidationRules.ensure('queryLevel')
                    .required()
                    .ensure('outputFields')
                    .satisfies((outputFields) => this.atLeastOneValidField(outputFields))
                    .tag('atLeastOneValidField')
                    .ensure('sortFields')
                    .satisfies((sortFields) => this.fieldsAreUnique(sortFields))
                    .tag('fieldsAreUnique')
                    .on(this.adhocReport.settings);

                this.validationController.addObject(this.adhocReport);
            } catch (error) {
                this.logger.error('Error loading Ad-hoc query.', error);
                this.dialogPresenter.showAlert(
                    'Error Loading Ad-hoc Report',
                    'An error occurred while loading the ad-hoc report. Please try again later.',
                );
            }

            this.pageContext.isLoading = false;
        })();
    }

    setupSegmentNameValidation(field) {
        ValidationRules.ensure('segmentName')
            .required()
            .when((f: any) => f.key === 'SampleSegment')
            .on(field);

        this.validationController.addObject(field);
    }

    tearDownSegmentNameValidation(field) {
        ValidationRules.off(field);
        this.validationController.removeObject(field);
    }

    async getSampleSegments() {
        let segments = await this.segmentationTemplateService.getSegmentationTemplateSegments({});
        let uniqueSegments = segments.reduce((segmentOptions, s) => {
            if (!segmentOptions.some((o) => o.name === s.name)) segmentOptions.push(s);

            return segmentOptions;
        }, []);

        uniqueSegments.sort((s1, s2) => s1.name.localeCompare(s2.name));
        return uniqueSegments;
    }

    findField(key) {
        for (let queryLevelItem of this.queryLevelItems)
            for (let field of queryLevelItem.fields) if (field.key === key) return field;
    }

    handleQueryLevelChanged() {
        var index = this.allQueryLevelItems.findIndex(
            (queryLevelItem) => queryLevelItem.queryLevel === this.adhocReport.settings.queryLevel,
        );
        this.queryLevelItems = this.allQueryLevelItems.slice(0, index + 1);
    }

    filterFieldChanged(isLast, fieldKey) {
        if (isLast && fieldKey) {
            let filterField = {};

            this.setupSegmentNameValidation(filterField);
            this.adhocReport.settings.filterFields.push(filterField);
        }
    }

    outputFieldChanged(isLast, fieldKey) {
        if (isLast && fieldKey) {
            let outputField = {};

            this.setupSegmentNameValidation(outputField);
            this.adhocReport.settings.outputFields.push(outputField);
        }
    }

    sortFieldChanged(isLast, fieldKey) {
        if (isLast && fieldKey) {
            let sortField = {};

            this.setupSegmentNameValidation(sortField);
            this.adhocReport.settings.sortFields.push(sortField);
        }
    }

    removeFilterField(filterField) {
        this.formChanged = true;

        var index = this.adhocReport.settings.filterFields.indexOf(filterField);
        if (index !== -1) {
            let filterField = this.adhocReport.settings.filterFields.splice(index, 1)[0];
            this.tearDownSegmentNameValidation(filterField);
        }
    }

    removeOutputField(outputField) {
        this.formChanged = true;

        var index = this.adhocReport.settings.outputFields.indexOf(outputField);
        if (index !== -1) {
            let outputField = this.adhocReport.settings.outputFields.splice(index, 1)[0];
            this.tearDownSegmentNameValidation(outputField);
        }
    }

    removeSortField(sortField) {
        this.formChanged = true;

        var index = this.adhocReport.settings.sortFields.indexOf(sortField);
        if (index !== -1) {
            let sortField = this.adhocReport.settings.sortFields.splice(index, 1)[0];
            this.tearDownSegmentNameValidation(sortField);
        }
    }

    handleFormChange() {
        this.formChanged = this.canSaveAdhocReports;
    }

    handleOutputFieldDragged(details) {
        var fields = this.adhocReport.settings.outputFields;
        fields.splice(details.targetIndex, 0, fields.splice(details.sourceIndex, 1)[0]);
    }

    handleSortFieldDragged(details) {
        var fields = this.adhocReport.settings.sortFields;
        fields.splice(details.targetIndex, 0, fields.splice(details.sourceIndex, 1)[0]);
    }

    deactivate() {
        this.queryLevelChangedSubscription && this.queryLevelChangedSubscription.dispose();
    }

    getFieldProperty(targetField, propertyName) {
        if (targetField.key === 'SampleSegment') return targetField.segmentName;

        for (let queryLevelItem of this.allQueryLevelItems)
            for (let field of queryLevelItem.fields)
                if (field.key === targetField.key) return field[propertyName];
    }

    buildGridOptions() {
        return {
            columnDefs: this.adhocReport.settings.outputFields
                .filter((o) => o.key)
                .map((o) => ({
                    suppressMenu: true,
                    headerName: this.getFieldProperty(o, 'caption'),
                    field: o.key === 'SampleSegment' ? o.segmentName : o.key,
                })),
            defaultColDef: { sortable: true, resizable: true },
        };
    }

    async save() {
        var isValid = await this.validate();
        if (!isValid) return;

        this.pageContext.isLoading = true;

        try {
            let adhocReport = (await this.adhocReportService.saveAdhocReport(
                this.adhocReport,
            )) as any;
            this.adhocReport.id = adhocReport.id;

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Ad-hoc report saved successfully.');
            this.router.navigateToRoute(
                'adhoc-report-detail',
                { id: this.adhocReport.id },
                { replace: true },
            );
        } catch (error) {
            this.logger.error('Error saving ad-hoc report.', error, {
                adhocReport: this.adhocReport,
            });
            this.dialogPresenter.showAlert(
                'Error Saving Ad-hoc Report',
                'An error occurred while saving the current ad-hoc report. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    async run() {
        var isValid = await this.validate(true);
        if (!isValid) return;

        this.pageContext.isLoading = true;

        try {
            this.gridOptions = null;
            this.reportResults = (await this.adhocReportService.getTempAdhocReportResults(
                this.adhocReport,
            )) as unknown as any[];
            this.gridOptions = this.buildGridOptions();
        } catch (error) {
            this.logger.error('Error running ad-hoc report.', error, {
                adhocReport: this.adhocReport,
            });

            this.dialogPresenter.showAlert(
                'Error Running Ad-hoc Report',
                error.apiErrorCode === 5
                    ? 'The report query could not execute in a timely manner. Please adjust your filter settings in order to return fewer results.'
                    : 'An error occurred while running the current ad-hoc report. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    export(format) {
        if (!this.adhocReport.id || this.formChanged) {
            this.dialogPresenter.showAlert(
                'Save Ad-hoc Report',
                `The ad-hoc report ${
                    this.adhocReport.id ? 'has modifications and ' : ''
                }must be saved before exporting.`,
            );

            return;
        }

        window.location = this.adhocReportService.getDownloadUrl(
            this.adhocReport.id,
            format,
        ) as unknown as any;
    }

    async validate(executionOnly = false) {
        this.validateExecutionOnly = !!executionOnly;
        var aggregateResult = await this.validationController.validate();
        this.validateExecutionOnly = false;

        this.noValidOutputColumns = !aggregateResult.results.find(
            (r) => r.propertyName === 'outputFields' && r.rule.tag === 'atLeastOneValidField',
        ).valid;
        this.duplicateSortFieldsPresent = !aggregateResult.results.find(
            (r) => r.propertyName === 'sortFields' && r.rule.tag === 'fieldsAreUnique',
        ).valid;

        return aggregateResult.valid;
    }
}
