import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import PageContext from '../infrastructure/page-context';
import Logger from '../infrastructure/logger';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import NewsItemService from './news-item-service';

@inject(Router, ValidationControllerFactory, PageContext, Logger, DialogPresenter, NewsItemService)
export default class NewsItemDetail {
    constructor(router, validationControllerFactory, pageContext, logger, dialogPresenter, newsItemService) {
        this.router = router;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.pageContext = pageContext;

        this.logger = logger;
        this.logger.name = 'news-item-detail';

        this.dialogPresenter = dialogPresenter;
        this.newsItemService = newsItemService;
    }

    activate(params) {
        (async () => {
            try {
                this.pageContext.isLoading = true;

                this.newsItem = params.id === 'create' ?
                    { isActive: true } :
                    await this.newsItemService.getNewsItem(parseInt(params.id));

                // Create date object and remove the UTC so the date is not changes since we only care about the date part (and not the time).
                if (this.newsItem.publishDate)
                    this.newsItem.publishDate = new Date(this.newsItem.publishDate.substring(0, this.newsItem.publishDate.length - 1));

                ValidationRules
                    .ensure('title').displayName('Title').required().maxLength(100)
                    .ensure('description').displayName('Description').required().maxLength(255)
                    .ensure('linkUrl').displayName('Link URL').matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/).required().maxLength(1024)
                    .ensure('publishDate').displayName('Publish date').required()
                    .on(this.newsItem);

                this.validationController.addObject(this.newsItem);
            } catch (error) {
                this.logger.error('Error loading news item', error, { newsItemId: params.id });

                var errorAlertPromise = error.apiErrorCode === 1 ?
                    this.dialogPresenter.showAlert(
                        'Error Loading News Item',
                        'The current news item doesn\'t exist.') :
                    this.dialogPresenter.showAlert(
                        'Error Loading News Item',
                        'An error occurred while loading the current news item. Please try again later.');

                errorAlertPromise.then(() => {
                    this.router.navigateToRoute('news-item-list');
                });
            }

            this.pageContext.isLoading = false;
        })();
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('news-item-list');
    }

    async save() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        try {
            this.newsItem.id = (await this.newsItemService.saveNewsItem(this.newsItem)).id;

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('News item saved successfully.');
            this.router.navigateToRoute('news-item-detail', { id: this.newsItem.id }, { replace: true });
        } catch (error) {
            this.logger.error('Error saving news item.', error, { newsItem: this.newsItem });
            this.dialogPresenter.showAlert(
                'Error Saving News Item',
                this.getApiErrorMessage(error.apiErrorCode));
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        switch (errorCode) {
            default:
                return 'An error occurred while saving the current news item. Please try again later.';
        }
    }
};
