import RecordStatus from 'record-status';
import { BaseQueryFilter } from './base-query-filter';
import { QueryFiltered } from './query-filtered';

export interface RecordStatusQuery {
    recordStatus: RecordStatus;
}

export class RecordStatusQueryFilters extends BaseQueryFilter
    implements RecordStatusQuery, QueryFiltered<RecordStatusQuery> {
    private _recordStatus: RecordStatus;

    constructor() {
        super();
        this._recordStatus = RecordStatus.ACTIVE;
    }

    public get recordStatus() {
        return this._recordStatus;
    }

    public set recordStatus(recordStatuses: RecordStatus) {
        this._recordStatus = recordStatuses || RecordStatus.ACTIVE;
    }

    public get count(): number {
        return (Number(!!this._recordStatus && this._recordStatus !== RecordStatus.ACTIVE));
    }

    reset(): void {
        this._recordStatus = RecordStatus.ACTIVE;
    }

    getQueryParams(): RecordStatusQuery {
        return {
            recordStatus: this._recordStatus,
        };
    }

    setFilterValues(params: RecordStatusQuery): void {
        super.setQueryFilteredValues(params, this);
    }
}
