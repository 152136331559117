import { inject, bindable } from 'aurelia-framework';

@inject(Element)
export class RecordChangeList {
    @bindable recordTypeTitle;
    @bindable recordChanges;
    @bindable propertyDisplayNames;

    constructor(element) {
        this.element = element;
    }

    loadRecordChanges() {
        this.element.dispatchEvent(new CustomEvent('loadrecordchanges', { bubbles: true, detail: {} }));
    }

    getRelationshipOperationDisplay(operation) {
        switch (operation.toLowerCase()) {
            case 'add':
                return 'Added';
            case 'remove':
                return 'Removed';
            case 'modify':
                return 'Modified';
            default:
                return 'Unknown';
        }
    }
};
