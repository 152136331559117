import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import { StatusQuery } from 'status/status-query-filters';
import TestFilters from './test-filters';
import TestResultSummary from './test-result-summary';

@autoinject
export default class RequestService {
    constructor(private httpClient: HttpClient) {}

    getTestResultCounts(statusQuery: StatusQuery): Promise<any[]> {
        var query = buildQueryString(statusQuery, true);

        return this.httpClient.get(
            `tests/all/result-counts/${query ? `?${query}` : ''}`,
        ) as any as Promise<any[]>;
    }

    getTestResultSummaries(filters: TestFilters): Promise<TestResultSummary[]> {
        var query = buildQueryString(filters, true);
        return this.httpClient.get(`tests?${query}`) as any as Promise<TestResultSummary[]>;
    }

    fetchConfirmationTestResultValues(mapId: number, testMethodGroup: string): Promise<string[]> {
        var query = buildQueryString({ mapId, testMethodGroup });

        return this.httpClient.get(`tests/confirmation-values?${query}`) as unknown as Promise<
            string[]
        >;
    }
}
