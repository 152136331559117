import SelectorOption from 'infrastructure/selector-option';

enum PointRisk {
    NOT_SPECIFIED = 'NotSpecified',
    LOW = 'Low',
    MEDIUM = 'Medium',
    HIGH = 'High'
}

export default PointRisk;

export function getPointRiskOptions() {
    return [
        { value: PointRisk.NOT_SPECIFIED, title: 'Not Specified' },
        { value: PointRisk.LOW, title: 'Low' },
        { value: PointRisk.MEDIUM, title: 'Medium' },
        { value: PointRisk.HIGH, title: 'High' }
    ] as SelectorOption[]
}
