import SelectorOption from 'infrastructure/selector-option';
import PlanType from './plans/plan-type';

export enum CollectionType {
    Routine = 'Routine',
    Vector = 'Vector',
    CorrectiveAction = 'CorrectiveAction'
}

export const collectionTypes: Record<CollectionType, SelectorOption> = {
    Routine: { value: CollectionType.Routine, title: 'Routine' },
    Vector: { value: CollectionType.Vector, title: 'Vector' },
    CorrectiveAction: { value: CollectionType.CorrectiveAction, title: 'Corrective Action' }
};

export default CollectionType;

export function getCollectionTypeOptions(): SelectorOption[] {
    return [
        collectionTypes.Routine,
        collectionTypes.Vector,
        collectionTypes.CorrectiveAction
    ];
}

export function getScheduledCollectionTypeFilterOptions(): SelectorOption[] {
    return [
        collectionTypes.Routine
    ]
}

export function getAdhocCollectionTypeOptions(): SelectorOption[] {
    return getCollectionTypeOptions();
}

export function getDiscretionaryCollectionTypeOptions(): SelectorOption[] {
    return getCollectionTypeOptions();
}

export function getPriorityRiskBasedCollectionTypeOptions(): SelectorOption[] {
    return getCollectionTypeOptions();
}

export function getCapPlanCollectionTypeOptions(): SelectorOption[] {
    return [
        collectionTypes.Vector,
        collectionTypes.CorrectiveAction,
    ];
}

export function toCollectionTypeTitle(collectionType: CollectionType): string {
    return collectionType !== CollectionType.CorrectiveAction ? collectionType.toString() : 'Corrective Action';
}

export function planTypeToCollectionTypes(planType: PlanType): SelectorOption[] {
    return planType === PlanType.SCHEDULED
        ? getScheduledCollectionTypeFilterOptions()
        : getCollectionTypeOptions();
}

export function planTypeStringToCollectionTypes(planType: string): SelectorOption[] {
    return planTypeToCollectionTypes(PlanType[planType]);
}

export function planTypeIdToCollectionTypes(planTypeId: number): SelectorOption[] {
    const planType = PlanType[planTypeId];
    return planTypeStringToCollectionTypes(planType);
}

export function selectorOptionToCollectionType(selectorOption: SelectorOption): CollectionType {
    if (!Object.getOwnPropertyDescriptor(collectionTypes, selectorOption.value)) {
        throw new Error('The SelectorOption is not of type CollectionType.');
    }

    return collectionTypes[selectorOption.value];
}

export function collectionTypesToSelectorOptions(cts: CollectionType[]): SelectorOption[] {

    const selectorOptions: SelectorOption[] = [];
    if (cts) {
        if (!Array.isArray(cts)) {
            throw new Error('The collection types passed are not an array.');
        }
        cts.forEach((ct: CollectionType) => {
            selectorOptions.push(collectionTypes[ct]);
        });
    }

    return selectorOptions;
}
