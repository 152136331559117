import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import Logger from '../infrastructure/logger';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import PageContext from '../infrastructure/page-context';
import SecurityService from '../security/security-service';
import PasswordRequirementsService from './password-requirements-service';
import NumberUtility from '../infrastructure/number-utility';

@inject(Router, ValidationControllerFactory, Logger, DialogPresenter, PageContext, SecurityService, PasswordRequirementsService)
export class PasswordRequirements {
    constructor(router, validationControllerFactory, logger, dialogPresenter, pageContext, securityService, passwordRequirementsService) {
        this.router = router;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.logger = logger;
        this.logger.name = 'password-requirements';

        this.dialogPresenter = dialogPresenter;
        this.pageContext = pageContext;
        this.securityService = securityService;
        this.passwordRequirementsService = passwordRequirementsService;   

        this.passwordRequirements = {};
        this.canEditPasswordRequirements = this.securityService.hasPermission('EditPasswordRequirements') && !this.securityService.isImpersonating();
    }

    activate() {
        this.pageContext.isLoading = true;

        this.passwordRequirementsService.getRequirements()
            .then(result => {
                this.passwordRequirements = result.requirements;
                this.userTopLevelOrganization = result.parentOrganizationName;

                // TODO: Move to more general location for reuse
                ValidationRules.customRule(
                    'blankOrNonNegative16BitInteger',
                    value => {
                        return (Number.isInteger(value) && value >= 0 && value <= 32767) || (!value && !isNaN(value));
                    },
                    `Value must be blank, or a reasonable positive whole number.`
                );

                ValidationRules
                    .ensure('minPasswordLength').satisfiesRule('blankOrNonNegative16BitInteger')
                    .ensure('requireUpperCaseCount').satisfiesRule('blankOrNonNegative16BitInteger')
                    .ensure('requireNumberCount').satisfiesRule('blankOrNonNegative16BitInteger')
                    .ensure('requireSpecialCharacterCount').satisfiesRule('blankOrNonNegative16BitInteger')
                    .ensure('passwordExpirationDays').satisfiesRule('blankOrNonNegative16BitInteger')
                    .ensure('passwordReuseCount').satisfiesRule('blankOrNonNegative16BitInteger')
                    .ensure('maxDaysBetweenLogins').satisfiesRule('blankOrNonNegative16BitInteger')
                    .ensure('failedLoginAttemptLimit').satisfiesRule('blankOrNonNegative16BitInteger')
                    .on(this.passwordRequirements);

                this.pageContext.isLoading = false;
            })
            .catch(error => {
                this.logger.error('Error loading password requirements.', error);
                this.pageContext.isLoading = false;
            });
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('dashboard');
    }

    async savePasswordRequirements() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        try {
            var results = await this.passwordRequirementsService.saveRequirements(this.passwordRequirements);
            this.passwordRequirements.id = results.id;

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Your changes have been saved successfully.');
        } catch (error) {
            this.logger.error('Error saving password requirements.', error, { passwordRequirements: this.passwordRequirements });
            this.dialogPresenter.showAlert(
                'Error Saving Requirements',
                this.getApiErrorMessage(error.apiErrorCode));
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        switch (errorCode) {
            case 210:
                return 'The requirements to be saved do not match your organization. Please try again, or contact your system administrator.';
            case 211:
            default:
                return 'An error occurred while saving the current user. Please try again later.';
        }
    }
}
