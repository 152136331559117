import { autoinject, BindingEngine, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { validateTrigger, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import LabService from 'labs/lab-service';
import OrganizationService from 'organizations/organization-service';
import RequestService from 'requests/request-service';
import SecurityService from 'security/security-service';
import operationsTimingOptions from '../operations-timing-options';
import TaskService from '../tasks/task-service';
import SampleDescriptionBuilder from './sample-description-builder';
import SampleDescriptionField from './sample-description-field';
import { testMethodNameFormatting } from 'test-methods/test-method-helper';

@autoinject
export default class RequestDetail {
    validationController: any;

    // Permissions
    canViewRequests: boolean;
    canEditRequests: boolean;

    // Data
    tasks: any[];
    request: any;
    labs: any;
    organization: any;
    organizationDefaultLabCodes: any;

    // UI
    enableCompositeButton: boolean;
    formChanged: boolean;
    maxSamplesPerComposite: any;
    selectedSamples = [];
    samplesMissingTests: any;

    // Description Builder
    private _desriptionBuilderLocalStorageKey = 'emma-request.description-builder-fields';
    sampleDescriptionFields: SampleDescriptionField[];
    selectedSampleDescriptionFields: SampleDescriptionField[];
    selectedSampleDescriptionFieldsCollectionChangedSubscription: any;
    @observable defaultDescription: boolean;
    defaultSampleDescriptionFields = SampleDescriptionBuilder.getFields(
        'collectedDateTime',
        'pointName',
        'planName',
        'id',
    );

    constructor(
        private router: Router,
        private bindingEngine: BindingEngine,
        private validationControllerFactory: ValidationControllerFactory,
        private pageContext: PageContext,
        private logger: Logger,
        private dialogPresenter: DialogPresenter,
        private organizationService: OrganizationService,
        private labService: LabService,
        private taskService: TaskService,
        private requestService: RequestService,
        private securityService: SecurityService,
    ) {
        this.bindingEngine = bindingEngine;
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.logger.name = 'location-testing-request-detail';

        this.canViewRequests = this.securityService.hasPermission('ViewRequests');
        this.canEditRequests =
            this.securityService.hasPermission('EditRequests') &&
            !this.securityService.isImpersonating();

        this.toggleSampleCombinationButtons();

        this.defaultDescription = true;
        this.sampleDescriptionFields = SampleDescriptionBuilder.fields;
        this.selectedSampleDescriptionFields = [];

        this.selectedSampleDescriptionFieldsCollectionChangedSubscription = this.bindingEngine
            .collectionObserver(this.selectedSampleDescriptionFields)
            .subscribe(this.selectedSampleDescriptionFieldsCollectionChanged.bind(this));
    }

    updateDescriptions() {
        this.request.samples.forEach(
            (s: any) => (s.description = this.generateDescription(s.parts)),
        );
        this.validationController.validate();
    }

    defaultDescriptionChanged(newValue: boolean, oldValue: boolean) {
        if (oldValue === undefined || newValue === oldValue) {
            return;
        }

        this.selectedSampleDescriptionFields.length = 0;
        if (!this.defaultDescription) {
            const savedFieldsString = localStorage.getItem(this._desriptionBuilderLocalStorageKey);
            if (savedFieldsString) {
                const savedFields = JSON.parse(savedFieldsString) as SampleDescriptionField[];
                const selectedSavedFields = this.sampleDescriptionFields.filter((f) => {
                    return savedFields.find((s) => s.name == f.name);
                });
                this.selectedSampleDescriptionFields.push(...selectedSavedFields);
            }
        }

        this.updateDescriptions();
    }

    selectedSampleDescriptionFieldsCollectionChanged() {
        localStorage.setItem(
            this._desriptionBuilderLocalStorageKey,
            JSON.stringify(this.selectedSampleDescriptionFields),
        );
        this.updateDescriptions();
    }

    isSelectedChanged(sample, isSelected) {
        sample.isSelected = isSelected;

        var index = this.selectedSamples.indexOf(sample);
        if (sample.isSelected && index === -1) this.selectedSamples.push(sample);
        else if (!sample.isSelected && index !== -1) this.selectedSamples.splice(index, 1);

        this.setAllCanCombineWithSelected();
        this.toggleSampleCombinationButtons();
    }

    clearSelectedSamples() {
        for (let sample of this.selectedSamples) sample.isSelected = false;

        this.selectedSamples.splice(0);
        this.setAllCanCombineWithSelected();
        this.toggleSampleCombinationButtons();
    }

    setAllCanCombineWithSelected() {
        for (let sample of this.request.samples) {
            if (sample.isSelected) continue;

            sample.canCombineWithSelected = this.checkCanCombineWithSelected(sample);
        }
    }

    toggleSampleCombinationButtons() {
        this.enableCompositeButton = this.selectedSamples.length > 1;
    }

    checkSampleHasQuantitativeTest(sample) {
        return sample.tests.some(
            (t) => t.testMethodCategory === 'MQN' || t.testMethodCategory === 'CQN',
        );
    }

    checkCanCombineWithSelected(sample) {
        if (this.checkSampleHasQuantitativeTest(sample)) return false;

        if (!this.selectedSamples.length) return true;

        var firstSelectedSample = this.selectedSamples[0];

        if (sample.parts[0].planId !== firstSelectedSample.parts[0].planId) return false;

        return true;
    }

    autoCreateComposites() {
        var groupedSamples = this.request.samples
            .filter((s) => !s.isComposite)
            .reduce((groupedSamples, s) => {
                var key = `${s.parts[0].planId}|${s.parts[0].pointZone}`;
                (groupedSamples[key] = groupedSamples[key] || []).push(s);

                return groupedSamples;
            }, {});

        for (let [_, samples] of Object.entries(groupedSamples) as [any, any[]]) {
            while (samples.length) {
                let compositeSamples = samples.splice(0, this.maxSamplesPerComposite);
                if (compositeSamples.length < 2) continue;

                this.compositeSamples(compositeSamples);
            }
        }
    }

    createCompositeSample(samples) {
        var sampleParts = samples.reduce(
            (sampleParts, sample) => [...sampleParts, ...sample.parts],
            [],
        );

        return {
            canCombineWithSelected: true,
            isSelected: false,
            description: this.generateDescription(sampleParts),
            isComposite: true,
            parts: sampleParts,
            tests: [...samples[0].tests],
        };
    }

    // TODO: Here
    compositeSamples(samples) {
        for (let i = samples.length - 1; i >= 0; i--) {
            let sampleIndex = this.request.samples.findIndex((s) => s === samples[i]);
            if (sampleIndex === -1) continue;

            let removedSample = this.request.samples.splice(sampleIndex, 1)[0];
            this.removeSampleValidation(removedSample);
        }

        let compositeSample = this.createCompositeSample(samples);
        this.addSampleValidation(compositeSample);
        this.request.samples.push(compositeSample);
    }

    compositeSelectedSamples() {
        this.compositeSamples(this.selectedSamples);
        this.clearSelectedSamples();
    }

    // TODO: Here
    uncomposite(samplePart, sample) {
        var index = sample.parts.indexOf(samplePart);
        sample.parts.splice(index, 1);
        sample.description = this.generateDescription(sample.parts);
        sample.isComposite = sample.parts.length > 1;

        let uncompositedSampleIndex = this.request.samples.indexOf(sample) + 1;
        let uncompositedSample = {
            canCombineWithSelected: true,
            isSelected: false,
            description: this.generateDescription([samplePart]),
            isComposite: false,
            parts: [samplePart],
            tests: [...sample.tests],
        };

        this.addSampleValidation(uncompositedSample);
        this.request.samples.splice(uncompositedSampleIndex, 0, uncompositedSample);

        this.setAllCanCombineWithSelected();
        this.toggleSampleCombinationButtons();
    }

    generateDescription(sampleParts: any[]) {
        let tasks = sampleParts.map((p) => this.tasks.find((t) => t.id == p.taskId));
        let sampleDescriptionFields = this.defaultDescription
            ? this.defaultSampleDescriptionFields
            : this.selectedSampleDescriptionFields;

        return SampleDescriptionBuilder.build(tasks, sampleDescriptionFields);
    }

    getOperationsTimingCaption(operationsTiming) {
        return operationsTimingOptions.find((o) => o.value === operationsTiming).title;
    }

    activate(params) {
        // eslint-disable-next-line sonarjs/cognitive-complexity
        (async () => {
            this.pageContext.isLoading = true;
            try {
                const [organization, labs, organizationDefaultLabCodes] = await Promise.all([
                    this.organizationService.getOrganization(params.organizationId),
                    this.labService.getLabs(),
                    this.organizationService.getConfiguration('defaultLabCode'),
                ]);
                this.organization = organization;
                this.organizationDefaultLabCodes = organizationDefaultLabCodes;
                this.request = {
                    organizationId: this.organization.id,
                };

                if (params.taskIds) {
                    this.tasks = (await this.taskService.getTasks(
                        { ids: params.taskIds },
                        null,
                        true,
                    )) as unknown as any[];

                    // Coerce collected date time into JS date from string.
                    for (let task of this.tasks)
                        task.collectedDateTime = new Date(
                            task.collectedDateTime.toLowerCase().endsWith('z')
                                ? task.collectedDateTime
                                : `${task.collectedDateTime}Z`,
                        );

                    // Sort tasks by zone then by plan name and then by point name.
                    this.tasks.sort((a, b) => {
                        var result = a.pointZone - b.pointZone;
                        if (result) return result;

                        result = (a.plan?.name ?? '').localeCompare(b.plan?.name ?? '');
                        if (result) return result;

                        return a.pointName.localeCompare(b.pointName);
                    });

                    // Store test method external sources encountered when setting up samples for the puropose of filtering labs and validation.
                    var testMethodExternalSources = [];

                    this.request.samples = this.tasks.map((t) => {
                        let parts = [
                            {
                                // Keys
                                taskId: t.id,
                                pointId: t.pointId,

                                // Other fields
                                pointName: t.pointName,
                                pointRoom: t.pointRoom,
                                pointZone: t.pointZone,
                                planId: t.plan?.id,
                                planName: t.plan?.name,
                                taskCollectedDateTime: t.collectedDateTime,
                                taskAssignedUserFullName: t.assignedUserFullName,
                                taskOperationsTimingCaption: this.getOperationsTimingCaption(
                                    t.operationsTiming,
                                ),
                                taskCollectionType: t.collectionType,
                            },
                        ];

                        let testMethods =
                            t.testMethods && t.testMethods.length !== 0
                                ? t.testMethods
                                : t.plan?.testMethods;

                        // Push external source into list to validate below.
                        testMethodExternalSources.push(
                            ...testMethods.map((tm) => tm.externalSource),
                        );

                        return {
                            canCombineWithSelected: true,
                            isSelected: false,
                            description: this.generateDescription(parts),
                            isComposite: false,
                            parts,
                            tests: (testMethods ?? []).map((tm) => ({
                                testMethodName: testMethodNameFormatting(
                                    tm,
                                    this.securityService.isImpersonating(),
                                    this.securityService.isWorkingUnderOrganization(),
                                ),
                                testMethodId: tm.id,
                                testMethodCategory: tm.category,
                            })),
                        };
                    });

                    for (let sample of this.request.samples) this.addSampleValidation(sample);

                    // Ensure only a single test method external source is present.
                    if (Array.from(new Set(testMethodExternalSources)).length > 1)
                        throw new Error(
                            'Tasks with test methods from more than one external source are present.',
                        );

                    // Filter labs based on external source of the test methods.
                    this.labs = labs
                        .filter((l) => l.externalSource === testMethodExternalSources[0])
                        .map((lab) => {
                            lab.caption = lab.displayName || `${lab.city}, ${lab.state}`;
                            return lab;
                        })
                        .sort(function (a, b) {
                            const captionA = a.caption.toUpperCase();
                            const captionB = b.caption.toUpperCase();
                            return captionA.localeCompare(captionB);
                        });
                } else {
                    this.request.samples = [];
                }

                ValidationRules.ensure('labId')
                    .required()
                    .ensure('sampledBy')
                    .maxLength(40)
                    .withMessage('Sampled By cannot be longer than 40 characters.')
                    .ensure('poNumber')
                    .maxLength(40)
                    .withMessage('PO Number cannot be longer than 40 characters.')
                    .on(this.request);

                this.samplesMissingTests = this.request.samples.some((s) => s.tests.length === 0);
                this.setAllCanCombineWithSelected();

                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error loading request.', error, { id: params.id });
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Request',
                    'An error occurred while loading the request. Please try again later.',
                );
            }
        })();
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.router.navigateToRoute('dashboard');
    }

    async submit() {
        const aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid) {
            this.pageContext.showErrorOverlay('Please fix validation errors prior to submission.');
            return;
        }

        var confirmed = await this.dialogPresenter.showConfirmation(
            'Submit Request',
            'Are you sure you want to submit this request?',
        );
        if (!confirmed) return;

        this.pageContext.isLoading = true;

        try {
            this.formChanged = false;
            this.request.id = (await this.requestService.createRequest(this.request)).id;
            this.pageContext.showSuccessOverlay('Request submitted successfully.');
            this.router.navigateToRoute(
                'request-detail',
                { id: this.request.id },
                { replace: true },
            );
        } catch (error) {
            this.logger.error('Error saving request.', error, { request: this.request });
            this.dialogPresenter.showAlert(
                'Error Submitting Request',
                this.getApiErrorMessage(error.apiErrorCode),
            );
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        return 'An error occurred while submitting the current request. Please try again later.';
    }

    addSampleValidation(sample) {
        ValidationRules.ensure('description').maxLength(265).on(sample);

        this.validationController.addObject(sample);
    }

    removeSampleValidation(sample) {
        ValidationRules.off(sample);
        this.validationController.removeObject(sample);
    }

    deactivate() {
        this.selectedSampleDescriptionFieldsCollectionChangedSubscription?.dispose();
    }
}
