import { bindable, inject } from 'aurelia-framework';

@inject(Element)
export class NotifyFormChangesCustomAttribute {
    @bindable disabled;
    @bindable enableSelect2;
    @bindable enableDatepicker;

    // Enables listening for custom events with optional element to listen on (if the element is not a child of this component).
    @bindable customEvents; // type: Array<string | { element: string, event: string }>

    constructor(element) {
        this.element = element;
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    attached() {
        this.element.addEventListener('input', this.handleFormChange);
        this.element.addEventListener('change', this.handleFormChange);

        if (this.enableDatepicker) {
            this.element.addEventListener('datepickerchanged', this.handleFormChange);
            this.datepickerAdded = true;
        }

        if (this.enableSelect2) {
            this.element.addEventListener('select2change', this.handleFormChange);
            this.select2Added = true;
        }

        if (this.customEvents && this.customEvents.length)
            for (let customEvent of this.customEvents) {
                if (typeof customEvent === 'string')
                    this.element.addEventListener(customEvent, this.handleFormChange);
                else
                    customEvent.element.addEventListener(customEvent.event, this.handleFormChange);
            }
    }

    detached() {
        this.element.removeEventListener('input', this.handleFormChange);
        this.element.removeEventListener('change', this.handleFormChange);

        if (this.datepickerAdded)
            this.element.removeEventListener('datepickerchanged', this.handleFormChange);

        if (this.select2Added) 
            this.element.removeEventListener('select2change', this.handleFormChange);

        if (this.customEvents && this.customEvents.length)
            for (let customEvent of this.customEvents) {
                if (typeof customEvent === 'string')
                    this.element.removeEventListener(customEvent, this.handleFormChange);
                else
                    customEvent.element.removeEventListener(customEvent.event, this.handleFormChange);
            }
    }

    handleFormChange($event) {
        if ($event.srcElement.classList.contains('select2-search__field'))
            return;

        if (this.disabled)
            return;

        this.element.dispatchEvent(new CustomEvent('formchange', { bubbles: true, detail: { formElement: $event.srcElement } }));
    }
}
