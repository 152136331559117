import { bindable, children, inject } from 'aurelia-framework';
import BooleanUtility from 'infrastructure/boolean-utility';
import $ from 'jquery';

@inject(Element)
export class Accordion {
    @children('accordion-item') items;
    @bindable multiExpand;

    $accordionElement;
    $accordion;

    constructor(element) {
        this.element = element;
    }

    attached() {
        this.$accordionElement = $(this.element.querySelector('ul'));
        this.$accordion = new Foundation.Accordion(this.$accordionElement, {
            multiExpand: this.multiExpand ? BooleanUtility.parsePrimitive(this.multiExpand) : false
        });
    }

    detached() {
        if (this.$accordion) {
            this.$accordion.destroy();
            this.$accordion = undefined;
            this.$accordionElement = undefined;
        }
    }

    expandAllItems() {
        if (!this.$accordionElement)
            return;

        // open all panels
        let accordion = this.$accordionElement;
        accordion.foundation('down', $(this.element.querySelectorAll('.accordion-content')));
    }

    closeAllItems() {
        if (!this.$accordionElement)
            return;

        // close all panels
        let accordion = this.$accordionElement;
        accordion.foundation('up', $(this.element.querySelectorAll('.accordion-content')));
    }
}
