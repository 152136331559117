import { BaseQueryFilter } from './base-query-filter';
import { QueryFiltered } from './query-filtered';

export interface PagedListQuery {
    pageSize: number;
    pageNumber: number;
    sortBy: string;
    sortDirection: string;
}

/* eslint-disable brace-style */
export class PagedListQueryFilters
    extends BaseQueryFilter
    implements PagedListQuery, QueryFiltered<PagedListQuery>
{
    pageSize: number;
    pageNumber: number;
    sortBy: string;
    sortDirection: string;

    constructor() {
        super();
        this.pageSize = 100;
        this.pageNumber = 1;
        this.sortBy = '';
        this.sortDirection = '';
    }

    public get count(): number {
        return 0;
    }

    reset(): void {
        this.pageSize = 100;
        this.pageNumber = 1;
        this.sortBy = '';
        this.sortDirection = '';
    }

    getQueryParams(): PagedListQuery {
        return {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
            sortBy: this.sortBy,
            sortDirection: this.sortDirection,
        };
    }

    setFilterValues(params: PagedListQuery): void {
        super.setQueryFilteredValues(params, this);
    }
}
