import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import moment from 'moment';
import 'moment-timezone';
import { StatusQuery } from '../status/status-query-filters';
import { RequestStatusCount } from './request-status-count';

@autoinject
export default class RequestService {
    ianaTimeZone: string;

    constructor(
        private httpClient: HttpClient) {
        this.httpClient = httpClient;
        this.ianaTimeZone = (moment as any).tz.guess();
    }

    getRequestStatusCounts(statusQuery: StatusQuery): Promise<RequestStatusCount[]> {
        const query = buildQueryString(statusQuery, true);

        return this.httpClient.get(`requests/all/status-counts/${(query ? `?${query}` : '')}`) as unknown as Promise<RequestStatusCount[]>;
    }

    getRequest(id) {
        return this.httpClient.get(`requests/${id}`);
    }

    //TODO: define Request interface
    getRequests(filters): Promise<any[]> {
        var queryParameters = filters;
        var query = buildQueryString(queryParameters, true);

        return this.httpClient.get(`requests${(query ? `?${query}` : '')}`) as unknown as Promise<any[]>;
    }

    createRequest(request: any): Promise<any> {
        return this.httpClient.post('requests', request) as unknown as Promise<any>;
    }

    createRequests(draftRequestTemplateIds) {
        let requests = draftRequestTemplateIds.map(id => ({ requestTemplateId: id }));

        return this.httpClient.post('requests/bulk', requests);
    }

    getExportUrl(requestIds, format) {
        var query = buildQueryString({ ids: requestIds }, true);
        return `api/requests/export?${query}&format=${format}`;
    }

    getSampleLabelPdfUrl(id, labelTemplateId) {
        var query = buildQueryString({
            labelTemplateId,
            ianaTimeZone: this.ianaTimeZone
        }, true);
        return `api/requests/${id}/sample-labels${(query ? `?${query}` : '')}`;
    }
}
