import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import SecurityService from '../security/security-service';

@autoinject
export class Privacy {
    isUserLoggedIn: boolean;

    constructor(
        private router: Router,
        securityService: SecurityService
    ) {
        this.isUserLoggedIn = securityService.isCurrentUserLoggedIn();
    }

    goBack() {
        this.router.navigateBack();
    }
}
