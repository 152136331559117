import { inject } from 'aurelia-framework';
import DialogPresenter from './dialogs/dialog-presenter';

@inject(DialogPresenter)
export default class FormChangeCheckStep {
    constructor(dialogPresenter) {
        this.dialogPresenter = dialogPresenter;
    }

    run(navigationInstruction, next) {
        if (navigationInstruction &&
            navigationInstruction.plan &&
            navigationInstruction.plan.default &&
            navigationInstruction.plan.default.prevComponent &&
            navigationInstruction.plan.default.prevComponent.viewModel &&
            navigationInstruction.plan.default.prevComponent.viewModel.formChanged
        ) {
            return this.dialogPresenter
                .showLoseChangesConfirmation()
                .then(confirmed => {
                    if (confirmed)
                        return next();
                    else
                        return next.cancel();
                });
        } else {
            return next();
        }
    }
}
