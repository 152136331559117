import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import PageContext from '../../infrastructure/page-context';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import SupportTopicService from './support-topic-service';

@inject(Router, ValidationControllerFactory, PageContext, DialogPresenter, Logger, SupportTopicService)
export default class SupportTopicDetail {
    constructor(router, validationControllerFactory, pageContext, dialogPresenter, logger, supportTopicService) {
        this.router = router;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;

        this.logger = logger;
        this.logger.name = 'support-topic-detail';

        this.supportTopicService = supportTopicService;
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('support-topic-list');
    }

    async save() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        try {
            this.topic.id = (await this.supportTopicService.saveSupportTopic(this.topic)).id;

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Support topic saved successfully.');
            this.router.navigateToRoute('support-topic-detail', { id: this.topic.id }, { replace: true });
        }
        catch (error) {
            this.logger.error('Error saving topic.', error, { topic: this.topic });
            this.dialogPresenter.showAlert(
                'Error Saving Support Topic',
                this.getApiErrorMessage(error.apiErrorCode));
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        switch (errorCode) {
            case 500:
                return 'The topic name specified is already in use.';
            default:
                return 'An error occurred while saving the current support topic. Please try again later.';
        }
    }

    activate(params) {
        (async () => {
            try {
                this.pageContext.isLoading = true;

                this.topic = { isActive: true };
                if (params.id !== 'create')
                    this.topic = await this.supportTopicService.getSupportTopic(params.id);

                ValidationRules
                    .ensure('name')
                        .displayName('Name')
                        .required()
                        .maxLength(100)
                    .ensure('description')
                        .displayName('Description')
                        .required()
                        .maxLength(255)
                    .ensure('email')
                        .displayName('Recipient Email')
                        .required()
                        .email()
                        .maxLength(512)
                    .on(this.topic);
                this.validationController.addObject(this.topic);
            }
            catch (error) {
                this.logger.error('Error loading support topic', error, { supportTopicId: params.id });

                var errorAlertPromise = error.apiErrorCode === 1 ?
                    this.dialogPresenter.showAlert(
                        'Error Loading Support Topic',
                        'The current topic doesn\'t exist.') :
                    this.dialogPresenter.showAlert(
                        'Error Loading Support Topic',
                        'An error occurred while loading the current support topic. Please try again later.');

                errorAlertPromise.then(() => {
                    this.router.navigateToRoute('support-topic-list');
                });
            }

            this.pageContext.isLoading = false;
        })();
    }
};
