import { autoinject, observable } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import moment from 'moment';

@autoinject
export class ReportParameter {
    @observable value;
    @observable values;

    data: any;

    activate(model) {
        this.data = model;

        this.setValidValues();
        this.setDefaults();

        var rules;

        if (model.type.toLowerCase() === 'integer')
            rules = ValidationRules.ensure('value').matches(/^[[0-9]*$/);
        if (model.type.toLowerCase() === 'float')
            rules = (rules || ValidationRules).ensure('value').matches(/^[[0-9.]*$/);
        
        if (this.data.nullable)
            return;

        (rules || ValidationRules)
            .ensure('values').satisfies(values => !!values.length)
            .ensure('value').required()
            .on(this);
    }

    setValidValues() {
        if (this.data.type.toLowerCase() !== 'boolean')
            return;

        if (this.data.validValues)
            return;

        this.data.validValues = [{ label: 'True', value: true }, { label: 'False', value: false }];

        if (!this.data.nullable)
            return;

        this.data.validValues.push({ label: '(Null)', value: '' });
    }

    setDefaults() {
        if (!this.data.defaultValues || !this.data.defaultValues.length)
            return;

        if (this.data.nullable && this.data.defaultValues[0] === '(Null)') {
            this.value = '';
            return;
        }

        var defaultValues = [];
        for (var defaultValue of this.data.defaultValues) {
            defaultValues.push({ value: defaultValue });
        }

        this.value = defaultValues[0].value;
        this.values = defaultValues;
    }

    valueChanged() {
        if (this.data.type === 'DateTime')
            this.data.values = [this.value && moment(this.value).startOf('day').format('M/D/YYYY')];
        else
            this.data.values = [this.value];
    }

    valuesChanged() {
        this.data.values = this.values.map(v => v.value);
    }
}
