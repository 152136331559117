import { autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

interface ExportableFieldSelectorModel {
    title: string;
    fields: string[];
    selected: string[];
}

interface ExportableField {
    value: string;
    label: string;
}

@autoinject
export default class ExportableFieldSelector {
    model: ExportableFieldSelectorModel;
    isLoading: boolean;
    
    exportableFields: ExportableField[];
    selectedFields: string[];

    constructor(
        private controller: DialogController
    ) {

        this.isLoading = true;
    }

    async activate(model: ExportableFieldSelectorModel) {
        this.selectedFields = [];
        this.model = model;

        let exportableFieldKeys = Object.keys(this.model.fields);

        this.exportableFields = [];
        exportableFieldKeys.forEach(k => {
            let field = { 
                value: k,
                label: this.model.fields[k]
            } as ExportableField;

            this.exportableFields.push(field);

            if ((!this.model.selected) || (this.model.selected && this.model.selected.findIndex(f => f === k) !== -1))
                this.selectedFields.push(k);
        });

        this.isLoading = false;
    }
}
