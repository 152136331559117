import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import Logger from '../infrastructure/logger';
import PageContext from '../infrastructure/page-context';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import SecurityService from '../security/security-service';
import UserService from '../users/user-service';
import RoleService from './role-service';

@inject(Router, Logger, PageContext, DialogPresenter, ValidationControllerFactory, RoleService, UserService, SecurityService)
export class RoleMoveUsers {
    constructor(router, logger, pageContext, dialogPresenter, validationControllerFactory, roleService, userService, securityService) {
        this.router = router;

        this.logger = logger;
        this.logger.name = 'role-move-users';

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.roleService = roleService;
        this.userService = userService;
        this.securityService = securityService;
        this.roleId = 0;
        this.role = null;
        this.roles = [];
        this.users = [];
        this.selectedRole = null;
        this.searchFilter = '';
        this.sortParam = { active: 'firstName', direction: 'asc' };

        this.allSelected = false;
        this.selectedUsers = [];
    }

    activate(params) {
        this.pageContext.isLoading = true;
        this.roleId = parseInt(params.id);

        Promise
            .all([
                this.roleService.getRole(this.roleId),
                this.roleService.getRoles(),
                this.roleService.getUsersInRole(this.roleId)
            ])
            .then(results => {
                this.role = results[0];
                this.roles = results[1].filter(r => r.id !== this.role.id);
                this.users = results[2];

                for (var i = 0; i < this.users.length; i++) 
                    this.users[i].fullName = this.users[i].firstName + ' ' + this.users[i].lastName;

                this.pageContext.isLoading = false;
            })
            .catch(error => {
                this.logger.error('Error loading data for role-move-users.', error, { roleId: this.roleId });
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Roles',
                    'An error occurred while loading the user roles. Please try again later.');
            });
    }

    cancelEdit() {
        this.router.navigateToRoute('role-list');
    }

    async saveUsers() {
        try {
            var userRoles = this
                .users
                .filter(u => u.roleId !== this.roleId)
                .reduce((userRoles, user) => {
                    userRoles[user.id] = user.roleId;
                    return userRoles;
                }, {});

            if (Object.keys(userRoles).length === 0)
                return;

            await this.userService.bulkUpdateUsersRole(userRoles);

            this.users = this.users.filter(u => u.roleId === this.roleId);
            if (!this.users.length) {
                this.router.navigateToRoute('role-list');
                this.pageContext.showSuccessOverlay('User roles saved successfully. You may now delete the original role.', false);
            } else {
                this.updateSelections();
                this.pageContext.showSuccessOverlay('Users with new roles saved successfully. Please ensure you assign the rest of the users to another role.', false);
            }
        } catch (error) {
            this.dialogPresenter.showAlert(
                'Error Saving User Roles',
                'An error occurred while saving the user roles. Please try again later.');
        }
    }

    setRoleOnSelectedUsers() {
        for (let user of this.selectedUsers)
            user.roleId = this.selectedRole.id;
    }

    setSelectedUsers() {
        this.selectedUsers = this.users.filter(user => user.isSelected);
    }

    updateSelections() {
        this.allSelected = this.users.every(user => user.isSelected);
        this.setSelectedUsers();
    }

    isSelectedChanged() {
        this.updateSelections();
    }

    allSelectedClicked() {
        if (this.users) {
            this.allSelected = !this.users.every(user => user.isSelected);

            for (let user of this.users)
                user.isSelected = this.allSelected;
        }

        this.setSelectedUsers();

        return true;
    }

    toggleSort(key) {
        this.sortParam.active = key;
        this.sortParam.direction = this.sortParam.direction === 'asc' ? 'desc' : 'asc';
    }
}
