import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { BindingEngine, inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import SelectorOption from 'infrastructure/selector-option';
import CollectionType from 'location-testing/collection-type';
import { getTestMethodGroups } from 'location-testing/test-method-group';
import RecordStatus, { getRecordStatusOptions } from 'record-status';
import RemediationRootCause from 'remediation-root-causes/remediation-root-cause';
import RemediationRootCauseService from 'remediation-root-causes/remediation-root-cause-service';
import svgPanZoom from 'svg-pan-zoom';
import RequestService from 'tests/test-service';
import CompareUtility from '../../infrastructure/compare-utility';
import debounce from '../../infrastructure/debounce';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import SecurityService from '../../security/security-service';
import PlanService from '../plans/plan-service';
import PointTypeService from '../point-types/point-type-service';
import Point from '../points/point';
import PointService from '../points/point-service';
import RemediationService from '../remediations/remediation-service';
import { MapDashboardQuery, MapDashboardQueryFilters } from './map-dashboard-query-filters';
import MapDashboardSelectionMode from './map-dashboard-selection-mode';
import MapDashboardViewOption from './map-dashboard-view-option';
import MapService from './map-service';
import { createPlaybackFrames } from './playback/playback-utility';
import { pointTestGrid } from './point-test-grid';

@inject(
    Router,
    EventAggregator,
    Logger,
    PageContext,
    DialogPresenter,
    SecurityService,
    MapService,
    PointService,
    PointTypeService,
    PlanService,
    RemediationService,
    BindingEngine,
    RequestService,
    RemediationRootCauseService,
)
export class MapDashboard {
    @observable pointFilterText;
    @observable view;
    @observable selectedViewOption: MapDashboardViewOption;
    @observable showLatestView: boolean;
    @observable viewPointSize;

    applyFiltersSubscription: any;
    clearFiltersSubscription: any;
    loadPromise: any;

    // elements
    panZoom: any;
    mapContainer: any;
    pan: any;
    pointDataDropdownViewModel: any;
    mapSvgElement: SVGSVGElement;

    draggingKeyPoint: Point;
    draggingKeyStartingPoint: any;
    draggingPointsMoved: boolean;
    selectionRubberband: any;
    pointMouseDownStartingPoint: any;
    innerPointViewSize: number;

    // configuration
    scale: number;

    sidebarView: string;
    currentSidebarDetailView: string;
    pointsView: any;

    panZoomSettings: any;
    pointTestsGridOptions: any;
    pointPlansGridOptions: any;
    pointRemediationsGridOptions: any;
    pointSelectionMode: MapDashboardSelectionMode;

    // permissions
    canViewResults: boolean;
    canViewPlans: boolean;
    canViewAllRedmediations: boolean;
    canEditRemediations: boolean;
    canViewAllTasks: boolean;
    canEditTasks: boolean;

    // state
    mapId: number;
    allFailedPointTestsSelected: boolean;
    @observable showFailedOnly: boolean;

    sidebarDetailViews: any[];

    selectedFailedPointTests: any[];
    selectedPoint: any;
    recordStatusOptions: SelectorOption[];
    confirmationTestValueOptions: SelectorOption[];
    confirmationTestValueOptionsSubscription: Subscription;

    // state - playback
    playbackFrames: any[];
    currentPlaybackFrame: number;
    isPlaying: boolean;
    playInterval: any;

    // state - point data
    pointTests: any[];
    pointPlans: any[];
    pointRemediations: any[];
    failedPointTests: any[];
    pointHasOverdueTasks: boolean;
    pointTestMetrics: any;

    //Filters
    mapDashboardQueryFilters: MapDashboardQueryFilters;
    pointRoomOptions: SelectorOption[];
    pointZoneOptions: SelectorOption[];
    pointTypeOptions: SelectorOption[];
    testMethodOptions: SelectorOption[];
    taskAssigneeOptions: SelectorOption[];
    planOptions: SelectorOption[];
    remediationRootCauseOptions: SelectorOption[];
    testMethodGroupOptions: SelectorOption[];

    results: any;
    map: any;
    points: Point[];
    roomName: any;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private mapService: MapService,
        private pointService: PointService,
        private pointTypeService: PointTypeService,
        private planService: PlanService,
        private remediationService: RemediationService,
        private readonly bindingEngine: BindingEngine,
        private readonly testService: RequestService,
        private readonly remediationRootCauseService: RemediationRootCauseService,
    ) {
        this.router = router;
        this.eventAggregator = eventAggregator;

        this.logger = logger;
        this.logger.name = 'map-dashboard';

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.securityService = securityService;
        this.mapService = mapService;
        this.pointService = pointService;
        this.pointTypeService = pointTypeService;
        this.planService = planService;
        this.remediationService = remediationService;
        this.remediationRootCauseService = remediationRootCauseService;
        this.testMethodGroupOptions = getTestMethodGroups();

        this.scale = 1;
        this.pointZoneOptions = [
            { value: 1, title: 'Zone 1' },
            { value: 2, title: 'Zone 2' },
            { value: 3, title: 'Zone 3' },
            { value: 4, title: 'Zone 4' },
        ];

        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);

        // Initialize dashboard settings.
        var defaultSettings = {
            showLatestView: true,
            viewPointSize: 5,
        };
        var settings = Object.assign(defaultSettings, this.mapService.getDashboardSettings());
        this.panZoomSettings = settings.panZoom || {};
        this.showLatestView = settings.showLatestView;
        this.viewPointSize = settings.viewPointSize;
        this.innerPointViewSize = this.viewPointSize - 1;

        this.pointFilterText = '';
        this.showFailedOnly = false;

        // Debounce the saving of pan zoom settings.
        this.savePanZoomSettings = debounce(this.savePanZoomSettings.bind(this), 500);

        var rowHeight = 45;

        this.selectedFailedPointTests = [];
        this.allFailedPointTestsSelected = false;
        this.pointTestsGridOptions = pointTestGrid;

        this.pointPlansGridOptions = {
            rowHeight,
            columnDefs: [
                {
                    suppressMenu: true,
                    headerName: 'Name',
                    field: 'name',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                    sort: 'asc',
                    template:
                        '<a route-href="route: plan-detail; params.bind: { id: data.id }">${data.name}</a>',
                },
                {
                    suppressMenu: true,
                    headerName: 'Type',
                    field: 'type',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                },
                {
                    suppressMenu: true,
                    headerName: 'Remediation',
                    field: 'isRemediationPlan',
                    template: '<i class="fa fa-check" if.bind="data.isRemediationPlan"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.pointRemediationsGridOptions = {
            rowHeight,
            columnDefs: [
                {
                    suppressMenu: true,
                    headerName: 'Name',
                    field: 'name',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                    sort: 'asc',
                    template:
                        '<a route-href="route: remediation-detail; params.bind: { id: data.id }">${data.name}</a>',
                },
                {
                    suppressMenu: true,
                    headerName: 'Assigned User',
                    field: 'assignedUserFullName',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                },
                {
                    suppressMenu: true,
                    headerName: 'Approval User',
                    field: 'approvalUserFullName',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                },
                {
                    suppressMenu: true,
                    headerName: 'Status',
                    field: 'status',
                    template: '${getRemediationStatusCaption(data.status)}',
                    width: 150,
                },
                {
                    suppressMenu: true,
                    headerName: 'Root Cause',
                    field: 'remediationRootCauseName',
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.canViewResults = this.securityService.hasPermission('ViewResults');
        this.canViewPlans = this.securityService.hasPermission('ViewPlans');
        this.canViewAllRedmediations = this.securityService.hasPermission('ViewAllRemediations');
        this.canEditRemediations =
            this.securityService.hasPermission('EditRemediations') &&
            !this.securityService.isImpersonating();
        this.canViewAllTasks = this.securityService.hasPermission('ViewAllTasks');
        this.canEditTasks =
            this.securityService.hasPermission('EditTasks') &&
            !this.securityService.isImpersonating();

        this.view = null;
        this.pointSelectionMode = MapDashboardSelectionMode.SINGLE;
        this.selectedViewOption = MapDashboardViewOption.ALL;

        this.sidebarView = 'list';
        this.sidebarDetailViews = [];

        if (this.canViewResults) this.sidebarDetailViews.push({ name: 'tests', title: 'Tests' });

        if (this.canViewPlans) this.sidebarDetailViews.push({ name: 'plans', title: 'Plans' });

        if (this.canViewAllRedmediations)
            this.sidebarDetailViews.push({
                name: 'remediations',
                title: 'Remediations',
            });

        if (this.sidebarDetailViews.length)
            this.currentSidebarDetailView = this.sidebarDetailViews[0].name;

        this.playbackFrames = [];
        this.currentPlaybackFrame = 0;
        this.isPlaying = false;

        this.applyFiltersSubscription = this.eventAggregator.subscribe('filters.apply', () =>
            this.handleApplyFilters(),
        );
        this.clearFiltersSubscription = this.eventAggregator.subscribe('filters.clear', () =>
            this.handleClearFilters(),
        );
        this.mapDashboardQueryFilters = new MapDashboardQueryFilters();

        this.pointMouseDown = this.pointMouseDown.bind(this);
        this.pointMouseMove = this.pointMouseMove.bind(this);
        this.pointMouseUp = this.pointMouseUp.bind(this);
        this.pointHover = this.pointHover.bind(this);
        this.pointHoverEnd = this.pointHoverEnd.bind(this);
    }

    setView(view) {
        // If switching out of playback mode, ensure playback is stopped.
        if (this.view === 'playback') this.stopPlayback();

        if (
            view === 'playback' &&
            (!this.mapDashboardQueryFilters.dateRangeQueryFilters.dateRangeFilterOption.startDate ||
                !this.mapDashboardQueryFilters.dateRangeQueryFilters.dateRangeFilterOption.endDate)
        ) {
            this.dialogPresenter.showAlert(
                'Bounded Date Range Required',
                'A start and end date must be specified for the collection date before switching to playback mode. Please open the filters pane and ensure both start and end dates are specified.',
            );
            return;
        }

        if (this.view === 'points') {
            this.points.forEach((p) => {
                p.isSelected = false;
                p.isHovered = false;
            });

            this.mapSvgElement.removeEventListener('mousedown', this.pointMouseDown);
            this.mapSvgElement.removeEventListener('mousemove', this.pointMouseMove);
            this.mapSvgElement.removeEventListener('mouseup', this.pointMouseUp);

            this.reloadData();
            this.panZoom.enableDblClickZoom();
        }

        if (view === 'points') {
            this.mapSvgElement.addEventListener('mousedown', this.pointMouseDown);
            this.mapSvgElement.addEventListener('mousemove', this.pointMouseMove);
            this.mapSvgElement.addEventListener('mouseup', this.pointMouseUp);

            this.panZoom.disableDblClickZoom();
        }

        this.view = view;
    }

    viewChanged() {
        if (this.view === 'playback') this.loadPlaybackData();

        this.pointSelectionMode =
            this.view === 'points'
                ? MapDashboardSelectionMode.MULTI
                : MapDashboardSelectionMode.SINGLE;
    }

    async handleManipulatePointsCancel() {
        this.setView(null);

        // force a reload of the points
        await this.loadPointData();
    }

    async handleManipulatePointsSave() {
        let originalPoints = await this.pointService.getPoints({
            mapIds: [this.map.id],
        });

        let dirtyPoints = [] as Point[];
        this.points.forEach((p) => {
            let originalPoint = originalPoints.find((op) => op.id === p.id) as Point;
            if (originalPoint.x !== p.x || originalPoint.y !== p.y) dirtyPoints.push(p);
        });

        try {
            await this.mapService.updateMapPoints(this.map.id, dirtyPoints);

            this.pageContext.showSuccessOverlay('Points updated successfully.');
        } catch (error) {
            this.logger.error('Error updating map point locations', error, {
                mapId: this.mapId,
            });
            this.dialogPresenter.showAlert(
                'Error Updating Point Locations',
                "An error occurred while updating the current map's point locations. Please try again later.", // eslint-disable-line
            );
        }

        this.setView(null);
    }

    async testMethodGroupChanged(newValue: string, oldValue: string) {
        if (!newValue || newValue === oldValue) {
            return;
        }

        const confirmationTestValues = await this.testService.fetchConfirmationTestResultValues(
            this.mapId,
            this.mapDashboardQueryFilters.confirmationTestingQueryFilters.testMethodGroup,
        );
        this.confirmationTestValueOptions = confirmationTestValues.map((v: string) => {
            return {
                value: v,
                title: v,
            } as SelectorOption;
        });
    }

    async loadPlaybackData() {
        this.currentPlaybackFrame = 0;
        this.pageContext.isLoading = true;

        try {
            var dailyResults = await this.mapService.getDailyResults(
                this.mapId,
                this.mapDashboardQueryFilters.getQueryParams(),
            );

            this.playbackFrames = createPlaybackFrames(
                this.mapDashboardQueryFilters.dateRangeQueryFilters.dateRangeFilterOption.startDate,
                this.mapDashboardQueryFilters.dateRangeQueryFilters.dateRangeFilterOption.endDate,
                dailyResults,
            );
        } catch (error) {
            this.logger.error('Error loading map dashboard daily results', error, {
                mapId: this.mapId,
            });
            this.dialogPresenter.showAlert(
                'Error Loading Map Daily Results',
                "An error occurred while loading the current map's daily results. Please try again later.", // eslint-disable-line
            );
        }

        this.pageContext.isLoading = false;
    }

    stopPlayback() {
        if (!this.isPlaying) return;

        this.togglePlayback();
    }

    togglePlayback() {
        if (this.isPlaying) {
            clearInterval(this.playInterval);
        } else {
            this.playInterval = setInterval(() => {
                this.currentPlaybackFrame =
                    this.currentPlaybackFrame === this.playbackFrames.length - 1
                        ? 0
                        : this.currentPlaybackFrame + 1;
            }, 1000);
        }

        this.isPlaying = !this.isPlaying;
    }

    getPointTestPassFailCaption(pointTest) {
        if (pointTest.passed) return 'Pass';

        if (pointTest.passed === null) {
            return pointTest.hasSpecification ? 'Incomplete' : 'Spec Required';
        }

        return 'Fail';
    }

    getRemediationStatusCaption(statusCode) {
        switch (statusCode) {
            case 1:
                return 'Not Started';
            case 2:
                return 'In Progress';
            case 3:
                return 'Completed';
            case 4:
                return 'Reviewed';
            case 5:
                return 'Rejected';
        }

        return '';
    }

    getRemediationInProgressCount(pointRemediations) {
        if (!pointRemediations) return 0;

        return pointRemediations.reduce((count, r) => count + (r.status === 2 ? 1 : 0), 0);
    }

    getRemediationCompletedCount(pointRemediations) {
        if (!pointRemediations) return 0;

        return pointRemediations.reduce((count, r) => count + (r.status > 2 ? 1 : 0), 0);
    }

    pointTestSelectedChanged() {
        this.allFailedPointTestsSelected = this.failedPointTests.every((t) => t.isSelected);
        this.selectedFailedPointTests = this.failedPointTests.filter((t) => t.isSelected);
    }

    allPointTestsSelectedClicked() {
        if (this.failedPointTests) {
            this.allFailedPointTestsSelected = !this.failedPointTests.every((t) => t.isSelected);

            for (let failedPointTest of this.failedPointTests)
                failedPointTest.isSelected = this.allFailedPointTestsSelected;
        }

        this.selectedFailedPointTests = this.failedPointTests.filter((t) => t.isSelected);

        return true;
    }

    setSelectedPoint(point) {
        this.selectedPoint = point;
        this.sidebarView = 'list';

        if (point) {
            point.xOriginal = point.x;
            point.yOriginal = point.y;

            if (this.pointSelectionMode === MapDashboardSelectionMode.SINGLE) {
                this.points.forEach((p) => (p.isSelected = false));
                point.isSelected = true;

                if (point.isSelected) this.ensurePointInView(point);
            }
        }
    }

    ensurePointInView(point) {
        if (!point) return;

        var mapContainerBounds = this.mapContainer.getBoundingClientRect();

        var absolutePointX = point.x * this.scale;
        var absolutePointY = point.y * this.scale;
        if (this.pan !== undefined) {
            absolutePointX += this.pan.x;
            absolutePointY += this.pan.y;
        }

        // Settings
        var maxPointRadius = 24;
        var mainNavWidth = 50;
        var pointBoundary = {
            leftX: maxPointRadius + mainNavWidth,
            topY: maxPointRadius,
            rightX: mapContainerBounds.width - maxPointRadius,
            bottomY: mapContainerBounds.height - maxPointRadius,
        };

        // If the point's absolute position is within the boundary, return.
        if (
            absolutePointX > pointBoundary.leftX &&
            absolutePointY > pointBoundary.topY &&
            absolutePointX < pointBoundary.rightX &&
            absolutePointY < pointBoundary.bottomY
        )
            return;

        // Move point to the center of the map container.
        this.panZoom.pan({
            x: mapContainerBounds.width / 2 - point.x * this.scale,
            y: mapContainerBounds.height / 2 - point.y * this.scale,
        });
    }

    showPointPopup($event, point) {
        if (this.view === 'points') return;

        $event.stopPropagation();
        this.setSelectedPoint(point);

        this.showPointDetail(point);
    }

    showPointList() {
        this.setSelectedPoint(null);
    }

    showPointDetail(point) {
        if (this.view === 'points') return;

        // Make sure to bring the point into view BEFORE calling setSelectedPoint.
        this.ensurePointInView(point);
        this.setSelectedPoint(point);

        this.sidebarView = 'detail';
        this.loadPointDetailData(point.id);
    }

    async loadPointDetailData(pointId) {
        this.pageContext.isLoading = true;

        try {
            this.pointTests = [];
            this.pointPlans = [];
            this.pointRemediations = [];
            this.failedPointTests = [];
            this.pointHasOverdueTasks = false;

            var results = await Promise.all([
                !this.canViewResults
                    ? Promise.resolve([])
                    : (this.pointService.getPointTests(
                          pointId,
                          this.mapDashboardQueryFilters.getQueryParams(),
                      ) as unknown as any[]),
                !this.canViewPlans
                    ? Promise.resolve([])
                    : (this.planService.getPlansByPoint(pointId, 'fragment') as unknown as any[]),
                !this.canViewAllRedmediations
                    ? Promise.resolve([])
                    : (this.remediationService.getRemediations({
                          pointId,
                      }) as unknown as any[]),
                !this.canViewAllTasks
                    ? Promise.resolve([])
                    : (this.pointService.getHasOverdueTasks(pointId) as unknown as boolean),
            ]);

            this.pointTests = results[0] as any[];

            this.pointPlans = ((results[1] as any[]) || []).filter((p) => p.isActive);
            this.pointRemediations = ((results[2] as any[]) || []).filter((r) => r.isActive);
            this.pointHasOverdueTasks = results[3] as boolean;

            this.failedPointTests = this.pointTests.filter((t) => t.passed === false); // Must check for 'false' since value can be null.this.
            this.generatePointTestMetrics();
        } catch (error) {
            this.logger.error('Error loading map dashboard point details', error, {
                mapId: this.mapId,
            });
            this.dialogPresenter.showAlert(
                'Error Loading Map',
                'An error occurred while loading the current map. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    generatePointTestMetrics() {
        this.pointTestMetrics = {
            totalCount: this.pointTests.length,
            failedCount: this.pointTests.reduce(
                (count, t) => count + (t.passed === false ? 1 : 0),
                0,
            ),
            passedCount: this.pointTests.reduce(
                (count, t) => count + (t.passed === true ? 1 : 0),
                0,
            ),
            get failedPercent() {
                return this.totalCount === 0
                    ? 0
                    : Math.round((100 * this.failedCount) / this.totalCount);
            },
            get passedPercent() {
                return this.totalCount === 0
                    ? 0
                    : Math.round((100 * this.passedCount) / this.totalCount);
            },
        };
    }

    showFailedOnlyChanged() {
        this.updatePointsView();
    }

    pointFilterTextChanged() {
        this.updatePointsView();
    }

    filterOnRecordStatus(point: Point) {
        switch (this.mapDashboardQueryFilters.recordStatusQueryFilters.recordStatus) {
            case RecordStatus.ACTIVE:
                return point.isActive;
            case RecordStatus.DELETED:
                return !point.isActive;
            default:
                return true;
        }
    }

    updatePointsView() {
        if (!this.points) return;

        var lowerCasedFilterText = this.pointFilterText.toLowerCase();
        this.pointsView = this.points.filter(
            (p) =>
                (!this.showFailedOnly || this.checkFailedTest(p.id)) &&
                ((p.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (p.description || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (p.room || '').toLowerCase().indexOf(lowerCasedFilterText) > -1) &&
                this.filterOnRecordStatus(p),
        );
    }

    checkFailedTest(pointId) {
        var result = this.results[pointId];
        if (!result) return false;

        return (
            result.latestSample?.tests?.some((t) => t.passed === false) ||
            result.historical?.tests?.some((t) => !!t.failedCount)
        );
    }

    private acceptAndSetSingleView(collectionTypes: CollectionType[]) {
        if (!collectionTypes || collectionTypes.length === 0) {
            this.selectedViewOption = MapDashboardViewOption.ALL;
            return;
        }

        const collectionType = collectionTypes[0];
        switch (collectionType) {
            case CollectionType.Routine:
                this.selectedViewOption = MapDashboardViewOption.ROUTINE;
                break;
            case CollectionType.Vector:
                this.selectedViewOption = MapDashboardViewOption.VECTOR_SWAB;
                break;
            case CollectionType.CorrectiveAction:
                this.selectedViewOption = MapDashboardViewOption.CORRECTIVE_ACTION;
                break;
            default:
                this.selectedViewOption = MapDashboardViewOption.ALL;
        }
    }

    activate(params: MapDashboardQuery) {
        this.mapDashboardQueryFilters.setFilterValues(params);
        this.acceptAndSetSingleView(
            this.mapDashboardQueryFilters.collectionTypesQueryFilters.collectionTypes,
        );

        this.loadPromise = (async () => {
            this.pageContext.isLoading = true;

            try {
                this.mapId = this.mapDashboardQueryFilters.id;

                this.recordStatusOptions = getRecordStatusOptions();
                var results = (await Promise.all([
                    this.mapService.getMap(this.mapId),
                    this.pointService.getPoints({
                        mapIds: [this.mapId],
                        pointId: params.pointId,
                    }),
                    this.mapService.getPlanFilterOptions(this.mapId),
                    this.mapService.getTestMethodFilterOptions(this.mapId),
                    this.mapService.getTaskAssigneeFilterOptions(this.mapId),
                    this.pointTypeService.getPointTypes({
                        ignoreSpecificationPointTypes: true,
                        mapId: this.mapId,
                    }),
                    this.canViewAllRedmediations
                        ? this.remediationRootCauseService.getRemediationRootCauseList()
                        : [],
                ])) as any;

                this.map = results[0];

                await this.loadPointData(results[1] as any[] as Point[]);

                this.pointRoomOptions = [
                    ...new Set(this.points.filter((p) => p.room).map((p) => p.room)),
                ].map((r) => ({ value: r, title: r }));
                this.planOptions = results[2];
                this.testMethodOptions = results[3];
                this.taskAssigneeOptions = results[4];
                this.pointTypeOptions = results[5].map((pt) => ({
                    title: pt,
                    value: pt,
                }));
                this.remediationRootCauseOptions = results[6].map((rrc: RemediationRootCause) => ({
                    title: rrc.name,
                    value: rrc.id,
                }));

                this.loadResults();
            } catch (error) {
                this.logger.error('Error loading map dashboard', error, {
                    mapId: params.id,
                });

                await (error.apiErrorCode === 1
                    ? this.dialogPresenter.showAlert(
                          'Error Loading Map',
                          'The current map doesnt exist.',
                      )
                    : this.dialogPresenter.showAlert(
                          'Error Loading Map',
                          'An error occurred while loading the current map. Please try again later.',
                      ));

                this.router.navigateToRoute('map-list');
            }

            this.pageContext.isLoading = false;
        })();
    }

    async attached() {
        await this.loadPromise;

        setTimeout(() => {
            if (!this.mapSvgElement) return;

            this.panZoom = svgPanZoom(this.mapSvgElement, {
                fit: false,
                center: false,
                zoomEnabled: true,
                minZoom: 0.001,
                onZoom: (scale) => {
                    this.scale = scale;
                    this.savePanZoomSettings();
                },
                onPan: (pan) => {
                    this.pan = pan;
                    this.savePanZoomSettings();
                },
            });

            var panZoomSettings = this.panZoomSettings && this.panZoomSettings[this.mapId];
            if (!panZoomSettings) return;

            this.panZoom.zoom(panZoomSettings.zoom);
            this.panZoom.pan(panZoomSettings.pan);
        });
    }

    async loadPointData(points: Point[] = null) {
        if (points === null)
            points = (await this.pointService.getPoints({
                mapIds: [this.mapId],
            })) as unknown as Point[];

        this.points = points;
        this.initializePoints();

        this.updatePointsView();
    }

    initializePoints() {
        this.points.forEach((p) => {
            p.isSelected = false;
        });
    }

    savePanZoomSettings() {
        this.mapService.saveDashboardSettings({
            panZoom: {
                [this.mapId]: {
                    zoom: this.panZoom.getZoom(),
                    pan: this.panZoom.getPan(),
                },
            },
        });
    }

    pointMouseDown(evt) {
        if (this.view !== 'points') return true;

        this.pointMouseDownStartingPoint = {
            x: evt.clientX,
            y: evt.clientY,
        };

        // point selection ?
        if (evt.target.classList.contains('draggable')) {
            // find the point that is the "key" being dragged (multiple can be selected, but only one is technically being dragged)
            let pointId = Number(evt.srcElement.pointId);
            this.draggingKeyPoint = this.points.find((p) => p.id === pointId);

            // retain the original coords where this point started
            this.draggingKeyStartingPoint = {
                x: this.draggingKeyPoint.x,
                y: this.draggingKeyPoint.y,
            };

            this.panZoom.disablePan();
        }

        if (!evt.ctrlKey) {
            // must be holding ctrl key to start rubberband selection
            return true;
        }

        // retain the original coords where this point started
        this.draggingKeyStartingPoint = this.getTranslatedPointCoordinates(evt);
        this.selectionRubberband = {
            x: this.draggingKeyStartingPoint.x,
            y: this.draggingKeyStartingPoint.y,
            width: 0,
            height: 0,
        };

        this.panZoom.disablePan();

        return false;
    }

    pointMouseMove(evt) {
        if (!this.draggingKeyStartingPoint) return;

        // get the new (x,y) for the "key" plan point being dragged
        let draggingPointCoords = this.getTranslatedPointCoordinates(evt);

        // calculate how far the point has travelled from its original location
        let draggingOffset = {
            x: draggingPointCoords.x - this.draggingKeyStartingPoint.x,
            y: draggingPointCoords.y - this.draggingKeyStartingPoint.y,
        };

        if (!this.draggingKeyPoint) {
            this.selectionRubberband.x =
                draggingOffset.x < 0 ? draggingPointCoords.x : this.draggingKeyStartingPoint.x;
            this.selectionRubberband.y =
                draggingOffset.y < 0 ? draggingPointCoords.y : this.draggingKeyStartingPoint.y;
            this.selectionRubberband.width = Math.abs(draggingOffset.x);
            this.selectionRubberband.height = Math.abs(draggingOffset.y);
        } else this.planPointMouseMouse(evt, draggingPointCoords, draggingOffset);

        return false;
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    planPointMouseMouse(evt, draggingPointCoords, draggingOffset) {
        if (!this.draggingKeyStartingPoint) return;

        if (evt.movementX !== 0 || evt.movementY !== 0) {
            // just started dragging and unselected point w/o ctrl key ??
            let deselectOtherPoints =
                !this.draggingPointsMoved && !evt.ctrlKey && !this.draggingKeyPoint.isSelected
                    ? true
                    : false;

            // iterate selected points and adjust x,y based on how much the original "key" point moved
            this.points.forEach((p) => {
                if (p.id === this.draggingKeyPoint.id) {
                    p.x = draggingPointCoords.x;
                    p.y = draggingPointCoords.y;

                    // ensure the point is selected if movement starts to occur
                    if (evt.movementX !== 0 || evt.movementY !== 0) p.isSelected = true;
                } else {
                    if (deselectOtherPoints) p.isSelected = false;

                    if (p.isSelected) {
                        p.x = p.xOriginal + draggingOffset.x;
                        p.y = p.yOriginal + draggingOffset.y;
                    }
                }
            });

            this.draggingPointsMoved = true;
        }
    }

    pointMouseUp(evt) {
        if (this.view !== 'points') return;

        // handle the case where the user doesn't click a plan point and isn't in rubberband selecting
        if (
            !this.draggingPointsMoved &&
            !this.selectionRubberband &&
            !evt.ctrlKey &&
            this.pointMouseDownStartingPoint
        ) {
            let clientOffset = {
                x: evt.clientX - this.pointMouseDownStartingPoint.x,
                y: evt.clientY - this.pointMouseDownStartingPoint.y,
            };

            // if the user did a simple click (no pan)
            if (!clientOffset.x && !clientOffset.y)
                this.points.forEach((p) => (p.isSelected = false));
        }

        if (!this.draggingKeyPoint) this.selectionMouseUp(evt);
        else this.planPointMouseUp(evt);

        this.draggingKeyPoint = null;
        this.draggingKeyStartingPoint = null;
        this.draggingPointsMoved = false;
        this.selectionRubberband = null;
        this.pointMouseDownStartingPoint = null;

        this.panZoom.enablePan();

        return false;
    }

    selectionMouseUp(evt) {
        if (!this.selectionRubberband) return;

        this.points.forEach((p) => {
            let selected = p.x >= this.selectionRubberband.x && p.y >= this.selectionRubberband.y;
            selected =
                selected && p.x <= this.selectionRubberband.x + this.selectionRubberband.width;
            selected =
                selected && p.y <= this.selectionRubberband.y + this.selectionRubberband.height;

            if (selected) {
                p.isSelected = true;
                p.xOriginal = p.x;
                p.yOriginal = p.y;
            }
        });
    }

    planPointMouseUp(evt) {
        if (!evt.ctrlKey) {
            if (evt.movementX === 0 && evt.movementY === 0 && !this.draggingPointsMoved) {
                this.draggingKeyPoint.isSelected = true;
                this.setSelectedPoint(this.draggingKeyPoint);
                this.points.forEach((p) => {
                    if (p.id !== this.draggingKeyPoint.id) p.isSelected = false;
                });
            }
        } else {
            if (!this.draggingPointsMoved)
                this.draggingKeyPoint.isSelected = !this.draggingKeyPoint.isSelected;
        }

        // retain where the points ended up in-case we de-select & select again
        let pointsSelected = this.points.filter((p) => p.isSelected === true);
        pointsSelected.forEach((p) => {
            p.xOriginal = p.x;
            p.yOriginal = p.y;
        });
    }

    pointHover(point) {
        if (!point) return;

        point.isHovered = true;
    }

    pointHoverEnd(point) {
        if (!point) return;

        point.isHovered = false;
    }

    showLatestViewChanged() {
        this.mapService.saveDashboardSettings({
            showLatestView: this.showLatestView,
        });
    }

    viewPointSizeChanged() {
        this.mapService.saveDashboardSettings({
            viewPointSize: parseInt(this.viewPointSize),
        });
        this.innerPointViewSize = this.viewPointSize - 1;
    }

    unbind() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
        this.clearFiltersSubscription && this.clearFiltersSubscription.dispose();
    }

    //TODO: Bind the view options to the filters
    private mapSelectedViewToCollectionTypeFilter() {
        switch (this.selectedViewOption) {
            case MapDashboardViewOption.ROUTINE:
                this.mapDashboardQueryFilters.collectionTypesQueryFilters.collectionTypes = [
                    CollectionType.Routine,
                ];
                break;
            case MapDashboardViewOption.VECTOR_SWAB:
                this.mapDashboardQueryFilters.collectionTypesQueryFilters.collectionTypes = [
                    CollectionType.Vector,
                ];
                break;
            case MapDashboardViewOption.CORRECTIVE_ACTION:
                this.mapDashboardQueryFilters.collectionTypesQueryFilters.collectionTypes = [
                    CollectionType.CorrectiveAction,
                ];
                break;
            default:
                this.mapDashboardQueryFilters.collectionTypesQueryFilters.collectionTypes = [];
        }
    }

    async loadResults() {
        this.pageContext.isLoading = true;

        try {
            this.mapSelectedViewToCollectionTypeFilter();
            const filters = this.mapDashboardQueryFilters.getQueryParams();

            const [latestResults, historicalResults] = this.canViewResults
                ? await Promise.all([
                      this.mapService.getLatestResults(this.mapId, filters),
                      this.mapService.getHistoricalResults(this.mapId, filters),
                  ])
                : [[], []];

            this.results = {};
            this.updateLatestResults(latestResults);
            this.updateHistoricalResults(historicalResults);
            this.updatePointsView(); // Update points in case a change in results might hide/show a point.
        } catch (error) {
            this.logger.error('Error loading map dashboard result data', error, {
                mapId: this.mapId,
            });
            this.dialogPresenter.showAlert(
                'Error Loading Map Data',
                'An error occurred while loading the current map data. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    ensureResult(pointId) {
        if (pointId in this.results) return this.results[pointId];

        return (this.results[pointId] = {
            latestSample: {
                collectionDate: null,
                tests: [],
                get allTestsPassed() {
                    return this.tests.every((t) => t.passed);
                },
                get anyTestWarned() {
                    return this.tests.some((t) => t.warn);
                },
                get totalFailedCount() {
                    return this.tests.reduce((s, t) => s + (t.passed ? 0 : 1), 0);
                },
            },
            historical: {
                tests: [],
                get allTestsPassed() {
                    return this.tests.every((t) => t.failedCount === 0);
                },
                get anyTestWarned() {
                    return this.tests.some((t) => t.warningCount > 0);
                },
                get totalFailedCount() {
                    return this.tests.reduce(
                        (totalFailedCount, currentTest) =>
                            totalFailedCount + currentTest.failedCount,
                        0,
                    );
                },
            },
        });
    }

    updateLatestResults(latestResults) {
        for (let latestResult of latestResults) {
            let result = this.ensureResult(latestResult.pointId);
            result.latestSample.collectionDate = latestResult.sampleCollectionDate;
            result.latestSample.tests.push({
                testMethod: this.testMethodOptions.find(
                    (tm) => tm.value === latestResult.testMethodId,
                ),
                passed: latestResult.passed,
                warn: latestResult.warn,
            });
        }
    }

    updateHistoricalResults(historicalResults) {
        for (let historicalResult of historicalResults) {
            let result = this.ensureResult(historicalResult.pointId);
            result.historical.tests.push({
                testMethod: this.testMethodOptions.find(
                    (tm) => tm.value === historicalResult.testMethodId,
                ),
                passedCount: historicalResult.passedCount,
                failedCount: historicalResult.failedCount,
                warningCount: historicalResult.warningCount,
            });
        }
    }

    createRemediation() {
        this.router.navigateToRoute('remediation-detail', {
            id: 'create',
            testIds: this.selectedFailedPointTests.map((t) => t.testId),
            mapId: this.mapId,
        });
    }

    createVectorTasks() {
        this.router.navigateToRoute('create-vector-tasks', {
            failedTestId: this.selectedFailedPointTests[0].testId,
        });
    }

    reloadData() {
        this.loadResults();

        if (this.view === 'playback') this.loadPlaybackData();

        if (this.sidebarView === 'detail') this.loadPointDetailData(this.selectedPoint.id);
    }

    selectedViewOptionChanged() {
        if (!this.mapId) return;

        this.mapSelectedViewToCollectionTypeFilter();
        this.router.navigateToRoute(
            'map-dashboard',
            this.mapDashboardQueryFilters.getQueryParams(),
        );
    }

    handleApplyFilters() {
        this.router.navigateToRoute(
            'map-dashboard',
            this.mapDashboardQueryFilters.getQueryParams(),
        );
    }

    async handleClearFilters() {
        this.mapDashboardQueryFilters.reset();
        this.router.navigateToRoute(
            'map-dashboard',
            this.mapDashboardQueryFilters.getQueryParams(),
        );
    }

    zoomIn() {
        this.panZoom && this.panZoom.zoomIn();
    }

    zoomOut() {
        this.panZoom && this.panZoom.zoomOut();
    }

    getTranslatedPointCoordinates(event) {
        const point = this.mapSvgElement.createSVGPoint();
        point.x = event.clientX;
        point.y = event.clientY;

        const pan = this.panZoom.getPan();

        // The cursor point, translated into svg coordinates
        const cursorPoint = point.matrixTransform(this.mapSvgElement.getScreenCTM().inverse());

        // adjust for zoom
        let pixelX = cursorPoint.x / this.scale;
        let pixelY = cursorPoint.y / this.scale;

        // adjust for pan
        pixelX = Math.round(pixelX - pan.x / this.scale);
        pixelY = Math.round(pixelY - pan.y / this.scale);

        return {
            x: pixelX,
            y: pixelY,
        };
    }

    async bind() {
        this.confirmationTestValueOptionsSubscription = this.bindingEngine
            .propertyObserver(
                this.mapDashboardQueryFilters.confirmationTestingQueryFilters,
                'testMethodGroup',
            )
            .subscribe((newValue: string, oldValue: string) => {
                this.testMethodGroupChanged(newValue, oldValue);
            });
    }

    dispose() {
        this.confirmationTestValueOptionsSubscription?.dispose();
    }
}
