import { AgPromise, ICellRendererParams } from 'ag-grid-community';
import { Container, View, ViewCompiler } from 'aurelia-framework';
import { IAureliaCellRendererComp } from './i-aurelia-cell-renderer-comp';

export abstract class AureliaCellRendererCompBase implements IAureliaCellRendererComp {
    container: Container;
    viewCompliler: ViewCompiler;
    view: View;
    rootElement: HTMLElement;

    constructor(template: string) {
        this.container = Container.instance;
        this.viewCompliler = this.container.get(ViewCompiler);
        const viewFactory = this.viewCompliler.compile(`<template>${template}</template>`);
        this.view = viewFactory.create(this.container);
        this.rootElement = document.createElement('div');
    }

    init?(params: ICellRendererParams): void | AgPromise<void> {
        this.view.bind(params);
        this.view.appendNodesTo(this.rootElement);
        this.view.attached();
    }

    getGui(): HTMLElement {
        return this.rootElement;
    }

    refresh(params: ICellRendererParams): boolean {
        return true;
    }

    destroy?(): void {
        this.view.unbind();
        this.view.removeNodes();
        this.view.detached();
    }
}
