import {
    CellEditingStoppedEvent,
    ColDef,
    ColGroupDef,
    GridOptions,
    ValueFormatterParams,
    ValueGetterParams,
} from 'ag-grid-community';
import { renderAgGridCellForAurelia } from 'infrastructure/aurelia-cell-renderer';

let inputRow: any = {};

const addNewItem = (params: CellEditingStoppedEvent) => {
    if (params.rowPinned !== 'top') {
        return;
    }

    inputRow.id = 0;
    params.api.applyTransaction({ add: [inputRow], addIndex: 0 });
    inputRow = {};
    params.api.setPinnedTopRowData([inputRow]);
};

const removeItem = (params: ValueGetterParams) => {
    const { node } = params;
    const rowToRemove = params.api.getRowNode(node.id);

    params.api.applyTransaction({
        remove: [rowToRemove?.data],
    });
};

const columnDefs: (ColDef | ColGroupDef)[] = [
    {
        field: 'name',
        headerName: 'Name',
        rowDrag: true,
        editable: true,
    },
    {
        field: 'id',
        headerName: '',
        sortable: false,
        valueGetter: (params: ValueGetterParams) => {
            if (!params.data || params.node.rowPinned === 'top') {
                return;
            }

            const { data } = params;
            data.removeItem = () => removeItem(params);

            return {
                data,
                template: `
                <div>
                    <a
                        click.delegate="data.removeItem(params)">
                        <i class="fa fa-trash-o"></i>
                    </a>
                </div>
                `,
            };
        },
        cellRenderer: renderAgGridCellForAurelia,
    },
];

export const remediationRootCauseGridOptions: GridOptions = {
    columnDefs,
    pinnedTopRowData: [inputRow],
    defaultColDef: {
        flex: 1,
        sortable: true,
        filter: false,
        valueFormatter: (params: ValueFormatterParams) => {
            const { node } = params;

            return node.rowPinned === 'top' ? 'Add Root Cause...' : undefined;
        },
    },
    rowDragManaged: true,
    animateRows: true,
    getRowStyle: ({ node }) =>
        node.rowPinned ? { 'font-weight': 'bold', 'font-style': 'italic' } : {},
    onCellEditingStopped: addNewItem,
};
