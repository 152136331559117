import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class TimeZoneService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getTimeZones() {
        return this.httpClient.get(`time-zones`);
    }
}
