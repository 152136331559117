export default class BooleanUtility {
    static parsePrimitive(value?: string | number | boolean | null): boolean {
        if (value === 'true') {
            return true;
        }
    
        return typeof value === 'string'
            ? !!+value   // we parse string to number first
            : !!value;
    }
}
