import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode, inject } from 'aurelia-framework';

@inject(Element, EventAggregator)
export class TestMethodFilter {
    @bindable testMethods;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedTestMethods;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) isFiltering;

    constructor(element, eventAggregator) {
        this.element = element;
        this.eventAggregator = eventAggregator;
        this.selectedTestMethods = [];
        this.isFiltering = false;
        this.clearSelectedTestMethods =
            this.clearSelectedTestMethods.bind(this);
    }

    selectedTestMethodsChanged() {
        this.isFiltering = !!this.selectedTestMethods.length;
    }

    clearSelectedTestMethods() {
        this.selectedTestMethods = [];
    }

    attached() {
        this.clearFilterSubscription = this.eventAggregator.subscribe(
            'filters.clear',
            this.clearSelectedTestMethods
        );
    }

    detached() {
        this.clearFilterSubscription && this.clearFilterSubscription.dispose();
    }
}
