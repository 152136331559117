import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@autoinject
export default class UserService {
    constructor(private httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getDashboardLinks() {
        return this.httpClient.get('dashboard/links');
    }
}
