import $ from 'jquery';
import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import PageContext from '../../infrastructure/page-context';
import Logger from '../../infrastructure/logger';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import CompareUtility from '../../infrastructure/compare-utility';
import SecurityService from '../../security/security-service';
import GlossaryTermService from './glossary-term-service';

@inject(Element, Router, PageContext, Logger, DialogPresenter, SecurityService, GlossaryTermService)
export default class GlossaryTermList {
    @observable filterText;

    constructor(element, router, pageContext, logger, dialogPresenter, securityService, glossaryTermService) {
        this.element = element;
        this.router = router;
        this.pageContext = pageContext;
        this.logger = logger;
        this.logger.name = 'glossary-term-list';
        this.dialogPresenter = dialogPresenter;
        this.securityService = securityService;
        this.glossaryTermService = glossaryTermService;
        this.filterText = '';
        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template: '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate: '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true
                },
                { suppressMenu: true, headerName: 'Name', field: 'name', comparator: CompareUtility.compareStringsInsensitive, width: 150, sort: 'asc' },
                {
                    suppressMenu: true,
                    headerName: 'Description',
                    field: 'description',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                    cellClass: 'long-text',
                    template: '<div title.bind="data.description" style="cursor:zoom-in">${data.description}</div>'
                },
                {
                    suppressMenu: true,
                    headerName: 'Active',
                    field: 'isActive',
                    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button click.delegate="navigateToGlossaryTermDetail(data.id)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button click.trigger="deleteGlossaryTerm(data)" disabled.bind="!data.isActive">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 100,
                    sortable: false,
                    suppressSizeToFit: true
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.allSelected = false;
        this.selectedActiveGlossaryTerms = [];
    }

    navigateToGlossaryTermDetail(id) {
        this.router.navigateToRoute('glossary-term-detail', { id });
    }

    setSelectedActiveGlossaryTerms() {
        this.selectedActiveGlossaryTerms = this.glossaryTermsView.filter(glossaryTerm => glossaryTerm.isSelected && glossaryTerm.isActive);
    }

    isSelectedChanged() {
        this.allSelected = this.glossaryTermsView.every(glossaryTerm => glossaryTerm.isSelected);
        this.setSelectedActiveGlossaryTerms();
    }

    allSelectedClicked() {
        if (this.glossaryTermsView) {
            this.allSelected = !this.glossaryTermsView.every(glossaryTerm => glossaryTerm.isSelected);

            for (let glossaryTerm of this.glossaryTermsView)
                glossaryTerm.isSelected = this.allSelected;
        }

        this.setSelectedActiveGlossaryTerms();

        return true;
    }

    filterTextChanged() {
        this.updateGlossaryTermsView();
    }

    updateGlossaryTermsView() {
        if (!this.glossaryTerms)
            return;

        var lowerCasedFilterText = this.filterText.toLowerCase();
        this.glossaryTermsView = this.glossaryTerms.filter(u =>
            (u.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
            (u.description || '').toLowerCase().indexOf(lowerCasedFilterText) > -1
        );
    }

    activate() {
        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.glossaryTerms = await this.glossaryTermService.getGlossaryTerms();
                this.pageContext.isLoading = false;
                this.updateGlossaryTermsView();
            } catch (error) {
                this.logger.error('Error while loading glossary terms', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Glossary Terms',
                    'An error occurred while loading the glossary terms. Please try again later.');
            }
        })();
    }

    deleteGlossaryTerm(glossaryTerm) {
        this.dialogPresenter
            .showConfirmation('Delete Glossary Term', 'Are you sure you want to delete this glossary term?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                this.pageContext.isLoading = true;
                this.glossaryTermService
                    .deleteGlossaryTerms([glossaryTerm.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Glossary term deleted successfully.');

                        glossaryTerm.isSelected = false;
                        glossaryTerm.isActive = false;

                        this.setSelectedActiveGlossaryTerms();
                    })
                    .catch(error => {
                        this.logger.error('Error deleting glossary term.', error, { glossaryTermId: glossaryTerm.id });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error);
                    });
            });
    }

    deleteSelectedGlossaryTerms() {
        var glossaryTermsToDelete = this.glossaryTermsView.filter(u => u.isSelected);
        if (!glossaryTermsToDelete.length)
            return;

        this.dialogPresenter
            .showConfirmation('Delete Selected Glossary Terms', 'Are you sure you want to delete the selected glossary terms?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                var glossaryTermIds = glossaryTermsToDelete.map(glossaryTerm => glossaryTerm.id);

                this.pageContext.isLoading = true;
                this.glossaryTermService
                    .deleteGlossaryTerms(glossaryTermIds)
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Selected glossary terms deleted successfully.');

                        for (let glossaryTerm of glossaryTermsToDelete) {
                            glossaryTerm.isSelected = false;
                            glossaryTerm.isActive = false;

                            this.setSelectedActiveGlossaryTerms();
                        }
                    })
                    .catch(error => {
                        this.logger.error('Error deleting glossary terms.', error, { glossaryTermIds });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, true);
                    });
            });
    }

    handleDeleteError(error, multipleGlossaryTerms) {
        this.dialogPresenter
            .showAlert(
            'Error Deleting Glossary Term' + (multipleGlossaryTerms ? 's' : ''),
            'An unexpected error occurred while trying to delete glossary term' + (multipleGlossaryTerms ? 's' : '') + '. Please try again later.');
    }
};
