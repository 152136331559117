import { inject } from 'aurelia-framework';
import { observable } from 'aurelia-framework';
import CompareUtility from '../infrastructure/compare-utility';
import Logger from '../infrastructure/logger';
import PageContext from '../infrastructure/page-context';
import TestMethodService from './test-method-service';

@inject(Logger, PageContext, TestMethodService)
export default class TestMethodList {
    @observable testMethodFilterText;

    constructor(logger, pageContext, testMethodService) {
        this.logger = logger;
        this.logger.name = 'test-method-list';

        this.pageContext = pageContext;
        this.testMethodService = testMethodService;
        this.testMethodFilterText = '';

        this.testMethodGridOptions = {
            columnDefs: [
                { suppressMenu: true, headerName: 'Bill Code', field: 'billCode', comparator: CompareUtility.compareStringsInsensitive, sort: 'asc' },
                { suppressMenu: true, headerName: 'Test Method', field: 'name', comparator: CompareUtility.compareStringsInsensitive, sort: 'asc' },
                { suppressMenu: true, headerName: 'Cost', field: 'quotedPrice', template: '${data.quotedPrice | currency}' }
            ],
            defaultColDef: { sortable: true, resizable: true },
        };
    }
    
   testMethodFilterTextChanged() {
        this.updateTestMethodView();
    }

    updateTestMethodView() {
        if (!this.organizationTestMethods)
            return;

        var lowerCasedFilterText = this.testMethodFilterText.toLowerCase();

        this.organizationTestMethodsView = this.organizationTestMethods.filter(otm =>
            (otm.testMethod.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
            (otm.quotedPrice || '').toString().toLowerCase().indexOf(lowerCasedFilterText) > -1);
    }

    activate(params) {
        this.pageContext.isLoading = true;

        this.testMethodService.getTestMethods(params.id)
            .then(testMethods => {
                this.testMethods = testMethods;
                this.updateTestMethodView();
                this.pageContext.isLoading = false;
            })
            .catch(error => {
                this.logger.error('Error loading test methods.', error, { organizationId: params.id });
                this.pageContext.isLoading = false
            });
    }
};
