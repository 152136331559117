import { bindable, bindingMode } from 'aurelia-framework';

export class ErrorTooltip {
    @bindable message;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) isVisible = false;

    tooltip;
    tooltipContainer;

    constructor() {
        this.isVisible = false;
    }

    isVisibleChanged() {
        this.initialize();
    }

    messageChanged() {
        this.initialize();
    }

    destroy() {
        this.tooltip && this.tooltip.destroy();
    }

    initialize() {
        if (!this.tooltipContainer || !this.isVisible)
            return;

        this.destroy();
        this.tooltip = new Foundation.Tooltip($(this.tooltipContainer), { tipText: this.message });
    }

    attached() {
        this.initialize();
    }

    detached() {
        this.destroy();
    }
}
