import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { ValidationController, ValidationRules } from 'aurelia-validation';
import { RemediationUserType } from 'location-testing/remediations/remediation-user-type';
import RemedationUsers from 'location-testing/remediations/remediation-users';
import DialogPresenter from '../../../infrastructure/dialogs/dialog-presenter';

@autoinject
export class AutoRemediationCustomElement implements RemedationUsers {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) autoRemediation: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) deactivateRoutineTaskGeneration: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) assignedUserId?: number;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) approvalUserId?: number;
    @bindable editable: boolean;
    @bindable assignedUserFullName?: string;
    @bindable approvalUserFullName?: string;

    constructor(
        private dialogPresenter: DialogPresenter,
        private readonly validationController: ValidationController,
    ) {}

    async selectUser(remdiationUserType: RemediationUserType): Promise<void> {
        const result = await this.dialogPresenter.showUserSelector('Select a user');
        if (result.wasCancelled) return;

        const user = result.output;
        if (!user) return;

        switch (remdiationUserType) {
            case RemediationUserType.Assigned:
                this.assignedUserId = user.id;
                this.assignedUserFullName = this.toUserFullName(user);
                break;
            case RemediationUserType.Approval:
                this.approvalUserId = user.id;
                this.approvalUserFullName = this.toUserFullName(user);
                break;
            default:
                return;
        }
    }
    async autoRemediationChanged(oldValue: boolean, newValue: boolean) {
        if (typeof newValue === 'undefined') {
            return;
        }

        // let the users bail out if they accidently click off auto-remediation
        if (!oldValue && (this.assignedUserId || this.approvalUserId)) {
            const confirmed = await this.dialogPresenter.showConfirmation(
                'Remove Auto Remediation Users',
                'Confirm you wish to remove auto remediation users from this cap plan.',
                'Confirm',
            );
            if (confirmed) {
                this.assignedUserId = null;
                this.approvalUserId = null;
                this.assignedUserFullName = null;
                this.approvalUserFullName = null;
                this.deactivateRoutineTaskGeneration = false;

                return;
            }

            this.autoRemediation = true;
            return;
        }

        this.deactivateRoutineTaskGeneration = false;
    }

    private toUserFullName(user: any): string {
        return user.firstName + ' ' + user.lastName;
    }

    bind() {
        ValidationRules.customRule(
            'autoRemediationRule',
            (v: boolean, model: this) => !v || (v && model.autoRemediation),
            '',
        );
        ValidationRules.ensure((model: this) => model.deactivateRoutineTaskGeneration)
            .satisfiesRule('autoRemediationRule')
            .ensure((model: this) => model.assignedUserId)
            .required()
            .when((model: this) => model.autoRemediation)
            .ensure((model: this) => model.approvalUserId)
            .required()
            .when((model: this) => model.autoRemediation)
            .ensure((model: this) => model.approvalUserId)
            .satisfies((v: number, model: this) => {
                return (
                    (!v && !model.assignedUserId) ||
                    (v && model.assignedUserId && v !== model.assignedUserId)
                );
            })
            .on(this);
    }
}
