import { UiViewModel } from 'infrastructure/ui-view-model';
import Schedule from 'location-testing/plans/schedule';
import moment from 'moment';
import TargetCollectionTimes from './target-collection-times';
import TaskDistributionMethod from './task-distribution-method';


export default class UiSchedule implements Schedule, UiViewModel {
    private _startDate: string;
    private _startDateView: Date;
    private _endDate: string;
    private _endDateView: Date;
    private _blackoutDates: string[];
    private _blackoutDatesView: Date[];
    private _scheduleMinDate: Date;

    cycleDurationDays: number;
    targetCollectionTimes: TargetCollectionTimes[];
    skipDays: number;
    taskDistributionMethod: string;
    blackoutSundays: boolean;
    blackoutMondays: boolean;
    blackoutTuesdays: boolean;
    blackoutWednesdays: boolean;
    blackoutThursdays: boolean;
    blackoutFridays: boolean;
    blackoutSaturdays: boolean;
    timeZone: string;

    constructor() {
        this.taskDistributionMethod = TaskDistributionMethod.WEEK_BASED;
        this.skipDays = 0;
        this.targetCollectionTimes = [];
        this.timeZone = 'Pacific Standard Time';
        this._blackoutDates = [];
        this._blackoutDatesView = [];

        this.startDateView = new Date();
        this._scheduleMinDate = this.getTwentyFourHoursAgoDate();
    }

    public get startDate() {
        return this._startDate;
    }

    public set startDate(startDate: string) {
        this._startDate = startDate;
        if (startDate) {
            this._startDateView = new Date(startDate);
        }
    }

    public get startDateView() {
        return this._startDateView;
    }

    public set startDateView(startDateView: Date) {
        this._startDateView = startDateView;
        this._startDate = moment(startDateView).format('YYYY-MM-DD');
    }

    public get endDate() {
        return this._endDate;
    }

    public set endDate(endDate: string) {
        this._endDate = endDate;
        if (endDate) {
            this._endDateView = new Date(endDate);
        }
    }

    public get endDateView() {
        return this._endDateView;
    }

    public set endDateView(endDateView: Date) {
        this._endDateView = endDateView;
        this._endDate = moment(endDateView).format('YYYY-MM-DD');
    }

    public get blackoutDates() {
        return this._blackoutDates;
    }

    public set blackoutDates(blackoutDates: string[]) {
        this._blackoutDates = blackoutDates;
        if (blackoutDates?.length) {
            this._blackoutDatesView = blackoutDates.map(d => new Date(`${d}${d.endsWith('Z') ? '' : 'Z'}`));
        }
    }

    public get blackoutDatesView() {
        return this._blackoutDatesView;
    }

    public set blackoutDatesView(blackoutDatesView: Date[]) {
        this._blackoutDatesView = blackoutDatesView;
        if (blackoutDatesView?.length) {
            this._blackoutDates = blackoutDatesView.map(d => moment(d).format('YYYY-MM-DD'));
        }
    }

    public get scheduleMinDate(): Date {
        return this._scheduleMinDate;
    }

    toEntity(): any {
        let schedule = {};
        Object.keys(this).forEach(k => {
            if (k.charAt(0) !== '_') {
                schedule[k] = this[k];
            }
        });

        schedule['startDate'] = this.startDate;
        schedule['endDate'] = this.endDate;
        schedule['blackoutDates'] = this.blackoutDates;

        return schedule;
    }

    loadViewFrom(entity: any): void {
        if (!entity) {
            return;
        }
        this.timeZone = entity.timeZone;
        this.startDate = entity.startDate;
        this.endDate = entity.endDate;
        this.cycleDurationDays = entity.cycleDurationDays;
        this.targetCollectionTimes = entity.targetCollectionTimes;
        this.skipDays = entity.skipDays;
        this.blackoutDates = entity.blackoutDates;
        this.taskDistributionMethod = entity.taskDistributionMethod;
        this.blackoutSundays = entity.blackoutSundays;
        this.blackoutMondays = entity.blackoutMondays;
        this.blackoutTuesdays = entity.blackoutTuesdays;
        this.blackoutWednesdays = entity.blackoutWednesdays;
        this.blackoutThursdays = entity.blackoutThursdays;
        this.blackoutFridays = entity.blackoutFridays;
        this.blackoutSaturdays = entity.blackoutSaturdays;
    }

    private getTwentyFourHoursAgoDate(): Date {
        let today = new Date();
        let yesterdayDate = new Date();
        yesterdayDate.setDate(today.getDate() - 1);

        return yesterdayDate;
    }
}
