import { ExportTaskCollectionTemplateColumn } from './export-task-collection-template-column';
import { ExportTaskCollectionTemplateConfiguration } from './export-task-collection-template-configuration';
import { emptyExportTaskCollectionTemplateFooterOptions, ExportTaskCollectionTemplateFooterOptions } from './export-task-collection-template-footer-options';
import { emptyExportTaskCollectionTemplateFormattingOptions, ExportTaskCollectionTemplateFormattingOptions } from './export-task-collection-template-formatting-options';
import { emptyExportTaskCollectionTemplateHeaderOptions, ExportTaskCollectionTemplateHeaderOptions } from './export-task-collection-template-header-options';

export interface ExportTaskCollectionTemplate {
    id: number;
    isActive: boolean;
    organizationConfigurations: ExportTaskCollectionTemplateConfiguration[];
    columns: ExportTaskCollectionTemplateColumn[];
    formattingOptions: ExportTaskCollectionTemplateFormattingOptions;
    headerOptions?: ExportTaskCollectionTemplateHeaderOptions;
    footerOptions?: ExportTaskCollectionTemplateFooterOptions;
}

export const emptyExportTaskCollectionTemplate = (): ExportTaskCollectionTemplate => ({
    id: 0,
    isActive: false,
    organizationConfigurations: [],
    columns: [],
    formattingOptions: emptyExportTaskCollectionTemplateFormattingOptions(),
    headerOptions: emptyExportTaskCollectionTemplateHeaderOptions(),
    footerOptions: emptyExportTaskCollectionTemplateFooterOptions(),
})
