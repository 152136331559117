import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import AdhocReportInfo from './contracts/adhoc-report-info';

@autoinject
export default class AdhocReportService {
    constructor(private httpClient: HttpClient) {}

    getDownloadUrl(id, format) {
        return `api/reporting/adhoc-reports/${id}/results?format=${format}`;
    }

    getCurrentUserAdhocReports(): Promise<AdhocReportInfo[]> {
        return this.httpClient.get(
            'reporting/adhoc-reports',
        ) as unknown as Promise<AdhocReportInfo[]>;
    }

    getAdhocReport(id) {
        return this.httpClient.get(`reporting/adhoc-reports/${id}`);
    }

    getTempAdhocReportResults(adhocReport) {
        return this.httpClient.post(
            'reporting/adhoc-reports/temp/results',
            adhocReport,
        );
    }

    getAdhocReportResults(id) {
        return this.httpClient.get(`reporting/adhoc-reports/${id}/results`);
    }

    deleteAdhocReports(ids) {
        var query = ids.map((id) => `id=${id}`).join('&');
        return this.httpClient.delete(`reporting/adhoc-reports?${query}`);
    }

    saveAdhocReport(adhocReport) {
        if (!adhocReport.id)
            return this.httpClient.post(
                'reporting/adhoc-reports/',
                adhocReport,
            );

        return this.httpClient.put(
            `reporting/adhoc-reports/${adhocReport.id}`,
            adhocReport,
        );
    }
}
