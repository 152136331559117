import SelectorOption from 'infrastructure/selector-option';

export default function getPointZoneOptions(): SelectorOption[] {
    return [
        { title: '1', value: 1 },
        { title: '2', value: 2 },
        { title: '3', value: 3 },
        { title: '4', value: 4 },
    ];
}
