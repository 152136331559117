import { bindable, bindingMode } from 'aurelia-framework';

export function getTimeOptions() {
    var times = [];

    const quarterHours = ['00', '15', '30', '45'];

    for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 4; j++) {
            let time = `${i}:${quarterHours[j]}`;
            if (i < 10)
                time = `0${time}`;

            let title;
            if (i === 0) {
                title = `12:${quarterHours[j]} AM`;
            }
            else if (i >= 12) {
                let twelveHrClockOffset = i;
                if (i > 12)
                    twelveHrClockOffset = i - 12;
                title = `${twelveHrClockOffset}:${quarterHours[j]} PM`;
            } else {
                title = `${time} AM`;
            }
            times.push({ title, value: time });
        }
    }

    return times;
};

export class TimeSelector {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedTime;
    @bindable disabled;

    constructor() {
        this.times = getTimeOptions();
    }
};
