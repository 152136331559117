import {inject, bindable} from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

@inject(DialogController)
export default class NameValueItemSelector {
    @bindable selectedItem;

    constructor(controller) {
        this.controller = controller;
    }

    async selectedItemChanged() {
    }

    activate(model) {
        this.model = model;
    }
}
