import { inject, bindable, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import NavigationAuthorizer from '../security/navigation/navigation-authorizer';

@inject(Router, NavigationAuthorizer)
export class LocationTestingNavigationMenu {
    @bindable colSize;
    @bindable mapId;

    constructor(router, navigationAuthorizer) {
        this.router = router;
        this.navigationAuthorizer = navigationAuthorizer;
    }

    async mapIdChanged() {
        if (!this.mapId)
            return;

        let navItems = [];

        for (let route of this.router.routes)
            if (route.navModel.config.group === 'location-testing' && await this.navigationAuthorizer.hasAccess(route.navModel.config))
                navItems.push({
                    href: route.name === 'map-dashboard' ?
                        this.router.generate(route.name, { id: this.mapId }) :
                        this.router.generate(route.name, { mapId: this.mapId }),
                    title: route.title,
                    icon: route.icon,
                    get isActive() {
                        return this.href === window.location.hash.substring(1);
                    }
                });

        this.navItems = navItems;
    }
};
