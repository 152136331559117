import moment from 'moment';
import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { createDateRangeFilterOptions } from '../../infrastructure/components/filters/date-range-filter';
import CompareUtility from '../../infrastructure/compare-utility';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import UserService from '../../users/user-service';
import CustomerTestImportService from './customer-test-import-service';

@inject(Router, EventAggregator, Logger, PageContext, DialogPresenter, UserService, CustomerTestImportService)
export default class CustomerTestImportList {
    @observable filterText;

    constructor(router, eventAggregator, logger, pageContext, dialogPresenter, userService, customerTestImportService) {
        this.router = router;
        this.eventAggregator = eventAggregator;

        this.logger = logger;
        this.logger.name = 'customer-test-import-list';

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.userService = userService;
        this.customerTestImportService = customerTestImportService;
        this.filterText = '';
        this.gridOptions = {
            columnDefs: [
                { suppressMenu: true, headerName: 'Uploaded', field: 'uploadDateTime', width: 150, sort: 'desc', template: '<div>${data.uploadDateTime | dateFormat}</div>' },
                { suppressMenu: true, headerName: 'Processed', field: 'processedDateTime', width: 150, template: '<div>${data.processedDateTime | dateFormat}</div>' },
                { suppressMenu: true, headerName: 'Organization', field: 'organizationName', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                { suppressMenu: true, headerName: 'File Name', field: 'originalFileName', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                { suppressMenu: true, headerName: 'Imported Records', field: 'successfulRecordCount' },
                { suppressMenu: true, headerName: 'Failed Records', field: 'failedRecordCount' },
                { suppressMenu: true, headerName: 'Total Records', field: 'totalRecordCount' },
                {
                    suppressMenu: true,
                    headerName: 'Error Logs',
                    cellClass: 'row-actions actions-persistent',
                    field: 'logFileUrl',
                    width: 150,
                    template: '<a if.bind="data.logFileUrl" class="normal" target="_blank" style="font-weight: normal" href.bind="data.logFileUrl">Download</a>',
                    sortable: false,
                    suppressSizeToFit: true
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        // Filters
        this.selectedOrganizations = [];
        this.uploadDateRangeOptions = createDateRangeFilterOptions();
        this.defaultUploadDateRangeOption = 'last30Days';
        this.selectedUploadDateRangeOption = this.uploadDateRangeOptions.find(o => o.value === this.defaultUploadDateRangeOption);

        this.handleApplyFilters = this.handleApplyFilters.bind(this);
    }

    handleApplyFilters() {
        this.loadCustomerTestImports();
    }

    filterTextChanged() {
        this.updateCustomerTestImportsView();
    }

    updateCustomerTestImportsView() {
        var lowerCasedFilterText = this.filterText.toLowerCase();

        if (this.customerTestImports) {
            this.customerTestImportsView = this.customerTestImports.filter(n =>
                (n.organizationName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1
            );
        }
    }

    async loadCustomerTestImports() {
        this.customerTestImports = await this.customerTestImportService.getCustomerTestImports({
            organizationIds: this.selectedOrganizations.map(o => o.id),
            uploadStartDate: this.selectedUploadDateRangeOption.startDate ?
                moment(this.selectedUploadDateRangeOption.startDate).startOf('day').utc().format() :
                null,
            uploadEndDate: this.selectedUploadDateRangeOption.endDate ?
                moment(this.selectedUploadDateRangeOption.endDate).startOf('day').add(1, 'days').utc().format() :
                null
        });

        for (let customerTestImport of this.customerTestImports) {
            if (!customerTestImport.uploadDateTime.endsWith('Z') && !customerTestImport.uploadDateTime.endsWith('z'))
                customerTestImport.uploadDateTime += 'Z';

            if (customerTestImport.processedDateTime) {
                customerTestImport.totalRecordCount = customerTestImport.successfulRecordCount + customerTestImport.failedRecordCount;

                if (!customerTestImport.processedDateTime.endsWith('Z') && !customerTestImport.processedDateTime.endsWith('z'))
                    customerTestImport.processedDateTime += 'Z';
            }
        }

        this.updateCustomerTestImportsView();
    }

    activate() {
        this.applyFiltersSubscription = this.eventAggregator.subscribe('filters.apply', this.handleApplyFilters);

        (async () => {
            this.pageContext.isLoading = true;

            try {
                var results = await Promise.all([
                    this.userService.getCurrentUserOrganizations(),
                    this.loadCustomerTestImports()
                ]);

                this.currentUserOrganizations = results[0];
            } catch (error) {
                this.logger.error('Error loading customer test imports.', error);
                this.dialogPresenter.showAlert(
                    'Error Loading Customer Test Imports',
                    'An error occurred while loading the customer test imports. Please try again later.');
            }

            this.pageContext.isLoading = false;
        })();
    }

    deactivate() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
    }
};
