import SelectorOption from 'infrastructure/selector-option';
import MapSummary from 'location-testing/maps/map-summary';

export default class MapSummaryConverter {
    static toSelectorOptions(mapSummaries: MapSummary[], activeOnly = true): SelectorOption[] {
        if (!mapSummaries)
            return [];

        let maps = activeOnly ? mapSummaries.filter(m => m.isActive) : mapSummaries;

        let options = [];
        maps.forEach(ms => {
            let option = {
                title: ms.name,
                value: ms.id
            } as unknown as SelectorOption;

            options.push(option);
        });

        return options;
    }
}
