import Attribute from 'location-testing/attribute';
import { BaseQueryFilter } from './base-query-filter';
import { QueryFiltered } from './query-filtered';

export interface MapAttributesQuery {
    mapAttributes: any;
}

export class MapAttributesQueryFilters extends BaseQueryFilter
    implements QueryFiltered<MapAttributesQuery> {

    private _key = 'mapAttributes';
    mapAttributes: Attribute[];

    constructor() {
        super();

        this.mapAttributes = [];
    }

    public get count(): number {
        return this.mapAttributes.length;
    }

    reset(): void {
        this.mapAttributes = [];
    }

    getQueryParams(): MapAttributesQuery {
        return Object.assign(
            {},
            ...this.mapAttributes.map((a: Attribute, i: number) => ({ [`${this._key}[${i}].name`]: a.name })),
            ...this.mapAttributes.map((a: Attribute, i: number) => ({ [`${this._key}[${i}].value`]: a.value })),
        );
    }

    setFilterValues(params: any): void {
        if (!params) {
            return;
        }

        const regexNameKey = /mapAttributes\[[0-9]+\].name/gm;
        const regexValueKey = /mapAttributes\[[0-9]+\].value/gm;
        const fooParams = Object.entries(params).map(([k]) => k);
        const nameGroups = fooParams.filter(p => regexNameKey.test(p));
        const valueGroups = fooParams.filter(p => regexValueKey.test(p));
        if (nameGroups.length !== valueGroups.length) {
            throw new Error('The map attribute name and value params do not match.');
        }

        nameGroups.forEach((k: string) => {
            const valueKey = k.substring(0, k.indexOf('.')) + '.value';
            this.mapAttributes.push({
                name: params[k], value: params[valueKey]
            });
        })
    }
}
