import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import SegmentationTemplate from './segmentation-template';
import SegmentationTemplateSegment from './segmentation-template-segment';

@autoinject
export default class SegmentationTemplateService {
    constructor(private httpClient: HttpClient) {
    }

    getSegmentationTemplates(filters = {}, view: 'Default' | 'Selector' = 'Default') {
        var query = buildQueryString({ ...filters, view }, true);

        return this.httpClient.get(`segmentation-templates${(query ? `?${query}` : '')}`) as any as Promise<SegmentationTemplate[]>;
    }

    getSegmentationTemplateSegments(filters = {}) {
        var query = buildQueryString(filters, true);

        return this.httpClient.get(`segmentation-templates/all/segments${(query ? `?${query}` : '')}`) as any as Promise<SegmentationTemplateSegment[]>;
    }

    getSegmentationTemplate(id: number) {
        return this.httpClient.get(`segmentation-templates/${id}`) as any as Promise<SegmentationTemplate>;
    }

    deleteSegmentationTemplates(ids) {
        var query = ids.map(id => `id=${id}`).join('&');
        return this.httpClient.delete(`segmentation-templates?${query}`);
    }

    saveSegmentationTemplate(segmentationTemplate: SegmentationTemplate): Promise<SegmentationTemplate> {
        if (!segmentationTemplate.id)
            return this.httpClient.post('segmentation-templates/', segmentationTemplate) as any as Promise<SegmentationTemplate>;

        return this.httpClient.put(`segmentation-templates/${segmentationTemplate.id}`, segmentationTemplate) as any as Promise<SegmentationTemplate>;
    }
}
