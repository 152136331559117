import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import AmsCoaExport from './ams-coa-export';

@autoinject
export default class AmsCoaExportService {
    constructor(
        private httpClient: HttpClient) {
    }

    createAmsCoaExport(amsCoaExport: AmsCoaExport) {
        return this.httpClient.post('ams-coa-exports', amsCoaExport);
    }
}
