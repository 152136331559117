import {
    ColDefUtil,
    Grid,
    PropertyKeys,
} from 'ag-grid-enterprise/dist/ag-grid-enterprise';
import {
    bindable,
    BindingEngine,
    Container,
    inject,
    ViewCompiler,
} from 'aurelia-framework';

PropertyKeys.ALL_PROPERTIES = [
    ...PropertyKeys.ALL_PROPERTIES,
    'container',
    'viewCompiler',
    'overrideContext',
];

ColDefUtil.ALL_PROPERTIES = [
    ...ColDefUtil.ALL_PROPERTIES,
    'headerCellTemplate',
    'metadata',
];

@inject(Container, Element, ViewCompiler, BindingEngine)
class DataGrid {
    @bindable gridOptions;
    @bindable gridData;
    @bindable disableAutosizeColumns;
    @bindable saveColumnState;

    // HACK: Used to stop rebinding of aurelia components due to the fact that the select2 behaves undesirably and loses it's selection
    //       in any cell, including on rows other than rows that are added or removed.
    @bindable supressCellRenderRefresh;

    templateMap = new Map();
    headerTemplateMap = new Map();
    groupTemplateMap = new Map();
    minColumnStateColumnWidth = 50;

    constructor(container, element, viewCompiler, bindingEngine) {
        this.container = container;
        this.element = element;
        this.viewCompiler = viewCompiler;
        this.bindingEngine = bindingEngine;
        this.disableAutosizeColumns = false;

        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
    }

    get columnStateLocalStorageKey() {
        return `column-state.${this.saveColumnState}`;
    }

    handleMouseDown(event) {
        this.mousePressed = true;
    }

    handleMouseUp(event) {
        this.mousePressed = false;

        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
            this.scrollInterval = null;
        }
    }

    handleMouseMove(event) {
        if (!this.gridOptions.enableRangeSelection || !this.mousePressed)
            return;

        let threshold = 120;
        let gridViewportBoundingRectangle =
            this.gridViewport.getBoundingClientRect();

        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
            this.scrollInterval = null;
        }

        if (
            event.clientY <= gridViewportBoundingRectangle.top + threshold &&
            this.gridViewport.scrollTop !== 0
        ) {
            this.scrollInterval = setInterval(() => {
                this.gridViewport.scrollTop -=
                    10 *
                    ((60 +
                        gridViewportBoundingRectangle.top +
                        threshold -
                        event.clientY) /
                        60);

                if (this.gridViewport.scrollTop === 0)
                    clearInterval(this.scrollInterval);
            }, 50);
        } else if (
            event.clientY >= gridViewportBoundingRectangle.bottom - threshold &&
            this.gridViewport.scrollHeight - this.gridViewport.scrollTop !==
                this.gridViewport.clientHeight
        ) {
            this.scrollInterval = setInterval(() => {
                this.gridViewport.scrollTop +=
                    10 *
                    ((60 +
                        event.clientY -
                        (gridViewportBoundingRectangle.bottom - threshold)) /
                        60);

                if (
                    this.gridViewport.scrollHeight -
                        this.gridViewport.scrollTop ===
                    this.gridViewport.clientHeight
                )
                    clearInterval(this.scrollInterval);
            }, 50);
        }
    }

    bind(bindingContext, overrideContext) {
        this.overrideContext = overrideContext;
    }

    updateGridDataSubscription() {
        if (this.gridDataSubscription) {
            this.gridDataSubscription.dispose();
            this.gridDataSubscription = null;
        }

        if (!this.gridData) return;

        this.gridDataSubscription = this.bindingEngine
            .collectionObserver(this.gridData)
            .subscribe(this.gridDataCollectionChanged.bind(this));
    }

    gridDataChanged(supressSetRowData) {
        if (this.gridApi) {
            this.updateGridDataSubscription();
            this.refresh();
        }
    }

    gridDataCollectionChanged(changeRecords) {
        for (let changeRecord of changeRecords) {
            let rowDataTransaction = {};

            if (changeRecord.addedCount) {
                rowDataTransaction.addIndex = changeRecord.index;
                rowDataTransaction.add = [];

                for (
                    var i = changeRecord.index;
                    i < changeRecord.index + changeRecord.addedCount;
                    i++
                )
                    rowDataTransaction.add.push(this.gridData[i]);
            }

            if (changeRecord.removed && changeRecord.removed.length)
                rowDataTransaction.remove = changeRecord.removed;

            this.gridApi.applyTransaction(rowDataTransaction);
        }
    }

    refresh() {
        this.gridApi.setRowData(this.gridData);
    }

    gridOptionsChanged() {
        this.reinitialize();
    }

    reinitialize() {
        this.destroy();
        this.initialize();
    }

    initialize() {
        if (!this.gridOptions) return;

        this.gridOptions.suppressLoadingOverlay = true;
        this.gridOptions.suppressNoRowsOverlay = true;
        this.gridOptions.suppressColumnVirtualisation = true;
        this.gridOptions.suppressContextMenu = true;

        if (!this.gridOptions.rowHeight) this.gridOptions.rowHeight = 60;

        if (!this.gridOptions.headerHeight) this.gridOptions.headerHeight = 45;

        if (this.gridOptions.columnDefs)
            for (let columnDef of this.gridOptions.columnDefs) {
                let template =
                    columnDef.template ?? this.templateMap.get(columnDef);
                if (template) {
                    columnDef.cellRenderer = this.createCellRenderer(
                        this.container,
                        `<template>${template}</template>`,
                    );
                    this.templateMap.set(columnDef, template);
                    delete columnDef.template;
                } else if (
                    columnDef.field &&
                    !columnDef.valueFormatter &&
                    !columnDef.valueGetter
                ) {
                    columnDef.cellRenderer = this.createCellRenderer(
                        this.container,
                        "<template>${data['" +
                            columnDef.field?.replace("'", "\\'") +
                            "']}</template>",
                    );

                    if (!columnDef.tooltip && !columnDef.tooltipField)
                        columnDef.tooltipField = columnDef.field;
                }

                let headerCellTemplate =
                    columnDef.headerCellTemplate ??
                    this.headerTemplateMap.get(columnDef);
                if (headerCellTemplate) {
                    columnDef.headerComponent = this.createHeaderComponent(
                        this.container,
                        `<template>${headerCellTemplate}</template>`,
                    );
                    this.headerTemplateMap.set(columnDef, headerCellTemplate);
                    delete columnDef.headerCellTemplate;
                }

                let groupTemplate =
                    columnDef.groupTemplate ??
                    this.groupTemplateMap.get(columnDef);
                if (groupTemplate) {
                    this.gridOptions.groupRowRenderer =
                        this.createGroupRenderer(
                            this.container,
                            `<template>${groupTemplate}</template>`,
                        );
                    this.groupTemplateMap.set(columnDef, groupTemplate);
                    delete columnDef.groupTemplate;
                }

                if (!columnDef.headerTooltip && columnDef.headerName)
                    columnDef.headerTooltip = columnDef.headerName;
            }

        if (this.gridData) {
            this.gridOptions.rowData = this.gridData;
            this.updateGridDataSubscription();
        }

        this.gridOptions.container = this.container;
        this.gridOptions.viewCompiler = this.viewCompiler;
        this.gridOptions.overrideContext = this.overrideContext;

        if (this.saveColumnState) {
            const {
                onGridReady,
                onColumnMoved,
                onColumnResized,
                onSortChanged,
            } = this.gridOptions;
            this.gridOptions.onGridReady = () =>
                this.loadColumnStateFromLocalStorage(onGridReady);
            this.gridOptions.onColumnMoved = () =>
                this.saveColumnStateToLocalStorage(onColumnMoved);
            this.gridOptions.onColumnResized = () =>
                this.saveColumnStateToLocalStorage(onColumnResized);
            this.gridOptions.onSortChanged = () =>
                this.saveColumnStateToLocalStorage(onSortChanged);
        }

        new Grid(
            this.element,
            this.gridOptions,
            this.globalEventListener.bind(this),
        );

        this.gridApi = this.gridOptions.api;
        this.gridOptions.onFirstDataRendered = () =>
            this.gridApi.sizeColumnsToFit;

        setTimeout(() => {
            if (this.element.au['full-height'])
                this.element.au['full-height'].viewModel.setHeight();
        });

        if (this.gridOptions.enableRangeSelection) {
            this.gridViewport = this.element.querySelector('.ag-body-viewport');
            this.gridViewport.addEventListener(
                'mousedown',
                this.handleMouseDown,
            );
            this.gridViewport.addEventListener(
                'mousemove',
                this.handleMouseMove,
            );
        }
    }

    destroy() {
        if (this.gridDataSubscription) {
            this.gridDataSubscription.dispose();
            this.gridDataSubscription = null;
        }

        if (this.gridApi) {
            this.gridApi.destroy();
            this.gridApi = null;
        }

        if (this.gridViewport) {
            this.gridViewport.removeEventListener(
                'mousedown',
                this.handleMouseDown,
            );
            this.gridViewport.removeEventListener(
                'mousemove',
                this.handleMouseMove,
            );
        }
    }

    attached() {
        this.initialize();
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    globalEventListener(eventType, event) {
        this.element.dispatchEvent(
            new CustomEvent(eventType.toLowerCase(), {
                bubbles: true,
                detail: event,
            }),
        );
    }

    detached() {
        document.removeEventListener('mouseup', this.handleMouseUp);
        this.destroy();
    }

    createHeaderComponent(container, template) {
        let viewCompiler = this.viewCompiler;
        let overrideContext = this.overrideContext;

        return class CustomHeaderComponent {
            init(params) {
                this.eGui = document.createElement('div');

                var viewFactory = viewCompiler.compile(template);
                var view = viewFactory.create(container);
                view.bind(params, overrideContext);
                view.appendNodesTo(this.eGui);
            }

            getGui() {
                return this.eGui;
            }
        };
    }

    createCellRenderer(container, template) {
        var localSupressCellRenderRefresh = this.supressCellRenderRefresh;
        var localViewCompiler = this.viewCompiler;
        var localOverrideContext = this.overrideContext;
        return class CustomCellRenderer {
            constructor() {
                var viewFactory = localViewCompiler.compile(template);
                this.view = viewFactory.create(container);
                this.rootElement = document.createElement('div');
                this.rootElement.setAttribute(
                    'style',
                    'display: inline-block; width: 100%',
                );
            }

            init(params) {
                this.refresh(params);
                this.initialized = true;
            }

            getGui() {
                return this.rootElement;
            }

            refresh(params) {
                if (this.initialized && localSupressCellRenderRefresh) return;

                this.view.bind(params, localOverrideContext);
                this.view.appendNodesTo(this.rootElement);
                this.view.attached();
            }

            destroy() {
                this.view.unbind();
                this.view.removeNodes();
                this.view.detached();
            }
        };
    }

    createGroupRenderer(container, template) {
        var localViewCompiler = this.viewCompiler;
        var localOverrideContext = this.overrideContext;
        return class CustomGroupRenderer {
            constructor() {
                var viewFactory = localViewCompiler.compile(template);
                this.view = viewFactory.create(container);
                this.rootElement = document.createElement('span');
                this.rootElement.classList.add('group-head');
            }

            init(params) {
                this.refresh(params);
            }

            getGui() {
                return this.rootElement;
            }

            refresh(params) {
                this.view.bind(params, localOverrideContext);
                this.view.appendNodesTo(this.rootElement);
                this.view.attached();
            }

            destroy() {
                this.view.unbind();
                this.view.removeNodes();
                this.view.detached();
            }
        };
    }

    loadColumnStateFromLocalStorage(base) {
        if (!this.saveColumnState) {
            base?.call();
            return;
        }

        const columnStateString = localStorage.getItem(
            this.columnStateLocalStorageKey,
        );
        if (!columnStateString) {
            base?.call();
            return;
        }

        const columnState = JSON.parse(columnStateString);
        this.overrideInvisibleColumnWidths(columnState);
        this.gridOptions.columnApi.applyColumnState({
            state: columnState,
            applyOrder: true,
        });

        base?.call();
    }

    saveColumnStateToLocalStorage(base) {
        if (!this.saveColumnState) {
            base?.call();
            return;
        }

        if (this.saveColumnStateDebounce)
            clearTimeout(this.saveColumnStateDebounce);

        this.saveColumnStateDebounce = setTimeout(() => {
            this.saveColumnStateDebounce = null;

            const columnState = this.gridOptions.columnApi.getColumnState();
            const trimmedColumnState = columnState.map((c) => ({
                colId: c.colId,
                sort: c.sort,
                sortIndex: c.sortIndex,
                width: c.width,
            }));
            localStorage.setItem(
                this.columnStateLocalStorageKey,
                JSON.stringify(trimmedColumnState),
            );
        }, 1000);

        base?.call();
    }

    overrideInvisibleColumnWidths(columnState) {
        columnState.forEach((col) => {
            if (col.width && col.width < this.minColumnStateColumnWidth) {
                delete col.width;
            }
        });

        return columnState;
    }
}

export { DataGrid };
