import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import { buildAttributeQuery } from '../attribute-utility';
import PointFilters from './point-filters';

@autoinject
export default class PointService {
    constructor(private httpClient: HttpClient) {}

    getPoints(filters: PointFilters, view: 'summary' = null): Promise<any[]> {
        let attributeQuery = buildAttributeQuery(filters.pointAttributes, 'pointAttributes');
        let { ids, organizationIds, mapIds, pointId, planIds, sampleIds, recordStatus } = filters;
        let query = buildQueryString(
            { ids, organizationIds, mapIds, pointId, planIds, sampleIds, recordStatus },
            true,
        );

        let url = `location-testing/points?${query}&view=${view || ''}&${attributeQuery}`;
        return this.httpClient.get(url) as any as Promise<any[]>;
    }

    getPoint(id): Promise<any> {
        return this.httpClient.get(`location-testing/points/${id}`) as any;
    }

    deletePoints(ids) {
        var query = ids.map((id) => `id=${id}`).join('&');
        return this.httpClient.delete(`location-testing/points?${query}`);
    }

    savePoint(point, pointPhotoFile): Promise<any> {
        var formData = new FormData();

        if (point.id) formData.append('id', point.id);

        if (pointPhotoFile) formData.append('photo', pointPhotoFile);

        formData.append('name', point.name);
        formData.append('description', point.description || '');
        formData.append('room', point.room || '');
        formData.append('zone', point.zone);
        formData.append('mobile', point.mobile);
        formData.append('type', point.type || '');
        formData.append('risk', point.risk);
        formData.append('isActive', point.isActive);
        formData.append('mapId', point.mapId);
        formData.append('x', point.x);
        formData.append('y', point.y);

        for (var i = 0; i < point.attributes.length; i++) {
            formData.append(`attributes[${i}].name`, point.attributes[i].name);
            formData.append(`attributes[${i}].value`, point.attributes[i].value);
        }

        if (!point.id)
            return this.httpClient
                .createRequest('location-testing/points/')
                .asPost()
                .withContent(formData)
                .send();

        return this.httpClient
            .createRequest(`location-testing/points/${point.id}`)
            .asPut()
            .withContent(formData)
            .send();
    }

    getPointRecordChanges(id): Promise<any[]> {
        return this.httpClient.get(
            `location-testing/points/${id}/record-changes`,
        ) as any as Promise<any[]>;
    }

    getPointTests(id, filters): Promise<any[]> {
        var query = buildQueryString(filters, true);

        return this.httpClient.get(
            `location-testing/points/${id}/tests${query ? `?${query}` : ''}`,
        ) as any as Promise<any[]>;
    }

    getHasOverdueTasks(id): Promise<any> {
        return this.httpClient.get(`location-testing/points/${id}/has-overdue-tasks`);
    }

    getRiskCaption(risk) {
        switch (risk) {
            case 'NotSpecified':
                return 'Not Specified';

            case 'Low':
            case 'Medium':
            case 'High':
            default:
                return risk;
        }
    }
}
