import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import { CertificateContact } from './certificate-contact';

@autoinject
export default class ResultService {
    constructor(
        private httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getResults(filters): Promise<any[]> {
        var query = buildQueryString(filters, true);
        return this.httpClient.get(`results${(query ? `?${query}` : '')}`) as unknown as Promise<any[]>;
    }

    getResultsUrl(filters) {
        var query = buildQueryString(filters, true);
        return `api/results${(query ? `?${query}` : '')}`;
    }

    getCertificateUrl(requestId) {
        return `api/results/${requestId}/certificate`;
    }

    getCertificateContact(sampleIds: string[]): Promise<CertificateContact> {
        return this.httpClient.post('results/contact', sampleIds) as unknown as Promise<CertificateContact>;
    }
}
