import { GridOptions } from 'ag-grid-enterprise/dist/ag-grid-enterprise';
import { autoinject } from 'aurelia-framework';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import PageContext from '../../../infrastructure/page-context';
import { adhocReportSubscriptionGrid } from './adhoc-report-subscription-grid';
import { AdhocReportSubscriptionService } from './adhoc-report-subscription-service';
import { AdhocReportSubscription } from './contracts/adhoc-report-subscription';

@autoinject
export class AdhocReportSubscriptionList {
    subscriptions: Readonly<AdhocReportSubscription[]>;
    gridOptions: Readonly<GridOptions>;

    constructor(
        private adhocReportSubscriptionService: AdhocReportSubscriptionService,
        private pageContext: PageContext,
        private logger: Logger,
        private dialogPresenter: DialogPresenter,
    ) {
        this.subscriptions = [];
        this.gridOptions = adhocReportSubscriptionGrid;

        this.logger.name = 'ad-hoc-report-subscription-list';
    }

    async delete(id: number) {
        this.pageContext.isLoading = true;
        try {
            await this.adhocReportSubscriptionService.deleteAdhocReportSubscription(id);

            this.pageContext.showSuccessOverlay('The subscription was deleted successfully.');
        } catch (error) {
            this.logger.error('Error deleting ad-hoc subscription.', error, id);
            this.handleDeleteError();
        }

        await this.loadSubscriptions();

        this.pageContext.isLoading = false;
    }

    async start(id: number) {
        this.pageContext.isLoading = true;
        try {
            await this.adhocReportSubscriptionService.startAdhocReportSubscription(id);

            this.pageContext.showSuccessOverlay('The subscription was started successfully.');
        } catch (error) {
            this.logger.error('Error starting ad-hoc subscription.', error, id);
            this.handleStartError();
        }

        await this.loadSubscriptions();

        this.pageContext.isLoading = false;
    }

    async stop(id: number) {
        this.pageContext.isLoading = true;
        try {
            await this.adhocReportSubscriptionService.stopAdhocReportSubscription(id);

            this.pageContext.showSuccessOverlay('The subscription was stopped successfully.');
        } catch (error) {
            this.logger.error('Error stopping ad-hoc subscription.', error, id);
            this.handleStopError();
        }

        await this.loadSubscriptions();

        this.pageContext.isLoading = false;
    }

    async canActivate() {
        this.pageContext.isLoading = true;
        await this.loadSubscriptions();
    }

    bind() {
        this.pageContext.isLoading = false;
    }

    handleDeleteError() {
        this.dialogPresenter.showAlert(
            'Error Deleting Ad-Hoc Report Subscription',
            'An unexpected error occurred while trying to delete ad-hoc report subscription. Please try again later.',
        );
    }

    handleStartError() {
        this.dialogPresenter.showAlert(
            'Error Starting Ad-Hoc Report Subscription',
            'An unexpected error occurred while trying to start ad-hoc report subscription. Please try again later.',
        );
    }

    handleStopError() {
        this.dialogPresenter.showAlert(
            'Error Stopping Ad-Hoc Report Subscription',
            'An unexpected error occurred while trying to stop ad-hoc report subscription. Please try again later.',
        );
    }

    async loadSubscriptions() {
        this.subscriptions =
            await this.adhocReportSubscriptionService.getAdhocReportSubscriptions();
    }
}
