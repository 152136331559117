import PointRisk, { getPointRiskOptions } from './point-risk';
import SelectorOption from 'infrastructure/selector-option';

export default class PointRiskComparer {
    static compareValues(a: PointRisk, b: PointRisk) {
        if (a === null)
            return -1;

        if (b === null)
            return 1;

        let options: SelectorOption[] = getPointRiskOptions();

        let aIndex = options.findIndex(x => x.value === a);
        let bIndex = options.findIndex(x => x.value === b);

        return aIndex - bIndex;
    }
}
