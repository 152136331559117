import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { inject, PLATFORM } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { activationStrategy } from 'aurelia-router';
import 'foundation-datepicker-js';
import 'foundation-sites';
import $ from 'jquery';
import 'select2';
import FormChangeCheckStep from './infrastructure/form-change-check-step';
import OrganizationService from './organizations/organization-service';
import AuthorizationCheckStep from './security/navigation/authorization-check-step';
import NavigationAuthorizer from './security/navigation/navigation-authorizer';
import SecurityService from './security/security-service';

@inject(
    HttpClient,
    EventAggregator,
    DialogService,
    NavigationAuthorizer,
    SecurityService,
    OrganizationService,
)
export class App {
    constructor(
        httpClient,
        eventAggregator,
        dialogService,
        navigationAuthorizer,
        securityService,
        organizationService,
    ) {
        this.httpClient = httpClient;
        this.httpClient.configure((config) => config.withInterceptor(this));

        this.eventAggregator = eventAggregator;
        this.dialogService = dialogService;
        this.navigationAuthorizer = navigationAuthorizer;
        this.unauthenticatedStuffQueue = [];
        this.attachedPromise = new Promise((resolve) => {
            this.attachedPromiseResolve = resolve;
        });

        this.securityService = securityService;
        this.organizationService = organizationService;

        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleRouterNavigationSuccess = this.handleRouterNavigationSuccess.bind(this);
        this.updateIsCurrnetUserInternal();
        this.currentYear = new Date().getFullYear();
    }

    updateIsCurrnetUserInternal() {
        this.isCurrentUserInternal = this.securityService.isCurrentUserInternal();
    }

    handleLogin() {
        this.updateIsCurrnetUserInternal();
    }

    handleLogout() {
        this.updateIsCurrnetUserInternal();
    }

    handleRouterNavigationSuccess() {
        this.locationTestingRouteActive =
            this.router &&
            this.router.currentInstruction &&
            this.router.currentInstruction.fragment.toLowerCase().startsWith('/location-testing');
    }

    configureRouter(config, router) {
        this.router = router;

        config.addAuthorizeStep(AuthorizationCheckStep);
        config.addPreActivateStep(FormChangeCheckStep);

        config.mapUnknownRoutes((instruction) => {
            return { route: '', redirect: 'dashboard' };
        });

        config.map([
            // Dashboard
            {
                route: ['', 'dashboard'],
                name: 'dashboard',
                moduleId: PLATFORM.moduleName('dashboard/dashboard'),
                title: 'Dashboard',
                nav: true,
                icon: 'television',
                allowedUserTypes: 'both',
                ignorePermissions: true,
                activationStrategy: activationStrategy.invokeLifecycle,
            },

            // Requests
            {
                route: 'requests/:id',
                name: 'request-detail',
                moduleId: PLATFORM.moduleName('requests/request-detail'),
                title: 'Request Detail',
                href: '/requests/create',
                requiredPermission: 'EditRequests',
            },
            {
                route: 'requests',
                name: 'request-list',
                moduleId: PLATFORM.moduleName('requests/request-list'),
                title: 'Requests',
                nav: true,
                navTitle: 'Requests',
                href: '/requests',
                icon: 'flask',
                requiredPermission: 'EditRequests',
                authorize: () =>
                    this.organizationService.checkConfigurationFlag(
                        'allowStandardRequestSubmission',
                    ),
            },

            // Legal
            {
                route: 'privacy',
                name: 'privacy',
                moduleId: PLATFORM.moduleName('legal/privacy'),
                title: 'Privacy Policy',
                allowedUserTypes: 'both',
                ignorePermissions: true,
            },
            {
                route: 'terms',
                name: 'terms',
                moduleId: PLATFORM.moduleName('legal/terms'),
                title: 'Terms of Use',
                allowedUserTypes: 'both',
                ignorePermissions: true,
            },

            // Location Testing
            {
                route: 'location-testing/maps',
                name: 'map-list',
                moduleId: PLATFORM.moduleName('location-testing/maps/map-list'),
                title: 'Maps',
                nav: true,
                icon: 'emma',
                requiredPermission: 'ViewMaps',
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            {
                route: 'location-testing/maps/:id',
                name: 'map-detail',
                moduleId: PLATFORM.moduleName('location-testing/maps/map-detail'),
                title: 'Map Detail',
                requiredPermission: 'ViewMaps',
            },
            {
                route: 'location-testing/maps/:id/dashboard',
                name: 'map-dashboard',
                moduleId: PLATFORM.moduleName('location-testing/maps/map-dashboard'),
                title: 'Map Dashboard',
                requiredPermission: 'ViewMaps',
                group: 'location-testing',
                icon: 'map-o',
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            {
                route: 'location-testing/points',
                name: 'point-list',
                moduleId: PLATFORM.moduleName('location-testing/points/point-list'),
                title: 'Sample Points',
                requiredPermission: 'ViewPoints',
                group: 'location-testing',
                icon: 'map-pin',
            },
            {
                route: 'location-testing/points/:id',
                name: 'point-detail',
                moduleId: PLATFORM.moduleName('location-testing/points/point-detail'),
                title: 'Sample Point Detail',
                requiredPermission: 'EditPoints',
            },
            {
                route: 'location-testing/plans',
                name: 'plan-list',
                moduleId: PLATFORM.moduleName('location-testing/plans/plan-list'),
                title: 'Plans',
                requiredPermission: 'ViewPlans',
                group: 'location-testing',
                icon: 'clipboard',
            },
            {
                route: 'location-testing/plans/:id',
                name: 'plan-detail',
                moduleId: PLATFORM.moduleName('location-testing/plans/plan-detail'),
                title: 'Plan Detail',
                requiredPermission: 'EditPlans',
            },
            {
                route: 'location-testing/plans/:id/generate-tasks',
                name: 'plan-generate-tasks',
                moduleId: PLATFORM.moduleName('location-testing/plans/generate-tasks'),
                title: 'Generate Tasks',
                requiredPermission: 'EditPlans',
            },

            {
                route: 'location-testing/remediations',
                name: 'remediation-list',
                moduleId: PLATFORM.moduleName('location-testing/remediations/remediation-list'),
                title: 'Remediations',
                group: 'location-testing',
                icon: 'exclamation-triangle',
                requiredPermission: 'ViewAllRemediations,ViewMyRemediations',
            },
            {
                route: 'location-testing/remediations/:id',
                name: 'remediation-detail',
                moduleId: PLATFORM.moduleName('location-testing/remediations/remediation-detail'),
                title: 'Remediation Detail',
                requiredPermission: 'EditRemediations',
            },
            {
                route: 'location-testing/tasks',
                name: 'task-list',
                moduleId: PLATFORM.moduleName('location-testing/tasks/task-list'),
                title: 'Tasks',
                requiredPermission: 'ViewAllTasks,ViewMyTasks',
                group: 'location-testing',
                icon: 'tasks',
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            {
                route: 'location-testing/tasks/:id',
                name: 'task-detail',
                moduleId: PLATFORM.moduleName('location-testing/tasks/task-detail'),
                title: 'Task Detail',
                requiredPermission: 'EditTasks',
            },
            {
                route: 'location-testing/tasks/create-vector-tasks',
                name: 'create-vector-tasks',
                moduleId: PLATFORM.moduleName('location-testing/tasks/create-vector-tasks'),
                title: 'Create Vector Tasks',
                requiredPermission: 'EditTasks',
            },
            {
                route: 'location-testing/cap-plans',
                name: 'cap-plan-list',
                moduleId: PLATFORM.moduleName('location-testing/cap-plans/cap-plan-list'),
                title: 'CAP Plans',
                nav: true,
                group: 'settings',
                requiredPermission: 'ViewCapPlans',
            },
            {
                route: 'tenant/:tenantId/location-testing/cap-plans/:id?',
                name: 'cap-plan-detail',
                moduleId: PLATFORM.moduleName('location-testing/cap-plans/cap-plan-detail'),
                title: 'CAP Plan Detail',
                requiredPermission: 'EditCapPlans',
            },
            {
                route: 'location-testing/customer-test-imports',
                name: 'customer-test-import-list',
                moduleId: PLATFORM.moduleName(
                    'location-testing/customer-test-imports/customer-test-import-list',
                ),
                title: 'Test Data Imports',
                nav: true,
                group: 'settings',
                requiredPermission: 'ViewCustomerTestImports',
            },
            {
                route: 'location-testing/customer-test-imports/create',
                name: 'customer-test-import-detail',
                moduleId: PLATFORM.moduleName(
                    'location-testing/customer-test-imports/customer-test-import-detail',
                ),
                title: 'Import Test Data',
                requiredPermission: 'UploadCustomerTestData',
            },
            {
                route: 'location-testing/requests/create',
                name: 'location-testing-request-detail',
                moduleId: PLATFORM.moduleName('location-testing/requests/request-detail'),
                title: 'Create Request',
                requiredPermission: 'EditRequests',
            },

            // Status
            {
                route: 'status',
                name: 'status',
                moduleId: PLATFORM.moduleName('status/status'),
                title: 'Status',
                nav: true,
                icon: 'check-circle',
                allowedUserTypes: 'external',
                requiredPermission: 'ViewResults',
                activationStrategy: activationStrategy.invokeLifecycle,
            },

            // Reports
            {
                route: 'reporting/reports',
                name: 'report-list',
                moduleId: PLATFORM.moduleName('reporting/reports/report-list'),
                title: 'Built-In Reports',
                nav: true,
                icon: 'bar-chart',
                allowedUserTypes: 'external',
                requiredPermission: 'ViewReports',
            },
            {
                route: 'adhoc-report-subscription-list',
                name: 'adhoc-report-list',
                moduleId: PLATFORM.moduleName(
                    'reporting/adhoc-reports/adhoc-report-subscriptions/adhoc-report-subscription-list',
                ),
                title: 'Ad-Hoc Reports',
                nav: true,
                icon: 'pie-chart',
                allowedUserTypes: 'external',
                requiredPermission: 'ViewReports',
            },
            {
                route: 'reporting/reports/:id',
                name: 'report-detail',
                moduleId: PLATFORM.moduleName('reporting/reports/report-detail'),
                title: 'Report Detail',
                allowedUserTypes: 'external',
                requiredPermission: 'ViewReports',
            },
            {
                route: 'reporting/adhoc-reports/:id',
                name: 'adhoc-report-detail',
                moduleId: PLATFORM.moduleName('reporting/adhoc-reports/adhoc-report-detail'),
                title: 'Ad-hoc Report Detail',
                allowedUserTypes: 'external',
                requiredPermission: 'RunAdhocReports',
            },
            {
                route: 'reporting/adhoc-reports/adhoc-report-subscriptions',
                name: 'adhoc-report-subscription-list',
                moduleId: PLATFORM.moduleName(
                    'reporting/adhoc-reports/adhoc-report-subscriptions/adhoc-report-subscription-list',
                ),
                title: 'Ad-hoc Report Subscriptions',
                allowedUserTypes: 'external',
                requiredPermission: 'RunAdhocReports',
            },
            {
                route: 'reporting/adhoc-reports/adhoc-report-subscriptions/:id',
                name: 'edit-adhoc-report-subscription',
                moduleId: PLATFORM.moduleName(
                    'reporting/adhoc-reports/adhoc-report-subscriptions/adhoc-report-subscription-edit',
                ),
                title: 'Edit Report Subscription',
                allowedUserTypes: 'external',
                requiredPermission: 'RunAdhocReports',
            },
            {
                route: 'reporting/adhoc-reports/adhoc-report-subscription',
                name: 'create-adhoc-report-subscription',
                moduleId: PLATFORM.moduleName(
                    'reporting/adhoc-reports/adhoc-report-subscriptions/adhoc-report-subscription-create',
                ),
                title: 'Create Report Subscription',
                allowedUserTypes: 'external',
                requiredPermission: 'RunAdhocReports',
            },

            // Settings
            {
                route: 'settings',
                name: 'settings',
                moduleId: PLATFORM.moduleName('organizations/settings'),
                title: 'Settings',
                nav: true,
                group: 'settings',
                allowedUserTypes: 'external',
                requiredPermission: 'ViewOrganizationSettings',
            },
            {
                route: 'roles',
                name: 'role-list',
                moduleId: PLATFORM.moduleName('roles/role-list'),
                title: 'Manage Roles',
                nav: true,
                group: 'settings',
                requiredPermission: 'ViewRoles',
            },
            {
                route: 'roles/:id',
                name: 'role-detail',
                moduleId: PLATFORM.moduleName('roles/role-detail'),
                title: 'Role Detail',
                requiredPermission: 'EditRoles',
            },
            {
                route: 'location-testing/plan-management',
                name: 'plan-management',
                moduleId: PLATFORM.moduleName(
                    'location-testing/plan-management/plan-management-list',
                ),
                title: 'Plan Management',
                nav: true,
                group: 'settings',
                requiredPermission: 'ViewPlanManagement',
            },
            {
                route: 'roles/:id/users',
                name: 'role-move-users',
                moduleId: PLATFORM.moduleName('roles/role-move-users'),
                title: 'Reassign User Roles',
                requiredPermission: 'AssignRoles',
            },

            {
                route: 'organizations',
                name: 'organization-list',
                moduleId: PLATFORM.moduleName('organizations/organization-list'),
                title: 'Manage Organizations',
                nav: true,
                group: 'settings',
                allowedUserTypes: 'internal',
            },
            {
                route: 'organizations/:id',
                name: 'organization-detail',
                moduleId: PLATFORM.moduleName('organizations/organization-detail'),
                title: 'Organization Detail',
                allowedUserTypes: 'internal',
            },

            {
                route: 'test-methods',
                name: 'test-method-list',
                moduleId: PLATFORM.moduleName('test-methods/test-method-list'),
                title: 'Latest Price Quotes',
                allowedUserTypes: 'external',
                requiredPermission: 'ViewPriceQuotes',
            },
            {
                route: 'location-testing/test-method-management',
                name: 'test-method-management',
                moduleId: PLATFORM.moduleName(
                    'location-testing/test-method-management/test-method-management-list',
                ),
                title: 'Test Method Management',
                nav: true,
                group: 'settings',
                requiredPermission: 'ViewTestMethodManagement',
            },

            {
                route: 'specifications',
                name: 'specification-list',
                moduleId: PLATFORM.moduleName('specifications/specification-list'),
                title: 'Manage Specifications',
                nav: true,
                group: 'settings',
                requiredPermission: 'ViewSpecifications',
            },
            {
                route: 'specifications/:id',
                name: 'specification-detail',
                moduleId: PLATFORM.moduleName('specifications/specification-detail'),
                title: 'Specification Detail',
                requiredPermission: 'EditSpecifications',
            },
            {
                route: 'remediation-root-causes',
                name: 'remediation-root-cause-list',
                moduleId: PLATFORM.moduleName(
                    'remediation-root-causes/remediation-root-cause-list',
                ),
                title: 'Manage Remediation Root Causes',
                nav: true,
                group: 'settings',
                requiredPermission: 'ViewAllRemediations',
            },

            {
                route: 'password-requirements',
                name: 'password-requirements',
                moduleId: PLATFORM.moduleName('password-requirements/password-requirements'),
                title: 'Password Requirements',
                nav: true,
                group: 'settings',
                requiredPermission: 'ViewPasswordRequirements',
            },
            {
                route: 'users',
                name: 'user-list',
                moduleId: PLATFORM.moduleName('users/user-list'),
                title: 'Manage Users',
                allowedUserTypes: 'both',
                nav: true,
                group: 'settings',
                requiredPermission: 'ViewUsers',
            },
            {
                route: 'users/:id',
                name: 'user-detail',
                moduleId: PLATFORM.moduleName('users/user-detail'),
                title: 'User Detail',
                allowedUserTypes: 'both',
                requiredPermission: 'ViewUsers',
            },
            {
                route: 'login-audit',
                name: 'login-audit',
                moduleId: PLATFORM.moduleName('login-audit/login-audit'),
                title: 'User Login Audit',
                nav: true,
                group: 'settings',
                requiredPermission: 'ViewUserLogins',
            },
            {
                route: 'login-audit/user/:id',
                name: 'login-audit/user',
                moduleId: PLATFORM.moduleName('login-audit/login-audit-detail'),
                title: 'Login Audit User Detail',
                requiredPermission: 'ViewUserLogins',
            },
            {
                route: 'labs',
                name: 'lab-list',
                moduleId: PLATFORM.moduleName('labs/lab-list'),
                title: 'Labs',
                allowedUserTypes: 'both',
                ignorePermissions: true,
                nav: true,
                group: 'settings',
            },
            {
                route: 'labs/:id',
                name: 'lab-detail',
                moduleId: PLATFORM.moduleName('labs/lab-detail'),
                title: 'Lab Detail',
                allowedUserTypes: 'internal',
            },

            {
                route: 'news-items',
                name: 'news-item-list',
                moduleId: PLATFORM.moduleName('news-items/news-item-list'),
                title: 'News Items',
                allowedUserTypes: 'internal',
                nav: true,
                group: 'settings',
            },
            {
                route: 'news-items/:id',
                name: 'news-item-detail',
                moduleId: PLATFORM.moduleName('news-items/news-item-detail'),
                title: 'News Item Detail',
                allowedUserTypes: 'internal',
            },

            {
                route: 'label-templates',
                name: 'label-template-list',
                moduleId: PLATFORM.moduleName('label-templates/label-template-list'),
                title: 'Label Templates',
                requiredPermission: 'ViewLabelTemplates',
                nav: true,
                group: 'settings',
            },
            {
                route: 'label-templates/:id',
                name: 'label-template-detail',
                moduleId: PLATFORM.moduleName('label-templates/label-template-detail'),
                title: 'Label Template Detail',
                requiredPermission: 'EditLabelTemplates',
            },

            {
                route: 'export-task-collection-templates',
                name: 'export-task-collection-template-list',
                moduleId: PLATFORM.moduleName(
                    'export-task-collection-templates/export-task-collection-template-list',
                ),
                title: 'Export Task Collection Templates',
                requiredPermission: 'ViewExportTaskCollectionTemplates',
                nav: true,
                group: 'settings',
                activationStrategy: activationStrategy.invokeLifecycle,
            },
            {
                route: 'export-task-collection-templates/:id',
                name: 'export-task-collection-template-detail',
                moduleId: PLATFORM.moduleName(
                    'export-task-collection-templates/export-task-collection-template-detail',
                ),
                title: 'Export Task Collection Template Detail',
                requiredPermission: 'EditExportTaskCollectionTemplates',
            },

            {
                route: 'segmentation-templates',
                name: 'segmentation-template-list',
                moduleId: PLATFORM.moduleName('segmentation-templates/segmentation-template-list'),
                title: 'Segmentation Templates',
                requiredPermission: 'ViewSegmentationTemplates',
                nav: true,
                group: 'settings',
                authorize: () =>
                    this.organizationService.checkConfigurationFlag('hasSegmentAccess'),
            },
            {
                route: 'segmentation-templates/:id',
                name: 'segmentation-template-detail',
                moduleId: PLATFORM.moduleName(
                    'segmentation-templates/segmentation-template-detail',
                ),
                title: 'Segmentation Template Detail',
                requiredPermission: 'EditSegmentationTemplates',
            },

            // Help Pages
            {
                route: 'help/glossary',
                name: 'glossary',
                moduleId: PLATFORM.moduleName('help/glossary/glossary'),
                title: 'Glossary',
                nav: true,
                group: 'help',
                allowedUserTypes: 'external',
                ignorePermissions: true,
                icon: 'book',
            },
            {
                route: 'help/glossary-terms',
                name: 'glossary-term-list',
                moduleId: PLATFORM.moduleName('help/glossary/glossary-term-list'),
                title: 'Manage Glossary Terms',
                nav: true,
                group: 'help',
                allowedUserTypes: 'internal',
                icon: 'book',
            },
            {
                route: 'help/glossary-terms/:id',
                name: 'glossary-term-detail',
                moduleId: PLATFORM.moduleName('help/glossary/glossary-term-detail'),
                title: 'Glossary Term Detail',
                allowedUserTypes: 'internal',
            },
            {
                route: 'help/user-guides',
                name: 'user-guide-list',
                moduleId: PLATFORM.moduleName('help/user-guides/user-guide-list'),
                title: 'Guides',
                nav: true,
                group: 'help',
                allowedUserTypes: 'both',
                ignorePermissions: true,
                icon: 'file-text-o',
            },
            {
                route: 'help/user-guides/:id',
                name: 'user-guide-detail',
                moduleId: PLATFORM.moduleName('help/user-guides/user-guide-detail'),
                title: 'Guide',
                allowedUserTypes: 'both',
                ignorePermissions: true,
            },
            {
                route: 'help/help-pages/:code',
                name: 'help-page-detail',
                moduleId: PLATFORM.moduleName('help/user-guides/user-guide-detail'),
                title: 'Help Page',
                allowedUserTypes: 'both',
                ignorePermissions: true,
            },
            {
                route: 'help/videos',
                name: 'video-list',
                moduleId: PLATFORM.moduleName('help/videos/video-list'),
                title: 'Videos',
                nav: true,
                group: 'help',
                allowedUserTypes: 'both',
                ignorePermissions: true,
                icon: 'play-circle',
            },
            {
                route: 'help/videos/:id',
                name: 'video-detail',
                moduleId: PLATFORM.moduleName('help/videos/video-detail'),
                title: 'Video',
                allowedUserTypes: 'both',
                ignorePermissions: true,
            },
            {
                route: 'help/support',
                name: 'support',
                moduleId: PLATFORM.moduleName('help/support/support'),
                title: 'Support',
                nav: true,
                group: 'help',
                allowedUserTypes: 'external',
                ignorePermissions: true,
                icon: 'envelope-o',
            },
            {
                route: 'help/support-topics',
                name: 'support-topic-list',
                moduleId: PLATFORM.moduleName('help/support/support-topic-list'),
                title: 'Manage Support Topics',
                nav: true,
                group: 'help',
                allowedUserTypes: 'internal',
                icon: 'envelope-o',
            },
            {
                route: 'help/support-topics/:id',
                name: 'support-topic-detail',
                moduleId: PLATFORM.moduleName('help/support/support-topic-detail'),
                title: 'Support Topic Detail',
                allowedUserTypes: 'internal',
            },
            {
                route: 'help/faq-groups',
                name: 'faq-group-list',
                moduleId: PLATFORM.moduleName('help/faqs/faq-group-list'),
                title: 'Manage FAQ Groups',
                nav: true,
                group: 'help',
                allowedUserTypes: 'internal',
                icon: 'question-circle-o',
            },
            {
                route: 'help/faq-groups/:id',
                name: 'faq-group-detail',
                moduleId: PLATFORM.moduleName('help/faqs/faq-group-detail'),
                title: 'FAQ Group Detail',
                allowedUserTypes: 'internal',
            },
            {
                route: 'help/faq-groups/:id/faqs',
                name: 'faq-list',
                moduleId: PLATFORM.moduleName('help/faqs/faq-list'),
                title: 'Manage FAQs',
                allowedUserTypes: 'internal',
            },
            {
                route: 'help/faqs/:id',
                name: 'faq-detail',
                moduleId: PLATFORM.moduleName('help/faqs/faq-detail'),
                title: 'FAQ Detail',
                allowedUserTypes: 'internal',
            },
            {
                route: 'help/faqs',
                name: 'faqs',
                moduleId: PLATFORM.moduleName('help/faqs/faqs'),
                title: 'FAQs',
                nav: true,
                group: 'help',
                allowedUserTypes: 'external',
                ignorePermissions: true,
                icon: 'question-circle-o',
            },

            // AMS CoA Exports
            {
                route: 'ams-coa-exports/create',
                name: 'ams-coa-export-create',
                moduleId: PLATFORM.moduleName('ams-coa-exports/create-ams-coa-export'),
                title: 'AMS CoA Export',
                nav: true,
                group: 'settings',
                allowedUserTypes: 'internal',
                icon: 'file-export',
            },

            // Test Results
            {
                route: 'test-results/test-results-list',
                name: 'test-results-list',
                moduleId: PLATFORM.moduleName('test-results/test-results-list'),
                title: 'Test Results',
                nav: true,
                icon: 'thermometer-half',
                allowedUserTypes: 'external',
                requiredPermission: 'ViewResults',
                activationStrategy: activationStrategy.invokeLifecycle,
            },

            // Partial Test Results
            {
                route: 'partial-test-results/partial-test-results-list',
                name: 'partial-test-results-list',
                moduleId: PLATFORM.moduleName('partial-test-results/partial-test-results-list'),
                title: 'Partial Test Results',
                nav: true,
                icon: 'list-alt',
                allowedUserTypes: 'external',
                requiredPermission: 'ViewPartialResults',
                activationStrategy: activationStrategy.invokeLifecycle,
                authorize: async () => {
                    const { organizationId } = this.securityService.getEffectiveUser();
                    const { partialTestResultLabs } =
                        await this.organizationService.getConfigurationValues(
                            ['partialTestResultLabs'],
                            organizationId,
                        );

                    return partialTestResultLabs?.length > 0;
                },
            },
        ]);
    }

    attached() {
        this.userLoginSubscription = this.eventAggregator.subscribe('user.login', this.handleLogin);
        this.userLogoutSubscription = this.eventAggregator.subscribe(
            'user.logout',
            this.handleLogout,
        );
        this.routerNavigationSuccessSubscription = this.eventAggregator.subscribe(
            'router:navigation:success',
            this.handleRouterNavigationSuccess,
        );

        (async () => {
            this.baseNavItems = [];

            for (let item of this.router.navigation)
                if (!item.config.group && (await this.navigationAuthorizer.hasAccess(item.config)))
                    this.baseNavItems.push(item);

            this.handleRouterNavigationSuccess();

            this.attachedPromiseResolve();

            // Activate off canvas menu on hover.
            $(() => {
                $('.off-canvas.position-left li').hover(
                    () => $('.off-canvas.position-left').addClass('is-transition-push is-open'),
                    () => $('.off-canvas.position-left').removeClass('is-transition-push is-open'),
                );
            });
        })();
    }

    detached() {
        this.userLoginSubscription && this.userLoginSubscription.dispose();
        this.userLogoutSubscription && this.userLogoutSubscription.dispose();
        this.routerNavigationSuccessSubscription &&
            this.routerNavigationSuccessSubscription.dispose();
    }

    responseError(message) {
        if (message.statusCode !== 401 || message.requestMessage.url === 'sessions/') throw message;

        if (this.securityService.isImpersonating()) {
            this.securityService.logout(true);
            throw message;
        }

        if (this.loginDialogVisible)
            return new Promise((resolve, reject) => {
                this.unauthenticatedStuffQueue.push({
                    message,
                    resolve,
                    reject,
                });
            });

        return new Promise((resolve, reject) => {
            this.unauthenticatedStuffQueue.push({ message, resolve, reject });
            this.loginDialogVisible = true;

            // The dialog must wait until the app's template has been attached.
            // If it doesn't wait then, when the app template loads, the dialog will be removed.
            this.attachedPromise.then(() => {
                this.dialogService
                    .open({
                        viewModel: PLATFORM.moduleName('security/login-dialog'),
                    })
                    .whenClosed((response) => {
                        this.loginDialogVisible = false;

                        if (response.wasCancelled) {
                            while (this.unauthenticatedStuffQueue.length) {
                                let item = this.unauthenticatedStuffQueue.shift();
                                item.reject(item.message);
                            }
                        } else {
                            while (this.unauthenticatedStuffQueue.length) {
                                let item = this.unauthenticatedStuffQueue.shift();
                                this.httpClient
                                    .send(item.message.requestMessage)
                                    .then((successMessage) => {
                                        item.resolve(successMessage);
                                    })
                                    .catch((errorMessage) => {
                                        item.reject(errorMessage);
                                    });
                            }
                        }
                    });
            });
        });
    }
}
