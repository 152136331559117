import $ from 'jquery';
import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import PageContext from '../../infrastructure/page-context';
import Logger from '../../infrastructure/logger';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import CompareUtility from '../../infrastructure/compare-utility';
import SecurityService from '../../security/security-service';
import UserGuideService from './user-guide-service';

@inject(Element, Router, PageContext, Logger, DialogPresenter, SecurityService, UserGuideService)
export default class UserGuideList {
    @observable filterText;

    constructor(element, router, pageContext, logger, dialogPresenter, securityService, userGuideService) {
        this.element = element;
        this.router = router;
        this.pageContext = pageContext;
        this.logger = logger;
        this.logger.name = 'user-guide-list';
        this.dialogPresenter = dialogPresenter;
        this.securityService = securityService;
        this.userGuideService = userGuideService;
        this.filterText = '';

        this.editMode = this.securityService.isCurrentUserInternal() && !this.securityService.isImpersonating();

        if (this.editMode) {
            this.gridOptions = {
                columnDefs: [
                    {
                        suppressMenu: true,
                        template: '<label><input type="checkbox" checked.bind="data.isSelected" change.trigger="isSelectedChanged()" disabled.bind="data.isHelpPage" title="${data.isHelpPage ? \'Cannot delete core help pages\' : \'\'}"></label>',
                        headerCellTemplate: '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                        headerClass: 'checkbox',
                        width: 80,
                        sortable: false,
                        suppressSizeToFit: true
                    },
                    { suppressMenu: true, headerName: 'Title', field: 'title', comparator: CompareUtility.compareStringsInsensitive, width: 150, sort: 'asc' },
                    { suppressMenu: true, headerName: 'Description', field: 'description', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                    {
                        suppressMenu: true,
                        headerName: 'Core',
                        field: 'isHelpPage',
                        template: '<i class="fa fa-check" if.bind="data.isHelpPage"></i>',
                        width: 120,
                        headerClass: 'text-center',
                        cellClass: 'medium-text-center',
                        suppressSizeToFit: true
                    },
                    {
                        suppressMenu: true,
                        headerName: 'Active',
                        field: 'isActive',
                        template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                        width: 120,
                        headerClass: 'text-center',
                        cellClass: 'medium-text-center',
                        suppressSizeToFit: true
                    },
                    {
                        suppressMenu: true,
                        headerName: '',
                        template: 
                            '<button click.delegate="navigateToUserGuideDetail(data.id)">' +
                            '    <i class="fa fa-pencil-square-o"></i>' +
                            '</button>' +
                            '<button click.trigger="deleteUserGuide(data)" disabled.bind="!data.isActive || data.isHelpPage" title="${data.isHelpPage ? \'Cannot delete core help pages\' : \'\'}">' +
                            '    <i class="fa fa-trash-o"></i>' +
                            '</button>',
                        cellClass: 'medium-text-right row-actions',
                        headerCellTemplate: '',
                        headerClass: 'row-actions',
                        width: 100,
                        sortable: false,
                        suppressSizeToFit: true
                    },
                ],
                defaultColDef: { sortable: true, resizable: true },
            };

            this.allSelected = false;
            this.selectedActiveUserGuides = [];
        }
    }

    navigateToUserGuideDetail(id) {
        this.router.navigateToRoute('user-guide-detail', { id });
    }

    setSelectedActiveUserGuides() {
        this.selectedActiveUserGuides = this.userGuidesView.filter(userGuide => userGuide.isSelected && userGuide.isActive);
    }

    isSelectedChanged() {
        this.allSelected = this.userGuidesView.every(userGuide => userGuide.isSelected);
        this.setSelectedActiveUserGuides();
    }

    allSelectedClicked() {
        if (this.userGuidesView) {
            this.allSelected = !this.userGuidesView.every(userGuide => userGuide.isSelected);

            for (let userGuide of this.userGuidesView)
                userGuide.isSelected = this.allSelected;
        }

        this.setSelectedActiveUserGuides();

        return true;
    }

    filterTextChanged() {
        this.updateUserGuidesView();
    }

    updateUserGuidesView() {
        if (!this.userGuides)
            return;

        var lowerCasedFilterText = this.filterText.toLowerCase();
        this.userGuidesView = this.userGuides.filter(u =>
            (u.title || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
            (u.description || '').toLowerCase().indexOf(lowerCasedFilterText) > -1
        );
    }

    activate() {
        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.userGuides = await this.userGuideService.getUserGuides();
                this.pageContext.isLoading = false;
                this.updateUserGuidesView();
            } catch (error) {
                this.logger.error('Error while loading user guides', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading User Guides',
                    'An error occurred while loading the user guides. Please try again later.');
            }
        })();
    }

    deleteUserGuide(userGuide) {
        this.dialogPresenter
            .showConfirmation('Delete User Guide', 'Are you sure you want to delete this user guide?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                this.pageContext.isLoading = true;
                this.userGuideService
                    .deleteUserGuides([userGuide.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('User guide deleted successfully.');

                        userGuide.isSelected = false;
                        userGuide.isActive = false;

                        this.setSelectedActiveUserGuides();
                    })
                    .catch(error => {
                        this.logger.error('Error deleting user guide.', error, { userGuideId: userGuide.id });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error);
                    });
            });
    }

    deleteSelectedUserGuides() {
        var userGuidesToDelete = this.userGuidesView.filter(u => u.isSelected);
        if (!userGuidesToDelete.length)
            return;

        this.dialogPresenter
            .showConfirmation('Delete Selected User Guides', 'Are you sure you want to delete the selected user guides?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                var userGuideIds = userGuidesToDelete.map(userGuide => userGuide.id);

                this.pageContext.isLoading = true;
                this.userGuideService
                    .deleteUserGuides(userGuideIds)
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Selected users deleted successfully.');

                        for (let userGuide of userGuidesToDelete) {
                            userGuide.isSelected = false;
                            userGuide.isActive = false;

                            this.setSelectedActiveUserGuides();
                        }
                    })
                    .catch(error => {
                        this.logger.error('Error deleting user guides.', error, { userGuideIds });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, true);
                    });
            });
    }

    handleDeleteError(error, multipleUserGuides) {
        this.dialogPresenter
            .showAlert(
            'Error Deleting User Guide' + (multipleUserGuides ? 's' : ''),
            'An unexpected error occurred while trying to delete user guide' + (multipleUserGuides ? 's' : '') + '. Please try again later.');
    }
};
