import { bindable, inject } from 'aurelia-framework';
import Chart from 'chart.js';

Chart.defaults.global.responsive = true;
Chart.defaults.global.defaultFontFamily = 'Raleway,sans-serif';

@inject(Element)
export class MiniDoughnutChart {

    @bindable chartData;

    constructor(element) {
        this.element = element;
    }

    chartDataChanged() {
        this.applyChartData();
    }

    attached() {
        this.chartCanvas = this.element.querySelector('canvas');
        this.doughnutChart = new Chart(this.chartCanvas.getContext('2d'), {
            type: 'doughnut',
            options: {
                animation: {
                    duration: 2000
                },
                cutoutPercentage: 75,
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false,
                }
            }
        });

        this.applyChartData();
    }

    applyChartData() {
        if (!this.chartData || !this.doughnutChart)
            return;

        this.doughnutChart.data = {
            labels: [],
            datasets: [{
                backgroundColor: [this.chartData.fillColor],
                borderWidth: 1,
                borderColor: '#543c44',
                data: [this.chartData.value, this.chartData.total - this.chartData.value]
            }] 
        };

        this.doughnutChart.update();
    }

    detached() {
        if (this.doughnutChart)
            this.doughnutChart.destroy();
    }
};
