import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';

@inject(HttpClient)
export default class OrganizationSampleTemplateService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getOrganizationSampleTemplates(organizationId) {
        return this.httpClient.get(`organization-sample-templates?organizationIds=${organizationId}`);
    }
}
