import { ValidationRules } from 'aurelia-validation';
import { FilterField } from './filter-field';
import { FilterOperator } from './filter-operator';

export class NumberFilter {
    operators: FilterOperator[];
    filterField: FilterField;

    constructor() {
        this.operators = [
            { title: 'equals', value: 'equals' },
            { title: 'is not equal to', value: 'not-equals' },
            { title: 'is less than', value: 'less-than' },
            { title: 'is less than or equal to', value: 'less-than-or-eqauls' },
            { title: 'is greater than', value: 'greater-than' },
            { title: 'is greater than or equal to', value: 'greater-than-or-eqauls' },
        ];
    }

    get value() {
        return this.filterField && this.filterField.values[0];
    }
    set value(value) {
        if (this.filterField)
            this.filterField.values[0] = value;
    }

    activate(filterField: FilterField) {
        this.filterField = filterField;

        // When switching field types, the operator may not be available. If not, default to the first one.
        if (!this.operators.some(o => o.value === this.filterField.operator))
            this.filterField.operator = this.operators[0].value;

        ValidationRules
            .ensure('value')
            .required()
            .on(this);
    }

    detached() {
        ValidationRules.off(this);
    }
}
