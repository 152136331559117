import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class FaqService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getFaqs(faqGroupId) {
        return this.httpClient.get(`help/faqs/${(faqGroupId ? `?faqGroupId=${faqGroupId}` : '')}`);
    }

    getFaq(id) {
        return this.httpClient.get(`help/faqs/${id}`);
    }

    deleteFaqs(ids) {
        var query = ids.map(id => `id=${id}`).join('&');
        return this.httpClient.delete(`help/faqs?${query}`);
    }

    saveFaq(faq) {
        if (!faq.id)
            return this.httpClient.post('help/faqs/', faq);

        return this.httpClient.put(`help/faqs/${faq.id}`, faq);
    }

    updateFaqOrdinals(idOrdinalMap) {
        return this.httpClient.patch(`help/faqs/`, idOrdinalMap);
    }
}
