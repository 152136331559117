import { GridOptions, ValueGetterParams } from 'ag-grid-enterprise/dist/ag-grid-enterprise';
import { toCollectionTypeTitle } from 'location-testing/collection-type';
import { toOperationsTiming } from 'location-testing/operations-timing-helper';

export function getCapPlanGridOptions(): GridOptions {
    return {
        columnDefs: [
            {
                suppressMenu: true,
                headerName: 'Name',
                field: 'name',
                cellRenderer: 'agGroupCellRenderer',
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
            },
            {
                suppressMenu: true,
                headerName: 'Organization',
                field: 'organizationName',
            },
            {
                suppressMenu: true,
                headerName: 'Collection Type',
                field: 'collectionType',
                headerClass: 'text-center',
                cellClass: 'medium-text-left',
                cellRenderer: (params) => {
                    return `<span>${toCollectionTypeTitle(params.value)}</span>`;
                },
            },
            {
                suppressMenu: true,
                headerName: 'Auto Remediation',
                field: 'autoRemediation',
                headerClass: 'text-center',
                cellClass: 'medium-text-center',
                cellRenderer: (params) => {
                    return params.value ? '<i class="fa fa-check"></i>' : '';
                },
            },
            {
                suppressMenu: true,
                headerName: 'Deactivate Routine Task Generation',
                field: 'deactivateRoutineTaskGeneration',
                headerClass: 'text-center',
                cellClass: 'medium-text-center',
                cellRenderer: (params) => {
                    return params.value ? '<i class="fa fa-check"></i>' : '';
                },
            },
            {
                suppressMenu: true,
                headerName: 'Assigned User',
                field: 'autoRemediationAssignedUserFullName',
            },
            {
                suppressMenu: true,
                headerName: 'Approval User',
                field: 'autoRemediationApprovalUserFullName',
            },
            {
                suppressMenu: true,
                headerName: 'Active',
                field: 'isActive',
                width: 120,
                headerClass: 'text-center',
                cellClass: 'medium-text-center',
                suppressSizeToFit: true,
                cellRenderer: (params) => {
                    return params.value ? '<i class="fa fa-check"></i>' : '';
                },
            },
            {
                suppressMenu: true,
                cellClass: 'medium-text-right',
                width: 150,
                sortable: false,
                suppressSizeToFit: true,
                valueGetter: (params: ValueGetterParams) => {
                    const { organizationId, id, isActive } = params.data;

                    return {
                        organizationId,
                        id,
                        isActive,
                    };
                },
                cellRenderer: (params) => {
                    const { organizationId, id, isActive } = params.value;
                    if (!isActive) {
                        return '<div class="row-actions-flex></div>';
                    }

                    return `
                <div class="row-actions-flex">
                    <a href="#/tenant/${organizationId}/location-testing/cap-plans/${id}">
                        <i class="fa fa-pencil-square-o"></i>
                    </a>
                </div>
                `;
                },
            },
        ],
        masterDetail: true,
        detailCellRendererParams: {
            detailGridOptions: {
                columnDefs: [
                    {
                        suppressMenu: true,
                        headerName: 'Test Method',
                        field: 'testMethodBillCode',
                    },
                    {
                        suppressMenu: true,
                        headerName: 'Plan',
                        field: 'planName',
                    },
                    {
                        suppressMenu: true,
                        headerName: 'Task Operations Timing',
                        field: 'taskOperationsTiming',
                        cellRenderer: (params) => {
                            return `<span>${toOperationsTiming(params.value)}</span>`;
                        },
                    },
                    {
                        suppressMenu: true,
                        headerName: 'Point Type',
                        field: 'pointType',
                    },
                    {
                        suppressMenu: true,
                        headerName: 'Point Zone',
                        field: 'pointZone',
                    },
                    {
                        suppressMenu: true,
                        headerName: 'Room',
                        field: 'room',
                    },
                ],
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                params.successCallback(params.data.emmaAttributes);
            },
        },
        defaultColDef: { sortable: true, resizable: true, flex: 1 },
        domLayout: 'autoHeight',
        suppressRowClickSelection: true,
        suppressContextMenu: true,
        rowSelection: 'multiple',
    };
}
