import TestMethod from 'test-methods/test-method';
import IQualitativeResult from './contracts/IQualitativeResult';
import IQualitativeSpecification from './contracts/IQualitativeSpecification';
import IQuantitativeSpecification from './contracts/IQuantitativeSpecification';
import ISpecificationBase from './contracts/ISpecificationBase';
import ISpecificationDto from './contracts/ISpecificationDto';

export default class Specification implements ISpecificationBase {
    private _type: string;

    id?: number;
    organizationId: number;
    testMethodId: number;
    applyOutcomesRetroactively: boolean;
    pointType?: string;
    operationsTiming?: string;
    zone?: number;
    room?: string

    maxQuantitativePassCount?: number;
    minQuantitativeWarningCount?: number;
    passingQualitativeResults?: IQualitativeResult[];
    warningQualitativeResults?: IQualitativeResult[];

    constructor() {
        this.maxQuantitativePassCount = 0;
        this.passingQualitativeResults = [];
        this.warningQualitativeResults = [];
    }

    public get type(): string {
        return this._type;
    }

    public set testMethod(testMethod: TestMethod) {
        this._type = testMethod.category === 'MQL' ? 'Qualitative' : 'Quantitative';
    }

    toModel(specification: ISpecificationDto): void {
        this.id = specification.id;
        this.organizationId = specification.organizationId;
        this.testMethodId = specification.testMethodId;
        this._type = specification.type;
        this.pointType = specification.pointType;
        this.operationsTiming = specification.operationsTiming;
        this.zone = specification.zone;
        this.room = specification.room;
        this.maxQuantitativePassCount = specification.maxQuantitativePassCount;
        this.minQuantitativeWarningCount = specification.minQuantitativeWarningCount;
        this.passingQualitativeResults = specification.passingQualitativeResults;
        this.warningQualitativeResults = specification.warningQualitativeResults;
    }

    toEntity(): IQuantitativeSpecification | IQualitativeSpecification {
        const $this = {
            id: this.id,
            organizationId: this.organizationId,
            testMethodId: this.testMethodId,
            applyOutcomesRetroactively: this.applyOutcomesRetroactively,
            pointType: this.pointType,
            operationsTiming: this.operationsTiming,
            zone: this.zone,
            room: this.room
        };

        if (this._type === 'Quantitative') {
            return {
                ...$this,
                maxQuantitativePassCount: this.maxQuantitativePassCount,
                minQuantitativeWarningCount: this.minQuantitativeWarningCount,
            } as IQuantitativeSpecification;
        }

        return {
            ...$this,
            passingQualitativeResults: this.passingQualitativeResults,
            warningQualitativeResults: this.warningQualitativeResults,
        } as IQualitativeSpecification;
    }

    haveConditionsChanged(oldSpecification: Specification): boolean {
        const spec1 = {
            maxQuantitativePassCount: Number(this.maxQuantitativePassCount),
            minQuantitativeWarningCount: Number(this.minQuantitativeWarningCount),
            passingQualitativeResults: this.passingQualitativeResults,
            warningQualitativeResults: this.warningQualitativeResults,
        };
        const spec2 = {
            maxQuantitativePassCount: Number(oldSpecification.maxQuantitativePassCount),
            minQuantitativeWarningCount: Number(oldSpecification.minQuantitativeWarningCount),
            passingQualitativeResults: oldSpecification.passingQualitativeResults,
            warningQualitativeResults: oldSpecification.warningQualitativeResults,
        };

        return JSON.stringify(spec1) !== JSON.stringify(spec2);
    }
}
