import { CustomError } from 'infrastructure/custom-error';

export default abstract class ValidationErrorFormatter {
    private static formatValidationErrors(apiErrorData: any): string {
        const validationErrorMessage: string[] = [];
        for (let i = 0; i < apiErrorData.length; i++) {
            validationErrorMessage.push(apiErrorData[i].errorMessage);
        }

        return validationErrorMessage.join(', ');
    }

    public static format(defaultMessage: string, error: any): string {
        let { apiErrorCode, apiErrorData, errorCode, message } = error;
        const userErrorCode = errorCode || apiErrorData?.apiErrorCode || apiErrorCode;
        let userErrorMessage =
            (error instanceof CustomError && message) || apiErrorData?.message || defaultMessage;

        if (userErrorCode === 400) {
            userErrorMessage = this.formatValidationErrors(apiErrorData);
        }

        return userErrorMessage;
    }
}
