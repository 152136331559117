export interface ExportTaskCollectionTemplateFormattingOptions {
    alternateRowColor: boolean;
    columnWidth: number;
    adjustColumnsToContents: boolean;
}

export const emptyExportTaskCollectionTemplateFormattingOptions = (): ExportTaskCollectionTemplateFormattingOptions => ({
    alternateRowColor: false,
    columnWidth: 8.43,
    adjustColumnsToContents: false,
});
