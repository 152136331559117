import { autoinject, observable } from 'aurelia-framework';
import {
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
} from 'aurelia-validation';
import { LocalStorageConstants } from 'infrastructure/constants/localstorage.constants';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import SelectorOption from 'infrastructure/selector-option';
import Validators from 'infrastructure/validation/validators';
import PageContext from '../infrastructure/page-context';
import SecurityService from '../security/security-service';
import OrganizationDataTypeSetting from './organization-data-type-setting';
import OrganizationService from './organization-service';
import OrganizationSetting from './organization-setting';

@autoinject
export default class Settings {
    validationController: ValidationController;
    organizationSettings: OrganizationDataTypeSetting[];
    @observable organizationId: number;
    organizationOptions: SelectorOption[];
    formChanged: boolean;
    organizationSettingValidationRules: any;

    constructor(
        private securityService: SecurityService,
        validationControllerFactory: ValidationControllerFactory,
        private organizationService: OrganizationService,
        private pageContext: PageContext,
        private logger: Logger,
        private dialogPresenter: DialogPresenter,
    ) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.organizationService = organizationService;
        this.pageContext = pageContext;
        this.logger = logger;
        this.dialogPresenter = dialogPresenter;

        this.organizationId = 0;
        this.organizationSettings = [];

        this.initializeValidationRules();
    }

    handleFormChange() {
        this.formChanged = true;
    }

    async organizationIdChanged(organizationId: number) {
        if (!organizationId) {
            return;
        }

        this.organizationSettings.forEach((s) => {
            this.validationController.removeObject(s);
        });

        this.pageContext.isLoading = true;

        try {
            const organizationSettings = await this.organizationService.getConfigurationsValues(
                organizationId,
            );

            this.organizationSettings = organizationSettings.map((os: OrganizationSetting) => {
                const setting = new OrganizationDataTypeSetting();
                setting.name = os.name;
                setting.displayName = os.displayName;
                setting.description = os.description;
                setting.value = os.value;
                setting.moduleType = os.moduleType;
                setting.dataType = os.dataType;

                return setting;
            });

            this.organizationSettings.forEach((s) =>
                this.validationController.addObject(s, this.organizationSettingValidationRules),
            );
        } catch (err) {
            this.logger.error('Error fetching settings.', err);
            this.dialogPresenter.showAlert(
                'Error Loading Settings',
                'An unexpected error occured while loading settings. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    async save() {
        const res = await this.validationController.validate();
        if (!res.valid) {
            return;
        }

        this.pageContext.isLoading = true;

        try {
            await this.organizationService.saveConfigurationsValues(
                this.organizationId,
                this.organizationSettings,
            );

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Setting saved.');
            this.removeLocalStorageSettings();
        } catch (error) {
            this.logger.error('Error saving settings.', error, {
                organizationId: this.organizationId,
                settings: this.organizationSettings,
            });
            this.dialogPresenter.showAlert(
                'Error Saving Settings',
                'An unexpected error occured while saving settings. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    initializeValidationRules() {
        this.organizationSettingValidationRules = ValidationRules.ensure(
            (s: OrganizationSetting) => s.value,
        ).satisfies((value, setting) => {
            switch (setting.dataType) {
                case 'String':
                    return value.length > 0;
                case 'EmailAddresses':
                    return Validators.areValidEmails(value);
                default: {
                    return true;
                }
            }
        }).rules;
    }

    async activate() {
        this.loadData();
    }

    async loadData() {
        this.pageContext.isLoading = true;

        try {
            const isWorkingUnderOrganization = this.securityService.isWorkingUnderOrganization();
            const organizations = isWorkingUnderOrganization
                ? await this.organizationService.getImpersonatingOrganizations()
                : await this.organizationService.getOrganizations();

            this.organizationOptions = organizations.map((x: any) => ({
                title: x.name,
                value: x.id,
            }));
        } catch (error) {
            this.logger.error('Error loading Configuration', error);
            this.dialogPresenter.showAlert(
                'Error Loading Settings',
                'An unexpected error occured while loading settings. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    private removeLocalStorageSettings() {
        const enableBetaFeatures = this.organizationSettings.find(
            (x) => x.name === 'EnableBetaFeatures',
        );

        if (enableBetaFeatures && !enableBetaFeatures.booleanValue) {
            localStorage.removeItem(LocalStorageConstants.BETA_GRID_ENABLED);
        }
    }
}
