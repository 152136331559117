import { inject, bindable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

@inject(DialogController)
export class CustomCoaDialog {

    constructor(dialogController) {
        this.dialogController = dialogController;
        this.output = {};
        this.output.contact = {};

        this.output.values = [];
        for (var i = 0; i < 3; i++) {
            this.output.values.push({ label: '', value: '' });
        }
    }

    activate(model) {
        this.model = model;
        this.output.contact = model.contact;
    }

    continue(output) {
        this.dialogController.ok(output);
    }

    cancel() {
        this.dialogController.cancel();
    }
}
