import { autoinject, observable } from 'aurelia-framework';
import moment from 'moment';
import PlanCoverage from './plan-coverage';
import PlanService from './plan-service';

@autoinject
export default class UiPlanCoverage {
    taskTargetDateTimeStart: Date;
    taskTargetDateTimeEnd: Date;
    @observable({ changeHandler: 'planIdChangedHandler' }) planId: number;
    
    planCoverageResult: PlanCoverage;

    constructor(private planService: PlanService) {
        let today = new Date();
        let nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
        this.taskTargetDateTimeStart = today;
        this.taskTargetDateTimeEnd = nextWeek;
    }

    async getPlanCoverage() {
        const start = moment(this.taskTargetDateTimeStart).utc().toISOString();
        const end = moment(this.taskTargetDateTimeEnd).utc().toISOString();
        this.planCoverageResult = await this.planService.getPlanCoverage(this.planId, start, end);
    }

    async planIdChangedHandler() {
        await this.getPlanCoverage();
    }
}
