import marked from 'marked';
import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import PageContext from '../../infrastructure/page-context';
import Logger from '../../infrastructure/logger';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import StringUtility from '../../infrastructure/string-utility';
import SecurityService from '../../security/security-service';
import UserGuideService from './user-guide-service';

@inject(Element, Router, ValidationControllerFactory, PageContext, Logger, DialogPresenter, SecurityService, UserGuideService)
export default class UserGuideDetail {
    constructor(element, router, validationControllerFactory, pageContext, logger, dialogPresenter, securityService, userGuideService) {
        this.element = element;
        this.router = router;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.pageContext = pageContext;
        this.logger = logger;
        this.logger.name = 'user-guide-detail';
        this.dialogPresenter = dialogPresenter;
        this.userGuideService = userGuideService;
        this.securityService = securityService;
        this.htmlContent = '';

        this.editMode = this.securityService.isCurrentUserInternal() && !this.securityService.isImpersonating();
    }

    activate(params) {
        (async () => {
            this.pageContext.isLoading = true;

            try {
                if (params.code) {
                    let userGuides = await this.userGuideService.getUserGuides(params.code);
                    if (userGuides.length === 0) {
                        this.handleNotFound();
                        return;
                    }

                    this.userGuide = userGuides[0];
                } else {
                    this.userGuide = params.id === 'create' ?
                        { isActive: true } :
                        await this.userGuideService.getUserGuide(params.id);
                }

                this.htmlContent = marked(this.userGuide.content || '');

                if (this.editMode) {
                    if (!this.userGuide.imageUploadPath)
                        this.userGuide.imageUploadPath = `/help/images/user-guides/${StringUtility.getRandomString(10)}`;

                    ValidationRules
                        .ensure('title').displayName('Title').required().maxLength(100)
                        .ensure('description').displayName('Description').required().maxLength(255)
                        .on(this.userGuide);

                    this.validationController.addObject(this.userGuide);
                }

                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error while loading user guide', error);
                this.pageContext.isLoading = false;

                if (error.apiErrorCode === 1) {
                    this.handleNotFound();
                    return;
                }

                this.dialogPresenter.showAlert(
                    'Error Loading User Guide',
                    'An error occurred while loading the user guide. Please try again later.');
            }
        })();
    }

    handleNotFound() {
        this.dialogPresenter
            .showAlert(
                'Help Page Not Found',
                'We sorry, but the help page you are trying to view does not exist.')
            .then(() => this.router.navigateToRoute('user-guide-list'));
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('user-guide-list');
    }

    async save() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        try {
            if (!this.userGuide.content)
                this.userGuide.content = '';

            this.userGuide.id = (await this.userGuideService.saveUserGuide(this.userGuide)).id;

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('User guide saved successfully.');
            this.router.navigateToRoute('user-guide-detail', { id: this.userGuide.id }, { replace: true });
        } catch (error) {
            this.logger.error('Error saving user guide.', error, { userGuide: this.userGuide });
            this.dialogPresenter.showAlert(
                'Error Saving User Guide',
                this.getApiErrorMessage(error.apiErrorCode));
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        switch (errorCode) {
            default:
                return 'An error occurred while saving the current user guide. Please try again later.';
        }
    }
};
