import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import OrganizationService from '../../organizations/organization-service';
import SecurityService from '../../security/security-service';
import UserService from '../../users/user-service';
import DialogPresenter from '../dialogs/dialog-presenter';
import Logger from '../logger';

@inject(Element, Router, Logger, DialogPresenter, SecurityService, UserService, OrganizationService)
export class HeaderAccountDropdown {
    @observable userFilterText;
    @observable orgFilterText;

    constructor(element, router, logger, dialogPresenter, securityService, userService, organizationService) {
        this.element = element;
        this.router = router;

        this.logger = logger;
        this.logger.name = 'header-account-dropdown';

        this.dialogPresenter = dialogPresenter;
        this.securityService = securityService;
        this.userService = userService;
        this.organizationService = organizationService;
        this.userFilterText = '';
        this.orgFilterText = '';
        this.usersAreLoading = true;
        this.orgsAreLoading = true;
        this.userView = this.securityService.getCurrentSessionUser();
        this.usersView = [];
        this.orgsView = [];
        this.updateImpersonatedUser();
        this.updateWorkingUnderOrganization();
    }

    userFilterTextChanged() {
        this.orgsView = [];
        this.orgFilterText = '';
        this.updateUserSearchResults();
    }

    orgFilterTextChanged() {
        this.usersView = [];
        this.userFilterText = '';
        this.updateOrgSearchResults();
    }

    async updateUserSearchResults() {
        if (this.userFilterText.trim() === '')
            return;
        try {
            this.usersAreLoading = true;
            var results = await this.userService.getUsers(this.userFilterText);
            this.usersView = results;
            this.usersAreLoading = false;
        } catch (error) {
            this.logger.error('Error searching for users to impersonate.', error, { userFilterText: this.userFilterText });
            this.usersAreLoading = false;
        }
    }

    async updateOrgSearchResults() {
        if (this.orgFilterText.trim() === '')
            return;
        try {
            this.orgsAreLoading = true;
            this.orgsView = await this.organizationService.findOrganizations(this.orgFilterText);
            this.orgsAreLoading = false;
        } catch (error) {
            this.logger.error('Error searching for organizations to work under.', error, { orgFilterText: this.orgFilterText });
            this.orgsAreLoading = false;
        }
    }

    logout() {
        try {
            this.securityService.logout();
            this.securityService.navigateToLoginScreen();
        } catch (error) {
            this.logger.error('Error logging out.', error);
        }
    }

    updateImpersonatedUser() {
        this.impersonatedUser = this.securityService.getImpersonatedUser() || {};
    }

    updateWorkingUnderOrganization() {
        this.workingUnderOrganization = this.securityService.getWorkingUnderOrganization() || {};
    }

    async startImpersonation(user) {
        this.usersAreLoading = true;

        try {
            await this.securityService.startImpersonation(this.simplifyUser(user));
            this.updateImpersonatedUser();
            this.userFilterText = '';
            this.navigateToDashboard();
        } catch (error) {
            this.logger.error('Error starting impersonation.', error);

            // No organization application modules
            if (error.apiErrorCode === 311) {
                await this.dialogPresenter.showAlert(
                    'Impersonated User Has No Application Modules',
                    'The user you are attempting to impersonate doesn\'t have any application modules and therefore cannot use this application. You cannot impersonate this user unless application modules are assigned to the user\'s top-level organization.');
            } else if(error.apiErrorCode === 312) {
                await this.dialogPresenter.showAlert(
                    'Impersonated User Has No Organizations',
                    'The user you are attempting to impersonate is an Overseer and doesn\'t have any organization associations. You cannot impersonate this user unless the user is associated to at least one organization.');
            } else {
                await this.dialogPresenter.showAlert(
                    'Error Starting Impersonation',
                    'An error occurred while starting impersonation. Please try again later.');
            }

            this.stopImpersonation();
        }

        this.usersAreLoading = false;
    }

    async startWorkingUnderOrganization(org) {
        this.orgsAreLoading = true;

        try {
            await this.securityService.startWorkingUnderOrganization(org);
            this.updateWorkingUnderOrganization();
            this.navigateToDashboard();
        } catch (error) {
            this.logger.error('Error starting work under organization.', error);
            this.stopImpersonation();
        }

        this.orgsAreLoading = false;
    }

    stopImpersonation() {
        this.usersAreLoading = true;
        this.orgsAreLoading = true;

        try {
            this.securityService.stopImpersonation();
            this.updateImpersonatedUser();
            this.updateWorkingUnderOrganization();
            this.navigateToDashboard();
        } catch (error) {
            this.logger.error('Error stopping impersonation.', error);
        }

        this.usersAreLoading = false;
        this.orgsAreLoading = false;
    }

    navigateToDashboard() {
        if (this.router.currentInstruction.config.route && this.router.currentInstruction.config.route.toLowerCase() !== 'dashboard')
            this.router.navigateToRoute('dashboard');
        else
            // Already on the dashboard - refresh re-run activation etc for changed permissions
            window.location.reload();
    }

    simplifyUser(user) {
        const organizationId = (user.organization && user.organization.id);
        const organizationName = (user.organization && user.organization.name);
        return {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            organizationId: organizationId || 0,
            organizationName: organizationName || ''
        };
    }
}
