import { Subscription } from 'aurelia-event-aggregator';
import { autoinject, observable } from 'aurelia-framework';
import {
    ControllerValidateResult,
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
} from 'aurelia-validation';
import FormViewModel from 'infrastructure/contracts/form-view-model';
import SelectorOption from 'infrastructure/selector-option';
import adhocReportInfo from '../contracts/adhoc-report-info';
import { AdhocReportSubscription } from './contracts/adhoc-report-subscription';
import { AdhocReportSubscriptionDetailActivation } from './contracts/adhoc-report-subscription-detail-activation';
import {
    getSubscriptionFrequencyTypes,
    subscriptionFrequency,
    SubscriptionFrequencyType,
} from './contracts/subscription-frequency-type';

@autoinject
export class AdhocReportSubscriptionDetail implements FormViewModel {
    subsriptionFrequencyTypes: Readonly<SelectorOption[]>;
    timeZones: Readonly<SelectorOption[]>;
    validationController: ValidationController;
    userAdhocReports: Readonly<adhocReportInfo[]>;
    formChanged: boolean;
    clearSubscription: Subscription;
    validateOnBind: boolean;

    id?: number;
    adhocReportId: number;
    @observable subscriptionFrequencyType: SubscriptionFrequencyType;
    @observable deliveryDay: number;
    deliveryTimeOfDay: string;
    timeZone: string;
    isStarted: boolean;
    emailTo: string;
    emailCC?: string;
    emailBCC?: string;
    emailSubject: string;
    emailComment?: string;
    renderFormat: string;

    validateForSubscription: boolean;
    emailRegEx: RegExp;

    constructor(
        private element: Element,
        private validationControllerFactory: ValidationControllerFactory,
    ) {
        this.validationController = this.validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;
        this.subsriptionFrequencyTypes = getSubscriptionFrequencyTypes();

        // eslint-disable-next-line no-useless-escape
        this.emailRegEx = new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    }

    deactivate(): void {
        throw new Error('Method not implemented.');
    }

    toEntity(): AdhocReportSubscription {
        return {
            id: this.id,
            adhocReportId: this.adhocReportId,
            subscriptionFrequencyType: this.subscriptionFrequencyType,
            deliveryDay: this.deliveryDay,
            deliveryTimeOfDay: this.deliveryTimeOfDay,
            timeZone: this.timeZone,
            isStarted: this.isStarted,
            emailTo: this.emailTo,
            emailCC: this.emailCC,
            emailBCC: this.emailBCC,
            emailSubject: this.emailSubject,
            emailComment: this.emailComment,
            renderFormat: this.renderFormat,
        };
    }

    loadViewFrom(entity: AdhocReportSubscription): void {
        this.id = entity.id;
        this.adhocReportId = entity.adhocReportId;
        this.subscriptionFrequencyType = entity.subscriptionFrequencyType;
        this.deliveryDay = entity.deliveryDay;
        this.deliveryTimeOfDay = entity.deliveryTimeOfDay;
        this.timeZone = entity.timeZone;
        this.isStarted = entity.isStarted;
        this.emailTo = entity.emailTo;
        this.emailCC = entity.emailCC;
        this.emailBCC = entity.emailBCC;
        this.emailSubject = entity.emailSubject;
        this.emailComment = entity.emailComment;
        this.renderFormat = entity.renderFormat;
    }

    handleFormChange(): void {
        this.formChanged = true;
    }

    subscriptionFrequencyTypeChanged() {
        this.validationController.validate();
    }

    deliveryDayChanged() {
        this.validationController.validate();
    }

    async save() {
        const { valid }: ControllerValidateResult = await this.validationController.validate();
        if (!valid) {
            return;
        }

        const event = new CustomEvent('form-saved', {
            bubbles: true,
            detail: this.toEntity(),
        });

        this.element.dispatchEvent(event);
    }

    cancel() {
        const event = new CustomEvent('form-cancelled', { bubbles: true });

        this.element.dispatchEvent(event);
    }

    async activate(activation: AdhocReportSubscriptionDetailActivation) {
        this.userAdhocReports = activation.userAdhocReports;
        this.loadViewFrom(activation.entity);
        this.validateOnBind = activation.validateOnBind;
        this.timeZones = activation.timeZones;
    }

    async bind() {
        function subscriptionFrequencyRule(
            subscriptionFrequencyType: SubscriptionFrequencyType,
            deliveryDay: number,
        ): boolean {
            const deliveryDayType = subscriptionFrequency[subscriptionFrequencyType];
            return (
                deliveryDay >= deliveryDayType.minDeliveryDay &&
                deliveryDay <= deliveryDayType.maxDeliveryDay
            );
        }

        ValidationRules.customRule(
            'subscriptionFrequencyTypeRule',
            (v: SubscriptionFrequencyType, o: this) => subscriptionFrequencyRule(v, o.deliveryDay),
            '',
        );

        ValidationRules.customRule(
            'deliveryDayRule',
            (v: number, o: this) => subscriptionFrequencyRule(o.subscriptionFrequencyType, v),
            '',
        );

        ValidationRules.customRule(
            'emailList',
            (list, obj) => {
                if (!list)
                    // Allow blanks
                    return true;

                var values = list.split(';').map((e) => e.trim());
                if (values.length === 0)
                    // Allow blanks
                    return true;

                for (var i = 0; i < values.length; i++) {
                    if (!values[i] || !this.emailRegEx.test(values[i])) return false;
                }

                return true;
            },
            // eslint-disable-next-line no-useless-escape
            'If specified, ${$displayName} must be a semicolon-separated list of valid email addresses.',
        );

        ValidationRules.ensure((f: this) => f.adhocReportId)
            .required()
            .min(1)
            .ensure((f: this) => f.subscriptionFrequencyType)
            .required()
            .then()
            .satisfiesRule('subscriptionFrequencyTypeRule')
            .when((o) => o.subscriptionFrequencyType == 2)
            .withMessage(subscriptionFrequency[2].subscriptionFrequencyTypeValidationErrorMessage)
            .satisfiesRule('subscriptionFrequencyTypeRule')
            .when((o) => o.subscriptionFrequencyType == 3)
            .withMessage(subscriptionFrequency[3].subscriptionFrequencyTypeValidationErrorMessage)
            .ensure((f: this) => f.deliveryDay)
            .required()
            .then()
            .satisfiesRule('deliveryDayRule')
            .when((o) => o.subscriptionFrequencyType == 2)
            .withMessage(subscriptionFrequency[2].deliveryDayValidationErrorMessage)
            .satisfiesRule('deliveryDayRule')
            .when((o) => o.subscriptionFrequencyType == 3)
            .withMessage(subscriptionFrequency[3].deliveryDayValidationErrorMessage)
            .ensure((f: this) => f.deliveryTimeOfDay)
            .required()
            .ensure((f: this) => f.timeZone)
            .required()
            .ensure((f: any) => f.emailTo)
            .displayName('To')
            .required()
            .satisfiesRule('emailList')
            .ensure((f) => f.emailCC)
            .displayName('CC')
            .satisfiesRule('emailList')
            .when((f) => f.emailCC?.length > 0)
            .ensure((f) => f.emailBCC)
            .displayName('BCC')
            .satisfiesRule('emailList')
            .when((f) => f.emailBCC?.length > 0)
            .ensure((f) => f.renderFormat)
            .displayName('Format')
            .required()
            .ensure((f) => f.emailSubject)
            .displayName('Subject')
            .required()
            .on(this);

        if (this.validateOnBind) {
            await this.validationController.validate();
        }
    }
}
