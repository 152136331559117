import { FilterField } from './filter-field';
import { FilterOperator } from './filter-operator';

interface Item {
    title: string;
    value: boolean;
}

export class BooleanFilter {
    operators: FilterOperator[];
    items: Item[];

    filterField: FilterField;

    constructor() {
        this.operators = [{ title: 'equals', value: 'equals' }];
        this.items = [
            { title: 'Yes', value: true },
            { title: 'No', value: false },
        ];
    }

    activate(filterField: FilterField) {
        this.filterField = filterField;

        // When switching field types, the operator may not be available. If not, default to the first one.
        if (!this.operators.some(o => o.value === this.filterField.operator))
            this.filterField.operator = this.operators[0].value;
    }
}
