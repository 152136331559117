import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import CreateOrEditModel from 'infrastructure/contracts/create-or-edit-model';
import SelectorOption from 'infrastructure/selector-option';
import TimeZoneService from 'infrastructure/time-zone-service';
import DialogPresenter from '../../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../../infrastructure/logger';
import PageContext from '../../../infrastructure/page-context';
import AdhocReportService from '../adhoc-report-service';
import { AdhocReportSubscriptionService } from './adhoc-report-subscription-service';
import { AdhocReportSubscription } from './contracts/adhoc-report-subscription';
import { AdhocReportSubscriptionDetailActivation } from './contracts/adhoc-report-subscription-detail-activation';

@autoinject
export class AdhocReportSubscriptionEdit implements CreateOrEditModel {
    activation: AdhocReportSubscriptionDetailActivation;

    constructor(
        private adhocReportService: AdhocReportService,
        private adhocReportSubscriptionService: AdhocReportSubscriptionService,
        private dialogPresenter: DialogPresenter,
        private logger: Logger,
        private pageContext: PageContext,
        private router: Router,
        private timeZoneService: TimeZoneService,
    ) {}

    async save(event: CustomEvent) {
        const { detail }: { detail: AdhocReportSubscription } = event;
        this.pageContext.isLoading = true;
        try {
            await this.adhocReportSubscriptionService.changeAdhocReportSubscriptionDelivery(
                detail.id,
                detail,
            );
            this.pageContext.showSuccessOverlay('Ad-Hoc Report Subscription successfully saved.');
            this.router.navigateToRoute('adhoc-report-subscription-list');
        } catch (error) {
            this.logger.error('Error editing Ad-hoc Report Subscription', error, {
                adhocReportSubscription: detail,
            });
            this.dialogPresenter.showAlert(
                'Error Saving Ad-hoc Report Subscription',
                'Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    cancel(event: CustomEvent) {
        this.router.navigateToRoute('adhoc-report-subscription-list');
    }

    async canActivate(params: any) {
        this.pageContext.isLoading = true;
        const [entity, userAdhocReports, timeZones] = await Promise.all([
            this.adhocReportSubscriptionService.getAdhocReportSubscription(params.id),
            this.adhocReportService.getCurrentUserAdhocReports(),
            this.timeZoneService.getTimeZones(),
        ]);

        this.activation = {
            userAdhocReports,
            timeZones: timeZones.map(
                (tz: any) => ({ title: tz.displayName, value: tz.id } as SelectorOption),
            ),
            entity,
            validateOnBind: true,
        };
    }

    bind() {
        this.pageContext.isLoading = false;
    }
}
