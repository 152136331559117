import { inject, transient } from 'aurelia-framework';
import { HttpClient, HttpResponseMessage } from 'aurelia-http-client';
import ApplicationInsightsService from './application-insights-service';

function getConsoleFunction(logLevel) {
    switch (logLevel) {
        case 4:
            return console.error;

        case 3:
            return console.warn;

        case 2:
        default:
            return console.info;
    }
}

function stringifyError(error) {
    if (error instanceof HttpResponseMessage) return error.response;

    if (error instanceof Error) return error.stack;

    if (error.message) return error.message;

    if (typeof error === 'string') return error;

    var errorToString = error.toString();
    if (errorToString !== '[object Object]') return errorToString;

    try {
        return JSON.stringify(error);
    } catch (e) {
        console.error('Error stringifying error.', e);
        return '[cannot stringify error]';
    }
}

function stringifyState(state) {
    try {
        return JSON.stringify(state);
    } catch (e) {
        console.error('Error stringifying state.', e);
        return '"cannot stringify state"';
    }
}

function tryParse(data) {
    try {
        return JSON.parse(data);
    } catch (e) {
        return null;
    }
}

var enableConsoleLog = window.location.hostname === 'localhost';

// Log levels in used in web-client: info = 2, warn = 3, error = 4
// These values come from Microsoft.Extensions.Logging.LogLevel

function getErrorData(error) {
    if (error instanceof HttpResponseMessage) {
        let responseContent = tryParse(error.response);
        let exception = (responseContent && responseContent.exception) || error.response;

        return {
            exception: exception && exception.toString(),
            apiErrorCode: responseContent && responseContent.apiErrorCode,
            requestId: error.headers && error.headers.get('x-http-request-id'),
            httpMethod: error.requestMessage.method,
            statusCode: error.statusCode,
            url: window.location.origin + error.requestMessage.baseUrl + error.requestMessage.url,
        };
    }

    return {
        exception: stringifyError(error),
        apiErrorCode: error.apiErrorCode,
        requestId: error.httpRequestId,
        httpMethod: error.httpMethod,
        statusCode: error.httpStatusCode,
        url: error.url,
    };
}

@transient()
@inject(HttpClient, ApplicationInsightsService)
export default class Logger {
    constructor(httpClient, applicationInsightsService) {
        this.httpClient = httpClient;
        this.applicationInsightsService = applicationInsightsService;
        this.name = '';
    }

    info(message, state) {
        this.log({
            logLevel: 2,
            message,
            state,
        });
    }

    warn(message, error, state) {
        this.log({
            logLevel: 3,
            message,
            error,
            state,
        });
    }

    error(message, error, state) {
        this.log({
            logLevel: 4,
            message,
            error,
            state,
        });
    }

    log({ logLevel = 2, message = '', error = null, state = null } = {}) {
        try {
            var loggerName = `[web-client] ${this.name}`;

            if (enableConsoleLog) {
                var consoleMessage = `${loggerName}: ${message}`;
                var consoleParameters = [consoleMessage, error, state].filter(
                    (item) => typeof item !== 'undefined' && item !== null,
                );
                var consoleFunction = getConsoleFunction(logLevel);
                consoleFunction(...consoleParameters);
            }

            var data = {
                logLevel,
                name: loggerName,
                message,
                state: state && stringifyState(state),
            };

            var errorData = getErrorData(error);
            var logEntry = Object.assign({}, data, errorData);

            this.httpClient.post('log-entries', logEntry).catch((e) => {
                console.error('Error posting log entry to server.', e);
            });

            errorData.loggerName = this.name;
            this.applicationInsightsService.trackEvent(
                logLevel,
                error,
                message,
                errorData,
                data.state,
            );
        } catch (e) {
            console.error('Error logging in logger.', e);
        }
    }
}
