import { inject } from 'aurelia-framework';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import Logger from '../infrastructure/logger';
import StringResourceService from '../infrastructure/string-resource-service';
import SecurityService from './security-service';
import LoginErrorMessageProvider from './login-error-message-provider';

@inject(ValidationControllerFactory, Logger, StringResourceService, SecurityService)
export class Login {
    constructor(validationControllerFactory, logger, stringResourceService, securityService) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        ValidationRules
            .ensure('username')
                .displayName('Username')
                .required()
            .ensure('password')
                .displayName('Password')
                .required()
                .on(this);

        this.logger = logger;
        this.logger.name = 'login';
        this.stringResourceService = stringResourceService;
        this.securityService = securityService;
        this.attemptingLogin = false;
        this.loadingDisclaimer = true;
    }

    activate(params, routeConfig) {
        this.verifyAccountToken = params.verifyaccount;

        (async () => {
            var stringResources = await this.stringResourceService.getStringResources();
            this.disclaimer = stringResources['login_disclaimer'];
            this.loadingDisclaimer = false;
        })();
    }

    async login() {
        this.errorMessage = '';
        this.passwordExpired = false;
        this.attemptingLogin = true;

        try {
            var aggregateResult = await this.validationController.validate();
            if (!aggregateResult.valid)
                return;

            await this.securityService.login(this.username, this.password, this.verifyAccountToken);
            this.securityService.navigateToApp();
        } catch (error) {
            this.logger.error('Error while logging in.', error, { username: this.username });
            this.passwordExpired = error.apiErrorCode === 300;
            this.errorMessage = LoginErrorMessageProvider.getErrorMessage(error);
        }

        this.attemptingLogin = false;
    }
}
