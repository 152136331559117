import Organization from 'organizations/organization';
import RecordStatus, { getRecordStatusOptions } from 'record-status';
import TestMethod from 'test-methods/test-method';
import CapPlanFilters from './cap-plan-filters';

export class UiCapPlanFilters implements CapPlanFilters {
    public isFilteringOrganization: boolean;

    public organizations: Organization[];
    public testMethods: TestMethod[];
    public recordStatusOptions: any;

    public selectedOrganizations: Organization[];
    public selectedTestMethods: TestMethod[];
    public recordStatus?: string;

    constructor() {
        this.isFilteringOrganization = false;
        this.organizations = [];
        this.selectedOrganizations = [];
        this.testMethods = [];
        this.selectedTestMethods = [];
        this.recordStatusOptions = getRecordStatusOptions();
        this.recordStatus = RecordStatus.ACTIVE;
    }

    get organizationIds(): number[] {
        return this.selectedOrganizations.map(o => o.organizationId);
    }

    get testMethodIds(): number[] {
        return this.selectedTestMethods.map(tm => tm.id);
    }

    get orgFilterCaption(): string {
        return this.selectedOrganizations.length
            ? this.selectedOrganizations.map(o => o.name).join(', ')
            : 'All Organizations';
    }

    get testMethodFilterCaption(): string {
        return this.selectedTestMethods.length
            ? this.selectedTestMethods.map(tm => tm.billCode).join(', ')
            : '';
    }

    apply() {
        this.isFilteringOrganization = true;
    }

    clear() {
        this.isFilteringOrganization = false;
        this.selectedOrganizations = [];
        this.selectedTestMethods = [];
        this.recordStatus = RecordStatus.ACTIVE;
    }

    toModel(): CapPlanFilters {
        return {
            organizationIds: this.organizationIds,
            testMethodIds: this.testMethodIds,
            recordStatus: this.recordStatus,
        };
    }
}
