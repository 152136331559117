export default class LabelTemplateType {
    static Unknown = new LabelTemplateType('Unknown');
    static Task = new LabelTemplateType('Task');
    static Sample = new LabelTemplateType('Sample');

    name: string;

    constructor(name: string) {
        this.name = name;
    }

    toString() {
        return this.name;
    }

    static getOptions() {
        return [
            { title: 'Task', value: LabelTemplateType.Task.name },
            { title: 'Sample', value: LabelTemplateType.Sample.name }
        ];
    }
}
