export abstract class BaseQueryFilter {
    private _defaultSegement = 'Filter';
    private _introSegment = 'Viewing';
    private _setGlobalFilterDebounce: any;

    private removeUndefinedProperties(obj: any): any {
        for (let key in obj) {
            if (obj[key] === undefined) {
                delete obj[key];
            }
        }

        return obj;
    }

    protected getGlobalFilterFromLocalStorage(key: string): any {
        const globalFilterString = localStorage.getItem(key);
        return globalFilterString && globalFilterString.length > 0
            ? JSON.parse(globalFilterString)
            : null;
    }

    protected setGlobalFilterToLocalStorage(key: string, obj: any): void {
        if (this._setGlobalFilterDebounce) {
            clearTimeout(this._setGlobalFilterDebounce);
        }

        this._setGlobalFilterDebounce = setTimeout(() => {
            this._setGlobalFilterDebounce = null;
            localStorage.setItem(key, JSON.stringify(obj));
        }, 1000);
    }

    protected getFilterCaption(
        filterCount: number,
        dateRangeFilterProp?: string,
        dateRangeOption?: string,
    ): string {
        const filterCountOnlyCaption = `with ${filterCount} filter${filterCount === 1 ? '' : 's'}.`;
        const dateRangeCaption = `${dateRangeFilterProp} for ${dateRangeOption}`;

        if (!dateRangeFilterProp || !dateRangeOption) {
            return filterCount > 0
                ? `${this._introSegment} ${filterCountOnlyCaption}`
                : this._defaultSegement;
        }

        return `${this._introSegment} ${dateRangeCaption} ${filterCountOnlyCaption}`;
    }

    protected formatSingleOrMultipleOrNothingArray(possibleArray: any) {
        if (!possibleArray) {
            return null;
        }

        return Array.isArray(possibleArray) ? possibleArray : [possibleArray];
    }

    protected setQueryFilteredValues(params: any, obj: any): void {
        const definedParams = this.removeUndefinedProperties(params);
        Object.keys(definedParams).forEach((paramKey) => {
            const prop =
                Object.getOwnPropertyDescriptor(Object.getPrototypeOf(obj), paramKey) ||
                Object.getOwnPropertyDescriptor(obj, paramKey);
            if (prop) {
                let type: string;
                const canSet = !!prop.get && prop.set;
                if (canSet) {
                    const get = prop.get.bind(obj);
                    const value = get();
                    type = typeof value;
                }
                if (prop.value) {
                    type = typeof prop.value;
                }
                switch (type) {
                    case 'boolean':
                        obj[paramKey] = definedParams[paramKey] === 'true' ? true : false;
                        break;
                    case 'number':
                        obj[paramKey] = Number(definedParams[paramKey]);
                        break;
                    default:
                        obj[paramKey] = definedParams[paramKey];
                }
            }
        });
    }
}
