import { autoinject, BindingEngine, Disposable, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
} from 'aurelia-validation';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import SecurityService from 'security/security-service';
import UserService from 'users/user-service';
import DisplayOnlyElementManager from './display-only-element-manager';
import LabelTemplateService from './label-template-service';
import LabelTemplateType from './label-template-type';
import SampleLabelDisplayOnlyElementManager from './sample-label-display-only-element-manager';
import UiLabelTemplate from './ui-label-template';
import UiLabelTemplateElement from './ui-label-template-element';

@autoinject
export class LabelTemplateList {
    organizations: any[];
    uiLabelTemplate: UiLabelTemplate;
    zoom: number;
    @observable selectedElement: UiLabelTemplateElement;

    objectNameOptions: any;
    fieldNameOptions: any;
    validationController: ValidationController;
    formChanged: boolean;
    zoomLevels: number[];
    zoomLevelIndex: number;
    canZoomIn: boolean;
    canZoomOut: boolean;
    canEditLabelTemplates: boolean;
    missingOrganizationConfiguration: boolean;
    labelTemplateTypeOptions: any;
    uiLabelTemplateWidthChangedSubscription: Disposable;
    uiLabelTemplateHeightChangedSubscription: Disposable;
    uiLabelTemplateTypeChangedSubscription: Disposable;
    revertingLabelTemplateType: boolean;
    allowAddRemoveElements: boolean;
    displayOnlyElementManager: DisplayOnlyElementManager;
    sampleLabelFontSize: number;

    constructor(
        validationControllerFactory: ValidationControllerFactory,
        private bindingEngine: BindingEngine,
        private router: Router,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private userService: UserService,
        private labelTemplateService: LabelTemplateService,
    ) {
        this.logger.name = 'label-template-detail';

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.zoomLevelIndex = 5;
        this.zoomLevels = [25, 50, 100, 200, 300, 400, 500];
        this.canZoomIn = true;
        this.canZoomOut = true;

        this.revertingLabelTemplateType = false;
        this.allowAddRemoveElements = false;

        this.selectedElement = null;

        this.canEditLabelTemplates =
            this.securityService.hasPermission('EditLabelTemplates') &&
            !this.securityService.isImpersonating();

        this.labelTemplateTypeOptions = LabelTemplateType.getOptions();
    }

    activate(params) {
        (async () => {
            this.pageContext.isLoading = true;

            try {
                let [labelTemplate, organizations] = await Promise.all([
                    params.id !== 'create'
                        ? this.labelTemplateService.getLabelTemplate(parseInt(params.id))
                        : {
                              id: null,
                              type: null,
                              width: 162,
                              height: 90,
                              elements: '[]', // JSON string to match server response.
                              organizationConfigurations: [],
                              isActive: true,
                              batchTestMethods: false,
                          },
                    this.userService.getCurrentUserOrganizations(),
                ]);

                this.uiLabelTemplate = UiLabelTemplate.fromModel(labelTemplate, organizations);
                this.subscribePropertyChanges(this.uiLabelTemplate);

                this.sampleLabelFontSize = this.uiLabelTemplate.elements[0]?.fontSize || 6;
                this.initializeDisplayOnlyElements(this.uiLabelTemplate.type);

                this.organizations = organizations;

                this.loadFieldOptions();
                this.setupValidation();
            } catch (error) {
                this.logger.error('Error loading label template.', error);
                this.dialogPresenter.showAlert(
                    'Error Loading Label Template',
                    'An error occurred while loading the label template. Please try again later.',
                );
            }

            this.pageContext.isLoading = false;
        })();
    }
    subscribePropertyChanges(uiLabelTemplate: UiLabelTemplate) {
        this.uiLabelTemplateWidthChangedSubscription = this.bindingEngine
            .propertyObserver(this.uiLabelTemplate, 'width')
            .subscribe(this.handleUiLabelTemplateWidthChanged.bind(this));

        this.uiLabelTemplateHeightChangedSubscription = this.bindingEngine
            .propertyObserver(this.uiLabelTemplate, 'height')
            .subscribe(this.handleUiLabelTemplateHeightChanged.bind(this));

        this.uiLabelTemplateTypeChangedSubscription = this.bindingEngine
            .propertyObserver(this.uiLabelTemplate, 'type')
            .subscribe(this.handleUiLabelTemplateTypeChanged.bind(this));
    }

    deactivate() {
        this.uiLabelTemplateWidthChangedSubscription?.dispose();
        this.uiLabelTemplateHeightChangedSubscription?.dispose();
        this.uiLabelTemplateTypeChangedSubscription?.dispose();
    }

    handleUiLabelTemplateWidthChanged() {
        this.displayOnlyElementManager?.positionDisplayOnlyElements();
    }

    handleUiLabelTemplateHeightChanged() {
        this.displayOnlyElementManager?.positionDisplayOnlyElements();
    }

    async handleUiLabelTemplateTypeChanged(type, oldType) {
        this.selectedElement = null;

        // Bail out (and clear flag) if reverting the template type.
        if (this.revertingLabelTemplateType) {
            this.revertingLabelTemplateType = false;
            return;
        }

        // Do not prompt user first time the template type is set.
        if (!oldType) {
            this.initializeDisplayOnlyElements(type);
            return;
        }

        if (type !== oldType) {
            // Prompt user if OK to clear out label elements.
            let confirmed = await this.dialogPresenter.showConfirmation(
                'Clear all label elements?',
                'Switching between label types will clear out all the label elements.',
            );
            if (confirmed) {
                this.clearElements();
                this.initializeDisplayOnlyElements(type);
                this.uiLabelTemplate.batchTestMethods = false;
                return;
            }

            // Revert value back if user cancels the confirmation.
            this.revertingLabelTemplateType = true;
            this.uiLabelTemplate.type = oldType;
        }
    }

    clearElements() {
        for (let element of this.uiLabelTemplate.elements) this.tearDownElementValidation(element);

        this.uiLabelTemplate.elements.splice(0);
    }

    initializeDisplayOnlyElements(templateType: string) {
        if (templateType !== 'Sample') {
            this.allowAddRemoveElements = true;
            this.displayOnlyElementManager = null;
            return;
        }

        this.allowAddRemoveElements = false;

        /*
         *   HACK/WORKAROUND
         *   Sample label templates persist an empty ('[]') for elements
         *   Task label templates use built in logic to define layout of elements
         *   If we get this far then this sample label template can either:
         *   (1) Be formatted using it's persisted element properties OR
         *   (2) Be display only and setup to save formatted elements for next persist
         */
        if (this.uiLabelTemplate.elements.length) {
            return;
        }

        this.displayOnlyElementManager = new SampleLabelDisplayOnlyElementManager(
            this.uiLabelTemplate,
        );
        this.displayOnlyElementManager.createDisplayOnlyElements();
    }

    addOrganizationConfiguration() {
        let organizationConfiguration = {
            id: null,
            organizationId: null,
            templateName: null,
            isActive: true,
        };

        this.uiLabelTemplate.organizationConfigurations.push(organizationConfiguration);
        this.setupOrganizationConfigurationValidation(organizationConfiguration);
        this.missingOrganizationConfiguration = false;
    }

    removeOrganizationConfiguration(organizationConfiguration) {
        this.tearDownOrganizationConfigurationValidation(organizationConfiguration);
        this.uiLabelTemplate.organizationConfigurations.splice(
            this.uiLabelTemplate.organizationConfigurations.indexOf(organizationConfiguration),
            1,
        );
        this.missingOrganizationConfiguration =
            this.uiLabelTemplate.organizationConfigurations.length === 0;
    }

    selectElement(element, $event: MouseEvent) {
        if (!element || !element.displayOnly) this.selectedElement = element;

        $event?.stopImmediatePropagation();
    }

    // Set all properties the same until all formatting(task) is allowed for sample labels
    sampleLabelFontSizeChanged() {
        this.uiLabelTemplate.elements.forEach((e) => {
            e.fontSize = Number(this.sampleLabelFontSize);
            e.objectName = 'sampleLabel';
            e.fieldName = 'sampleLabelField';
        });
    }

    async selectedElementChanged(element, oldElement) {
        if (oldElement) this.validateElement(oldElement);

        if (element && element.error) this.validateElement(element);
    }

    async validateElement(element: UiLabelTemplateElement) {
        var result = await this.validationController.validate({ object: element });
        element.error = !result.valid;
    }

    addElement() {
        let element = UiLabelTemplateElement.new(this.uiLabelTemplate, 'text', false);

        this.setupElementValidation(element);
        this.uiLabelTemplate.elements.push(element);
        this.selectedElement = element;
    }

    removeSelectedElement() {
        if (!this.selectedElement) return;

        this.tearDownElementValidation(this.selectedElement);
        this.uiLabelTemplate.elements.splice(
            this.uiLabelTemplate.elements.indexOf(this.selectedElement),
            1,
        );
        this.selectedElement = null;
    }

    zoomIn() {
        this.canZoomOut = true;
        this.zoomLevelIndex++;

        if (this.zoomLevelIndex === this.zoomLevels.length - 1) this.canZoomIn = false;
    }

    zoomOut() {
        this.canZoomIn = true;
        this.zoomLevelIndex--;

        if (this.zoomLevelIndex === 0) this.canZoomOut = false;
    }

    handleFormChange() {
        this.formChanged = true;
    }

    batchTestMethodsChanged() {
        this.configureFieldNameOptionsForBatchTestMethods();
    }

    loadFieldOptions() {
        this.objectNameOptions = {
            Task: [{ title: 'Task', value: 'taskLabel' }],
        };

        this.fieldNameOptions = {
            Task: {
                taskLabel: [
                    { group: 'Task', title: 'ID', value: 'taskId' },
                    { group: 'Task', title: 'Sample Description', value: 'taskSampleDescription' },
                    { group: 'Task', title: 'Operations Timing', value: 'operationsTiming' },
                    { group: 'Task', title: 'Collection Type', value: 'collectionType' },
                    { group: 'Task', title: 'Assigned Username', value: 'taskAssignedUsername' },
                    { group: 'Task', title: 'Target Date Time', value: 'targetDateTime' },
                    { group: 'Task', title: 'Collected Date Time', value: 'collectedDateTime' },
                    { group: 'Task', title: 'Internal ID', value: 'organizationInternalId' },
                    { group: 'Point', title: 'ID', value: 'pointId' },
                    { group: 'Point', title: 'Name', value: 'samplePointName' },
                    { group: 'Point', title: 'Description', value: 'samplePointDescription' },
                    { group: 'Point', title: 'Room', value: 'samplePointRoom' },
                    { group: 'Point', title: 'Zone', value: 'samplePointZone' },
                    { group: 'Point', title: 'Type', value: 'samplePointType' },
                    { group: 'Point', title: 'Mobile', value: 'samplePointMobile' },
                    { group: 'Organization', title: 'ID', value: 'organizationId' },
                    { group: 'Organization', title: 'Name', value: 'organizationName' },
                    { group: 'Map', title: 'ID', value: 'mapId' },
                    { group: 'Map', title: 'Name', value: 'mapName' },
                    { group: 'Map', title: 'Description', value: 'mapDescription' },
                    { group: 'Plan', title: 'ID', value: 'planId' },
                    { group: 'Plan', title: 'Name', value: 'planName' },
                    { group: 'Plan', title: 'Description', value: 'planDescription' },
                    { group: 'Plan', title: 'Type', value: 'planType' },
                    {
                        group: 'Plan',
                        title: 'Schedule Cycle Duration Days',
                        value: 'planScheduleCycleDurationDays',
                    },
                    { group: 'Point', title: 'ID', value: 'remediationId' },
                    { group: 'Point', title: 'Name', value: 'remediationName' },
                    { group: 'Point', title: 'Description', value: 'remediationDescription' },
                    {
                        group: 'Point',
                        title: 'Assigned Username',
                        value: 'remediationAssignedUsername',
                    },
                    {
                        group: 'Point',
                        title: 'Approval Username',
                        value: 'remediationApprovalUsername',
                    },
                    { group: 'Point', title: 'Status', value: 'remediationStatus' },
                    { group: 'Test Method', title: 'ID', value: 'testMethodExternalId' },
                    { group: 'Test Method', title: 'Name', value: 'testMethodName' },
                    { group: 'Test Method', title: 'Code', value: 'testMethodBillCode' },
                    {
                        group: 'Test Method',
                        title: 'Batched Test Methods',
                        value: 'batchedTestMethods',
                    },
                ],
            },
        };

        this.configureFieldNameOptionsForBatchTestMethods();
    }

    fieldNameRules = {
        disabledForStandardMode: ['batchedTestMethods'],
        disabledForBatchMode: ['testMethodExternalId', 'testMethodName', 'testMethodBillCode'],
    };

    configureFieldNameOptionsForBatchTestMethods() {
        this.fieldNameOptions['Task'].taskLabel.forEach((option) => {
            option.disabled = this.uiLabelTemplate.batchTestMethods
                ? this.fieldNameRules.disabledForBatchMode.includes(option.value)
                : this.fieldNameRules.disabledForStandardMode.includes(option.value);
        });

        this.uiLabelTemplate.elements.forEach((element) => {
            if (
                (this.uiLabelTemplate.batchTestMethods &&
                    this.fieldNameRules.disabledForBatchMode.includes(element.fieldName)) ||
                (!this.uiLabelTemplate.batchTestMethods &&
                    this.fieldNameRules.disabledForStandardMode.includes(element.fieldName))
            ) {
                element.fieldName = null;
            }
        });
    }

    setupValidation() {
        ValidationRules.ensure('width')
            .required()
            .satisfies((w) => 36 <= w && w <= 1500)
            .ensure('height')
            .required()
            .satisfies((h) => 36 <= h && h <= 1500)
            .ensure('type')
            .required()
            .on(this.uiLabelTemplate);

        for (let organizationConfiguration of this.uiLabelTemplate.organizationConfigurations)
            this.setupOrganizationConfigurationValidation(organizationConfiguration);

        for (let element of this.uiLabelTemplate.elements.filter((e) => !e.displayOnly))
            this.setupElementValidation(element);
    }

    setupOrganizationConfigurationValidation(organizationConfiguration) {
        let ensureSingleOccurrence = (organizationId) => {
            let occurrences = this.uiLabelTemplate.organizationConfigurations.filter(
                (c) => c.organizationId === organizationId,
            ).length;

            return occurrences === 1;
        };

        ValidationRules.ensure('organizationId')
            .required()
            .satisfies(ensureSingleOccurrence)
            .ensure('templateName')
            .required()
            .on(organizationConfiguration);
    }

    tearDownOrganizationConfigurationValidation(organizationConfiguration) {
        ValidationRules.off(organizationConfiguration);
    }

    setupElementValidation(element) {
        ValidationRules.ensure((e: UiLabelTemplateElement) => e.objectName)
            .required()
            .when((e) => e.mode === 'field')
            .ensure((e: UiLabelTemplateElement) => e.fieldName)
            .required()
            .when((e) => e.mode === 'field')
            .ensure((e: UiLabelTemplateElement) => e.template)
            .required()
            .when((e) => e.mode === 'template')
            .ensure((e: UiLabelTemplateElement) => e.width)
            .required()
            .satisfies((width, e) => 10 <= width && width <= this.uiLabelTemplate.width - e.x)
            .ensure((e: UiLabelTemplateElement) => e.x)
            .required()
            .satisfies((x, e) => 0 <= x && x <= this.uiLabelTemplate.width - e.width)
            .ensure((e: UiLabelTemplateElement) => e.y)
            .required()
            .satisfies((y) => 0 <= y && y <= this.uiLabelTemplate.height)
            .ensure((e: UiLabelTemplateElement) => e.fontSize)
            .required()
            .satisfies((fontSize) => 3 <= fontSize && fontSize <= 72)
            .on(element);
    }

    tearDownElementValidation(element) {
        ValidationRules.off(element);
    }

    async save() {
        var aggregateResult = await this.validationController.validate();

        for (let result of aggregateResult.results)
            if (!result.valid && result.object instanceof UiLabelTemplateElement)
                result.object.error = true;

        this.missingOrganizationConfiguration =
            this.uiLabelTemplate.organizationConfigurations.length === 0;

        for (let element of this.uiLabelTemplate.elements) await this.validateElement(element);

        let errorElements = this.uiLabelTemplate.elements.filter((e) => e.error);

        if (
            !aggregateResult.valid ||
            this.missingOrganizationConfiguration ||
            errorElements.length !== 0
        ) {
            this.pageContext.showErrorOverlay(
                'Part of this form is not valid. Please correct any issues and save again.',
            );
            return;
        }

        this.pageContext.isLoading = true;

        try {
            let labelTemplate = this.uiLabelTemplate.toModel();

            let savedLabelTemplate = await this.labelTemplateService.saveLabelTemplate(
                labelTemplate,
            );
            this.uiLabelTemplate.id = savedLabelTemplate.id;
            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Label template saved successfully.');

            this.router.navigateToRoute(
                'label-template-detail',
                { id: this.uiLabelTemplate.id },
                { replace: true },
            );
        } catch (error) {
            this.logger.error('Error saving label template.', error, {
                uiLabelTemplate: this.uiLabelTemplate,
            });
            this.dialogPresenter.showAlert(
                'Error Saving Label Template',
                this.getApiErrorMessage(error.apiErrorCode),
            );
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(apiErrorCode: number) {
        return 'An error occurred while saving the label template. Please try again later.';
    }

    cancel() {
        this.router.navigateToRoute('label-template-list');
    }
}
