export default abstract class Validators {
    static isValidPostalCode(postalCode: string): boolean {
        if (!postalCode) {
            return false;
        }

        const usFormat = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
        const canFormat = new RegExp(
            /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
        );
        if (usFormat.test(postalCode)) {
            return true;
        }
        if (canFormat.test(postalCode)) {
            return true;
        }
        return false;
    }

    static areValidEmails(semicolonSeparatedEmails: string): boolean {
        if (!semicolonSeparatedEmails) {
            return true;
        }

        const emailRegex =
            /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        const emailAddresses = semicolonSeparatedEmails.split(/[;,]/);

        return emailAddresses.every((em) => emailRegex.test(em));
    }
}
