import svgPanZoom from 'svg-pan-zoom';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, ValidationController, validateTrigger, ValidationRules } from 'aurelia-validation';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import SecurityService from 'security/security-service';
import MapService from '../maps/map-service';
import PointService from './point-service';
import PointTypeService from '../point-types/point-type-service';
import PointAttributeService from '../point-attributes/point-attribute-service';
import PointRisk, { getPointRiskOptions } from './point-risk';
import SelectorOption from 'infrastructure/selector-option';
import Point from './point';
import PointAttribute from './point-attribute';

@autoinject
export class PointDetail {

    supportedPhotoFormatMessage = 'The following are supported photo formats: .jpg, .jpeg, .png';

    @observable pointPhoto: any;

    validationController: ValidationController;
    loadPromise: any;

    mapId: number;
    point: Point;
    canEditPoints: boolean;
    canViewAudit: boolean;
    formChanged: boolean;
    recordChanges: any;

    fileReader: any;
    pointPhotoInput: any;
    pointPhotoDataUrl: any;
    mapSvg: any;
    panZoom: any;
    isPanning: boolean;

    pointAttributes: any[];
    propertyDisplayNames: any;
    pointRiskOptions: SelectorOption[];
    pointAttributeNames: any[];
    pointAttributeValues: any[];
    scale: number;
    
    existingPointTypes: any[];
    otherPoints: any[];

    constructor(
        private router: Router,
        validationControllerFactory: ValidationControllerFactory,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private mapService: MapService,
        private pointService: PointService,
        private pointTypeService: PointTypeService,
        private pointAttributeService: PointAttributeService
    ) {
        this.router = router;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.logger = logger;
        this.logger.name = 'point-detail';

        this.canEditPoints = this.securityService.hasPermission('EditPoints') && !this.securityService.isImpersonating();
        this.canViewAudit = this.securityService.isCurrentUserInternal() && this.securityService.isImpersonating();

        this.fileReader = new FileReader();
        this.fileReader.addEventListener('load', this.handlePhotoLoad.bind(this));

        this.pointAttributes = [];

        this.propertyDisplayNames = {
            'Name': 'Name',
            'Description': 'Description',
            'Room': 'Room',
            'Zone': 'Zone',
            'Mobile': 'Mobile',
            'ImagePath': 'Point Photo',
            'IsActive': 'Active',
            'PointLocation': 'Point Location'
        };

        this.pointRiskOptions = getPointRiskOptions();

        this.scale = 1;
    }

    activate(params) {
        // eslint-disable-next-line sonarjs/cognitive-complexity
        this.loadPromise = (async () => {

            this.pageContext.isLoading = true;

            try {

                this.mapId = params.mapId;
               
                var results = await Promise.all([
                    this.pointTypeService.getPointTypes(),
                    this.pointAttributeService.getPointAttributes(),
                    params.id === 'create'
                        ? await this.mapService.getMap(parseInt(params.mapId))
                        : await this.pointService.getPoint(parseInt(params.id)),
                    this.pointService.getPoints({ mapIds: [this.mapId] })
                ]);

                this.existingPointTypes = results[0].map(pt => ({ name: pt }));

                var usedPointAttributes = results[1];

                if (params.id === 'create') {
                    let map = results[2] as any;
                    this.point = { mapId: map.id, isActive: true, mapPhotoUrl: map.photoUrl, attributes: [], x: 0, y: 0, risk: PointRisk.NOT_SPECIFIED } as Point;
                } else
                    this.point = results[2] as unknown as any;

                this.otherPoints = (results[3] as unknown as any[]).filter(p => p.id !== this.point.id);

                this.pointAttributeNames = usedPointAttributes.reduce((attributes, attribute) => {
                    // HACK: old data included the string 'null' in it
                    if (!attributes.some(a => a.name.toLowerCase() === attribute.name.toLowerCase()) && attribute.name.toLowerCase() !== 'null')
                        attributes.push({ name: attribute.name, value: attribute.name });

                    return attributes;
                },
                    []);
                this.pointAttributeValues = usedPointAttributes.reduce((attributes, attribute) => {
                    if (!Object.keys(attributes).find(k => k.toLowerCase() === attribute.name.toLowerCase()))
                        attributes[attribute.name] = [{ name: attribute.value, value: attribute.value }];
                    else if (!attributes[attribute.name].some(a => a.value === attribute.value))
                        attributes[attribute.name].push({ name: attribute.value, value: attribute.value });

                    return attributes;
                },
                    {});

                this.pointAttributes.splice(0);
                for (let attribute of this.point.attributes) {
                    this.pointAttributes.push(this.createPointAttribute(attribute.name, attribute.value));
                }

                this.setupValidation();
            } catch (error) {
                this.logger.error('Error loading point', error, { pointId: params.id });

                await (error.apiErrorCode === 1
                    ? this.dialogPresenter.showAlert(
                        'Error Loading Point',
                        'The current point doesn\'t exist.')
                    : this.dialogPresenter.showAlert(
                        'Error Loading Point',
                        'An error occurred while loading the current point. Please try again later.')
                );

                const mapId = this.point ? this.point.mapId || this.mapId : this.mapId;
                if (mapId)
                    this.router.navigateToRoute('point-list', { mapId });
            }

            this.pageContext.isLoading = false;
        })();
    }

    setupValidation() {
        ValidationRules.customRule(
            'notNull',
            (value, obj) => {
                if (value === null || value === undefined || value === 'null')
                   return false;

                return true;
            },
            'The field is required and must be specified.',
            () => ({})
        );

        ValidationRules
            .ensure('name').displayName('Name').required()
            .ensure('zone').displayName('Zone').required()
            .ensure('mobile').displayName('Mobile').required()
            .ensure('risk').displayName('Risk').required()
            .ensure('x').required().satisfies(v => parseInt(v) > 0)
        .on(this.point);

        this.validationController.addObject(this.point);
        this.pointAttributes.forEach(a => this.setupPointAttributeValidation(a));
    }

    setupPointAttributeValidation(attribute: PointAttribute) {
        if (!attribute)
            return;

        ValidationRules
            .ensure((o: PointAttribute) => o.name).required().satisfiesRule('notNull')
            .ensure((o: PointAttribute) => o.value).required().satisfiesRule('notNull')
        .on(attribute);

        this.validationController.addObject(attribute);
    }

    tearDownPointAttributeValidation(attribute: PointAttribute) {
        if (!attribute)
            return;

        this.validationController.removeObject(attribute);
        ValidationRules.off(attribute);
    }

    bringPointIntoView(mapImageBoundingRectangle) {
        // Pan x and y only if the image is larger than the container SVG in that dimension.

        // Using loose equivalency due to binding for validation on x which makes it a string.
        const x = this.point.x != 0 && mapImageBoundingRectangle.width > this.mapSvg.clientWidth ?
            this.mapSvg.clientWidth / 2 - this.point.x :
            0;

        const y = this.point.y != 0 && mapImageBoundingRectangle.height > this.mapSvg.clientHeight ?
            this.mapSvg.clientHeight / 2 - this.point.y :
            0;

        this.panZoom.pan({ x, y });
    }

    async attached() {
        this.panZoom = svgPanZoom('#svg-id', {
            fit: false,
            center: false,
            zoomEnabled: true,
            dblClickZoomEnabled: false,
            minZoom: .001,
            onZoom: (scale) => {
                this.scale = scale;
            },
            beforePan: (oldPan, newPan) => {
                if (oldPan.x !== newPan.x || oldPan.y !== newPan.y)
                    this.isPanning = true;
                else
                    this.isPanning = false;
            },
        });

        // Ensure data is loaded.
        await this.loadPromise;

        // Ensure UI has rendered based on bindings from loaded data.
        var interval = setInterval(() => {
            var mapImage = this.mapSvg && this.mapSvg.querySelector('image');
            if (!mapImage)
                return;

            clearInterval(interval);

            var mapImageBoundingRectangle = mapImage.getBoundingClientRect();

            // If the image has loaded (by checking width and height), perform pan, otherwise, perform pan on image load event.
            if (mapImageBoundingRectangle.width && mapImageBoundingRectangle.height)
                this.bringPointIntoView(mapImageBoundingRectangle);
            else
                mapImage.onload = () => {
                    // Requery image size after image is loaded.
                    mapImageBoundingRectangle = mapImage.getBoundingClientRect();
                    this.bringPointIntoView(mapImageBoundingRectangle);
                };
        }, 100);
    }

    createPointAttribute(name = null, value = null): PointAttribute {
        return {
            name,
            value,
            valuePlaceholder: 'Select or enter a value',
            isLoadingValues: false,
            pointAttributeNames: this.pointAttributeNames.slice(0),
            pointAttributeValues: (this.pointAttributeValues[name] || []).slice(0)
        } as PointAttribute;
    }

    addPointAttribute() {
        let pointAttribute = this.createPointAttribute();

        this.pointAttributes.push(pointAttribute);
        this.setupPointAttributeValidation(pointAttribute);
        this.formChanged = true;
    }

    removePointAttribute(attribute: PointAttribute) {
        this.pointAttributes.splice(this.pointAttributes.findIndex(a => a === attribute), 1);
        this.tearDownPointAttributeValidation(attribute);
        this.formChanged = true;
    }

    handlePointTypeTagCreated(tag) {
        for (var i = this.existingPointTypes.length - 1; i >= 0; i--) {
            if (this.existingPointTypes[i].tag)
                this.existingPointTypes.splice(i, 1);
        }

        this.existingPointTypes.push(tag);
        this.point.type = tag.name;
    }

    handlePointAttributeNameChange(attribute, newName) {
        if (!newName || newName in attribute.pointAttributeValues)
            return;

        var originalPlaceholder = attribute.valuePlaceholder;
        attribute.isLoadingValues = true;
        attribute.valuePlaceholder = 'Loading items...';
        attribute.pointAttributeValues = (this.pointAttributeValues[newName] || []).slice(0);
        attribute.valuePlaceholder = originalPlaceholder;
        attribute.isLoadingValues = false;
    }

    handleAttributeNameTagCreated(tag, attribute) {
        for (var i = attribute.pointAttributeNames.length - 1; i >= 0; i--) {
            if (attribute.pointAttributeNames[i].tag)
                attribute.pointAttributeNames.splice(i, 1);
        }

        attribute.pointAttributeNames.push(tag);
        attribute.name = tag.name;
    }

    handleAttributeValueTagCreated(tag, attribute) {
        for (var i = attribute.pointAttributeValues.length - 1; i >= 0; i--) {
            if (attribute.pointAttributeValues[i].tag)
                attribute.pointAttributeValues.splice(i, 1);
        }

        attribute.pointAttributeValues.push(tag);
        attribute.value = tag.value;
    }

    pointPhotoChanged() {
        if (!this.pointPhotoInput.files || !this.pointPhotoInput.files.length)
            return;

        var file = this.pointPhotoInput.files[0];
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            this.dialogPresenter.showAlert(
                'Unsupported Photo Format',
                this.supportedPhotoFormatMessage);

            this.pointPhotoInput.value = '';
        }

        this.fileReader.readAsDataURL(file);
    }

    handlePhotoLoad() {
        this.pointPhotoDataUrl = this.fileReader.result;
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('point-list', { mapId: this.point.mapId || this.mapId });
    }

    async save() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        this.point.attributes.splice(0);
        for (let pointAttribute of this.pointAttributes) {
            this.point.attributes.push({
                name: pointAttribute.name,
                value: pointAttribute.value
            });
        }

        try {
            let savedPoint = await this.pointService.savePoint(this.point, this.pointPhotoInput.files[0]) as any;
            this.point.id = savedPoint.id;

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Point saved successfully.');
            this.router.navigateToRoute('point-detail', { id: this.point.id, mapId: this.mapId }, { replace: true });
        } catch (error) {
            this.logger.error('Error saving point.', error, { point: this.point });
            this.dialogPresenter.showAlert(
                'Error Saving Point',
                this.getApiErrorMessage(error.apiErrorCode));
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        if (errorCode === 1300)
            return 'The format of the point photo is not a supported format. ' + this.supportedPhotoFormatMessage;

        return 'An error occurred while saving the current point. Please try again later.';
    }

    zoomIn() {
        this.panZoom && this.panZoom.zoomIn();
    }

    zoomOut() {
        this.panZoom && this.panZoom.zoomOut();
    }

    setPointCoordinates(event) {

        if (this.isPanning) {

            this.isPanning = false;
            return;
        }

        const pt = this.mapSvg.createSVGPoint();
        pt.x = event.clientX;
        pt.y = event.clientY;

        const pan = this.panZoom.getPan();

        // The cursor point, translated into svg coordinates
        const cursorpt = pt.matrixTransform(this.mapSvg.getScreenCTM().inverse());

        // adjust for zoom
        let pixelX = cursorpt.x / this.scale;
        let pixelY = cursorpt.y / this.scale;

        // adjust for pan
        pixelX = Math.round(pixelX - pan.x / this.scale);
        pixelY = Math.round(pixelY - pan.y / this.scale);

        // set 
        this.point.x = pixelX;
        this.point.y = pixelY;
    }

    async loadRecordChanges() {
        try {
            this.pageContext.isLoading = true;
            this.recordChanges = await this.pointService.getPointRecordChanges(this.point.id);
        } catch (error) {
            this.dialogPresenter.showAlert(
                'Error Loading Point Audit',
                'An error occurred while loading the current point audit. Please try again later.');
        }

        this.pageContext.isLoading = false;
    }
}
