import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import SelectorOption from 'infrastructure/selector-option';
import Courier from './courier';

@autoinject
export default class CourierService {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    async getCouriers(): Promise<Courier[]> {
        let couriers = await this.httpClient.get('couriers');
        return couriers as unknown as Courier[];
    }

    async getCoAReportStyleOptions(couriers: Courier[] = null): Promise<SelectorOption[]> {
        let courierData = (couriers === null) ? await this.getCouriers() : couriers;

        return courierData.map(c => ({
            title: c.id,
            value: c.id 
        }));
    }
}
