import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import PageContext from '../../infrastructure/page-context';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import SupportTopicService from './support-topic-service';

@inject(Router, PageContext, DialogPresenter, Logger, SupportTopicService)
export default class SupportTopicList {
    constructor(router, pageContext, dialogPresenter, logger, supportTopicService) {
        this.router = router;
        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.logger = logger;
        this.logger.name = 'support-topic-list';
        this.supportTopicService = supportTopicService;
    }

    navigateToSupportTopicDetail(id) {
        this.router.navigateToRoute('support-topic-detail', { id });
    }
    
    deleteSupportTopic(topic) {
        this.dialogPresenter
            .showConfirmation('Delete Support Topic', 'Are you sure you want to delete this support topic?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                this.pageContext.isLoading = true;
                this.supportTopicService
                    .deleteSupportTopics([topic.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Support topic deleted successfully.');
                        topic.isActive = false;
                    })
                    .catch(error => {
                        this.logger.error('Error deleting support topic.', error, { supportTopicId: topic.id });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error);
                    });
            });
    }

    activate() {
        (async () => {
            this.pageContext.isLoading = true;
            try {
                var topics = await this.supportTopicService.getSupportTopics();
                this.topics = topics;
                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error loading support topics.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Support Topics',
                    'An error occurred while loading the support topics. Please try again later.');
            }
        })();
    }
};
