import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import Organization from './organization';

@autoinject
export default class OrganizationService {
    constructor(private httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    findOrganizations(startsWith: string) {
        const query = buildQueryString({ startsWith }, true);

        return this.httpClient.get(`organizations?${query}`);
    }

    getOrganization(id) {
        return this.httpClient.get(`organizations/${id}`);
    }

    getOrganizations(): any {
        return this.httpClient.get('organizations');
    }

    getImpersonatingOrganizations(): Promise<Organization[]> {
        return this.httpClient.get('organizations/impersonating') as any as Promise<Organization[]>
    }

    getOrganizationDetail(id) {
        return this.httpClient.get(`organizations/${id}/detail`);
    }

    saveOrganization(organization) {
        return this.httpClient.put(`organizations/${organization.id}`, organization);
    }

    getConfigurationValues(names, id = null) {
        var query = buildQueryString({ names }, true);

        if (!id) id = 'all';

        return this.httpClient.get(`organizations/${id}/configuration${query ? `?${query}` : ''}`);
    }

    getConfigurationsValues(id: number): any {
        return this.httpClient.get(`organizations/${id}/settings`);
    }

    saveConfigurationValues(id, data) {
        return this.httpClient.put(`organizations/${id}/configuration`, data);
    }

    saveConfigurationsValues(id, data) {
        return this.httpClient.put(`organizations/${id}/settings`, data);
    }

    async checkConfigurationFlag(name) {
        let configuration = await this.getConfigurationValues([name]);

        return Object.values(configuration).some(c => c[name]?.toLowerCase() === 'true');
    }

    async getConfiguration(nameOrNames) {
        let isArray = Array.isArray(nameOrNames);
        return await this.getConfigurationValues(isArray ? nameOrNames : [nameOrNames]);
    }

   getImpersonatingConfiguration(nameOrNames) {
        const names = Array.isArray(nameOrNames) ? nameOrNames : [nameOrNames];
        const query = buildQueryString({names}, true);
        
        return this.httpClient.get(`organizations/impersonating/configuration?${query}`);
    }
}
