import { GridOptions, RowNode } from 'ag-grid-community';
import { Grid } from 'ag-grid-enterprise/dist/ag-grid-enterprise';
import { autoinject } from 'aurelia-framework';
import ValidationErrorFormatter from 'infrastructure/validation/validation-error-formatter';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import Logger from '../infrastructure/logger';
import SecurityService from '../security/security-service';
import RemediationRootCause from './remediation-root-cause';
import { remediationRootCauseGridOptions } from './remediation-root-cause-grid';
import RemediationRootCauseService from './remediation-root-cause-service';

@autoinject
export default class RemediationRootCauseList {
    gridOptions: GridOptions;
    remediationRootCauseGrid: Grid;
    gridData: RemediationRootCause[];

    rowDataChanged: boolean;
    canEditRemediationRootCauseList: boolean;

    constructor(
        private readonly logger: Logger,
        private readonly dialogPresenter: DialogPresenter,
        private readonly remediationRootCauseService: RemediationRootCauseService,
        private readonly securityService: SecurityService,
    ) {
        this.logger.name = 'remediation-root-cause-list';
        this.canEditRemediationRootCauseList =
            this.securityService.hasPermission('EditRemediations') &&
            !this.securityService.isImpersonating();
    }

    async loadGridData() {
        try {
            this.gridData = await this.remediationRootCauseService.getRemediationRootCauseList();
        } catch (error) {
            this.logger.error('Error loading remediation root cause list', error);
            this.dialogPresenter.showAlert(
                'Error Loading Remediation Root Causes',
                'An error occured while loading remediation root causes.  Please try again later.',
            );
        }
    }

    async save() {
        this.gridOptions.api.showLoadingOverlay();

        const sortedRowData: RemediationRootCause[] = [];
        this.gridOptions.api.forEachNodeAfterFilterAndSort((node: RowNode) => {
            const { id, name } = node.data;
            sortedRowData.push({ id, name });
        });
        this.gridData = sortedRowData;

        try {
            await this.remediationRootCauseService.createRemediationRootCauseList(this.gridData);
        } catch (error) {
            const errorMessage = ValidationErrorFormatter.format(
                'An error occured. Please try again later.',
                error,
            );

            this.gridOptions.api.hideOverlay();
            this.logger.error('Error loading remediation root cause list', error);
            this.dialogPresenter.showAlert(
                'Error Saving Remediation Root Cause List',
                errorMessage,
            );
        }

        this.gridOptions.api.hideOverlay();
        this.rowDataChanged = false;
    }

    removeUnsavedRows() {
        const rowNodeDataToRemove: any[] = [];
        this.gridOptions.api.getModel().forEachNode((rowNode: RowNode) => {
            const { name } = rowNode.data;
            if (!this.gridData.map((item) => item.name).includes(name)) {
                rowNodeDataToRemove.push(rowNode.data);
            }
        });

        this.gridOptions.api.applyTransaction({
            remove: rowNodeDataToRemove,
        });
    }

    async canActivate() {
        await this.loadGridData();
    }

    bind() {
        this.gridOptions = remediationRootCauseGridOptions;
        this.gridOptions.rowData = this.gridData;
        this.gridOptions.onRowDataUpdated = () => (this.rowDataChanged = true);
        new Grid(this.remediationRootCauseGrid, this.gridOptions);
    }

    async canDeactivate() {
        if (!this.rowDataChanged) {
            return true;
        }

        return await this.dialogPresenter.showLoseChangesConfirmation();
    }
}
