import { Calendar, createElement } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import timeGridPlugin from '@fullcalendar/timegrid';
import { bindable, bindingMode, inject } from 'aurelia-framework';
import moment from 'moment';
import DateUtility from '../date-utility';

class CustomDayHeader {
    render() {
        return createElement('div', {}, this.props.text)
    }
}

//export interface FullCalendarEvent {
//    id?: string;
//    groupId?: string;
//    title: string;
//    start: string;
//    end: string;
//    description?: string;
//    allDay?: boolean;
//    url?: string;
//    extendedProps?: any;
//    backgroundColor?: string;           // override the default
//}

//export type FullCalendarView = 'dayGridMonth' | 'timeGridWeek';

@inject(Element)
export class FullCalendar {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) events;
    @bindable navLinks;        // can click day/week names to navigate views
    @bindable dayMaxEvents;    // allow "more" link when too many events
    @bindable editable;
    @bindable initialDate;
    @bindable initialView;

    // DOM
    calendarElement;
    calendar;
    
    // state
    isAttached;
    tooltips = [];

    constructor(element) {
        this.element = element;
        this.eventClick = this.eventClick.bind(this);
    }

    attached() {
        this.initialize();
        this.isAttached = true;
    }

    detached() {
        this.calendar?.destroy();
        this.calendar = null;

        this.isAttached = false;
    }

    initialize() {
        let initialDate =
            this.initialDate && typeof this.initialDate === 'string' ? DateUtility.stringToMoment(this.initialDate) : 
            this.initialDate ? moment(this.initialDate).format('YYYY-MM-DD') :
            this.initialDate;

        this.calendar = new Calendar(this.calendarElement, {
            plugins: [ momentPlugin, interactionPlugin, dayGridPlugin, timeGridPlugin ],
            titleFormat: '{MMMM {D}}, YYYY',
            headerToolbar: {
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            },
            height: '100%',
            initialDate: initialDate ?? moment().format('YYYY-MM-DD'),
            initialView: 'timeGridWeek',
            slotDuration: '00:15',
            navLinks: this.navLinks ?? true,
            editable: this.editable ?? true,
            dayMaxEvents: this.dayMaxEvents ?? true,
            dayMaxEventRows: 1,
            eventMaxStack: 1,
            expandRows: false,
            dayHeaderContent(arg) {
              return createElement(CustomDayHeader, { text: arg.text })
            },
            nowIndicator: true,
            handleWindowResize: true,
            eventClick: this.eventClick,
        });

        this.addEventSources();
    }

    render() {
        this.calendar?.render();
    }

    gotoDate(date) {
        this.calendar?.gotoDate(date);
    }

    switchView(view, date) {
        this.calendar?.changeView(view, date);
    }

    eventsChanged() {
        this.addEventSources();
        this.render();
    }

    eventClick (info) {
        let event = info?.event;
        if (event) {
            this.element.dispatchEvent(new CustomEvent('fullcalendareventclick', {
                bubbles: true,
                detail: {
                    event: event,
                    element: info.el
                }
            }));
        }
    }

    addEventSources() {
        if (!this.calendar)
            return;

        this.calendar.removeAllEventSources();
        this.calendar.addEventSource( {
            events: this.events,
        });
    }
}
