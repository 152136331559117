import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import { DashboardQuery } from 'dashboard/dashboard-query-filters';
import moment from 'moment';
import 'moment-timezone';
import Task from './task';
import { TaskCounts } from './task-counts';

@autoinject
export default class TaskService {
    ianaTimeZone: any;

    constructor(private httpClient: HttpClient) {
        this.ianaTimeZone = (moment as any).tz.guess();
    }

    getTasks(taskFilters, view, includePlan?: boolean) {
        var queryParameters = taskFilters;
        queryParameters.view = view;
        queryParameters.includePlan = includePlan;

        var query = buildQueryString(queryParameters, true);

        return this.httpClient.get(`location-testing/tasks${(query ? `?${query}` : '')}`);
    }

    getTask(id, includePlan?: boolean) {
        return this.httpClient.get(`location-testing/tasks/${id}${(includePlan ? '?includePlan=true' : '')}`);
    }

    getTaskStatuses() {
        return [
            { id: 1, code: 'NotStarted', name: 'Not Started' },
            { id: 2, code: 'InProgress', name: 'In Progress' },
            { id: 3, code: 'Completed', name: 'Completed' },
            { id: 4, code: 'DidNotComplete', name: 'Did Not Complete' },
        ];
    }

    toBatches<T>(items: T[], batchSize) {
        var copiedItems = [...items];
        var batches: T[][] = [];

        while (copiedItems.length)
            batches.push(copiedItems.splice(0, batchSize));

        return batches;
    }

    deleteTasks(ids: number[]) {
        var idBatches = this.toBatches(ids, 200);
        return Promise.all(idBatches.map(ids => {
            var query = ids.map(id => `id=${id}`).join('&');
            return this.httpClient.delete(`location-testing/tasks?${query}`);
        }));
    }

    async batchUpdateTasks(action, ids: number[], additionalParameters = {}) {
        var idBatches = this.toBatches(ids, 200);
        var results = await Promise.all(idBatches.map(ids => {
            var data = { ids, ...additionalParameters };
            return this.httpClient.put(`location-testing/tasks/batch?action=${action}`, data) as any as any[];
        }));

        var flattenedResults = [];
        for (let result of results)
            flattenedResults.push(...result);

        return flattenedResults;
    }

    assignTasks(ids: number[], assignedUserId) {
        return this.batchUpdateTasks('assign', ids, { assignedUserId });
    }

    unassignTasks(ids: number[]) {
        return this.batchUpdateTasks('unassign', ids);
    }

    updateTaskStatus(ids: number[], statusId, collectedDateTime, comments) {
        return this.batchUpdateTasks('update-status', ids, { statusId, collectedDateTime, comments });
    }

    getTaskCounts(dashboardQuery: DashboardQuery): Promise<TaskCounts> {
        const query = buildQueryString(dashboardQuery, true);
        return this.httpClient.get(`location-testing/task-counts/${(query ? `?${query}` : '')}`) as unknown as Promise<TaskCounts>;
    }

    getCriticalTaskCounts(organizationIds) {
        var query = buildQueryString({
            // Map organization IDs to multiple 'organizationId' query string variables.
            organizationId: organizationIds
        }, true);
        return this.httpClient.get(`location-testing/task-counts/critical${(query ? `?${query}` : '')}`);
    }

    saveTask(task) {
        if (!task.id)
            return this.httpClient.post('location-testing/tasks', task);

        return this.httpClient.put(`location-testing/tasks/${task.id}`, task);
    }

    createTasks(tasks: Task[]) {
        return this.httpClient.post('location-testing/tasks/bulk', tasks);
    }

    getTaskRecordChanges(id) {
        return this.httpClient.get(`location-testing/tasks/${id}/record-changes`);
    }

    printLabels(taskIds, printerId) {
        var request = {
            ids: taskIds,
            printerId: printerId,
            ianaTimeZone: this.ianaTimeZone
        }

        return this.httpClient.post('location-testing/tasks/print-labels/', request);
    }

    exportLabelsCsv(taskIds, fields) {
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        let selectedFields = [];
        fields.forEach(f => selectedFields.push(capitalizeFirstLetter(f)));

        var request = {
            ids: taskIds,
            selectedFields: selectedFields,
            ianaTimeZone: this.ianaTimeZone
        }

        var query = buildQueryString(request, true);

        return this.httpClient.get(`location-testing/tasks/export-labels-csv${(query ? `?${query}` : '')}`);
    }

    getExportableLabelsCsvFields(taskIds) {
        var request = {
            ids: taskIds
        }

        var query = buildQueryString(request, true);

        return this.httpClient.get(`location-testing/tasks/export-labels-csv?fields=true${(query ? `&${query}` : '')}`);
    }

    getTaskLabelPdfUrl(taskIds, labelTemplateId) {
        var query = buildQueryString({
            taskIds: taskIds,
            labelTemplateId,
            ianaTimeZone: this.ianaTimeZone
        }, true);
        return `api/location-testing/tasks/labels-pdf${(query ? `?${query}` : '')}`;
    }

    getExporTaskCollectionTemplate(
        taskIds: number[],
        exportTaskCollectionTemplateId: number,
        exportTaskCollectionTemplateName: string) {
        const queryString = buildQueryString(
            { 
                taskIds, 
                exportTaskCollectionTemplateId, 
                exportTaskCollectionTemplateName,
                ianaTimeZone: this.ianaTimeZone
            }, true);

        return `api/location-testing/tasks/export-task-collection-template?${queryString}`;
    }
}
