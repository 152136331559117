import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class RoleService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    saveRole(role) {
        if (role.id)
            return this
                .httpClient
                .put('roles/' + role.id, role);

        return this
            .httpClient
            .post('roles', role);
    }

    getRole(id) {
        return this
            .httpClient
            .get('roles/' + id);
    }

    getRoles() {
        return this
            .httpClient
            .get('roles');
    }

    deleteRole(id) {
        return this
            .httpClient
            .delete('roles/' + id);
    }

    getUsersInRole(id) {
        return this
            .httpClient
            .get('roles/' + id + '/users');
    }

    getPermissions() {
        return this
            .httpClient
            .get('roles/permissions');
    }

    getCurrentPermissions() {
        return this
            .httpClient
            .get('roles/current/permissions');
    }
}
