enum OutcomeStatus {
    ALL = 'All',
    PASS = 'Pass',
    FAIL = 'Fail',
    INCOMPLETE = 'Incomplete',
}

export default OutcomeStatus;

export function getOutcomeStatusOptions() {
    return [
        { value: OutcomeStatus.ALL, title: 'All' },
        { value: OutcomeStatus.PASS, title: 'Pass' },
        { value: OutcomeStatus.FAIL, title: 'Fail' },
        { value: OutcomeStatus.INCOMPLETE, title: 'Incomplete' },
    ];
}
