import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import TaskGenerationRequest from './task-generation-request';
import Logger from '../../infrastructure/logger';
import TaskGenerationClientSettings from './task-generation-client-settings';
import GenerateTasksPointZoneDistributionMethod from './generate-tasks-point-zone-distribution-method';

@autoinject
export default class TaskGenerationRequestService {
    constructor(
        private httpClient: HttpClient,
        private logger: Logger
    ) {
        this.httpClient = httpClient;
    }

    submit(request: TaskGenerationRequest) {
        return this.httpClient.post('location-testing/task-generation-requests', request);
    }

    getPlanSettings(planId: number): TaskGenerationClientSettings {
        let storageKey = `${'taskGenerationPlanSettings'}-${planId}`;

        try {
            return JSON.parse(localStorage.getItem(storageKey)) as TaskGenerationClientSettings;
        } catch (error) {
            this.logger.warn(`Failed to parse local storage value for '${storageKey}'.`);
            return null;
        }
    }

    savePlanSettings(planId: number, settingsToPersist: TaskGenerationClientSettings) {
        var settings = (this.getPlanSettings(planId) || {}) as TaskGenerationClientSettings;

        settings.pointZoneDistributionMethod = settingsToPersist.pointZoneDistributionMethod;
        settings.pointZoneDistributions = settingsToPersist.pointZoneDistributions;
        settings.riskPriorities = settingsToPersist.riskPriorities;
        settings.dateSelectionType = settingsToPersist.dateSelectionType;

        if(settingsToPersist.pointZoneDistributionMethod === GenerateTasksPointZoneDistributionMethod.NOT_APPLICABLE ||
            settingsToPersist.pointZoneDistributionMethod === GenerateTasksPointZoneDistributionMethod.PERCENTAGE){
                settings.requestedTaskCount = settingsToPersist.requestedTaskCount;
            } else {
               delete settings.requestedTaskCount;
            }

        let storageKey = `${'taskGenerationPlanSettings'}-${planId}`;
        localStorage.setItem(storageKey, JSON.stringify(settings));
    }
}

