import { autoinject, bindable } from 'aurelia-framework';
import TestMethod from 'test-methods/test-method';
import CapPlanService from '../cap-plan-service';
import { RelatedCapPlan } from '../contracts/related-cap-plan';

@autoinject
export class RelatedCapPlansCustomElement {
    @bindable capPlanId?: number;
    @bindable organizationId: number;
    @bindable testMethods: TestMethod[];
    relatedCapPlans: RelatedCapPlan[];

    constructor(private readonly capPlanService: CapPlanService) {
        this.testMethods = [];
        this.relatedCapPlans = [];
    }

    private async getRelatedCapPlans() {
        if (this.testMethods.length === 0) {
            return;
        }

        this.relatedCapPlans = await this.capPlanService.getRelatedCapPlans(
            this.organizationId,
            this.testMethods.map((tm: TestMethod) => tm.id),
            this.capPlanId || 0,
        );
    }

    async testMethodsChanged() {
        await this.getRelatedCapPlans();
    }

    async bind() {
        await this.getRelatedCapPlans();
    }
}
