import { EventAggregator } from 'aurelia-event-aggregator';
import { inject } from "aurelia-framework";
import DialogPresenter from '../dialogs/dialog-presenter';

@inject(Element, DialogPresenter, EventAggregator)
export class ClientRefresh {

    constructor(element, dialogPresenter, eventAggregator) {
        this.element = element;
        this.dialogPresenter = dialogPresenter;
        this.eventAggregator = eventAggregator;
        this.clientIsCurrent = true;
    }

    handleClientIsCurrent() {
        this.clientIsCurrent = true;
    }

    handleClientRefresh(version) {
        const { clientVersion } = version;
        this.newClientVersion = clientVersion;
        this.clientIsCurrent = false;
    }

    async showClientVersionReloadDialog() {
        if (await this.dialogPresenter.showClientVersionReloadDialog())
            window.location.reload();
    }

    attached() {
        this.clientIsCurrentSubscription = this.eventAggregator.subscribe('client.isCurrent', () => this.handleClientIsCurrent());
        this.refreshClientSubscription = this.eventAggregator.subscribe('client.refresh', (version) => this.handleClientRefresh(version));
    }

    detached() {
        this.clientIsCurrentSubscription && this.clientIsCurrentSubscription.dispose();
        this.refreshClientSubscription && this.refreshClientSubscription.dispose();
    }
}
