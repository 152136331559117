import DisplayOnlyElementManager from './display-only-element-manager';
import UiLabelTemplate from './ui-label-template';
import UiLabelTemplateElement from './ui-label-template-element';

export default class SampleLabelDisplayOnlyElementManager implements DisplayOnlyElementManager {
    sampleIdElement: UiLabelTemplateElement;
    sampleDescriptionElement: UiLabelTemplateElement;
    collectionDateElement: UiLabelTemplateElement;
    testCodesElement: UiLabelTemplateElement;

    margin: number;
    fontSize: number;
    lineSpacing: number;

    constructor(private uiLabelTemplate: UiLabelTemplate) {
        const { elements } = this.uiLabelTemplate;
        this.margin = 6;
        this.fontSize = Array.isArray(elements) && elements.length ? elements[0].fontSize : 7;
        this.lineSpacing = 3;
    }

    createDisplayOnlyElements() {
        this.sampleIdElement = UiLabelTemplateElement.new(this.uiLabelTemplate, 'text', true);
        this.sampleIdElement.sampleTextOverride = '12-123456-001';
        this.sampleIdElement.bold = true;

        this.sampleDescriptionElement = UiLabelTemplateElement.new(
            this.uiLabelTemplate,
            'text',
            true,
        );
        this.sampleDescriptionElement.sampleTextOverride = 'This is an example sample description';

        this.collectionDateElement = UiLabelTemplateElement.new(this.uiLabelTemplate, 'text', true);
        this.collectionDateElement.sampleTextOverride = '6/18/2021 8:00 AM';
        this.collectionDateElement.bold = true;

        this.positionDisplayOnlyElements();

        this.uiLabelTemplate.elements.push(this.sampleIdElement);
        this.uiLabelTemplate.elements.push(this.sampleDescriptionElement);
        this.uiLabelTemplate.elements.push(this.collectionDateElement);
    }

    positionDisplayOnlyElements() {
        this.sampleIdElement.x = this.margin;
        this.sampleIdElement.y = this.margin;
        this.sampleIdElement.width = this.uiLabelTemplate.width - 2 * this.margin;
        this.sampleIdElement.textAlign = 'right';

        this.sampleDescriptionElement.x = this.margin;
        this.sampleDescriptionElement.y = this.margin + this.fontSize + this.lineSpacing;
        this.sampleDescriptionElement.width = this.uiLabelTemplate.width - 2 * this.margin;

        this.collectionDateElement.x = this.margin;
        this.collectionDateElement.y = this.margin;
        this.collectionDateElement.width = this.uiLabelTemplate.width - 2 * this.margin;
    }
}
