import { Subscription } from 'aurelia-event-aggregator';
import { BindingEngine, autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
    ValidateResult,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
    validateTrigger,
} from 'aurelia-validation';
import SelectorOption from 'infrastructure/selector-option';
import ApplicationModuleService from '../application-modules/application-module-service';
import CompareUtility from '../infrastructure/compare-utility';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import Logger from '../infrastructure/logger';
import PageContext from '../infrastructure/page-context';
import LabService from '../labs/lab-service';
import TestMethodService from '../test-methods/test-method-service';
import OrganizationConfiguration from './organization-configuration';
import OrganizationService from './organization-service';

function mergeModules(organization, modules) {
    if (!organization || !organization.applicationModules || !modules) return;

    for (var i = 0; i < organization.applicationModules.length; i++) {
        modules.find((m) => m.token === organization.applicationModules[i].token).isSelected = true;
    }
}

@autoinject
export default class OrganizationDetail {
    @observable testMethodFilterText;
    @observable moduleFilterText;
    @observable childrenFilterText;

    validationController: ValidationController;
    testMethodGridOptions: any;

    moduleGridOptions: any;
    childrenGridOptions: any;
    configurationGridOptions: any;

    labOptions: SelectorOption[];
    labCodeSubscription: Subscription;
    labCodesSubscription: Subscription;

    // state
    currentView: string;
    views: any;

    organizationView: any;
    organization: any;

    formChanged: boolean;
    allModulesSelected: boolean;

    testMethodsView: any;
    modules: any;
    modulesView: any;
    childrenView: any;

    configurationsView: any;

    constructor(
        private router: Router,
        private bindingEngine: BindingEngine,
        validationControllerFactory: ValidationControllerFactory,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private logger: Logger,
        private organizationService: OrganizationService,
        private labService: LabService,
        private testMethodService: TestMethodService,
        private applicationModuleService: ApplicationModuleService,
    ) {
        this.router = router;
        this.bindingEngine = bindingEngine;
        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.organizationService = organizationService;
        this.labService = labService;
        this.testMethodService = testMethodService;
        this.applicationModuleService = applicationModuleService;
        this.logger = logger;
        this.logger.name = 'organization-detail';

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.testMethodFilterText = '';
        this.moduleFilterText = '';
        this.childrenFilterText = '';

        this.testMethodGridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    headerName: 'Test Method',
                    field: 'name',
                    comparator: CompareUtility.compareStringsInsensitive,
                    sort: 'asc',
                },
                {
                    suppressMenu: true,
                    headerName: 'Bill Code',
                    field: 'billCode',
                    comparator: CompareUtility.compareStringsInsensitive,
                },
                {
                    suppressMenu: true,
                    headerName: 'Customer Price',
                    field: 'quotedPrice',
                    template: '${data.quotedPrice | currency}',
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.moduleGridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template:
                        '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isModulesSelectedChanged()" disabled.bind="organizationView.parentId"></label>',
                    headerCellTemplate:
                        '<label click.delegate="allModulesSelectedClicked()" if.bind="organizationView.parentId === null"><input type="checkbox" checked.bind="allModulesSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Module',
                    field: 'token',
                    comparator: CompareUtility.compareStringsInsensitive,
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.childrenGridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    headerName: 'Organization Name',
                    field: 'name',
                    comparator: CompareUtility.compareStringsInsensitive,
                    sort: 'asc',
                },
                {
                    suppressMenu: true,
                    headerName: 'LIMS Client ID',
                    field: 'externalId',
                    comparator: CompareUtility.compareStringsInsensitive,
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.configurationGridOptions = {
            columnDefs: [
                { suppressMenu: true, headerName: 'Option', field: 'displayName', width: 400 },
                {
                    suppressMenu: true,
                    headerName: 'Value',
                    template:
                        '<label if.bind="data.type === \'boolean\'"><input type="checkbox" checked.bind="data.isSelected"></label>' +
                        '<div if.bind="data.type === \'emailAddresses\'"><input type="text" value.bind="data.emailRecipients & validate"></label><error-tooltip message="Email addresses should be valid and separated by a semi-colon."></error-tooltip></div>' +
                        '<select2 if.bind="data.type === \'lab\'" items.bind="labOptions" selected-value.bind="data.labCode"></select2>' +
                        '<select2 if.bind="data.type === \'partialTestResultLabs\'" items.bind="labOptions" multiple="true" selected-values.bind="data.partialTestResultLabs & validate"></select2>',
                    width: 300,
                    sortable: false,
                    suppressSizeToFit: true,
                },
            ],
            defaultColDef: { sortable: false, resizable: true },
        };

        this.currentView = 'test-methods';
        this.views = [
            { name: 'test-methods', title: 'Test Methods', hasErrors: false },
            { name: 'modules', title: 'Modules', hasErrors: false },
            { name: 'children', title: 'Children', hasErrors: false },
            { name: 'configuration', title: 'Configuration', hasErrors: false },
        ];
    }

    setCurrentView(view) {
        this.currentView = view;
    }

    isModulesSelectedChanged() {
        this.allModulesSelected = this.modulesView.every((u) => u.isSelected);
    }

    allModulesSelectedClicked() {
        this.allModulesSelected = !this.modulesView.every((u) => u.isSelected);

        for (let module of this.modulesView) module.isSelected = this.allModulesSelected;

        return true;
    }

    updateView() {
        if (!this.organization) return;
        this.organizationView = JSON.parse(JSON.stringify(this.organization));
        delete this.organizationView.testMethods;
        delete this.organizationView.applicationModules;
        delete this.organizationView.children;
        delete this.organizationView.configurations;
    }

    updateChildrenView() {
        if (!this.organization || !this.organization.children) return;

        var lowerCasedFilterText = this.childrenFilterText.toLowerCase();

        this.childrenView = this.organization.children.filter(
            (c) =>
                (c.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (c.externalId || '').toLowerCase().indexOf(lowerCasedFilterText) > -1,
        );
    }

    updateTestMethodView() {
        if (!this.organization || !this.organization.testMethods) return;

        var lowerCasedFilterText = this.testMethodFilterText.toLowerCase();

        this.testMethodsView = this.organization.testMethods.filter(
            (tm) =>
                (tm.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (tm.billCode || '').toLowerCase().indexOf(lowerCasedFilterText) > -1,
        );
    }

    updateModuleView() {
        if (!this.modules) return;

        var lowerCasedFilterText = this.moduleFilterText.toLowerCase();

        this.modulesView = this.modules.filter(
            (m) => (m.token || '').toLowerCase().indexOf(lowerCasedFilterText) > -1,
        );

        this.allModulesSelected = this.modulesView.every((u) => u.isSelected);
    }

    updateConfigurationView() {
        if (!this.organization || !this.organization.configurations) return;

        let allowStandardRequestSubmission =
            this.organization.configurations?.allowStandardRequestSubmission?.toLowerCase() ===
                'true' ?? false;
        let allowPartialRequestSpecification =
            this.organization.configurations?.allowPartialRequestSpecification?.toLowerCase() ===
                'true' ?? false;
        let allowCompositing =
            this.organization.configurations?.allowCompositing?.toLowerCase() === 'true' ?? false;
        let defaultLabCode = this.organization.configurations?.defaultLabCode;
        let requestSubmissionEmailRecipients =
            this.organization.configurations?.requestSubmissionEmailRecipients;
        let partialTestResultEnabledLabs =
            this.organization.configurations?.partialTestResultLabs?.split(',');

        this.configurationsView = [
            {
                type: 'boolean',
                displayName: 'Allow Standard Request Submission',
                fieldName: OrganizationConfiguration.ALLOW_STANDARD_REQUEST_SUBMISSION,
                isSelected: allowStandardRequestSubmission,
            },
            {
                type: 'boolean',
                displayName: 'Allow Compositing',
                fieldName: OrganizationConfiguration.ALLOW_COMPOSITING,
                isSelected: allowCompositing,
            },
            {
                type: 'lab',
                displayName: 'Default Lab',
                fieldName: OrganizationConfiguration.DEFAULT_LAB_CODE,
                labCode: defaultLabCode,
            },
            {
                type: 'emailAddresses',
                displayName: 'Request Submission Email Recipients',
                fieldName: OrganizationConfiguration.REQUEST_SUBMISSION_EMAIL_RECIPIENTS,
                emailRecipients: requestSubmissionEmailRecipients,
            },
            {
                type: 'boolean',
                displayName: 'Disable Task Related Emails',
                fieldName: OrganizationConfiguration.DISABLE_TASK_RELATED_EMAILS,
                isSelected:
                    this.organization.configurations?.disableTaskRelatedEmails?.toLowerCase() ===
                        'true' ?? false,
            },
            {
                type: 'boolean',
                displayName: 'Enable Segmentation',
                fieldName: OrganizationConfiguration.ENABLE_SEGMENTATION,
                isSelected:
                    this.organization.configurations?.hasSegmentAccess?.toLowerCase() === 'true' ??
                    false,
            },
            {
                type: 'partialTestResultLabs',
                displayName: 'Partial Test Result Labs',
                fieldName: OrganizationConfiguration.PARTIAL_TEST_RESULT_LABS,
                partialTestResultLabs: partialTestResultEnabledLabs,
            },
        ];
    }

    testMethodFilterTextChanged() {
        this.updateTestMethodView();
    }

    moduleFilterTextChanged() {
        this.updateModuleView();
    }

    childrenFilterTextChanged() {
        this.updateChildrenView();
    }

    activate(params) {
        this.pageContext.isLoading = true;

        (async () => {
            try {
                let configurationValues = [
                    OrganizationConfiguration.ALLOW_STANDARD_REQUEST_SUBMISSION,
                    OrganizationConfiguration.ALLOW_COMPOSITING,
                    OrganizationConfiguration.DEFAULT_LAB_CODE,
                    OrganizationConfiguration.REQUEST_SUBMISSION_EMAIL_RECIPIENTS,
                    OrganizationConfiguration.DISABLE_TASK_RELATED_EMAILS,
                    OrganizationConfiguration.ENABLE_SEGMENTATION,
                    OrganizationConfiguration.PARTIAL_TEST_RESULT_LABS,
                ];

                const [
                    organization,
                    configurations,
                    testMethods,
                    applicationModules,
                    modules,
                    labs,
                ] = await Promise.all([
                    this.organizationService.getOrganization(params.id),
                    this.organizationService.getConfigurationValues(configurationValues, params.id),
                    this.testMethodService.getTestMethods(params.id),
                    this.applicationModuleService.getApplicationModules(params.id),
                    this.applicationModuleService.getApplicationModules(),
                    this.labService.getLabs(),
                ]);

                this.organization = organization;
                this.organization.configurations = configurations;
                this.organization.testMethods = testMethods;
                this.organization.applicationModules = applicationModules;
                this.modules = modules;
                this.labOptions = labs.map((l) => ({
                    title: `${l.externalId} - ${l.displayName ?? `${l.city}, ${l.state}`}`,
                    value: l.externalId,
                }));

                this.updateTestMethodView();

                this.updateModuleView();
                mergeModules(this.organization, this.modulesView);
                this.allModulesSelected = this.modulesView.every((m) => m.isSelected);

                this.updateConfigurationView();

                this.updateView();
                this.updateChildrenView();

                this.setupValidation();
                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error loading organization.', error, { id: params.id });
                this.pageContext.isLoading = false;
            }
        })();
    }

    handleFormChange() {
        this.formChanged = true;
    }

    setupValidation() {
        const labCode = this.configurationsView.find(
            (t) => t.fieldName === OrganizationConfiguration.DEFAULT_LAB_CODE,
        );
        const labCodes = this.configurationsView.find(
            (t) => t.fieldName === OrganizationConfiguration.PARTIAL_TEST_RESULT_LABS,
        );
        this.labCodeSubscription = this.bindingEngine
            .propertyObserver(labCode, 'labCode')
            .subscribe(this.handleFormChange.bind(this));
        this.labCodesSubscription = this.bindingEngine
            .propertyObserver(labCodes, OrganizationConfiguration.PARTIAL_TEST_RESULT_LABS)
            .subscribe(this.handleFormChange.bind(this));

        ValidationRules.ensure('name')
            .displayName('Organization Name')
            .required()
            .on(this.organizationView);

        this.validationController.addObject(this.organizationView);

        // validation for individual configuration properties
        let emailRecipients = this.configurationsView.find(
            (x) => x.fieldName === OrganizationConfiguration.REQUEST_SUBMISSION_EMAIL_RECIPIENTS,
        );

        // eslint-disable-next-line
        let regex: RegExp =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        ValidationRules.ensure('emailRecipients')
            .satisfies((value) => {
                if (value === null || value === undefined) return true;

                let emailAddresses = value.split(';');
                return emailAddresses.every(
                    (em) =>
                        em === null ||
                        em === undefined ||
                        (em as any).length === 0 ||
                        regex.test(em),
                );
            })
            .on(emailRecipients);

        this.validationController.addObject(emailRecipients);
    }

    updateValidationIndicators(results: ValidateResult[]) {
        this.views.forEach((view) => {
            if (view.name === 'configuration') {
                let result = results.find((vr) => vr.propertyName === 'emailRecipients');
                view.hasErrors = result ? !result.valid : false;
            } else view.hasErrors = false;
        });
    }

    getOrganizationForSave() {
        var clone = JSON.parse(JSON.stringify(this.organizationView));
        clone.applicationModules = this.modulesView.filter((tm) => tm.isSelected);

        return clone;
    }

    willRemoveApplicationModules(organization) {
        if (
            !this.organization.applicationModules ||
            this.organization.applicationModules.length === 0
        )
            return false;

        if (organization.applicationModules.length === 0) return true;

        var toBeRemoved = this.organization.applicationModules.filter(function (am1) {
            return !organization.applicationModules.some(function (am2) {
                return am1.id === am2.id;
            });
        });

        if (toBeRemoved.length > 0) return true;

        return false;
    }

    async saveOrganization() {
        var validationResults = await this.validationController.validate();
        this.updateValidationIndicators(validationResults.results);
        if (!validationResults.valid) return;

        var organization = this.getOrganizationForSave();
        if (this.willRemoveApplicationModules(organization))
            this.showRolePermissionRemovalWarning();
        else this.saveOrganizationCommand(organization);

        this.saveOrganizationConfiguration(organization);
    }

    saveOrganizationCommand(organization) {
        this.pageContext.isLoading = true;

        if (!organization) organization = this.getOrganizationForSave();

        Promise.all([
            this.organizationService.saveOrganization(organization),
            this.organization.parentId === null
                ? this.applicationModuleService.saveApplicationModules(
                      organization.id,
                      organization.applicationModules,
                  )
                : Promise.resolve({}),
        ])
            .then((results) => {
                this.formChanged = false;
                this.pageContext.isLoading = false;
                this.pageContext.showSuccessOverlay('Organization saved successfully.');
                this.router.navigateToRoute('organization-list');
            })
            .catch((error) => {
                this.logger.error('Error saving organization.', error, { organization });
                this.pageContext.isLoading = false;
                this.handleSaveError(error);
            });
    }

    saveOrganizationConfiguration(organization) {
        var configuration = {};

        this.configurationsView.forEach((entry) => {
            switch (entry.type) {
                case 'boolean':
                    configuration[entry.fieldName] = entry.isSelected;
                    break;

                case 'lab':
                    configuration[entry.fieldName] = entry.labCode;
                    break;

                case 'emailAddresses':
                    configuration[entry.fieldName] = entry.emailRecipients;
                    break;

                case 'partialTestResultLabs':
                    configuration[entry.fieldName] = entry.partialTestResultLabs?.join();
                    break;
            }
        });

        this.organizationService.saveConfigurationValues(organization.id, configuration);
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('organization-list');
    }

    handleSaveError(error: any) {
        this.dialogPresenter.showAlert(
            'Error Saving Organization',
            this.getApiErrorMessage(error.apiErrorCode),
        );
    }

    getApiErrorMessage(errorCode) {
        if (errorCode === 102)
            return 'You cannot modify application modules for a child organization.';

        return 'An unexpected error occurred while trying to save organization. Please try again later.';
    }

    showRolePermissionRemovalWarning() {
        this.dialogPresenter
            .showConfirmation(
                'Delete Associated Role Permissions',
                'Removing application modules may result in removing permissions, within those application modules, from their associated roles. This action cannot be undone, are you sure?',
            )
            .then((confirmed) => {
                if (!confirmed) return;

                var organization = this.getOrganizationForSave();
                this.saveOrganizationCommand(organization);
            });
    }

    deactivate() {
        this.labCodeSubscription?.dispose();
        this.labCodesSubscription?.dispose();
    }
}
