import { AgPromise, IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';
import { AureliaCellEditorCompBase } from 'infrastructure/ag-grid/aurelia-cell-editor-comp-base';

const template = `<datepicker
                    style="display: block; width: calc(100% - 10px)"
                    pick-time.bind="true"
                    value.bind="data.collectionDate"
                    max-selectable-date.bind="maxCollectionDate">
                </datepicker>`;

export class AgDatePickerEditor extends AureliaCellEditorCompBase {
    private _datePickerInput: HTMLInputElement;
    private _charWasPressed: boolean;

    constructor() {
        super(template);
    }

    init(params: ICellEditorParams): void | AgPromise<void> {
        const { charPress, value } = params;
        super.init(params);
        this._datePickerInput = this.rootElement.querySelector('input');

        this._charWasPressed = charPress?.length > 0;
        this._datePickerInput.value = charPress || value;
        this._datePickerInput.setAttribute('data-cy', 'ag-popup-editor-input');
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
        this._datePickerInput.focus();
        if (this._charWasPressed) {
            this._datePickerInput.setSelectionRange(20, 20);
        }
    }

    getValue() {
        return this._datePickerInput.value;
    }
}
