import { DialogService } from 'aurelia-dialog';
import { inject } from 'aurelia-framework';
import Alert from './alert';
import Confirmation from './confirmation';
import { DataGridReorderDialog } from './data-grid-reorder-dialog';
import ExportableFieldSelector from './exportable-field-selector';
import LabelSelector from './label-selector';
import NameValueItemSelector from './name-value-item-selector';
import UserSelector from './user-selector';

@inject(DialogService)
export default class DialogPresenter {
    constructor(dialogService) {
        this.dialogService = dialogService;
    }

    showAlert(title, message, okButtonTitle) {
        return new Promise((resolve) => {
            this.dialogService
                .open({
                    viewModel: Alert,
                    model: {
                        title,
                        message,
                        okButtonTitle,
                    },
                })
                .whenClosed((response) => resolve());
        });
    }

    showConfirmation(title, message, okButtonTitle, cancelButtonTitle) {
        return new Promise((resolve) => {
            this.dialogService
                .open({
                    viewModel: Confirmation,
                    model: {
                        title,
                        message,
                        okButtonTitle,
                        cancelButtonTitle,
                    },
                })
                .whenClosed((response) => resolve(!response.wasCancelled));
        });
    }

    showLoseChangesConfirmation() {
        return this.showConfirmation(
            'Lose Changes?',
            'You may have unsaved changes. Are you sure you want to leave the page?',
            'Leave',
            'Stay',
        );
    }

    showUserSelector(title) {
        return new Promise((resolve) => {
            this.dialogService
                .open({
                    viewModel: UserSelector,
                    model: { title: title || 'Select a user' },
                })
                .whenClosed((response) => resolve(response));
        });
    }

    showExportableFieldSelector(title, fields, selected) {
        return new Promise((resolve) => {
            this.dialogService
                .open({
                    viewModel: ExportableFieldSelector,
                    model: {
                        title: title || 'Select fields to be included with the export',
                        fields: fields,
                        selected: selected,
                    },
                })
                .whenClosed((response) => resolve(response));
        });
    }

    showLabelSelector(title, labelTemplateType, organizationId) {
        return new Promise((resolve) => {
            this.dialogService
                .open({
                    viewModel: LabelSelector,
                    model: {
                        title: title || 'Select a label',
                        labelTemplateType,
                        organizationId,
                    },
                })
                .whenClosed((response) => resolve(response));
        });
    }

    showNameValueItemSelector(
        title,
        description,
        placeholderText,
        items,
        captionPropertyName,
        valuePropertyName,
    ) {
        return new Promise((resolve) => {
            this.dialogService
                .open({
                    viewModel: NameValueItemSelector,
                    model: {
                        title,
                        description,
                        placeholderText,
                        items,
                        captionPropertyName,
                        valuePropertyName,
                    },
                })
                .whenClosed((response) => resolve(response));
        });
    }

    showClientVersionReloadDialog() {
        return this.showConfirmation(
            'New Version Available',
            'A new version of the portal is available. To have the best experience, you should reload the browser with the latest version.',
            'Reload Browser',
            'Cancel',
        );
    }

    showDataGridReorderDialog(model) {
        return new Promise((resolve) => {
            this.dialogService
                .open({
                    viewModel: DataGridReorderDialog,
                    model,
                })
                .whenClosed((response) => resolve(response));
        });
    }
}
