import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import CompareUtility from '../infrastructure/compare-utility';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import Logger from '../infrastructure/logger';
import OrganizationUtility from '../infrastructure/organization-utility';
import PageContext from '../infrastructure/page-context';
import OrganizationService from '../organizations/organization-service';
import SecurityService from '../security/security-service';
import SpecificationService from './specification-service';

@inject(
    Router,
    PageContext,
    Logger,
    DialogPresenter,
    OrganizationService,
    SecurityService,
    SpecificationService,
)
export default class SpecificationList {
    @observable specificationFilterText;
    @observable selectedOrganizations;

    constructor(
        router,
        pageContext,
        logger,
        dialogPresenter,
        organizationService,
        securityService,
        specificationService,
    ) {
        this.router = router;
        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.securityService = securityService;
        this.organizationService = organizationService;
        this.specificationService = specificationService;
        this.logger = logger;
        this.logger.name = 'specification-list';

        this.allSelected = false;
        this.specificationFilterText = '';
        this.selectedOrganizations = [];
        this.selectedSpecifications = [];
        this.canEditSpecifications =
            this.securityService.hasPermission('EditSpecifications') &&
            !this.securityService.isImpersonating();
        this.canViewSpecifications = this.securityService.hasPermission('ViewSpecifications');
        this.canAccessLocationTestingModule = this.securityService.hasApplicationModule(3);

        this.specificationGridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template:
                        '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate:
                        '<label click.trigger="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                    headerClass: 'checkbox',
                    sortable: false,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Test Method',
                    field: 'testMethodName',
                    comparator: CompareUtility.compareStringsInsensitive,
                    sort: 'asc',
                },
                {
                    suppressMenu: true,
                    headerName: 'Test Code',
                    field: 'testMethodBillCode',
                    comparator: CompareUtility.compareStringsInsensitive,
                },
                {
                    suppressMenu: true,
                    hide: !this.canAccessLocationTestingModule,
                    headerName: 'Point Type',
                    field: 'pointType',
                    comparator: CompareUtility.compareStringsInsensitive,
                },
                {
                    suppressMenu: true,
                    hide: !this.canAccessLocationTestingModule,
                    headerName: 'Operations Timing',
                    field: 'operationsTimingCaption',
                    comparator: CompareUtility.compareStringsInsensitive,
                },
                {
                    suppressMenu: true,
                    hide: !this.canAccessLocationTestingModule,
                    headerName: 'Zone',
                    field: 'zone',
                },
                {
                    suppressMenu: true,
                    hide: !this.canAccessLocationTestingModule,
                    headerName: 'Room',
                    field: 'room',
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button if.bind="canViewSpecifications" click.delegate="navigateToSpecificationDetail(data)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button if.bind="canEditSpecifications" click.trigger="deleteSpecification(data)">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 100,
                    sortable: false,
                    suppressSizeToFit: true,
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };
    }

    navigateToSpecificationDetail(specification) {
        this.router.navigateToRoute('specification-detail', { id: specification.id || 'create' });
    }

    setSelectedSpecifications() {
        this.selectedSpecifications = this.specificationsView.filter(
            (specification) => specification.isSelected && specification.id,
        );
    }

    isSelectedChanged() {
        this.allSelected = this.specificationsView.every(
            (specification) => specification.isSelected,
        );

        this.setSelectedSpecifications();
    }

    allSelectedClicked() {
        if (this.specificationsView) {
            this.allSelected = !this.specificationsView.every(
                (specification) => specification.isSelected,
            );

            for (let specification of this.specificationsView)
                specification.isSelected = this.allSelected;
        }

        this.setSelectedSpecifications();

        return true;
    }

    selectedOrganizationsChanged() {
        if (!this.selectedOrganizations.length) return;

        this.getSpecifications();
    }

    specificationFilterTextChanged() {
        this.updateSpecificationView();
    }

    updateSpecificationView() {
        if (!this.specifications) return;

        var lowerCasedFilterText = this.specificationFilterText.toLowerCase();

        this.specificationsView = this.specifications.filter(
            (s) =>
                (s.testMethodName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (s.testMethodBillCode || '')
                    .toString()
                    .toLowerCase()
                    .indexOf(lowerCasedFilterText) > -1 ||
                (s.pointType || '').toString().toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (s.operationsTiming || '').toString().toLowerCase().indexOf(lowerCasedFilterText) >
                    -1 ||
                (s.zone || '').toString().toLowerCase().indexOf(lowerCasedFilterText) > -1,
        );
    }

    getOperationsTimingCaption(operationsTiming) {
        switch (operationsTiming) {
            case 'NotSpecified':
                return 'Not Specified';

            case 'PreOperations':
                return 'Pre Operations';

            case 'DuringOperations':
                return 'During Operations';

            case 'PostOperations':
                return 'Post Operations';

            default:
                return '';
        }
    }

    async getSpecifications() {
        if (!this.selectedOrganizations || this.selectedOrganizations.length === 0) return;

        try {
            this.pageContext.isLoading = true;
            this.specifications = await this.specificationService.getSpecifications(
                this.selectedOrganizations[0].id,
            );

            for (let specification of this.specifications)
                specification.operationsTimingCaption = this.getOperationsTimingCaption(
                    specification.operationsTiming,
                );

            this.updateSpecificationView();
            this.pageContext.isLoading = false;
        } catch (error) {
            this.logger.error('Error loading specifications.', error);
            this.pageContext.isLoading = false;
            this.dialogPresenter.showAlert(
                'Error Loading Specifications',
                'An error occurred while loading the specifications. Please try again later.',
            );
        }
    }

    activate(params) {
        (async () => {
            this.pageContext.isLoading = true;
            try {
                this.userView = this.securityService.getEffectiveUser();
                if (!this.userView) throw new Error('No current user.');

                const isWorkingUnderOrganization =
                    this.securityService.isWorkingUnderOrganization();
                var organizations = isWorkingUnderOrganization
                    ? await this.organizationService.getImpersonatingOrganizations()
                    : await this.organizationService.getOrganizations();
                this.organizations = OrganizationUtility.flattenOrganization(organizations);

                if (!this.organizations) throw new Error('No organizations for this user.');

                var defaultOrganization = this.organizations.filter(
                    (o) => o.id === this.userView.organizationId,
                );
                var selectedOrganization = defaultOrganization.length
                    ? defaultOrganization[0]
                    : this.organizations[0];
                this.selectedOrganizations = [selectedOrganization];

                const { testMethodName } = params;
                if (testMethodName) {
                    this.specificationFilterText = testMethodName;
                }

                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error loading specifications.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Specifications',
                    'An error occurred while loading the specifications. Please try again later.',
                );
            }
        })();
    }

    deleteSpecification(specification) {
        this.dialogPresenter
            .showConfirmation(
                'Delete Specification',
                'Are you sure you want to delete this specification?',
            )
            .then((confirmed) => {
                if (!confirmed) return;

                this.pageContext.isLoading = true;
                this.specificationService
                    .deleteSpecifications([specification.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Specification deleted successfully.');
                        this.removeSpecifications([specification]);
                    })
                    .catch((error) => {
                        this.logger.error('Error deleting specification.', error);
                        this.pageContext.isLoading = false;
                    });
            })
            .catch((error) => {
                this.logger.error('Error deleting specification.', error);
                this.pageContext.isLoading = false;
            });
    }

    deleteSelectedSpecifications() {
        var specificationsToDelete = this.specificationsView.filter((u) => u.isSelected);
        if (!specificationsToDelete.length) return;

        this.dialogPresenter
            .showConfirmation(
                'Delete Selected Specifications',
                'Are you sure you want to delete the selected specifications?',
            )
            .then((confirmed) => {
                if (!confirmed) return;

                this.pageContext.isLoading = true;
                this.specificationService
                    .deleteSpecifications(
                        specificationsToDelete.map((specification) => specification.id),
                    )
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay(
                            'Selected Specifications deleted successfully.',
                        );
                        this.removeSpecifications(specificationsToDelete);
                    })
                    .catch((error) => {
                        this.logger.error('Error deleting specifications.', error);
                        this.pageContext.isLoading = false;
                    });
            })
            .catch((error) => {
                this.logger.error('Error deleting specifications.', error);
                this.pageContext.isLoading = false;
            });
    }

    removeSpecifications(specifications) {
        for (let specification of specifications) {
            let i = this.specifications.indexOf(specification);
            if (i !== -1) this.specifications.splice(i, 1);
        }

        this.updateSpecificationView();
    }
}
