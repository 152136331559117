import {
    CellEditingStoppedEvent,
    ColDef,
    GetMainMenuItemsParams,
    GridApi,
    GridOptions,
    MenuItemDef,
} from 'ag-grid-community';
import { RichSelectModule } from 'ag-grid-enterprise/dist/ag-grid-enterprise';
import { agGridSetPreferences } from 'infrastructure/ag-grid/ag-localstorage-helper';
import { AgDateTimeEditorTypes } from './ag-grid-components/ag-datetime-editor-types';
import { SampleSegmentationGridPreferences } from './ag-grid-components/sample-segmentation-grid-preferences';

const setFocusedCell = (event: CellEditingStoppedEvent) => {
    const { api, node, column, colDef, columnApi, rowIndex } = event;
    const isLastRow = api.getDisplayedRowCount() - 1 === rowIndex;
    if (!node.lastChild || !isLastRow) {
        return;
    }

    const { colId } = colDef;
    const cols = columnApi.getAllColumns();
    const colIndex = cols.findIndex((c) => c.getColId() === colId);
    if (cols.length === colIndex + 1) {
        return;
    }

    api.setFocusedCell(0, cols[colIndex + 1].getColId());
};

const setDateTimeEditor = (api: GridApi, preferences: SampleSegmentationGridPreferences) => {
    const colDefs: ColDef[] = api.getColumnDefs();
    agGridSetPreferences(preferences);
    api.setColumnDefs(colDefs);
};

const getMainMenuItems = (params: GetMainMenuItemsParams): (string | MenuItemDef)[] => {
    const { defaultItems, api, context } = params;
    const colId = params.column.getId();
    if (colId !== 'collection_date') {
        return defaultItems;
    }

    const { preferences }: { preferences: SampleSegmentationGridPreferences } = context;
    const collectionDateMenuItems: (MenuItemDef | string)[] = defaultItems.slice(0);

    collectionDateMenuItems.push({
        name: 'Date/Time Editor',
        subMenu: [
            {
                name: AgDateTimeEditorTypes.PICKER,
                action: () => {
                    preferences.dateTimeEditor = AgDateTimeEditorTypes.PICKER;
                    setDateTimeEditor(api, preferences);
                },
                checked: preferences.dateTimeEditor === AgDateTimeEditorTypes.PICKER,
            },
            {
                name: AgDateTimeEditorTypes.MASK,
                action: () => {
                    preferences.dateTimeEditor = AgDateTimeEditorTypes.MASK;
                    setDateTimeEditor(api, preferences);
                },
                checked: preferences.dateTimeEditor === AgDateTimeEditorTypes.MASK,
            },
        ],
    });

    return collectionDateMenuItems;
};

const segmentationSampleGridOptions: GridOptions = {
    frameworkComponents: {
        selectEditorComponent: RichSelectModule,
    },
    defaultColDef: {
        flex: 1,
        minWidth: 100,
        sortable: true,
        resizable: true,
    },
    context: {
        preferences: new SampleSegmentationGridPreferences(),
    },
    getMainMenuItems,
    animateRows: true,
    enterMovesDown: true,
    enterMovesDownAfterEdit: true,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    enableRangeSelection: true,
    enableFillHandle: true,
    fillHandleDirection: 'y',
};

export { segmentationSampleGridOptions };
