import { Subscription } from 'aurelia-event-aggregator';
import { BindingEngine, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
    validateTrigger,
} from 'aurelia-validation';
import ValidationErrorFormatter from 'infrastructure/validation/validation-error-formatter';
import PointRoomService from 'location-testing/point-rooms/point-room-service';
import Organization from 'organizations/organization';
import TestMethod from 'test-methods/test-method';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import Logger from '../infrastructure/logger';
import OrganizationUtility from '../infrastructure/organization-utility';
import PageContext from '../infrastructure/page-context';
import operationsTimingOptions from '../location-testing/operations-timing-options';
import PointTypeService from '../location-testing/point-types/point-type-service';
import OrganizationService from '../organizations/organization-service';
import QualitativeResultService from '../qualitative-results/qualitative-result-service';
import SecurityService from '../security/security-service';
import TestMethodService from '../test-methods/test-method-service';
import IPointType from './contracts/IPointType';
import IQualitativeResult from './contracts/IQualitativeResult';
import ISpecificationType from './contracts/ISpecificationType';
import Specification from './specification';
import SpecificationService from './specification-service';
import { testMethodNameFormatting } from '../test-methods/test-method-helper';

@autoinject
export default class SpecificationDetail {
    validationController: ValidationController;
    specificationTypes: ISpecificationType[];
    operationsTimingOptions: any[];
    canViewSpecifications: boolean;
    canEditSpecifications: boolean;
    canAccessLocationTestingModule: boolean;
    initSpecification: Specification;
    canApplyRetroactively: boolean;
    isCreate: boolean;
    formChanged: boolean;

    specification: Specification;
    testMethods: TestMethod[];
    organizations: Organization[];
    qualitativeResultTypes: IQualitativeResult[];
    existingPointTypes: IPointType[];
    existingPointRooms: IPointType[];

    organizationChangeSubscription: Subscription;
    testMethodChangeSubscription: Subscription;
    maxQuantitativePassCountChangeSubscription: Subscription;
    minQuantitativeWarningCountChangeSubscription: Subscription;
    passingQualitativeResultsChangeSubscription: Subscription;
    warningQualitativeResultsChangeSubscription: Subscription;

    constructor(
        private bindingEngine: BindingEngine,
        private router: Router,
        validationControllerFactory: ValidationControllerFactory,
        private pageContext: PageContext,
        private logger: Logger,
        private dialogPresenter: DialogPresenter,
        private organizationService: OrganizationService,
        private qualitativeResultService: QualitativeResultService,
        private securityService: SecurityService,
        private testMethodService: TestMethodService,
        private pointTypeService: PointTypeService,
        private pointRoomService: PointRoomService,
        private specificationService: SpecificationService,
    ) {
        this.bindingEngine = bindingEngine;
        this.router = router;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.securityService = securityService;
        this.organizationService = organizationService;
        this.qualitativeResultService = qualitativeResultService;
        this.testMethodService = testMethodService;
        this.pointTypeService = pointTypeService;
        this.specificationService = specificationService;
        this.specificationTypes = [
            { id: 1, token: 'Quantitative' },
            { id: 2, token: 'Qualitative' },
        ];
        this.logger = logger;
        this.logger.name = 'specification-detail';

        this.operationsTimingOptions = operationsTimingOptions;

        this.canViewSpecifications = this.securityService.hasPermission('ViewSpecifications');
        this.canEditSpecifications =
            this.securityService.hasPermission('EditSpecifications') &&
            !this.securityService.isImpersonating();
        this.canAccessLocationTestingModule = this.securityService.hasApplicationModule(3);

        this.initSpecification = null;
        this.canApplyRetroactively = false;
        this.isCreate = false;
    }

    organizationChanged() {
        this.getTestMethods();
    }

    testMethodChanged() {
        this.updateSpecificationType();
    }

    conditionPropertyChanged() {
        const conditionIsSet =
            this.specification.maxQuantitativePassCount > 0 ||
            this.specification.passingQualitativeResults.length > 0;

        if (this.isCreate) {
            this.canApplyRetroactively = conditionIsSet;
            return;
        }

        this.canApplyRetroactively = this.specification.haveConditionsChanged(
            this.initSpecification,
        );
    }

    async getTestMethods() {
        if (!this.specification || !this.specification.organizationId) return;

        this.pageContext.isLoading = true;

        try {
            this.testMethods = await this.testMethodService.getTestMethods(
                this.specification.organizationId,
            );

            for (let testMethod of this.testMethods)
                testMethod.displayName = testMethodNameFormatting(
                    testMethod,
                    this.securityService.isImpersonating(),
                    this.securityService.isWorkingUnderOrganization(),
                );
        } catch (error) {
            this.logger.error('Error loading test methods.', error);
            this.dialogPresenter.showAlert(
                'Error Loading Specifications',
                'An error occurred while loading the test methods. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    updateSpecificationType() {
        if (!this.specification || !this.specification.testMethodId || !this.testMethods) {
            return;
        }

        var testMethod = this.testMethods.find((tm) => tm.id === this.specification.testMethodId);
        if (!testMethod) {
            return;
        }

        this.specification.testMethod = testMethod;
    }

    activate(params) {
        (async () => {
            this.pageContext.isLoading = true;
            this.isCreate = params.id === 'create';
            try {
                const isWorkingUnderOrganization =
                    this.securityService.isWorkingUnderOrganization();

                var results = await Promise.all([
                    isWorkingUnderOrganization
                        ? this.organizationService.getImpersonatingOrganizations()
                        : this.organizationService.getOrganizations(),
                    this.qualitativeResultService.getQualitativeResultTypes(),
                    this.pointTypeService.getPointTypes(),
                    this.pointRoomService.getOrganizationPointRooms(),
                    this.isCreate
                        ? Promise.resolve(null)
                        : this.specificationService.getSpecification(params.id),
                ]);

                this.organizations = OrganizationUtility.flattenOrganization(results[0]);
                this.qualitativeResultTypes = results[1];
                this.existingPointTypes = results[2].map((pt) => ({ name: pt, tag: pt }));
                this.existingPointRooms = results[3].map((pt) => ({ name: pt, tag: pt }));

                this.specification = new Specification();
                if (!this.isCreate) {
                    this.specification.toModel(results[4]);
                }
                this.initSpecification = JSON.parse(JSON.stringify(this.specification));

                this.organizationChanged();
                this.testMethodChanged();

                this.organizationChangeSubscription = this.bindingEngine
                    .propertyObserver(this.specification, 'organizationId')
                    .subscribe(this.organizationChanged.bind(this));

                this.testMethodChangeSubscription = this.bindingEngine
                    .propertyObserver(this.specification, 'testMethodId')
                    .subscribe(this.testMethodChanged.bind(this));

                this.maxQuantitativePassCountChangeSubscription = this.bindingEngine
                    .propertyObserver(this.specification, 'maxQuantitativePassCount')
                    .subscribe(this.conditionPropertyChanged.bind(this));

                this.minQuantitativeWarningCountChangeSubscription = this.bindingEngine
                    .propertyObserver(this.specification, 'minQuantitativeWarningCount')
                    .subscribe(this.conditionPropertyChanged.bind(this));

                this.passingQualitativeResultsChangeSubscription = this.bindingEngine
                    .propertyObserver(this.specification, 'passingQualitativeResults')
                    .subscribe(this.conditionPropertyChanged.bind(this));

                this.warningQualitativeResultsChangeSubscription = this.bindingEngine
                    .propertyObserver(this.specification, 'warningQualitativeResults')
                    .subscribe(this.conditionPropertyChanged.bind(this));

                ValidationRules.ensure('organizationId')
                    .displayName('Organization')
                    .required()
                    .ensure('testMethodId')
                    .displayName('Test Method')
                    .required()
                    .ensure('passingQualitativeResults')
                    .displayName('Passing Qualitative Results')
                    .satisfies((v: IQualitativeResult[]) => !!v.length)
                    .ensure('warningQualitativeResults')
                    .displayName('Warning Qualitative Results')
                    .satisfies((v: IQualitativeResult[], m: Specification) => {
                        if (!v) {
                            return true;
                        }
                        const combined = m.passingQualitativeResults.concat(v);
                        return [...new Set(combined.map((r) => r.id))].length === combined.length;
                    })
                    .ensure('maxQuantitativePassCount')
                    .displayName('Quantitative Pass Count')
                    .satisfies(
                        (maxQuantitativePassCount: number) => Number(maxQuantitativePassCount) >= 0,
                    )
                    .ensure('minQuantitativeWarningCount')
                    .displayName('Quantitative Warning Count')
                    .satisfies(
                        (v: number, m: Specification) =>
                            !v || Number(v) < Number(m.maxQuantitativePassCount),
                    )
                    .on(this.specification);

                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error loading specification.', error, { id: params.id });
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Specification',
                    'An error occurred while loading the specification. Please try again later.',
                );
            }
        })();
    }

    handlePointTypeTagCreated(tag) {
        for (var i = this.existingPointTypes.length - 1; i >= 0; i--) {
            if (this.existingPointTypes[i].tag) this.existingPointTypes.splice(i, 1);
        }

        this.existingPointTypes.push(tag);
        this.specification.pointType = tag.name;
    }

    handlePointRoomTagCreated(tag) {
        for (var i = this.existingPointRooms.length - 1; i >= 0; i--) {
            if (this.existingPointRooms[i].tag) this.existingPointRooms.splice(i, 1);
        }

        this.existingPointRooms.push(tag);
        this.specification.room = tag.name;
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('specification-list');
    }

    async save() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid) return;

        this.pageContext.isLoading = true;

        try {
            const response = await this.specificationService.saveSpecification(this.specification);

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Specification saved successfully.');
            this.router.navigateToRoute(
                'specification-detail',
                { id: response['id'] },
                { replace: true },
            );
        } catch (error) {
            this.logger.error('Error saving specification.', error, {
                specification: this.specification,
            });

            const { title, message } = this.getApiErrorMessage(error);
            this.dialogPresenter.showAlert(title, message);
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(error) {
        const apiErrorMessage = {
            errorCode: error.errorCode || error.apiErrorCode,
            title: '',
            message: '',
        };

        switch (apiErrorMessage.errorCode) {
            case 400:
            case 403:
                apiErrorMessage.title = 'Validation Error';
                apiErrorMessage.message = ValidationErrorFormatter.format(
                    'A validation error occured',
                    error,
                );
                break;
            case 900:
                apiErrorMessage.title = 'Specification Already Exists';
                apiErrorMessage.message =
                    'A specification with the same criteria already exists. Please find and edit the existing specification to make changes to its conditions.';
                break;
            case 901:
            case 902:
                apiErrorMessage.title = 'Error Applying Retroactively';
                apiErrorMessage.message = error.message;
                break;
            default:
                (apiErrorMessage.title = 'Error Saving Specification'),
                    (apiErrorMessage.message =
                        'An error occurred while saving the current specification. Please try again later.');
                break;
        }

        return apiErrorMessage;
    }

    detached() {
        this.organizationChangeSubscription?.dispose();
        this.testMethodChangeSubscription?.dispose();

        this.maxQuantitativePassCountChangeSubscription?.dispose();
        this.minQuantitativeWarningCountChangeSubscription?.dispose();
        this.passingQualitativeResultsChangeSubscription?.dispose();
        this.warningQualitativeResultsChangeSubscription?.dispose();
    }
}
