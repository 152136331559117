import { inject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import NavigationAuthorizer from '../../security/navigation/navigation-authorizer';

@inject(Element, Router, EventAggregator, NavigationAuthorizer)
export class HeaderNavigationMenu {
    @bindable group;
    @bindable icon;
    @bindable className;

    constructor(element, router, eventAggregator, navigationAuthorizer) {
        this.element = element;
        this.router = router;
        this.eventAggregator = eventAggregator;
        this.navigationAuthorizer = navigationAuthorizer;
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleImpersonationStart = this.handleImpersonationStart.bind(this);
        this.handleImpersonationStop = this.handleImpersonationStop.bind(this);
    }

    updateNavigationItems() {
        if (!this.group)
            return;

        (async () => {
            let navItems = [];

            for (let item of this.router.navigation)
                if (item.config.group === this.group && await this.navigationAuthorizer.hasAccess(item.config))
                    navItems.push(item);

            this.navItems = navItems;
        })();
    }


    handleLogin() {
        this.updateNavigationItems();
    }

    handleLogout() {
        this.updateNavigationItems();
    }

    handleImpersonationStart() {
        this.updateNavigationItems();
    }

    handleImpersonationStop() {
        this.updateNavigationItems();
    }

    attached() {
        this.$dropdown = $(this.element.querySelector('ul[data-dropdown-menu]'));
        this.$dropdown.foundation();
        
        this.updateNavigationItems();
        this.userLoginSubscription = this.eventAggregator.subscribe('user.login', this.handleLogin);
        this.userLogoutSubscription = this.eventAggregator.subscribe('user.logout', this.handleLogout);
        this.userImpersonationStartSubscription = this.eventAggregator.subscribe('impersonation.start', this.handleImpersonationStart);
        this.userImpersonationStopSubscription = this.eventAggregator.subscribe('impersonation.stop', this.handleImpersonationStop);
    }

    detached() {
        this.$dropdown && this.$dropdown.foundation('destroy');

        this.userLoginSubscription && this.userLoginSubscription.dispose();
        this.userLogoutSubscription && this.userLogoutSubscription.dispose();
        this.userImpersonationStartSubscription && this.userImpersonationStartSubscription.dispose();
        this.userImpersonationStopSubscription && this.userImpersonationStopSubscription.dispose();
    }
}
