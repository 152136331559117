import { BaseQueryFilter } from './base-query-filter';
import { QueryFiltered } from './query-filtered';

export interface IsAssignedQuery {
    isAssigned: any;
}

export class IsAssignedQueryFilters extends BaseQueryFilter
    implements IsAssignedQuery, QueryFiltered<IsAssignedQuery> {

    private _isAssigned: number;

    constructor() {
        super();

        this._isAssigned = -1;
    }

    public get isAssigned(): any {
        return this._isAssigned;
    }

    public set isAssigned(assignedState: any) {
        this._isAssigned = assignedState;
    }

    public get count(): number {
        return (Number(this._isAssigned !== -1));
    }

    reset(): void {
        this._isAssigned = -1;
    }

    getQueryParams(): IsAssignedQuery {
        return this._isAssigned === 0 || this._isAssigned === 1
            ? { isAssigned: this._isAssigned === 0 ? false : true }
            : null;
    }

    setFilterValues(params: IsAssignedQuery): void {
        if (!params) {
            return;
        }

        let isAssigned: number;
        switch (params.isAssigned) {
            case 'true':
                isAssigned = 1;
                break;
            case 'false':
                isAssigned = 0;
                break
            default:
                isAssigned = -1;
        }

        super.setQueryFilteredValues({ isAssigned }, this);
    }
}
