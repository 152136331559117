import { inject, bindable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import Logger from '../infrastructure/logger';
import SecurityService from './security-service';
import LoginErrorMessageProvider from './login-error-message-provider';

@inject(DialogController, ValidationControllerFactory, Logger, SecurityService)
export class LoginDialog {
    constructor(dialogController, validationControllerFactory, logger, securityService) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        ValidationRules
            .ensure('username')
            .displayName('Username')
            .required()
            .ensure('password')
            .displayName('Password')
            .required()
            .on(this);

        this.dialogController = dialogController;

        this.logger = logger;
        this.logger.name = 'login-dialog';

        this.securityService = securityService;
    }

    activate(model) {
        this.model = model;
    }

    attached() {
        this.username = '';
        this.password = '';
        this.errorMessage = '';
        this.securityService.logout();
    }

    login() {
        this.errorMessage = '';

        this.validationController
            .validate()
            .then(aggregateResult => {
                if (!aggregateResult.valid)
                    return;

                this.securityService
                    .login(this.username, this.password)
                    .then(() => {
                        this.dialogController.ok();
                    })
                    .catch(error => {
                        this.logger.error('Error while logging in.', error, { username: this.username });
                        this.errorMessage = LoginErrorMessageProvider.getErrorMessage(error);
                    });
            });
    }

    cancel() {
        this.dialogController.cancel();
    }
}
