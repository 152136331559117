import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class SupportTopicService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getSupportTopics() {
        return this.httpClient.get('help/support-topics');
    }

    getSupportTopic(id) {
        return this.httpClient.get(`help/support-topics/${id}`);
    }

    deleteSupportTopics(ids) {
        var query = ids.map(id => `id=${id}`).join('&');
        return this.httpClient.delete(`help/support-topics?${query}`);
    }

    saveSupportTopic(supportTopic) {
        if (!supportTopic.id)
            return this.httpClient.post('help/support-topics/', supportTopic);

        return this.httpClient.put(`help/support-topics/${supportTopic.id}`, supportTopic);
    }
}
