import { inject } from 'aurelia-framework';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import StringResourceService from '../../infrastructure/string-resource-service';
import PageContext from '../../infrastructure/page-context';
import Logger from '../../infrastructure/logger';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import SupportTopicService from './support-topic-service';
import SupportRequestService from './support-request-service';

@inject(ValidationControllerFactory, StringResourceService, PageContext, Logger, DialogPresenter, SupportTopicService, SupportRequestService)
export default class Support {
    constructor(validationControllerFactory, stringResourceService, pageContext, logger, dialogPresenter, supportTopicService, supportRequestService) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.stringResourceService = stringResourceService;
        this.pageContext = pageContext;

        this.logger = logger;
        this.logger.name = 'support';

        this.dialogPresenter = dialogPresenter;
        this.supportTopicService = supportTopicService;
        this.supportRequestService = supportRequestService;
    }

    handleFormChange() {
        this.formChanged = true;
    }

    activate(params) {
        (async () => {
            try {
                this.pageContext.isLoading = true;
                this.supportTopics = await this.supportTopicService.getSupportTopics();
                this.disclaimer = (await this.stringResourceService.getStringResources())['support_disclaimer'];

                this.selectedSupportTopics = [];

                ValidationRules
                    .ensure('selectedSupportTopics')
                    .displayName('Support Topic')
                    .satisfies(selectedOrganizations => !!selectedOrganizations.length)
                    .ensure('message')
                    .displayName('Message')
                    .required()
                    .on(this);

                var supportFormMessage = localStorage.getItem('supportFormMessage');
                if (supportFormMessage) {
                    this.message = supportFormMessage;
                    localStorage.removeItem('supportFormMessage');
                }

            } catch (error) {
                this.logger.error('Error loading support topics', error);
                this.dialogPresenter.showAlert(
                    'Error Loading Support Topics',
                    'An error occurred while loading the support topics. Please try again later.');
            }

            this.pageContext.isLoading = false;
        })();
    }

    async submit() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        var supportRequest = {
            supportTopicId: this.selectedSupportTopics[0].id,
            message: this.message
        };

        this.pageContext.isLoading = true;

        try {
            this.supportRequestService.createSupportRequest(supportRequest);
            this.pageContext.showSuccessOverlay('Support request created successfully.', false);
            this.selectedSupportTopics = [];
            this.message = '';
            this.formChanged = false;
        } catch (error) {
            this.logger.error('Error creating support request.', error, { supportRequest });
            this.dialogPresenter.showAlert(
                'Error Creating Support Request',
                'An error occurred while creating the support request. Please try again later.');
        }

        this.pageContext.isLoading = false;
    }
};
