import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import RemediationRootCause from './remediation-root-cause';

@autoinject
export default class RemediationRootCauseService {
    private baseRoute: string;

    constructor(private httpClient: HttpClient) {
        this.baseRoute = 'location-testing/remediation-root-causes';
    }

    getRemediationRootCauseList(): Promise<RemediationRootCause[]> {
        return this.httpClient.get(this.baseRoute) as unknown as Promise<RemediationRootCause[]>;
    }

    createRemediationRootCauseList(remediationRootCauseList: RemediationRootCause[]) {
        return this.httpClient.put(this.baseRoute, remediationRootCauseList);
    }
}
