import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

function buildReportUrl(id, format, parameters, specialAction = null) {
    var action = specialAction ? `/${specialAction}` : '';
    var url = `reporting/reports/${id}${action}?format=`;

    if (format) url += `${format}`;

    if (parameters) {
        Object.keys(parameters).forEach(function (key) {
            const values = parameters[key];
            if (!values) return;

            for (let value of values)
                if (value !== null && typeof value !== 'undefined') url += `&${key}=${value}`;
        });
    }

    return url;
}

@autoinject
export default class ReportService {
    constructor(private httpClient: HttpClient) {}

    getReports() {
        return this.httpClient.get('reporting/reports');
    }

    getReportNotDownload(id: number) {
        return this.httpClient.get(`reporting/reports/${id}`);
    }

    getReport(id, format = null, parameters = null) {
        var url = buildReportUrl(id, format, parameters);
        return this.httpClient.get(url);
    }

    async getSubscription(reportId) {
        return this.httpClient.get(`reporting/reports/${reportId}/subscription`);
    }

    async createOrUpdateSubscription(id, subscriptionCriteria, parameters) {
        var format = subscriptionCriteria.deliveryFormat || 'pdf';
        var url = buildReportUrl(id, format, parameters, 'subscription');

        subscriptionCriteria.reportId = id;
        return this.httpClient.post(url, subscriptionCriteria);
    }

    async deleteSubscription(reportId) {
        return this.httpClient.delete(`reporting/reports/${reportId}/subscription`);
    }

    getDownloadUrl(id, format, parameters) {
        return 'api/' + buildReportUrl(id, format, parameters);
    }
}
