import { inject } from 'aurelia-framework';
import { RedirectToRoute } from 'aurelia-router';
import NavigationAuthorizer from './navigation-authorizer';

@inject(NavigationAuthorizer)
export default class AuthorizationCheckStep {
    constructor(navigationAuthorizer) {
        this.navigationAuthorizer = navigationAuthorizer;
    }

    async run(navigationInstruction, next) {
        var hasAccess = await this.navigationAuthorizer.hasAccess(navigationInstruction.config);
        if (!hasAccess) {
            if (navigationInstruction.previousInstruction)
                return next.cancel();

            return next.cancel(new RedirectToRoute('dashboard'));
        }

        return next();
    }
};
