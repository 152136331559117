import { autoinject, bindable, bindingMode } from 'aurelia-framework';

@autoinject
export class AdhocField {
    @bindable queryLevelItems;
    @bindable type;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) fieldKey;
    @bindable({ defaultBindingMode: bindingMode.fromView }) fieldDataType;
    @bindable({ defaultBindingMode: bindingMode.fromView }) fieldSupportsFullTextSearch;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) fieldCaption;

    constructor(private element: Element) {
    }
    
    filterFields(fields, type) {
        if (!fields || !type)
            return [];

        return fields.filter(field =>
            (type === 'filter' && !field.disableFilter) ||
            (type === 'output' && !field.disableOutput) ||
            (type === 'sort' && !field.disableSort)
        );
    }

    fieldKeyChanged(fieldKey) {
        for (let queryLevelItem of this.queryLevelItems)
            for (let field of queryLevelItem.fields)
                if (field.key === fieldKey) {
                    // TODO: When implementing other segment data types, ensure the propert segment's data type is passed along.
                    this.fieldDataType = field.key === 'SampleSegment' ? 'Text' : field.dataType;
                    this.fieldSupportsFullTextSearch = field.supportsFullTextSearch;
                    this.fieldCaption = field.caption;
                    break;
                }
        
        this.element.dispatchEvent(new CustomEvent('fieldchange', { bubbles: true, detail: { fieldKey } }));
    }

    attached() {
        this.fieldKeyChanged(this.fieldKey);
    }
}
