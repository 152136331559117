import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
} from 'aurelia-validation';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import Logger from '../infrastructure/logger';
import PageContext from '../infrastructure/page-context';
import Validators from '../infrastructure/validation/validators';
import LabService from './lab-service';

@inject(Router, ValidationControllerFactory, PageContext, Logger, DialogPresenter, LabService)
export default class LabDetail {
    validationController: ValidationController;

    lab: any;
    formChanged: boolean;

    constructor(
        private router: Router,
        validationControllerFactory: ValidationControllerFactory,
        private pageContext: PageContext,
        private logger: Logger,
        private dialogPresenter: DialogPresenter,
        private labService: LabService,
    ) {
        this.router = router;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.pageContext = pageContext;

        this.logger = logger;
        this.logger.name = 'lab-detail';

        this.dialogPresenter = dialogPresenter;
        this.labService = labService;
    }

    activate(params) {
        (async () => {
            try {
                this.pageContext.isLoading = true;
                this.lab = await this.labService.getLab(params.id);

                ValidationRules.ensure('address1')
                    .displayName('Address')
                    .required()
                    .ensure('city')
                    .displayName('City')
                    .required()
                    .ensure('state')
                    .displayName('State')
                    .required()
                    .ensure('postalCode')
                    .displayName('Postal Code')
                    .required()
                    .then()
                    .satisfies((val, o: any) => Validators.isValidPostalCode(val))
                    .ensure('phoneNumber')
                    .displayName('Phone')
                    .required()
                    .ensure('faxNumber')
                    .displayName('Fax')
                    .required()
                    .ensure('contactTitle')
                    .displayName('Contact Title')
                    .required()
                    .ensure('contactName')
                    .displayName('Contact Name')
                    .required()
                    .ensure('contactEmail')
                    .displayName('Contact Email')
                    .required()
                    .email()
                    .ensure('requestSubmissionEmail')
                    .displayName('Request Submission Email')
                    .required()
                    .email()
                    .on(this.lab);

                this.validationController.addObject(this.lab);
            } catch (error) {
                this.logger.error('Error loading lab', error, { labId: params.id });

                var errorAlertPromise =
                    error.apiErrorCode === 1
                        ? this.dialogPresenter.showAlert(
                              'Error Loading Lab',
                              'The current lab doesnt exist.',
                          )
                        : this.dialogPresenter.showAlert(
                              'Error Loading Lab',
                              'An error occurred while loading the current lab. Please try again later.',
                          );

                errorAlertPromise.then(() => {
                    this.router.navigateToRoute('lab-list');
                });
            }

            this.pageContext.isLoading = false;
        })();
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('lab-list');
    }

    async save() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid) return;

        this.pageContext.isLoading = true;

        try {
            var result = await this.labService.saveLab(this.lab);
            this.lab.labDetailId = result.labDetailId;

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Lab saved successfully.');
            this.router.navigateToRoute('lab-detail', { id: this.lab.id }, { replace: true });
        } catch (error) {
            this.logger.error('Error saving lab', error, { lab: this.lab });
            this.dialogPresenter.showAlert(
                'Error Saving Lab',
                this.getApiErrorMessage(error.apiErrorCode),
            );
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        if (errorCode === 1) return 'No record was found for the specified lab ID.';

        return 'An error occurred while saving the current lsn. Please try again later.';
    }

    validatePostalCode(postalCode) {
        return false;
    }
}
