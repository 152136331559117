export interface ExportTaskCollectionTemplateConfiguration {
    id: number;
    organizationId: number;
    templateName: string;
}

export const emptyExportTaskCollectionTemplateConfiguration = (): ExportTaskCollectionTemplateConfiguration => ({
    id: 0,
    organizationId: 0,
    templateName: '',
});
