import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import Logger from '../infrastructure/logger';
import PageContext from '../infrastructure/page-context';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import SecurityService from '../security/security-service';
import RoleService from './role-service';

@inject(Router, Logger, PageContext, ValidationControllerFactory, DialogPresenter, SecurityService, RoleService)
export default class RoleDetail {
    constructor(router, logger, pageContext, validationControllerFactory, dialogPresenter, securityService, roleService) {
        this.router = router;

        this.logger = logger;
        this.logger.name = 'role-detail';

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.securityService = securityService;
        this.roleService = roleService;
        this.role = {};
        this.permissions = {};
        this.errorMessage = '';
        this.isGroupSelected = {};
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.canEditRoles = this.securityService.hasPermission('EditRoles') && !this.securityService.isImpersonating();

        ValidationRules
            .ensure('name')
            .displayName('Role name')
            .required()
            .on(this.role);
    }

    mapPermissions() {
        for (var i = 0; i < this.permissions.length; i++) {
            var unselectedPermission = this.permissions[i];
            if (unselectedPermission.id === 0)
                continue;
            if (!this.role.permissions.find(p => p.id === unselectedPermission.id))
                this.role.permissions.push(unselectedPermission);
        }
    }

    activate(params) {
        this.pageContext.isLoading = true;

        this.roleService.getPermissions()
            .then(permissions => {
                this.permissions = permissions;

                if (params.id === 'create') {
                    this.role.organization = {
                        id: params.orgId,
                        name: params.orgName
                    };
                    this.role.permissions = [];
                    this.mapPermissions();
                    this.pageContext.isLoading = false;
                    return;
                }

                this.roleService.getRole(params.id)
                    .then(role => {
                        this.role = role;
                        this.role.permissions.map((permission) => {
                            permission.isSelected = true;
                            return permission;
                        });
                        this.mapPermissions();
                        this.pageContext.isLoading = false;
                    })
                    .catch(error => {
                        this.logger.error('Error loading role.', error, { roleId: params.id });
                        this.pageContext.isLoading = false;
                        this.dialogPresenter.showAlert('Error Loading Role', 'An error occurred while loading the role. Please try again later.');
                    });
            })
            .catch(error => {
                this.logger.error('Error loading permissions for role detail.', error, { roleId: params.id });
                this.dialogPresenter.showAlert('Error Loading Permissions', 'An error occurred while loading the roles permissions. Please try again later.');
                this.pageContext.isLoading = false;
            });
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('role-list');
    }

    getRoleClone() {
        var clone = JSON.parse(JSON.stringify(this.role));
        clone.permissions = [];
        for (var i = 0; i < this.role.permissions.length; i++) {
            var permission = this.role.permissions[i];
            if (permission.isSelected)
                clone.permissions.push({ id: permission.id });
        }
        return clone;
    }

    async saveRole() {
        var clone = this.getRoleClone();

        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        try {
            this.role.id = (await this.roleService.saveRole(clone)).id;

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Role saved successfully.');
            this.router.navigateToRoute('role-detail', { id: this.role.id }, { replace: true });
        } catch (error) {
            this.logger.error('Error saving role.', error, { role: clone });
            this.dialogPresenter.showAlert(
                'Error Saving Role',
                this.getApiErrorMessage(error.apiErrorCode));
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        switch (errorCode) {
            case 401:
                return 'The specified role name is already in use. Please change the role name.';

            default:
                return 'An error occurred while saving the role. Please try again later.';
        }
    }

    updateIsGroupSelected(group, values) {
        this.isGroupSelected[group] = values.every(v => v.isSelected);
    }

    togglePermission(group, permission, isSelected, values) {
        permission.isSelected = isSelected;
        this.updateIsGroupSelected(group, values);
        return true;
    }

    toggleGroupPermissions(group, values, isSelected) {

        for (var i = 0; i < values.length; i++) {
            values[i].isSelected = isSelected;
        }
    }
}
