import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import CompareUtility from 'infrastructure/compare-utility';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import SelectorOption from 'infrastructure/selector-option';
import OrganizationService from 'organizations/organization-service';
import RecordStatus, { getRecordStatusOptions } from 'record-status';
import SecurityService from 'security/security-service';
import SegmentationTemplateService from './segmentation-template-service';
import UiSegmentationTemplate from './ui-segmentation-template';

@autoinject
export class SegmentationTemplateList {
    @observable filterText;

    // filters
    applyFiltersSubscription: Subscription;
    clearFiltersSubscription: Subscription;

    recordStatusOptions: SelectorOption[];
    selectedRecordStatus: RecordStatus;

    gridOptions: any;
    allSelected: boolean;
    selectedActiveSegmentationTemplates: any[];
    canEditSegmentationTemplates: boolean;
    selectedSegmentationTemplateTypes: string[] = [];
    uiSegmentationTemplates: UiSegmentationTemplate[];
    uiSegmentationTemplatesView: UiSegmentationTemplate[];
    organizations: any[];
    selectedOrganizations: any[] = [];
    filterCount: number;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private segmentationTemplateService: SegmentationTemplateService,
        private organizationService: OrganizationService,
    ) {
        this.logger.name = 'segmentation-template-list';

        this.filterText = '';

        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template:
                        '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()" disabled.bind="data.isArchived"></label>',
                    headerCellTemplate:
                        '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Name',
                    field: 'name',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 100,
                },
                {
                    suppressMenu: true,
                    headerName: 'Organization',
                    field: 'organizationCaption',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 100,
                },
                {
                    suppressMenu: true,
                    headerName: 'Archived',
                    field: 'isArchived',
                    template:
                        '<i class="fa fa-check" if.bind="data.isArchived" data-cy="is-archived-icon"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Active',
                    field: 'isActive',
                    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button click.delegate="navigateToSegmentationTemplateDetail(data.id)" disabled.bind="data.isArchived">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button if.bind="canEditSegmentationTemplates" click.trigger="deleteSegmentationTemplate(data)" disabled.bind="!data.isActive || data.isArchived">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 150,
                    sortable: false,
                    suppressSizeToFit: true,
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.allSelected = false;
        this.selectedActiveSegmentationTemplates = [];
        this.selectedRecordStatus = RecordStatus.ACTIVE;
        this.canEditSegmentationTemplates =
            this.securityService.hasPermission('EditSegmentationTemplates') &&
            !this.securityService.isImpersonating();
        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
    }

    navigateToSegmentationTemplateDetail(id) {
        this.router.navigateToRoute('segmentation-template-detail', { id });
    }

    isSelectedChanged() {
        this.allSelected = this.uiSegmentationTemplatesView.every(
            (t) => t.isSelected || t.isArchived,
        );
        this.setSelectedActiveSegmentationTemplates();
    }

    allSelectedClicked() {
        if (this.uiSegmentationTemplatesView) {
            this.allSelected = !this.uiSegmentationTemplatesView.every(
                (t) => t.isSelected || t.isArchived,
            );

            for (let t of this.uiSegmentationTemplatesView) {
                if (!t.isArchived) {
                    t.isSelected = this.allSelected;
                }
            }
        }

        this.setSelectedActiveSegmentationTemplates();

        return true;
    }

    setSelectedActiveSegmentationTemplates() {
        this.selectedActiveSegmentationTemplates = this.uiSegmentationTemplatesView.filter(
            (t) => t.isSelected && t.isActive && !t.isArchived,
        );
    }

    filterTextChanged() {
        this.updateSegmentationTemplatesView();
    }

    handleApplyFilters() {
        this.updateSegmentationTemplatesView();
    }

    handleClearFilters() {
        this.selectedSegmentationTemplateTypes = [];
        this.selectedRecordStatus = RecordStatus.ACTIVE;
        this.updateSegmentationTemplatesView();
    }

    updateSegmentationTemplatesView() {
        var lowerCasedFilterText = this.filterText.toLowerCase();

        if (this.uiSegmentationTemplates) {
            this.uiSegmentationTemplatesView = this.uiSegmentationTemplates.filter(
                (t) =>
                    ((t.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                        (t.organizationCaption || '').toLowerCase().indexOf(lowerCasedFilterText) >
                            -1) &&
                    this.matchSelectedRecordStatus(t) &&
                    (this.selectedOrganizations.length === 0 ||
                        this.selectedOrganizations.some((o) => t.organizationId === o.id)),
            );

            this.allSelected = this.uiSegmentationTemplatesView.every((t) => t.isSelected);
        }

        this.filterCount =
            (this.selectedSegmentationTemplateTypes.length != 0 ? 1 : 0) +
            (this.selectedOrganizations.length != 0 ? 1 : 0) +
            (this.selectedRecordStatus !== RecordStatus.ACTIVE ? 1 : 0);
    }

    matchSelectedRecordStatus(segmentationTemplate: UiSegmentationTemplate) {
        if (this.selectedRecordStatus === RecordStatus.ALL) return true;

        let filteredIsActiveValue = this.selectedRecordStatus === RecordStatus.ACTIVE;
        return segmentationTemplate.isActive === filteredIsActiveValue;
    }

    activate(params) {
        this.applyFiltersSubscription = this.eventAggregator.subscribe(
            'filters.apply',
            this.handleApplyFilters,
        );
        this.clearFiltersSubscription = this.eventAggregator.subscribe(
            'filters.clear',
            this.handleClearFilters,
        );

        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.recordStatusOptions = getRecordStatusOptions();

                let [segmentationTemplates, organizations] = await Promise.all([
                    this.segmentationTemplateService.getSegmentationTemplates(),
                    this.organizationService.getOrganizations(),
                ]);

                this.organizations = organizations;
                this.uiSegmentationTemplates = segmentationTemplates.map((t) =>
                    UiSegmentationTemplate.fromModel(t, organizations),
                );
                this.updateSegmentationTemplatesView();
                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error loading segmentation templates.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Segmentation Templates',
                    'An error occurred while loading the segmentation templates. Please try again later.',
                );
            }
        })();
    }

    deactivate() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
        this.clearFiltersSubscription && this.clearFiltersSubscription.dispose();
    }

    async deleteSegmentationTemplate(segmentationTemplate) {
        let confirmed = await this.dialogPresenter.showConfirmation(
            'Delete Segmentation Template',
            'Are you sure you want to delete this segmentation template?',
        );
        if (!confirmed) return;

        this.pageContext.isLoading = true;

        try {
            await this.segmentationTemplateService.deleteSegmentationTemplates([
                segmentationTemplate.id,
            ]);

            this.pageContext.showSuccessOverlay('Segmentation template deleted successfully.');

            segmentationTemplate.isSelected = false;
            segmentationTemplate.isActive = false;

            this.setSelectedActiveSegmentationTemplates();
        } catch (error) {
            this.logger.error('Error deleting segmentation template.', error, {
                segmentationTemplateId: segmentationTemplate.id,
            });
            this.handleDeleteError(error, false);
        }

        this.pageContext.isLoading = false;
    }

    async deleteSelectedSegmentationTemplates() {
        let segmentationTemplatesToDelete = this.uiSegmentationTemplatesView.filter(
            (t) => t.isSelected,
        );
        if (!segmentationTemplatesToDelete.length) return;

        let confirmed = await this.dialogPresenter.showConfirmation(
            'Delete Selected Segmentation Templates',
            'Are you sure you want to delete the selected segmentation templates?',
        );
        if (!confirmed) return;

        var segmentationTemplateIds = segmentationTemplatesToDelete.map((t) => t.id);

        this.pageContext.isLoading = true;

        try {
            await this.segmentationTemplateService.deleteSegmentationTemplates(
                segmentationTemplateIds,
            );
            this.pageContext.showSuccessOverlay(
                'Selected segmentation templates deleted successfully.',
            );

            for (let t of segmentationTemplatesToDelete) {
                t.isSelected = false;
                t.isActive = false;

                this.setSelectedActiveSegmentationTemplates();
            }

            this.updateSegmentationTemplatesView();
        } catch (error) {
            this.logger.error('Error deleting segmentation templates.', error, {
                segmentationTemplateIds,
            });
            this.handleDeleteError(error, true);
        }

        this.pageContext.isLoading = false;
    }

    handleDeleteError(error, multiple) {
        this.dialogPresenter.showAlert(
            'Error Deleting Segmentation Template' + (multiple ? 's' : ''),
            this.getApiErrorMessage(error.apiErrorCode, multiple),
        );
    }

    getApiErrorMessage(errorCode, multiple) {
        if (errorCode === 2)
            return (
                'You dont have access to delete the segmentation template' + (multiple ? 's' : '')
            );

        if (errorCode === 1401)
            return (
                (multiple
                    ? 'One or more segmentation templates are in use by a request template or draft. View or edit the segmentation templates to get details on their usage.'
                    : 'The segmentation template is in use by a request template or draft. View or edit the segmentation template to get details on its usage.') +
                // Additional messaging for both cases above.
                ' You must delete all associated request templates and drafts, or remove the usage of the segmentation template from within the associated request templates and drafts before returning to delete segmentation templates.'
            );

        return 'An unexpected error occurred while trying to delete segmentation templates. Please try again later.';
    }
}
