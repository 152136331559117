function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

export class NumberFormatValueConverter {
    toView(value) {
        if (!value)
            return value;

        return numberWithCommas(value);
    }
}