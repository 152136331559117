import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';

@inject(DialogController)
export default class Alert {
    constructor(controller) {
        this.controller = controller;
    }

    activate(model) {
        this.isMessageHtml = model.message?.trim()?.startsWith('<');
        this.model = model;
    }
}
