import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import { ColumnState } from 'ag-grid-community/dist/lib/main';
import SelectorOption from 'infrastructure/selector-option';

const isColGroupDefGuard = (
    colOrColGroupDef: ColDef | ColGroupDef,
): colOrColGroupDef is ColGroupDef => {
    return Object.keys(colOrColGroupDef).includes('children');
};

/*
 * This can theorically support multiple column groups, hence the foreach loops.
 * The drawback of the foreach loop is that it doesn't check if a selector option value is present in the col defs
 * TODO: Create separate unit test to match generateColumnsDefs w/ testResultsViewBySelectorOptions
 */
const groupColumnDefsBy = (
    selectedViewByOption: SelectorOption,
    colDefs: (ColDef | ColGroupDef)[],
): (ColDef | ColGroupDef)[] => {
    const setRowGroup = (cd: ColDef): void => {
        cd.rowGroup = cd.field === selectedViewByOption.value;
    };

    colDefs.forEach((cdAny: ColDef | ColGroupDef, i: number) => {
        if (isColGroupDefGuard(cdAny)) {
            cdAny.children.forEach((cd: ColDef) => {
                setRowGroup(cd);
            });
        } else {
            setRowGroup(cdAny);
        }
    });

    return colDefs;
};

const setGroupByColumnDefinitions = (
    gridOptions: GridOptions,
    selectedViewByOption: SelectorOption,
    colDefs: (ColDef | ColGroupDef)[],
    autoSortedColumnStateColId: string,
): void => {
    const columnDefs = groupColumnDefsBy(selectedViewByOption, colDefs);
    const colState: ColumnState = {
        colId: autoSortedColumnStateColId || 'ag-Grid-AutoColumn',
        sort: 'asc',
    };
    gridOptions.api?.setColumnDefs(columnDefs);
    gridOptions.columnApi?.applyColumnState({
        state: [colState],
        defaultState: { sort: null },
    });
};

export { setGroupByColumnDefinitions };
