export default class ExcelCellRenderer {
    init(params) {
        let gridOptions = params.api.gridOptionsWrapper.gridOptions;

        this.overrideContext = gridOptions.overrideContext;

        this.rootElement = document.createElement('div');
        this.rootElement.style.width = '100%';

        let viewFactory = gridOptions.viewCompiler.compile(`<template>${params.viewTemplate}</template>`);
        this.view = viewFactory.create(gridOptions.container);

        this.refresh(params);
        this.initialized = true;
    }

    getGui() {
        return this.rootElement;
    }

    refresh(params) {
        if (this.initialized)
            return;

        this.view.bind(params, this.overrideContext);
        this.view.appendNodesTo(this.rootElement);
        this.view.attached();
    }

    destroy() {
        this.view.unbind();
        this.view.removeNodes();
        this.view.detached();
    }
}
