import { LicenseManager } from 'ag-grid-enterprise/dist/ag-grid-enterprise';
import { PLATFORM } from 'aurelia-framework';
import { Guid } from 'guid-typescript';
import environment from '../config/environment.json';
import ApplicationInsightsService from './infrastructure/application-insights-service';
import './infrastructure/closest'; // polyfill for IE
import FoundationValidationRenderer from './infrastructure/foundation-validation-renderer';
import HttpClientConfigurator from './infrastructure/http-client-configurator';
import Logger from './infrastructure/logger';
import throttle from './infrastructure/throttle';
import SecurityService from './security/security-service';

// This is needed for async/await support.
window.regeneratorRuntime = regeneratorRuntime;

throttle('resize', 'optimizedresize');

export function configure(aurelia) {
    aurelia.use
        .standardConfiguration()
        .feature(PLATFORM.moduleName('infrastructure/components/index'))
        .feature(PLATFORM.moduleName('infrastructure/converters/index'))
        .plugin(PLATFORM.moduleName('aurelia-dialog'))
        .plugin(PLATFORM.moduleName('aurelia-validation'))
        .plugin(PLATFORM.moduleName('aurelia-ui-virtualization'))
        .container.registerHandler('foundation-validation-renderer', (container) =>
            container.get(FoundationValidationRenderer),
        );

    // ag-grid-enterprise license
    // renewed at version 32.0.2
    LicenseManager.setLicenseKey(
        'Using_this_{AG_Grid}_Enterprise_key_{AG-058246}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Food_Safety_Net_Services}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{JBS_Custom_Software_Solutions_FSNS_Portal}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{JBS_Custom_Software_Solutions_FSNS_Portal}_need_to_be_licensed___{JBS_Custom_Software_Solutions_FSNS_Portal}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{23_June_2025}____[v3]_[01]_MTc1MDYzMzIwMDAwMA==4d74bbe700f600cc59b776d98d7d22dc',
    );

    if (environment.debug) {
        aurelia.use.developmentLogging();
    }

    if (environment.testing) {
        aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
    }

    aurelia.container.registerSingleton(ApplicationInsightsService);

    aurelia.start().then(() => {
        var originalErrorHandler = window.onerror;

        var logger = aurelia.container.get(Logger);
        logger.name = 'main';

        window.onerror = (messageOrEvent, source, lineno, colno, error) => {
            logger.error('An unhandled error occurred.', error);
            originalErrorHandler &&
                originalErrorHandler(messageOrEvent, source, lineno, colno, error);
        };

        window.addEventListener('unhandledrejection', (event) => {
            logger.error('Unhandled rejected promise.', event.reason);
        });

        if (Guid.isGuid(appInsightsKey)) {
            var appInsightsService = aurelia.container.get(ApplicationInsightsService);
            appInsightsService.registerKey(appInsightsKey);
        }

        var securityService = aurelia.container.get(SecurityService);
        var httpClientConfigurator = aurelia.container.get(HttpClientConfigurator);
        httpClientConfigurator.configure();

        securityService.navigateToRoot();
    });
}
