import CollectionType from 'location-testing/collection-type';
import GenerateTasksDistributionMethod from './generate-tasks-distribution-method';
import GenerateTasksPointZoneDistributionMethod from './generate-tasks-point-zone-distribution-method';

export default class TaskGenerationRequest {
    planId: number;

    timeZone: any;

    startDate: Date;
    endDate: Date;

    requestedTaskCount: number;
    tasksToBeGenerated: number; // used only for display purposes

    riskPriorities: TaskRequestPriority[];
    collectionType: CollectionType;

    testMethodIds: number[];

    blackoutDates: Date[];

    blackoutSundays: boolean;
    blackoutMondays: boolean;
    blackoutTuesdays: boolean;
    blackoutWednesdays: boolean;
    blackoutThursdays: boolean;
    blackoutFridays: boolean;
    blackoutSaturdays: boolean;

    pointDistributionMethod: GenerateTasksDistributionMethod;

    pointZoneDistributionMethod: GenerateTasksPointZoneDistributionMethod;
    pointZoneDistributions: TaskGenerationZoneDistribution[];
}

export class TaskRequestPriority {
    risk: string;
    percentage: number;
}

export class TaskGenerationZoneDistribution {
    zone: number;
    value: number;
    type: 'count' | 'percentage';

    constructor(zone: number, value: number, type: 'count' | 'percentage') {
        this.zone = zone;
        this.value = value;
        this.type = type;
    }

    private static getZoneForServer(
        pointZoneDistributionMethod: GenerateTasksPointZoneDistributionMethod,
        zoneDistributions: any,
        zonePercentageCount: any) {
        const { zone1, zone2, zone3, zone4 } = pointZoneDistributionMethod == GenerateTasksPointZoneDistributionMethod.DISCRETE
            ? zoneDistributions
            : zonePercentageCount;

        return { zone1, zone2, zone3, zone4 };
    }

    private static getZoneForUI(
        pointZoneDistributionMethod: GenerateTasksPointZoneDistributionMethod,
        zoneDistributions: any,
        zonePercentageCount: any) {
        const { zone1, zone2, zone3, zone4 } = (pointZoneDistributionMethod === GenerateTasksPointZoneDistributionMethod.DISCRETE ||
            pointZoneDistributionMethod === GenerateTasksPointZoneDistributionMethod.PERCENTAGE)
            ? zoneDistributions
            : zonePercentageCount;

        return { zone1, zone2, zone3, zone4 };
    }

    private static getType(pointZoneDistributionMethod: GenerateTasksPointZoneDistributionMethod) {
        return (pointZoneDistributionMethod === GenerateTasksPointZoneDistributionMethod.DISCRETE ||
            pointZoneDistributionMethod === GenerateTasksPointZoneDistributionMethod.NOT_APPLICABLE)
            ? 'count'
            : 'percentage'
    }

    public static createAllZonesForServer(
        pointZoneDistributionMethod: GenerateTasksPointZoneDistributionMethod,
        zoneDistributions: any,
        zonePercentageCount: any) {
        const { zone1, zone2, zone3, zone4 } = this.getZoneForServer(pointZoneDistributionMethod,
            zoneDistributions,
            zonePercentageCount);
        const type = this.getType(pointZoneDistributionMethod);

        return [
            new TaskGenerationZoneDistribution(1, Number(zone1), type),
            new TaskGenerationZoneDistribution(2, Number(zone2), type),
            new TaskGenerationZoneDistribution(3, Number(zone3), type),
            new TaskGenerationZoneDistribution(4, Number(zone4), type)
        ];
    }

    public static createAllZonesForUI(
        pointZoneDistributionMethod: GenerateTasksPointZoneDistributionMethod,
        zoneDistributions: any,
        zonePercentageCount: any) {
        const { zone1, zone2, zone3, zone4 } = this.getZoneForUI(pointZoneDistributionMethod,
            zoneDistributions,
            zonePercentageCount);
        const type = this.getType(pointZoneDistributionMethod);

        return [
            new TaskGenerationZoneDistribution(1, Number(zone1), type),
            new TaskGenerationZoneDistribution(2, Number(zone2), type),
            new TaskGenerationZoneDistribution(3, Number(zone3), type),
            new TaskGenerationZoneDistribution(4, Number(zone4), type)
        ];
    }
}

