import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import SelectorOption from 'infrastructure/selector-option';
import CoAReportStyle from './coa-report-style';

@autoinject
export default class CoAReportStyleService {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    async getCoAReportStyles(): Promise<CoAReportStyle[]> {
        let coaReportStyles = await this.httpClient.get('coa-report-styles');
        return coaReportStyles as unknown as CoAReportStyle[];
    }

    async getCoAReportStyleOptions(coaReportStyles: CoAReportStyle[] = null): Promise<SelectorOption[]> {
        let reportStyles = (coaReportStyles === null) ? await this.getCoAReportStyles() : coaReportStyles;

        return reportStyles.map(rs => ({
            title: rs.id,
            value: rs.id
        }));
    }
}
