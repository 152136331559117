import { computedFrom } from 'aurelia-framework';
import UiLabelTemplate from './ui-label-template';

export default class UiLabelTemplateElement {
    uiLabelTemplate: UiLabelTemplate;
    type: string;
    fontSize: number;
    fontFamily: string;
    bold: boolean;
    mode: 'field' | 'template';
    template: string;
    displayOnly: boolean;

    private _objectName: string;

    get objectName() {
        return this._objectName;
    }
    set objectName(value) {
        this._objectName = value;
        this.fieldName = null;
    }

    private _x: number;

    get x() {
        return this._x;
    }
    set x(value) {
        let finalValue =
            value < 0
                ? 0
                : value > this.uiLabelTemplate.width - this.width
                ? this.uiLabelTemplate.width - this.width
                : value;

        this._x = finalValue;
    }

    private _y: number;

    get y() {
        return this._y;
    }
    set y(value) {
        let finalValue =
            value < 0
                ? 0
                : value > this.uiLabelTemplate.height
                ? this.uiLabelTemplate.height
                : value;

        this._y = finalValue;
    }

    private _width: number;

    get width() {
        return this._width;
    }
    set width(value) {
        let finalValue =
            value < 10
                ? 10
                : value > this.uiLabelTemplate.width - this.x
                ? this.uiLabelTemplate.width - this.x
                : value;

        this._width = finalValue;
    }

    fieldName: string;
    textAlign: string;
    sampleTextOverride: string;

    error = false;

    @computedFrom('mode', 'objectName', 'fieldName', 'template', 'sampleTextOverride')
    get sampleText() {
        if (this.sampleTextOverride) return this.sampleTextOverride;

        if (this.mode === 'field' && (this.objectName || this.fieldName))
            return `${this.objectName ?? ''}.${this.fieldName ?? ''}`;

        return 'Sample text';
    }

    @computedFrom('mode', 'objectName', 'fieldName', 'template')
    get hoverText() {
        if (this.mode === 'field' && (this.objectName || this.fieldName))
            return `${this.objectName ?? ''}.${this.fieldName ?? ''}`;

        if (this.mode === 'template') return this.template;

        return '';
    }

    static new(uiLabelTemplate: UiLabelTemplate, type, displayOnly: boolean) {
        let uiLabelTemplateElement = new UiLabelTemplateElement();
        uiLabelTemplateElement.uiLabelTemplate = uiLabelTemplate;
        uiLabelTemplateElement.type = type;
        uiLabelTemplateElement.x = 0;
        uiLabelTemplateElement.y = 0;
        uiLabelTemplateElement.fontSize = 6;
        uiLabelTemplateElement.fontFamily = 'Helvetica';
        uiLabelTemplateElement.bold = false;
        uiLabelTemplateElement.mode = 'field';
        uiLabelTemplateElement.width = 50;
        uiLabelTemplateElement.textAlign = 'left';
        uiLabelTemplateElement.displayOnly = displayOnly;
        if (uiLabelTemplate.type === 'Sample') {
            uiLabelTemplateElement.objectName = 'sampleLabel';
            uiLabelTemplateElement.fieldName = 'sampleLabelField';
        }

        return uiLabelTemplateElement;
    }

    static fromModel(uiLabelTemplate: UiLabelTemplate, element) {
        let uiLabelTemplateElement = new UiLabelTemplateElement();
        uiLabelTemplateElement.uiLabelTemplate = uiLabelTemplate;
        uiLabelTemplateElement.type = element.type;
        uiLabelTemplateElement.x = element.x;
        uiLabelTemplateElement.y = element.y;
        uiLabelTemplateElement.fontSize = element.fontSize;
        uiLabelTemplateElement.fontFamily = 'Helvetica';
        uiLabelTemplateElement.bold = (element.font ?? '').endsWith('-Bold');
        uiLabelTemplateElement.mode = element.mode ?? (element.template ? 'template' : 'field');
        uiLabelTemplateElement.template = element.template;
        uiLabelTemplateElement.objectName = element.objectName;
        uiLabelTemplateElement.fieldName = element.fieldName;
        uiLabelTemplateElement.width = element.options.width;
        uiLabelTemplateElement.textAlign = element.options.align || 'left';
        uiLabelTemplateElement.sampleTextOverride = element.sampleTextOverride;

        return uiLabelTemplateElement;
    }

    toModel() {
        return {
            type: this.type,
            x: this.x,
            y: this.y,
            fontSize: this.fontSize,
            font: this.fontFamily + (this.bold ? '-Bold' : ''),
            mode: this.mode,
            template: this.template,
            objectName: this.objectName,
            fieldName: this.fieldName,
            sampleTextOverride: this.sampleTextOverride,
            options: {
                width: this.width,
                align: this.textAlign === 'left' ? null : this.textAlign,
            },
        };
    }
}
