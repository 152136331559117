import { autoinject, BindingEngine } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
} from 'aurelia-validation';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import SecurityService from 'security/security-service';
import UserService from 'users/user-service';
import {
    emptyExportTaskCollectionTemplate,
    ExportTaskCollectionTemplate,
} from './export-task-collection-template';
import { ExportTaskCollectionTemplateColumn } from './export-task-collection-template-column';
import {
    emptyExportTaskCollectionTemplateConfiguration,
    ExportTaskCollectionTemplateConfiguration,
} from './export-task-collection-template-configuration';
import { ExportTaskCollectionTemplateFooterOptions } from './export-task-collection-template-footer-options';
import { ExportTaskCollectionTemplateHeaderOptions } from './export-task-collection-template-header-options';
import ExportTaskCollectionTemplateService from './export-task-collection-template-service';
@autoinject
export class ExportTaskCollectionTemplateDetail {
    canEdit: boolean;
    exportTaskCollectionTemplate: ExportTaskCollectionTemplate;
    fieldMappings: Record<string, string>;
    formChanged: boolean;
    organizations: any[];
    validationController: ValidationController;
    organizationRules: ValidationRules;
    headerCellTextRules: ValidationRules;
    footerCellTextRules: ValidationRules;

    constructor(
        private bindingEngine: BindingEngine,
        private dialogPresenter: DialogPresenter,
        private exportTaskCollectionTemplateService: ExportTaskCollectionTemplateService,
        private logger: Logger,
        private pageContext: PageContext,
        private router: Router,
        private securityService: SecurityService,
        private userService: UserService,
        validationControllerFactory: ValidationControllerFactory,
    ) {
        this.logger.name = 'label-template-detail';

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;
        this.canEdit =
            this.securityService.hasPermission('EditExportTaskCollectionTemplates') &&
            !this.securityService.isImpersonating();
    }

    handleFormChange() {
        this.formChanged = true;
    }

    async addOrganizationConfiguration() {
        const config = emptyExportTaskCollectionTemplateConfiguration();
        this.exportTaskCollectionTemplate.organizationConfigurations.push(config);
        await this.validationController.validate({
            object: this.exportTaskCollectionTemplate,
            propertyName: 'organizationConfigurations',
        });
        this.validationController.addObject(config, this.organizationRules);
    }

    async removeOrganizationConfiguration(
        organizationConfiguration: ExportTaskCollectionTemplateConfiguration,
    ) {
        const index =
            this.exportTaskCollectionTemplate.organizationConfigurations.indexOf(
                organizationConfiguration,
            );
        this.exportTaskCollectionTemplate.organizationConfigurations.splice(index, 1);
        this.validationController.removeObject(organizationConfiguration);
        await this.validationController.validate({
            object: this.exportTaskCollectionTemplate,
            propertyName: 'organizationConfigurations',
        });
    }

    addHeaderCellText() {
        const text = '';
        this.exportTaskCollectionTemplate.headerOptions.cellText.push(text);
    }

    removeHeaderCellText(text: string) {
        const index = this.exportTaskCollectionTemplate.headerOptions.cellText.indexOf(text);
        this.exportTaskCollectionTemplate.headerOptions.cellText.splice(index, 1);
    }

    addFooterCellText() {
        const text = '';
        this.exportTaskCollectionTemplate.footerOptions.cellText.push(text);
    }

    removeFooterCellText(text: string) {
        const index = this.exportTaskCollectionTemplate.footerOptions.cellText.indexOf(text);
        this.exportTaskCollectionTemplate.footerOptions.cellText.splice(index, 1);
    }

    async onColumnChecked() {
        await this.validationController.validate({
            object: this.exportTaskCollectionTemplate,
            propertyName: 'columns',
        });
    }

    async save() {
        const validationResult = await this.validationController.validate();
        if (!validationResult.valid) {
            return;
        }

        this.pageContext.isLoading = true;
        try {
            const exportTaskCollectionTemplate =
                this.exportTaskCollectionTemplate.id === 0
                    ? await this.exportTaskCollectionTemplateService.createExportTaskCollectionTemplates(
                          this.exportTaskCollectionTemplate,
                      )
                    : await this.exportTaskCollectionTemplateService.updateExportTaskCollectionTemplates(
                          this.exportTaskCollectionTemplate,
                      );
            this.exportTaskCollectionTemplate = exportTaskCollectionTemplate;
            this.pageContext.showSuccessOverlay(
                'Export task collection template saved successfully.',
            );

            this.formChanged = false;
            this.router.navigateToRoute(
                'export-task-collection-template-detail',
                { id: this.exportTaskCollectionTemplate.id },
                { replace: true },
            );
        } catch (error) {
            this.logger.error('Error saving export task collection template', error, {
                exportTaskCollectionTemplate: this.exportTaskCollectionTemplate,
            });
            this.dialogPresenter.showAlert(
                'Error Saving Export Task Collection Template',
                'An error occured while saving the export task collection template. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    cancel() {
        this.exportTaskCollectionTemplate = emptyExportTaskCollectionTemplate();
        this.exportTaskCollectionTemplate.columns = this.mapFieldMappingsToExportColumns();
    }

    private mapFieldMappingsToExportColumns() {
        const exportTaskCollectionTemplateColumns = [] as ExportTaskCollectionTemplateColumn[];
        for (var field in this.fieldMappings) {
            exportTaskCollectionTemplateColumns.push({
                name: field,
                displayName: this.fieldMappings[field],
                selected: false,
            });
        }

        return exportTaskCollectionTemplateColumns;
    }

    private setupValidation() {
        ValidationRules.ensure((m: ExportTaskCollectionTemplate) => m.columns)
            .satisfies((cols: ExportTaskCollectionTemplateColumn[]) =>
                cols.some((col: ExportTaskCollectionTemplateColumn) => col.selected),
            )
            .withMessage('At least one field must be selected.')
            .ensure((m) => m.organizationConfigurations)
            .required()
            .minItems(1)
            .withMessage(
                'No organizations have been configured. Please click the Add Organization button to add one.',
            )
            .on(this.exportTaskCollectionTemplate);

        const ensureSingleOccurence = (organizationId: number) => {
            return (
                this.exportTaskCollectionTemplate.organizationConfigurations.filter(
                    (config: ExportTaskCollectionTemplateConfiguration) =>
                        config.organizationId === organizationId,
                ).length === 1
            );
        };
        this.organizationRules = ValidationRules.ensure(
            (m: ExportTaskCollectionTemplateConfiguration) => m.organizationId,
        )
            .satisfies((id: number) => id > 0)
            .withMessage('Organization is required.')
            .satisfies(ensureSingleOccurence)
            .withMessage('An organization can only be added once.')
            .ensure((m) => m.templateName)
            .required().rules;

        this.headerCellTextRules = ValidationRules.ensure(
            (m: ExportTaskCollectionTemplateHeaderOptions) => m.cellText,
        )
            .satisfies((arr: string[]) => arr.every((s) => s.length < 100))
            .withMessage('Maximum length allowed for each cell text is 100 characters.').rules;

        this.footerCellTextRules = ValidationRules.ensure(
            (m: ExportTaskCollectionTemplateFooterOptions) => m.cellText,
        )
            .satisfies((arr: string[]) => arr.every((s) => s.length < 100))
            .withMessage('Maximum length allowed for each cell text is 100 characters.').rules;

        this.exportTaskCollectionTemplate.organizationConfigurations.forEach(
            (config: ExportTaskCollectionTemplateConfiguration) => {
                this.validationController.addObject(config, this.organizationRules);
            },
        );
        this.validationController.addObject(
            this.exportTaskCollectionTemplate.headerOptions,
            this.headerCellTextRules,
        );
        this.validationController.addObject(
            this.exportTaskCollectionTemplate.footerOptions,
            this.footerCellTextRules,
        );
    }

    activate(params) {
        (async () => {
            this.pageContext.isLoading = true;
            try {
                const [organizations, exportTaskCollectionTemplate, fieldMappings] =
                    await Promise.all([
                        this.userService.getCurrentUserOrganizations(),
                        params.id === 'create'
                            ? emptyExportTaskCollectionTemplate()
                            : this.exportTaskCollectionTemplateService.getExportTaskCollectionTemplate(
                                  params.id,
                              ),
                        this.exportTaskCollectionTemplateService.getExportTaskCollectionTemplateColumns(),
                    ]);

                this.organizations = organizations;
                this.fieldMappings = fieldMappings;
                if (exportTaskCollectionTemplate.columns.length === 0) {
                    exportTaskCollectionTemplate.columns = this.mapFieldMappingsToExportColumns();
                }
                this.exportTaskCollectionTemplate = exportTaskCollectionTemplate;
                this.setupValidation();
            } catch (error) {
                this.logger.error('Error loading export task collection template.', error);
                this.dialogPresenter.showAlert(
                    'Error Loading Export Task Collection Template',
                    'An error occurred while loading the export task collection template. Please try again later.',
                );
            }

            this.pageContext.isLoading = false;
        })();
    }

    unbind() {
        this.exportTaskCollectionTemplate.organizationConfigurations.forEach(
            (config: ExportTaskCollectionTemplateConfiguration) => {
                this.validationController.removeObject(config);
            },
        );
    }
}
