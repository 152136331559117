import { inject, bindable, bindingMode } from 'aurelia-framework';
import $ from 'jquery';

@inject(Element)
export class Slider {
    @bindable start;
    @bindable end;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value;

    constructor(element) {
        this.element = element;
        this.handleSliderChange = this.handleSliderChange.bind(this);
    }

    attached() {
        this.$slider = $(this.element.querySelector('div.slider'));
        this.$slider.foundation();
        this.$slider.on('changed.zf.slider', this.handleSliderChange);
        this.sliderPlugin = this.$slider.data('zfPlugin');
    }

    detached() {
        if (this.$slider) {
            this.$slider.off('changed.zf.slider', this.handleSliderChange);
            this.$slider.data('zfPlugin')?.destroy();
        }
    }

    startChanged() {
        if (this.valueInput)
            this.valueInput.min = this.start;

        if (this.sliderPlugin)
            this.sliderPlugin.options.start = this.start;
    }

    endChanged() {
        if (this.valueInput)
            this.valueInput.max = this.end;

        if (this.sliderPlugin)
            this.sliderPlugin.options.end = this.end;
    }

    valueChanged() {
        if (this.valueInput)
            this.valueInput.value = this.value;
        
        if (this.sliderPlugin)
            this.sliderPlugin.setHandles();
    }

    handleSliderChange() {
        if (!this.valueInput)
            return;

        let newValue = parseInt(this.valueInput.value);
        if (this.value !== newValue)
            this.value = newValue;
    }
};
