import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class PointAttributeService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getPointAttributes() {
        return this.httpClient.get(`location-testing/point-attributes`);
    }

    getPointAttributeValues(name) {
        return this.httpClient.get(`location-testing/point-attributes/${name}/values`);
    }
}
