import { computedFrom } from 'aurelia-framework';
import DataGridSelectAllItem from './data-grid-select-all-item';

/*
* NOTE: The values you pass into _filterKeys
*       do not currently support TS get() (TODO).
*       Map your filter keys to the underlying field name.
*       e.g. use '_name' for 'name' when name is 'public get name()'
*/
export default class DataGridSelectAll {
    private _allSelected: boolean;
    private _dataGridItems: DataGridSelectAllItem[];
    private _canDeleteItems: boolean;
    private _selectedDataGridItems: DataGridSelectAllItem[];
    private _filteredDataGridItems: DataGridSelectAllItem[];
    private _filterKeys: string[];

    constructor(
        dataGridItems: DataGridSelectAllItem[],
        filterKeys: string[]
    ) {
        this._dataGridItems = dataGridItems;
        this._allSelected = false;
        this._canDeleteItems = false;
        this._selectedDataGridItems = [];
        this._filterKeys = filterKeys;
        this._filteredDataGridItems = this._dataGridItems;
    }

    @computedFrom('_allSelected')
    public get allSelected() {
        return this._allSelected;
    }

    public set allSelected(selected: boolean) {
        this._allSelected = selected;
        if (this._allSelected) {
            this._dataGridItems.forEach((dgi: DataGridSelectAllItem) => dgi.isSelected = true);
            this._selectedDataGridItems = this._dataGridItems;

        } else {
            this._dataGridItems.forEach((dgi: DataGridSelectAllItem) => dgi.isSelected = false);
            this._selectedDataGridItems = [];
        }

        this._canDeleteItems = this._selectedDataGridItems.length &&
            this._selectedDataGridItems.every(i => i.isActive);
    }

    @computedFrom('_canDeleteItems')
    public get canDeleteItems() {
        return this._canDeleteItems;
    }

    public get filteredDataGridItems() {
        return this._filteredDataGridItems;
    }

    public get dataGridItems() {
        return this._dataGridItems;
    }

    public set dataGridItems(items: DataGridSelectAllItem[]) {
        this._dataGridItems = items;
        this._filteredDataGridItems = this._dataGridItems;
    }

    public get selectedDataGridItems() {
        return this._selectedDataGridItems;
    }

    isSelectedChanged() {
        this._allSelected = this._dataGridItems.every(i => i.isSelected);
        this._selectedDataGridItems = this._dataGridItems
            .filter((dgi: DataGridSelectAllItem) => dgi.isSelected && dgi.isActive);
        this._canDeleteItems = this._selectedDataGridItems.length &&
            this._selectedDataGridItems.every(i => i.isActive);
    }

    filterDataGridItems(filterText: string) {
        if (!filterText || filterText.length === 0 || this._filterKeys.length === 0) {
            this._filteredDataGridItems = this._dataGridItems;
            return;
        }

        const filteredItems = this._dataGridItems.filter((i) => {
            return Object.keys(i).reduce((acc, curr) => {
                if (this._filterKeys.includes(curr)) {
                    return acc || i[curr].toLowerCase().includes(filterText.toLowerCase());
                }
            });
        });

        this._filteredDataGridItems = filteredItems;
    }
}
