import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import SelectorOption from 'infrastructure/selector-option';
import PointSelectorFilter from 'location-testing/point-selector-filter';
import RecordStatus, { getRecordStatusOptions } from 'record-status';
import CompareUtility from '../../infrastructure/compare-utility';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import SecurityService from '../../security/security-service';
import PointAttributeService from '../point-attributes/point-attribute-service';
import { getPointRiskOptions } from './point-risk';
import PointRiskComparer from './point-risk-comparer';
import PointService from './point-service';

@autoinject
export class PointList {
    @observable filterText;

    // subscriptions
    applyFiltersSubscription: Subscription;
    clearFiltersSubscription: Subscription;

    gridOptions: any;
    pointRiskOptions: SelectorOption[];
    mapId: number;

    allSelected: boolean;
    canEditPoints: boolean;

    selectedActivePoints: any[];
    pointAttributes: any[];
    recordStatusOptions: SelectorOption[];
    selectedRecordStatuses: RecordStatus[];

    @observable points: any[];
    pointsView: any[];

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private pointService: PointService,
        private pointAttributeService: PointAttributeService,
        private filters: PointSelectorFilter,
    ) {
        this.logger = logger;
        this.logger.name = 'point-list';

        this.filterText = '';

        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template:
                        '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate:
                        '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Name',
                    field: 'name',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                    sort: 'asc',
                },
                {
                    suppressMenu: true,
                    headerName: 'Description',
                    field: 'description',
                    width: 275,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Room',
                    field: 'room',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                },
                { suppressMenu: true, headerName: 'Zone', field: 'zone' },
                {
                    suppressMenu: true,
                    headerName: 'Mobile',
                    field: 'mobile',
                    width: 80,
                    template: '<span>${decodeMobile(data.mobile)}</span>',
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Paused Pending Remediation',
                    field: 'isPausedPendingRemediation',
                    template: '<span>${decodeIsPaused(data.isPausedPendingRemediation)}</span>',
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                },
                {
                    suppressMenu: true,
                    headerName: 'Type',
                    field: 'type',
                    comparator: CompareUtility.compareStringsInsensitive,
                },
                {
                    suppressMenu: true,
                    headerName: 'Risk',
                    field: 'risk',
                    template: '<span>${decodeRisk(data.risk)}</span>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                    comparator: PointRiskComparer.compareValues,
                },
                { suppressMenu: true, headerName: 'Plans', field: 'planCount' },
                { suppressMenu: true, headerName: 'Open Tasks', field: 'openTaskCount' },
                {
                    suppressMenu: true,
                    headerName: 'Unassigned Tasks',
                    field: 'unassignedTaskCount',
                },
                {
                    suppressMenu: true,
                    headerName: 'Active',
                    field: 'isActive',
                    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button click.delegate="navigateToPointDetail(data.id)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button if.bind="canEditPoints" click.trigger="deletePoint(data)" disabled.bind="!data.isActive">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 150,
                    sortable: false,
                    suppressSizeToFit: true,
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.allSelected = false;
        this.selectedActivePoints = [];
        this.selectedRecordStatuses = [RecordStatus.ACTIVE];
        this.canEditPoints =
            this.securityService.hasPermission('EditPoints') &&
            !this.securityService.isImpersonating();
        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);

        this.pointRiskOptions = getPointRiskOptions();
    }

    decodeRisk(risk) {
        return this.pointRiskOptions.find((o) => o.value === risk).title;
    }

    decodeMobile(mobile) {
        return mobile ? 'Yes' : 'No';
    }

    decodeIsPaused(isPaused) {
        return isPaused ? 'Yes' : 'No';
    }

    navigateToPointDetail(id) {
        this.router.navigateToRoute('point-detail', { id, mapId: this.mapId });
    }

    setSelectedActivePoints() {
        this.selectedActivePoints = this.pointsView.filter(
            (point) => point.isSelected && point.isActive,
        );
    }

    isSelectedChanged() {
        this.allSelected = this.pointsView.every((point) => point.isSelected);
        this.setSelectedActivePoints();
    }

    allSelectedClicked() {
        if (this.pointsView) {
            this.allSelected = !this.pointsView.every((point) => point.isSelected);

            for (let point of this.pointsView) point.isSelected = this.allSelected;
        }

        this.setSelectedActivePoints();

        return true;
    }

    filterTextChanged() {
        this.updatePointsView();
    }

    async handleApplyFilters() {
        await this.loadPoints();
        this.updatePointsView();
    }

    handleClearFilters() {
        this.filters.reset();
        this.selectedRecordStatuses = [RecordStatus.ACTIVE];

        this.updatePointsView();
    }

    @computedFrom('filters.filterCount', 'selectedRecordStatuses')
    get filterCount() {
        let localCount = Number(
            !!this.selectedRecordStatuses && this.selectedRecordStatuses[0] !== RecordStatus.ACTIVE,
        );
        return localCount + this.filters.filterCount;
    }

    updatePointsView() {
        var lowerCasedFilterText = this.filterText.toLowerCase();

        if (this.points) {
            let pointAttributeFiltersInUse =
                this.filters.convertPointAttributeSelectionsToPointAttributes().length > 0;

            this.pointsView = this.filters
                .applyFilters(this.points, pointAttributeFiltersInUse)
                .filter((p) => (p.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1);
            this.allSelected = this.pointsView.every((point) => point.isSelected);
        }
    }

    pointsChanged() {
        this.filters.setupFilterOptions(this.points, this.pointAttributes);
        this.updatePointsView();
    }

    async loadPoints() {
        let pointAttributes = this.filters.convertPointAttributeSelectionsToPointAttributes();
        let selectedRecordStatus = this.selectedRecordStatuses?.[0] ?? RecordStatus.ALL;

        this.points = await this.pointService.getPoints(
            {
                mapIds: [this.mapId],
                pointAttributes,
                recordStatus: selectedRecordStatus,
            },
            'summary',
        );
    }

    activate(params) {
        this.applyFiltersSubscription = this.eventAggregator.subscribe(
            'filters.apply',
            this.handleApplyFilters,
        );
        this.clearFiltersSubscription = this.eventAggregator.subscribe(
            'filters.clear',
            this.handleClearFilters,
        );

        this.mapId = params.mapId;

        (async () => {
            this.pageContext.isLoading = true;

            try {
                const [pointAttributes, recordStatusOptions] = await Promise.all([
                    this.pointAttributeService.getPointAttributes(),
                    getRecordStatusOptions(),
                ]);

                this.pointAttributes = pointAttributes;
                this.recordStatusOptions = recordStatusOptions;

                await this.loadPoints();
            } catch (error) {
                this.logger.error('Error loading points.', error);
                this.dialogPresenter.showAlert(
                    'Error Loading Points',
                    'An error occurred while loading the points. Please try again later.',
                );
            }

            this.pageContext.isLoading = false;
        })();
    }

    deactivate() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
        this.clearFiltersSubscription && this.clearFiltersSubscription.dispose();
    }

    deletePoint(point) {
        this.dialogPresenter
            .showConfirmation('Delete Point', 'Are you sure you want to delete this point?')
            .then((confirmed) => {
                if (!confirmed) return;

                this.pageContext.isLoading = true;
                this.pointService
                    .deletePoints([point.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Point deleted successfully.');

                        point.isSelected = false;
                        point.isActive = false;

                        this.setSelectedActivePoints();
                    })
                    .catch((error) => {
                        this.logger.error('Error deleting point.', error, { pointId: point.id });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, false);
                    });
            });
    }

    deleteSelectedPoints() {
        var pointsToDelete = this.pointsView.filter((u) => u.isSelected);
        if (!pointsToDelete.length) return;

        this.dialogPresenter
            .showConfirmation(
                'Delete Selected Points',
                'Are you sure you want to delete the selected points? This will remove the point from all associated plans.',
            )
            .then((confirmed) => {
                if (!confirmed) return;

                var pointIds = pointsToDelete.map((point) => point.id);

                this.pageContext.isLoading = true;
                this.pointService
                    .deletePoints(pointIds)
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay(
                            'Selected points deleted successfully.',
                        );

                        for (let point of pointsToDelete) {
                            point.isSelected = false;
                            point.isActive = false;

                            this.setSelectedActivePoints();
                        }

                        this.updatePointsView();
                    })
                    .catch((error) => {
                        this.logger.error('Error deleting points.', error, { pointIds });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, true);
                    });
            });
    }

    handleDeleteError(error, multiplePoints) {
        this.dialogPresenter.showAlert(
            'Error Deleting Point' + (multiplePoints ? 's' : ''),
            this.getApiErrorMessage(error.apiErrorCode, multiplePoints),
        );
    }

    getApiErrorMessage(errorCode, multiplePoints) {
        if (errorCode === 2)
            return 'You dont have access to delete the point' + (multiplePoints ? 's' : '');

        return 'An unexpected error occurred while trying to delete points. Please try again later.';
    }
}
