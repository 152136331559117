import { bindable } from 'aurelia-framework';

export class RequestDetailLocationTestingSampleInfo {
    @bindable organizationDetail;
    @bindable lab;
    @bindable request;
    @bindable mode;

    exportSampleIds() {
        let csvContent = 'data:text/csv;charset=utf-8,';
        csvContent += '"Task ID","Sample ID"\r\n';

        for (let sample of this.request.samples)
            for (let part of sample.parts)
                csvContent += `${part.taskId},${sample.id}` + '\r\n';

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `Request ${this.request.id} Sample IDs.csv`);
        link.innerHTML = 'Click Here to download';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
