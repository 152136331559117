import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import PageContext from '../../infrastructure/page-context';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import CompareUtility from '../../infrastructure/compare-utility';
import Logger from '../../infrastructure/logger';
import VideoService from './video-service';
import SecurityService from '../../security/security-service';

@inject(Router, PageContext, DialogPresenter, Logger, SecurityService, VideoService)
export default class VideoList {

    @observable filterText;

    constructor(router, pageContext, dialogPresenter, logger, securityService, videoService) {
        this.router = router;
        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.logger = logger;
        this.logger.name = 'video-list';

        this.securityService = securityService;
        this.videoService = videoService;
        this.editMode = this.securityService.isCurrentUserInternal() && !this.securityService.isImpersonating();
        this.filterText = '';


        if (this.editMode) {
            this.gridOptions = {
                columnDefs: [
                    { suppressMenu: true, headerName: 'Video Id', field: 'externalVideoId', comparator: CompareUtility.compareStringsInsensitive, width: 120, suppressSizeToFit: true },
                    { suppressMenu: true, headerName: 'Title', field: 'title', comparator: CompareUtility.compareStringsInsensitive, sort: 'asc' },
                    { suppressMenu: true, headerName: 'Description', field: 'shortDescription', comparator: CompareUtility.compareStringsInsensitive },
                    {
                        suppressMenu: true,
                        headerName: 'Active',
                        field: 'isActive',
                        template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                        width: 120,
                        headerClass: 'text-center',
                        cellClass: 'medium-text-center',
                        suppressSizeToFit: true
                    },
                    {
                        suppressMenu: true,
                        headerName: '',
                        template: `
                            <button click.delegate="navigateToHelpVideoDetail(data.id)">
                                <i class="fa fa-pencil-square-o"></i>
                            </button>
                            <button click.trigger="deleteHelpVideo(data)" disabled.bind="!data.isActive">
                                <i class="fa fa-trash-o"></i>
                            </button>`,
                        cellClass: 'medium-text-right row-actions',
                        headerCellTemplate: '',
                        headerClass: 'row-actions',
                        width: 100,
                        sortable: false,
                        suppressSizeToFit: true
                    },
                ],
                defaultColDef: { sortable: true, resizable: true },
            };

        }

    }

    activate() {
        (async () => {

            this.pageContext.isLoading = true;
            try {

                this.videos = await this.videoService.getVideos();
                this.updateView();
                this.pageContext.isLoading = false;

            } catch (error) {
                this.logger.error('Error loading videos.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Videos',
                    'An error occurred while loading the videos. Please try again later.');
            }
        })();
    }

    filterTextChanged() {
        this.updateView();
    }

    updateView() {

        if (!this.videos)
            return;

        var lowerCasedFilterText = this.filterText.toLowerCase();

        this.videosView = this.videos.filter(v => (v.title || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
            (v.shortDescription || '').toLowerCase().indexOf(lowerCasedFilterText) > -1);

    }

    navigateToHelpVideoDetail(id) {
        this.router.navigateToRoute('video-detail', { id });
    }

    deleteHelpVideo(helpVideo) {
        this.dialogPresenter
            .showConfirmation('Delete Help Video', 'Are you sure you want to delete this help video?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                this.pageContext.isLoading = true;
                this.videoService
                    .deleteVideos([helpVideo.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Help Video deleted successfully.');

                        helpVideo.isActive = false;

                    })
                    .catch(error => {
                        this.logger.error('Error deleting Help Video.', error, { HelpVideoId: helpVideo.id });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error);
                    });
            });
    }

    handleDeleteError(error) {
        this.dialogPresenter
            .showAlert(
                'Error Deleting Help Video',
                'An unexpected error occurred while trying to delete Help Video. Please try again later.');
    }
};
