import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import CompareUtility from '../infrastructure/compare-utility';
import Logger from '../infrastructure/logger';
import PageContext from '../infrastructure/page-context';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import UserService from '../users/user-service';
import LoginAuditService from './login-audit-service';

@inject(Router, Logger, PageContext, DialogPresenter, UserService, LoginAuditService)
export default class LoginAuditDetail {
    
    constructor(router, logger, pageContext, dialogPresenter, userService, loginAuditService) {
        this.router = router;

        this.logger = logger;
        this.logger.name = 'login-audit-detail';

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.userService = userService;
        this.loginAuditService = loginAuditService;
        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    headerName: 'Successful',
                    field: 'isSuccessful',
                    template: '<i class="fa fa-check" if.bind="data.isSuccessful"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true
                },
                { suppressMenu: true, headerName: 'Attempted On', field: 'attemptDateTime', template: '${data.attemptDateTime | dateFormat}', width: 150 },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };
    }

    activate(params) {
        (async () => {
            this.user = null;
            this.loginAuditDetail = null;
            this.pageContext.isLoading = true;

            try {
                var results = await Promise.all([
                    this.userService.getUser(params.id),
                    this.loginAuditService.getLoginAuditDetail(params.id)
                ]);
                this.user = results[0];
                this.loginAuditDetail = results[1];  
                this.pageContext.isLoading = false;
            } catch (error) {
                var notFound = error.apiErrorCode === 1;

                this.logger.error('Error loading audit details.', error, { userId: params.id });
                this.pageContext.isLoading = false;
                this.dialogPresenter
                    .showAlert(
                        'Error Loading Login Audit Detail',
                        notFound ? `No user exists for ID ${params.id}` : 'An error occurred while loading login audit detail for this user. Please try again later.'
                    )
                    .then(() => this.router.navigateToRoute('login-audit'));
            }
        })();
    }
};
