import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import SelectorOption from 'infrastructure/selector-option';

type MapPlanFilterType = 'map' | 'plan';

@autoinject
export class MapPlanFilter {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) mapSummaryOptions: SelectorOption[];
    @bindable({ defaultBindingMode: bindingMode.twoWay }) planSummaryOptions: SelectorOption[];

    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedFilterType: MapPlanFilterType;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedValues: number[];

    readonly defaultFilterType = 'map';

    // subscriptions
    clearFilterSubscription: Subscription;

    constructor(private eventAggregator: EventAggregator) {
        this.selectedFilterType = this.selectedFilterType ?? this.defaultFilterType;
        this.reset = this.reset.bind(this);
    }

    attached() {
        this.clearFilterSubscription = this.eventAggregator.subscribe('filters.clear', this.reset);
    }

    detached() {
        this.clearFilterSubscription && this.clearFilterSubscription.dispose();
    }

    reset() {
        this.selectedFilterType = this.defaultFilterType;
        this.selectedValues = null;
    }

    selectedFilterTypeChanged() {
        this.selectedValues = null;
    }
}
