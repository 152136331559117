import { inject } from 'aurelia-framework';
import $ from 'jquery';

@inject(Element)
export class DataTogglerCustomAttribute {
    constructor(element) {
        this.element = element;
    }

    attached() {
        this.$toggler = $(this.element);
        this.$toggler.foundation();
    }

    detached() {
        if( !this.$toggler)
            return;

        var toggler = this.$toggler.data('zfPlugin');
        toggler?.destroy();
    }
};
