import { autoinject, BindingEngine, Disposable } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import Logger from '../../../infrastructure/logger';
import ParameterOptionService from '../../parameter-option-service';
import { FilterOperator } from './filter-operator';

export function isSelectionFilterDataType(dataType) {
    switch (dataType) {
        case 'Organization':
        case 'Lab':
        case 'TestMethodCode':
        case 'TestMethodDescription':
        case 'RequestStatus':
            return true;
    }

    return false;
}

@autoinject
export class SelectionFilter {

    operators: FilterOperator[];
    items: any[];

    localFilterField: any;

    placeholder: string;
    defaultPlaceholder: 'Select items';

    isLoading: boolean;
    fieldDataTypeChangeSubscription: Disposable;

    constructor(
        private bindingEngine: BindingEngine,
        private logger: Logger,
        private parameterOptionService: ParameterOptionService
    ) {
        this.logger.name = 'selection-filter';
        this.placeholder = this.defaultPlaceholder;
    }

    async loadItems(filterField) {
        this.operators = [
            { title: 'equals', value: 'in' },
            { title: 'is not equal to', value: 'not-in' }
        ];

        if (filterField.key === 'OrganizationCode')
            this.operators.pop();

        // Don't query for other data types that are not selections.
        if (!isSelectionFilterDataType(filterField.dataType))
            return;

        this.isLoading = true;
        this.placeholder = 'Loading items...';

        try {
            this.items = await this.parameterOptionService.getParameterOptions(filterField.dataType) as unknown as any[];
            this.placeholder = this.defaultPlaceholder;
        } catch (error) {
            this.logger.error(`Error loading items for data type '${filterField.dataType}'.`, error);
            this.placeholder = 'Error loading items';
        }

        this.isLoading = false;
    }

    activate(filterField) {
        (async () => {
            await this.loadItems(filterField);

            // This local field is required so that the outer scope doesn't bleed into this component.
            this.localFilterField = filterField;

            // When switching field types, the operator may not be available. If not, default to the first one.
            if (!this.operators.some(o => o.value === this.localFilterField.operator))
                this.localFilterField.operator = this.operators[0].value;

            this.fieldDataTypeChangeSubscription = this.bindingEngine
                .propertyObserver(this.localFilterField, 'dataType')
                .subscribe(this.handleFieldDataTypeChanged.bind(this));

            ValidationRules
                .ensure('values').satisfies(values => !!(values && values.length))
                .on(this.localFilterField);
        })();
    }

    handleFieldDataTypeChanged() {
        this.loadItems(this.localFilterField);
    }

    detached() {
        this.fieldDataTypeChangeSubscription?.dispose();
    }
}
