import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import SecurityService from '../../security/security-service';
import UserService from '../../users/user-service';
import MapAttributeService from '../map-attributes/map-attribute-service';
import MapService from './map-service';

@autoinject
export class MapDetail {
    @observable mapPhoto;

    supportedPhotoFormatMessage = 'The following are supported photo formats: .jpg, .jpeg, .png';

    // state
    validationController: ValidationController;
    formChanged: boolean;

    // permissions
    canEditMaps: boolean;
    canViewAudit: boolean;

    // data
    currentUserOrganizations: any;
    map: any;
    fileReader: any;
    mapAttributes: any[];
    propertyDisplayNames: any;
    recordChanges: any;

    mapAttributeNames: any;
    mapAttributeValues: any;

    mapPhotoInput: any;
    mapPhotoDataUrl: any;

    constructor(
        private router: Router,
        private validationControllerFactory: ValidationControllerFactory,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private userService: UserService,
        private mapService: MapService,
        private mapAttributeService: MapAttributeService
    ) {
        this.router = router;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.logger = logger;
        this.logger.name = 'map-detail';

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.securityService = securityService;
        this.userService = userService;
        this.mapService = mapService;
        this.mapAttributeService = mapAttributeService;

        this.canEditMaps = this.securityService.hasPermission('EditMaps') && !this.securityService.isImpersonating();
        this.canViewAudit = this.securityService.isCurrentUserInternal() && this.securityService.isImpersonating();

        this.fileReader = new FileReader();
        this.fileReader.addEventListener('load', this.handlePhotoLoad.bind(this));

        this.mapAttributes = [];

        this.propertyDisplayNames = {
            'Name': 'Name',
            'Description': 'Description',
            'PhotoUrl': 'Photo',
            'IsActive': 'Active',
            'OrganizationName': 'Organization Name'
        };
    }

    activate(params) {
        (async () => {
            this.pageContext.isLoading = true;

            try {
                var results = await Promise.all([
                    this.userService.getCurrentUserOrganizations(),
                    this.mapAttributeService.getMapAttributes(),
                    params.id === 'create' ?
                        Promise.resolve({ isActive: true, attributes: [] }) :
                        await this.mapService.getMap(parseInt(params.id))
                ]);

                // Current user organizations must be set before map.
                this.currentUserOrganizations = results[0];

                var usedMapAttributes = results[1];

                this.mapAttributeNames = usedMapAttributes.reduce((attributes, attribute) => {
                    if (!attributes.some(a => a.name.toLowerCase() === attribute.name.toLowerCase()))
                        attributes.push({ name: attribute.name, value: attribute.name });

                    return attributes;
                }, []);
                this.mapAttributeValues = usedMapAttributes.reduce((attributes, attribute) => {
                    if (!Object.keys(attributes).find(k => k.toLowerCase() === attribute.name.toLowerCase()))
                        attributes[attribute.name] = [{ name: attribute.value, value: attribute.value }];
                    else if (!attributes[attribute.name].some(a => a.value === attribute.value))
                        attributes[attribute.name].push({ name: attribute.value, value: attribute.value });

                    return attributes;
                }, {});
                this.map = results[2];

                this.mapAttributes.splice(0);
                for (let attribute of this.map.attributes) {
                    this.mapAttributes.push(this.createMapAttribute(attribute.name, attribute.value));
                }

                ValidationRules
                    .ensure('mapPhoto').displayName('Blueprint Image').satisfies(mapPhoto => !!mapPhoto || this.map.photoUrl)
                    .on(this);

                ValidationRules
                    .ensure('name').displayName('Name').required()
                    .ensure('organizationId').displayName('Organization').required()
                    .on(this.map);

            } catch (error) {
                this.logger.error('Error loading map', error, { mapId: params.id });

                await (error.apiErrorCode === 1 ?
                    this.dialogPresenter.showAlert(
                        'Error Loading Map',
                        'The current map doesn\'t exist.') :
                    this.dialogPresenter.showAlert(
                        'Error Loading Map',
                        'An error occurred while loading the current map. Please try again later.')
                );

                this.router.navigateToRoute('map-list');
            }

            this.pageContext.isLoading = false;
        })();
    }

    createMapAttribute(name = null, value = null) {
        return {
            name,
            value,
            valuePlaceholder: 'Select or enter a value',
            isLoadingValues: false,
            mapAttributeNames: this.mapAttributeNames.slice(0),
            mapAttributeValues: (this.mapAttributeValues[name] || []).slice(0)
        };
    }

    addMapAttribute() {
        this.mapAttributes.push(this.createMapAttribute());
        this.formChanged = true;
    }

    removeMapAttribute(attribute) {
        this.mapAttributes.splice(this.mapAttributes.findIndex(a => a === attribute), 1);
        this.formChanged = true;
    }

    handleMapAttributeNameChange(attribute, newName) {
        if (!newName || newName in attribute.mapAttributeValues)
            return;

        var originalPlaceholder = attribute.valuePlaceholder;
        attribute.isLoadingValues = true;
        attribute.valuePlaceholder = 'Loading items...';
        attribute.mapAttributeValues = (this.mapAttributeValues[newName] || []).slice(0);
        attribute.valuePlaceholder = originalPlaceholder;
        attribute.isLoadingValues = false;
    }

    handleAttributeNameTagCreated(tag, attribute) {
        for (var i = attribute.mapAttributeNames.length - 1; i >= 0; i--) {
            if (attribute.mapAttributeNames[i].tag)
                attribute.mapAttributeNames.splice(i, 1);
        }

        attribute.mapAttributeNames.push(tag);
        attribute.name = tag.name;
    }

    handleAttributeValueTagCreated(tag, attribute) {
        for (var i = attribute.mapAttributeValues.length - 1; i >= 0; i--) {
            if (attribute.mapAttributeValues[i].tag)
                attribute.mapAttributeValues.splice(i, 1);
        }

        attribute.mapAttributeValues.push(tag);
        attribute.value = tag.value;
    }

    mapPhotoChanged() {
        if (!this.mapPhotoInput.files || !this.mapPhotoInput.files.length)
            return;

        var file = this.mapPhotoInput.files[0];
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            this.dialogPresenter.showAlert(
                'Unsupported Photo Format',
                this.supportedPhotoFormatMessage);

            this.mapPhotoInput.value = '';
        }

        this.fileReader.readAsDataURL(file);
    }

    handlePhotoLoad() {
        this.mapPhotoDataUrl = this.fileReader.result;
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('map-list');
    }

    async save() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        this.map.attributes.splice(0);
        for (let mapAttribute of this.mapAttributes) {
            this.map.attributes.push({
                name: mapAttribute.name,
                value: mapAttribute.value
            });
        }

        try {
            let savedMap =  (await this.mapService.saveMap(this.map, this.mapPhotoInput.files[0])) as any;
            this.map.id = savedMap.id;

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Map saved successfully.');
            this.router.navigateToRoute('map-detail', { id: this.map.id }, { replace: true });
        } catch (error) {
            this.logger.error('Error saving map.', error, { map: this.map });
            this.dialogPresenter.showAlert(
                'Error Saving Map',
                this.getApiErrorMessage(error.apiErrorCode));
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        if (errorCode === 1200)
            return 'The format of the map photo is not a supported format. ' + this.supportedPhotoFormatMessage;

        return 'An error occurred while saving the current map. Please try again later.';
    }

    async loadRecordChanges() {
        try {
            this.pageContext.isLoading = true;
            this.recordChanges = await this.mapService.getMapRecordChanges(this.map.id);
        } catch (error) {
            this.dialogPresenter.showAlert(
                'Error Loading Map Audit',
                'An error occurred while loading the current map audit. Please try again later.');
        }

        this.pageContext.isLoading = false;
    }
}
