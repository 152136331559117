import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import UserService from '../../users/user-service';
import CustomerTestImportService from './customer-test-import-service';

@inject(Router, ValidationControllerFactory, Logger, PageContext, DialogPresenter, UserService, CustomerTestImportService)
export class CustomerTestImportDetail {
    @observable testDataFile;
    organizationId = null;

    constructor(router, validationControllerFactory, logger, pageContext, dialogPresenter, userService, customerTestImportService) {
        this.router = router;

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.logger = logger;
        this.logger.name = 'customer-test-import-detail';

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.userService = userService;
        this.customerTestImportService = customerTestImportService;
        this.acceptFormats = [
            '.csv',
            '.txt',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            'text/csv',
            'text/plain'
        ];

        // User authorization is performed at the router since this page is used only for creating and not viewing imports.
    }

    activate() {
        ValidationRules
            .ensure('organizationId').displayName('Organization').required()
            .ensure('testDataFile').displayName('Test Data File').satisfies(testDataFile => !!testDataFile)
            .on(this);

        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.currentUserOrganizations = await this.userService.getCurrentUserOrganizations();
            } catch (error) {
                this.dialogPresenter.showAlert(
                    'Error Loading Organizations',
                    'There was an error loading the organization list. Please try again later.');
            }

            this.pageContext.isLoading = false;
        })();
    }

    handleFormChange() {
        this.formChanged = true;
    }

    testDataFileChanged() {
        if (!this.testDataFileInput.files || !this.testDataFileInput.files.length)
            return;

        var file = this.testDataFileInput.files[0];
        if (!this.acceptFormats.some(format => file.type === format)) {
            this.dialogPresenter.showAlert(
                'Unsupported File Format',
                `The following are supported file formats: .csv, .txt`);

            this.testDataFileInput.value = '';
        }
    }   

    async saveCustomerTestImport() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        try {
            await this.customerTestImportService.saveCustomerTestImport({ organizationId: this.organizationId }, this.testDataFileInput.files[0]);

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Test data file uploaded successfully.');
            this.router.navigateToRoute('customer-test-import-list');
        } catch (error) {
            this.logger.error('Error uploading test data file.', error);
            this.dialogPresenter.showAlert(
                'Error Saving Test Data File',
                'There was an error uploading the test data file. Please try again later.');
        }

        this.pageContext.isLoading = false;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('customer-test-import-list');
    }
};
