import { bindable, bindingMode, inject, computedFrom, autoinject } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import DateRangeFilterOption from './date-range-filter-option';

var msInOneDay = 24 * 60 * 60 * 1000;

export function createDateRangeFilterOption(value, caption, numericValue = 0, isFuture = false): DateRangeFilterOption {
    var startDate = null;
    var endDate = null;

    if (value !== 'custom') {
        let now = new Date();
        if (isFuture) {
            startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            endDate = new Date(startDate.getTime() + (numericValue * msInOneDay));
        } else {
            endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            startDate = new Date(endDate.getTime() - (numericValue * msInOneDay));
        }
    }
    
    return {
        value,
        caption,
        numericValue,
        startDate,
        endDate
    } as DateRangeFilterOption;
}

export function createDateRangeFilterOptions(): DateRangeFilterOption[] {
    return [
        createDateRangeFilterOption('last7Days', 'Last 7 Days', 7),
        createDateRangeFilterOption('last30Days', 'Last 30 Days', 30),
        createDateRangeFilterOption('last60Days', 'Last 60 Days', 60),
        createDateRangeFilterOption('last90Days', 'Last 90 Days', 90),
        createDateRangeFilterOption('custom', 'Specific Range')
    ] as DateRangeFilterOption[];
}

@autoinject
export class DateRangeFilter {
    @bindable defaultOption: DateRangeFilterOption;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) options: DateRangeFilterOption[];
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedOption: DateRangeFilterOption;
    @bindable pickTime = false;

    // subscriptions
    clearFilterSubscription: Subscription;

    constructor(private eventAggregator: EventAggregator) {
        this.clearDateRange = this.clearDateRange.bind(this);
    }

    attached() {
        this.clearFilterSubscription = this.eventAggregator.subscribe('filters.clear', this.clearDateRange);
    }

    detached() {
        this.clearFilterSubscription && this.clearFilterSubscription.dispose();
    }

    clearDateRange() {
        this.selectedOption = this.options.find(o => o.value === (this.defaultOption.value || this.defaultOption || 'custom'));
    }
    
    @computedFrom('selectedOption.startDate', 'selectedOption.endDate')
    get minSelectableDate(): number | Date {
        return this.selectedOption && this.selectedOption.startDate ? this.selectedOption.startDate : -Infinity
    }

    @computedFrom('selectedOption.startDate', 'selectedOption.endDate')
    get maxSelectableDate(): number | Date {
        return this.selectedOption && this.selectedOption.endDate ? this.selectedOption.endDate : Infinity;
    }
}
