import { autoinject, observable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import Logger from 'infrastructure/logger';

@autoinject
export default class RequestTemplateSelector {
    @observable newTemplateName: any;
    newTemplateNameUnique: boolean;
    templateNameLengthTooLong: boolean;
    selectedTemplate: any;

    model: any;

    constructor(
        private controller: DialogController,
        private logger: Logger
    ) {
        this.controller = controller;

        this.logger = logger;
        this.newTemplateName = '';
        this.newTemplateNameUnique = true;
        this.templateNameLengthTooLong = false;
    }

    newTemplateNameChanged() {
        if (!this.model?.organizationRequestTemplates)
            return;

        let templateIndex = this.model.organizationRequestTemplates.findIndex(t => t.title.toLowerCase() === this.newTemplateName.toLowerCase());
        this.newTemplateNameUnique = (templateIndex === -1);
        this.templateNameLengthTooLong = (this.newTemplateName.length > 100);
    }

    handleClose() {
        let requestTemplateSelection = (this.newTemplateName.length > 0 ? this.newTemplateName : this.selectedTemplate);

        this.model.requestTemplateSelection = requestTemplateSelection;
        this.model.existingTemplate = (this.newTemplateName.length === 0);

        this.controller.ok(this.model);
    }

    activate(model) {
        this.model = model;
    }
}
