import { autoinject } from 'aurelia-framework';
import { HttpClient, HttpResponseMessage } from 'aurelia-http-client';
import ISpecificationDto from './contracts/ISpecificationDto';
import Specification from './specification';

@autoinject
export default class SpecificationService {
    constructor(private httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getSpecifications(organizationId: number) {
        return this.httpClient.get(`specifications?organizationId=${organizationId}`);
    }

    getSpecification(id: number): Promise<ISpecificationDto> {
        return this.httpClient.get(`specifications/${id}`) as unknown as Promise<ISpecificationDto>;
    }

    saveSpecification(specification: Specification): Promise<HttpResponseMessage> {
        switch (specification.type) {
            case 'Quantitative':
                return !specification.id
                    ? this.httpClient.post('specifications/quantitative', specification.toEntity())
                    : this.httpClient.put('specifications/quantitative', specification.toEntity());
            case 'Qualitative':
                return !specification.id
                    ? this.httpClient.post('specifications/qualitative', specification.toEntity())
                    : this.httpClient.put('specifications/qualitative', specification.toEntity());
            default:
                throw new Error('An error occured. Specification type is invalid');
        }
    }

    deleteSpecifications(ids: number[]) {
        if (ids.length === 0) return Promise.resolve();

        var query = ids.map((id) => `id=${id}`).join('&');
        return this.httpClient.delete(`specifications?${query}`);
    }
}
