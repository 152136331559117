import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community';
import InputMask from 'inputmask';

export class AgDateMaskEditor implements ICellEditorComp {
    private value: any;
    private input!: HTMLInputElement;
    private _inputMask: InputMask;
    private _maskOptions: any;
    private _charWasPressed: boolean;

    constructor() {
        this._maskOptions = {
            alias: 'datetime',
            inputFormat: 'mm/dd/yyyy hh:MM TT',
            placeholder: '*',
            min: '01/01/1960 12:00 AM',
            max: '12/31/2050 11:59 PM',
        };
    }

    init(params: ICellEditorParams): void {
        this._charWasPressed = params.charPress?.length > 0;
        this.value = params.charPress || params.value;

        this.input = document.createElement('input');
        this.input.type = 'text';
        this.input.value = this.value;
        this.input.setAttribute('data-cy', 'ag-popup-editor-input');
        this._inputMask = new InputMask(this._maskOptions).mask(this.input);
    }

    getGui() {
        return this.input;
    }

    afterGuiAttached() {
        this.input.focus();
        if (this._charWasPressed) {
            this.input.setSelectionRange(20, 20);
        }
    }

    isCancelAfterEnd(): boolean {
        const isValid = this._inputMask.isValid(this.input.value, this._maskOptions);
        if (!isValid) {
            this.input.value = null;
        }

        return !isValid;
    }

    getValue() {
        return this.input.value;
    }
}
