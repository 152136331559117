import { autoinject, bindable, children } from 'aurelia-framework';

@autoinject
export class RequiredCustomAttribute {
    @bindable title: string;
    @bindable disabled: boolean | string;

    requiredIndicator: Element;
    requiredIndicatorAdded: boolean;

    constructor(private element: Element) {
        let i = document.createElement('i');
        i.classList.add('fa', 'fa-asterisk');
        i.title = this.title ?? 'This field is required.';

        this.requiredIndicator = i;
        this.element.appendChild(document.createTextNode(' '));
        this.updateIndicatorInDOM();
    }

    disabledChanged(newValue, oldValue) {
        this.updateIndicatorInDOM();
    }

    updateIndicatorInDOM() {
        if (this.disabled === true || this.disabled === 'true') {
            if (this.requiredIndicatorAdded)
                this.element.removeChild(this.requiredIndicator);
        } else {
            this.element.appendChild(this.requiredIndicator);
            this.requiredIndicatorAdded = true;
        }
    }

    attached() {
        if (this.disabled === true || this.disabled === 'true')
            return;

        this.updateIndicatorInDOM();
    }
}
