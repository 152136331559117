import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import PageContext from '../../infrastructure/page-context';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import VideoService from './video-service';
import SecurityService from '../../security/security-service';

@inject(Router, PageContext, ValidationControllerFactory, DialogPresenter, Logger, SecurityService, VideoService)
export default class VideoDetail {

    constructor(router, pageContext, validationControllerFactory, dialogPresenter, logger, securityService, videoService) {
        this.router = router;
        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.logger = logger;
        this.logger.name = 'video-detail';

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.securityService = securityService;
        this.videoService = videoService;
        this.editMode = this.securityService.isCurrentUserInternal() && !this.securityService.isImpersonating();
        this.video = {};

    }

    updateVideoUrl() {
        this.video.videoUrl = '';
    }

    activate(params) {
        (async () => {

            this.pageContext.isLoading = true;
            try {

                this.video = params.id === 'create' ? {} : await this.videoService.getVideo(params.id);

                if (this.video && this.video.externalVideoId)
                    this.video.videoUrl = `https://player.vimeo.com/video/${this.video.externalVideoId}`;

                if (this.editMode) {

                    ValidationRules
                        .ensure('title').displayName('Title').required().maxLength(100)
                        .ensure('shortDescription').displayName('Short Description').required().maxLength(255)
                        .ensure('externalVideoId').displayName('Video ID').required().maxLength(255)
                        .on(this.video);

                    this.validationController.addObject(this.video);
                }

                this.pageContext.isLoading = false;

            } catch (error) {
                this.logger.error('Error loading video.', error);
                this.pageContext.isLoading = false;

                if (error.apiErrorCode === 1) { // Not Found
                    await this.dialogPresenter.showAlert(
                        'Video Not Found',
                        'The video you are trying to view does not exist.');

                    this.router.navigateToRoute('video-list');
                    return;
                }

                this.dialogPresenter.showAlert(
                    'Error Loading Video',
                    'An error occurred while loading the video. Please try again later.');
            }
        })();
    }



    async getExternalVideoMetadata() {

        this.video.videoUrl = '';

        if (!this.editMode || this.video.externalVideoId.length < 9)
            return;

        this.pageContext.isLoading = true;
        try {

            this.videoMetadata = await this.videoService.getVideoExternalMetadata(this.video.externalVideoId);
            if (this.videoMetadata && this.videoMetadata.externalVideoId) {

                this.video.videoUrl = `https://player.vimeo.com/video/${this.video.externalVideoId}`;
                this.video.title = this.video.title || this.videoMetadata.title;
                this.video.shortDescription = this.video.shortDescription || this.videoMetadata.description;
                this.video.thumbnailUrl = this.videoMetadata.thumbnailUrl;
                this.video.isActive = true;

            } else {
                this.dialogPresenter.showAlert(
                    'Vimeo Video Not Found',
                    'The Vimeo video could not be found. Please try another video id.');
            }
            this.pageContext.isLoading = false;

        } catch (error) {
            this.logger.error('Error loading external video metadata.', error);
            this.pageContext.isLoading = false;
            this.dialogPresenter.showAlert(this.getApiErrorTitle(error.apiErrorCode), this.getApiErrorMessage(error.apiErrorCode, true));
        }
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('video-list');
    }

    async save() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        try {
            this.video.id = (await this.videoService.saveVideo(this.video)).id;

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Help video saved successfully.');
            this.router.navigateToRoute('video-detail', { id: this.video.id }, { replace: true });
        } catch (error) {
            this.logger.error('Error saving help video.', error, { video: this.video });
            this.dialogPresenter.showAlert(
                'Error Saving Help Video',
                this.getApiErrorMessage(error.apiErrorCode, false));
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode, isExternalLoad) {
        switch (errorCode) {
            case 600:
                return 'The Vimeo video has not been correctly configured for privacy. Please correct Vimeo privacy settings and try again.';
            case 601:
                return 'The Vimeo access token was not authorized. Please contact support.';
            default:
                return isExternalLoad ?
                    'An error occurred while loading the external video. Please try again later.' :
                    'An error occurred while saving the current help video. Please try again later.';
        }
    }

    getApiErrorTitle(errorCode) {
        switch (errorCode) {
            case 600:
                return 'Vimeo Video Not Private';
            case 601:
                return 'Unauthorized Access Token';
            default:
                return 'Error Loading External Video';
        }

    }
};
