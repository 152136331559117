import { BaseQueryFilter } from 'infrastructure/components/query-filters/base-query-filter';
import { MapAttributesQueryFilters } from 'infrastructure/components/query-filters/map-attributes-query-filter';
import { OrganizationsQuery, OrganizationsQueryFilters } from 'infrastructure/components/query-filters/organizations-query-filter';
import { QueryFiltered } from 'infrastructure/components/query-filters/query-filtered';
import { RecordStatusQuery, RecordStatusQueryFilters } from 'infrastructure/components/query-filters/record-status-query-filter';
import Attribute from 'location-testing/attribute';
import RecordStatus from 'record-status';

export interface MapListQuery {
    organizationIds: number[];
    recordStatus: RecordStatus;
    mapAttributes: Attribute[];
}

export class MapListQueryFilters extends BaseQueryFilter
    implements QueryFiltered<MapListQuery> {

    organizationQueryFilters: OrganizationsQueryFilters;
    recordStatusQueryFilters: RecordStatusQueryFilters;
    mapAttributeQueryFilters: MapAttributesQueryFilters;

    constructor() {
        super();

        this.organizationQueryFilters = new OrganizationsQueryFilters();
        this.recordStatusQueryFilters = new RecordStatusQueryFilters();
        this.mapAttributeQueryFilters = new MapAttributesQueryFilters();
    }

    public get count(): number {
        return 0 +
            this.organizationQueryFilters.count +
            this.recordStatusQueryFilters.count +
            this.mapAttributeQueryFilters.count;
    }

    public get filterCaption(): string {
        return super.getFilterCaption(this.count);
    }

    reset(): void {
        this.organizationQueryFilters.reset();
        this.recordStatusQueryFilters.reset();
        this.mapAttributeQueryFilters.reset();
    }

    getQueryParams(): MapListQuery {
        return {
            ...this.organizationQueryFilters.getQueryParams(),
            ...this.recordStatusQueryFilters.getQueryParams(),
            ...this.mapAttributeQueryFilters.getQueryParams(),
        };
    }

    setFilterValues(params: MapListQuery): void {
        const organizationsQuery: OrganizationsQuery = { organizationIds: params.organizationIds };
        const recordStatusQuery: RecordStatusQuery = { recordStatus: params.recordStatus };

        this.organizationQueryFilters.setFilterValues(organizationsQuery);
        this.recordStatusQueryFilters.setFilterValues(recordStatusQuery);
        this.mapAttributeQueryFilters.setFilterValues(params);

        super.setQueryFilteredValues({}, this);
    }
}

