import { autoinject } from 'aurelia-framework';
import { HttpClient, HttpResponseMessage } from 'aurelia-http-client';
import { AdhocReportSubscription } from './contracts/adhoc-report-subscription';
import { SubscriptionFrequencyType } from './contracts/subscription-frequency-type';


@autoinject
export class AdhocReportSubscriptionService {
    private baseRoute: string;

    constructor(private httpClient: HttpClient) {
        this.baseRoute = 'adhoc-report-subscriptions';
    }

    getAdhocReportSubscriptions(): Promise<Readonly<AdhocReportSubscription[]>> {
        return this.httpClient.get(this.baseRoute) as unknown as Promise<
            Readonly<AdhocReportSubscription[]>
        >;
    }

    getAdhocReportSubscription(id: number): Promise<AdhocReportSubscription> {
        return this.httpClient.get(`${this.baseRoute}/${id}`) as unknown as Promise<
            Readonly<AdhocReportSubscription>
        >;
    }

    createAdhocReportSubscription(
        entity: AdhocReportSubscription,
    ): Promise<AdhocReportSubscription> {
        return this.httpClient.post(
            this.baseRoute,
            entity,
        ) as unknown as Promise<AdhocReportSubscription>;
    }

    changeAdhocReportSubscriptionDelivery(
        id: number,
        request: AdhocReportSubscription,
    ): Promise<AdhocReportSubscription> {
        return this.httpClient.put(
            `${this.baseRoute}/${id}`,
            request,
        ) as unknown as Promise<AdhocReportSubscription>;
    }

    startAdhocReportSubscription(id: number): Promise<HttpResponseMessage> {
        return this.httpClient.put(
            `${this.baseRoute}/${id}/start`,
            null,
        ) as unknown as Promise<HttpResponseMessage>;
    }

    stopAdhocReportSubscription(id: number): Promise<HttpResponseMessage> {
        return this.httpClient.put(
            `${this.baseRoute}/${id}/stop`,
            null,
        ) as unknown as Promise<HttpResponseMessage>;
    }

    deleteAdhocReportSubscription(id: number): Promise<HttpResponseMessage> {
        return this.httpClient.delete(
            `${this.baseRoute}/${id}`,
        ) as unknown as Promise<HttpResponseMessage>;
    }
}
