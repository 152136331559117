import { autoinject, bindable } from 'aurelia-framework';
import { validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules } from 'aurelia-validation';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import SelectorOption from '../infrastructure/selector-option';
import OrganizationDistributionGroup from '../organizations/organization-distribution-group';
import PortalRequest from './request';

@autoinject
export class RequestDetailRequestInfo {
    @bindable organizations;
    @bindable labs;
    @bindable request: PortalRequest;
    @bindable coaReportStyleOptions: SelectorOption[];
    @bindable courierOptions: SelectorOption[];
    @bindable distributionGroups: OrganizationDistributionGroup[];
    @bindable distributionGroupOptions: SelectorOption[];
    @bindable enablePartialRequests: boolean;
    @bindable organizationRequestTemplates: SelectorOption[];
    @bindable selectedOrganizationRequestTemplate;
    @bindable createMode: boolean;
    @bindable draftTemplateName: string;
    @bindable isLabWare: boolean;

    validationController: ValidationController;

    constructor(
        validationControllerFactory: ValidationControllerFactory,
        private dialogPresenter: DialogPresenter
    ) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;
    }

    requestChanged(newRequest, oldRequest) {
        if (oldRequest)
            ValidationRules.off(oldRequest);

        if (newRequest)
            ValidationRules
                .ensure((o: PortalRequest) => o.organizationId).required()
                .ensure((o: PortalRequest) => o.labId).required()
                .ensure((o: PortalRequest) => o.distributionGroup)
                    .required().when(o => !this.isLabWare)  
                .ensure((o: PortalRequest) => o.courier).required()
                .ensure((o: PortalRequest) => o.coaReportStyle).required()
                .ensure((o: PortalRequest) => o.purpose)
                    .maxLength(4000).withMessage('Special Instructions cannot be longer than 4000 characters.')
                .ensure((o: PortalRequest) => o.notes)
                    .maxLength(2000).withMessage('CoA Notes cannot be longer than 2000 characters.')
                .ensure((o: PortalRequest) => o.sampledBy)
                    .maxLength(40).withMessage('Sampled By cannot be longer than 40 characters.')
                .ensure((o: PortalRequest) => o.projectName)
                    .maxLength(40).withMessage('Project Name cannot be longer than 40 characters.')
                .ensure((o: PortalRequest) => o.poNumber)
                    .maxLength(40).withMessage('PO Number cannot be longer than 40 characters.')
                    .required().when(o => o.poRequired).withMessage('PO Number for this organization is required.')
                .on(newRequest);
    }

    async validate(reset = false): Promise<boolean> {
        var result = await this.validationController.validate();

        if (reset)
            this.validationController.reset();

        return result.valid;
    }

    handleOrganizationSelectorClicked() {
        if (this.request?.samples?.length && !this.request?.id)
            this.dialogPresenter.showAlert('Cannot Change Organizations', 'You cannot change organizations when samples are present. Please clear the list of samples before changing organizations.');
    }
}
