import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import CompareUtility from '../infrastructure/compare-utility';
import Logger from '../infrastructure/logger';
import PageContext from '../infrastructure/page-context';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import { createDateRangeFilterOptions } from '../infrastructure/components/filters/date-range-filter';
import LoginAuditService from './login-audit-service';

@inject(Router, EventAggregator, Logger, PageContext, DialogPresenter, LoginAuditService)
export default class LoginAudit {
    @observable filterText;

    constructor(router, eventAggregator, logger, pageContext, dialogPresenter, loginAuditService) {
        this.router = router;
        this.eventAggregator = eventAggregator;

        this.logger = logger;
        this.logger.name = 'login-audit';

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.loginAuditService = loginAuditService;
        this.filterText = '';
        this.gridOptions = {
            columnDefs: [
                { suppressMenu: true, headerName: 'Username', field: 'email', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                { suppressMenu: true, headerName: 'Last Name', field: 'lastName', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                { suppressMenu: true, headerName: 'First Name', field: 'firstName', comparator: CompareUtility.compareStringsInsensitive, sort: 'asc', width: 150 },
                { suppressMenu: true, headerName: 'Login Attempts', field: 'numLoginAttempts', width: 120 },
                { suppressMenu: true, headerName: 'Failed Logins', field: 'numFailedLogins', width: 120 },
                { suppressMenu: true, headerName: 'Successful Logins', field: 'numSuccessfulLogins', width: 120 },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button click.delegate="navigateToDetail(data.userId)">
                            <!--<i class="fa fa-info-circle"></i>-->
                            <i class="fa fa-arrow-circle-right"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    width: 100,
                    sortable: false,
                    suppressSizeToFit: true
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.dateRangeOptions = createDateRangeFilterOptions();
        this.defaultDateRangeOption = 'last30Days';
        this.selectedDateRangeOption = this.dateRangeOptions.find(o => o.value === this.defaultDateRangeOption);
        this.handleApplyFilters = this.handleApplyFilters.bind(this);
    }

    navigateToDetail(id) {
        this.router.navigateToRoute('login-audit/user', { id });
    }

    filterTextChanged() {
        this.updateLoginDataView(); 
    }

    updateLoginDataView() {
        var lowerCasedFilterText = this.filterText.toLowerCase();

        if (this.loginData) {
            this.loginDataView = this.loginData.filter(ld =>
                (ld.firstName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (ld.lastName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (ld.email || '').toLowerCase().indexOf(lowerCasedFilterText) > -1)
        }

        if (this.selectedDateRangeOption) {
            this.dateRangeCaption = this.selectedDateRangeOption.value === 'custom' ?
                'Custom date range' :
                this.selectedDateRangeOption.caption;
        }
    }

    handleApplyFilters() {
        this.fetchLoginAuditData();
    }
    
    async fetchLoginAuditData() {
        this.pageContext.isLoading = true;

        try {
            var results = await this.loginAuditService.getLoginAuditData(this.selectedDateRangeOption.startDate, this.selectedDateRangeOption.endDate);
            this.loginData = results;
            this.updateLoginDataView();
            this.pageContext.isLoading = false;
            this.isFiltering = !!this.selectedDateRangeOption.startDate || !!this.selectedDateRangeOption.endDate;
        } catch (error) {
            this.logger.error('Error loading login audit.', error);
            this.pageContext.isLoading = false;
            this.dialogPresenter.showAlert(
                'Error Loading Login Audit Data',
                'An error occurred while loading login audit data. Please try again later.');
        }
    }

    activate() {
        this.applyFiltersSubscription = this.eventAggregator.subscribe('filters.apply', this.handleApplyFilters);

        (async () => { this.fetchLoginAuditData(); })();
    }

    deactivate() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
    }
};
