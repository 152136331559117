import { BaseQueryFilter } from 'infrastructure/components/query-filters/base-query-filter';
import { DateRangeQuery, DateRangeQueryFilter } from 'infrastructure/components/query-filters/date-range-query-filter';
import { MapPlanQuery, MapPlanQueryFilter } from 'infrastructure/components/query-filters/map-plan-query-filter';
import { OrganizationsQuery, OrganizationsQueryFilters } from 'infrastructure/components/query-filters/organizations-query-filter';
import { QueryFiltered } from 'infrastructure/components/query-filters/query-filtered';
import moment from 'moment';

export interface DashboardQuery {
    dateRangeOption?: string;
    targetStartDate: string;
    targetEndDate: string;
    dateRangeOverride?: boolean;
    organizationIds: number[];
    mapId?: number;
    planId?: number;
    mapIds: number[];
    planIds: number[];
    onlyAssignedToMe: boolean;
}

export class DashboardQueryFilters extends BaseQueryFilter
    implements QueryFiltered<DashboardQuery> {

    private _filterCaption: string;
    private _taskTargetFilterRangeCaption: string;
    private _taskTargetDateRangeFormat = 'MMMM D';

    dateRangeQueryFilters: DateRangeQueryFilter;
    mapPlanQueryFilters: MapPlanQueryFilter;
    organizationQueryFilters: OrganizationsQueryFilters;
    onlyAssignedToMe: boolean;

    constructor() {
        super();

        this.dateRangeQueryFilters = new DateRangeQueryFilter();
        this.mapPlanQueryFilters = new MapPlanQueryFilter();
        this.organizationQueryFilters = new OrganizationsQueryFilters();
        this.onlyAssignedToMe = false;
    }

    public get filterCaption(): string {
        return this._filterCaption;
    }

    public get taskTargetFilterRangeCaption(): string {
        return this._taskTargetFilterRangeCaption;
    }

    public get count(): number {
        return 0 +
            this.mapPlanQueryFilters.count +
            this.organizationQueryFilters.count +
            (Number(!!this.onlyAssignedToMe)
            );
    }

    reset(): void {
        this.dateRangeQueryFilters.reset();
        this.mapPlanQueryFilters.reset();
        this.organizationQueryFilters.reset();
        this.onlyAssignedToMe = false;
    }

    getQueryParams(): DashboardQuery {
        return {
            ...this.dateRangeQueryFilters.getQueryParams(),
            ...this.mapPlanQueryFilters.getQueryParams(),
            ...this.organizationQueryFilters.getQueryParams(),
            onlyAssignedToMe: this.onlyAssignedToMe,
        };
    }

    private formatTaskTargetFilterCaption(): void {
        const startDate = moment(this.dateRangeQueryFilters.dateRangeFilterOption.startDate).isValid()
            ? moment(this.dateRangeQueryFilters.dateRangeFilterOption.startDate).format(this._taskTargetDateRangeFormat)
            : '*'
        const endDate = moment(this.dateRangeQueryFilters.dateRangeFilterOption.endDate).isValid()
            ? moment(this.dateRangeQueryFilters.dateRangeFilterOption.endDate).format(this._taskTargetDateRangeFormat)
            : '*';
        this._taskTargetFilterRangeCaption = `${startDate} through ${endDate}`;
    }

    setFilterValues(params: DashboardQuery): void {
        const dateRangeQuery: DateRangeQuery = {
            dateRangeOverride: params.dateRangeOverride,
            dateRangeOption: params.dateRangeOption,
            targetStartDate: params.targetStartDate,
            targetEndDate: params.targetEndDate,
        };
        const mapPlanQuery: MapPlanQuery = {
            mapId: params.mapId,
            planId: params.planId,
            mapIds: params.mapIds,
            planIds: params.planIds
        };
        const organizationsQuery: OrganizationsQuery = { organizationIds: params.organizationIds };
        const { onlyAssignedToMe } = { ...params };

        this.dateRangeQueryFilters.setFilterValues(dateRangeQuery);
        this.mapPlanQueryFilters.setFilterValues(mapPlanQuery);
        this.organizationQueryFilters.setFilterValues(organizationsQuery);

        super.setQueryFilteredValues({ onlyAssignedToMe }, this);

        this._filterCaption = super.getFilterCaption(
            this.count,
            'Task Target and Request Creation Date Range',
            this.dateRangeQueryFilters.dateRangeCaption);
        this.formatTaskTargetFilterCaption();
    }
}
