import { Subscription } from 'aurelia-event-aggregator';
import { autoinject, BindingEngine, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
} from 'aurelia-validation';
import RemediationRootCause from 'remediation-root-causes/remediation-root-cause';
import RemediationRootCauseService from 'remediation-root-causes/remediation-root-cause-service';
import CompareUtility from '../../infrastructure/compare-utility';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import SecurityService from '../../security/security-service';
import MapService from '../maps/map-service';
import RemediationService from './remediation-service';

@autoinject
export class RemediationDetail {
    @observable testsFilterText;

    // persissions
    canViewRemediations: boolean;
    canEditRemediations: boolean;
    canSetAssignStatus: boolean;
    canSetApprovalStatus: boolean;
    canViewAudit: boolean;

    mapId: number;
    remediation: any;
    testsView: any[];
    recordChanges: any[];

    statuses: any[];
    remediationRootCauseOptions: RemediationRootCause[];
    currentUser: any;
    currentView: string;
    views: any[];
    formChanged: boolean;

    validationController: ValidationController;
    testsGridOptions: any;
    filesGridOptions: any;
    propertyDisplayNames: any;

    preFilesView: any;
    postFilesView: any;
    postFileInput: any;
    postFile: any;
    preFileInput: any;
    preFile: any;

    remediationSubscription: Subscription;

    constructor(
        private router: Router,
        private bindingEngine: BindingEngine,
        validationControllerFactory: ValidationControllerFactory,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private mapService: MapService,
        private remediationService: RemediationService,
        private readonly remediationRootCauseService: RemediationRootCauseService,
    ) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.logger.name = 'remediation-detail';

        this.canViewRemediations =
            this.securityService.hasPermission('ViewAllRemediations') ||
            this.securityService.hasPermission('ViewMyRemediations');
        this.canEditRemediations =
            this.securityService.hasPermission('EditRemediations') &&
            !this.securityService.isImpersonating();
        this.canSetAssignStatus = false;
        this.canSetApprovalStatus = false;
        this.canViewAudit =
            this.securityService.isCurrentUserInternal() && this.securityService.isImpersonating();

        this.currentView = 'associated-tests';
        this.views = [
            { name: 'associated-tests', title: 'Associated Tests' },
            { name: 'pre-remediation-files', title: 'Pre-Remediation Files' },
            { name: 'post-remediation-files', title: 'Post-Remediation Files' },
        ];

        const defaultColumnDefinition = {
            suppressMenu: true,
            comparator: CompareUtility.compareStringsInsensitive,
            sortable: true,
            resizable: true,
        };

        this.testsFilterText = '';

        this.testsGridOptions = {
            defaultColDef: defaultColumnDefinition,
            columnDefs: [
                { headerName: 'Sample Id', field: 'sampleId' },
                { headerName: 'Test Method', field: 'testMethodDisplayName' },
                { headerName: 'Collected', field: 'collectionDate', width: 150 },
                { headerName: 'Room', field: 'room' },
                { headerName: 'Point', field: 'pointName' },
                {
                    headerName: 'Mobile',
                    field: 'isMobile',
                    template: '<i class="fa fa-check" if.bind="data.isMobile"></i>',
                    width: 115,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                },
                { headerName: 'Zone', field: 'zone' },
            ],
        };

        this.filesGridOptions = {
            defaultColDef: defaultColumnDefinition,
            columnDefs: [
                { headerName: 'Filename', field: 'name', width: 450 },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button if.bind="canViewRemediations" click.trigger="downloadRemediationFile(data)">
                            <i class="fa fa-download"></i>
                        </button>
                        <button if.bind="canEditRemediations" click.trigger="deleteRemediationFile(data)">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 150,
                    sortable: false,
                    suppressSizeToFit: true,
                },
            ],
        };

        this.propertyDisplayNames = {
            Name: 'Name',
            Status: 'Status',
            Description: 'Description',
            IsActive: 'Active',
        };
    }

    setCurrentView(view) {
        this.currentView = view;
    }

    testsFilterTextChanged() {
        this.updateTestsView();
    }

    handleFormChange() {
        this.formChanged = true;
    }

    activate(params) {
        // eslint-disable-next-line sonarjs/cognitive-complexity
        (async () => {
            if (!this.canViewRemediations)
                this.router.navigateToRoute('remediation-list', { mapId: params.mapId });

            this.pageContext.isLoading = true;

            try {
                this.mapId = params.mapId;
                this.currentUser = this.securityService.getEffectiveUser();

                var remediation =
                    params.id === 'create'
                        ? {
                              mapId: this.mapId,
                              isActive: true,
                              status: 1,
                              assignedUserId: this.currentUser.id,
                              approvalUserId: this.currentUser.id,
                              tests: [],
                          }
                        : ((await this.remediationService.getRemediation(
                              parseInt(params.id),
                          )) as any);

                if (params.testIds)
                    remediation.tests = await this.mapService.getMapTests(
                        remediation.mapId,
                        params.testIds,
                    );

                this.canSetAssignStatus =
                    params.id === 'create' ||
                    this.currentUser.id === parseInt(remediation.assignedUserId);
                this.canSetApprovalStatus =
                    this.currentUser.id === parseInt(remediation.approvalUserId);

                var statuses = this.remediationService.getRemediationStatuses() as any[];
                for (let status of statuses) {
                    switch (status.id) {
                        case 1:
                        case 2:
                        case 3:
                            status.disabled = !this.canSetAssignStatus;
                            break;
                        case 4:
                        case 5:
                            status.disabled = !this.canSetApprovalStatus;
                            break;
                        default:
                            status.disabled = true;
                            break;
                    }
                }

                this.remediationRootCauseOptions =
                    await this.remediationRootCauseService.getRemediationRootCauseList();
                this.statuses = statuses;
                this.remediation = remediation;
                this.updateTestsView();
                this.updateFileViews();

                this.remediationSubscription = this.bindingEngine
                    .propertyObserver(this.remediation, 'status')
                    .subscribe(this.remedationStatusChanged.bind(this));

                ValidationRules.ensure('name')
                    .required()
                    .ensure('status')
                    .required()
                    .ensure('assignedUserId')
                    .required()
                    .ensure('approvalUserId')
                    .required()
                    .satisfies((v, o: any) => {
                        return v && o.assignedUserId && parseInt(v) !== parseInt(o.assignedUserId);
                    })
                    .on(this.remediation);
            } catch (error) {
                this.logger.error('Error loading remediation', error, { remediationId: params.id });

                await (error.apiErrorCode === 1
                    ? this.dialogPresenter.showAlert(
                          'Error Loading Remediation',
                          'The current remediation was not found.',
                      )
                    : this.dialogPresenter.showAlert(
                          'Error Loading Remediation',
                          'An error occurred while loading the current remediation. Please try again later.',
                      ));

                const mapId = this.remediation ? this.remediation.mapId || this.mapId : this.mapId;
                if (mapId) this.router.navigateToRoute('remediation-list', { mapId });
            }
            this.pageContext.isLoading = false;
        })();
    }

    updateTestsView() {
        if (!this.remediation || !this.remediation.tests) return;

        var lowerCasedFilterText = this.testsFilterText.toLowerCase();
        this.testsView = this.remediation.tests.filter(
            (t) =>
                (t.testMethodDisplayName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (t.sampleId || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (t.collectedDate || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (t.room || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (t.pointName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (t.isMobile || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (t.zone || '').toLowerCase().indexOf(lowerCasedFilterText) > -1,
        );
    }

    updateFileViews() {
        if (!this.remediation || !this.remediation.files) return;
        this.preFilesView = this.remediation.files.filter((f) => !f.isPostRemediation);
        this.postFilesView = this.remediation.files.filter((f) => f.isPostRemediation);
    }

    async selectUser(type) {
        const result = await this.dialogPresenter.showUserSelector('Select a user');
        if (result.wasCancelled) return;

        const user = result.output;
        if (!user) return;

        switch (type) {
            case 'assigned':
                this.remediation.assignedUserId = user.id;
                this.remediation.assignedUserFullName = user.firstName + ' ' + user.lastName;
                break;
            case 'approval':
                this.remediation.approvalUserId = user.id;
                this.remediation.approvalUserFullName = user.firstName + ' ' + user.lastName;
                break;
            default:
                return;
        }
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('remediation-list', {
            mapId: this.remediation.mapId || this.mapId,
        });
    }

    async save() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid) return;

        this.pageContext.isLoading = true;

        try {
            let savedRemediation = (await this.remediationService.saveRemediation(
                this.remediation,
            )) as any;
            this.remediation.id = savedRemediation.id;

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Remediation saved successfully.');

            if (!this.remediation.isActive)
                this.router.navigateToRoute('remediation-list', {
                    mapId: this.remediation.mapId || this.mapId,
                });
            else
                this.router.navigateToRoute(
                    'remediation-detail',
                    { id: this.remediation.id },
                    { replace: true },
                );
        } catch (error) {
            this.logger.error('Error saving remediation.', error, {
                remediation: this.remediation,
            });
            this.dialogPresenter.showAlert(
                'Error Saving Remediation',
                this.getApiErrorMessage(error.apiErrorCode),
            );
        }

        this.pageContext.isLoading = false;
    }

    async saveRemediationFile(isPostRemediation) {
        if (!this.remediation.id) return;

        if (!this.remediation.files) this.remediation.files = [];

        let remediationFile = { remediationId: this.remediation.id, isPostRemediation };

        this.pageContext.isLoading = true;

        try {
            let file;
            if (isPostRemediation) {
                file = this.postFileInput.files[0];
                this.postFileInput.value = null;
                this.postFile = null;
            } else {
                file = this.preFileInput.files[0];
                this.preFileInput.type = 'text';
                this.preFileInput.type = 'file';
                this.preFile = null;
            }

            remediationFile = (await this.remediationService.saveRemediationFile(
                remediationFile,
                file,
            )) as any;
            this.remediation.files.push(remediationFile);
            this.updateFileViews();
            this.pageContext.showSuccessOverlay('Remediation file saved successfully.');
        } catch (error) {
            this.logger.error('Error uploading remediation file.', error, {
                remediation: this.remediation,
            });
            this.dialogPresenter.showAlert(
                'Error Uploading Remediation File',
                this.getApiErrorMessage(error.apiErrorCode),
            );
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode) {
        return 'An error occurred. Please try again later.';
    }

    async downloadRemediationFile(remediationFile) {
        const a = document.createElement('a');
        a.href = remediationFile.filePath;
        a.download = remediationFile.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    async deleteRemediationFile(remediationFile) {
        this.dialogPresenter
            .showConfirmation(
                'Delete Remedation File',
                'Are you sure you want to delete this file?',
            )
            .then((confirmed) => {
                if (!confirmed) return;

                this.pageContext.isLoading = true;
                this.remediationService
                    .deleteRemediationFile(remediationFile.remediationId, remediationFile.id)
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.remediation.files = this.remediation.files.filter(function (file) {
                            return file.id !== remediationFile.id;
                        });

                        this.updateFileViews();
                        this.pageContext.showSuccessOverlay(
                            'Remediation file deleted successfully.',
                        );
                    })
                    .catch((error) => {
                        this.logger.error('Error deleting remediation file.', error, {
                            remediationFileId: remediationFile.id,
                        });
                        this.pageContext.isLoading = false;
                        this.dialogPresenter.showAlert(
                            'Error Deleteing Remediation File',
                            this.getApiErrorMessage(error.apiErrorCode),
                        );
                    });
            });
    }

    async loadRecordChanges() {
        try {
            this.pageContext.isLoading = true;
            this.recordChanges = (await this.remediationService.getRemediationRecordChanges(
                this.remediation.id,
            )) as unknown as any[];
        } catch (error) {
            this.dialogPresenter.showAlert(
                'Error Loading Remediation Audit',
                'An error occurred while loading the current remediation audit. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    remedationStatusChanged(newValue: number, oldValue: number) {
        if (oldValue !== 2 && newValue === 2) {
            this.remediation.remediationRootCauseId = null;
        }
    }

    detached() {
        this.remediationSubscription?.dispose();
    }
}
