import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import SelectorOption from 'infrastructure/selector-option';
import Logger from '../../infrastructure/logger';

@autoinject
export default class MapService {
    constructor(private httpClient: HttpClient, private logger: Logger) {
        this.httpClient = httpClient;
        this.logger = logger;
    }

    getMaps(queryParams: Record<string, any>) {
        const query = buildQueryString(queryParams);

        return this.httpClient.get(`location-testing/maps${query ? `?${query}` : ''}`);
    }

    getMap(id) {
        return this.httpClient.get(`location-testing/maps/${id}`);
    }

    deleteMaps(ids) {
        var query = ids.map((id) => `id=${id}`).join('&');
        return this.httpClient.delete(`location-testing/maps?${query}`);
    }

    saveMap(map, mapPhotoFile) {
        var formData = new FormData();

        if (map.id) formData.append('id', map.id);

        if (mapPhotoFile) formData.append('photo', mapPhotoFile);

        formData.append('name', map.name);
        formData.append('description', map.description || '');
        formData.append('isActive', map.isActive);
        formData.append('organizationId', map.organizationId);

        for (var i = 0; i < map.attributes.length; i++) {
            formData.append(`attributes[${i}].name`, map.attributes[i].name);
            formData.append(`attributes[${i}].value`, map.attributes[i].value);
        }

        if (!map.id)
            return this.httpClient
                .createRequest('location-testing/maps/')
                .asPost()
                .withContent(formData)
                .send();

        return this.httpClient
            .createRequest(`location-testing/maps/${map.id}`)
            .asPut()
            .withContent(formData)
            .send();
    }

    updateMapPoints(mapId, points) {
        return this.httpClient.patch(`location-testing/maps/${mapId}`, points);
    }

    getMapRecordChanges(id) {
        return this.httpClient.get(`location-testing/maps/${id}/record-changes`);
    }

    getLatestResults(id, filters) {
        var query = buildQueryString(filters, true);
        return this.httpClient.get(
            `location-testing/maps/${id}/latest-results${query ? `?${query}` : ''}`,
        );
    }

    getHistoricalResults(id, filters) {
        var query = buildQueryString(filters, true);
        return this.httpClient.get(
            `location-testing/maps/${id}/historical-results${query ? `?${query}` : ''}`,
        );
    }

    getDailyResults(id, filters) {
        var query = buildQueryString(filters, true);
        return this.httpClient.get(
            `location-testing/maps/${id}/daily-results${query ? `?${query}` : ''}`,
        );
    }

    getPlanFilterOptions(id: number): Promise<SelectorOption[]> {
        return this.httpClient.get(
            `location-testing/maps/${id}/plan-filter-options`,
        ) as unknown as Promise<SelectorOption[]>;
    }

    getTestMethodFilterOptions(id: number): Promise<SelectorOption[]> {
        return this.httpClient.get(
            `location-testing/maps/${id}/test-method-filter-options`,
        ) as unknown as Promise<SelectorOption[]>;
    }

    getTaskAssigneeFilterOptions(id: number): Promise<SelectorOption[]> {
        return this.httpClient.get(
            `location-testing/maps/${id}/task-assignee-filter-options`,
        ) as unknown as Promise<SelectorOption[]>;
    }

    getDashboardSettings() {
        try {
            return JSON.parse(localStorage.getItem('mapDashboardSettings'));
        } catch (error) {
            this.logger.warn('Failed to parse local storage value for "mapDashboardSettings".');
            return null;
        }
    }

    saveDashboardSettings({
        panZoom,
        showLatestView: showLatestView,
        viewPointSize: viewPointSize,
    }: any) {
        var settings = this.getDashboardSettings() || {};

        settings.showLatestView =
            typeof showLatestView === 'boolean' ? showLatestView : settings.showLatestView;
        settings.viewPointSize = viewPointSize || settings.viewPointSize;

        if (panZoom) settings.panZoom = Object.assign(settings.panZoom || {}, panZoom);

        localStorage.setItem('mapDashboardSettings', JSON.stringify(settings));
    }

    getMapTests(id, testIds) {
        var query = testIds.map((testId) => `testId=${testId}`).join('&');
        return this.httpClient.get(`location-testing/maps/${id}/tests?${query}`);
    }
}
