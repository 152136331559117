var errorMessages = new Map([
    [300, 'Your password has expired.'], // PasswordExpired
    [301, 'Your account is currently locked. Please contact your system administrator.'], // UserLockedOut
    [302, 'You have exceeded the maximum number of allowed login attempts. Your account has been locked. Please contact your system administrator.'], // ExceededAllowedFailedLoginAttempts
    [303, 'Due to your recent inactivity, your account will need to be verified before you can log in again. An email has been sent to you containing instructions as to how to proceed.'], // ExceededMaxDaysAllowedBetweenLogins
    [304, 'Due to your recent inactivity, you need to verify your account by using the login URL sent to you via email. Another email has been sent to you with a new login URL, please disregard any previous login URLs sent via email.'], // IdentityVerificationTokenMissing
    [305, 'The account verification token specified in the URL does not match the one that was generated for you. Another email has been sent to you with a new login URL, please disregard any previous login URLs sent via email.'], // IdentityVerificationTokenDoesNotMatch
    [306, 'The account verification token generated for you has expired. Another email has been sent to you with a new login URL, please disregard any previous login URLs sent via email.'], // IdentityVerificationTokenExpired
    [308, 'You must specify both username and password.'], // NoUsernameAndOrPasswordProvided
    [310, 'The specified account is not active. Please contact your system administrator.'], // UserNotActive
    [311, 'The specified account does not have access to any part of the application. Please contact your system administrator.'], // NoOrganizationApplicationModules
    [312, 'The specified account does not have access to any organizations. Please contact your system administrator.'], // NoOverseerOrganizations
]);

export default class LoginErrorMessageProvider {
    static getErrorMessage(httpResponseMessageOrApiError) {
        var apiErrorCode = httpResponseMessageOrApiError.apiErrorCode;
        if (apiErrorCode && errorMessages.has(apiErrorCode))
            return errorMessages.get(apiErrorCode);
        
        if (httpResponseMessageOrApiError.statusCode === 401)
            return 'Invalid credentials.';

        return 'An unexpected error occurred. Please try again.';
    }
};
