import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import CompareUtility from '../infrastructure/compare-utility';
import Logger from '../infrastructure/logger';
import PageContext from '../infrastructure/page-context';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import NewsItemService from './news-item-service';

@inject(Router, Logger, PageContext, DialogPresenter, NewsItemService)
export default class NewsItemList {
    @observable filterText;

    constructor(router, logger, pageContext, dialogPresenter, newsItemService) {
        this.router = router;

        this.logger = logger;
        this.logger.name = 'news-item-list';

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.newsItemService = newsItemService;
        this.filterText = '';
        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template: '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate: '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true
                },
                { suppressMenu: true, headerName: 'Publish Date', field: 'publishDate', width: 150, sort: 'desc', template: '<div>${data.publishDate | dateFormat:\'L\'}</div>' },
                { suppressMenu: true, headerName: 'Title', field: 'title', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                { suppressMenu: true, headerName: 'Description', field: 'description', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                { suppressMenu: true, headerName: 'Link URL', field: 'linkUrl', comparator: CompareUtility.compareStringsInsensitive, width: 150, template: '<a class="normal" target="_blank" style="font-weight: normal" href.bind="data.linkUrl">${data.linkUrl}</a>' },
                {
                    suppressMenu: true,
                    headerName: 'Active',
                    field: 'isActive',
                    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button click.delegate="navigateToNewsItemDetail(data.id)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button click.trigger="deleteNewsItem(data)" disabled.bind="!data.isActive">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 100,
                    sortable: false,
                    suppressSizeToFit: true
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.allSelected = false;
        this.selectedActiveNewsItems = [];
    }

    navigateToNewsItemDetail(id) {
        this.router.navigateToRoute('news-item-detail', { id });
    }

    setSelectedActiveNewsItems() {
        this.selectedActiveNewsItems = this.newsItemsView.filter(newsItem => newsItem.isSelected && newsItem.isActive);
    }

    isSelectedChanged() {
        this.allSelected = this.newsItemsView.every(newsItem => newsItem.isSelected);
        this.setSelectedActiveNewsItems();
    }

    allSelectedClicked() {
        if (this.newsItemsView) {
            this.allSelected = !this.newsItemsView.every(newsItem => newsItem.isSelected);

            for (let newsItem of this.newsItemsView)
                newsItem.isSelected = this.allSelected;
        }

        this.setSelectedActiveNewsItems();

        return true;
    }

    filterTextChanged() {
        this.updateNewsItemsView(); 
    }

    updateNewsItemsView() {
        var lowerCasedFilterText = this.filterText.toLowerCase();

        if (this.newsItems) {
            this.newsItemsView = this.newsItems.filter(n =>
                (n.title || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (n.description || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (n.linkUrl || '').toLowerCase().indexOf(lowerCasedFilterText) > -1
            );

            this.allSelected = this.newsItemsView.every(newsItem => newsItem.isSelected);
        }
    }

    activate() {
        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.newsItems = await this.newsItemService.getNewsItems();

                for (let newsItem of this.newsItems) {
                    // Create date object and remove the UTC so the date is not changes since we only care about the date part (and not the time).
                    newsItem.publishDate = new Date(newsItem.publishDate.substring(0, newsItem.publishDate.length - 1));
                }

                this.updateNewsItemsView();
            } catch (error) {
                this.logger.error('Error loading news items.', error);
                this.dialogPresenter.showAlert(
                    'Error Loading News Items',
                    'An error occurred while loading the news items. Please try again later.');
            }

            this.pageContext.isLoading = false;
        })(); 
    }
    
    deleteNewsItem(newsItem) {
        this.dialogPresenter
            .showConfirmation('Delete News Item', 'Are you sure you want to delete this news item?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                this.pageContext.isLoading = true;
                this.newsItemService
                    .deleteNewsItems([newsItem.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('News item deleted successfully.');

                        newsItem.isSelected = false;
                        newsItem.isActive = false;

                        this.setSelectedActiveNewsItems();
                    })
                    .catch(error => {
                        this.logger.error('Error deleting news item.', error, { newsItemId: newsItem.id });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error);
                    });
            });
    }

    deleteSelectedNewsItems() {
        var newsItemsToDelete = this.newsItemsView.filter(n => n.isSelected);
        if (!newsItemsToDelete.length)
            return;

        this.dialogPresenter
            .showConfirmation('Delete Selected News Items', 'Are you sure you want to delete the selected news items?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                var newsItemIds = newsItemsToDelete.map(newsItem => newsItem.id);

                this.pageContext.isLoading = true;
                this.newsItemService
                    .deleteNewsItems(newsItemIds)
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Selected news items deleted successfully.');

                        for (let newsItem of newsItemsToDelete) {
                            newsItem.isSelected = false;
                            newsItem.isActive = false;

                            this.setSelectedActiveNewsItems();
                        }
                    })
                    .catch(error => {
                        this.logger.error('Error deleting news items.', error, { newsItemIds });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, true);
                    });
            });
    }

    handleDeleteError(error, multiple) {
        this.dialogPresenter
            .showAlert(
                'Error Deleting News Item' + (multiple ? 's' : ''),
                this.getApiErrorMessage(error.apiErrorCode));
    }

    getApiErrorMessage(errorCode) {
        switch (errorCode) {
            default:
                return 'An unexpected error occurred while trying to delete newsItems. Please try again later.';
        }
    }
};
