import moment from 'moment';

export default class CompareUtility {
    static compareIntegers(a, b) {
        return parseInt(a) - parseInt(b);
    }

    static compareFloats(a, b) {
        return parseFloat(a) - parseFloat(b);
    }

    static compareStringsInsensitive(a, b) {
        return (a || '').toLowerCase().localeCompare((b || '').toLowerCase());
    }

    static stripCompareIntegers(a, b) {
        if (!a && !b) {
            return 0;
        }
        if (!a) {
            return -1;
        }
        if (!b) {
            return 1;
        }
        return parseInt(a.replace(/\D/g, '')) - parseInt(b.replace(/\D/g, ''));
    }

    static dateCompare(date1, date2) {
        const date1Number = Date.parse(date1);
        const date2Number = Date.parse(date2);

        if (isNaN(date1Number) && isNaN(date2Number)) {
            return 0;
        }
        if (isNaN(date1Number)) {
            return -1;
        }
        if (isNaN(date2Number)) {
            return 1;
        }

        return date1Number - date2Number;
    }
}
