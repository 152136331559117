import { BaseQueryFilter } from 'infrastructure/components/query-filters/base-query-filter';
import {
    CollectionTypeQuery,
    CollectionTypesQueryFilters,
} from 'infrastructure/components/query-filters/collection-types-query-filter';
import {
    ConfirmationTestingQuery,
    ConfirmationTestingQueryFilters,
} from 'infrastructure/components/query-filters/confirmation-testing-query-filter';
import {
    DateRangeQuery,
    DateRangeQueryFilter,
} from 'infrastructure/components/query-filters/date-range-query-filter';
import { QueryFiltered } from 'infrastructure/components/query-filters/query-filtered';
import {
    RecordStatusQuery,
    RecordStatusQueryFilters,
} from 'infrastructure/components/query-filters/record-status-query-filter';
import CollectionType from 'location-testing/collection-type';
import TestMethodGroup from 'location-testing/test-method-group';
import RecordStatus from 'record-status';

export interface MapDashboardQuery {
    id: number;
    dateRangeOption?: string;
    targetStartDate: string;
    targetEndDate: string;
    dateRangeOverride?: boolean;
    planIds: number[];
    recordStatus: RecordStatus;
    pointId?: number;
    pointRooms: string[];
    pointTypes: string[];
    pointZones: string[];
    testMethodIds: number[];
    taskAssigneeIds: number[];
    collectionTypes: CollectionType[];
    remediationRootCauseIds: number[];
    testMethodGroup?: TestMethodGroup;
    confirmationTestValues?: string[];
}

/* eslint-disable brace-style */
export class MapDashboardQueryFilters
    extends BaseQueryFilter
    implements QueryFiltered<MapDashboardQuery>
{
    private _filterCaption: string;
    id: number;
    dateRangeQueryFilters: DateRangeQueryFilter;
    recordStatusQueryFilters: RecordStatusQueryFilters;
    collectionTypesQueryFilters: CollectionTypesQueryFilters;
    confirmationTestingQueryFilters: ConfirmationTestingQueryFilters;

    pointRooms: string[];
    pointTypes: string[];
    pointZones: string[];
    testMethodIds: number[];
    taskAssigneeIds: number[];
    planIds: number[];
    pointId: number;
    remediationRootCauseIds: number[];

    constructor() {
        super();

        this.dateRangeQueryFilters = new DateRangeQueryFilter();
        this.recordStatusQueryFilters = new RecordStatusQueryFilters();
        this.collectionTypesQueryFilters = new CollectionTypesQueryFilters();
        this.confirmationTestingQueryFilters = new ConfirmationTestingQueryFilters();

        this.pointRooms = [];
        this.pointTypes = [];
        this.pointZones = [];
        this.testMethodIds = [];
        this.taskAssigneeIds = [];
        this.planIds = [];
        this.remediationRootCauseIds = [];
        this.id = 0;
    }

    public get count(): number {
        return (
            0 +
            this.recordStatusQueryFilters.count +
            this.collectionTypesQueryFilters.count +
            this.confirmationTestingQueryFilters.count +
            Number(!!(this.testMethodIds && this.testMethodIds.length)) +
            Number(!!(this.pointRooms && this.pointRooms.length)) +
            Number(!!(this.pointZones && this.pointZones.length)) +
            Number(!!(this.pointTypes && this.pointTypes.length)) +
            Number(!!(this.taskAssigneeIds && this.taskAssigneeIds.length)) +
            Number(!!(this.planIds && this.planIds.length)) +
            Number(!!(this.remediationRootCauseIds && this.remediationRootCauseIds.length))
        );
    }

    public get filterCaption(): string {
        return this._filterCaption;
    }

    reset(): void {
        this.dateRangeQueryFilters.reset();
        this.recordStatusQueryFilters.reset();
        this.collectionTypesQueryFilters.reset();
        this.confirmationTestingQueryFilters.reset();

        this.pointRooms = [];
        this.pointTypes = [];
        this.pointZones = [];
        this.testMethodIds = [];
        this.taskAssigneeIds = [];
        this.planIds = [];
        this.remediationRootCauseIds = [];
    }

    getQueryParams(): MapDashboardQuery {
        return {
            ...this.dateRangeQueryFilters.getQueryParams(),
            ...this.recordStatusQueryFilters.getQueryParams(),
            ...this.collectionTypesQueryFilters.getQueryParams(),
            ...this.confirmationTestingQueryFilters.getQueryParams(),
            testMethodIds: this.testMethodIds,
            pointRooms: this.pointRooms,
            pointZones: this.pointZones,
            pointTypes: this.pointTypes,
            taskAssigneeIds: this.taskAssigneeIds,
            planIds: this.planIds,
            id: this.id,
            pointId: this.pointId,
            remediationRootCauseIds: this.remediationRootCauseIds,
        };
    }

    setFilterValues(params: MapDashboardQuery): void {
        const dateRangeQuery: DateRangeQuery = {
            dateRangeOption: params.dateRangeOption,
            targetStartDate: params.targetStartDate,
            targetEndDate: params.targetEndDate,
            dateRangeOverride: params.dateRangeOverride,
        };
        const recordStatusQuery: RecordStatusQuery = { recordStatus: params.recordStatus };

        const collectionTypeQuery: CollectionTypeQuery = {
            collectionTypes: params.collectionTypes,
        };
        const confirmationTestingQuery: ConfirmationTestingQuery = {
            testMethodGroup: params.testMethodGroup,
            confirmationTestValues: params.confirmationTestValues,
        };

        const {
            pointRooms,
            pointTypes,
            pointZones,
            testMethodIds,
            taskAssigneeIds,
            planIds,
            remediationRootCauseIds,
            id,
        } = { ...params };

        this.dateRangeQueryFilters.setFilterValues(dateRangeQuery);
        this.recordStatusQueryFilters.setFilterValues(recordStatusQuery);
        this.collectionTypesQueryFilters.setFilterValues(collectionTypeQuery);
        this.confirmationTestingQueryFilters.setFilterValues(confirmationTestingQuery);

        super.setQueryFilteredValues(
            {
                pointRooms,
                pointTypes,
                pointZones,
                testMethodIds: super.formatSingleOrMultipleOrNothingArray(testMethodIds),
                taskAssigneeIds: taskAssigneeIds?.map(Number),
                planIds: planIds?.map(Number),
                remediationRootCauseIds: super.formatSingleOrMultipleOrNothingArray(
                    remediationRootCauseIds,
                ),
                id,
            },
            this,
        );

        this._filterCaption = super.getFilterCaption(
            this.count,
            'Collection Date Range',
            this.dateRangeQueryFilters.dateRangeCaption,
        );
    }
}
