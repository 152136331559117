import moment from 'moment';

export default class DateUtility {
    static stringToMoment(dateString) {
        try {
            if (!dateString)
                return null;

            var date = new Date(dateString);
            if (isNaN(date.getTime()))
                return null;

            return moment(date);
        } catch (ex) {
            console.warn(ex);
            return null;
        }
    }

    static durationToString(duration) {
        var days = duration.days();
        var hours = duration.hours();
        var minutes = duration.minutes();
        var seconds = duration.seconds();
        var milliseconds = duration.milliseconds();

        var result =  '';

        if (days)
            result += days + 'd:';

        if (result || hours)
            result += hours + 'h:';

        if (result || minutes)
            result += minutes + 'm:';

        result += seconds + 's';

        return result;
    }

    static toView(value, format, defaultValue, convertToLocal = true) {
        if (moment.isMoment(value)) {
            if (convertToLocal)
                return value.local().format(format || 'L LT');

            return value.format(format || 'L LT');
        }

        if (!value)
            return defaultValue || value;

        if (value instanceof Date) {
            if (convertToLocal)
                return moment(value).local().format(format || 'L LT');

            return moment.utc(value).format(format || 'L LT');
        }

        var containsOffset = false;
        var tIndex = value.indexOf('T');
        if (tIndex > -1) {
            let time = value.substring(tIndex + 1);
            if (time.indexOf('+') > -1 || time.indexOf('-') > -1)
                containsOffset = true;
        }

        //if (typeof value === 'string' && value[value.length - 1].toLowerCase() !== 'z' && !containsOffset)
        //    value += 'z';
        if (convertToLocal)
            return moment(new Date(value)).local().format(format || 'L LT');

        return moment(new Date(value)).format(format || 'L LT');
    }
}
