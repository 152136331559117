import moment from 'moment';
import { createDateRangeFilterOption } from '../filters/date-range-filter';
import DateRangeFilterOption from '../filters/date-range-filter-option';
import { BaseQueryFilter } from './base-query-filter';
import { QueryFiltered } from './query-filtered';

export interface DateRangeQuery {
    dateRangeOption: string;
    targetStartDate: string;
    targetEndDate: string;
    dateRangeOverride?: boolean;
    enableTimeFilter?: boolean;
}

export class DateRangeQueryFilter extends BaseQueryFilter implements QueryFiltered<DateRangeQuery> {
    private _queryFilterDateRangeLocalStorageKey = 'query-filter.date-range';
    private _querystringDateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
    private _displayDateFormat = 'MM/DD/YYYY';
    private _defaultDateRangeFilterOption: DateRangeFilterOption;
    private _defaultDateRangeFilterOptions: DateRangeFilterOption[] = [
        createDateRangeFilterOption('last7Days', 'Last 7 Days', 7, false),
        createDateRangeFilterOption('last30Days', 'Last 30 Days', 30, false),
        createDateRangeFilterOption('custom', 'Specific Range'),
        createDateRangeFilterOption('today', 'Today', 0, true),
        createDateRangeFilterOption('next1Days', 'Today and Tomorrow (Emma)', 1, true),
        createDateRangeFilterOption('next5Days', 'Next 5 Days (Emma)', 5, true),
        createDateRangeFilterOption('next6Days', 'Next 6 Days (Emma)', 6, true),
        createDateRangeFilterOption('next7Days', 'Next 7 Days (Emma)', 7, true),
        createDateRangeFilterOption('next30Days', 'Next 30 Days (Emma)', 30, true),
        createDateRangeFilterOption('next60Days', 'Next 60 Days (Emma)', 60, true),
        createDateRangeFilterOption('next90Days', 'Next 90 Days (Emma)', 90, true),
        createDateRangeFilterOption('next120Days', 'Next 120 Days (Emma)', 120, true),
    ];
    private _dateRangeOption: string;
    private _dateRangeFilterOption: DateRangeFilterOption;
    private _dateRangeFilterOptions: DateRangeFilterOption[];
    private _enableTimeFilter: boolean;

    constructor() {
        super();

        this._defaultDateRangeFilterOption =
            super.getGlobalFilterFromLocalStorage(this._queryFilterDateRangeLocalStorageKey) ||
            this._defaultDateRangeFilterOptions.find((o) => o.value === 'today');

        this._dateRangeFilterOptions = this._defaultDateRangeFilterOptions;
        this._dateRangeOption = this._defaultDateRangeFilterOption.value;
        this._dateRangeFilterOption = this._defaultDateRangeFilterOption;
        this._enableTimeFilter = false;
    }

    public get defaultDateRangeFilterOption() {
        return this._defaultDateRangeFilterOption;
    }

    public get dateRangeOption() {
        return this._dateRangeOption;
    }

    public get dateRangeFilterOption() {
        return this._dateRangeFilterOption;
    }

    public set dateRangeFilterOption(option: DateRangeFilterOption) {
        const dateRangeFilterOption = this._dateRangeFilterOptions.find(
            (o: DateRangeFilterOption) => o.value === option.value,
        );
        if (!dateRangeFilterOption) {
            throw new Error(`The dateRangeFilterOption of ${option.value} is not valid.`);
        }
        if (dateRangeFilterOption.value === 'custom') {
            dateRangeFilterOption.startDate = null;
            dateRangeFilterOption.endDate = null;
        }

        this._dateRangeOption = dateRangeFilterOption.value;
        this._dateRangeFilterOption = dateRangeFilterOption;
    }

    public get dateRangeFilterOptions(): DateRangeFilterOption[] {
        return this._dateRangeFilterOptions;
    }

    public get dateRangeCaption(): string {
        if (this._dateRangeOption !== 'custom') {
            return this._dateRangeFilterOption.caption;
        }

        return this.formattedDateRange;
    }

    public get formattedDateRange(): string {
        const startDate = this.formatDisplayDate(this._dateRangeFilterOption.startDate) || '*';
        const endDate = this.formatDisplayDate(this._dateRangeFilterOption.endDate) || '*';

        return `( ${startDate} - ${endDate} )`;
    }

    public get enableTimeFilter(): boolean {
        return this._enableTimeFilter;
    }

    private formatDisplayDate(date: Date) {
        return date ? moment(date).format(this._displayDateFormat) : null;
    }

    public formatDate(date: Date, endDate?: boolean): string {
        return date
            ? this._enableTimeFilter
                ? moment(date).format()
                : endDate
                ? moment(date).startOf('day').add(1, 'days').format()
                : moment(date).startOf('day').format()
            : null;
    }

    private configureDateRangeFilterOption(
        dateRangeOption: string,
        targetStartDate: string,
        targetEndDate: string,
        subtractDay?: boolean,
    ): DateRangeFilterOption {
        //We must support named date ranges to keep in sync
        //support named ranges first if not found bail out to default
        //otherwise everything is custom
        if (dateRangeOption && dateRangeOption !== 'custom') {
            const namedDateRangeFilterOption = this._dateRangeFilterOptions.find(
                (o) => o.value === dateRangeOption,
            );
            if (!namedDateRangeFilterOption) {
                return;
            }
            this.dateRangeFilterOption = namedDateRangeFilterOption;
            return;
        }

        const customDateRangeOption = this._dateRangeFilterOptions.find(
            (o) => o.value === 'custom',
        );
        const momentStartDate = moment(targetStartDate, this._querystringDateFormat);
        const momentEndDate = subtractDay
            ? moment(targetEndDate, this._querystringDateFormat).subtract(1, 'days')
            : moment(targetEndDate, this._querystringDateFormat);
        if (momentStartDate.isValid()) {
            customDateRangeOption.startDate = momentStartDate.toDate();
        }
        if (momentEndDate.isValid()) {
            customDateRangeOption.endDate = momentEndDate.toDate();
        }

        this._dateRangeOption = customDateRangeOption.value;
        this._dateRangeFilterOption = customDateRangeOption;
    }

    public get count(): number {
        return Number(this._dateRangeFilterOption !== this._defaultDateRangeFilterOption);
    }

    reset(): void {
        this._defaultDateRangeFilterOption = this._defaultDateRangeFilterOptions.find(
            (o) => o.value === 'today',
        );
        this._dateRangeOption = this._defaultDateRangeFilterOption.value;
        this._dateRangeFilterOption = this._defaultDateRangeFilterOption;
        this._enableTimeFilter = false;
        super.setGlobalFilterToLocalStorage(
            this._queryFilterDateRangeLocalStorageKey,
            this._dateRangeFilterOption,
        );
    }

    getQueryParams(): DateRangeQuery {
        return {
            targetStartDate: this.formatDate(this._dateRangeFilterOption.startDate),
            targetEndDate: this.formatDate(this._dateRangeFilterOption.endDate, true),
            dateRangeOption: this._dateRangeOption,
            enableTimeFilter: this._enableTimeFilter,
        };
    }

    setFilterValues(params: DateRangeQuery): void {
        if (!params) {
            return;
        }

        const {
            dateRangeOption,
            targetStartDate,
            targetEndDate,
            dateRangeOverride,
            enableTimeFilter,
        } = params;
        this._enableTimeFilter = enableTimeFilter;

        //(1) Do nothing - defaults apply
        if (!dateRangeOption && !dateRangeOverride) {
            return;
        }

        //(2) Override global settings for this request
        if (dateRangeOverride) {
            this.configureDateRangeFilterOption(dateRangeOption, targetStartDate, targetEndDate);
            return;
        }

        //(3) Set Global
        // The end date needs to be rolled back for custom dates...
        // as it was incremented up for the query/server
        this.configureDateRangeFilterOption(dateRangeOption, targetStartDate, targetEndDate, true);
        super.setGlobalFilterToLocalStorage(
            this._queryFilterDateRangeLocalStorageKey,
            this._dateRangeFilterOption,
        );
    }
}
