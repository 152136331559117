import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import RequestTemplate from 'request-templates/request-template';
import SelectorOption from 'infrastructure/selector-option';
import PortalRequest from '../requests/request';

@autoinject
export default class RequestTemplateService {
    constructor(private httpClient: HttpClient) {
    }

    async getRequestTemplates(filters: any): Promise<RequestTemplate[]> {
        var query = buildQueryString(filters, true);

        let requestTemplates = await this.httpClient.get(`request-templates${(query ? `?${query}` : '')}`);        
        return requestTemplates as unknown as RequestTemplate[];
    }
    
    transformRequestTemplatesToSelectorOptions(requestTemplates: RequestTemplate[], drafts: boolean): SelectorOption[] {
        let options: SelectorOption[] = [];
        requestTemplates.forEach(rt => {
            let option = { 
                title: rt.name, 
                value: rt.id.toString(), 
                organizationId: rt.organizationId,
                organizationName: rt.organizationName,
                isDraft: rt.isDraft
            };
            
            if (option.isDraft === drafts)
                options.push(option);
        })

        return options;
    }

    async getRequestTemplate(requestTemplateId: number): Promise<RequestTemplate> {
        let requestTemplate = await this.httpClient.get(`request-templates/${requestTemplateId}`);
        return requestTemplate as unknown as RequestTemplate;
    }

    async cloneRequestTemplate(requestTemplateId: number) {
        return this.httpClient.post(`request-templates/${requestTemplateId}`, requestTemplateId);
    }

    async createRequestTemplate(templateName: string, draftRequest: boolean, isValidForSubmission: boolean, request: PortalRequest): Promise<RequestTemplate> {
        if (!templateName || templateName.length === 0)
            return;

        let requestTemplate = await this.httpClient.post(`request-templates?templateName=${templateName}&draft=${draftRequest}&valid=${isValidForSubmission}`, request);
        return requestTemplate as unknown as RequestTemplate;
    }

    async updateRequestTemplate(requestTemplateId: number, draftRequest: boolean, isValidForSubmission: boolean, request: PortalRequest): Promise<RequestTemplate> {
        let requestTemplate = await this.httpClient.put(`request-templates/${requestTemplateId}?draft=${draftRequest}&valid=${isValidForSubmission}`, request);
        return requestTemplate as unknown as RequestTemplate;
    }

    async deleteRequestTemplates(ids: number[]) {
        var query = ids.map(id => `id=${id}`).join('&');
        return this.httpClient.delete(`request-templates?${query}`);
    }

    getDraftExportUrl(requestTemplateIds: number[], format: string) {
        var query = buildQueryString({ids : requestTemplateIds}, true);
        return `api/request-templates/export?${query}&format=${format}`;
    }
}
