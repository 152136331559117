import SelectorOption from 'infrastructure/selector-option';

enum TestMethodGroup {
    SEROTYPE = 'Salmonella Serotype',
    SPECIATION = 'Listeria Speciation Id',
    PROFILE = 'Profile',
}

export default TestMethodGroup;

export function getTestMethodGroups(): SelectorOption[] {
    return [
        { value: TestMethodGroup.SEROTYPE, title: 'Serotype' },
        { value: TestMethodGroup.SPECIATION, title: 'Speciation' },
        { value: TestMethodGroup.PROFILE, title: 'Profile' },
    ];
}
