import { autoinject } from 'aurelia-dependency-injection';
import { buildQueryString } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import TestMethod from 'test-methods/test-method';
import Logger from '../../infrastructure/logger';
import CapPlanFilters from './cap-plan-filters';
import CapPlan from './contracts/cap-plan';
import { CapPlanListItem } from './contracts/cap-plan-list-item';
import { RelatedCapPlan } from './contracts/related-cap-plan';

@autoinject
export default class CapPlanService {
    constructor(private httpClient: HttpClient, private logger: Logger) {
        this.httpClient = httpClient;
        this.logger = logger;
    }

    getCapPlans(capPlanFilters: CapPlanFilters): Promise<CapPlanListItem[]> {
        const query = buildQueryString(capPlanFilters, true);

        return this.httpClient.get(
            `location-testing/cap-plans${query ? `?${query}` : ''}`,
        ) as any as Promise<CapPlanListItem[]>;
    }

    getCapPlan(tenantId: number, id: number): Promise<CapPlan> {
        return this.httpClient.get(
            `tenant/${tenantId}/location-testing/cap-plans/${id}`,
        ) as any as Promise<CapPlan>;
    }

    getTestMethods(): Promise<TestMethod[]> {
        return this.httpClient.get('location-testing/cap-plans/all/test-methods') as any as Promise<
            TestMethod[]
        >;
    }

    getRelatedCapPlans(
        tenantId: number,
        testMethodIds: number[],
        capPlanId: number,
    ): Promise<RelatedCapPlan[]> {
        const queryString = buildQueryString({ testMethodId: testMethodIds }, true);

        return this.httpClient.get(
            `tenant/${tenantId}/location-testing/cap-plans/${capPlanId}/related${
                queryString.length ? '?' + queryString : ''
            }`,
        ) as any as Promise<RelatedCapPlan[]>;
    }

    bulkDeleteCapPlans(ids: number[]) {
        return this.httpClient.post('location-testing/cap-plans/bulk-delete', { ids });
    }

    createCapPlan(tenantId: number, capPlan: CapPlan): Promise<any> {
        return this.httpClient.post(
            `tenant/${tenantId}/location-testing/cap-plans`,
            capPlan,
        ) as any as Promise<any>;
    }

    updateCapPlan(tenantId: number, capPlan: CapPlan): Promise<CapPlan> {
        return this.httpClient.put(
            `tenant/${tenantId}/location-testing/cap-plans/${capPlan.id}`,
            capPlan,
        ) as any as Promise<CapPlan>;
    }

    getCapPlanRecordChanges(tenantId: number, id: number) {
        return this.httpClient.get(
            `tenant/${tenantId}/location-testing/cap-plans/${id}/record-changes`,
        );
    }
}
