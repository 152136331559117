enum PlanCoverageType {
    Collected = 'Collected',
    Scheduled = 'Scheduled',
    CollectedAndScheduled = 'CollectedAndScheduled',
    Unused = 'Unused',
}

export const planCoverageTypes: Record<string, string> = {
    Collected: PlanCoverageType.Collected,
    Scheduled: PlanCoverageType.Scheduled,
    CollectedAndScheduled: 'Collected and Scheduled',
    Unused: PlanCoverageType.Unused,
};

export function toPlanCoverageType(planCoverageTitle: string): PlanCoverageType {
    return planCoverageTitle !== 'Collected and Scheduled' 
        ? PlanCoverageType[planCoverageTitle] 
        : PlanCoverageType.CollectedAndScheduled;
}

