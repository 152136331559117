import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class LoginAuditService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getLoginAuditData(minDate, maxDate) {
        var qs = '';
        if(!!minDate)
            qs = `minDate=${encodeURIComponent(new Date(minDate).toISOString())}`;
        if(!!maxDate)
            qs = `${qs}${(!!qs ? '&' : '')}maxDate=${encodeURIComponent(new Date(maxDate).toISOString())}`;

        return this.httpClient.get(`login-audit${(!!qs ? '?' : '')}${qs}`);
    }

    getLoginAuditDetail(userId) {
        return this.httpClient.get(`login-audit/detail?userId=${userId}`);
    }
}
