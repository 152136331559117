import SegmentationTemplate from './segmentation-template';
import UiSegmentationTemplateSegment from './ui-segmentation-template-segment';

export default class UiSegmentationTemplate {
    id: number;
    organizationId: number;
    name: string;
    isArchived: boolean;
    isActive: boolean;

    segments: UiSegmentationTemplateSegment[];

    organization: any;
    organizationCaption: string;

    isSelected: boolean;

    static fromModel(
        segmentationTemplate: SegmentationTemplate,
        organizations: any[],
    ): UiSegmentationTemplate {
        let uiSegmentationTemplate = new UiSegmentationTemplate();
        uiSegmentationTemplate.id = segmentationTemplate.id;
        uiSegmentationTemplate.organizationId = segmentationTemplate.organizationId;
        uiSegmentationTemplate.name = segmentationTemplate.name;
        uiSegmentationTemplate.isArchived = segmentationTemplate.isArchived;
        uiSegmentationTemplate.isActive = segmentationTemplate.isActive;

        uiSegmentationTemplate.segments = (segmentationTemplate.segments ?? [])
            .filter((s) => s.isActive)
            .map((s) => UiSegmentationTemplateSegment.fromModel(s));

        uiSegmentationTemplate.organization = organizations.find(
            (o) => o.id === segmentationTemplate.organizationId,
        );
        if (uiSegmentationTemplate.organization)
            uiSegmentationTemplate.organizationCaption = `${uiSegmentationTemplate.organization.externalId} - ${uiSegmentationTemplate.organization.name}`;

        uiSegmentationTemplate.isSelected = false;

        return uiSegmentationTemplate;
    }

    toModel(): SegmentationTemplate {
        return {
            id: this.id,
            organizationId: this.organizationId,
            name: this.name,
            isArchived: this.isArchived,
            isActive: this.isActive,
            segments: this.segments.map((s) => s.toModel()),
        };
    }
}
