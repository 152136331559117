import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class ApplicationModuleService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getApplicationModules(organizationId) {
        return this
            .httpClient
            .get(`application-modules${(organizationId ? `?organizationId=${organizationId}` : '')}`);
    }

    saveApplicationModules(organizationId, applicationModules) {
        return this
            .httpClient
            .put(`application-modules?organizationId=${organizationId}`, applicationModules);
    }
}
