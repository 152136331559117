import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { ValidationController, ValidationRules } from 'aurelia-validation';

@autoinject
export class SampleDescriptionCustomElement {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) description: string;
    @bindable editable: boolean;
    @bindable index: number;

    constructor(
        private element: Element,
        private readonly validationController: ValidationController,
    ) {}

    removeSampleDescription(index: number) {
        const event = new CustomEvent('sample-description-removed', {
            bubbles: true,
            detail: index,
        });

        this.element.dispatchEvent(event);
    }

    bind() {
        ValidationRules.ensure((m: this) => m.description)
            .required()
            .on(this);
    }
}
