import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import SelectorOption from 'infrastructure/selector-option';
import OrganizationPlan from 'location-testing/plans/organization-plan';
import PlanService from 'location-testing/plans/plan-service';

@autoinject
export class PlanSelectorCustomElement {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) planId: number;
    @bindable organizationId: number;
    planOptions: SelectorOption[];

    constructor(private readonly planService: PlanService) {}

    private async getOrganizationPlans() {
        const organizationPlans = await this.planService.getOrganizationPlans(this.organizationId);
        this.planOptions = organizationPlans.map((op: OrganizationPlan) => {
            return {
                title: op.planName,
                value: op.planId,
            } as SelectorOption;
        });
    }

    async organizationIdChanged() {
        await this.getOrganizationPlans();
    }

    async bind() {
        await this.getOrganizationPlans();
    }
}
