import { inject } from 'aurelia-framework';
import PageContext from './infrastructure/page-context';

@inject(Element, PageContext)
export default class Layout {
    constructor(element, pageContext) {
        this.element = element;
        this.pageContext = pageContext;
    }

    attached() {
        this.$filterArea = $(this.element.querySelector('#offCanvasFiltering'));
        this.$filterArea.foundation();
    }

    detached() {
        this.$filterArea.foundation('destroy');
    }
};
