import { Container } from 'aurelia-framework';
import { CustomError } from 'infrastructure/custom-error';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';

// NOTE: Try/Catch does not pick up server side errors.
// TODO: Combine this w/ submitCustomCerificateForm?
function handleDownload(blob: Blob, contentDispositionHeader: string) {
    const regExpFileName = /filename=(?<filename>.*)/;
    const fileName: string | null =
        regExpFileName.exec(contentDispositionHeader)?.groups?.filename ?? null;

    let a = document.createElement('a');
    document.body.appendChild(a);
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
}

function parseAndThrowResponse(blob: Blob) {
    const dialogPresenter = Container.instance.get(DialogPresenter);
    const logger = Container.instance.get(Logger);
    blob.text()
        .then((b) => {
            const data = JSON.parse(b);
            const { apiErrorCode, message } = data || {};
            throw new CustomError(message, apiErrorCode);
        })
        .catch((e: CustomError) => {
            const { errorCode } = e;
            logger.error(
                'Error downloading report',
                'A server side error occured while trying to download a report',
            );

            const errorMessage =
                errorCode && errorCode === 705
                    ? 'The maximum number of rows supported by this version of Excel is 65536.  Please adjust the parameters in order to return fewer results.'
                    : 'An unexpected error occured. Please try again or contact support.';
            dialogPresenter.showAlert('Error Downloading Report', errorMessage);
        });
}

export async function downloadSsrsReport(downloadUrl: string, postObject: any = null) {
    const pageContext = Container.instance.get(PageContext);

    pageContext.isLoading = true;

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            const type = xhr.getResponseHeader('Content-Type');
            const contentDispositionHeader = xhr.getResponseHeader('Content-Disposition');
            const blob = new Blob([xhr.response], { type });

            pageContext.isLoading = false;
            if (xhr.status === 200) {
                handleDownload(blob, contentDispositionHeader);

                return;
            }
            parseAndThrowResponse(blob);
        }
    };

    if (postObject !== null) {
        xhr.open('POST', downloadUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(postObject));
    } else {
        xhr.open('GET', downloadUrl, true);
        xhr.send();
    }
}
