import { computedFrom } from 'aurelia-framework';
import LabelTemplate from './label-template';
import LabelTemplateOrganizationConfiguration from './label-template-organization-configuration';
import LabelTemplateType from './label-template-type';
import UiLabelTemplateElement from './ui-label-template-element';

export default class UiLabelTemplate {
    id: number;
    type: string;
    width: number;
    height: number;
    elements: UiLabelTemplateElement[];
    isActive: boolean;
    batchTestMethods: boolean;

    organizationConfigurations: LabelTemplateOrganizationConfiguration[];

    sizeCaption: string;
    typeCaption: string;

    configuredForCaption: string;
    configuredForHoverCaption: string;

    @computedFrom('width')
    get inchWidth() {
        return (this.width / 72).toFixed(2);
    }

    @computedFrom('height')
    get inchHeight() {
        return (this.height / 72).toFixed(2);
    }

    static fromModel(labelTemplate: LabelTemplate, organizations: any[]) {
        let uiLabelTemplate = new UiLabelTemplate();
        uiLabelTemplate.id = labelTemplate.id;
        uiLabelTemplate.type = labelTemplate.type;
        uiLabelTemplate.width = labelTemplate.width;
        uiLabelTemplate.height = labelTemplate.height;
        uiLabelTemplate.elements = JSON.parse(labelTemplate.elements).map((e) =>
            UiLabelTemplateElement.fromModel(uiLabelTemplate, e),
        );
        uiLabelTemplate.isActive = labelTemplate.isActive;
        uiLabelTemplate.batchTestMethods = labelTemplate.batchTestMethods;

        uiLabelTemplate.sizeCaption = `${(labelTemplate.width / 72).toFixed(2)} x ${(
            labelTemplate.height / 72
        ).toFixed(2)} in.`;
        uiLabelTemplate.typeCaption =
            LabelTemplateType.getOptions().find((o) => o.value === labelTemplate.type)?.title ??
            labelTemplate.type;

        uiLabelTemplate.organizationConfigurations =
            labelTemplate.organizationConfigurations.filter((c) => c.isActive) ?? [];

        uiLabelTemplate.configuredForCaption = '';
        uiLabelTemplate.configuredForHoverCaption = '';

        for (let organizationConfiguration of uiLabelTemplate.organizationConfigurations) {
            let organization = organizations.find(
                (o) => o.id === organizationConfiguration.organizationId,
            );
            if (!organization) continue;

            uiLabelTemplate.configuredForCaption += `${organization.name} (${organizationConfiguration.templateName}), `;
            uiLabelTemplate.configuredForHoverCaption += `${organization.name} (${organizationConfiguration.templateName})\n`;
        }

        uiLabelTemplate.configuredForCaption = uiLabelTemplate.configuredForCaption.substring(
            0,
            uiLabelTemplate.configuredForCaption.length - 2,
        );
        uiLabelTemplate.configuredForHoverCaption =
            uiLabelTemplate.configuredForHoverCaption.substring(
                0,
                uiLabelTemplate.configuredForHoverCaption.length - 1,
            );

        return uiLabelTemplate;
    }

    toModel(): LabelTemplate {
        return {
            id: this.id,
            width: Number(this.width),
            height: Number(this.height),
            type: this.type,
            isActive: this.isActive,
            batchTestMethods: this.batchTestMethods,
            organizationConfigurations: this.organizationConfigurations,
            elements: JSON.stringify(this.elements.map((e) => e.toModel())),
        };
    }
}
