import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)

export default class QualitativeResultService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getQualitativeResultTypes() {

        return this
            .httpClient
            .get(`qualitative-results`);
    }
    
}
