import { autoinject, inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ValidationControllerFactory, validateTrigger, ValidationRules, ValidationController } from 'aurelia-validation';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import AmsCoaExportService from './ams-coa-export-service';
import AmsCoaExport from './ams-coa-export';
import moment from 'moment';

@autoinject
export class CustomerTestImportDetail {
    minCollectionDate: Date;
    maxCollectionDate: Date;
    validationController: ValidationController;
    formChanged: boolean;

    constructor(
        private router: Router,
        validationControllerFactory: ValidationControllerFactory,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private amsCoaExportService: AmsCoaExportService) {

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.logger.name = 'customer-test-import-detail';

        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
    }

    activate() {
        ValidationRules
            .ensure('minCollectionDate').required()
            .ensure('maxCollectionDate').required().satisfies(_ => this.minCollectionDate?.getTime() < this.maxCollectionDate?.getTime())
            .on(this);

        this.validationController.addObject(this);
    }

    handleFormChange() {
        this.formChanged = true;
    }

    async createAmsCoaExport() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid)
            return;

        this.pageContext.isLoading = true;

        try {
            await this.amsCoaExportService.createAmsCoaExport({
                minCollectionDate: moment(this.minCollectionDate).startOf('day').format('YYYY-MM-DD') + 'T00:00:00Z',
                maxCollectionDate: moment(this.maxCollectionDate).startOf('day').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00Z'
            });

            this.formChanged = false;
            await this.dialogPresenter.showAlert('AMS CoA Export Created', 'The requested export has been created. Once the export has been fully processed, you will receive an email with access to the exported file.');
            this.router.navigateToRoute('dashboard');
        } catch (error) {
            this.logger.error('Error uploading test data file.', error);
            this.dialogPresenter.showAlert(
                'Error Saving Test Data File',
                'There was an error uploading the test data file. Please try again later.');
        }

        this.pageContext.isLoading = false;
    }
}
