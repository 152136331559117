import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
} from 'aurelia-validation';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import OrganizationService from 'organizations/organization-service';
import SecurityService from 'security/security-service';
import RecordStatus from '../record-status';
import RequestTemplateService from '../request-templates/request-template-service';
import SegmentType from './segment-type';
import SegmentationTemplate from './segmentation-template';
import SegmentationTemplateService from './segmentation-template-service';
import UiSegmentationTemplate from './ui-segmentation-template';
import UiSegmentationTemplateSegment from './ui-segmentation-template-segment';

@autoinject
export class SegmentationTemplateList {
    organizations: any[];
    uiSegmentationTemplate: UiSegmentationTemplate;

    validationController: ValidationController;
    formChanged: boolean;
    initiallyActive: boolean;

    expandedView: string;

    canViewRequests: boolean;
    canEditSegmentationTemplates: boolean;
    missingSegments: boolean;

    segmentNames = [];
    newSegmentNames = new Map();

    requestTemplates = [];

    constructor(
        validationControllerFactory: ValidationControllerFactory,
        private router: Router,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private organizationService: OrganizationService,
        private requestTemplateService: RequestTemplateService,
        private segmentationTemplateService: SegmentationTemplateService,
    ) {
        this.logger.name = 'segmentation-template-detail';

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.initiallyActive = false;
        this.expandedView = null;

        this.canEditSegmentationTemplates =
            this.securityService.hasPermission('EditSegmentationTemplates') &&
            !this.securityService.isImpersonating();
        this.canViewRequests = this.securityService.hasPermission('ViewRequests');
    }

    toggleViewExpansion(view: string, srcElement: Element) {
        this.expandedView = this.expandedView === view ? null : view;
    }

    activate(params) {
        (async () => {
            this.pageContext.isLoading = true;
            const isWorkingUnderOrganization = this.securityService.isWorkingUnderOrganization();

            try {
                let [
                    organizationConfigurations,
                    segmentationTemplates,
                    segmentationTemplate,
                    organizations,
                    requestTemplates,
                ] = await Promise.all([
                    isWorkingUnderOrganization
                        ? this.organizationService.getImpersonatingConfiguration('hasSegmentAccess')
                        : this.organizationService.getConfiguration('hasSegmentAccess'),
                    this.segmentationTemplateService.getSegmentationTemplates({
                        recordStatus: RecordStatus.ACTIVE,
                    }),
                    params.id !== 'create'
                        ? this.segmentationTemplateService.getSegmentationTemplate(
                              parseInt(params.id),
                          )
                        : Promise.resolve({ isActive: true } as SegmentationTemplate),
                    isWorkingUnderOrganization
                        ? this.organizationService.getImpersonatingOrganizations()
                        : this.organizationService.getOrganizations(),
                    params.id !== 'create' && this.canViewRequests
                        ? this.requestTemplateService.getRequestTemplates({})
                        : Promise.resolve([]),
                ]);

                if (segmentationTemplate.isArchived) {
                    this.router.navigateToRoute('segmentation-template-list');
                }

                let organizationIds = Object.keys(organizationConfigurations);
                if (
                    !organizationIds.some(
                        (id) =>
                            organizationConfigurations[id]?.hasSegmentAccess?.toLowerCase() ===
                            'true',
                    )
                ) {
                    this.router.navigateToRoute('dashboard');
                    return;
                }

                if (params.id === 'create') this.canViewRequests = false;

                this.uiSegmentationTemplate = UiSegmentationTemplate.fromModel(
                    segmentationTemplate,
                    organizations,
                );

                this.segmentNames = segmentationTemplates.reduce(
                    (segmentNames, segmentationTemplate) => {
                        for (let segment of segmentationTemplate.segments)
                            if (!segmentNames.some((n) => n.name === segment.name))
                                segmentNames.push({ name: segment.name });

                        return segmentNames;
                    },
                    [],
                );

                this.initiallyActive = this.uiSegmentationTemplate.isActive;
                this.organizations = organizations.filter(
                    (o) =>
                        organizationConfigurations[o.id]?.hasSegmentAccess?.toLowerCase() ===
                        'true',
                );
                this.requestTemplates = requestTemplates.filter((t) => {
                    let requestTemplate = JSON.parse(t.templateJson);
                    return (
                        requestTemplate.samples?.some(
                            (s) => s.segmentationTemplateId === this.uiSegmentationTemplate.id,
                        ) ?? false
                    );
                });
                this.setupValidation();
            } catch (error) {
                this.logger.error('Error loading segmentation template.', error);
                this.dialogPresenter.showAlert(
                    'Error Loading Segmentation Template',
                    'An error occurred while loading the segmentation template. Please try again later.',
                );
            }

            this.pageContext.isLoading = false;
        })();
    }

    handleIsActiveChecked(checked: boolean) {
        if (!checked) {
            this.uiSegmentationTemplate.isArchived = false;
        }
    }

    addSegment() {
        let segment = UiSegmentationTemplateSegment.new(
            this.uiSegmentationTemplate,
            SegmentType.Text.name,
        );

        this.uiSegmentationTemplate.segments.push(segment);
        this.setupSegmentValidation(segment);
        this.missingSegments = false;
    }

    removeSegment(segment) {
        this.tearDownSegmentValidation(segment);
        this.uiSegmentationTemplate.segments.splice(
            this.uiSegmentationTemplate.segments.indexOf(segment),
            1,
        );
        this.missingSegments = this.uiSegmentationTemplate.segments.length === 0;
    }

    handleSegmentNameTagCreated(segmentName, segment) {
        let existingNewSegmentName = this.newSegmentNames.get(segment);
        if (existingNewSegmentName) {
            let i = this.segmentNames.findIndex((sn) => sn.name === existingNewSegmentName);
            if (i !== -1) this.segmentNames.splice(i, 1);
        }

        this.newSegmentNames.set(segment, segmentName);
        this.segmentNames.push({ name: segmentName });
        segment.name = segmentName;
    }

    navigateToRequestTemplateDetail(id) {
        this.router.navigateToRoute('request-detail', { id: 'create', requestTemplateId: id });
    }

    handleFormChange() {
        this.formChanged = true;
    }

    setupValidation() {
        ValidationRules.ensure('organizationId')
            .required()
            .ensure('name')
            .maxLength(512)
            .required()
            .on(this.uiSegmentationTemplate);

        for (let segment of this.uiSegmentationTemplate.segments)
            this.setupSegmentValidation(segment);
    }

    setupSegmentValidation(segment) {
        ValidationRules.ensure('name').required().on(segment);
    }

    tearDownSegmentValidation(segment) {
        ValidationRules.off(segment);
    }

    async save() {
        var aggregateResult = await this.validationController.validate();

        this.missingSegments = this.uiSegmentationTemplate.segments.length === 0;

        if (!aggregateResult.valid || this.missingSegments) {
            this.pageContext.showErrorOverlay(
                'Part of this form is not valid. Please correct any issues and save again.',
            );
            return;
        }

        if (this.uiSegmentationTemplate.isArchived) {
            const confirmed = await this.dialogPresenter.showConfirmation(
                'Archive Segmentation Template',
                'Are you sure you want to archive this template?  This is not reversible.',
            );
            if (!confirmed) {
                return;
            }
        }
        // TODO: Check for case when deleting a segmentation template that is used by request templates or drafts.
        //if (this.initiallyActive && this.)

        this.pageContext.isLoading = true;

        try {
            let segmentationTemplate = this.uiSegmentationTemplate.toModel();

            let savedSegmentationTemplate =
                await this.segmentationTemplateService.saveSegmentationTemplate(
                    segmentationTemplate,
                );
            this.uiSegmentationTemplate.id = savedSegmentationTemplate.id;
            this.formChanged = false;
            this.initiallyActive = this.uiSegmentationTemplate.isActive;

            this.pageContext.showSuccessOverlay('Segmentation template saved successfully.');

            this.router.navigateToRoute('segmentation-template-list');
        } catch (error) {
            this.logger.error('Error saving segmentation template.', error, {
                uiSegmentationTemplate: this.uiSegmentationTemplate,
            });
            this.dialogPresenter.showAlert(...this.getApiErrorMessage(error.apiErrorCode));
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(apiErrorCode: number) {
        if (apiErrorCode === 1400)
            return [
                'Template Name Already In Use',
                'The name of this template is already in use by the selected organization. Please change the name of this template before saving.',
            ];

        if (apiErrorCode === 1401)
            return [
                'Template In Use By Saved Request',
                'Please ensure that all associated request templates and drafts have been deleted or remove this segmentation template from them before deleting this template.',
            ];

        return [
            'Error Saving Segmentation Template',
            'An error occurred while saving the segmentation template. Please try again later.',
        ];
    }

    cancel() {
        this.router.navigateToRoute('segmentation-template-list');
    }
}
