import SegmentationTemplateSegment from './segmentation-template-segment';
import SegmentationTemplateSegmentUsages from './segmentation-template-segment-usages';
import UiSegmentationTemplate from './ui-segmentation-template';

export default class UiSegmentationTemplateSegment {
    id: number;
    segmentationTemplateId: number;
    type: string;
    name: string;
    required: boolean;
    useOnSampleLabels: boolean;
    useOnRequestCoas: boolean;
    parentSegmentId?: number;
    values?: string;
    isActive: boolean;

    static new(uiSegmenationTemplate: UiSegmentationTemplate, type) {
        let uiSegmentationTemplateSegment = new UiSegmentationTemplateSegment();
        uiSegmentationTemplateSegment.segmentationTemplateId = uiSegmenationTemplate.id;
        uiSegmentationTemplateSegment.type = type;
        uiSegmentationTemplateSegment.required = false;
        uiSegmentationTemplateSegment.isActive = true;

        return uiSegmentationTemplateSegment;
    }

    static fromModel(segment: SegmentationTemplateSegment) {
        let uiSegmentationTemplateSegment = new UiSegmentationTemplateSegment();
        uiSegmentationTemplateSegment.id = segment.id;
        uiSegmentationTemplateSegment.segmentationTemplateId = segment.segmentationTemplateId;
        uiSegmentationTemplateSegment.type = segment.type;
        uiSegmentationTemplateSegment.name = segment.name;
        uiSegmentationTemplateSegment.required = segment.required;
        uiSegmentationTemplateSegment.useOnSampleLabels =
            SegmentationTemplateSegmentUsages.SampleLabels.in(segment.usages);
        uiSegmentationTemplateSegment.useOnRequestCoas =
            SegmentationTemplateSegmentUsages.RequestCoas.in(segment.usages);
        uiSegmentationTemplateSegment.isActive = segment.isActive;

        return uiSegmentationTemplateSegment;
    }

    toModel(): SegmentationTemplateSegment {
        var usages = [];

        if (this.useOnSampleLabels) usages.push(SegmentationTemplateSegmentUsages.SampleLabels);

        if (this.useOnRequestCoas) usages.push(SegmentationTemplateSegmentUsages.RequestCoas);

        return {
            id: this.id,
            segmentationTemplateId: this.segmentationTemplateId,
            type: this.type,
            name: this.name,
            required: this.required,
            usages: SegmentationTemplateSegmentUsages.combine(usages),
            parentSegmentId: this.parentSegmentId,
            values: this.values,
            isActive: this.isActive,
        };
    }
}
