import { inject, bindable, bindingMode, TaskQueue, child, Container, ViewCompiler } from 'aurelia-framework';

@inject(Element, TaskQueue, Container, ViewCompiler)
export class Select2 {
    @bindable enableCustomItems;
    @bindable suppressFullWidth;
    @bindable placeholder;
    @bindable disablePlaceholder;
    @bindable items;
    @bindable allowClear;
    @bindable disabled;
    @bindable multiple;
    @bindable titlePropertyName;
    @bindable valuePropertyName;
    @bindable groupPropertyName;
    @bindable disabledPropertyName;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedItems;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedItem;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedValues;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedValue;
    @bindable minimumResultsForSearch;
    @bindable filter;
    @child('select2-item-template') itemTemplate;

    constructor(element, taskQueue, container, viewCompiler) {
        this.element = element;
        this.container = container;
        this.viewCompiler = viewCompiler;
        this.taskQueue = taskQueue;

        this.closeDropdownOnEnterKey = this.closeDropdownOnEnterKey.bind(this);
    }

    disabledChanged() {
        if (!this.$select)
            return;

        this.reinitialize();
    }

    created(owningView, myView) {
        var controller = owningView.controllers.find(c => c.view === myView);
        var boundProperty = controller.boundProperties.find(bp => bp.binding.targetProperty.startsWith('selected')).binding.targetProperty;
        this[boundProperty + 'Bound'] = true;
    }

    placeholderChanged() {
        if (!this.$select)
            return;

        this.reinitialize();
    }

    reinitialize() {
        // Defer re-initialization to allow the binding of items to mutate the select element.
        this.taskQueue.queueTask(() => {
            this.destroy();
            this.initialize();
            this.trySetDefault();
        });
    }

    itemsChanged() {
        if (this.groupPropertyName && this.items) {
            this.groupedItems = this.items.reduce((groupedItems, item) => {
                let group = item[this.groupPropertyName];
                groupedItems.has(group) ?
                    groupedItems.get(group).push(item) :
                    groupedItems.set(group, [item]);

                return groupedItems;
            }, new Map());
        }

        if (!this.$select)
            return;

        this.reinitialize();
    }

    itemTemplateChanged() {
        if (!this.$select)
            return;

        this.reinitialize();
    }

    selectedItemsChanged() {
        this.updateSelection();
        this.dispatchEvent();
    }

    selectedItemChanged() {
        this.updateSelection();
        this.dispatchEvent();
    }

    selectedValuesChanged() {
        this.updateSelection();
        this.dispatchEvent();
    }

    selectedValueChanged() {
        this.updateSelection();
        this.dispatchEvent();
    }

    closeDropdownOnEnterKey(e) {
        if (e.keyCode === 13)
            this.$select && this.$select.select2('close');
    }

    attached() {
        $('body').on('keydown', 'input.select2-search__field', this.closeDropdownOnEnterKey);

        this.$select = $(this.element.getElementsByTagName('select')[0]);
        this.initialize();
    }

    dispatchEvent() {
        this.element.dispatchEvent(new CustomEvent('select2change', {
            bubbles: true,
            detail: {
                selectedItems: this.selectedItems,
                selectedItem: this.selectedItem,
                selectedValues: this.selectedValues,
                selectedValue: this.selectedValue
            }
        }));
    }

    detached() {
        $('body').off('keydown', 'input.select2-search__field', this.closeDropdownOnEnterKey);

        this.destroy();
        this.isDetached = true;
    }

    createTag(params) {
        if (!params.term || !this.items)
            return;
        
        var tag = {
            id: params.term,
            text: params.term,
            tag: true
        };

        tag[this.valuePropertyName || 'value'] = params.term;
        tag[this.titlePropertyName || 'title'] = params.term;
        
        return tag;
    }

    insertTag(data, tag) {
        this.element.dispatchEvent(new CustomEvent('tagcreated', {
            bubbles: true,
            detail: { tag }
        }));
    }

    findItemByValue(items, value) {
        // coercion used here intentionally to support int/string/etc 
        return items.find(item => {
            var itemValue = item[this.valuePropertyName || 'value'];
            // Handle boolean string values differently since coersion doesn't work below.
            if (typeof itemValue === 'boolean') {
                let booleanValue = value === 'true';
                return itemValue === booleanValue;
            }

            return itemValue == value;
        });
    }

    initialize() {
        this.$select
            .select2({
                placeholder: this.placeholder || (this.multiple ? 'Select items' : 'Select item'),
                minimumResultsForSearch: this.enableCustomItems ? 0 : (this.minimumResultsForSearch || this.minimumResultsForSearch === 0) ? this.minimumResultsForSearch : Infinity,
                allowClear: !!this.allowClear,
                dropdownAutoWidth: true,
                ...this.itemTemplate && {
                    templateResult: (state => {
                        let viewFactory = this.viewCompiler.compile(this.itemTemplate.template);
                        let view = viewFactory.create(this.container);
                        let rootElement = document.createElement('div');
                        rootElement.setAttribute('style', 'display: inline-block; width: 100%');

                        view.bind({ item: 'id' in state ? this.findItemByValue(this.items, state.id) : null }, this.itemTemplate.parentBindingContext);
                        view.appendNodesTo(rootElement);
                        view.attached();

                        return rootElement;
                    })
                },
                disabled: !!this.disabled,
                tags: !!this.enableCustomItems,
                createTag: !!this.enableCustomItems && this.createTag.bind(this),
                insertTag: !!this.enableCustomItems && this.insertTag.bind(this),
                language: !this.enableCustomItems ? null : {
                    noResults: () => {
                        return `Press 'Enter' to add this custom text`;
                    }
                },
                ...this.filter && {
                    matcher: (params, data) => {
                        if (!params.term)
                            return data;

                        let matched = 'id' in data && this.filter(params.term, this.findItemByValue(this.items, data.id));
                        if (matched)
                            return data;

                        return null;
                    }
                }
            })
            .on('select2:select', e => {
                var item = this.findItemByValue(this.items, e.params.data.id);
                if (!item)
                    return;

                var selectedItems = this.multiple ? [...this.getSelectedItems(), item] : [item];

                this.setSelection(selectedItems);
            })
            .on('select2:unselect', e => {
                var selectedItems = [...this.getSelectedItems()];
                var item = this.findItemByValue(selectedItems, e.params.data.id);
                var itemToRemoveIndex = selectedItems.indexOf(item);
                if (itemToRemoveIndex === -1)
                    return;

                selectedItems.splice(itemToRemoveIndex, 1);

                this.setSelection(selectedItems);
            });

        this.trySetDefault();
        this.updateSelection();
    }

    trySetDefault() {
        if (!this.getSelectedValues().length) {
            if (this.multiple) {
                this.setSelection([]);
            } else if (this.disablePlaceholder && this.items && this.items.length) {
                this.setSelection(this.items[0]);
            }
        }
    }

    setSelection(selectedItems) {
        if (this.selectedItemsBound)
            this.selectedItems = selectedItems;

        else if (this.selectedItemBound)
            this.selectedItem = selectedItems.length ? selectedItems[0] : null;

        else if (this.selectedValuesBound)
            this.selectedValues = selectedItems.length ? selectedItems.map(i => i[this.valuePropertyName || 'value']) : [];

        else if (this.selectedValueBound)
            this.selectedValue = selectedItems.length ? selectedItems.map(i => i[this.valuePropertyName || 'value'])[0] : null;
    }

    updateSelection() {
        if (!this.$select)
            return;

        var values = this.getSelectedValues();

        var finalValue = values.length ?
            this.multiple ?
                values.map(e => typeof e === 'boolean' ? e ? 'true' : 'false' : e) :
                (values.map(e => typeof e === 'boolean' ? e ? 'true' : 'false' : e) [0] ?? null) :
            null;

        this.$select.val(finalValue).trigger('change');
    }

    getSelectedValues() {
        if (this.selectedItemsBound)
            return (this.selectedItems || []).map(i => i[this.valuePropertyName || 'value']);

        else if (this.selectedItemBound)
            return this.selectedItem ? [this.selectedItem[this.valuePropertyName || 'value']] : [];

        else if (this.selectedValuesBound)
            return this.selectedValues ?? [];

        else if (this.selectedValueBound)
            return [this.selectedValue ?? null];

        return [];
    }

    getSelectedItems() {
        var selectedValues = this.getSelectedValues();

        return (this.items || [])
            .filter(i => selectedValues.some(v => v === i[this.valuePropertyName || 'value']));
    }

    destroy() {
        if (this.isDetached)
            return;

        this.$select
            .select2('destroy')
            .off('select2:select')
            .off('select2:unselect');
    }
};
