import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import SelectorOption from 'infrastructure/selector-option';
import RecordStatus, { getRecordStatusOptions } from 'record-status';
import CompareUtility from '../infrastructure/compare-utility';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import Logger from '../infrastructure/logger';
import PageContext from '../infrastructure/page-context';
import SecurityService from '../security/security-service';
import UserService from './user-service';

@autoinject
export default class UserList {
    @observable filterText;
    @observable selectedOrganizations;

    // configuration
    gridOptions: any;

    recordStatusOptions: SelectorOption[];
    selectedRecordStatuses: RecordStatus[];

    // subscriptions
    applyFiltersSubscription: Subscription;
    clearFiltersSubscription: Subscription;

    // data
    users: any[];
    usersView: any[];
    selectedActiveUsers: any[];
    allSelected: boolean;
    currentUserOrganizations: any;

    // permissions
    canEditUsers: boolean;
    canDeleteUsers: boolean;
    canAssignRoles: boolean;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private userService: UserService
    ) {
        this.logger.name = 'user-list';
        this.filterText = '';

        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template: '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate: '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true
                },
                { suppressMenu: true, headerName: 'First Name', field: 'firstName', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                { suppressMenu: true, headerName: 'Last Name', field: 'lastName', comparator: CompareUtility.compareStringsInsensitive, sort: 'asc', width: 150 },
                { suppressMenu: true, headerName: 'Email', field: 'email', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                { suppressMenu: true, headerName: 'Role', field: 'roleName', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                { suppressMenu: true, headerName: 'Organization', field: 'organizationName', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                { suppressMenu: true, headerName: 'Last Login', field: 'lastLoginDateTime', template: '${data.lastLoginDateTime | dateFormat}', width: 150 },
                {
                    suppressMenu: true,
                    headerName: 'Locked',
                    field: 'isLocked',
                    template: '<i class="fa fa-check" if.bind="data.isLocked"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: 'Active',
                    field: 'isActive',
                    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button click.delegate="navigateToUserDetail(data.id)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button if.bind="canDeleteUsers" click.trigger="deleteUser(data)" disabled.bind="!data.isActive">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 100,
                    sortable: false,
                    suppressSizeToFit: true
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.allSelected = false;
        this.selectedActiveUsers = [];
        this.canEditUsers = this.securityService.hasPermission('EditUsers') && !this.securityService.isImpersonating();
        this.canDeleteUsers = this.securityService.hasPermission('DeleteUsers') && !this.securityService.isImpersonating();
        this.canAssignRoles = this.securityService.hasPermission('AssignRoles') && !this.securityService.isImpersonating();
        this.selectedOrganizations = [];
        this.selectedRecordStatuses = [RecordStatus.ACTIVE];

        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
    }

    navigateToUserDetail(id) {
        this.router.navigateToRoute('user-detail', { id });
    }

    setSelectedActiveUsers() {
        this.selectedActiveUsers = this.usersView.filter(user => user.isSelected && user.isActive);
    }

    isSelectedChanged() {
        this.allSelected = this.usersView.every(user => user.isSelected);
        this.setSelectedActiveUsers();
    }

    allSelectedClicked() {
        if (this.usersView) {
            this.allSelected = !this.usersView.every(user => user.isSelected);

            for (let user of this.usersView)
                user.isSelected = this.allSelected;
        }

        this.setSelectedActiveUsers();

        return true;
    }

    filterTextChanged() {
        this.updateUsersView();
    }

    handleApplyFilters() {
        if (this.securityService.isCurrentUserInternal()) {
            let organizationIds = this.selectedOrganizations.map(o => o.id);

            this.loadUsers(organizationIds);
            return;
        }

        this.updateUsersView();
    }

    handleClearFilters() {
        this.selectedRecordStatuses = [RecordStatus.ACTIVE];
    }

    updateUsersView() {
        var lowerCasedFilterText = this.filterText.toLowerCase();

        if (this.users) {
            // filter for organization
            let usersMatchingOrganization = this.users.filter(u =>
                this.matchSelectedOrganizations(u) && (
                    (u.firstName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (u.lastName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (u.email || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                    (u.roleName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1
                )
            );

            // filter based on record status
            let selectedRecordStatus = this.selectedRecordStatuses[0];
            if (selectedRecordStatus === RecordStatus.ACTIVE || selectedRecordStatus === RecordStatus.DELETED) {
                let activeStatus = selectedRecordStatus === RecordStatus.ACTIVE;
                usersMatchingOrganization = usersMatchingOrganization.filter(u => u.isActive === activeStatus);
            }

            this.usersView = usersMatchingOrganization;

            this.allSelected = this.usersView.every(user => user.isSelected);
        }
    }

    matchSelectedOrganizations(user) {
        if (!this.selectedOrganizations || !this.selectedOrganizations.length)
            return true;

        return this.selectedOrganizations.some(o => o.id === user.organizationId);
    }

    async loadUsers(organizationIds = []) {
        this.pageContext.isLoading = true;
        try {
            this.users = (await this.userService.getUsers(null, organizationIds)) as any;

            for (let user of this.users)
                user.organizationName = user.organization?.name;

            this.updateUsersView();
        } finally {
            this.pageContext.isLoading = false;
        }
    }

    activate() {
        this.applyFiltersSubscription = this.eventAggregator.subscribe('filters.apply', this.handleApplyFilters);
        this.clearFiltersSubscription = this.eventAggregator.subscribe('filters.clear', this.handleClearFilters);

        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.recordStatusOptions = getRecordStatusOptions();

                const [_, currentUserOrganizations] = await Promise.all([
                    this.loadUsers(),
                    this.userService.getCurrentUserOrganizations()
                ]);

                this.currentUserOrganizations = currentUserOrganizations;
            } catch (error) {
                this.logger.error('Error loading users.', error);
                this.dialogPresenter.showAlert(
                    'Error Loading Users',
                    'An error occurred while loading the users. Please try again later.');
            }

            this.pageContext.isLoading = false;
        })();
    }

    deactivate() {
        this.applyFiltersSubscription?.dispose();
        this.clearFiltersSubscription?.dispose();
    }
    
    deleteUser(user) {
        this.dialogPresenter
            .showConfirmation('Delete User', 'Are you sure you want to delete this user?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                this.pageContext.isLoading = true;
                this.userService
                    .deleteUsers([user.id])
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('User deleted successfully.');

                        user.isSelected = false;
                        user.isActive = false;

                        this.setSelectedActiveUsers();
                    })
                    .catch(error => {
                        this.logger.error('Error deleting user.', error, { userId: user.id });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, false);
                    });
            });
    }

    deleteSelectedUsers() {
        var usersToDelete = this.usersView.filter(u => u.isSelected);
        if (!usersToDelete.length)
            return;

        this.dialogPresenter
            .showConfirmation('Delete Selected Users', 'Are you sure you want to delete the selected users?')
            .then(confirmed => {
                if (!confirmed)
                    return;

                var userIds = usersToDelete.map(user => user.id);

                this.pageContext.isLoading = true;
                this.userService
                    .deleteUsers(userIds)
                    .then(() => {
                        this.pageContext.isLoading = false;
                        this.pageContext.showSuccessOverlay('Selected users deleted successfully.');

                        for (let user of usersToDelete) {
                            user.isSelected = false;
                            user.isActive = false;

                            this.setSelectedActiveUsers();
                        }
                    })
                    .catch(error => {
                        this.logger.error('Error deleting users.', error, { userIds });
                        this.pageContext.isLoading = false;
                        this.handleDeleteError(error, true);
                    });
            });
    }

    handleDeleteError(error, multipleUsers) {
        this.dialogPresenter
            .showAlert(
                'Error Deleting User' + (multipleUsers ? 's' : ''),
                this.getApiErrorMessage(error.apiErrorCode, multipleUsers));
    }

    getApiErrorMessage(errorCode, multipleUsers) {
        switch (errorCode) {
            case 101:
                return 'You cannot delete your own user account';

            case 2:
                return 'You dont have access to delete the user account' + (multipleUsers ? 's' : '');

            default:
                return 'An unexpected error occurred while trying to delete users. Please try again later.';
        }
    }
}
