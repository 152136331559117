import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import CompareUtility from 'infrastructure/compare-utility';
import { createDateRangeFilterOptions } from 'infrastructure/components/filters/date-range-filter';
import { LocalStorageConstants } from 'infrastructure/constants/localstorage.constants';
import { OrganizationConfigurationConstants } from 'infrastructure/constants/organization-configuration.constants';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import moment from 'moment';
import OrganizationService from 'organizations/organization-service';
import RequestService from 'requests/request-service';
import SecurityService from 'security/security-service';
import UserService from 'users/user-service';
import LabelTemplateService from '../label-templates/label-template-service';
import RequestTemplateService from '../request-templates/request-template-service';

@autoinject
export default class RequestList {
    @observable templateFilterText = '';
    @observable requestFilterText = '';
    @observable betaGridEnabled: boolean;
    enableBetaFeatures: boolean;

    requestTemplateGridOptions: any;
    requestGridOptions: any;
    initiallyLoaded = false;

    allTemplatesSelected: boolean;
    selectedActiveRequestTemplates: any[];
    selectedValidRequestTemplates: any[];
    selectedDrafts: any[];

    requestTemplates: any[];
    requestTemplatesView: any[];

    allRequestsSelected = false;
    selectedRequests: any[];
    canDownloadRequestPdfs = false;
    canDeleteSelectedRequests = false;

    requests: any[];
    requestsView: any[];

    currentUser: any;
    currentUserOrganizations: any[];
    selectedOrganizations: any[];

    createdDateRangeOptions: any;
    defaultCreatedDateRangeOption: string;
    selectedCreatedDateRangeOption: any;

    submittedDateRangeOptions: any;
    defaultSubmittedDateRangeOption: string;
    selectedSubmittedDateRangeOption: any;

    applyFiltersSubscription: any;
    clearFiltersSubscription: any;
    templateTypeOptions: any[];
    selectedTemplateType: string;

    requestTemplateGridFullHeightViewModel: any;
    requestGridFullHeightViewModel: any;

    sampleLabelTemplates: any[];

    @observable selectedView: 'pending' | 'submitted' = 'pending';

    noLabelTemplatesMessage =
        'You do not have any sample label templates configured. Please ensure you have at least one sample label template configured.';
    selectSingleRequestMessage = 'A single request must be selected.';
    canDownloadUnavailablePdfs: boolean;
    canDeleteRequestTemplates: boolean;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private pageContext: PageContext,
        private logger: Logger,
        private dialogPresenter: DialogPresenter,
        private userService: UserService,
        private securityService: SecurityService,
        private requestService: RequestService,
        private requestTemplateService: RequestTemplateService,
        private labelTemplateService: LabelTemplateService,
        private organizationService: OrganizationService,
    ) {
        this.logger.name = 'request-list';

        this.templateTypeOptions = [
            { title: 'Any', value: 'any' },
            { title: 'Drafts', value: 'draft' },
            { title: 'Templates', value: 'template' },
        ];

        this.selectedTemplateType = 'any';

        this.selectedOrganizations = [];

        this.createdDateRangeOptions = createDateRangeFilterOptions();
        this.defaultCreatedDateRangeOption = 'custom';
        this.selectedCreatedDateRangeOption = this.createdDateRangeOptions.find(
            (o) => o.value === this.defaultCreatedDateRangeOption,
        );

        this.submittedDateRangeOptions = createDateRangeFilterOptions();
        this.defaultSubmittedDateRangeOption = 'last7Days';
        this.selectedSubmittedDateRangeOption = this.submittedDateRangeOptions.find(
            (o) => o.value === this.defaultSubmittedDateRangeOption,
        );

        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);

        this.requestTemplateGridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template:
                        '<label><input type="checkbox" checked.bind="data.isSelected" change.trigger="isTemplateSelectedChanged()" data-cy="request-template-grid-row-checkbox"></label>',
                    headerCellTemplate:
                        '<label click.delegate="allTemplatesSelectedClicked()"><input type="checkbox" data-cy="select-all-templates-checkbox"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Type',
                    field: 'typeCaption',
                    width: 160,
                    sort: 'asc',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Name',
                    field: 'name',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 290,
                    suppressSizeToFit: true,
                    sort: 'asc',
                },
                {
                    suppressMenu: true,
                    headerName: 'Valid for Submission',
                    field: 'isValidForSubmission',
                    template: '<i class="fa fa-check" if.bind="data.isValidForSubmission"></i>',
                    width: 170,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Organization',
                    field: 'organizationName',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Collection Date',
                    field: 'minCollectionDate',
                    template: '${data.collectionDateCaption}',
                    width: 160,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Sample Count',
                    field: 'sampleCount',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'PO Number',
                    field: 'poNumber',
                    width: 120,
                    comparator: CompareUtility.compareStringsInsensitive,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Created Date/Time',
                    field: 'createDateTime',
                    template: '${data.createDateTime | dateFormat}',
                    width: 160,
                    suppressSizeToFit: true,
                },
                //{
                //    suppressMenu: true,
                //    headerName: 'Active',
                //    field: 'isActive',
                //    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                //    width: 120,
                //    headerClass: 'text-center',
                //    cellClass: 'medium-text-center',
                //    suppressSizeToFit: true
                //},
                {
                    suppressMenu: true,
                    headerName: '',
                    template:
                        '<button click.delegate="navigateToRequestTemplateDetail(data.id)" data-cy="request-detail-button">' +
                        '    <i class="fa fa-pencil-square-o"></i>' +
                        '</button>' +
                        '<button click.delegate="cloneRequestTemplate(data)">' +
                        '    <i class="fa fa-clone"></i>' +
                        '</button>' +
                        '<button click.trigger="deleteRequestTemplate(data)" disabled.bind="!data.isActive">' +
                        '    <i class="fa fa-trash-o"></i>' +
                        '</button>',
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 100,
                    sortable: false,
                    suppressSizeToFit: true,
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.requestGridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template:
                        '<label><input type="checkbox" checked.bind="data.isSelected" change.trigger="isRequestSelectedChanged()" data-cy="request-grid-row-checkbox"></label>',
                    headerCellTemplate:
                        '<label click.delegate="allRequestsSelectedClicked()"><input type="checkbox" data-cy="select-all-requests-checkbox"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Request #',
                    field: 'externalId',
                    width: 160,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Submitted By',
                    field: 'submittedBy',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Status',
                    field: 'customerRequestStatus',
                    width: 160,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Organization',
                    field: 'organizationName',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 150,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'PO Number',
                    field: 'poNumber',
                    width: 120,
                    comparator: CompareUtility.compareStringsInsensitive,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Submitted Date/Time',
                    field: 'requestDate',
                    template: '${data.requestDate | dateFormat}',
                    width: 200,
                    sort: 'desc',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'PDF Available',
                    field: 'pdfAvailableCaption',
                    width: 100,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template:
                        '<button click.delegate="navigateToRequestDetail(data.id)">' +
                        '    <i class="fa fa-pencil-square-o"></i>' +
                        '</button>',
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 100,
                    sortable: false,
                    suppressSizeToFit: true,
                },
            ],
            defaultColDef: { sortable: true, resizable: true },
        };

        this.allTemplatesSelected = false;
        this.selectedActiveRequestTemplates = [];
        this.selectedValidRequestTemplates = [];
        this.selectedDrafts = [];

        this.allRequestsSelected = false;
        this.selectedRequests = [];
        this.canDownloadUnavailablePdfs = this.securityService.canModifyCustomerData();
        this.canDeleteRequestTemplates =
            this.securityService.hasPermission('DeleteRequestTemplates');
    }

    async selectedViewChanged() {
        switch (this.selectedView) {
            case 'pending':
                setTimeout(() => this.requestTemplateGridFullHeightViewModel?.update());
                break;

            case 'submitted':
                setTimeout(() => this.requestGridFullHeightViewModel?.update());
                break;
        }

        if (this.initiallyLoaded) this.loadData();
    }

    navigateToRequestTemplateDetail(id) {
        this.router.navigateToRoute('request-detail', { id: 'create', requestTemplateId: id });
    }

    async cloneRequestTemplate(requestTemplate) {
        this.pageContext.isLoading = true;

        try {
            await this.requestTemplateService.cloneRequestTemplate(requestTemplate.id);
            await this.loadRequestTemplates();

            this.pageContext.showSuccessOverlay('The item was cloned successfully.');

            requestTemplate.isSelected = false;
            requestTemplate.isActive = false;

            this.setSelectedRequestTemplates();
        } catch (error) {
            this.logger.error('Error cloning request template.', error, {
                requestTemplateId: requestTemplate.id,
            });
            this.handleCloneError(error);
        }

        this.pageContext.isLoading = false;
    }

    navigateToRequestDetail(id) {
        this.router.navigateToRoute('request-detail', { id });
    }

    setSelectedRequestTemplates() {
        this.selectedActiveRequestTemplates = this.requestTemplatesView.filter(
            (requestTemplate) => requestTemplate.isSelected && requestTemplate.isActive,
        );
        this.selectedValidRequestTemplates = this.selectedActiveRequestTemplates.filter(
            (t) => t.isValidForSubmission,
        );
        this.selectedDrafts = this.selectedActiveRequestTemplates.filter((t) => t.isDraft);
        this.canDeleteSelectedRequests = this.selectedActiveRequestTemplates.every(
            (requestTemplate) => requestTemplate.createByUserId === this.currentUser.id,
        );
    }

    isTemplateSelectedChanged() {
        this.allTemplatesSelected = this.requestTemplatesView.every(
            (requestTemplate) => requestTemplate.isSelected,
        );
        this.setSelectedRequestTemplates();
    }

    allTemplatesSelectedClicked() {
        if (this.requestTemplatesView) {
            console.log('it is the view');
            this.allTemplatesSelected = !this.requestTemplatesView.every(
                (requestTemplate) => requestTemplate.isSelected,
            );

            for (let requestTemplate of this.requestTemplatesView)
                requestTemplate.isSelected = this.allTemplatesSelected;
        } else {
            console.log('no its not');
        }

        this.setSelectedRequestTemplates();

        return true;
    }

    setSelectedRequests() {
        this.selectedRequests = this.requestsView.filter((r) => r.isSelected);
        this.canDownloadRequestPdfs =
            this.selectedRequests?.length > 0 &&
            (this.canDownloadUnavailablePdfs ||
                this.selectedRequests?.every((r) => r.pdfAvailable));
    }

    isRequestSelectedChanged() {
        this.allRequestsSelected = this.requestsView.every((r) => r.isSelected);
        this.setSelectedRequests();
    }

    allRequestsSelectedClicked() {
        if (this.requestsView) {
            this.allRequestsSelected = !this.requestsView.every((r) => r.isSelected);

            for (let r of this.requestsView) r.isSelected = this.allRequestsSelected;
        }

        this.setSelectedRequests();

        return true;
    }

    templateFilterTextChanged() {
        this.updateRequestTemplatesView();
    }

    updateRequestTemplatesView() {
        if (!this.requestTemplates) return;

        var lowerCasedFilterText = this.templateFilterText.toLowerCase();
        this.requestTemplatesView = this.requestTemplates.filter(
            (u) =>
                (u.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (u.organizationName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (u.poNumber || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (u.typeCaption || '').toLowerCase().indexOf(lowerCasedFilterText) > -1,
        );
    }

    requestFilterTextChanged() {
        this.updateRequestsView();
    }

    updateRequestsView() {
        if (!this.requests) return;

        var lowerCasedFilterText = this.requestFilterText.toLowerCase();
        this.requestsView = this.requests.filter(
            (u) =>
                (u.externalId || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (u.organizationName || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (u.poNumber || '').toLowerCase().indexOf(lowerCasedFilterText) > -1,
        );
    }

    async handleApplyFilters() {
        this.loadData({ force: true });
    }

    async loadData({ force = false } = {}) {
        if (!this.initiallyLoaded) return;

        try {
            if (this.selectedView === 'pending' && (!this.requestTemplates || force))
                await this.loadRequestTemplates();
            else if (this.selectedView === 'submitted' && (!this.requests || force))
                await this.loadRequests();
        } catch (error) {
            this.logger.error(
                this.selectedView === 'pending'
                    ? 'Error while loading request templates'
                    : 'Error while loading requests',
                error,
            );
            this.dialogPresenter.showAlert(
                this.selectedView === 'pending'
                    ? 'Error Loading Request Templates'
                    : 'Error Loading Requests',
                this.selectedView === 'pending'
                    ? 'An error occurred while loading the request templates. Please try again later.'
                    : 'An error occurred while loading the requests. Please try again later.',
            );
        }
    }

    handleClearFilters() {
        this.selectedTemplateType = 'any';
        this.selectedOrganizations = [];
        this.selectedCreatedDateRangeOption = this.createdDateRangeOptions.find(
            (o) => o.value === this.defaultCreatedDateRangeOption,
        );
        this.selectedSubmittedDateRangeOption = this.submittedDateRangeOptions.find(
            (o) => o.value === this.defaultSubmittedDateRangeOption,
        );
    }

    async loadRequestTemplates() {
        let requestTemplateFilters = {
            organizationIds: this.selectedOrganizations.map((o) => o.id),
            createdDateFrom: this.selectedCreatedDateRangeOption.startDate
                ? moment(this.selectedCreatedDateRangeOption.startDate)
                      .startOf('day')
                      .format('M/D/YYYY')
                : null,
            createdDateTo: this.selectedCreatedDateRangeOption.endDate
                ? moment(this.selectedCreatedDateRangeOption.endDate)
                      .startOf('day')
                      .add(1, 'days')
                      .format('M/D/YYYY')
                : null,
            isActive: true,
            isDraft:
                this.selectedTemplateType === 'draft'
                    ? true
                    : this.selectedTemplateType === 'template'
                    ? false
                    : null,
        } as any;

        let [requestTemplates, currentUserOrganizations] = await Promise.all([
            this.requestTemplateService.getRequestTemplates(requestTemplateFilters),
            this.userService.getCurrentUserOrganizations(),
        ]);

        this.requestTemplates = requestTemplates;
        this.currentUserOrganizations = currentUserOrganizations as any;

        for (let requestTemplate of this.requestTemplates)
            this.populateAdditionalProperties(requestTemplate);

        this.updateRequestTemplatesView();
    }

    async loadRequests() {
        let filters = {
            organizationIds: this.selectedOrganizations.map((o) => o.id),
            requestDateFrom: this.selectedSubmittedDateRangeOption.startDate
                ? moment(this.selectedSubmittedDateRangeOption.startDate)
                      .startOf('day')
                      .format('M/D/YYYY')
                : null,
            requestDateTo: this.selectedSubmittedDateRangeOption.endDate
                ? moment(this.selectedSubmittedDateRangeOption.endDate)
                      .startOf('day')
                      .add(1, 'days')
                      .format('M/D/YYYY')
                : null,
        };

        this.requests = await this.requestService.getRequests(filters);

        for (let request of this.requests) {
            request.requestDate = new Date(
                request.requestDate.endsWith('Z') && request.requestDate.endsWith('z')
                    ? request.requestDate
                    : request.requestDate + 'Z',
            );
            request.organizationName = this.currentUserOrganizations.find(
                (o) => o.id === request.organizationId,
            )?.name;

            request.pdfAvailable =
                request.requestSubmissionStatus === null ||
                request.requestSubmissionStatus === 'Submitted';
            request.pdfAvailableCaption = request.pdfAvailable ? 'Yes' : 'No';
        }

        this.updateRequestsView();
    }

    async loadSampleLabelTemplates() {
        this.sampleLabelTemplates = await this.labelTemplateService.getLabelTemplates(
            { type: 'Sample' },
            'Selector',
        );
    }

    activate() {
        this.applyFiltersSubscription = this.eventAggregator.subscribe(
            'filters.apply',
            this.handleApplyFilters,
        );
        this.clearFiltersSubscription = this.eventAggregator.subscribe(
            'filters.clear',
            this.handleClearFilters,
        );

        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.currentUser = this.securityService.getEffectiveUser();

                await Promise.all([this.loadRequestTemplates(), this.loadSampleLabelTemplates()]);

                this.initiallyLoaded = true;
            } catch (error) {
                this.logger.error('Error while loading request templates', error);
                this.dialogPresenter.showAlert(
                    'Error Loading Request Templates',
                    'An error occurred while loading the request templates. Please try again later.',
                );
            }

            this.pageContext.isLoading = false;
        })();
    }

    deactivate() {
        this.applyFiltersSubscription?.dispose();
        this.clearFiltersSubscription?.dispose();
    }

    getCollectionDateCaption(minCollectionDate, maxCollectionDate) {
        if (!minCollectionDate && !maxCollectionDate) return '';

        if (
            !maxCollectionDate ||
            moment(minCollectionDate).isSame(moment(maxCollectionDate), 'day')
        )
            return moment(minCollectionDate).format('M/D/YYYY');

        return `${moment(minCollectionDate).format('M/D/YYYY')} - ${moment(
            maxCollectionDate,
        ).format('M/D/YYYY')}`;
    }

    populateAdditionalProperties(requestTemplate) {
        try {
            let request = JSON.parse(requestTemplate.templateJson);

            requestTemplate.poNumber = request.poNumber;
            requestTemplate.typeCaption = requestTemplate.isDraft ? 'Draft' : 'Template';

            if (!request.samples) return;

            if (
                !requestTemplate.createDateTime.endsWith('Z') &&
                !requestTemplate.createDateTime.endsWith('z')
            )
                requestTemplate.createDateTime += 'Z';

            requestTemplate.createDateTime = new Date(requestTemplate.createDateTime);
            requestTemplate.sampleCount = request.samples.length;

            this.populateCollectionDateProperties(requestTemplate, request);
        } catch (error) {
            this.logger.error('Error enumerating request templates.', error, { requestTemplate });
        }
    }

    populateCollectionDateProperties(requestTemplate, request) {
        let minCollectionDate;
        let maxCollectionDate;

        for (let sample of request.samples) {
            if (!sample.collectionDate) continue;

            if (!sample.collectionDate.endsWith('Z') && !sample.collectionDate.endsWith('z'))
                sample.collectionDate += 'Z';

            let collectionDate = new Date(sample.collectionDate);

            if (!minCollectionDate || minCollectionDate.getTime() > collectionDate)
                minCollectionDate = collectionDate;

            if (!maxCollectionDate || maxCollectionDate.getTime() < collectionDate)
                maxCollectionDate = collectionDate;
        }

        requestTemplate.minCollectionDate = minCollectionDate;
        requestTemplate.collectionDateCaption = this.getCollectionDateCaption(
            minCollectionDate,
            maxCollectionDate,
        );
    }

    async submitSelectedRequestTemplates() {
        var confirmed = await this.dialogPresenter.showConfirmation(
            'Submit Selected Requests',
            'Are you sure you want to submit the selected requests?',
        );
        if (!confirmed) return;

        this.pageContext.isLoading = true;

        let selectedRequestTemplateIds = this.selectedActiveRequestTemplates.map((t) => t.id);

        try {
            await this.requestService.createRequests(selectedRequestTemplateIds);
            await this.loadRequestTemplates();

            this.pageContext.showSuccessOverlay(
                `Your request${
                    selectedRequestTemplateIds.length > 1 ? 's were' : ' was'
                } submitted successfully.`,
            );

            this.setSelectedRequestTemplates();
        } catch (error) {
            this.logger.error('Error submitting draft requests.', error, {
                selectedRequestTemplateIds,
            });
            this.dialogPresenter.showAlert(
                'Error Submitting Request' +
                    (this.selectedActiveRequestTemplates.length > 1 ? 's' : ''),
                'An unexpected error occurred while trying to submit the selected request' +
                    (this.selectedActiveRequestTemplates.length > 1 ? 's' : '') +
                    '. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    async deleteRequestTemplate(requestTemplate) {
        let confirmationHeader = requestTemplate.isDraft
            ? 'Delete Draft Request'
            : 'Delete Request Template';
        let confirmed = await this.dialogPresenter.showConfirmation(
            confirmationHeader,
            'Are you sure you want to delete this item?',
        );
        if (!confirmed) return;

        this.pageContext.isLoading = true;

        try {
            await this.requestTemplateService.deleteRequestTemplates([requestTemplate.id]);
            await this.loadRequestTemplates();

            this.pageContext.showSuccessOverlay('The item was deleted successfully.');

            requestTemplate.isSelected = false;
            requestTemplate.isActive = false;

            this.setSelectedRequestTemplates();
        } catch (error) {
            this.logger.error('Error deleting request template.', error, {
                requestTemplateId: requestTemplate.id,
            });
            this.handleDeleteError(error, false);
        }

        this.pageContext.isLoading = false;
    }

    async deleteSelectedRequestTemplates() {
        var requestTemplatesToDelete = this.requestTemplatesView.filter((u) => u.isSelected);
        if (!requestTemplatesToDelete.length) return;

        let confirmed = await this.dialogPresenter.showConfirmation(
            'Delete Selected Items',
            'Are you sure you want to delete the selected items?',
        );
        if (!confirmed) return;

        var requestTemplateIds = requestTemplatesToDelete.map(
            (requestTemplate) => requestTemplate.id,
        );

        this.pageContext.isLoading = true;

        try {
            await this.requestTemplateService.deleteRequestTemplates(requestTemplateIds);
            await this.loadRequestTemplates();

            this.pageContext.showSuccessOverlay('Selected items were deleted successfully.');

            for (let requestTemplate of requestTemplatesToDelete) {
                requestTemplate.isSelected = false;
                requestTemplate.isActive = false;

                this.setSelectedRequestTemplates();
            }
        } catch (error) {
            this.logger.error('Error deleting request templates.', error, { requestTemplateIds });
            this.handleDeleteError(error, true);
        }

        this.pageContext.isLoading = false;
    }

    downloadRequestExports(format: string) {
        let url = this.requestService.getExportUrl(
            this.selectedRequests.map((t) => t.id),
            format,
        );
        window.location.href = url;

        const formatMsg = format === 'pdf' ? 'PDF' : 'Excel';

        this.pageContext.showSuccessOverlay(`${formatMsg} export started.`);
    }

    downloadDraftExports(format: string) {
        let url = this.requestTemplateService.getDraftExportUrl(
            this.selectedActiveRequestTemplates.map((t) => t.id),
            format,
        );
        window.location.href = url;

        const formatMsg = format === 'pdf' ? 'PDF' : 'Excel';

        this.pageContext.showSuccessOverlay(`${formatMsg} export started.`);
    }

    async downloadSampleLabelsPdf() {
        let labelTemplate;
        let requestId;
        try {
            labelTemplate = await this.selectSampleLabelTemplate();
            requestId = this.requestsView.find((r) => r.isSelected)?.id;
            if (!requestId) return;

            this.pageContext.showSuccessOverlay('Sample label download started.');

            let url = this.requestService.getSampleLabelPdfUrl(requestId, labelTemplate.id);
            window.location.href = url;
        } catch (error) {
            this.logger.error('Error downloading labels PDF.', error, {
                requestId,
                labelTemplateId: labelTemplate?.id,
            });
            this.dialogPresenter.showAlert(
                'Error Downloading Labels PDF',
                'An error occurred while downloading the labels PDF. Please try again later.',
            );
        }
    }

    async selectSampleLabelTemplate() {
        var organizationIds = this.requestsView
            .filter((r) => r.isSelected)
            .map((r) => r.organizationId);
        if (Array.from(new Set(organizationIds)).length > 1) {
            await this.dialogPresenter.showAlert(
                'Cannot Export Labels PDF',
                'There are requests from more than one organization selected. Please ensure that requests from a single organziation are selected.',
            );
            return;
        }

        let result = await this.dialogPresenter.showLabelSelector(
            'Select a sample label',
            'Sample',
            organizationIds[0],
        );
        if (result.wasCancelled) return null;

        return result.output;
    }

    handleDeleteError(error, multipleRequestTemplates) {
        this.dialogPresenter.showAlert(
            'Error Deleting Request Template' + (multipleRequestTemplates ? 's' : ''),
            'An unexpected error occurred while trying to delete request template' +
                (multipleRequestTemplates ? 's' : '') +
                '. Please try again later.',
        );
    }

    handleCloneError(error) {
        this.dialogPresenter.showAlert(
            'Error Cloning Request Template',
            'An unexpected error occurred while trying to clone  request template. Please try again later.',
        );
    }

    /////////////////////////////////////////////////Beta Grid New Code///////////////////////////////////////

    async configureBetaFeaturesAvailability() {
        const currentUser = this.securityService.getEffectiveUser();
        const { organizationId } = currentUser;
        const orgSettings = (await this.organizationService.getConfigurationValues(
            [OrganizationConfigurationConstants.ENABLE_BETA_FEATURES],
            organizationId,
        )) as unknown as any;

        const betaFeaturesEnabled = orgSettings?.enableBetaFeatures?.toLowerCase() === 'true';
        if (betaFeaturesEnabled) {
            this.enableBetaFeatures = true;
        } else {
            localStorage.removeItem(LocalStorageConstants.BETA_GRID_ENABLED);
        }

        this.betaGridEnabled =
            this.enableBetaFeatures &&
            localStorage.getItem(LocalStorageConstants.BETA_GRID_ENABLED) === 'true';
    }

    async canActivate() {
        await this.configureBetaFeaturesAvailability();
    }

    async betaGridEnabledChanged(newValue: boolean, oldValue: boolean) {
        if (!this.enableBetaFeatures || oldValue === undefined) {
            return;
        }

        const enableBetaGrid = newValue ? 'true' : 'false';
        localStorage.setItem(LocalStorageConstants.BETA_GRID_ENABLED, enableBetaGrid);
    }
}
