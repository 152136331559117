import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import SelectorOption from '../selector-option';

export interface ColumnOrder {
    id: string;
    direction: string;
}

interface DataGridReorderDialogModel {
    title: string;
    columnOptions: SelectorOption[];
}

@autoinject
export class DataGridReorderDialog {
    title: string;
    columnOrders: ColumnOrder[] = [];
    columnOptions: SelectorOption[] = [];
    directionOptions: SelectorOption[] = [
        { title: 'Ascending', value: 'asc' },
        { title: 'Descending', value: 'desc' },
    ];

    constructor(private controller: DialogController) { }

    addColumnOrder() {
        this.columnOrders.push({
            id: this.columnOptions[0].value as string,
            direction: this.directionOptions[0].value as string
        });
    }

    removeLastColumnOrder() {
        this.columnOrders.pop();
    }

    activate(model: DataGridReorderDialogModel) {
        this.title = model.title ?? 'Reorder Grid'
        this.columnOptions = model.columnOptions;
        this.addColumnOrder();
    }
}
