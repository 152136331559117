import { GridOptions } from 'ag-grid-community';
import { ColDef, RowNode } from 'ag-grid-community/dist/lib/main';
import { Grid } from 'ag-grid-enterprise/dist/ag-grid-enterprise';
import { DialogService } from 'aurelia-dialog';
import { BindingEngine, Disposable, autoinject, observable } from 'aurelia-framework';
import { Router, activationStrategy } from 'aurelia-router';
import {
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
    validateTrigger,
} from 'aurelia-validation';
import DistributionGroupService from 'distributions-groups/distribution-group-service';
import { LocalStorageConstants } from 'infrastructure/constants/localstorage.constants';
import { OrganizationConfigurationConstants } from 'infrastructure/constants/organization-configuration.constants';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import OrganizationUtility from 'infrastructure/organization-utility';
import PageContext from 'infrastructure/page-context';
import LvOrganizationSampleTemplate from 'lab-vantage/lv-organization-sample-type';
import Lab from 'labs/lab';
import LabService from 'labs/lab-service';
import { toCollectionTypeTitle } from 'location-testing/collection-type';
import operationsTimingOptions from 'location-testing/operations-timing-options';
import TaskService from 'location-testing/tasks/task-service';
import OrganizationSampleTemplateService from 'organization-sample-templates/organization-sample-template-service';
import OrganizationRequestDefaults from 'organizations/organization-request-defaults';
import OrganizationService from 'organizations/organization-service';
import RequestDefaultsService from 'request-defaults/request-defaults-service';
import SecurityService from 'security/security-service';
import SegmentationTemplate from 'segmentation-templates/segmentation-template';
import SegmentationTemplateFilters from 'segmentation-templates/segmentation-template-filters';
import SegmentationTemplateService from 'segmentation-templates/segmentation-template-service';
import { v4 as uuidv4 } from 'uuid';
import CoAReportStyleService from '../coa-report-styles/coa-report-style-service';
import CourierService from '../couriers/courier-service';
import SelectorOption from '../infrastructure/selector-option';
import OrganizationDistributionGroup from '../organizations/organization-distribution-group';
import RequestTemplateSelector from '../request-templates/request-template-selector';
import RequestTemplateService from '../request-templates/request-template-service';
import PortalRequest from './request';
import RequestDetailMode from './request-detail-mode';
import { RequestDetailRequestInfo } from './request-detail-request-info';
import { RequestDetailSampleInfo } from './request-detail-sample-info';
import RequestService from './request-service';
import Sample from './sample';
import SampleTypeSummary from './sample-type-summary';
import { SegmentationSampleGridColBuilder } from './v2/sample-segmentation-grid-col-builder';
import SegmentationSampleGridColDataFactory from './v2/sample-segmentation-grid-col-data-factory';
import { segmentationSampleGridOptions } from './v2/sample-segmentation-grid-options';
import { isSampleValid } from './v2/sample-segmentation-validator';

@autoinject
export default class RequestDetail {
    validationController: ValidationController;
    organizationIdChangedSubscription: Disposable;
    requestTemplateSubscription: Disposable;
    rushChangedSubscription: Disposable;
    labChangedSubscription: Disposable;

    // Permissions
    canViewRequests: boolean;
    canEditRequests: boolean;

    // Data
    request: PortalRequest;
    mode: RequestDetailMode;
    labs: Lab[];
    organizationConfigurations: any;
    organizationDefaultLabCodes: any;
    organizations: any;
    organizationDetail: any;
    lab: any;
    coaReportStyleOptions: SelectorOption[];
    courierOptions: SelectorOption[];
    distributionGroups: OrganizationDistributionGroup[];
    distributionGroupOptions: SelectorOption[];
    organizationSampleTemplates: LvOrganizationSampleTemplate[];
    organizationRequestTemplates: SelectorOption[];
    draftRequests: SelectorOption[];
    requestTemplateId: any;

    // UI
    createMode: boolean;
    enableCompositing: boolean;
    enablePartialRequests: boolean;
    segmentationTemplates: SegmentationTemplate[];
    suspendOrgChangeProcessing: boolean;
    suspendRequestTemplateChangeProcessing: boolean;
    previouslyAttemptedSubmission: boolean;
    formChanged: boolean;
    selectedSamples = [];
    orderedSamples: Sample[];
    @observable view = 'request';

    isLabWare: boolean;

    sampleTypeSummariesDataGridViewModel: any;
    sampleTypeSummaryDataGridConfig: any;
    sampleTypeSummaries: SampleTypeSummary[];
    samplesValid: boolean;

    standardSampleInfoViewModel: RequestDetailSampleInfo;

    // Child view models
    editModeRequestInfoViewModel: RequestDetailRequestInfo;
    draft: boolean;
    draftTemplateName: string;

    //new v2 grid
    betaGridEnabled: boolean;
    sampleSegmentationGridOptions: GridOptions;
    sampleSegmentationGrid: Grid;
    sampleSegmentationGridIsEditable: boolean;

    constructor(
        private router: Router,
        private pageContext: PageContext,
        private logger: Logger,
        private dialogPresenter: DialogPresenter,
        private labService: LabService,
        private taskService: TaskService,
        private requestService: RequestService,
        private requestTemplateService: RequestTemplateService,
        private segmentationTemplateService: SegmentationTemplateService,
        private securityService: SecurityService,
        private organizationService: OrganizationService,
        private distributionGroupService: DistributionGroupService,
        private requestDefaultsService: RequestDefaultsService,
        private organizationSampleTemplateService: OrganizationSampleTemplateService,
        private courierService: CourierService,
        private coaReportStyleService: CoAReportStyleService,
        private dialogService: DialogService,
        private bindingEngine: BindingEngine,
        validationControllerFactory: ValidationControllerFactory,
    ) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.logger.name = 'request-detail';

        this.canViewRequests = this.securityService.hasPermission('ViewRequests');
        this.canEditRequests =
            this.securityService.hasPermission('EditRequests') &&
            !this.securityService.isImpersonating();

        this.suspendOrgChangeProcessing = false;
        this.suspendRequestTemplateChangeProcessing = false;
        this.samplesValid = true;

        this.handleOrganizationIdChanged = this.handleOrganizationIdChanged.bind(this);
        this.handleRequestTemplateChanged = this.handleRequestTemplateChanged.bind(this);
        this.handleRushChanged = this.handleRushChanged.bind(this);
        this.labChanged = this.labChanged.bind(this);

        this.sampleTypeSummaryDataGridConfig = {
            columnDefs: [
                {
                    suppressMenu: true,
                    headerName: 'Sample Template',
                    field: 'sampleTemplateName',
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: '# of Samples',
                    field: 'sampleCount',
                    width: 150,
                    suppressSizeToFit: true,
                },
                {
                    suppressMenu: true,
                    headerName: 'Test Methods',
                    template:
                        '<span title="${data.testMethodsTooltip}">${data.testMethodsDisplay}</span>',
                },
            ],
            domLayout: 'autoHeight',
        };

        this.sampleTypeSummaries = [];
        this.orderedSamples = [];
    }

    viewChanged() {
        if (this.view === 'samples')
            setTimeout(() => {
                this.standardSampleInfoViewModel.resetView();
            });
    }

    getOperationsTimingCaption(operationsTiming) {
        return operationsTimingOptions.find((o) => o.value === operationsTiming).title;
    }

    generateDistributionGroupOptions(distributionGroups) {
        this.distributionGroups = distributionGroups as unknown as OrganizationDistributionGroup[];
        this.distributionGroupOptions = this.distributionGroups.map((dg) => ({
            title: dg.name,
            value: dg.name,
        }));
    }

    ensureDistributionGroupOption(distributionGroupName: string) {
        if (this.distributionGroups.findIndex((d) => d.name === distributionGroupName) === -1) {
            let option = {
                title: distributionGroupName,
                value: distributionGroupName,
            };

            this.distributionGroupOptions.concat(option);
        }
    }

    trySetDefaultLab() {
        if (this.request.labId) return;

        let defaultLabCode =
            this.organizationDefaultLabCodes[this.request.organizationId]?.['defaultLabCode'];
        let defaultLab = !defaultLabCode
            ? null
            : this.labs.find((l) => l.externalId === defaultLabCode);

        if (defaultLab) this.request.labId = defaultLab.id;
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    async handleOrganizationIdChanged(organizationId: number) {
        if (!this.canProcessOrganizationIdChange()) return;

        try {
            this.pageContext.isLoading = true;

            await this.updateOrganizationConfigurableItems(organizationId);

            const [distributionGroups, requestDefaults, organizationSampleTemplates] =
                await Promise.all([
                    this.distributionGroupService.getDistributionGroups(organizationId),
                    this.requestDefaultsService.getRequestDefaults(organizationId),
                    this.organizationSampleTemplateService.getOrganizationSampleTemplates(
                        organizationId,
                    ),
                ]);

            this.generateDistributionGroupOptions(distributionGroups);

            // set the default distribution group for the organization
            let selectedDistributionGroup = this.distributionGroups.find((x) => x.isDefault);
            if (selectedDistributionGroup != null) {
                this.request.distributionGroup = selectedDistributionGroup.name;
            }

            // set the request defaults for the organization
            let orgRequestDefaults = requestDefaults as unknown as OrganizationRequestDefaults;
            if (orgRequestDefaults && orgRequestDefaults.courier !== null)
                this.request.courier = orgRequestDefaults.courier;

            if (orgRequestDefaults && orgRequestDefaults.coaReportStyle !== null)
                this.request.coaReportStyle = orgRequestDefaults.coaReportStyle;

            if (orgRequestDefaults && orgRequestDefaults.poNumber !== null)
                this.request.poNumber = orgRequestDefaults.poNumber;

            if (orgRequestDefaults && orgRequestDefaults.poRequired !== null)
                this.request.poRequired = orgRequestDefaults.poRequired;

            if (orgRequestDefaults && orgRequestDefaults.emailReports !== null)
                this.request.reportingServiceEmail = orgRequestDefaults.emailReports;

            organizationSampleTemplates.sort(this.sortOrganizationSampleTypes);
            this.organizationSampleTemplates = organizationSampleTemplates;

            this.trySetDefaultLab();

            if (this.betaGridEnabled) {
                await this.setupAndBindV2SamplesGrid();
            }
        } catch (error) {
            this.logger.error('Error loading organization data.', error, { id: organizationId });
            this.dialogPresenter.showAlert(
                'Error Loading Organization Data',
                'An error occurred while loading the data for the selected organization. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    canProcessOrganizationIdChange() {
        if (!this.request || this.suspendOrgChangeProcessing) return false;

        return true;
    }

    async updateOrganizationConfigurableItems(organizationId: number) {
        this.enableCompositing =
            this.organizationConfigurations[organizationId]?.allowCompositing?.toLowerCase() ===
            'true';
        this.enablePartialRequests =
            this.organizationConfigurations[
                organizationId
            ]?.allowPartialRequestSpecification?.toLowerCase() === 'true';

        let enableSegmentation =
            this.organizationConfigurations[organizationId]?.hasSegmentAccess?.toLowerCase() ===
            'true';

        if (enableSegmentation) {
            const filters: SegmentationTemplateFilters = {
                recordStatus: 'Active',
                organizationIds: [organizationId],
            };
            if (!this.request.id || this.request.id === 0) {
                filters.isArchived = false;
            }
            this.segmentationTemplates =
                await this.segmentationTemplateService.getSegmentationTemplates(filters);
        }
    }

    //Not sure why this doesn't just navigage to the requested template?
    async handleRequestTemplateChanged(requestTemplateId: number, oldRequestTemplateId: number) {
        if (this.betaGridEnabled) {
            this.router.navigateToRoute(
                'request-detail',
                { id: 'create', requestTemplateId },
                { replace: true },
            );
            return;
        }

        if (this.suspendRequestTemplateChangeProcessing) {
            this.suspendRequestTemplateChangeProcessing = false;
            return;
        }

        if (!requestTemplateId) return;

        if (this.formChanged) {
            let confirmed = await this.dialogPresenter.showConfirmation(
                'Apply Request Template',
                'There are outstanding changes in this request. Continue applying this template?',
            );
            if (!confirmed) {
                this.request.requestTemplateId = oldRequestTemplateId;
                this.suspendRequestTemplateChangeProcessing = true;
                return;
            }
        }

        this.pageContext.isLoading = true;

        try {
            let selectedRequestTemplate = await this.requestTemplateService.getRequestTemplate(
                requestTemplateId as number,
            );
            if (!selectedRequestTemplate) return;

            this.suspendOrgChangeProcessing = true;

            let templateData = JSON.parse(
                selectedRequestTemplate.templateJson,
            ) as unknown as PortalRequest;

            this.request.organizationId = templateData.organizationId;
            await this.updateOrganizationConfigurableItems(this.request.organizationId);

            let sampleTempates =
                await this.organizationSampleTemplateService.getOrganizationSampleTemplates(
                    this.request.organizationId,
                );
            this.organizationSampleTemplates = sampleTempates;

            const distributionGroups = await this.distributionGroupService.getDistributionGroups(
                this.request.organizationId,
            );
            this.generateDistributionGroupOptions(distributionGroups);

            this.request.labId = templateData.labId;
            this.request.courier = templateData.courier;
            this.request.coaReportStyle = templateData.coaReportStyle;

            this.request.distributionGroup = templateData.distributionGroup;
            this.request.projectName = templateData.projectName;
            this.request.poNumber = templateData.poNumber;
            this.request.sampledBy = templateData.sampledBy;

            this.request.reportingServiceEmail = templateData.reportingServiceEmail;
            this.request.notes = templateData.notes;
            this.request.purpose = templateData.purpose;

            this.request.poRequired = templateData.poRequired;
            this.request.rush = templateData.rush;

            this.standardSampleInfoViewModel.removeSamples(this.request.samples);
            this.request.samples.splice(0);

            if (templateData.samples)
                this.standardSampleInfoViewModel.addSamples(templateData.samples);

            this.generateSampleTypeSummaries();
        } catch (error) {
            this.logger.error('Error retrieving request template.', error, {
                requestTemplateId: requestTemplateId,
            });
            this.dialogPresenter.showAlert(
                'Error Retrieving Request Template',
                'An error occurred while retreiving the request template. Please try again later.',
            );
        }

        this.suspendOrgChangeProcessing = false;
        this.pageContext.isLoading = false;
    }

    handleRushChanged(rush: boolean) {
        if (rush)
            this.pageContext.showInfoOverlay('Additional fees will be charged for rush requests');
    }

    labChanged(newValue: number) {
        const selectedLab = this.labs.find((l: Lab) => l.id === newValue);
        this.isLabWare = selectedLab?.externalSource === 'LabWare';
        if (this.isLabWare) {
            this.request.reportingServiceEmail = null;
            this.request.rush = false;
            this.request.distributionGroup = null;
        }
    }

    async handleExitSampleInfo() {
        this.view = 'request';

        if (this.previouslyAttemptedSubmission)
            this.samplesValid = await this.standardSampleInfoViewModel.validate();

        this.generateSampleTypeSummaries();
    }

    async handleSaveRequestTemplate(isDraft: boolean) {
        // is this an existing persisted draft request
        if (this.draft) {
            await this.overwriteExistingDraftRequestTemplate();
        } else {
            await this.promptAndSaveRequestTemplate(isDraft);
        }
    }

    async overwriteExistingDraftRequestTemplate() {
        const orderedSamplesRequest = this.cloneWithOrderedSamples(this.request);

        let requestTemplateId = this.request.requestTemplateId;

        let [mainValid, requestInfoValid, samplesValid] = await Promise.all([
            this.validate(true),
            this.editModeRequestInfoViewModel.validate(true),
            !this.betaGridEnabled
                ? this.standardSampleInfoViewModel.validate(true)
                : this.isBetaGridValid(),
        ]);

        let isValidForSubmission = mainValid && requestInfoValid && samplesValid;
        await this.requestTemplateService.updateRequestTemplate(
            requestTemplateId,
            true,
            isValidForSubmission,
            orderedSamplesRequest,
        );

        this.pageContext.showSuccessOverlay('The draft request has been saved.');
        this.formChanged = false;
        this.router.navigateToRoute('request-list');
    }

    async promptAndSaveRequestTemplate(isDraft: boolean) {
        let requestTemplates = (
            isDraft ? this.draftRequests : this.organizationRequestTemplates
        ) as any[];
        let filteredRequestTemplates = requestTemplates.filter(
            (rt) => rt.organizationId === this.request.organizationId,
        );

        let selectedOrganizationName = this.organizations.filter(
            (o) => o.id === this.request.organizationId,
        )?.[0].name;

        const result: any = await new Promise((resolve) => {
            this.dialogService
                .open({
                    viewModel: RequestTemplateSelector,
                    model: {
                        title: isDraft ? 'Save Draft' : 'Save Request Template',
                        label: `Organization: ${selectedOrganizationName}`,
                        organizationRequestTemplates: filteredRequestTemplates,
                        draftMode: isDraft,
                    },
                })
                .whenClosed((response) => resolve(response));
        });

        if (result.wasCancelled) return;

        this.pageContext.isLoading = true;

        try {
            const orderedSamplesRequest = this.cloneWithOrderedSamples(this.request);

            let isValidForSubmission = false;
            if (isDraft) {
                let [mainValid, requestInfoValid, samplesValid] = await Promise.all([
                    this.validate(true),
                    this.editModeRequestInfoViewModel.validate(true),
                    !this.betaGridEnabled
                        ? this.standardSampleInfoViewModel.validate(true)
                        : this.isBetaGridValid(),
                ]);

                isValidForSubmission = mainValid && requestInfoValid && samplesValid;
            }

            this.standardSampleInfoViewModel?.fixUpSampleCollectionDates();
            if (result.output.existingTemplate) {
                let requestTemplateId = result.output.requestTemplateSelection as number;
                await this.requestTemplateService.updateRequestTemplate(
                    requestTemplateId,
                    isDraft,
                    isValidForSubmission,
                    orderedSamplesRequest,
                );

                this.pageContext.showSuccessOverlay('Request template has been updated.');
            } else {
                await this.requestTemplateService.createRequestTemplate(
                    result.output.requestTemplateSelection,
                    isDraft,
                    isValidForSubmission,
                    orderedSamplesRequest,
                );

                let successMessage = isDraft
                    ? 'The draft request has been saved.'
                    : 'The request template has been created.';
                this.pageContext.showSuccessOverlay(successMessage);
            }

            this.formChanged = false;
        } catch (error) {
            this.logger.error('Error updating request template.', error, {});
            this.dialogPresenter.showAlert(
                'Error Updating Request Templates',
                'An error occurred while updating request templates. Please try again later.',
            );
        }

        this.router.navigateToRoute('request-list');
        this.pageContext.isLoading = false;
    }

    generateSampleTypeSummaries() {
        let summaryMap = new Map();

        // rollup details
        this.request.samples.forEach((s) => {
            let sampleType = summaryMap.get(s.sampleTypeId);
            if (!sampleType) {
                let masterSampleTemplate = this.organizationSampleTemplates.find(
                    (st) => st.sampleTypeId === s.sampleTypeId,
                );

                let testMethodsDisplay = '',
                    testMethodsTooltip = '';

                if (masterSampleTemplate) {
                    testMethodsDisplay = masterSampleTemplate.testTemplates
                        .map((tt) => `${tt.testMethod.code}`)
                        .join(', ');
                    testMethodsTooltip = masterSampleTemplate.testTemplates
                        .map(
                            (tt) =>
                                `${tt.testMethod.code} - ${
                                    tt.testMethod.customerSpecificName || tt.testMethod.name
                                }`,
                        )
                        .join('\n');
                } else {
                    testMethodsDisplay = 'N/A';
                }

                sampleType = {
                    sampleTemplateName:
                        s.sampleTypeId === null
                            ? 'Not Specified'
                            : masterSampleTemplate
                            ? masterSampleTemplate.description ?? 'Not Selected'
                            : 'Unknown',
                    sampleCount: 1,
                    testMethodsDisplay: testMethodsDisplay,
                    testMethodsTooltip: testMethodsTooltip,
                };
                summaryMap.set(s.sampleTypeId, sampleType);
            } else {
                sampleType.sampleCount++;
            }
        });

        this.sampleTypeSummaries = Array.from(summaryMap.values());
    }

    async clearSamples() {
        let confirmed = await this.dialogPresenter.showConfirmation(
            'Clear All Samples',
            'Are you sure you want to clear all the samples on this request?',
        );
        if (!confirmed) return;

        this.request?.samples?.splice(0);
        this.sampleTypeSummaries = [];
    }

    fixSampleCollectionDates() {
        function setDate(date) {
            if (date && typeof date === 'string')
                // Convert UTC+00:00 date string into date object @ local time
                date = new Date(`${date}${date.endsWith('Z') ? '' : 'Z'}`);
            return date;
        }

        if (this.request && this.request.samples) {
            this.request.samples.forEach((s) => {
                s.collectionDate = setDate(s.collectionDate);
            });
        }
    }

    async tryPopulateLocationTestingDetails() {
        this.mode = RequestDetailMode.Standard;

        let taskIds = this.request.samples.reduce((taskIds, sample) => {
            if (sample.parts && sample.parts.length > 0) {
                taskIds.push(...sample.parts.map((p) => p.taskId));
            }

            return taskIds;
        }, []);

        if (taskIds.length === 0) return;

        // NOTE: we have tasks, so this came from location-testing
        this.mode = RequestDetailMode.LocationTesting;

        let tasks = (await this.taskService.getTasks(
            { ids: taskIds },
            null,
            true,
        )) as unknown as any[];
        for (let sample of this.request.samples) {
            for (let part of sample.parts) {
                let task = tasks.find((t) => t.id === part.taskId);

                part.pointName = task.pointName;
                part.pointRoom = task.pointRoom;
                part.pointZone = task.pointZone;
                part.planId = task.plan?.id;
                part.planName = task.plan?.name;
                part.taskCollectedDateTime = new Date(
                    task.collectedDateTime.toLowerCase().endsWith('z')
                        ? task.collectedDateTime
                        : `${task.collectedDateTime}Z`,
                );
                part.taskAssignedUserFullName = task.assignedUserFullName;
                part.taskOperationsTimingCaption = this.getOperationsTimingCaption(
                    task.operationsTiming,
                );
                part.taskCollectionType = toCollectionTypeTitle(task.collectionType);
            }
        }
    }

    subscribeToPropertyChanges() {
        this.organizationIdChangedSubscription = this.bindingEngine
            .propertyObserver(this.request, 'organizationId')
            .subscribe(this.handleOrganizationIdChanged);

        this.requestTemplateSubscription = this.bindingEngine
            .propertyObserver(this.request, 'requestTemplateId')
            .subscribe(this.handleRequestTemplateChanged);

        this.rushChangedSubscription = this.bindingEngine
            .propertyObserver(this.request, 'rush')
            .subscribe(this.handleRushChanged);

        this.labChangedSubscription = this.bindingEngine
            .propertyObserver(this.request, 'labId')
            .subscribe(this.labChanged);
    }

    //note: this is required to ensure beta grid data is reloaded on navigate.
    //changed to 'invoke-lifecyle' when old grid is removed.
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    activate(params) {
        // eslint-disable-next-line sonarjs/cognitive-complexity
        (async () => {
            this.pageContext.isLoading = true;
            this.view = 'request';

            try {
                if (params.id === 'create') {
                    this.createMode = true;
                    this.mode = RequestDetailMode.Standard;

                    let canSubmitStandardRequests =
                        await this.organizationService.checkConfigurationFlag(
                            'allowStandardRequestSubmission',
                        );
                    if (!canSubmitStandardRequests) {
                        this.router.navigateBack();
                        return;
                    }
                }

                const [
                    organizations,
                    organizationConfigurations,
                    organizationDefaultLabCodes,
                    labs,
                    coaReportStyleOptions,
                    courierOptions,
                    request,
                ] = await Promise.all([
                    this.organizationService.getOrganizations(),
                    this.organizationService.getConfigurationValues([
                        'allowStandardRequestSubmission',
                        'allowCompositing',
                        'allowPartialRequestSpecification',
                        'hasSegmentAccess',
                        OrganizationConfigurationConstants.ENABLE_BETA_FEATURES,
                    ]),
                    this.organizationService.getConfiguration('defaultLabCode'),
                    this.labService.getLabs(),
                    this.coaReportStyleService.getCoAReportStyleOptions(),
                    this.courierService.getCoAReportStyleOptions(),
                    params.id === 'create'
                        ? Promise.resolve({ samples: [] })
                        : this.requestService.getRequest(params.id),
                ]);

                this.organizationConfigurations = organizationConfigurations;
                this.organizationDefaultLabCodes = organizationDefaultLabCodes;

                this.setupOrganizations(organizations);
                this.setupLabsSelectorOptions(labs);
                await this.setupRequest(params.requestTemplateId, request);

                this.coaReportStyleOptions = coaReportStyleOptions;
                this.courierOptions = courierOptions;

                this.labChanged(this.request.labId);

                if (this.request.organizationId)
                    await this.updateOrganizationConfigurableItems(this.request.organizationId);

                this.subscribeToPropertyChanges();

                if (this.request.id || params.requestTemplateId) {
                    this.fixSampleCollectionDates();
                    await this.tryPopulateLocationTestingDetails();

                    this.organizationDetail = await this.organizationService.getOrganizationDetail(
                        this.request.organizationId,
                    );

                    if (this.request.labId) {
                        this.lab = await this.labService.getLab(this.request.labId);
                        this.lab.caption =
                            this.lab.displayName || `${this.lab.city}, ${this.lab.state}`;
                    }

                    if (this.mode === RequestDetailMode.Standard) {
                        const [distributionGroups, requestDefaults, organizationSampleTemplates] =
                            await Promise.all([
                                this.distributionGroupService.getDistributionGroups(
                                    this.request.organizationId,
                                ),
                                this.requestDefaultsService.getRequestDefaults(
                                    this.request.organizationId,
                                ),
                                this.organizationSampleTemplateService.getOrganizationSampleTemplates(
                                    this.request.organizationId,
                                ),
                            ]);

                        this.generateDistributionGroupOptions(distributionGroups);

                        let orgRequestDefaults =
                            requestDefaults as unknown as OrganizationRequestDefaults;
                        if (orgRequestDefaults && orgRequestDefaults.poRequired !== null)
                            this.request.poRequired = orgRequestDefaults.poRequired;

                        this.organizationSampleTemplates = organizationSampleTemplates;
                        this.generateSampleTypeSummaries();

                        // fix-up request that may have stale settings
                        if (params.requestTemplateId) {
                            if (
                                (distributionGroups as any).findIndex(
                                    (d) => d.name === this.request.distributionGroup,
                                ) === -1
                            )
                                this.request.distributionGroup = null;
                        } else {
                            this.ensureDistributionGroupOption(this.request.distributionGroup);
                        }
                    }
                }

                await this.setupForCreation();

                // NOTE: Call this here on edit only.
                //       Call on create in onOrgIdChanged
                const isThisAnEditPageLoad = this.request.id || params.requestTemplateId;
                const betGridCanBeRendered =
                    this.betaGridEnabled &&
                    isThisAnEditPageLoad &&
                    this.mode === RequestDetailMode.Standard;
                if (betGridCanBeRendered) {
                    await this.setupAndBindV2SamplesGrid();
                }
            } catch (error) {
                if (error.apiErrorCode === 1101) {
                    this.dialogPresenter.showAlert(
                        'Error Loading Request',
                        'The organization does not have sufficient permissions to submit requests any longer.  Please contact support to resolve this issue.',
                    );
                    this.router.navigateToRoute('request-list');
                } else {
                    this.logger.error('Error loading request.', error, { id: params.id });
                    this.dialogPresenter.showAlert(
                        'Error Loading Request',
                        'An error occurred while loading the request. Please try again later.',
                    );
                }
            }

            this.formChanged = false;
            this.pageContext.isLoading = false;
        })();
    }

    deactivate() {
        this.organizationIdChangedSubscription?.dispose();
        this.requestTemplateSubscription?.dispose();
        this.rushChangedSubscription?.dispose();
        this.labChangedSubscription?.dispose();
    }

    handleFormChange() {
        this.formChanged = true;
    }

    cancel() {
        this.router.navigateToRoute('dashboard');
    }

    // This is a shadow copy of samples that are updated on ag-grid postSort().
    // This outputed PortalRequest object should only be used just before submitting to server.
    cloneWithOrderedSamples(request: PortalRequest) {
        const requestClone = JSON.parse(JSON.stringify(request)) as PortalRequest;

        let orderedSamples: Sample[] = [];
        if (this.betaGridEnabled) {
            this.sampleSegmentationGridOptions.api.forEachNodeAfterFilterAndSort(
                (rowNode: RowNode) => {
                    orderedSamples.push(rowNode.data);
                },
            );
        } else {
            orderedSamples = this.orderedSamples;
        }

        requestClone.samples = orderedSamples;

        return requestClone;
    }

    async submit() {
        const orderedSamplesRequest = this.cloneWithOrderedSamples(this.request);

        let [mainValid, requestInfoValid, samplesValid] = await Promise.all([
            this.validate(),
            this.editModeRequestInfoViewModel.validate(),
            !this.betaGridEnabled
                ? this.standardSampleInfoViewModel.validate()
                : this.isBetaGridValid(),
        ]);

        if (!(mainValid && requestInfoValid && samplesValid)) {
            this.previouslyAttemptedSubmission = true;
            this.samplesValid = samplesValid;
            this.pageContext.showErrorOverlay(
                'Part of this form is not valid. Please correct any issues and save again.',
            );
            return;
        }

        let confirmed = await this.dialogPresenter.showConfirmation(
            'Submit Request',
            'Are you sure you want to submit this request?',
        );
        if (!confirmed) return;

        this.pageContext.isLoading = true;

        try {
            this.request.id = (await this.requestService.createRequest(orderedSamplesRequest)).id;
            this.pageContext.showSuccessOverlay('Request submitted successfully.');
            this.formChanged = false;
            this.router.navigateToRoute(
                'request-detail',
                { id: this.request.id },
                { replace: true },
            );
        } catch (error) {
            this.logger.error('Error saving request.', error, { request: this.request });
            this.dialogPresenter.showAlert(
                'Error Submitting Request',
                'An error occurred while submitting the current request. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    async validate(reset = false): Promise<boolean> {
        var result = await this.validationController.validate();

        if (reset) this.validationController.reset(); // we don't want the user to see any errors rendering

        return result.valid;
    }

    setupValidation() {
        ValidationRules.ensureObject()
            .satisfies((val, obj) => {
                return this.request.samples.length > 0;
            })
            .withMessage('One sample must be provided for submission.')
            .on(this);

        this.validationController.addObject(this);
    }

    async setupRequest(requestTemplateId, request) {
        // populate the request
        if (requestTemplateId) {
            let requestTemplate = await this.requestTemplateService.getRequestTemplate(
                requestTemplateId,
            );
            if (requestTemplate.isDraft) {
                this.draft = true;
                this.draftTemplateName = requestTemplate.name;
            }

            this.request = JSON.parse(requestTemplate.templateJson) as unknown as PortalRequest;
            this.request.requestTemplateId = requestTemplateId;
        } else {
            this.request = request;
        }

        //this gives us identification of records in the sample grid
        this.request.samples.forEach((s) => (s.gridId = uuidv4()));

        // HACK: because we repurposed Request objects on the server where labId isn't nullable, it comes out with a default value
        //       of 0 which is an invalid DB key The 0 value allows the request object to pass validation erroneously on subsequent updates
        if (this.request.labId === 0) this.request.labId = undefined;
    }

    async setupForCreation() {
        if (!this.createMode) return;

        let requestTemplateFilters = { isActive: true } as any;
        let requestTemplates = await this.requestTemplateService.getRequestTemplates(
            requestTemplateFilters,
        );

        this.organizationRequestTemplates =
            this.requestTemplateService.transformRequestTemplatesToSelectorOptions(
                requestTemplates,
                false,
            );
        this.draftRequests = this.requestTemplateService.transformRequestTemplatesToSelectorOptions(
            requestTemplates,
            true,
        );

        if (this.request?.samples?.length > 0) {
            this.standardSampleInfoViewModel?.configureWithExistingSamples();
        }

        this.setupValidation();
    }

    sortOrganizationSampleTypes(a, b) {
        var comparison = 0;

        if (a.ordinal > b.ordinal) comparison = 1;
        else if (a.ordinal < b.ordinal) comparison = -1;

        return comparison;
    }

    setupLabsSelectorOptions(labs) {
        this.labs = labs
            .map((lab) => {
                lab.caption = lab.displayName || `${lab.city}, ${lab.state}`;
                return lab;
            })
            .sort(function (a, b) {
                const captionA = a.caption.toUpperCase();
                const captionB = b.caption.toUpperCase();
                return captionA.localeCompare(captionB);
            });
    }

    setupOrganizations(organizations) {
        var organizations = OrganizationUtility.flattenOrganization(organizations);

        this.organizations = this.createMode
            ? organizations.filter(
                  (o) =>
                      this.organizationConfigurations[
                          o.id
                      ]?.allowStandardRequestSubmission?.toLowerCase() === 'true',
              )
            : organizations;
    }

    /////////////////////////////////////////start new code//////////////////////////////////////////////////

    canActivate() {
        this.betaGridEnabled =
            localStorage.getItem(LocalStorageConstants.BETA_GRID_ENABLED) === 'true';
    }

    /*
     *  TODO: Replace this w/ Aurelia lifecylce hooks.
     *   loadRequiredDataAsync()
     *   canActivate()
     *   bind()
     *  This should ONLY be called at the end of the (to be removed) activate hook
     */
    async setupAndBindV2SamplesGrid() {
        if (!this.betaGridEnabled) {
            return;
        }

        const thisIsASubmittedRequest = this.request.id && this.request.id > 0;
        this.sampleSegmentationGridIsEditable = !thisIsASubmittedRequest;

        //bind()
        const colDataFactory = new SegmentationSampleGridColDataFactory(
            this.segmentationTemplates,
            this.request.samples,
        );
        const columnDefiner = new SegmentationSampleGridColBuilder(colDataFactory)
            .addSampleTemplateCol(
                this.organizationSampleTemplates,
                this.sampleSegmentationGridIsEditable,
            )
            .addCollectionDateCol(this.sampleSegmentationGridIsEditable)
            .addSegmentationTemplateCol(this.sampleSegmentationGridIsEditable)
            .addLookupCols(this.sampleSegmentationGridIsEditable)
            .addNonLookupCols(this.sampleSegmentationGridIsEditable)
            .addDescriptionCol(this.sampleSegmentationGridIsEditable)
            .addLastCols(this.organizationSampleTemplates, this.sampleSegmentationGridIsEditable);

        this.sampleSegmentationGridOptions = segmentationSampleGridOptions;
        this.sampleSegmentationGridOptions.columnDefs = columnDefiner.colDefs;
        this.sampleSegmentationGridOptions.rowData = this.request.samples;

        new Grid(this.sampleSegmentationGrid, this.sampleSegmentationGridOptions);
    }

    betaAddSamplesToGrid(count: number) {
        const { api } = this.sampleSegmentationGridOptions;

        //NOTE: There are 2 kinds of sample interfaces. Fix.
        const samples = [];
        for (let i = 0; i < count; i++) {
            const sample: any = {
                sampleTypeId: null,
                collectionDate: null,
                description: null,
                composite: 'None',
                segmentationTemplateId: null,
                segments: null,
                gridId: uuidv4(),
            };

            samples.push(sample);
        }
        this.request.samples.push(...samples);
        api.setRowData(this.request.samples);
    }

    betaRemoveSamplesFromGrid() {
        const { api } = this.sampleSegmentationGridOptions;
        const selectedNodes = api.getSelectedNodes();
        const currentColDefs = api.getColumnDefs();

        //(1) identify and remove selected nodes
        const selectedSampleIds: string[] = [];
        const selectedSegmentationTemplateIds = [];
        selectedNodes.forEach((rn: RowNode) => {
            selectedSampleIds.push(rn.data.gridId);
            selectedSegmentationTemplateIds.push(rn.data.segmentationTemplateId);
        });
        const updatedSamples = this.request.samples.filter(
            ({ gridId }) => !selectedSampleIds.includes(gridId),
        );
        api.setRowData(updatedSamples);
        this.request.samples = updatedSamples;

        //(2) Identify which cols are no longer needed
        const segmentationTemplateIdsToCheck = [...new Set(selectedSegmentationTemplateIds)];
        const segmentationTemplateIdsToRemove: string[] = [];
        segmentationTemplateIdsToCheck.forEach((id) => {
            if (!this.request.samples.find((s) => s.segmentationTemplateId === id)) {
                segmentationTemplateIdsToRemove.push(id?.toString());
            }
        });
        if (segmentationTemplateIdsToRemove.length === 0) {
            return;
        }

        //(3) match the segmentation template id to the cd colId and filter those types out
        const updatedColDefs = currentColDefs.filter(
            (cd: ColDef) =>
                !segmentationTemplateIdsToRemove.includes(cd.colId.split(/[_ ]+/).pop()),
        );
        api.setColumnDefs(updatedColDefs);
    }

    isBetaGridValid() {
        const { api } = this.sampleSegmentationGridOptions;
        let isValid = true;
        api.forEachNodeAfterFilterAndSort((r) => {
            const v = isSampleValid(r.data);
            if (!v) {
                isValid = false;
            }
        });

        return isValid;
    }
}
