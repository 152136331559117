import { TargetInstruction, noView, processContent, autoinject } from 'aurelia-framework';

function parseElement(compiler, resources, element, instruction) {
    var html = element.innerHTML;
    if (html !== '')
        instruction.template = html;

    element.innerHTML = '';
}

@autoinject
@noView
@processContent(parseElement)
export class SelectorItemTemplate {
    template: string;
    parentBindingContext: any;

    constructor(targetInstruction: TargetInstruction) {
        this.template = '<template>' + (targetInstruction.elementInstruction as any).template + '</template>';
    }

    bind(bindingContext, overrideContext) {
        this.parentBindingContext = overrideContext.bindingContext ?? overrideContext;
    }
}
