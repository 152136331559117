import { autoinject, bindable, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
} from 'aurelia-validation';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import OrganizationUtility from '../../infrastructure/organization-utility';
import PageContext from '../../infrastructure/page-context';
import LabService from '../../labs/lab-service';
import OrganizationService from '../../organizations/organization-service';
import SecurityService from '../../security/security-service';
import TestMethodService from '../../test-methods/test-method-service';
import { downloadSsrsReport } from './report-downloader';
import ReportService from './report-service';

@autoinject
export default class ReportDetail {
    @observable selectedOrganizations;
    @bindable subscriptionCriteria;

    labs: any[];
    selectedLabs: any[];

    organizations: any[];

    user: any;
    currentUserOrganizationId: string;
    userLocalGmtOffset: string;

    report: any;
    reportHtml: string;
    showSubscriptionForm: boolean;

    testMethods: any[];
    selectedTestMethods: any[];

    validationController: ValidationController;
    validateForSubscription: boolean;
    emailRegEx: RegExp;

    organizationParameter: any;
    labParameter: any;
    testMethodParameter: any;
    parameters: any[];

    formChanged: boolean;
    canEditReports: boolean;
    canCreateSubscriptions: boolean;

    constructor(
        private router: Router,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private organizationService: OrganizationService,
        private testMethodService: TestMethodService,
        private reportService: ReportService,
        private securityService: SecurityService,
        private labService: LabService,
        validationControllerFactory: ValidationControllerFactory,
    ) {
        this.logger.name = 'report-detail';

        this.emailRegEx = new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;
        this.validateForSubscription = false;

        this.user = this.securityService.getEffectiveUser();
        this.currentUserOrganizationId = this.user.organizationId;
        this.reportHtml = '';

        // The permission on the server is RunAdhocReports which effectively is treated as EditReports
        // Will update this at a later time as a user could have cached version or permissions.
        this.canEditReports = this.securityService.hasPermission('RunAdhocReports');
    }

    activate(params) {
        (async () => {
            this.pageContext.isLoading = true;

            try {
                var organizations = await this.organizationService.getOrganizations();
                this.organizations = OrganizationUtility.flattenOrganization(organizations);
                if (!this.organizations) throw new Error('No organizations for this user.');

                var defaultOrganization = this.organizations.filter(
                    (o) => o.id === this.currentUserOrganizationId,
                );
                var selectedOrganization = defaultOrganization.length
                    ? defaultOrganization[0]
                    : this.organizations[0];
                this.selectedOrganizations = [selectedOrganization];

                this.labs = (await this.labService.getLabs())
                    .map((lab) => {
                        lab.caption = lab.displayName || `${lab.city}, ${lab.state}`;
                        return lab;
                    })
                    .sort(function (a, b) {
                        var captionA = a.caption.toUpperCase();
                        var captionB = b.caption.toUpperCase();
                        return captionA.localeCompare(captionB);
                    });

                this.report = await this.reportService.getReportNotDownload(params.id);
                if (this.report.hasSubscription) {
                    try {
                        var sub = await this.reportService.getSubscription(this.report.id);
                        this.subscriptionCriteria = sub;
                    } catch (error) {
                        // Either there was an error getting subscription data from SSRS, or the database and SSRS are out of sync (most likely, changes/deletions were made directly within SSRS).
                        this.logger.error('Error loading subscription.', error);
                        this.dialogPresenter.showAlert(
                            'Error Loading Subscription',
                            'An error occurred while loading your subscription for this report. Please try again later or contact system admin.',
                        );
                        // Don't update the database, but flag the report as not having a subscription for current purposes
                        this.report.hasSubscription = false;
                    }
                }

                if (!this.subscriptionCriteria)
                    // Default the TO email to that of the current user, in case the user wants to create a subscription now
                    this.subscriptionCriteria = { toEmailAddress: this.user.email };

                this.subscriptionCriteria.deliveryTimeGmtOffsetMinutes =
                    new Date().getTimezoneOffset();
                if (this.subscriptionCriteria.deliveryTimeGmtOffsetMinutes === 0)
                    this.userLocalGmtOffset = 'UTC';
                else {
                    this.subscriptionCriteria.deliveryTimeGmtOffsetMinutes *= -1; // Value will be positive for negative offsets, and negative for positive offsets -- so need to invert the sign here
                    var hoursOffset = this.subscriptionCriteria.deliveryTimeGmtOffsetMinutes / 60;
                    this.userLocalGmtOffset = `Local/GMT${hoursOffset >= 0 ? '+' : '-'}${(
                        '0000' + (Math.abs(hoursOffset) * 100).toString()
                    ).slice(-4)}`;
                }

                if (this.subscriptionCriteria.deliveryTime) {
                    // Convert from server time zone (UTC)
                    var local = new Date();
                    var deliveryDateTime = new Date(
                        `${
                            local.getUTCMonth() + 1
                        }/${local.getUTCDate()}/${local.getUTCFullYear()} ${
                            this.subscriptionCriteria.deliveryTime
                        } GMT+00:00`,
                    );
                    this.subscriptionCriteria.deliveryTime = `${(
                        '00' + deliveryDateTime.getHours().toString()
                    ).slice(-2)}:${('00' + deliveryDateTime.getMinutes().toString()).slice(-2)}`;
                }

                this.preparePredefinedParameters();
                this.setReportValidationRules();
                this.setSubscriptionValidationRules();

                // If the user is impersonating an organization then we remove the option to create subscriptions
                this.canCreateSubscriptions = true;
                if (this.securityService.isWorkingUnderOrganization()) {
                    this.canCreateSubscriptions = false;
                }

                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error loading report.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Report',
                    'An error occurred while loading the report. Please try again later.',
                );
            }
        })();
    }

    selectedOrganizationsChanged() {
        if (!this.selectedOrganizations.length) return;

        this.getTestMethods();

        if (this.selectedTestMethods)
            this.selectedTestMethods = this.selectedTestMethods.filter((selectedTestMethod) =>
                this.testMethods.some((testMethod) => testMethod.id === selectedTestMethod.id),
            );
    }

    async getTestMethods() {
        if (!this.selectedOrganizations || this.selectedOrganizations.length === 0) return;

        try {
            this.pageContext.isLoading = true;
            let testMethods = [];
            for (let organization of this.selectedOrganizations) {
                testMethods = testMethods
                    .concat(await this.testMethodService.getTestMethods(organization.id))
                    .map((testMethod) => {
                        testMethod.nameWithCode = `${testMethod.billCode} - ${testMethod.name}`;
                        return testMethod;
                    });
            }
            this.testMethods = testMethods.sort(function (a, b) {
                var nameWithCodeA = a.nameWithCode.toUpperCase();
                var nameWithCodeB = b.nameWithCode.toUpperCase();
                return nameWithCodeA.localeCompare(nameWithCodeB);
            });

            this.pageContext.isLoading = false;
        } catch (error) {
            this.logger.error('Error loading test methods.', error);
            this.pageContext.isLoading = false;
            this.dialogPresenter.showAlert(
                'Error Loading Test Methods',
                'An error occurred while loading the test methods. Please try again later.',
            );
        }
    }

    preparePredefinedParameters() {
        if (!this.report || !this.report.parameters) return;

        this.organizationParameter = this.report.parameters.find((p) => p.name === 'Organization');
        this.labParameter = this.report.parameters.find((p) => p.name === 'Lab');
        this.testMethodParameter = this.report.parameters.find((p) => p.name === 'TestMethod');
        this.parameters = this.report.parameters.filter(
            (p) => ['Organization', 'Lab', 'TestMethod'].indexOf(p.name) === -1,
        );
    }

    setReportValidationRules() {
        var rules;

        if (this.labParameter && !this.labParameter.nullable && !this.labParameter.allowBlank)
            rules = ValidationRules.ensure('selectedLabs').satisfies(
                (selectedLabs) => !!selectedLabs && !!selectedLabs.length,
            );

        if (
            this.testMethodParameter &&
            !this.testMethodParameter.nullable &&
            !this.testMethodParameter.allowBlank
        )
            rules = (rules || ValidationRules)
                .ensure('selectedTestMethods')
                .satisfies(
                    (selectedTestMethods) => !!selectedTestMethods && !!selectedTestMethods.length,
                );

        if (rules) rules.on(this);
    }

    setSubscriptionValidationRules() {
        ValidationRules.customRule(
            'emailList',
            (list, obj) => {
                if (!list)
                    // Allow blanks
                    return true;

                var values = list.split(';').map((e) => e.trim());
                if (values.length === 0)
                    // Allow blanks
                    return true;

                for (var i = 0; i < values.length; i++) {
                    if (!values[i] || !this.emailRegEx.test(values[i])) return false;
                }

                return true;
            },
            // eslint-disable-next-line no-useless-escape
            'If specified, ${$displayName} must be a semicolon-separated list of valid email addresses.',
        );

        ValidationRules.ensure((o: any) => o.toEmailAddress)
            .displayName('To')
            .required()
            .when(() => this.validateForSubscription)
            .satisfiesRule('emailList')
            .when(() => this.validateForSubscription)
            .ensure((o) => o.carbonCopyList)
            .displayName('CC')
            .satisfiesRule('emailList')
            .when(() => this.validateForSubscription)
            .ensure((o) => o.blindCarbonCopyList)
            .displayName('BCC')
            .satisfiesRule('emailList')
            .when(() => this.validateForSubscription)
            .ensure((o) => o.deliveryFormat)
            .displayName('Delivery Format')
            .required()
            .when(() => this.validateForSubscription)
            .ensure((o) => o.emailSubject)
            .displayName('Subject')
            .required()
            .when(() => this.validateForSubscription)
            .ensure((o) => o.deliveryFreqency)
            .displayName('Delivery frequency')
            .required()
            .when(() => this.validateForSubscription)
            .ensure((o) => o.deliveryDayOfWeek)
            .displayName('Delivery Day')
            .required()
            .when(() => this.validateForSubscription)
            .ensure((o) => o.deliveryTime)
            .displayName('Delivery Time')
            .required()
            .when(() => this.validateForSubscription)
            .on(this.subscriptionCriteria);
    }

    handleFormChange() {
        this.formChanged = true;
    }

    getParameters() {
        var keyValues = {};

        if (!this.parameters) return keyValues;

        for (let parameter of this.parameters) {
            keyValues[parameter.name] = parameter.values;
        }

        if (this.organizationParameter) {
            if (this.selectedOrganizations && this.selectedOrganizations.length)
                keyValues['OrganizationId'] = this.selectedOrganizations.map((organization) => {
                    return organization.id;
                });
            else
                keyValues['OrganizationId'] = this.organizations.map((organization) => {
                    return organization.id;
                });
        }

        if (this.selectedLabs && this.selectedLabs.length)
            keyValues['LabId'] = this.selectedLabs.map((lab) => {
                return lab.id;
            });

        if (this.selectedTestMethods && this.selectedTestMethods.length)
            keyValues['TestMethodId'] = this.selectedTestMethods.map((tm) => {
                return tm.id;
            });

        return keyValues;
    }

    async renderReport() {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid) return;

        this.pageContext.isLoading = true;
        try {
            this.reportHtml = (await this.reportService.getReport(
                this.report.id,
                'html',
                this.getParameters(),
            )) as unknown as string;

            this.pageContext.isLoading = false;
        } catch (error) {
            this.logger.error('Error rendering report.', error);
            this.pageContext.isLoading = false;
            this.dialogPresenter.showAlert(
                'Error Rendering Report',
                error.apiErrorCode === 5
                    ? 'The report query could not execute in a timely manner. If possible, please adjust the parameters in order to return fewer results.'
                    : 'An error occurred while rendering the report. Please try again later.',
            );
        }
    }

    async downloadReport(format) {
        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid) return;

        const downloadUrl = this.reportService.getDownloadUrl(
            this.report.id,
            format,
            this.getParameters(),
        ) as unknown as string;

        this.pageContext.showSuccessOverlay('Report download started successfully.');

        downloadSsrsReport(downloadUrl);
    }

    async createOrUpdateSubscription() {
        this.validateForSubscription = true;
        var valid = (await this.validationController.validate()).valid;
        this.validateForSubscription = false;
        if (!valid) return;

        this.pageContext.isLoading = true;
        try {
            await this.reportService.createOrUpdateSubscription(
                this.report.id,
                this.subscriptionCriteria,
                this.getParameters(),
            );
            this.report.hasSubscription = true;
            this.pageContext.isLoading = false;
            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Your subscription has been saved successfully.');
        } catch (error) {
            this.logger.error('Error creating report subscription.', error);
            this.pageContext.isLoading = false;
            this.dialogPresenter.showAlert(
                'Error Creating Subscription',
                'An error occurred while creating the report subscription. Please try again later.',
            );
        }
    }

    async deleteSubscription() {
        var confirmed = await this.dialogPresenter.showConfirmation(
            'Delete Subscription',
            'Are you sure you want to delete this subscription?',
        );
        if (!confirmed) return;
        this.pageContext.isLoading = true;
        try {
            await this.reportService.deleteSubscription(this.report.id);
            this.report.hasSubscription = false;
            this.subscriptionCriteria = { toEmailAddress: this.user.email };
            this.showSubscriptionForm = false;
            this.pageContext.isLoading = false;
            this.pageContext.showSuccessOverlay('Your subscription has been successfully deleted.');
        } catch (error) {
            this.logger.error('Error deleting report subscription.', error);
            this.pageContext.isLoading = false;
            this.dialogPresenter.showAlert(
                'Error Deleting Subscription',
                'An error occurred while deleting the report subscription. Please try again later.',
            );
        }
    }

    goToSupport() {
        var message = '';
        message += `I'm having issues running the following report. Here are the details:
--------------------------------------
Report: ${this.report.name.trim()}`;

        if (this.organizationParameter)
            message += `
Organization(s): ${
                this.selectedOrganizations
                    ? this.selectedOrganizations.map((o) => o.name.trim()).join(', ')
                    : '(none)'
            }`;

        if (this.labParameter)
            message += `
Lab(s): ${
                this.selectedLabs
                    ? this.selectedLabs.map((o) => o.city.trim() + ' ' + o.state.trim()).join(', ')
                    : '(none)'
            }`;

        if (this.testMethodParameter)
            message += `
Test method(s): ${
                this.selectedTestMethods
                    ? this.selectedTestMethods
                          .map((o) => o.name.trim() + ' (' + o.billCode.trim() + ')')
                          .join(', ')
                    : '(none)'
            }`;

        for (var i = 0; i < this.parameters.length; i++) {
            var param = this.parameters[i];
            message += `
${param.prompt}: ${param.values ? param.values.join(', ') : '(none)'}`;
        }

        localStorage.setItem('supportFormMessage', message);
        window.open(this.router.generate('support'));
    }
}
