import { inject, observable } from 'aurelia-framework';
import PageContext from '../../infrastructure/page-context';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import GlossaryTermService from './glossary-term-service';
import SecurityService from '../../security/security-service';

@inject(PageContext, DialogPresenter, Logger, SecurityService, GlossaryTermService)
export default class Glossary {
    @observable filterText;
    
    constructor(pageContext, dialogPresenter, logger, securityService, glossaryTermService) {
        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;

        this.logger = logger;
        this.logger.name = 'glossary';

        this.securityService = securityService;
        this.glossaryTermService = glossaryTermService;
        this.filterText = '';
    }

    activate() {
        (async () => {

            this.pageContext.isLoading = true;
            try {
                this.user = this.securityService.getEffectiveUser();
                if (!this.user)
                    throw new Error('No current user.');

                var glossaryTerms = await this.glossaryTermService.getGlossaryTerms();
                this.glossaryTermGroups = buildGlossaryTermGroups(glossaryTerms);
                this.glossaryTermGroupVisibilities = {};
                this.updateGlossaryTermGroupVisibilities();
                this.pageContext.isLoading = false;

            } catch (error) {
                this.logger.error('Error loading glossary.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Glossary',
                    'An error occurred while loading the glossary. Please try again later.');
            }
        })();
    }

    filterTextChanged() {
        if (!this.glossaryTermGroups)
            return;

        var lowerCasedFilterText = this.filterText.toLowerCase();

        for (let glossaryTerms of this.glossaryTermGroups.values())
            for (let glossaryTerm of glossaryTerms)
                glossaryTerm.isVisible = (glossaryTerm.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1;

        this.updateGlossaryTermGroupVisibilities();
    }

    updateGlossaryTermGroupVisibilities() {
        for (let [letter, glossaryTerms] of this.glossaryTermGroups.entries())
            this.glossaryTermGroupVisibilities[letter] = glossaryTerms.some(gt => gt.isVisible);
    }
};

function buildGlossaryTermGroups(glossaryTerms) {
    var glossaryTermGroups = new Map();

    for (let glossaryTerm of glossaryTerms) {
        glossaryTerm.isVisible = true;

        var letter = glossaryTerm.name.trim()[0].toUpperCase();
        var glossaryTermGroup = glossaryTermGroups.get(letter);
        if (glossaryTermGroup)
            glossaryTermGroup.push(glossaryTerm);
        else
            glossaryTermGroups.set(letter, [glossaryTerm]);
    }

    return glossaryTermGroups;
}
