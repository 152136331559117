import { ValueGetterParams } from 'ag-grid-enterprise/dist/ag-grid-enterprise';
import CompareUtility from '../../infrastructure/compare-utility';

const formatTestResult = (params: ValueGetterParams): string => {
    const { confirmation, displayValue } = params.data;
    return confirmation?.length ? confirmation : displayValue;
};

export const pointTestGrid: any = {
    rowHeight: 45,
    columnDefs: [
        {
            suppressMenu: true,
            // Don't show if testId not specified (due to it being a customer imported test).
            template:
                '<label if.bind="data.passed === false && data.testId"><input type="checkbox" checked.bind="data.isSelected" change.delegate="pointTestSelectedChanged()"></label>',
            headerCellTemplate:
                '<label click.delegate="allPointTestsSelectedClicked()"><input type="checkbox" checked.bind="allFailedPointTestsSelected"></label>',
            headerClass: 'checkbox',
            width: 64,
            sortable: false,
            suppressSizeToFit: true,
        },
        {
            suppressMenu: true,
            headerName: 'Sample ID',
            field: 'sampleExternalId',
            comparator: CompareUtility.compareStringsInsensitive,
            width: 100,
            template:
                '<a route-href="route: status; params.bind: { requestId: data.requestExternalId, sampleId: data.sampleExternalId }">${data.sampleExternalId}</a>',
        },
        {
            suppressMenu: true,
            headerName: 'Collection Date',
            field: 'sampleCollectionDate',
            comparator: CompareUtility.dateCompare,
            width: 140,
            sort: 'desc',
            template: "${data.sampleCollectionDate | dateFormat:'L'}</a>", // eslint-disable-line
        },
        {
            suppressMenu: true,
            headerName: 'Test Method',
            field: 'testMethodDisplayName',
            comparator: CompareUtility.compareStringsInsensitive,
            width: 200,
        },
        {
            suppressMenu: true,
            headerName: 'Pass/Fail',
            field: 'passed',
            width: 110,
            template: '${getPointTestPassFailCaption(data)}',
        },
        {
            suppressMenu: true,
            headerName: 'Result',
            field: 'displayValue',
            width: 110,
            valueGetter: formatTestResult,
        },
        {
            suppressMenu: true,
            headerName: 'Plan Name',
            field: 'planName',
            comparator: CompareUtility.compareStringsInsensitive,
            width: 200,
        },
        {
            suppressMenu: true,
            headerName: 'Collection Type',
            field: 'collectionType',
            width: 75,
        },
    ],
    defaultColDef: { sortable: true, resizable: true },
};
