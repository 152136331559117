import { inject, bindable } from 'aurelia-framework';

@inject(Element)
export class FullHeightCustomAttribute {
    @bindable shrink;
    @bindable fixedHeightOverride;

    constructor(element) {
        this.element = element;
        this.setHeight = () => {
            if (this.fixedHeightOverride) {
                this.element.style.height = this.fixedHeightOverride + 'px';
                return;
            }

            var headerBounds = document.getElementsByTagName('footer')[0].getBoundingClientRect();
            var footerTop = headerBounds.y || headerBounds.top;

            var elementBounds = this.element.getBoundingClientRect();
            var currentTop = elementBounds.y || elementBounds.top;

            this.element.style.height = (footerTop - currentTop - (this.shrink || 0)) + 'px';
        };
    }

    fixedHeightOverrideChanged() {
        setTimeout(() => this.setHeight());
    }

    attached() {
        window.addEventListener('optimizedresize', this.setHeight);
        window.document.addEventListener('pagecontentsizechange', this.setHeight);

        this.setHeight();
    }

    detached() {
        window.removeEventListener('optimizedresize', this.setHeight);
        window.document.removeEventListener('pagecontentsizechange', this.setHeight);
    }

    update() {
        setTimeout(() => this.setHeight());
    }
}
