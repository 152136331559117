import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { ManageTestMethodDataRow } from './manage-test-method-data-row';

@autoinject
export class TestMethodManagementService {
    private baseRoute: string;
    constructor(private httpClient: HttpClient) {
        this.baseRoute = 'test-method-management/organization';
    }

    fetchOrganizationTestMethods(organizationId: number): Promise<ManageTestMethodDataRow[]> {
        return this.httpClient.get(`${this.baseRoute}/${organizationId}`) as unknown as Promise<
            ManageTestMethodDataRow[]
        >;
    }

    hideOrganizationTestMethods(organizationId: number, organizationTestMethodIds: number[]) {
        return this.httpClient.put(
            `${this.baseRoute}/${organizationId}/bulk-hide`,
            organizationTestMethodIds,
        );
    }

    showOrganizationTestMethods(organizationId: number, organizationTestMethodIds: number[]) {
        return this.httpClient.put(
            `${this.baseRoute}/${organizationId}/bulk-show`,
            organizationTestMethodIds,
        );
    }
}
