import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { ValidationController, ValidationRules } from 'aurelia-validation';
import SelectorOption from 'infrastructure/selector-option';
import TestMethod from 'test-methods/test-method';
import TestMethodService from '../../../test-methods/test-method-service';
import SecurityService from 'security/security-service';
import { testMethodNameFormatting } from 'test-methods/test-method-helper';

@autoinject
export class TestMethodsSelectorCustomElement {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) testMethods: TestMethod[];
    @bindable labId: number;
    @bindable organizationId: number;
    @bindable labExternalSource: string;
    @bindable editable: number;
    testMethodOptions: SelectorOption[];

    constructor(
        private readonly testMethodService: TestMethodService,
        private readonly securityService: SecurityService,
        private readonly validationController: ValidationController,
    ) {
        this.labId = 0;
    }

    private async getTestMethods() {
        if (this.labId === 0) {
            return;
        }

        const testMethods = await this.testMethodService.getTestMethods(this.organizationId, {
            externalSource: this.labExternalSource,
        });

        this.testMethodOptions = testMethods.map((tm) => {
            tm.title = testMethodNameFormatting(
                tm,
                this.securityService.isImpersonating(),
                this.securityService.isWorkingUnderOrganization(),
            );
            return tm;
        });
    }

    async labIdChanged() {
        await this.getTestMethods();
    }

    async bind() {
        await this.getTestMethods();
        ValidationRules.ensure((m: this) => m.testMethods)
            .minItems(1)
            .on(this);
    }
}
