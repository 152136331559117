import { inject, bindable, bindingMode } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

@inject(Element, EventAggregator)
export class OrganizationFilter {
    @bindable organizations;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedOrganizations;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) isFiltering;

    constructor(element, eventAggregator) {
        this.element = element;
        this.eventAggregator = eventAggregator;
        this.selectedOrganizations = [];
        this.isFiltering = false;
        this.clearSelectedOrganizations = this.clearSelectedOrganizations.bind(this);
    }

    selectedOrganizationsChanged() {
        this.isFiltering = !!this.selectedOrganizations.length;
    }

    clearSelectedOrganizations() {       
        this.selectedOrganizations = [];
    }

    attached() {
        this.clearFilterSubscription = this.eventAggregator.subscribe('filters.clear', this.clearSelectedOrganizations);
    }
    
    detached() {
        this.clearFilterSubscription && this.clearFilterSubscription.dispose();
    }
};
