import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import SelectorOption from 'infrastructure/selector-option';
import RecordStatus, { getRecordStatusOptions } from 'record-status';
import CompareUtility from 'infrastructure/compare-utility';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import SecurityService from 'security/security-service';
import UserService from 'users/user-service';
import LabelTemplateType from './label-template-type';
import UiLabelTemplate from './ui-label-template';
import LabelTemplateService from './label-template-service';

@autoinject
export class LabelTemplateList {
    @observable filterText;

    // filters
    applyFiltersSubscription: Subscription;
    clearFiltersSubscription: Subscription;

    recordStatusOptions: SelectorOption[];
    selectedRecordStatus: RecordStatus;

    gridOptions;
    labelTemplateTypeOptions: SelectorOption[];
    allSelected: boolean;
    selectedActiveLabelTemplates: any[];
    canEditLabelTemplates: boolean;
    selectedLabelTemplateTypes: string[] = [];
    uiLabelTemplates: UiLabelTemplate[];
    uiLabelTemplatesView: any[];
    organizations: any[];
    selectedOrganizations: any[] = [];
    filterCount: number;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private labelTemplateService: LabelTemplateService,
        private userService: UserService
    ) {
        this.logger.name = 'label-template-list';

        this.filterText = '';

        this.gridOptions = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template: '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate: '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: 'Type',
                    field: 'typeCaption',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 100
                },
                {
                    suppressMenu: true,
                    headerName: 'Size',
                    field: 'sizeCaption',
                    comparator: CompareUtility.compareStringsInsensitive,
                    width: 100
                },
                {
                    suppressMenu: true,
                    headerName: 'Configured For',
                    field: 'configuredForCaption',
                    template: '<span title="${data.configuredForHoverCaption}">${data.configuredForCaption}</span>',
                    comparator: CompareUtility.compareStringsInsensitive,
                },
                {
                    suppressMenu: true,
                    headerName: 'Active',
                    field: 'isActive',
                    template: '<i class="fa fa-check" if.bind="data.isActive"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button click.delegate="navigateToLabelTemplateDetail(data.id)">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                        <button if.bind="canEditLabelTemplates" click.trigger="deleteLabelTemplate(data)" disabled.bind="!data.isActive">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerClass: 'row-actions',
                    width: 150,
                    sortable: false,
                    suppressSizeToFit: true
                },
            ],
            defaultColDef: {
                sortable: true
            }
        };

        this.allSelected = false;
        this.selectedActiveLabelTemplates = [];
        this.selectedRecordStatus = RecordStatus.ACTIVE;
        this.canEditLabelTemplates = this.securityService.hasPermission('EditLabelTemplates') && !this.securityService.isImpersonating();
        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
    }

    navigateToLabelTemplateDetail(id) {
        this.router.navigateToRoute('label-template-detail', { id });
    }

    isSelectedChanged() {
        this.allSelected = this.uiLabelTemplatesView.every(t => t.isSelected);
        this.setSelectedActiveLabelTemplates();
    }

    allSelectedClicked() {
        if (this.uiLabelTemplatesView) {
            this.allSelected = !this.uiLabelTemplatesView.every(t => t.isSelected);

            for (let t of this.uiLabelTemplatesView)
                t.isSelected = this.allSelected;
        }

        this.setSelectedActiveLabelTemplates();

        return true;
    }

    setSelectedActiveLabelTemplates() {
        this.selectedActiveLabelTemplates = this.uiLabelTemplatesView.filter(t => t.isSelected && t.isActive);
    }

    filterTextChanged() {
        this.updateLabelTemplatesView();
    }

    handleApplyFilters() {
        this.updateLabelTemplatesView();
    }

    handleClearFilters() {
        this.selectedLabelTemplateTypes = [];
        this.selectedRecordStatus = RecordStatus.ACTIVE;
        this.updateLabelTemplatesView();
    }

    updateLabelTemplatesView() {
        var lowerCasedFilterText = this.filterText.toLowerCase();

        if (this.uiLabelTemplates) {
            this.uiLabelTemplatesView = this.uiLabelTemplates.filter(t =>
                this.matchSelectedLabelTemplateTypes(t) &&
                (t.configuredForCaption || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 &&
                this.matchSelectedRecordStatus(t) &&
                (this.selectedOrganizations.length === 0 || this.selectedOrganizations.some(o => t.organizationConfigurations.some(c => o.id === c.organizationId)))
            );

            this.allSelected = this.uiLabelTemplatesView.every(t => t.isSelected);
        }

        this.filterCount =
            (this.selectedLabelTemplateTypes.length != 0 ? 1 : 0) +
            (this.selectedOrganizations.length != 0 ? 1 : 0) +
            (this.selectedRecordStatus !== RecordStatus.ACTIVE ? 1 : 0);
    }

    matchSelectedLabelTemplateTypes(uiLabelTemplate: UiLabelTemplate) {
        if (!this.selectedLabelTemplateTypes || !this.selectedLabelTemplateTypes.length)
            return true;

        return this.selectedLabelTemplateTypes.some(type => type === uiLabelTemplate.type);
    }

    matchSelectedRecordStatus(uiLabelTemplate: UiLabelTemplate) {
        if (this.selectedRecordStatus === RecordStatus.ALL)
            return true;

        let filteredIsActiveValue = this.selectedRecordStatus === RecordStatus.ACTIVE;
        return uiLabelTemplate.isActive === filteredIsActiveValue;
    }

    activate(params) {
        this.applyFiltersSubscription = this.eventAggregator.subscribe('filters.apply', this.handleApplyFilters);
        this.clearFiltersSubscription = this.eventAggregator.subscribe('filters.clear', this.handleClearFilters);

        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.recordStatusOptions = getRecordStatusOptions();
                this.labelTemplateTypeOptions = LabelTemplateType.getOptions();

                let [labelTemplates, organizations] = await Promise.all([
                    this.labelTemplateService.getLabelTemplates(),
                    this.userService.getCurrentUserOrganizations()
                ]);

                this.organizations = organizations;
                this.uiLabelTemplates = labelTemplates.map(t => UiLabelTemplate.fromModel(t, organizations));
                this.updateLabelTemplatesView();
                this.pageContext.isLoading = false;
            } catch (error) {
                this.logger.error('Error loading label templates.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Label Templates',
                    'An error occurred while loading the label templates. Please try again later.');
            }
        })();
    }

    deactivate() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
        this.clearFiltersSubscription && this.clearFiltersSubscription.dispose();
    }

    async deleteLabelTemplate(labelTemplate) {
        let confirmed = await this.dialogPresenter.showConfirmation('Delete Label Template', 'Are you sure you want to delete this label template?')
        if (!confirmed)
            return;

        this.pageContext.isLoading = true;

        try {
            await this.labelTemplateService.deleteLabelTemplates([labelTemplate.id]);

            this.pageContext.showSuccessOverlay('Label template deleted successfully.');

            labelTemplate.isSelected = false;
            labelTemplate.isActive = false;

            this.setSelectedActiveLabelTemplates();
        } catch (error) {
            this.logger.error('Error deleting label template.', error, { labelTemplateId: labelTemplate.id });
            this.handleDeleteError(error, false);
        }

        this.pageContext.isLoading = false;
    }

    async deleteSelectedLabelTemplates() {
        let labelTemplatesToDelete = this.uiLabelTemplatesView.filter(t => t.isSelected);
        if (!labelTemplatesToDelete.length)
            return;

        let confirmed = await this.dialogPresenter.showConfirmation('Delete Selected Label Templates', 'Are you sure you want to delete the selected label templates?');
        if (!confirmed)
            return;

        var labelTemplateIds = labelTemplatesToDelete.map(t => t.id);

        this.pageContext.isLoading = true;

        try {
            await this.labelTemplateService.deleteLabelTemplates(labelTemplateIds);
            this.pageContext.showSuccessOverlay('Selected label templates deleted successfully.');

            for (let t of labelTemplatesToDelete) {
                t.isSelected = false;
                t.isActive = false;

                this.setSelectedActiveLabelTemplates();
            }

            this.updateLabelTemplatesView();
        } catch (error) {
            this.logger.error('Error deleting label templates.', error, { labelTemplateIds });
            this.handleDeleteError(error, true);
        }

        this.pageContext.isLoading = false;
    }

    handleDeleteError(error, multiple) {
        this.dialogPresenter
            .showAlert(
                'Error Deleting Label Template' + (multiple ? 's' : ''),
                this.getApiErrorMessage(error.apiErrorCode, multiple));
    }

    getApiErrorMessage(errorCode, multiple) {
        if (errorCode === 2)
            return 'You dont have access to delete the label template' + (multiple ? 's' : '');

        return 'An unexpected error occurred while trying to delete label templates. Please try again later.';
    }
}
