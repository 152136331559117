import {
    PagedListQuery,
    PagedListQueryFilters,
} from 'infrastructure/components/query-filters/paged-list-query-filter';
import { BaseQueryFilter } from '../infrastructure/components/query-filters/base-query-filter';
import {
    DateRangeQuery,
    DateRangeQueryFilter,
} from '../infrastructure/components/query-filters/date-range-query-filter';
import { QueryFiltered } from '../infrastructure/components/query-filters/query-filtered';
import OutcomeStatus from './outcome-status';
import { OutcomeStatusQuery, OutcomeStatusQueryFilters } from './outcome-status-query-filter';

export interface TestResultListQuery {
    dateRangeOption?: string;
    targetStartDate: string;
    targetEndDate: string;
    dateRangeOverride?: boolean;
    enableTimeFilter?: boolean;
    outcomeStatus?: OutcomeStatus;
    pointZones?: number[];
    pageSize?: number;
    pageNumber?: number;
    sortBy?: string;
    sortDirection?: string;
}

/* eslint-disable brace-style */
export class TestResultsQueryFilters
    extends BaseQueryFilter
    implements QueryFiltered<TestResultListQuery>
{
    private _filterCaption: string;
    dateRangeQueryFilters: DateRangeQueryFilter;
    pagedListQueryFilters: PagedListQueryFilters;
    outcomeStatusQueryFilters: OutcomeStatusQueryFilters;
    pointZones?: number[];

    constructor() {
        super();

        this.dateRangeQueryFilters = new DateRangeQueryFilter();
        this.pagedListQueryFilters = new PagedListQueryFilters();
        this.outcomeStatusQueryFilters = new OutcomeStatusQueryFilters();
        this.pointZones = [];
    }

    public get count(): number {
        return (
            0 +
            this.outcomeStatusQueryFilters.count +
            Number(!!(this.pointZones && this.pointZones.length))
        );
    }

    public get filterCaption(): string {
        return this._filterCaption;
    }

    reset(): void {
        this.dateRangeQueryFilters.reset();
        this.pagedListQueryFilters.reset();
        this.outcomeStatusQueryFilters.reset();

        this.pointZones = [];
    }

    getQueryParams(): TestResultListQuery {
        return {
            ...this.dateRangeQueryFilters.getQueryParams(),
            ...this.outcomeStatusQueryFilters.getQueryParams(),
            ...this.pagedListQueryFilters.getQueryParams(),
            pointZones: this.pointZones,
        };
    }

    setFilterValues(params: TestResultListQuery): void {
        const dateRangeQuery: DateRangeQuery = {
            dateRangeOverride: params.dateRangeOverride,
            dateRangeOption: params.dateRangeOption,
            targetStartDate: params.targetStartDate,
            targetEndDate: params.targetEndDate,
            enableTimeFilter: params.enableTimeFilter,
        };
        const pagedListQuery: PagedListQuery = {
            pageSize: params.pageSize,
            pageNumber: params.pageNumber,
            sortBy: params.sortBy,
            sortDirection: params.sortDirection,
        };
        const outcomeStatusQuery: OutcomeStatusQuery = { outcomeStatus: params.outcomeStatus };
        const { pointZones } = { ...params };

        this.dateRangeQueryFilters.setFilterValues(dateRangeQuery);
        this.pagedListQueryFilters.setFilterValues(pagedListQuery);
        this.outcomeStatusQueryFilters.setFilterValues(outcomeStatusQuery);

        super.setQueryFilteredValues({ pointZones }, this);

        this._filterCaption = super.getFilterCaption(
            this.count,
            'Target Date Range',
            this.dateRangeQueryFilters.dateRangeCaption,
        );
    }
}
