import CollectionType from 'location-testing/collection-type';
import { BaseQueryFilter } from './base-query-filter';
import { QueryFiltered } from './query-filtered';

export interface CollectionTypeQuery {
    collectionTypes: CollectionType[];
}

/* eslint-disable brace-style */
export class CollectionTypesQueryFilters
    extends BaseQueryFilter
    implements QueryFiltered<CollectionTypeQuery>
{
    collectionTypes: CollectionType[];

    constructor() {
        super();
        this.collectionTypes = [];
    }

    public get count() {
        return Number(!!this.collectionTypes?.length);
    }

    reset(): void {
        this.collectionTypes = [];
    }

    getQueryParams(): CollectionTypeQuery {
        return {
            collectionTypes: this.collectionTypes,
        };
    }

    setFilterValues(params: CollectionTypeQuery): void {
        if (!params) {
            return;
        }

        const { collectionTypes } = params;

        let collectionTypesArr: CollectionType[];
        if (collectionTypes) {
            collectionTypesArr = Array.isArray(collectionTypes)
                ? collectionTypes
                : [collectionTypes];
        }

        this.collectionTypes = collectionTypesArr;
    }
}
