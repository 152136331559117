import { autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import CompareUtility from '../../infrastructure/compare-utility';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import SecurityService from '../../security/security-service';
import AdhocReportService from '../adhoc-reports/adhoc-report-service';
import ReportService from './report-service';

@autoinject
export class ReportList {
    @observable filterText;

    reports: any[];
    reportsView: any[];

    gridOptions: any;

    canEditReports: boolean;

    constructor(
        private router: Router,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private reportService: ReportService,
        private adhocReportService: AdhocReportService
    ) {
        this.logger.name = 'report-list';

        this.filterText = '';
        this.reportsView = [];

        // The permission on the server is RunAdhocReports which effectively is treated as EditReports
        // Will update this at a later time as a user could have cached version or permissions.
        this.canEditReports = this.securityService.hasPermission('RunAdhocReports');

        this.gridOptions = {
            columnDefs: [
                { suppressMenu: true, headerName: 'Report Name', field: 'name', comparator: CompareUtility.compareStringsInsensitive, sort: 'asc' },
                { suppressMenu: true, headerName: 'Report Type', field: 'type', comparator: CompareUtility.compareStringsInsensitive, width: 150 },
                {
                    suppressMenu: true,
                    headerName: 'Subscription',
                    field: 'hasSubscription',
                    template: '<i class="fa fa-check" if.bind="data.hasSubscription"></i>',
                    width: 120,
                    headerClass: 'text-center',
                    cellClass: 'medium-text-center',
                    suppressSizeToFit: true
                },
                {
                    suppressMenu: true,
                    headerName: '',
                    template: `
                        <button if.bind="(!data.isAdhocReport || canEditReports)" click.trigger="navigateToReportDetail(data)">
                            <i class="fa fa-arrow-circle-right"></i>
                        </button>
                        <button if.bind="data.isAdhocReport && canEditReports" click.trigger="deleteAdhocReport(data)">
                            <i class="fa fa-trash-o"></i>
                        </button>`,
                    cellClass: 'medium-text-right row-actions',
                    headerCellTemplate: '',
                    headerClass: 'row-actions',
                    width: 120,
                    sortable: false,
                    suppressSizeToFit: true
                }
            ],
            defaultColDef: { sortable: true, resizable: true },
        };
    }

    activate() {
        (async () => {

            this.pageContext.isLoading = true;
            try {
                var results = await Promise
                    .all([
                        this.reportService.getReports() as unknown as any[],
                        this.adhocReportService.getCurrentUserAdhocReports() as unknown as any[]
                    ]);

                for (let adhocReport of results[0])
                    adhocReport.type = 'Built-in';

                for (let adhocReport of results[1]) {
                    adhocReport.type = 'Ad-hoc';
                    adhocReport.isAdhocReport = true;
                }

                this.reports = [
                    ...results[0].filter(r => r.isActive),
                    ...results[1].filter(r => r.isActive)
                ];

                this.updateView();
                this.pageContext.isLoading = false;

            } catch (error) {
                this.logger.error('Error loading reports.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Reports',
                    'An error occurred while loading the reports. Please try again later.');
            }
        })();
    }

    filterTextChanged() {
        this.updateView();
    }

    updateView() {
        if (!this.reports)
            return;

        var lowerCasedFilterText = this.filterText.toLowerCase();

        this.reportsView = this.reports.filter(v => (v.name || '').toLowerCase().indexOf(lowerCasedFilterText) > -1);
    }

    async deleteAdhocReport(adhocReport) {
        var confirmed = await this.dialogPresenter.showConfirmation('Delete Ad-hoc Report', 'Are you sure you want to delete this ad-hoc report?');
        if (!confirmed)
            return;

        this.pageContext.isLoading = true;

        try {
            await this.adhocReportService.deleteAdhocReports([adhocReport.id]);

            this.pageContext.isLoading = false;
            this.pageContext.showSuccessOverlay('Ad-hoc report deleted successfully.');

            let index = this.reports.indexOf(adhocReport);
            if (index !== -1)
                this.reports.splice(index, 1);

            this.updateView();
        } catch (error) {
            this.logger.error('Error deleting ad-hoc report.', error, { adhocReportId: adhocReport.id });
            this.dialogPresenter.showAlert(
                'Error Deleting Ad-hoc Report',
                'An unexpected error occurred while trying to the ad-hoc report. Please try again later.');
        }

        this.pageContext.isLoading = false;
    }
    
    navigateToReportDetail(report) {
        if (report.isAdhocReport)
            this.router.navigateToRoute('adhoc-report-detail', { id: report.id });
        else
            this.router.navigateToRoute('report-detail', { id: report.id });
    }
}
