import { inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import PageContext from 'infrastructure/page-context';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import LabService from './lab-service';
import SecurityService from '../security/security-service';

@inject(Router, PageContext, DialogPresenter, Logger, LabService, SecurityService)
export default class LabList {
    @observable filterText;

    isInternalView: boolean;
    labs: any;
    labsView: any;

    constructor(
        private router: Router,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private logger: Logger,
        private labService: LabService,
        private securityService: SecurityService
    ) {
        this.logger.name = 'lab-list';

        this.isInternalView = this.securityService.getCurrentSessionUser().isInternal && !this.securityService.isImpersonating();

        this.labService = labService;
        this.filterText = '';
    }

    navigateToLabDetail(id) {
        this.router.navigateToRoute('lab-detail', { id });
    }

    filterTextChanged() {
        this.updateLabsView();
    }

    updateLabsView() {
        var lowerCasedFilterText = this.filterText.toLowerCase();

        if (this.labs) {
            this.labsView = this.labs.filter(l =>
                (l.externalId || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (l.address1 || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (l.address2 || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (l.city || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (l.state || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (l.postalCode || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (l.phoneNumber || '').toLowerCase().indexOf(lowerCasedFilterText) > -1
            );
        }
    }

    activate() {
        (async () => {
            this.pageContext.isLoading = true;

            try {
                var labs = await this.labService.getAdminLabs();

                this.pageContext.isLoading = false;

                this.labs = labs;
                this.updateLabsView();
            } catch (error) {
                this.logger.error('Error loading labs.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Labs',
                    'An error occurred while loading the labs. Please try again later.');
            }
        })();
    }
}
