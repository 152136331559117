import { autoinject, buildQueryString } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import Organization from 'organizations/organization';

@autoinject
export default class UserService {
    constructor(private httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getUsers(search: string = null, organizationIds: number[] = []) {
        var query = buildQueryString({
            search,
            organizationIds
        }, true);

        return this.httpClient.get(`users?${query}`);
    }

    getUser(id: number) {
        return this.httpClient.get(`users/${id}`);
    }

    deleteUsers(ids: number[]) {
        var query = ids.map(id => `id=${id}`).join('&');
        return this.httpClient.delete(`users?${query}`);
    }

    getCurrentUserOrganizations(): Promise<Organization[]> {
        return this.httpClient.get('users/current/organizations') as any as Promise<Organization[]>;
    }

    saveUser(user: any) {
        if (!user.id)
            return this.httpClient.post('users/', user);

        return this.httpClient.put(`users/${user.id}`, user);
    }

    unlockUser(id: number) {
        return this.httpClient.patch(`users/${id}`, { isLocked: false });
    }

    bulkUpdateUsersRole(userRoles) {
        return this.httpClient.put('users/roles', userRoles);
    }

    getUserRecordChanges(id: number) {
        return this.httpClient.get(`users/${id}/record-changes`);
    }
}
