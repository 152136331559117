import { Grid } from 'ag-grid-enterprise/dist/ag-grid-enterprise';
import { autoinject } from 'aurelia-dependency-injection';
import { EventAggregator } from 'aurelia-event-aggregator';
import { observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { expandRowAtIndex } from 'infrastructure/ag-grid/ag-onfirstdatarendered-handler';
import UserService from 'users/user-service';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import Logger from '../../infrastructure/logger';
import PageContext from '../../infrastructure/page-context';
import SecurityService from '../../security/security-service';
import { getCapPlanGridOptions } from './cap-plan-grid';
import CapPlanService from './cap-plan-service';
import { CapPlanListItem } from './contracts/cap-plan-list-item.js';
import { UiCapPlanFilters } from './ui-cap-plan-filters';

@autoinject
export class CapPlanList {
    @observable filterText: string;

    gridOptions: any;
    allSelected: boolean;
    capPlans: CapPlanListItem[];
    capPlanFilter: UiCapPlanFilters;

    applyFiltersSubscription: any;
    clearFiltersSubscription: any;
    canEditCapPlans: boolean;
    isGridRowSelected: boolean;
    selectedCapPlans: number[];
    currentUser: any;
    capPlanGrid: any;

    constructor(
        private capPlanService: CapPlanService,
        private dialogPresenter: DialogPresenter,
        private eventAggregator: EventAggregator,
        private logger: Logger,
        private pageContext: PageContext,
        private router: Router,
        private securityService: SecurityService,
        private userService: UserService,
    ) {
        this.capPlanService = capPlanService;
        this.dialogPresenter = dialogPresenter;
        this.eventAggregator = eventAggregator;
        this.logger = logger;
        this.pageContext = pageContext;
        this.router = router;
        this.securityService = securityService;
        this.userService = userService;

        this.capPlanFilter = new UiCapPlanFilters();
        this.selectedCapPlans = [];

        this.handleApplyFilters = this.handleApplyFilters.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
        this.deleteSelectedCapPlans = this.deleteSelectedCapPlans.bind(this);
        this.canEditCapPlans =
            this.securityService.hasPermission('EditCapPlans') &&
            !this.securityService.isImpersonating();
    }

    filterTextChanged(newValue: string) {
        this.gridOptions.api.setQuickFilter(newValue);
    }

    async handleApplyFilters() {
        this.capPlanFilter.apply();
        await this.loadCapPlans();
    }

    async handleClearFilters() {
        this.capPlanFilter.clear();
        await this.loadCapPlans();
    }

    handleAGGridSelectionChanged(event: any) {
        const selectedNodes = event.api.getSelectedNodes();
        this.isGridRowSelected = selectedNodes.length > 0;
    }

    async loadCapPlans() {
        const capPlans = await this.capPlanService.getCapPlans(this.capPlanFilter.toModel());
        this.capPlans = capPlans;
        this.gridOptions?.api.setRowData(this.capPlans);
    }

    navigateToCapPlanDetail(tenantId: number, id: number) {
        this.router.navigateToRoute('cap-plan-detail', { tenantId, id });
    }

    navigateToCapPlanCreate() {
        this.router.navigateToRoute('cap-plan-detail', {
            tenantId: this.currentUser.organizationId,
        });
    }

    async deleteSelectedCapPlans() {
        const confirmed = await this.dialogPresenter.showConfirmation(
            'Delete CAP Plans',
            'Are you sure you want to delete these CAP plans?',
        );
        if (!confirmed) {
            return;
        }

        const ids = this.gridOptions.api.getSelectedNodes().map((rowNode) => rowNode.data.id);
        try {
            await this.capPlanService.bulkDeleteCapPlans(ids);

            const updatedCapPlanList = this.capPlans.filter(({ id }) => !ids.includes(id));
            this.capPlans = updatedCapPlanList;
            this.gridOptions.api.setRowData(this.capPlans);

            this.pageContext.showSuccessOverlay('Selected CAP plans deleted successfully.');
        } catch (error) {
            this.logger.error('Error deleting CAP plans', error, { ids });
            this.dialogPresenter.showAlert(
                'Error Deleting CAP Plans',
                'An error occured while deleting the CAP plans. Please try again later.',
            );
        }
    }

    async canActivate() {
        this.applyFiltersSubscription = this.eventAggregator.subscribe(
            'filters.apply',
            this.handleApplyFilters,
        );
        this.clearFiltersSubscription = this.eventAggregator.subscribe(
            'filters.clear',
            this.handleClearFilters,
        );
        try {
            const [currentUser, organizations, testMethods] = await Promise.all([
                this.securityService.getEffectiveUser(),
                this.userService.getCurrentUserOrganizations(),
                this.capPlanService.getTestMethods(),
            ]);

            this.currentUser = currentUser;
            this.capPlanFilter.organizations = organizations;
            this.capPlanFilter.testMethods = testMethods;
            await this.loadCapPlans();
        } catch (error) {
            this.logger.error('Error loading CAP Plans.', error);
            this.dialogPresenter.showAlert(
                'Error Loading CAP Plans',
                'An error occured while loading the CAP plans. Please try again later.',
            );
        }
    }

    bind() {
        this.gridOptions = getCapPlanGridOptions();
        this.gridOptions.rowData = this.capPlans;
        this.gridOptions.onSelectionChanged = this.handleAGGridSelectionChanged.bind(this);
        this.gridOptions.onFirstDataRendered = (params: any) => expandRowAtIndex(params, 1);
        new Grid(this.capPlanGrid, this.gridOptions);
    }

    deactivate() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
        this.clearFiltersSubscription && this.clearFiltersSubscription.dispose();
    }
}
