import { EventAggregator } from 'aurelia-event-aggregator';
import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import SecurityService from '../security/security-service';
import apiErrorsInterceptor from './api-errors/api-errors-interceptor';
import DialogPresenter from './dialogs/dialog-presenter';

@inject(HttpClient, SecurityService, EventAggregator, DialogPresenter)
export default class HttpClientConfigurator {
    constructor(httpClient, securityService, eventAggregator, dialogPresenter) {
        this.httpClient = httpClient;
        this.securityService = securityService;
        this.eventAggregator = eventAggregator;
        this.dialogPresenter = dialogPresenter;
    }

    checkClientVersion(message) {
        const clientVersion = message?.headers?.get('x-client-update-to');
        clientVersion
            ? this.eventAggregator.publish('client.refresh', { clientVersion })
            : this.eventAggregator.publish('client.isCurrent');

        return clientVersion;
    }

    configure() {
        this.httpClient.configure((config) =>
            config
                .withBaseUrl('/api/')
                .withHeader('X-Requested-With', 'XMLHttpRequest')
                .withHeader('X-Client-Version', portalClientVersion)
                .withInterceptor(apiErrorsInterceptor)
                .withInterceptor({
                    request: (message) => {
                        this.securityService.tryAddAuthentication(message);
                        this.securityService.tryAddImpersonation(message);
                        this.securityService.tryAddWorkingUnderOrganization(message);

                        return message;
                    },
                    response: (message) => {
                        this.securityService.tryUpdateAuthentication(message);

                        this.checkClientVersion(message);

                        if (message.response && message.content !== undefined)
                            return message.content;

                        return message;
                    },
                    responseError: async (message) => {
                        const clientVersion = this.checkClientVersion(message);

                        if (
                            clientVersion &&
                            clientVersion !== portalClientVersion &&
                            (await this.dialogPresenter.showClientVersionReloadDialog())
                        )
                            window.location.reload();

                        throw message;
                    },
                }),
        );
    }
}
