import { autoinject } from 'aurelia-framework';
import EntityViewModel from 'infrastructure/contracts/entity-view-model';
import CollectionType from 'location-testing/collection-type';
import TestMethod from 'test-methods/test-method';
import CapPlan from './contracts/cap-plan';

@autoinject
export class UiCapPlan implements EntityViewModel<CapPlan> {
    id: number;
    organizationId: number;
    name: string;
    collectionType: CollectionType;
    organizationName?: string;
    labId: number;
    testMethods: TestMethod[];
    testingDayCount: number;
    sampleDescriptions: string[];
    planId?: number;
    room?: string;
    taskOperationsTiming?: string;
    pointZone?: number;
    pointType?: string;
    autoRemediation: boolean;
    deactivateRoutineTaskGeneration: boolean;
    assignedUserId?: number;
    approvalUserId?: number;
    assignedUserFullName?: string;
    approvalUserFullName?: string;

    constructor(tenantId: number) {
        this.id = 0;
        this.organizationId = tenantId;
        this.sampleDescriptions = [];
        this.labId = 0;
        this.room = null;
        this.testMethods = [];
        this.testingDayCount = 0;
        this.autoRemediation = false;
        this.deactivateRoutineTaskGeneration = false;
    }

    toEntity(): CapPlan {
        return {
            id: this.id,
            organizationId: this.organizationId,
            name: this.name,
            collectionType: this.collectionType,
            labId: this.labId,
            testMethods: this.testMethods,
            testingDayCount: this.testingDayCount,
            sampleDescriptions: this.sampleDescriptions,
            planId: this.planId,
            room: this.room,
            taskOperationsTiming: this.taskOperationsTiming,
            pointZone: this.pointZone,
            pointType: this.pointType,
            autoRemediation: this.autoRemediation,
            deactivateRoutineTaskGeneration: this.deactivateRoutineTaskGeneration,
            autoRemediationAssignedUserId: this.assignedUserId,
            autoRemediationApprovalUserId: this.approvalUserId,
        };
    }

    loadViewFrom(entity: CapPlan): void {
        if (!entity) {
            return;
        }

        this.id = entity.id;
        this.organizationId = entity.organizationId;
        this.name = entity.name;
        this.collectionType = entity.collectionType;
        this.labId = entity.labId;
        this.testMethods = entity.testMethods;
        this.testingDayCount = entity.testingDayCount;
        this.sampleDescriptions = entity.sampleDescriptions;
        this.planId = entity.planId;
        this.room = entity.room;
        this.taskOperationsTiming = entity.taskOperationsTiming;
        this.pointZone = entity.pointZone;
        this.pointType = entity.pointType;
        this.autoRemediation = entity.autoRemediation;
        this.deactivateRoutineTaskGeneration = entity.deactivateRoutineTaskGeneration;
        this.assignedUserId = entity.autoRemediationAssignedUserId;
        this.approvalUserId = entity.autoRemediationApprovalUserId;
        this.assignedUserFullName = entity.autoRemediationAssignedUserFullName;
        this.approvalUserFullName = entity.autoRemediationApprovalUserFullName;
    }
}
