import { computedFrom } from 'aurelia-framework';
import TestMethodGroup from 'location-testing/test-method-group';
import { BaseQueryFilter } from './base-query-filter';
import { QueryFiltered } from './query-filtered';

export interface ConfirmationTestingQuery {
    testMethodGroup: TestMethodGroup;
    confirmationTestValues: string[];
}

/* eslint-disable brace-style */
export class ConfirmationTestingQueryFilters
    extends BaseQueryFilter
    implements QueryFiltered<ConfirmationTestingQuery>
{
    private _testMethodGroup: TestMethodGroup;
    private _confirmationTestValues: string[];

    constructor() {
        super();
        this._testMethodGroup = null;
        this._confirmationTestValues = [];
    }

    @computedFrom('_testMethodGroup')
    public get testMethodGroup() {
        return this._testMethodGroup;
    }

    public set testMethodGroup(tmg: TestMethodGroup) {
        this._testMethodGroup = tmg;
        this._confirmationTestValues = [];
    }

    public get count(): number {
        return (
            0 +
            Number(!!this.testMethodGroup) +
            Number(!!(this._confirmationTestValues && this._confirmationTestValues.length))
        );
    }

    reset(): void {
        this._testMethodGroup = null;
        this._confirmationTestValues = [];
    }

    getQueryParams(): ConfirmationTestingQuery {
        return {
            testMethodGroup: this._testMethodGroup,
            confirmationTestValues: this._confirmationTestValues,
        };
    }

    setFilterValues(params: ConfirmationTestingQuery): void {
        if (!params) {
            return;
        }

        super.setQueryFilteredValues(params, this);
    }
}
