import { UiViewModel } from 'infrastructure/ui-view-model';
import { CertificateContact } from './certificate-contact';

export class UiCertificateContact implements CertificateContact, UiViewModel {
    organizationCode: string;
    organizationKey: string;
    parentOrganizationKey: string;
    companyName: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phoneNumber: string;
    faxNumber: string;
    contactFullName: string;
    contactEmail: string;

    public get fullContact(): string {
        let contactParts: string[] = [];
        contactParts.push(this.companyName);
        if (this.contactFullName) {
            contactParts.push(`, ${this.contactFullName}`);
        }
        if (this.address && this.city && this.state && this.postalCode) {
            contactParts.push(`, ${this.address}`);
            contactParts.push(`, ${this.city}`);
            contactParts.push(`, ${this.state}`);
            contactParts.push(`, ${this.postalCode}`);
        }

        return contactParts.join('');
    }

    toEntity() {
        throw new Error('Method not implemented.');
    }

    loadViewFrom(entity: CertificateContact): void {
        this.organizationCode = entity.organizationCode;
        this.organizationKey = entity.organizationKey;
        this.parentOrganizationKey = entity.parentOrganizationKey;
        this.companyName = entity.companyName;
        this.address = entity.address;
        this.city = entity.city;
        this.state = entity.state;
        this.postalCode = entity.postalCode;
        this.country = entity.country;
        this.phoneNumber = entity.phoneNumber;
        this.faxNumber = entity.faxNumber;
        this.contactFullName = entity.contactFullName;
        this.contactEmail = entity.contactEmail;
    }
}
