import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import SelectorOption from 'infrastructure/selector-option';
import MapService from 'location-testing/maps/map-service';
import MapSummary from 'location-testing/maps/map-summary';
import MapSummaryConverter from 'location-testing/maps/map-summary-converter';
import PlanService from 'location-testing/plans/plan-service';
import PlanSummary from 'location-testing/plans/plan-summary';
import PlanSummaryConverter from 'location-testing/plans/plan-summary-converter';
import RemediationService from 'location-testing/remediations/remediation-service';
import RemediationStatus from 'location-testing/remediations/remediation-status';
import { TaskListQuery } from 'location-testing/tasks/task-list-query-filters';
import TaskService from 'location-testing/tasks/task-service';
import TaskStatus from 'location-testing/tasks/task-status';
import moment from 'moment';
import NewsItemService from 'news-items/news-item-service';
import Organization from 'organizations/organization';
import RequestService from 'requests/request-service';
import SecurityService from 'security/security-service';
import { StatusQuery } from 'status/status-query-filters';
import OutcomeStatus from 'test-results/outcome-status';
import { TestResultListQuery } from 'test-results/test-results-query-filters';
import TestService from 'tests/test-service';
import UserService from 'users/user-service';
import { DashboardQuery, DashboardQueryFilters } from './dashboard-query-filters';
import DashboardService from './dashboard-service';

type DashboardLinks = Record<string, string>;

interface DateRange {
    start: moment.Moment;
    exclusiveEnd: moment.Moment;
    title: string;
}

@autoinject
export class Dashboard {
    // filters
    dashboardQueryFilters: DashboardQueryFilters;
    mapSelectorOptions: SelectorOption[];
    planSelectorOptions: SelectorOption[];

    // controls
    filterAccordion: any;

    // subscriptions
    applyFiltersSubscription: Subscription;
    clearFiltersSubscription: Subscription;

    // permissions
    canViewPriceQuotes: boolean;
    canViewRequests: boolean;
    canViewResults: boolean;
    canViewAllTasks: boolean;
    canViewMyTasks: boolean;
    canViewAllRemediations: boolean;
    canViewMyRemediations: boolean;
    canViewMaps: boolean;
    canViewPlans: boolean;
    canFilter: boolean;

    //
    requestChartLinkUrl: string;
    requestChartData: any;
    resultChartLinkUrl: string;
    resultChartData: any;

    myTaskCountsToday: any;
    myTaskCountsWeek: any;
    myRemediationCounts: any;

    taskCountsToday: any;
    taskCountsWeek: any;
    criticaltaskCounts: any;
    remediationCounts: any;

    taskStatuses: TaskStatus[];
    remediationStatuses: RemediationStatus[];

    currentUser: any;
    newsItems: any;
    organizations: Organization[];
    dashboardLinks: DashboardLinks;

    statusQuery: StatusQuery;
    testResultListQuery: TestResultListQuery;

    constructor(
        private router: Router,
        private eventAggregator: EventAggregator,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private logger: Logger,
        private securityService: SecurityService,
        private newsItemService: NewsItemService,
        private userService: UserService,
        private requestService: RequestService,
        private testService: TestService,
        private dashboardService: DashboardService,
        private mapService: MapService,
        private planService: PlanService,
        private taskService: TaskService,
        private remediationService: RemediationService,
    ) {
        this.logger.name = 'dashboard';

        // Permissions
        this.canViewPriceQuotes = this.securityService.hasPermission('ViewPriceQuotes');
        this.canViewRequests = this.securityService.hasPermission('ViewRequests');
        this.canViewResults = this.securityService.hasPermission('ViewResults');
        this.canViewAllTasks = this.securityService.hasPermission('ViewAllTasks');
        this.canViewMyTasks = this.securityService.hasPermission('ViewMyTasks');
        this.canViewAllRemediations = this.securityService.hasPermission('ViewAllRemediations');
        this.canViewMyRemediations = this.securityService.hasPermission('ViewMyRemediations');
        this.canViewMaps = this.securityService.hasPermission('ViewMaps');
        this.canViewPlans = this.securityService.hasPermission('ViewPlans');
        this.canFilter = this.canViewRequests || this.canViewResults;

        this.applyFiltersSubscription = this.eventAggregator.subscribe('filters.apply', () =>
            this.handleApplyFilters(),
        );
        this.clearFiltersSubscription = this.eventAggregator.subscribe('filters.clear', () =>
            this.handleClearFilters(),
        );

        this.dashboardQueryFilters = new DashboardQueryFilters();
    }

    handleApplyFilters() {
        this.router.navigateToRoute('dashboard', this.dashboardQueryFilters.getQueryParams());
    }

    handleClearFilters() {
        this.dashboardQueryFilters.reset();
        this.router.navigateToRoute('dashboard', this.dashboardQueryFilters.getQueryParams());
    }

    async refreshWidgetData() {
        const now = new Date();
        const dashboardQuery = this.dashboardQueryFilters.getQueryParams();
        const todaysDashboardQuery = Object.assign({}, dashboardQuery);
        todaysDashboardQuery.dateRangeOption = 'custom';
        todaysDashboardQuery.targetStartDate =
            this.dashboardQueryFilters.dateRangeQueryFilters.formatDate(now);
        todaysDashboardQuery.targetEndDate =
            this.dashboardQueryFilters.dateRangeQueryFilters.formatDate(now, true);

        this.statusQuery = {
            organizationIds: dashboardQuery.organizationIds,
            dateRangeOption: dashboardQuery.dateRangeOption,
            targetStartDate: dashboardQuery.targetStartDate,
            targetEndDate: dashboardQuery.targetEndDate,
        };

        this.testResultListQuery = {
            outcomeStatus: OutcomeStatus.ALL,
            dateRangeOption: dashboardQuery.dateRangeOption,
            targetStartDate: dashboardQuery.targetStartDate,
            targetEndDate: dashboardQuery.targetEndDate,
        };

        await Promise.all([
            this.refreshRequestChartData(dashboardQuery),
            this.refreshResultChartData(dashboardQuery),
            this.refreshMyTaskData(todaysDashboardQuery, dashboardQuery),
            this.refreshTaskManagementData(todaysDashboardQuery, dashboardQuery),
            this.refreshMyRemediationData(dashboardQuery.organizationIds),
            this.refreshRemediationManagementData(dashboardQuery.organizationIds),
        ]);
    }

    generateStatusLinkUrl({ customerRequestStatus = null } = {}) {
        const statusQuery = this.statusQuery;
        statusQuery.customerRequestStatus = customerRequestStatus;

        return this.router.generate('status', statusQuery);
    }

    generateTestResultLinkUrl(outcome: OutcomeStatus) {
        const testResultQuery = this.testResultListQuery;
        testResultQuery.outcomeStatus = outcome;

        return this.router.generate('test-results-list', testResultQuery);
    }

    async refreshRequestChartData(dashboardQuery: DashboardQuery) {
        if (!this.canViewRequests) return;

        this.pageContext.isLoading = true;

        try {
            var requestStatusCounts = await this.requestService.getRequestStatusCounts(
                dashboardQuery,
            );

            var maxValue = requestStatusCounts.reduce(
                (sum, requestStatusCount) => sum + requestStatusCount.count,
                0,
            );
            var buildRequestStatusDatapoint = (requestStatus, label, dataType, backgroundColor) => {
                var requestStatusCount = requestStatusCounts.find(
                    (e) => e.requestStatus === requestStatus,
                );
                var value = requestStatusCount ? requestStatusCount.count : 0;
                var percent = maxValue === 0 ? 0 : value / maxValue;

                return {
                    value,
                    label,
                    dataType,
                    maxValue,
                    backgroundColor,
                    percent,
                    url: this.generateStatusLinkUrl({
                        customerRequestStatus: label,
                    }),
                };
            };

            this.requestChartLinkUrl = this.generateStatusLinkUrl();
            this.requestChartData = {
                maxValue,
                datapoints: [
                    buildRequestStatusDatapoint(
                        'Submitted',
                        'Submitted',
                        'submitted',
                        'rgba(171,171,173,.35)',
                    ),
                    buildRequestStatusDatapoint('Received', 'Received', 'received', '#cc2350'),
                    buildRequestStatusDatapoint(
                        'Processing',
                        'Processing',
                        'processing',
                        '#9fd0cb',
                    ),
                    buildRequestStatusDatapoint('Completed', 'Completed', 'completed', '#25d925'),
                ],
            };
        } catch (error) {
            this.logger.error('Error occurred while loading request chart data.', error);
            this.requestChartData = {
                errorMessage: 'Error loading chart data',
            };
        }

        this.pageContext.isLoading = false;
    }

    async refreshResultChartData(dashboardQuery: DashboardQuery) {
        if (!this.canViewResults) return;

        this.pageContext.isLoading = true;

        try {
            const { organizationIds, targetStartDate, targetEndDate } = dashboardQuery;
            var testResultCounts = await this.testService.getTestResultCounts({
                organizationIds,
                targetStartDate,
                targetEndDate,
            });

            var maxValue = testResultCounts.reduce(
                (sum, testResultCount) => sum + testResultCount.count,
                0,
            );
            var buildTestResultDatapoint = (passed, label, dataType, backgroundColor) => {
                var testResultCount = testResultCounts.find((e) => e.passed === passed);
                var value = testResultCount ? testResultCount.count : 0;
                var percent = maxValue === 0 ? 0 : value / maxValue;
                return {
                    value,
                    label,
                    dataType,
                    maxValue,
                    backgroundColor,
                    percent,
                    url: this.generateTestResultLinkUrl(label),
                };
            };

            this.resultChartLinkUrl = this.generateTestResultLinkUrl(OutcomeStatus.ALL);
            this.resultChartData = {
                maxValue,
                datapoints: [
                    buildTestResultDatapoint(null, 'Incomplete', 'submitted', '#79686d'),
                    buildTestResultDatapoint(true, 'Pass', 'negative', '#25d925'),
                    buildTestResultDatapoint(false, 'Fail', 'positive', '#c00'),
                ],
            };
        } catch (error) {
            this.logger.error('Error occurred while loading test result chart data.', error);
            this.resultChartData = { errorMessage: 'Error loading chart data' };
        }

        this.pageContext.isLoading = false;
    }

    async refreshMyTaskData(todaysDashboardQuery: DashboardQuery, dashboardQuery: DashboardQuery) {
        if (!this.canViewMyTasks) return;

        this.pageContext.isLoading = true;

        try {
            todaysDashboardQuery.onlyAssignedToMe = true;
            dashboardQuery.onlyAssignedToMe = true;
            const [taskCountsToday, taskCountsWeek] = await Promise.all([
                this.taskService.getTaskCounts(todaysDashboardQuery),
                this.taskService.getTaskCounts(dashboardQuery),
            ]);

            this.myTaskCountsToday = taskCountsToday;
            this.myTaskCountsWeek = taskCountsWeek;
        } catch (error) {
            this.logger.error('Error occurred while loading My Task widget data.', error);
        }

        this.pageContext.isLoading = false;
    }

    async refreshTaskManagementData(
        todaysDashboardQuery: DashboardQuery,
        dashboardQuery: DashboardQuery,
    ) {
        if (!this.canViewAllTasks) return;

        this.pageContext.isLoading = true;

        try {
            todaysDashboardQuery.onlyAssignedToMe = false;
            dashboardQuery.onlyAssignedToMe = false;
            const [taskCountsToday, taskCountsWeek, criticalTaskCounts] = await Promise.all([
                this.taskService.getTaskCounts(todaysDashboardQuery),
                this.taskService.getTaskCounts(dashboardQuery),
                this.taskService.getCriticalTaskCounts(dashboardQuery.organizationIds),
            ]);

            this.taskCountsToday = taskCountsToday;
            this.taskCountsWeek = taskCountsWeek;
            this.criticaltaskCounts = criticalTaskCounts;
        } catch (error) {
            this.logger.error('Error occurred while loading Task Management widget data.', error);
        }

        this.pageContext.isLoading = false;
    }

    async refreshMyRemediationData(organizationIds: number[]) {
        if (!this.canViewAllRemediations) return;

        this.pageContext.isLoading = true;

        try {
            this.myRemediationCounts = (await this.remediationService.getRemediationCounts(
                organizationIds,
                true,
            )) as unknown as number;
        } catch (error) {
            this.logger.error('Error occurred while loading My Remediations widget data.', error);
        }

        this.pageContext.isLoading = false;
    }

    async refreshRemediationManagementData(organizationIds: number[]) {
        if (!this.canViewAllRemediations) return;

        this.pageContext.isLoading = true;

        try {
            this.remediationCounts = (await this.remediationService.getRemediationCounts(
                organizationIds,
                false,
            )) as unknown as number;
        } catch (error) {
            this.logger.error(
                'Error occurred while loading Remediation Management widget data.',
                error,
            );
        }

        this.pageContext.isLoading = false;
    }

    activate(params: DashboardQuery) {
        // Short circuit the loading of the dashboard if the user is going to be redirect away.
        if (this.securityService.pendingRedirectAfterlogin) return;

        this.dashboardQueryFilters.setFilterValues(params);

        (async () => {
            this.pageContext.isLoading = true;

            try {
                const [currentUser, newsItems, currentUserOrganizations, maps, dashboardLinks] =
                    await Promise.all([
                        this.securityService.getEffectiveUser(),
                        this.newsItemService.getNewsItems({ forDashboard: true }),
                        this.canFilter
                            ? this.userService.getCurrentUserOrganizations()
                            : Promise.resolve([]),
                        this.canViewMaps ? this.mapService.getMaps([]) : [],
                        this.dashboardService.getDashboardLinks(),
                        this.refreshWidgetData(),
                    ]);

                this.taskStatuses = this.taskService.getTaskStatuses() as unknown as TaskStatus[];
                this.remediationStatuses =
                    this.remediationService.getRemediationStatuses() as unknown as RemediationStatus[];
                this.currentUser = currentUser;
                this.newsItems = newsItems;
                this.organizations = currentUserOrganizations;
                this.dashboardLinks = dashboardLinks as unknown as DashboardLinks;

                // grab data to drive the map/plan filter
                let mapSummaries = maps as unknown as MapSummary[];
                let mapIds = mapSummaries.map((m) => m.id);
                let planSummaries =
                    this.canViewPlans && mapIds.length > 0
                        ? ((await this.planService.getPlans(mapIds)) as unknown as PlanSummary[])
                        : [];

                this.mapSelectorOptions = MapSummaryConverter.toSelectorOptions(mapSummaries);
                this.planSelectorOptions = PlanSummaryConverter.toSelectorOptions(
                    planSummaries,
                    mapSummaries,
                );
            } catch (error) {
                this.logger.error('Error loading dashboard.', error);
                this.dialogPresenter.showAlert(
                    'Error Loading Dashboard',
                    'An error occurred while loading the dashbord. Please try again later.',
                );
            }

            this.pageContext.isLoading = false;
        })();
    }

    unbind() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
        this.clearFiltersSubscription && this.clearFiltersSubscription.dispose();
    }

    navigateToPricing() {
        if (!this.currentUser || !this.currentUser.organizationId) return;

        this.router.navigateToRoute('test-method-list', {
            id: this.currentUser.organizationId,
        });
    }

    private mapTaskListQuery(): TaskListQuery {
        const dashboardQuery = this.dashboardQueryFilters.getQueryParams();
        return {
            mapId: dashboardQuery.mapId,
            planId: dashboardQuery.planId,
            mapIds: dashboardQuery.mapIds,
            planIds: dashboardQuery.planIds,
            dateRangeOption: dashboardQuery.dateRangeOption,
            targetStartDate: dashboardQuery.targetStartDate,
            targetEndDate: dashboardQuery.targetEndDate,
        };
    }

    navigateToMyTodaysFilteredTasks(taskStatusCodes?: string[]) {
        const now = new Date();
        const taskListQuery = this.mapTaskListQuery();
        taskListQuery.dateRangeOverride = true;
        taskListQuery.dateRangeOption = 'custom';
        taskListQuery.targetStartDate =
            this.dashboardQueryFilters.dateRangeQueryFilters.formatDate(now);
        taskListQuery.targetEndDate = this.dashboardQueryFilters.dateRangeQueryFilters.formatDate(
            now,
            true,
        );
        taskListQuery.onlyAssignedToMe = true;

        if (taskStatusCodes && taskStatusCodes.length > 0) {
            taskListQuery.taskStatusIds = taskStatusCodes.map(
                (code) => this.taskStatuses.find((s) => s.code === code).id,
            );
        }

        this.router.navigateToRoute('task-list', taskListQuery);
    }

    navigateToMyFilteredTasks() {
        const taskListQuery = this.mapTaskListQuery();
        taskListQuery.onlyAssignedToMe = true;

        this.router.navigateToRoute('task-list', taskListQuery);
    }

    navigateToAllTodaysFilteredTasks(taskStatusCodes?: string[]) {
        const now = new Date();
        const taskListQuery = this.mapTaskListQuery();
        taskListQuery.dateRangeOverride = true;
        taskListQuery.dateRangeOption = 'custom';
        taskListQuery.targetStartDate =
            this.dashboardQueryFilters.dateRangeQueryFilters.formatDate(now);
        taskListQuery.targetEndDate = this.dashboardQueryFilters.dateRangeQueryFilters.formatDate(
            now,
            true,
        );
        taskListQuery.onlyAssignedToMe = false;

        if (taskStatusCodes && taskStatusCodes.length > 0) {
            taskListQuery.taskStatusIds = taskStatusCodes.map(
                (code) => this.taskStatuses.find((s) => s.code === code).id,
            );
        }

        this.router.navigateToRoute('task-list', taskListQuery);
    }

    navigateToAllFilteredTasks(isAssigned?: string) {
        const taskListQuery = this.mapTaskListQuery();
        taskListQuery.isAssigned = isAssigned;

        this.router.navigateToRoute('task-list', taskListQuery);
    }

    navigateToCriticalUnassignedFilteredTasksThroughToday() {
        const now = new Date();
        const taskListQuery = this.mapTaskListQuery();
        taskListQuery.dateRangeOverride = true;
        taskListQuery.dateRangeOption = 'custom';
        taskListQuery.targetStartDate = null;
        taskListQuery.targetEndDate = this.dashboardQueryFilters.dateRangeQueryFilters.formatDate(
            now,
            true,
        );

        this.router.navigateToRoute('task-list', taskListQuery);
    }

    navigateToCriticalOverdueFilteredTasksThroughNow() {
        const taskListQuery = this.mapTaskListQuery();
        taskListQuery.dateRangeOverride = true;
        taskListQuery.dateRangeOption = 'custom';
        taskListQuery.targetStartDate = null;
        taskListQuery.targetEndDate = moment().format();
        taskListQuery.enableTimeFilter = true;

        const criticalStatuses = this.taskStatuses.filter(
            (ts) => ts.code === 'NotStarted' || ts.code === 'InProgress',
        );
        taskListQuery.taskStatusIds = criticalStatuses.map((cs) => cs.id);

        this.router.navigateToRoute('task-list', taskListQuery);
    }

    navigateToRemediationList({
        onlyAssignedToMe = false,
        isAssigned = null,
        remediationStatusCodes = null,
    } = {}) {
        this.router.navigateToRoute('remediation-list', {
            dateRangeOption: 'custom',
            onlyAssignedToMe,
            isAssigned,
            remediationStatusIds: (remediationStatusCodes || []).map(
                (code) => this.remediationStatuses.find((s) => s.code === code).id,
            ),
        });
    }
}
