import { inject, bindable, observable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import Logger from 'infrastructure/logger';
import LabelTemplateService from 'label-templates/label-template-service';

@inject(DialogController, Element, Logger, LabelTemplateService)
export default class LabelSelector {
    @bindable selectedLabelTemplate;
    @observable searchText;

    constructor(controller, element, logger, labelTemplateService) {
        this.controller = controller;
        this.element = element;
        this.logger = logger;

        this.labelTemplateService = labelTemplateService;

        this.isLoading = true;
        this.labelTemplates = [];
    }

    async updateSearchResults() {
        this.selectedLabelTemplate = null;
        this.isLoading = true;

        try {
            if (!this.model.labelTemplateType)
                throw new Error('Cannot seach for label templates because label template type was not specified.');

            if (!this.model.organizationId)
                throw new Error('Cannot seach for label templates because an organization ID was not specified.');

            var labelTemplates = await this.labelTemplateService.getLabelTemplates({
                type: this.model.labelTemplateType,
                organizationIds: [this.model.organizationId],
                recordStatus: 'Active'
            }, 'Selector');

            let filteredLabelTemplates = labelTemplates.filter(lt => lt.organizationId === this.model.organizationId);
            filteredLabelTemplates.forEach(lt => {
                lt.displayName = `${lt.templateName} (${(lt.width/72).toFixed(2)} x ${(lt.height/72).toFixed(2)} in.)`;
            });

            this.labelTemplates = filteredLabelTemplates;
        } catch (error) {
            this.logger.error('Error searching for label templates.', error, { searchText: this.searchText });
        }

        this.isLoading = false;
    }

    async activate(model) {
        this.model = model;
        await this.updateSearchResults();
    }
}
