import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';

@inject(HttpClient)
export default class TestMethodService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getTestMethods(organizationIds, filters) {

        var queryParameters = Object.assign({}, filters, {
            organizationIds: Array.isArray(organizationIds) ?
                organizationIds :
                [organizationIds]
        });

        var query = buildQueryString(queryParameters, true);

        return this
            .httpClient
            .get(`test-methods${(query ? `?${query}` : '')}`);
    }
}
