import GenerateTasksPointZoneDistributionMethod from './generate-tasks-point-zone-distribution-method';
import TaskGenerationRequest, { TaskGenerationZoneDistribution, TaskRequestPriority } from './task-generation-request';
import GenerateTasksDateSelectionType  from './generate-task-date-selection-type';

export default class TaskGenerationClientSettings {
    pointZoneDistributionMethod: GenerateTasksPointZoneDistributionMethod;
    pointZoneDistributions: TaskGenerationZoneDistribution[];
    riskPriorities?: TaskRequestPriority[];
    requestedTaskCount: number;
    dateSelectionType: GenerateTasksDateSelectionType;


    static fromTaskGenerationRequest(request: TaskGenerationRequest, dateSelectionType: GenerateTasksDateSelectionType) {
        let clientSettings = new TaskGenerationClientSettings();

        clientSettings.pointZoneDistributionMethod = request.pointZoneDistributionMethod;
        clientSettings.pointZoneDistributions = request.pointZoneDistributions;
        clientSettings.riskPriorities = request.riskPriorities;
        clientSettings.requestedTaskCount = request.requestedTaskCount;
        clientSettings.dateSelectionType = dateSelectionType;

        return clientSettings
    }
}
