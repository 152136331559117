enum RecordStatus {
    ALL = 'Any',
    ACTIVE = 'Active',
    DELETED = 'Deleted'
}

export default RecordStatus;

export function getRecordStatusOptions() {
    return [
        { value: RecordStatus.ALL, title: 'All' },
        { value: RecordStatus.ACTIVE, title: 'Active Only' },
        { value: RecordStatus.DELETED, title: 'Deleted Only' }
    ];
}
