import { ICellRendererParams } from 'ag-grid-community';
import { Container, ViewCompiler } from 'aurelia-framework';

export function renderAgGridCellForAurelia(params: ICellRendererParams): HTMLElement {
    const { template, data } = Object(params.value);
    if (!data) {
        return;
    }

    const aureliaTemplate = `<template>${template}</template>`;
    const container = Container.instance;
    const viewCompiler = container.get(ViewCompiler);
    const viewFactory = viewCompiler.compile(aureliaTemplate);
    const view = viewFactory.create(container);
    const rootElement = document.createElement('div');
    view.bind(params.value);
    view.appendNodesTo(rootElement);

    return rootElement;
}
