import { ValidationController } from 'aurelia-validation';
import $ from 'jquery';

export default class ExcelCellEditor {
    init(params) {
        let gridOptions = params.api.gridOptionsWrapper.gridOptions;

        this.overrideContext = gridOptions.overrideContext;

        this.rootElement = document.createElement('div');
        this.rootElement.style.width = '100%';

        let viewFactory = gridOptions.viewCompiler.compile(
            `<template>${params.editTemplate}</template>`,
        );
        this.view = viewFactory.create(gridOptions.container);
        this.validationController = gridOptions.container.get(ValidationController);

        this.refresh(params);
        this.initialized = true;
    }

    getGui() {
        return this.rootElement;
    }

    // HACK: On init if a key was pressed the element is not yet bound and the first key stroke is lost.
    //       This only gets called on init so if input is the type in the cell editor then set it's value to the key.
    //       Everything else goes on as before.
    // NOTE: It is possible to use the built in (ag-grid) cell editor and enter (down) functionality and that works.
    //       However, if you want to use Aurelia validation controller it gets messy fast. You were warned.
    setInititalKeyStrokeValueToInputIfExists(params) {
        const { charPress } = params;
        if (!charPress) {
            return;
        }

        const editorElement = this.getEditorElement();
        if (editorElement.nodeName !== 'INPUT') {
            return;
        }

        editorElement.value = charPress;
    }

    refresh(params) {
        if (this.initialized) return;

        this.view.bind(params, this.overrideContext);
        this.view.appendNodesTo(this.rootElement);
        this.view.attached();

        this.setInititalKeyStrokeValueToInputIfExists(params);
    }

    isCancelBeforeStart() {
        return false;
    }

    isCancelAfterEnd() {
        // HACK: Ensure the datepicker value updates its outer value binding before being destroyed after editing ends.
        let datepickerElement = this.rootElement.querySelector('datepicker');
        if (datepickerElement) datepickerElement.au.controller.viewModel.updateValue();

        return false;
    }

    destroy() {
        // HACK: Store whether the value is valid in order for the validation renderer to
        //       show the appropriate UI when switching to the readonly view.
        this.rootElement.parentElement.__valid = this.checkValid();

        // HACK: The double queuing described below in afterGuiAttached calls open()
        //       on the select2 element.  If the user did not select an option and
        //       and clicked on another cell this element would lock the UI scrolling.
        //       Calling close() before unbinding and destroy seems to release the
        //       element and allow the UI to resume.
        // TODO: Use the stock beta version and move away from all this craziness.
        let select2SelectElement = this.rootElement.querySelector('select2 select');
        if (select2SelectElement) {
            $(select2SelectElement).data('select2').close();
        }

        this.view.unbind();
        this.view.removeNodes();
        this.view.detached();
    }

    checkValid() {
        var editorElement = this.getEditorElement();
        if (!editorElement) return;

        var validationController = this.validationController;
        if (!validationController) return true;

        var validationControllerElements = Array.from(validationController.elements.entries());
        return validationControllerElements
            .filter((e) => e[1].some((ee) => ee === editorElement))
            .every((e) => e[0].valid);
    }

    getEditorElement() {
        let select2Element = this.rootElement.querySelector('select2');
        if (select2Element) return select2Element;

        let datepickerElement = this.rootElement.querySelector('datepicker');
        if (datepickerElement) return datepickerElement;

        let inputElement = this.rootElement.querySelector('input');
        if (inputElement) return inputElement;

        return null;
    }

    afterGuiAttached() {
        let select2SelectElement = this.rootElement.querySelector('select2 select');
        if (select2SelectElement) {
            // Double queuing required because the way select2 component parses the item template and sets
            // the property. It happens over the course of two queued operations.
            // See comment above on destroy()
            setTimeout(() => {
                setTimeout(() => {
                    $(select2SelectElement).data('select2').open();
                });
            });
            return;
        }

        let datepickerElement = this.rootElement.querySelector('datepicker');
        if (datepickerElement) {
            datepickerElement.au.controller.viewModel.show();
            datepickerElement.querySelector('input').focus();
            return;
        }

        let inputElement = this.rootElement.querySelector('input');
        if (inputElement) {
            inputElement.focus();
            return;
        }
    }

    getValue() {
        let select2Element = this.rootElement.querySelector('select2');
        if (select2Element) return select2Element.au.controller.viewModel.selectedValue;

        let datepickerElement = this.rootElement.querySelector('datepicker');
        if (datepickerElement) return datepickerElement.au.controller.viewModel.value;

        let inputElement = this.rootElement.querySelector('input');
        return inputElement?.value;
    }
}
