import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@inject(HttpClient)
export default class PasswordRequirementsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getRequirements() {
        return this.httpClient.get('password-requirements/current');
    }

    saveRequirements(requirements) {
        return this.httpClient.post('password-requirements/current', requirements);
    }
}
