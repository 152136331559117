import { BaseQueryFilter } from 'infrastructure/components/query-filters/base-query-filter';
import { DateRangeQuery, DateRangeQueryFilter } from 'infrastructure/components/query-filters/date-range-query-filter';
import { OrganizationsQuery, OrganizationsQueryFilters } from 'infrastructure/components/query-filters/organizations-query-filter';
import { QueryFiltered } from 'infrastructure/components/query-filters/query-filtered';

export interface StatusQuery {
    organizationIds: number[];
    dateRangeOption?: string;
    targetStartDate: string;
    targetEndDate: string;
    dateRangeOverride?: boolean;
    requestId?: string;
    sampleId?: string;
    poNumber?: string;
    description?: string;
    format?: string;
    customerRequestStatus?: string;
}

export class StatusQueryFilters extends BaseQueryFilter
    implements QueryFiltered<StatusQuery> {

    private _filterCaption: string;
    organizationQueryFilters: OrganizationsQueryFilters;
    dateRangeQueryFilters: DateRangeQueryFilter;
    requestId: string;
    sampleId: string;
    poNumber: string;
    description: string;
    format: string;
    customerRequestStatus: string;

    constructor() {
        super();

        this.organizationQueryFilters = new OrganizationsQueryFilters();
        this.dateRangeQueryFilters = new DateRangeQueryFilter();
    }

    public get count(): number {
        return 0 +
            this.organizationQueryFilters.count +
            (Number((!!this.requestId && this.requestId.length > 0))) +
            (Number(!!(this.sampleId && this.sampleId.length > 0))) +
            (Number(!!(this.poNumber && this.poNumber.length > 0))) +
            (Number(!!(this.description && this.description.length > 0))
            );
    }
    public get filterCaption(): string {
        return this._filterCaption;
    }

    reset(): void {
        this.organizationQueryFilters.reset();
        this.dateRangeQueryFilters.reset();
        this.requestId = null;
        this.sampleId = null;
        this.poNumber = null;
        this.description = null;
    }

    getQueryParams(): StatusQuery {
        return {
            ...this.organizationQueryFilters.getQueryParams(),
            ...this.dateRangeQueryFilters.getQueryParams(),
            requestId: this.requestId,
            sampleId: this.sampleId,
            poNumber: this.poNumber,
            description: this.description,
            format: this.format,
            customerRequestStatus: this.customerRequestStatus,
        };
    }

    setFilterValues(params: StatusQuery): void {
        const dateRangeQuery: DateRangeQuery = {
            dateRangeOption: params.dateRangeOption,
            targetStartDate: params.targetStartDate,
            targetEndDate: params.targetEndDate,
            dateRangeOverride: params.dateRangeOverride,
        };
        const organizationsQuery: OrganizationsQuery = { organizationIds: params.organizationIds };
        const {
            requestId,
            sampleId,
            poNumber,
            description,
        } = { ...params };

        this.organizationQueryFilters.setFilterValues(organizationsQuery);
        this.dateRangeQueryFilters.setFilterValues(dateRangeQuery);

        super.setQueryFilteredValues({
            requestId,
            sampleId,
            poNumber,
            description
        }, this);

        this._filterCaption = super.getFilterCaption(
            this.count,
            'Request Creation Date Range',
            this.dateRangeQueryFilters.dateRangeCaption);
    }
}
