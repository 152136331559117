import { ChartRef, ChartType, CreateRangeChartParams, GridOptions } from 'ag-grid-community';
import { bindable, bindingMode } from 'aurelia-framework';
import { setGroupByColumnDefinitions } from 'infrastructure/ag-grid/ag-grouping-helper';
import SelectorOption from 'infrastructure/selector-option';
import { generateTestResultsColumnDefs } from './test-results-grid';

const testResultsViewBySelectorOptions: SelectorOption[] = [
    { title: 'All', value: 'all' },
    { title: 'Remediation Root Cause', value: 'remediationRootCauseName' },
    { title: 'Map Name', value: 'mapName' },
    { title: 'Plan Name', value: 'planName' },
    { title: 'Point Name', value: 'pointName' },
    { title: 'Point Description', value: 'pointDescription' },
    { title: 'Point Zone', value: 'pointZone' },
    { title: 'Point Room', value: 'pointRoom' },
    { title: 'Point Type', value: 'pointType' },
    { title: 'Point Risk', value: 'pointRisk' },
    { title: 'Point Mobility', value: 'pointIsMobile' },
];

export class TestResultsGroupByCustomElement {
    @bindable gridOptions: GridOptions;
    @bindable currentDateRangeCaption: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) currentChartRef: ChartRef;
    @bindable filteredByListeria: boolean;
    viewBySelectorOptions: SelectorOption[];

    private _selectedViewByOption: SelectorOption;

    constructor() {
        this.viewBySelectorOptions = testResultsViewBySelectorOptions;
    }

    filteredByListeriaChanged() {
        this.selectedViewByOption = testResultsViewBySelectorOptions[0];
    }

    public get selectedViewByOption(): SelectorOption {
        return this._selectedViewByOption;
    }

    public set selectedViewByOption(selectedOption: SelectorOption) {
        let autoSortedColumnStateColId: string;
        if (selectedOption.value === 'all') {
            autoSortedColumnStateColId = 'requestExternalId';
        }
        this._selectedViewByOption = selectedOption;

        setGroupByColumnDefinitions(
            this.gridOptions,
            this.selectedViewByOption,
            generateTestResultsColumnDefs(),
            autoSortedColumnStateColId,
        );
        this.drawChart();
    }

    drawChart() {
        this.currentChartRef?.destroyChart();
        if (this._selectedViewByOption.value === 'all') {
            return;
        }

        const rowEndIndex = this.gridOptions.api.getLastDisplayedRow();
        if (rowEndIndex === -1) {
            return;
        }

        const params: CreateRangeChartParams = {
            cellRange: {
                rowStartIndex: 0,
                rowEndIndex,
                columns: [
                    this.selectedViewByOption.value as string,
                    'result-count-pass',
                    'result-count-fail',
                ],
            },
            chartType: ChartType.GroupedColumn,
            chartThemeName: 'passFailCustomTheme',
            chartThemeOverrides: {
                common: {
                    title: {
                        enabled: true,
                        text: `${this.filteredByListeria ? 'Listeria ' : ''}Pass/Fail by ${
                            this.selectedViewByOption.title
                        } - ${this.currentDateRangeCaption}`,
                    },
                },
            },
        };

        this.currentChartRef = this.gridOptions.api.createRangeChart(params);
    }
}
