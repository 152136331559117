import { inject, bindable, observable, Container } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import DialogPresenter from './dialog-presenter';
import UserService from '../../users/user-service';
import Logger from 'infrastructure/logger';

@inject(DialogController, Container, Element, Logger, UserService)
export default class UserSelector {
    @bindable user;
    @observable searchText;

    constructor(controller, container, element, logger, userService) {
        this.controller = controller;
        this.element = element;
        this.container = container;
        this.logger = logger;
        this.userService = userService;
        this.searchText = '';
        this.isLoading = true;
        this.matchingUsers = [];
    }

    searchTextChanged() {
        this.updateSearchResults();
    }

    async updateSearchResults() {
        if (this.searchText.trim() === '')
            return;

        this.user = null;

        try {
            this.isLoading = true;
            var matchingUsers = await this.userService.getUsers(this.searchText);

            for (let user of matchingUsers)
                user.title = `${user.firstName} ${user.lastName}`;

            this.matchingUsers = matchingUsers;
            this.isLoading = false;
        } catch (error) {
            this.logger.error('Error searching for users.', error, { searchText: this.searchText });
            this.container.get(DialogPresenter).showAlert('Unexpected Error', 'An unexpected error occurred while searching for users. Please try again later.');
            this.isLoading = false;
        }
    }

    activate(model) {
        this.model = model;
    }
}
