import DateUtility from '../date-utility';

export class DateFormatValueConverter {
    toView(value, format, defaultValue, convertToLocal = true) {
        return DateUtility.toView(value, format, defaultValue, convertToLocal);
    }

    fromView(value) {
        if (value)
            return new Date(value);

        return null;
    }
}
