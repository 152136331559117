import { inject, observable } from 'aurelia-framework';
import PageContext from '../../infrastructure/page-context';
import Logger from '../../infrastructure/logger';
import DialogPresenter from '../../infrastructure/dialogs/dialog-presenter';
import FaqGroupService from './faq-group-service';

@inject(PageContext, Logger, DialogPresenter, FaqGroupService)
export default class FaqList {
    @observable filterText;

    constructor(pageContext, logger, dialogPresenter, faqGroupService) {
        this.pageContext = pageContext;

        this.logger = logger;
        this.logger.name = 'faqs';

        this.dialogPresenter = dialogPresenter;
        this.faqGroupService = faqGroupService;
        this.filterText = '';
    }

    activate() {
        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.faqGroups = await this.faqGroupService.getFaqGroups(true);
                this.updateView();
            } catch (error) {
                this.logger.error('Error loading FAQ.', error);
                this.dialogPresenter.showAlert(
                    'Error Loading FAQs',
                    'An error occurred while loading the FAQs. Please try again later.');
            }

            this.pageContext.isLoading = false;
        })();
    }

    filterTextChanged() {
        this.updateView();
    }

    updateView() {
        if (!this.faqGroups)
            return;

        var lowerCasedFilterText = this.filterText.toLowerCase();

        for (let faqGroup of this.faqGroups) {
            faqGroup.isVisible = false;
            for (let faq of faqGroup.faqs) {
                faq.isVisible = (faq.question || '').toLowerCase().indexOf(lowerCasedFilterText) > -1;
                faqGroup.isVisible = faq.isVisible ? true : faqGroup.isVisible;
            }
        }
    }
};
