import { autoinject, bindable } from 'aurelia-framework';
import CompareUtility from 'infrastructure/compare-utility';
import Point from 'location-testing/points/point';
import PointSelectorFilter from '../point-selector-filter';
import PointAttribute from '../points/point-attribute';
import PointService from '../points/point-service';
@autoinject
export class PlanPointSelector {
    @bindable points: Point[];
    @bindable planPoints;
    @bindable mapId: number;
    @bindable selectedOnly: boolean;

    @bindable filters: PointSelectorFilter;

    gridPoints: any[];
    pointGridConfig: any;
    pointGridFullHeightViewModel: any;
    allPointsSelected: boolean;

    constructor(
        private element: Element,
        private pointService: PointService
    ) {
        this.pointGridConfig = {
            columnDefs: [
                {
                    suppressMenu: true,
                    template: '<label><input type="checkbox" checked.bind="data.isSelected" change.delegate="isPointSelectedChanged()"></label>',
                    headerCellTemplate: '<label click.delegate="allPointsSelectedClicked()"><input type="checkbox" checked.bind="allPointsSelected"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true,
                },
                { suppressMenu: true, headerName: 'Name', field: 'name', suppressSizeToFit: true, comparator: CompareUtility.compareStringsInsensitive, sort: 'asc' },
                { suppressMenu: true, headerName: 'Description', field: 'description', width: 300, suppressSizeToFit: true },
                { suppressMenu: true, headerName: 'Room', field: 'room', suppressSizeToFit: true, comparator: CompareUtility.compareStringsInsensitive },
                { suppressMenu: true, headerName: 'Zone', field: 'zone', suppressSizeToFit: true },
                { suppressMenu: true, headerName: 'Mobile', field: 'mobile', width: 80, suppressSizeToFit: true },
                { suppressMenu: true, headerName: 'Type', field: 'type', suppressSizeToFit: true, comparator: CompareUtility.compareStringsInsensitive },
                { suppressMenu: true, headerName: 'Risk', field: 'risk', suppressSizeToFit: true },
                { suppressMenu: true, headerName: 'Pause Pending Remediation', field: 'isPausedPendingRemediation'}
            ],
            defaultColDef: { sortable: true, resizable: true, filter: true },
            animateRows: true,
            isExternalFilterPresent: () => this.selectedOnly,
            doesExternalFilterPass: (node: any) => this.doesExternalFilterPass(node),
        };

        this.allPointsSelected = false;
    }

    selectedOnlyChanged() {
        this.pointGridConfig.api.onFilterChanged();
    }

    doesExternalFilterPass(node: any) {
        return node.data.isSelected;
    }

    resetView(pointAttributes?: PointAttribute[]) {
        if (pointAttributes)
            this.filters.setupFilterOptions(this.points, pointAttributes);

        this.pointGridFullHeightViewModel.update();
    }

    updatePlanPoints() {
        // Ensure that the source point have their isSelected property synced with the grid points prior to filtering and adding them to the plan.
        this.updateSourcePointSelections();

        this.planPoints.splice(0);
        this.planPoints.push(...this.points.filter(p => p.isSelected));

        this.element.dispatchEvent(new CustomEvent('planpointschanged', { bubbles: true, detail: {} }));
    }

    isPointSelectedChanged() {
        this.allPointsSelected = this.gridPoints.every(p => p.isSelected);
        this.updatePlanPoints();
    }

    allPointsSelectedClicked() {
        if (this.points) {
            this.allPointsSelected = !this.gridPoints.every(p => p.isSelected);

            for (let point of this.gridPoints)
                point.isSelected = this.allPointsSelected;

            this.updatePlanPoints();
        }

        return true;
    }

    updateSourcePointSelections() {
        for (let gridPoint of this.gridPoints) {
            let point = this.points.find(p => p.id === gridPoint.id);
            if (point)
                point.isSelected = gridPoint.isSelected;
        }
    }

    async updateGridPoints() {
        let gridPoints = await this.getFilteredGridPoints();

        this.gridPoints = gridPoints.map(p => ({
            id: p.id,
            isSelected: p.isSelected,
            name: p.name,
            description: p.description,
            room: p.room,
            zone: p.zone,
            mobile: p.mobile ? 'Yes' : 'No',
            type: p.type,
            risk: this.pointService.getRiskCaption(p.risk),
            isPausedPendingRemediation: p.isPausedPendingRemediation ? 'Yes': 'No',
        }));
    }

    async getFilteredGridPoints() {
        if (!this.points)
            return [];

        let pointAttributes = this.filters.convertPointAttributeSelectionsToPointAttributes();
        let pointSummaries = await this
            .pointService
            .getPoints({
                mapIds: [this.mapId],
                pointAttributes,
            },
                'summary');

        let pointIds = pointSummaries.map(pointSummary => pointSummary.id)
        let points = this.points.filter(p => pointIds.includes(p.id));

        return this.filters.applyFilters(points, pointAttributes.length > 0);
    }

    pointsChanged() {
        if (!this.points)
            return;

        (async () => {
            this.filters.setupFilterOptions(this.points);
            this.initialize();
            await this.updateGridPoints();
        })();
    }

    planPointsChanged() {
        this.initialize();
    }

    initialize() {
        if (!this.points || !this.planPoints)
            return;

        let gridPoints = this.points.filter(p => this.planPoints.some(pp => pp.id === p.id));

        for (let point of gridPoints)
            point.isSelected = true;

        this.gridPoints = gridPoints;
    }
}
