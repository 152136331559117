export class SortValueConverter {
    toView(array, propertyName, direction) {
        if (typeof array === 'undefined' || array === null) return array;
        if (!Array.isArray(array)) return array;
        if (array.length === 0) return array;

        var result = array.slice(0)
            .sort((a, b) => {
                if (direction.toLowerCase() === 'asc')
                    return (a[propertyName].toLowerCase() > b[propertyName].toLowerCase());
                return (b[propertyName].toLowerCase() > a[propertyName].toLowerCase());
            });
        return result;
    }
}
