import { autoinject, BindingEngine, Disposable, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules,
} from 'aurelia-validation';
import { getTimeOptions } from 'infrastructure/components/time-selector';
import DialogPresenter from 'infrastructure/dialogs/dialog-presenter';
import Logger from 'infrastructure/logger';
import PageContext from 'infrastructure/page-context';
import SelectorOption from 'infrastructure/selector-option';
import TimeZoneService from 'infrastructure/time-zone-service';
import Lab from 'labs/lab';
import LabService from 'labs/lab-service';
import { getCollectionTypeOptions } from 'location-testing/collection-type';
import PointService from 'location-testing/points/point-service';
import moment from 'moment';
import SecurityService from 'security/security-service';
import TestMethodService from 'test-methods/test-method-service';
import operationsTimingOptions from '../operations-timing-options';
import PointRisk from '../points/point-risk';
import GenerateTasksDateSelectionType from './generate-task-date-selection-type';
import GenerateTasksDistributionMethod from './generate-tasks-distribution-method';
import GenerateTasksPointZoneDistributionMethod from './generate-tasks-point-zone-distribution-method';
import Plan from './plan';
import { toPlanCoverageType } from './plan-coverage-type';
import PlanService from './plan-service';
import PlanType from './plan-type';
import TaskGenerationClientSettings from './task-generation-client-settings';
import TaskGenerationRequest, {
    TaskGenerationZoneDistribution,
    TaskRequestPriority,
} from './task-generation-request';
import TaskGenerationRequestService from './task-generation-request-service';
import UiPlanCoverage from './ui-plan-coverage';
import UiSchedule from './ui-schedule';
import { testMethodNameFormatting } from 'test-methods/test-method-helper';

@autoinject
export class GenerateTasks {
    @observable blackoutDate: any;

    validationController: ValidationController;
    requestedPointCountSubscription: Disposable;
    requestedPointCountZone1Subscription: Disposable;
    requestedPointCountZone2Subscription: Disposable;
    requestedPointCountZone3Subscription: Disposable;
    requestedPointCountZone4Subscription: Disposable;
    pointZoneDistributionMethodSubscription: Disposable;
    dateSelectionTypeSubscription: Disposable;
    weeklySwabsSubscription: Disposable;
    startDateSubscription: Disposable;
    endDateSubscription: Disposable;

    // Security
    canEditPlans: boolean;
    currentUser: any;

    mapId: number;
    plan: Plan;
    schedule: any;
    recordChanges: any;
    pausedPointCount: number;

    testMethodOptions: any[];
    selectedTestMethodIds: number[];

    labs: Lab[];
    collectionTypeOptions: SelectorOption[];

    dateSelectionType: GenerateTasksDateSelectionType;
    taskGenerationRequest: TaskGenerationRequest;
    riskLevels: any;
    zoneDistributions: any;
    zoneDistributionMax: number;

    zonePercentageCount: any;
    showZonePercentageCounts: boolean;

    planTypes: any;
    timeZones: any;
    operationsTimingOptions: any;
    planPointRiskBreakdown: any;
    planPointZoneBreakdown: any;

    planPointRisks: string[];

    isPaused: boolean;
    wasPausedAtLoad: boolean;
    isLocked: boolean;
    timeCaptions: any;
    scheduleMinDate: Date;
    targetCollectionTimesInvalid: boolean;
    weekBasedTaskDistribution: boolean;
    taskCountDisabled: boolean;
    weeklySwabs: number;

    formChanged: boolean;

    showMainSection: boolean;
    showScheduleSection: boolean;
    showCollectionTimesSection: boolean;
    showBlackoutSection: boolean;
    showRiskDistributionSection: boolean;

    constructor(
        validationControllerFactory: ValidationControllerFactory,
        private router: Router,
        private bindingEngine: BindingEngine,
        private logger: Logger,
        private pageContext: PageContext,
        private dialogPresenter: DialogPresenter,
        private securityService: SecurityService,
        private timeZoneService: TimeZoneService,
        private testMethodService: TestMethodService,
        private labService: LabService,
        private planService: PlanService,
        private taskGenerationRequestService: TaskGenerationRequestService,
        private planCoverage: UiPlanCoverage,
        private pointService: PointService,
    ) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        this.logger.name = 'plan-generate-tasks';

        this.canEditPlans =
            this.securityService.hasPermission('EditPlans') &&
            !this.securityService.isImpersonating();

        this.isLocked = false;
        this.wasPausedAtLoad = false;

        this.showMainSection = false;
        this.showScheduleSection = false;
        this.showRiskDistributionSection = false;
        this.showCollectionTimesSection = false;
        this.showBlackoutSection = false;
        this.taskCountDisabled = false;
        this.planPointRisks = ['High', 'Medium', 'Low', 'Unknown'];
        this.zoneDistributionMax = 100;

        this.operationsTimingOptions = operationsTimingOptions;
        this.collectionTypeOptions = getCollectionTypeOptions();

        this.initSchedule();

        this.timeCaptions = getTimeOptions().reduce((mapping, time) => {
            mapping[time.value] = time.title;
            return mapping;
        }, {});

        this.handleRequestedPointCountChanged = this.handleRequestedPointCountChanged.bind(this);
        this.handlePointZoneDistributionMethodChanged =
            this.handlePointZoneDistributionMethodChanged.bind(this);
    }

    initSchedule() {
        var today = new Date();
        var minDate = new Date();
        minDate.setDate(today.getDate() - 1);

        this.scheduleMinDate = minDate;
        this.schedule = { startDate: today };
    }

    isPriorityBasedPlan() {
        return this.plan.planTypeId === PlanType.RISK_BASED_PRIORITY;
    }

    mapDateSelectionTypeToPointDistributionMethod(
        dateSelectionType: GenerateTasksDateSelectionType,
    ) {
        switch (dateSelectionType) {
            case GenerateTasksDateSelectionType.BY_WEEK:
                return GenerateTasksDistributionMethod.WEEKLY_SWAB;
            case GenerateTasksDateSelectionType.BY_DAY:
                return GenerateTasksDistributionMethod.DAILY_SWAB;
            default:
                return this.weekBasedTaskDistribution
                    ? GenerateTasksDistributionMethod.WEEK_BASED
                    : GenerateTasksDistributionMethod.SEQUENTIAL;
        }
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    prepareTaskRequestForSubmission(request: TaskGenerationRequest) {
        const copy = JSON.parse(JSON.stringify(request)) as TaskGenerationRequest;
        delete request.riskPriorities;

        copy.startDate = moment(request.startDate).format('YYYY-MM-DD') as any as Date;
        copy.endDate =
            this.dateSelectionType === GenerateTasksDateSelectionType.BY_WEEK
                ? (moment(request.startDate)
                      .add(Number(this.weeklySwabs), 'weeks')
                      .add(-1, 'days')
                      .format('YYYY-MM-DD') as any as Date)
                : (moment(request.endDate).format('YYYY-MM-DD') as any as Date);

        copy.requestedTaskCount = Number(copy.requestedTaskCount);

        copy.pointDistributionMethod = this.mapDateSelectionTypeToPointDistributionMethod(
            this.dateSelectionType,
        );

        if (this.plan.planTypeId === PlanType.RISK_BASED_PRIORITY) {
            let highTaskRiskPriority = {
                risk: PointRisk.HIGH.toString(),
                percentage: Number(this.riskLevels.high),
            } as TaskRequestPriority;

            let mediumTaskRiskPriority = {
                risk: PointRisk.MEDIUM.toString(),
                percentage: Number(this.riskLevels.medium),
            } as TaskRequestPriority;

            let lowTaskRiskPriority = {
                risk: PointRisk.LOW.toString(),
                percentage: Number(this.riskLevels.low),
            } as TaskRequestPriority;

            copy.riskPriorities = [
                highTaskRiskPriority,
                mediumTaskRiskPriority,
                lowTaskRiskPriority,
            ];
        }

        if (
            this.taskGenerationRequest.pointZoneDistributionMethod !==
            GenerateTasksPointZoneDistributionMethod.NOT_APPLICABLE
        ) {
            copy.pointZoneDistributionMethod = GenerateTasksPointZoneDistributionMethod.DISCRETE;
            copy.pointZoneDistributions = TaskGenerationZoneDistribution.createAllZonesForServer(
                this.taskGenerationRequest.pointZoneDistributionMethod,
                this.zoneDistributions,
                this.zonePercentageCount,
            );

            if (
                this.taskGenerationRequest.pointZoneDistributionMethod ===
                GenerateTasksPointZoneDistributionMethod.DISCRETE
            )
                delete copy.requestedTaskCount;
        }

        return copy;
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    initializeTaskRequest() {
        // load defaults for the plan
        let today = new Date();
        let tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        let planSettings = this.taskGenerationRequestService.getPlanSettings(this.plan.id);

        this.taskGenerationRequest = new TaskGenerationRequest();
        this.taskGenerationRequest.planId = this.plan.id;
        this.taskGenerationRequest.testMethodIds = (this.plan.testMethods ?? []).map((tm) => tm.id);
        this.taskGenerationRequest.startDate = today;
        this.taskGenerationRequest.endDate = tomorrow;
        this.taskGenerationRequest.timeZone = this.plan.schedule.timeZone;
        this.taskGenerationRequest.requestedTaskCount =
            planSettings?.requestedTaskCount || this.plan.points.length;
        this.dateSelectionType =
            planSettings?.dateSelectionType || GenerateTasksDateSelectionType.SINGLE_DATE;

        // copy blackout schedule info
        this.taskGenerationRequest.blackoutDates = [];
        if (this.plan.schedule.blackoutDates)
            this.taskGenerationRequest.blackoutDates.push(...this.plan.schedule.blackoutDatesView);

        this.taskGenerationRequest.blackoutSundays = this.plan.schedule.blackoutSundays;
        this.taskGenerationRequest.blackoutMondays = this.plan.schedule.blackoutMondays;
        this.taskGenerationRequest.blackoutTuesdays = this.plan.schedule.blackoutTuesdays;
        this.taskGenerationRequest.blackoutWednesdays = this.plan.schedule.blackoutWednesdays;
        this.taskGenerationRequest.blackoutThursdays = this.plan.schedule.blackoutThursdays;
        this.taskGenerationRequest.blackoutFridays = this.plan.schedule.blackoutFridays;
        this.taskGenerationRequest.blackoutSaturdays = this.plan.schedule.blackoutSaturdays;
        this.taskGenerationRequest.collectionType = this.plan.collectionType;

        this.weeklySwabs = 1;

        this.taskGenerationRequest.pointZoneDistributionMethod =
            planSettings?.pointZoneDistributionMethod ??
            GenerateTasksPointZoneDistributionMethod.NOT_APPLICABLE;

        this.riskLevels = {
            high: planSettings?.riskPriorities?.find((x) => x.risk === 'High')?.percentage ?? 50,
            medium:
                planSettings?.riskPriorities?.find((x) => x.risk === 'Medium')?.percentage ?? 25,
            low: planSettings?.riskPriorities?.find((x) => x.risk === 'Low')?.percentage ?? 25,
            get sum() {
                return (
                    (Number(this.high) || 0) + (Number(this.medium) || 0) + (Number(this.low) || 0)
                );
            },
        };

        this.zoneDistributions = {
            zone1: planSettings?.pointZoneDistributions?.find((x) => x.zone === 1)?.value ?? 0,
            zone2: planSettings?.pointZoneDistributions?.find((x) => x.zone === 2)?.value ?? 0,
            zone3: planSettings?.pointZoneDistributions?.find((x) => x.zone === 3)?.value ?? 0,
            zone4: planSettings?.pointZoneDistributions?.find((x) => x.zone === 4)?.value ?? 0,
            get sum() {
                return (
                    (Number(this.zone1) || 0) +
                    (Number(this.zone2) || 0) +
                    (Number(this.zone3) || 0) +
                    (Number(this.zone4) || 0)
                );
            },
        };

        this.zonePercentageCount = {
            zone1: planSettings?.pointZoneDistributions?.find((x) => x.zone === 1)?.value ?? 0,
            zone2: planSettings?.pointZoneDistributions?.find((x) => x.zone === 2)?.value ?? 0,
            zone3: planSettings?.pointZoneDistributions?.find((x) => x.zone === 3)?.value ?? 0,
            zone4: planSettings?.pointZoneDistributions?.find((x) => x.zone === 4)?.value ?? 0,
            // eslint-disable-next-line sonarjs/no-identical-functions
            get sum() {
                return (
                    (Number(this.zone1) || 0) +
                    (Number(this.zone2) || 0) +
                    (Number(this.zone3) || 0) +
                    (Number(this.zone4) || 0)
                );
            },
        };

        this.requestedPointCountSubscription = this.bindingEngine
            .propertyObserver(this.taskGenerationRequest, 'requestedTaskCount')
            .subscribe(this.handleRequestedPointCountChanged);

        this.requestedPointCountZone1Subscription = this.bindingEngine
            .propertyObserver(this.zoneDistributions, 'zone1')
            .subscribe(this.handleRequestedPointCountChanged);

        this.requestedPointCountZone2Subscription = this.bindingEngine
            .propertyObserver(this.zoneDistributions, 'zone2')
            .subscribe(this.handleRequestedPointCountChanged);

        this.requestedPointCountZone3Subscription = this.bindingEngine
            .propertyObserver(this.zoneDistributions, 'zone3')
            .subscribe(this.handleRequestedPointCountChanged);

        this.requestedPointCountZone4Subscription = this.bindingEngine
            .propertyObserver(this.zoneDistributions, 'zone4')
            .subscribe(this.handleRequestedPointCountChanged);

        this.pointZoneDistributionMethodSubscription = this.bindingEngine
            .propertyObserver(this.taskGenerationRequest, 'pointZoneDistributionMethod')
            .subscribe(this.handlePointZoneDistributionMethodChanged);

        this.dateSelectionTypeSubscription = this.bindingEngine
            .propertyObserver(this, 'dateSelectionType')
            .subscribe(this.handleRequestedPointCountChanged);

        this.weeklySwabsSubscription = this.bindingEngine
            .propertyObserver(this, 'weeklySwabs')
            .subscribe(this.handleRequestedPointCountChanged);

        this.startDateSubscription = this.bindingEngine
            .propertyObserver(this.taskGenerationRequest, 'startDate')
            .subscribe(this.handleRequestedPointCountChanged);

        this.startDateSubscription = this.bindingEngine
            .propertyObserver(this.taskGenerationRequest, 'endDate')
            .subscribe(this.handleRequestedPointCountChanged);

        this.handleRequestedPointCountChanged();
        this.handlePointZoneDistributionMethodChanged();
    }

    handleRequestedPointCountChanged() {
        this.generateRequestedTaskCount();
    }

    handlePointZoneDistributionMethodChanged() {
        if (
            this.taskGenerationRequest.pointZoneDistributionMethod ===
            GenerateTasksPointZoneDistributionMethod.DISCRETE
        )
            this.zoneDistributionMax = 1000;

        if (
            this.taskGenerationRequest.pointZoneDistributionMethod ===
            GenerateTasksPointZoneDistributionMethod.PERCENTAGE
        )
            this.zoneDistributionMax = 100;

        this.taskCountDisabled =
            this.taskGenerationRequest.pointZoneDistributionMethod ===
            GenerateTasksPointZoneDistributionMethod.DISCRETE;
        this.showZonePercentageCounts =
            this.taskGenerationRequest.pointZoneDistributionMethod ===
            GenerateTasksPointZoneDistributionMethod.PERCENTAGE;

        this.validationController.validate({
            object: this.zoneDistributions,
            propertyName: 'zone1',
        });
        this.validationController.validate({
            object: this.zoneDistributions,
            propertyName: 'zone2',
        });
        this.validationController.validate({
            object: this.zoneDistributions,
            propertyName: 'zone3',
        });
        this.validationController.validate({
            object: this.zoneDistributions,
            propertyName: 'zone4',
        });
        this.validationController.validate({ object: this.zoneDistributions, propertyName: 'sum' });

        this.generateRequestedTaskCount();
    }

    getDates() {
        const startDate = moment(this.taskGenerationRequest.startDate);
        const endDate = moment(this.taskGenerationRequest.endDate);
        const diff = endDate.diff(startDate, 'days');
    }

    getMultiplier() {
        let multiplier;
        switch (this.dateSelectionType) {
            case GenerateTasksDateSelectionType.BY_WEEK:
                multiplier = this.weeklySwabs;
                break;
            case GenerateTasksDateSelectionType.BY_DAY:
                multiplier = Math.ceil(
                    1 +
                        (this.taskGenerationRequest.endDate.getTime() -
                            this.taskGenerationRequest.startDate.getTime()) /
                            (1000 * 3600 * 24),
                );
                break;
            default:
                multiplier = 1;
        }

        return Number.isNaN(multiplier) ? 1 : multiplier;
    }

    generateRequestedTaskCount() {
        const multiplier = this.getMultiplier();

        if (
            this.taskGenerationRequest.pointZoneDistributionMethod ===
                GenerateTasksPointZoneDistributionMethod.NOT_APPLICABLE ||
            this.taskGenerationRequest.pointZoneDistributionMethod ===
                GenerateTasksPointZoneDistributionMethod.PERCENTAGE
        ) {
            this.taskGenerationRequest.tasksToBeGenerated = Number.isNaN(
                this.taskGenerationRequest.requestedTaskCount,
            )
                ? 0
                : this.taskGenerationRequest.requestedTaskCount *
                  this.schedule.targetCollectionTimes.length *
                  multiplier;
        }

        if (
            this.taskGenerationRequest.pointZoneDistributionMethod ===
            GenerateTasksPointZoneDistributionMethod.PERCENTAGE
        ) {
            this.zonePercentageCount.zone1 = Math.round(
                this.taskGenerationRequest.requestedTaskCount *
                    (this.zoneDistributions.zone1 / 100),
            );
            this.zonePercentageCount.zone2 = Math.round(
                this.taskGenerationRequest.requestedTaskCount *
                    (this.zoneDistributions.zone2 / 100),
            );
            this.zonePercentageCount.zone3 = Math.round(
                this.taskGenerationRequest.requestedTaskCount *
                    (this.zoneDistributions.zone3 / 100),
            );
            this.zonePercentageCount.zone4 = Math.round(
                this.taskGenerationRequest.requestedTaskCount *
                    (this.zoneDistributions.zone4 / 100),
            );
        }

        if (
            this.taskGenerationRequest.pointZoneDistributionMethod ===
            GenerateTasksPointZoneDistributionMethod.DISCRETE
        ) {
            this.taskGenerationRequest.tasksToBeGenerated = Number.isNaN(this.zoneDistributions.sum)
                ? 0
                : this.zoneDistributions.sum *
                  this.schedule.targetCollectionTimes.length *
                  multiplier;
        }
    }

    updateSections() {
        let pausedOrLocked = this.isLocked || this.wasPausedAtLoad;

        let planTypesUsingSchedule = [PlanType.SCHEDULED];
        let planTypesUsingBlackout = [
            PlanType.SCHEDULED,
            PlanType.RISK_BASED_PRIORITY,
            PlanType.ADHOC,
        ];

        this.showMainSection = true;
        this.showCollectionTimesSection = true;
        this.showScheduleSection = pausedOrLocked
            ? false
            : planTypesUsingSchedule.includes(this.plan.planTypeId);
        this.showBlackoutSection = pausedOrLocked
            ? false
            : planTypesUsingBlackout.includes(this.plan.planTypeId);
        this.showRiskDistributionSection = pausedOrLocked
            ? false
            : this.plan.planTypeId === PlanType.RISK_BASED_PRIORITY;
    }

    generatePointBreakdowns(plan) {
        this.planPointRiskBreakdown = {};
        this.planPointZoneBreakdown = {};

        if (!plan) return;

        // get the count of the risk levels
        let riskLevels = plan?.points.map((p) => p.risk);
        this.planPointRiskBreakdown = riskLevels.reduce(
            (a, c) => ((a[c] = (a[c] || 0) + 1), a),
            Object.create(null),
        );

        let zones = plan?.points.map((p) => p.zone);
        this.planPointZoneBreakdown = zones.reduce(
            (a, c) => ((a[c] = (a[c] || 0) + 1), a),
            Object.create(null),
        );
    }

    activate(params) {
        // eslint-disable-next-line sonarjs/cognitive-complexity
        (async () => {
            this.pageContext.isLoading = true;

            try {
                this.currentUser = this.securityService.getEffectiveUser();
                this.mapId = parseInt(params.mapId);
                let planId = parseInt(params.id);
                this.planCoverage.planId = planId;

                const [labs, planTypes, plan, timeZones] = await Promise.all([
                    this.labService.getLabs(),
                    this.planService.getPlanTypes(),
                    this.planService.getPlan(planId),
                    this.timeZoneService.getTimeZones(),
                ]);

                this.labs = labs;
                var lab = labs.find((l) => l.id === plan.labId);

                this.testMethodOptions = (
                    await this.testMethodService.getTestMethods(plan.organizationId, {
                        externalSource: lab.externalSource,
                    })
                ).map((tm) => ({
                    title: testMethodNameFormatting(
                        tm,
                        this.securityService.isImpersonating(),
                        this.securityService.isWorkingUnderOrganization(),
                    ),
                    value: tm.id,
                }));

                this.planTypes = planTypes;
                this.plan = plan;
                this.timeZones = timeZones.map((tz) => ({ title: tz.displayName, value: tz.id }));
                this.pausedPointCount = this.plan.points.filter(
                    (p) => p.isPausedPendingRemediation,
                ).length;

                const mapId = this.plan.mapId;

                // make sure this is the correct plan type
                if (
                    this.plan.planTypeId !== PlanType.RISK_BASED_PRIORITY &&
                    this.plan.planTypeId !== PlanType.ADHOC
                )
                    this.router.navigateToRoute('plan-list', { mapId });

                this.generatePointBreakdowns(this.plan);

                // make sure that this plan isn't paused and is active -
                this.isLocked = !this.plan.isActive;
                this.wasPausedAtLoad = this.plan.isPaused;
                if (this.isLocked || this.wasPausedAtLoad)
                    this.router.navigateToRoute('plan-list', { mapId });

                this.mapId = mapId;
                const schedule = new UiSchedule();
                schedule.loadViewFrom(this.plan.schedule);
                this.schedule = schedule;
                this.plan.schedule = this.schedule;

                this.initializeTaskRequest();
                this.updateSections();

                this.setupValidation();
            } catch (error) {
                this.logger.error('Error loading plan', error, { planId: params.id });

                await (error.apiErrorCode === 1
                    ? this.dialogPresenter.showAlert(
                          'Error Loading Plan',
                          'The current plan does not exist.',
                      )
                    : this.dialogPresenter.showAlert(
                          'Error Loading Plan',
                          'An error occurred while loading the current plan. Please try again later.',
                      ));

                const mapId = this.plan ? this.plan.mapId || this.mapId : this.mapId;
                if (mapId) this.router.navigateToRoute('plan-list', { mapId });
            }
            this.pageContext.isLoading = false;
        })();
    }

    deactivate() {
        this.requestedPointCountSubscription?.dispose();
        this.pointZoneDistributionMethodSubscription?.dispose();
        this.requestedPointCountZone1Subscription?.dispose();
        this.requestedPointCountZone2Subscription?.dispose();
        this.requestedPointCountZone3Subscription?.dispose();
        this.requestedPointCountZone4Subscription?.dispose();
        this.dateSelectionTypeSubscription?.dispose();
        this.weeklySwabsSubscription?.dispose();
        this.startDateSubscription?.dispose();
        this.endDateSubscription?.dispose();
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    setupValidation() {
        if (this.plan.planTypeId === PlanType.RISK_BASED_PRIORITY) {
            ValidationRules.ensure('high')
                .required()
                .ensure('medium')
                .required()
                .ensure('low')
                .required()
                .ensure('sum')
                .satisfies((r) => r === 100)
                .on(this.riskLevels);
        }

        let errorMessageRequestedTaskCount =
            'Requested Task Count is required and must be greater than zero. ';
        if (this.plan.planTypeId === PlanType.RISK_BASED_PRIORITY)
            errorMessageRequestedTaskCount +=
                'The requested task count can not exceed the number of points included in the plan.';

        ValidationRules.ensure((o: TaskGenerationRequest) => o.planId)
            .required()
            .ensure((o: TaskGenerationRequest) => o.startDate)
            .required()
            .ensure((o: TaskGenerationRequest) => o.endDate)
            .required()
            .when(
                () =>
                    this.dateSelectionType === GenerateTasksDateSelectionType.DATE_RANGE ||
                    this.dateSelectionType === GenerateTasksDateSelectionType.BY_DAY,
            )
            .satisfies((endDate: Date, o: TaskGenerationRequest) => {
                if (!endDate || !o.startDate) return false;

                return (
                    moment(o.endDate).isSame(o.startDate, 'day') ||
                    moment(o.endDate).isAfter(o.startDate, 'day')
                );
            })
            .when(
                () =>
                    this.dateSelectionType === GenerateTasksDateSelectionType.DATE_RANGE ||
                    this.dateSelectionType === GenerateTasksDateSelectionType.BY_DAY,
            )

            .ensure((o: TaskGenerationRequest) => o.requestedTaskCount)
            .required()
            .satisfies((taskCount) => {
                if (this.plan.planTypeId === PlanType.RISK_BASED_PRIORITY)
                    return taskCount > 0 && taskCount <= this.plan.points.length;
                else return taskCount > 0;
            })
            .withMessage(errorMessageRequestedTaskCount)

            .on(this.taskGenerationRequest);

        let zoneOrPercentErrorMessage =
            'Required when using count or percent method.  Must be greater than 0.   Percentages must be less than or equal to 100.';
        let zoneDoesNotHaveEnoughPointsErrorMessage =
            'The plan does not include enough unique points in this zone to continue.';

        ValidationRules.ensure((o: any) => o.zone1)
            .satisfies((zoneValue) => this.validZoneOrPercent(zoneValue as number))
            .withMessage(zoneOrPercentErrorMessage)
            .satisfies((zoneValue) => this.planHasEnoughUniquePoints(zoneValue as number, 1))
            .withMessage(zoneDoesNotHaveEnoughPointsErrorMessage)

            .ensure((o: any) => o.zone2)
            .satisfies((zoneValue) => this.validZoneOrPercent(zoneValue as number))
            .withMessage(zoneOrPercentErrorMessage)
            .satisfies((zoneValue) => this.planHasEnoughUniquePoints(zoneValue as number, 2))
            .withMessage(zoneDoesNotHaveEnoughPointsErrorMessage)

            .ensure((o: any) => o.zone3)
            .satisfies((zoneValue) => this.validZoneOrPercent(zoneValue as number))
            .withMessage(zoneOrPercentErrorMessage)
            .satisfies((zoneValue) => this.planHasEnoughUniquePoints(zoneValue as number, 3))
            .withMessage(zoneDoesNotHaveEnoughPointsErrorMessage)

            .ensure((o: any) => o.zone4)
            .satisfies((zoneValue) => this.validZoneOrPercent(zoneValue as number))
            .withMessage(zoneOrPercentErrorMessage)
            .satisfies((zoneValue) => this.planHasEnoughUniquePoints(zoneValue as number, 4))
            .withMessage(zoneDoesNotHaveEnoughPointsErrorMessage)

            .ensure('sum')
            .satisfies((sum: number) => {
                if (
                    this.taskGenerationRequest.pointZoneDistributionMethod ===
                    GenerateTasksPointZoneDistributionMethod.DISCRETE
                )
                    return sum > 0;

                return true;
            })
            .withMessage('The sum of entries must be greater than 0.')

            .ensure('sum')
            .satisfies((sum: number) => {
                if (
                    this.taskGenerationRequest.pointZoneDistributionMethod ===
                    GenerateTasksPointZoneDistributionMethod.PERCENTAGE
                )
                    return (
                        Number(this.taskGenerationRequest.requestedTaskCount) ===
                        this.zonePercentageCount.sum
                    );

                return true;
            })
            .withMessage(
                'The sum of the points by zone percentage must equal the requested number of points.',
            )

            .on(this.zoneDistributions);

        ValidationRules.ensure((o: GenerateTasks) => o.weeklySwabs)
            .required()
            .when(() => this.dateSelectionType === GenerateTasksDateSelectionType.BY_WEEK)
            .min(1)
            .when(() => this.dateSelectionType === GenerateTasksDateSelectionType.BY_WEEK)
            .withMessage('The number of weeks of weekly swabs to perform.')

            .on(this);
    }

    validZoneOrPercent(zoneValue) {
        if (
            this.taskGenerationRequest.pointZoneDistributionMethod ===
            GenerateTasksPointZoneDistributionMethod.NOT_APPLICABLE
        )
            return true;

        if (zoneValue === null || zoneValue === undefined) return false;

        let countOrPercent: number = Number.parseInt(zoneValue);
        if (countOrPercent === null) return false;

        let maxValue =
            this.taskGenerationRequest.pointZoneDistributionMethod ===
            GenerateTasksPointZoneDistributionMethod.DISCRETE
                ? this.zoneDistributionMax
                : 100;

        return countOrPercent >= 0 && countOrPercent <= maxValue;
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    planHasEnoughUniquePoints(zoneValue, zone: number) {
        if (
            this.taskGenerationRequest.pointZoneDistributionMethod ===
            GenerateTasksPointZoneDistributionMethod.NOT_APPLICABLE
        )
            return true;

        if (zoneValue === null || zoneValue === undefined) return false;

        let countOrPercent: number = Number.parseInt(zoneValue);
        if (countOrPercent === null) return false;

        let zonePointCount = 0;
        let zonePointMax = 0;
        switch (zone) {
            case 1:
                zonePointCount =
                    this.taskGenerationRequest.pointZoneDistributionMethod ===
                    GenerateTasksPointZoneDistributionMethod.DISCRETE
                        ? countOrPercent
                        : this.zonePercentageCount.zone1;
                zonePointMax =
                    this.planPointZoneBreakdown[1] !== undefined
                        ? this.planPointZoneBreakdown[1]
                        : 0;
                break;

            case 2:
                zonePointCount =
                    this.taskGenerationRequest.pointZoneDistributionMethod ===
                    GenerateTasksPointZoneDistributionMethod.DISCRETE
                        ? countOrPercent
                        : this.zonePercentageCount.zone2;
                zonePointMax =
                    this.planPointZoneBreakdown[2] !== undefined
                        ? this.planPointZoneBreakdown[2]
                        : 0;
                break;

            case 3:
                zonePointCount =
                    this.taskGenerationRequest.pointZoneDistributionMethod ===
                    GenerateTasksPointZoneDistributionMethod.DISCRETE
                        ? countOrPercent
                        : this.zonePercentageCount.zone3;
                zonePointMax =
                    this.planPointZoneBreakdown[3] !== undefined
                        ? this.planPointZoneBreakdown[3]
                        : 0;
                break;

            case 4:
                zonePointCount =
                    this.taskGenerationRequest.pointZoneDistributionMethod ===
                    GenerateTasksPointZoneDistributionMethod.DISCRETE
                        ? countOrPercent
                        : this.zonePercentageCount.zone4;
                zonePointMax =
                    this.planPointZoneBreakdown[4] !== undefined
                        ? this.planPointZoneBreakdown[4]
                        : 0;
                break;
        }

        return countOrPercent >= 0 && zonePointCount <= zonePointMax;
    }

    handleFormChange() {
        this.formChanged = true;
    }

    blackoutDateChanged() {
        if (!this.blackoutDate) return;

        let blackoutDate = moment
            .utc([
                this.blackoutDate.getFullYear(),
                this.blackoutDate.getMonth(),
                this.blackoutDate.getDate(),
                0,
            ])
            .toDate();

        if (!this.taskGenerationRequest.blackoutDates)
            this.taskGenerationRequest.blackoutDates = [];

        if (
            !this.taskGenerationRequest.blackoutDates.some(
                (d) => d.getTime() === blackoutDate.getTime(),
            )
        ) {
            this.taskGenerationRequest.blackoutDates.push(blackoutDate);
            this.taskGenerationRequest.blackoutDates.sort((a, b) => a.getTime() - b.getTime());
        }

        setTimeout(() => {
            this.blackoutDate = null;
        });
    }

    removeBlackoutDate(dateToRemove) {
        this.taskGenerationRequest.blackoutDates.splice(
            this.taskGenerationRequest.blackoutDates.indexOf(dateToRemove),
            1,
        );
    }

    cancel() {
        this.formChanged = false;
        this.router.navigateToRoute('plan-list', { mapId: this.plan.mapId });
    }

    async save() {
        if (this.dateSelectionType === GenerateTasksDateSelectionType.SINGLE_DATE)
            this.taskGenerationRequest.endDate = this.taskGenerationRequest.startDate;

        var aggregateResult = await this.validationController.validate();
        if (!aggregateResult.valid) return;

        this.pageContext.isLoading = true;

        try {
            // the db sees all point zone distribution methods as 'Discrete'
            let request = this.prepareTaskRequestForSubmission(this.taskGenerationRequest);
            await this.taskGenerationRequestService.submit(request);

            // set the local view back to what the client selected.
            request.pointZoneDistributionMethod = this.taskGenerationRequest
                .pointZoneDistributionMethod as GenerateTasksPointZoneDistributionMethod;
            request.pointZoneDistributions = TaskGenerationZoneDistribution.createAllZonesForUI(
                request.pointZoneDistributionMethod,
                this.zoneDistributions,
                this.zonePercentageCount,
            );

            let clientSettings = TaskGenerationClientSettings.fromTaskGenerationRequest(
                request,
                this.dateSelectionType,
            );
            this.taskGenerationRequestService.savePlanSettings(this.plan.id, clientSettings);

            this.formChanged = false;
            this.pageContext.showSuccessOverlay('Task Generation Request submitted successfully.');

            this.router.navigateToRoute('plan-list', { mapId: this.plan.mapId });
        } catch (error) {
            this.logger.error('Error saving plan.', error, { plan: this.plan });
            this.dialogPresenter.showAlert(
                'Error Generating Tasks',
                this.getApiErrorMessage(error.apiErrorCode),
            );
        }

        this.pageContext.isLoading = false;
    }

    getApiErrorMessage(errorCode: number) {
        let message =
            'An error occurred while submitting a task generation request. Please try again later.';

        switch (errorCode) {
            case 1202:
                message =
                    'No points matched your request.  Please update the settings and try again later.';
                break;

            case 1203:
                message =
                    'No points matched the specified risk levels.  Please update the settings and try again later.';
                break;

            case 1204:
                message =
                    'No matching points for the zone criteria that was specified.  Please update the settings and try again later.';
                break;
        }

        return message;
    }

    exportChartData($event: any) {
        const {
            detail: { coverageType },
        } = $event;
        const planCoverageType = toPlanCoverageType(coverageType);
        const start = moment(this.planCoverage.taskTargetDateTimeStart).utc().toISOString();
        const end = moment(this.planCoverage.taskTargetDateTimeEnd).utc().toISOString();
        try {
            const url = this.planService.exportPlanCoveragePoints(
                this.plan.id,
                planCoverageType,
                start,
                end,
            );
            window.location.href = url;
        } catch (error) {
            this.logger.error('Error exporting plan points.', error, { plan: this.plan });
            this.dialogPresenter.showAlert('Error Exporting Plan Points', error.message);
        }
    }
}
