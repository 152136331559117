import { AgPromise, ICellRendererParams } from 'ag-grid-community';
import { AureliaCellRendererCompBase } from 'infrastructure/ag-grid/aurelia-cell-renderer-comp-base';

const template = `
                <div class="row-actions-flex" title="Replace test method">
                    <button disabled.bind="!canEditPlanManagement" click.delegate="replace(groupData || data)" data-cy="plan-management-replace-test-method-button">
                        <i class="fa fa-retweet"></i>
                    </button>
                </div>`;

export class AgPlanManagementGridActionRenderer extends AureliaCellRendererCompBase {
    constructor() {
        super(template);
    }

    init(params: ICellRendererParams): void | AgPromise<void> {
        const { node } = params;
        if (node.uiLevel === 0) {
            const { childrenAfterGroup } = node;
            const groupData = JSON.parse(JSON.stringify(childrenAfterGroup[0].data));
            delete groupData.organizationId;

            params['groupData'] = groupData;
        } else {
            delete params['groupData'];
        }

        super.init(params);
    }
}
