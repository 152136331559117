import { PLATFORM } from 'aurelia-framework';

export function configure(config) {
    config.globalResources([
        PLATFORM.moduleName('./accordion'),
        PLATFORM.moduleName('./accordion-item'),
        PLATFORM.moduleName('./content-size-change'),
        PLATFORM.moduleName('./data-dropdown'),
        PLATFORM.moduleName('./data-equalizer'),
        PLATFORM.moduleName('./data-grid'),
        PLATFORM.moduleName('./data-toggler'),
        PLATFORM.moduleName('./datepicker'),
        PLATFORM.moduleName('./doughnut-chart'),
        PLATFORM.moduleName('./mini-doughnut-chart'),
        PLATFORM.moduleName('./error-tooltip'),
        PLATFORM.moduleName('./full-calendar'),
        PLATFORM.moduleName('./full-height'),
        PLATFORM.moduleName('./info-tip'),
        PLATFORM.moduleName('./keyhold-prevent-focus'),
        PLATFORM.moduleName('./notify-form-changes'),
        PLATFORM.moduleName('./record-change-list'),
        PLATFORM.moduleName('./required'),
        PLATFORM.moduleName('./scroll-into-view'),
        PLATFORM.moduleName('./select2'),
        PLATFORM.moduleName('./select2-item-template'),
        PLATFORM.moduleName('./selector'),
        PLATFORM.moduleName('./selector-item-template'),
        PLATFORM.moduleName('./slider'),
        PLATFORM.moduleName('./time-selector'),
        PLATFORM.moduleName('./filters/filter-buttons'),
        PLATFORM.moduleName('./filters/date-range-filter'),
        PLATFORM.moduleName('./filters/organization-filter'),
        PLATFORM.moduleName('./filters/map-plan-filter'),
        PLATFORM.moduleName('./filters/test-method-filter'),
    ]);
}
