import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import LabelTemplate from './label-template';

@autoinject
export default class LabelTemplateService {
    constructor(private httpClient: HttpClient) {
    }

    getLabelTemplates(filters = {}, view: 'Default' | 'Selector' = 'Default') {
        var query = buildQueryString({ ...filters, view }, true);

        return this.httpClient.get(`label-templates${(query ? `?${query}` : '')}`) as any as Promise<LabelTemplate[]>;
    }

    getLabelTemplate(id: number) {
        return this.httpClient.get(`label-templates/${id}`) as any as Promise<LabelTemplate>;
    }

    deleteLabelTemplates(ids) {
        var query = ids.map(id => `id=${id}`).join('&');
        return this.httpClient.delete(`label-templates?${query}`);
    }

    saveLabelTemplate(labelTemplate: LabelTemplate): Promise<LabelTemplate> {
        if (!labelTemplate.id)
            return this.httpClient.post('label-templates/', labelTemplate) as any as Promise<LabelTemplate>;

        return this.httpClient.put(`label-templates/${labelTemplate.id}`, labelTemplate) as any as Promise<LabelTemplate>;
    }
}
