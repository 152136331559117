import { PLATFORM } from "aurelia-framework";

export function configure(aurelia) {
    aurelia.globalResources(
        PLATFORM.moduleName('./array-property-map-value-converter'),
        PLATFORM.moduleName('./percent-value-converter'),
        PLATFORM.moduleName('./boolean-value-converter'),
        PLATFORM.moduleName('./comma-separate-value-converter'),
        PLATFORM.moduleName('./number-value-converter'),
        PLATFORM.moduleName('./number-format-value-converter'),
        PLATFORM.moduleName('./date-format-value-converter'),
        PLATFORM.moduleName('./dictionary-value-converter'),
        PLATFORM.moduleName('./group-by-value-converter'),
        PLATFORM.moduleName('./filter-value-converter'),
        PLATFORM.moduleName('./sort-value-converter'),
        PLATFORM.moduleName('./take-value-converter'),
        PLATFORM.moduleName('./json-value-converter'),
        PLATFORM.moduleName('./currency-value-converter'),
        PLATFORM.moduleName('./object-keys-value-converter'),
        PLATFORM.moduleName('./lookup-and-map-value-converter'),
        PLATFORM.moduleName('./test-method-format-value-converter'),
        PLATFORM.moduleName('./cap-plan-test-method-format-value-converter'),
    );
}
