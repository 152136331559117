export class CustomError extends Error {
    errorCode: number;
    constructor(message: string, errorCode: number) {
        super(message);
        Object.setPrototypeOf(this, CustomError.prototype);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CustomError);
        }
        this.errorCode = errorCode;
    }
}

//TODO: Define CustomError Codes
// 1 : Exception
// 4 : Client side validation
// ? : ?
