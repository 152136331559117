import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';
import PagedList from 'infrastructure/contracts/paged-list';
import { TestResult } from './test-result';
import { TestResultListQuery } from './test-results-query-filters';

@autoinject
export class TestResultService {
    private baseRoute: string;

    constructor(private httpClient: HttpClient) {
        this.baseRoute = 'test-results';
    }

    getTestResults(testResultListQuery: TestResultListQuery): Promise<PagedList<TestResult>> {
        var query = buildQueryString(testResultListQuery, true);
        return this.httpClient.get(
            `${this.baseRoute}${query ? `?${query}` : ''}`,
        ) as unknown as Promise<PagedList<TestResult>>;
    }

    getDownloadUrl(testResultListQuery: TestResultListQuery) {
        var query = buildQueryString(testResultListQuery, true);
        return `api/test-results/download${query ? `?${query}` : ''}`;
    }
}
