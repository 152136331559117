import { inject } from 'aurelia-framework';
import { ValidationControllerFactory, validateTrigger, ValidationRules } from 'aurelia-validation';
import SecurityService from './security-service';

@inject(ValidationControllerFactory, SecurityService)
export class ResetPassword {
    constructor(validationControllerFactory, securityService) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.change;

        ValidationRules
            .ensure('password')
            .displayName('Password')
            .required()
            .ensure('confirmPassword')
            .displayName('Confirm Password')
            .required()
            .on(this);

        this.securityService = securityService;
    }

    activate(params, routeConfig) {
        this.token = params.token;
    }

    resetPassword() {
        this.errorMessage = '';
        this.successMessage = '';

        this.requestIsPending = true;
        this.successOrPermanentFailure = false;
        this.passwordRequirementFailures = [];

        this.validationController
            .validate()
            .then(aggregateResult => {
                if (!aggregateResult.valid) {
                    this.requestIsPending = false;
                    return;
                }

                this.securityService
                    .resetPassword(this.token, this.password, this.confirmPassword)
                    .then((result) => {
                        this.successMessage = "Your password has been successfully reset.";
                        this.requestIsPending = false;
                        this.successOrPermanentFailure = true;
                    })
                    .catch(response => {
                        switch (response.apiErrorCode) {
                            case 200:
                                this.errorMessage = "You must specify a non-blank password.";
                                break;
                            case 201:
                                this.errorMessage = "The passwords do not match.";
                                break;
                            case 202:
                                this.errorMessage = "The supplied password reset token does not correspond to any known user.";
                                this.successOrPermanentFailure = true;
                                break;
                            case 203:
                                this.errorMessage = "Your password reset token has expired.";
                                this.successOrPermanentFailure = true;
                                break;
                            case 204:
                                this.errorMessage = "The password does not meet the following requirements:";
                                for (var i = 0; i < response.apiErrorData.requirementFailures.length; i++) {
                                    switch (response.apiErrorData.requirementFailures[i]) {
                                        case 205:
                                            this.passwordRequirementFailures.push(`The password must be at least ${response.apiErrorData.passwordRequirements.minPasswordLength} characters in length.`);
                                            break;
                                        case 206:
                                            this.passwordRequirementFailures.push(`The password must contain at least ${response.apiErrorData.passwordRequirements.requireUpperCaseCount} uppercase character(s).`);
                                            break;
                                        case 207:
                                            this.passwordRequirementFailures.push(`The password must contain at least ${response.apiErrorData.passwordRequirements.requireNumberCount} numeric character(s).`);
                                            break;
                                        case 208:
                                            this.passwordRequirementFailures.push(`The password must contain at least ${response.apiErrorData.passwordRequirements.requireSpecialCharacterCount} special character(s).`);
                                            break;
                                        case 209:
                                            if (response.apiErrorData.passwordRequirements.passwordReuseCount == 0)
                                                this.passwordRequirementFailures.push(`The specified password has already been used.`);
                                            else if (response.apiErrorData.passwordRequirements.passwordReuseCount == 1)
                                                this.passwordRequirementFailures.push(`Passwords cannot be reused more than once.`);
                                            else if (response.apiErrorData.passwordRequirements.passwordReuseCount == 2)
                                                this.passwordRequirementFailures.push(`Passwords cannot be reused more than twice.`);
                                            else
                                                this.passwordRequirementFailures.push(`Passwords cannot be reused more than ${response.apiErrorData.passwordRequirements.passwordReuseCount} times.`);
                                            break;
                                    }
                                }
                                break;
                            default:
                                this.errorMessage = "An unknown error has occurred. Please try again.";
                        }

                        this.requestIsPending = false;
                    });
            });
    }

    navigateToLoginScreen() {
        this.securityService.navigateToLoginScreen();
    }
}
