import { BaseQueryFilter } from './base-query-filter';
import { QueryFiltered } from './query-filtered';

export interface OrganizationsQuery {
    organizationIds: number[];
}

export class OrganizationsQueryFilters extends BaseQueryFilter
    implements QueryFiltered<OrganizationsQuery> {
    organizationIds: number[];

    constructor() {
        super();
        this.organizationIds = [];
    }

    reset(): void {
        this.organizationIds = [];
    }

    public get count() {
        return (Number(!!this.organizationIds?.length));
    }

    getQueryParams(): OrganizationsQuery {
        return {
            organizationIds: this.organizationIds,
        };
    }

    resetFilterValues(): void {
        this.organizationIds = [];
    }

    setFilterValues(params: OrganizationsQuery): void {
        this.resetFilterValues();

        super.setQueryFilteredValues({
            organizationIds: params.organizationIds?.map(Number)
        }, this);
    }
}
