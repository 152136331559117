import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Aurelia, inject, PLATFORM } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { Router } from 'aurelia-router';
import ApplicationInsightsService from 'infrastructure/application-insights-service';
import Cookies from 'js-cookie';
import Logger from '../infrastructure/logger';
import RoleService from '../roles/role-service';

@inject(Aurelia, Router, HttpClient, EventAggregator, DialogService, Logger, RoleService, ApplicationInsightsService)
export default class SecurityService {
    constructor(aurelia, router, httpClient, eventAggregator, dialogService, logger, roleService, applicationInsightsService) {
        this.aurelia = aurelia;
        this.router = router;
        this.httpClient = httpClient;
        this.eventAggregator = eventAggregator;
        this.dialogService = dialogService;

        this.logger = logger;
        this.logger.name = 'security-service';

        this.applicationInsightsService = applicationInsightsService;

        this.roleService = roleService;
        this.startSessionExpirationChecks();
    }

    startSessionExpirationChecks() {
        window.setInterval(() => {
            var session = this.getCurrentSession();
            if (!session)
                return;

            if (new Date(session.expiration) > new Date())
                return;

            var navigateToLogin = !!localStorage.getItem('impersonatedUser') || !!localStorage.getItem('workingUnderOrganization');

            this.logout(navigateToLogin);

            if (!navigateToLogin)
                this.dialogService.open({
                    viewModel: PLATFORM.moduleName('security/login-dialog'),
                    model: { sessionExpired: true }
                });
        }, 5000);
    }

    login(username, password, verifyIdentityToken) {
        return this
            .httpClient
            .post('sessions/', { username, password, verifyIdentityToken })
            .then(session => {
                this.applicationInsightsService.setUserId(username, session.user.id);

                this.setCurrentSession(session);

                if (!session.user.isInternal)
                    return this.refreshCurrentPermissions()
                        .then(() => {
                            this.eventAggregator.publish('user.login');
                        });
            });
    }

    async refreshCurrentPermissions() {
        var currentPermissions = await this.roleService.getCurrentPermissions();
        localStorage.setItem('currentPermissions', JSON.stringify(currentPermissions));
    }

    initResetPassword(email) {
        return this.httpClient.post(`password-reset/tokens?email=${email}`);
    }

    resetPassword(token, newPassword, newPasswordConfirm) {
        return this.httpClient.patch(`password-reset/tokens/${token}`, { newPassword, newPasswordConfirm });
    }

    logout(navigateToLogin) {
        if(this.isImpersonating()){
            localStorage.removeItem('query-filter.date-range');
        }
        localStorage.removeItem('currentSession');
        localStorage.removeItem('currentPermissions');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('impersonatedUser');
        localStorage.removeItem('workingUnderOrganization');
        Cookies.remove('impersonatedUserId');
        Cookies.remove('workingUnderOrganizationId');

        this.httpClient
            .delete('sessions/current')
            .then(() => {
                this.eventAggregator.publish('user.logout');

                if (navigateToLogin)
                    this.navigateToLoginScreen();
            })
            .catch(error => {
                this.logger.error('Error logging out.', error);
            });

        this.applicationInsightsService.clearUserId();
    }

    setCurrentSession(session) {
        localStorage.setItem('currentSession', JSON.stringify(session));
    }

    getCurrentSession() {
        return this.parseLocalStorage('currentSession');
    }

    getCurrentSessionUser() {
        return this.parseLocalStorage('currentSession').user;
    }

    getCurrentPermissions() {
        return this.parseLocalStorage('currentPermissions');
    }

    parseLocalStorage(key) {
        var valueString = localStorage.getItem(key);
        if (!valueString)
            return null;

        try {
            return JSON.parse(valueString);
        } catch (error) {
            this.logger.warn(`Failed to parse local storage value for '${key}': ${valueString}`, error, { key, valueString });
            return null;
        }
    }

    isCurrentUserLoggedIn() {
        var session = this.getCurrentSession();
        if (!session)
            return null;

        if (new Date(session.expiration) < new Date()) {
            this.logout();
            return false;
        }

        return true;
    }

    hasPermission(permissionCode) {
        var currentPermissions = this.getCurrentPermissions() || [];
        if (currentPermissions.length === 0)
            return false;

        return !!currentPermissions.find(p => p.code === permissionCode);
    }

    hasApplicationModule(applicationModuleId) {
        var session = this.getCurrentSession();
        if (!session || !session.user || !session.user.applicationModules)
            return false;

        return !!session.user.applicationModules.find(m => m.id === applicationModuleId);
    }

    isCurrentUserInternal() {
        var currentSession = this.getCurrentSession();
        if (!currentSession)
            return false;

        return currentSession.user.isInternal;
    }

    canModifyCustomerData() {
        var currentSession = this.getCurrentSession();
        if (!currentSession)
            return false;

        return currentSession.user.canModifyCustomerData;
    }

    tryAddAuthentication(message) {
        var session = this.getCurrentSession();
        if (session)
            message.headers.add('Authorization', `Bearer ${session.token}`);
    }

    tryAddImpersonation(message) {
        var impersonatedUser = this.getImpersonatedUser();

        if (!impersonatedUser || !impersonatedUser.id)
            return;

        var impersonatedUserId = parseInt(impersonatedUser.id);
        if (!impersonatedUserId)
            return;

        message.headers.add('X-Impersonated-User-Id', impersonatedUserId);
    }

    tryAddWorkingUnderOrganization(message) {
        const workingUnderOrganization = this.getWorkingUnderOrganization();

        if (!workingUnderOrganization || !workingUnderOrganization.id)
            return;

        const impersonatedOrgId = parseInt(workingUnderOrganization.id);
        if (!impersonatedOrgId)
            return;

        message.headers.add('X-Working-Under-Organization-Id', impersonatedOrgId);
    }

    tryUpdateAuthentication(message) {
        if (!message.headers)
            return;

        if (!message.headers.has('x-set-authorization-token'))
            return;

        var token = message.headers.get('x-set-authorization-token');
        var expiration = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
        var session = this.getCurrentSession();
        if (!session)
            return;

        session.token = token;
        session.expiration = expiration;
        this.setCurrentSession(session);
        this.httpClient.put('sessions/current');
    }

    navigateToLoginScreen() {
        this.aurelia.setRoot(PLATFORM.moduleName('security/unauthenticated-app'));
    }

    navigateToApp() {
        this.aurelia.setRoot(PLATFORM.moduleName('app')).then(() => {
            if (!this.redirectAfterLoginFragment)
                return;

            this.router.navigate(this.redirectAfterLoginFragment);
            this.redirectAfterLoginFragment = null;
        });
    }

    navigateToRoot() {
        var loggedIn = this.isCurrentUserLoggedIn();
        if (loggedIn) {
            this.navigateToApp();
            return;
        }

        this.redirectAfterLoginFragment = window.location.hash.substring(1);
        this.navigateToLoginScreen();
    }

    get pendingRedirectAfterlogin() {
        return !!this.redirectAfterLoginFragment;
    }

    getEffectiveUser() {
        if (!!localStorage.getItem('impersonatedUser'))
            return this.getImpersonatedUser();

        const workingUnderOrganization = this.getWorkingUnderOrganization();
        if (workingUnderOrganization) {
            const currentUser = this.getCurrentSessionUser();
            currentUser.organizationId = workingUnderOrganization.id;

            return currentUser;
        }

        return this.getCurrentSessionUser();
    }

    isImpersonating() {
        return !!localStorage.getItem('impersonatedUser');
    }

    isWorkingUnderOrganization() {
        return !!localStorage.getItem('workingUnderOrganization');
    }

    getImpersonatedUser() {
        return this.parseLocalStorage('impersonatedUser');
    }

    getWorkingUnderOrganization() {
        return this.parseLocalStorage('workingUnderOrganization');
    }

    async startImpersonation(user) {
        Cookies.set('impersonatedUserId', user.id);
        localStorage.setItem('impersonatedUser', JSON.stringify(user));
        localStorage.removeItem('query-filter.date-range');
        await this.refreshCurrentPermissions();
        this.eventAggregator.publish('impersonation.start');
    }

    async startWorkingUnderOrganization(org) {
        Cookies.set('workingUnderOrganizationId', org.id);
        localStorage.setItem('workingUnderOrganization', JSON.stringify(org));
        await this.refreshCurrentPermissions();
        this.eventAggregator.publish('impersonation.start');
    }

    stopImpersonation() {
        Cookies.remove('impersonatedUserId');
        Cookies.remove('workingUnderOrganizationId');
        localStorage.removeItem('impersonatedUser');
        localStorage.removeItem('workingUnderOrganization');
        localStorage.removeItem('currentPermissions');
        localStorage.removeItem('query-filter.date-range');
        this.eventAggregator.publish('impersonation.stop');
    }
}
