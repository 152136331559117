import { autoinject, BindingEngine, Disposable } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { FilterOperator } from './filter-operator';

@autoinject
export class TextFilter {

    filterField: any;
    operators: FilterOperator[];

    filterFieldSupportFullTextSearchChangeSubscription: Disposable;

    constructor(private bindingEngine: BindingEngine) {
    }

    get value() {
        return this.filterField && this.filterField.values[0];
    }
    set value(value) {
        if (this.filterField)
            this.filterField.values[0] = value;
    }

    activate(filterField) {
        this.filterField = filterField;
        this.reloadOperators();

        this.filterFieldSupportFullTextSearchChangeSubscription = this.bindingEngine
            .propertyObserver(this.filterField, 'supportsFullTextSearch')
            .subscribe(this.handleFilterFieldSupportFullTextSearchChange.bind(this));

        // When switching field types, the operator may not be available. If not, default to the first one.
        if (!this.operators.some(o => o.value === this.filterField.operator))
            this.filterField.operator = this.operators[0].value;

        ValidationRules
            .ensure('value')
            .required()
            .on(this);
    }

    reloadOperators() {
        this.operators = [
            { title: 'equals', value: 'equals' },
            { title: 'is not equal to', value: 'not-equals' },
            { title: 'starts with', value: 'starts-with' },
            { title: 'contains (partial string)', value: 'like' },
        ];

        if (this.filterField.supportsFullTextSearch) {
            this.operators.push({ title: 'contains (full word)', value: 'contains', tooltip: 'Searches for the specified phrase. The phrase specified must contain full words.' });
        } else {
            // Un-select 'contains' option if newly selected field doesn't support full-text search.
            if (this.filterField.operator === 'contains')
                this.filterField.operator = 'equals';
        }
    }

    handleFilterFieldSupportFullTextSearchChange() {
        this.reloadOperators();
    }

    detached() {
        ValidationRules.off(this);
        this.filterFieldSupportFullTextSearchChangeSubscription?.dispose();
    }
}
