import { autoinject, bindable, containerless } from 'aurelia-framework';

@containerless
@autoinject
export class MapImage {
    @bindable href;

    imageElement: SVGImageElement;

    constructor(private element: Element) {
        this.element = element;
    }

    attached() {
        var image = new Image();
        image.addEventListener('load', () => {
            this.imageElement = document.createElementNS('http://www.w3.org/2000/svg', 'image');
            this.imageElement.setAttributeNS('http://www.w3.org/1999/xlink', 'href', this.href);
            this.imageElement.setAttributeNS(null, 'x', '0');
            this.imageElement.setAttributeNS(null, 'y', '0');
            this.imageElement.setAttributeNS(null, 'width', image.width.toString());
            this.imageElement.setAttributeNS(null, 'height', image.height.toString());
            this.imageElement.setAttributeNS(null, 'data-cy', 'map-image');

            (this.element.parentElement || this.element.parentNode).appendChild(this.imageElement);
        });
        image.src = this.href;
    }
}
