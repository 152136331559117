import { BaseQueryFilter } from '../infrastructure/components/query-filters/base-query-filter';
import {
    DateRangeQuery,
    DateRangeQueryFilter,
} from '../infrastructure/components/query-filters/date-range-query-filter';
import { QueryFiltered } from '../infrastructure/components/query-filters/query-filtered';

export interface PartialTestResultListQuery {
    dateRangeOption?: string;
    targetStartDate: string;
    targetEndDate: string;
    dateRangeOverride?: boolean;
    enableTimeFilter?: boolean;
}

/* eslint-disable brace-style */
export class PartialTestResultsQueryFilters
    extends BaseQueryFilter
    implements QueryFiltered<PartialTestResultListQuery>
{
    private _filterCaption: string;
    dateRangeQueryFilters: DateRangeQueryFilter;

    constructor() {
        super();

        this.dateRangeQueryFilters = new DateRangeQueryFilter();
    }

    public get count(): number {
        return 0;
    }

    public get filterCaption(): string {
        return this._filterCaption;
    }

    reset(): void {
        this.dateRangeQueryFilters.reset();
    }

    getQueryParams(): PartialTestResultListQuery {
        return {
            ...this.dateRangeQueryFilters.getQueryParams(),
        };
    }

    setFilterValues(params: PartialTestResultListQuery): void {
        const dateRangeQuery: DateRangeQuery = {
            dateRangeOverride: params.dateRangeOverride,
            dateRangeOption: params.dateRangeOption,
            targetStartDate: params.targetStartDate,
            targetEndDate: params.targetEndDate,
            enableTimeFilter: params.enableTimeFilter,
        };

        this.dateRangeQueryFilters.setFilterValues(dateRangeQuery);

        this._filterCaption = super.getFilterCaption(
            this.count,
            'Target Date Range',
            this.dateRangeQueryFilters.dateRangeCaption,
        );
    }
}
