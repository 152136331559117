import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';

@autoinject
export default class AdhocReportOptionsService {
    constructor(private httpClient: HttpClient) {
    }

    getAdhocReportOptions() {
        return this.httpClient.get('reporting/adhoc-report-options') as any as Promise<any>;
    }
}
