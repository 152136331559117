import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { buildQueryString } from 'aurelia-path';

@inject(HttpClient)
export default class CustomerTestImportService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getCustomerTestImports({ organizationIds, uploadStartDate, uploadEndDate }) {
        var query = buildQueryString({
            // Map organization IDs to multiple 'organizationId' query string variables.
            organizationId: organizationIds,
            uploadStartDate,
            uploadEndDate
        }, true);

        return this.httpClient.get(`location-testing/customer-test-imports${(query ? `?${query}` : '')}`);
    }

    saveCustomerTestImport(customerTestImport, testDataFile) {
        var formData = new FormData();

        if (testDataFile)
            formData.append('file', testDataFile);

        formData.append('organizationId', customerTestImport.organizationId);

        if (customerTestImport.id)
            throw new Error('Updating an import is not supported.');

        return this
            .httpClient
            .createRequest('location-testing/customer-test-imports')
            .asPost()
            .withContent(formData)
            .send();
    }
}
