import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import Logger from './logger';

@inject(HttpClient, Logger)
export default class StringResourceService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    getStringResources() {
        if (!this.stringResources)
            this.stringResources = this.httpClient.get('string-resources');

        return this.stringResources;
    }
};
