import { DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PLATFORM, autoinject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { CustomError } from 'infrastructure/custom-error';
import ValidationErrorFormatter from 'infrastructure/validation/validation-error-formatter';
import Organization from 'organizations/organization';
import CompareUtility from '../infrastructure/compare-utility';
import DialogPresenter from '../infrastructure/dialogs/dialog-presenter';
import Logger from '../infrastructure/logger';
import PageContext from '../infrastructure/page-context';
import RequestService from '../requests/request-service';
import SecurityService from '../security/security-service';
import TestService from '../tests/test-service';
import UserService from '../users/user-service';
import { submitCustomCertiticateForm } from './custom-coa-submitter';
import { Result } from './result';
import ResultService from './result-service';
import { StatusQuery, StatusQueryFilters } from './status-query-filters';
import { UiCertificateContact } from './ui-certificate-contact';

function allFunction(params) {
    var result = true;
    const { values } = params;
    values.forEach(function (value) {
        result = result && value;
    });
    return result;
}

@autoinject
export default class ResultList {
    @observable filterText;
    @observable selectedOrganizations;
    @observable filterRequestId;
    @observable filterSampleId;
    @observable filterPoNumber;
    @observable filterDescription;

    customCoaForm: HTMLFormElement;
    customKeyValues: any;
    footerNotes: any;

    applyFiltersSubscription: Subscription;
    clearFiltersSubscription: Subscription;

    filterCustomerRequestStatus: any;
    allSelected: boolean;
    anySelected: boolean;
    initiallyLoaded: boolean;

    canViewRequests: boolean;
    canViewResults: boolean;
    canViewCoas: boolean;

    customCoaSamples: any[];
    chartViewOptions: any[];

    selectedChartView: any;

    groupingOptions: any[];
    selectedGroupView: any;

    resultsView: any[];
    mobileResultsView: any[];
    results: Result[];

    donut1Data: any;
    donut2Data: any;
    donut3Data: any;
    donut4Data: any;

    donutTestData1: any;
    donutTestData2: any;
    donutTestData3: any;

    gridOptions: any;
    requestGroupGridOptions: any;
    organizationGroupGridOptions: any;
    labGroupGridOptions: any;

    organizations: Organization[];

    statusQueryFilters: StatusQueryFilters;

    constructor(
        private router: Router,
        private pageContext: PageContext,
        private dialogService: DialogService,
        private dialogPresenter: DialogPresenter,
        private logger: Logger,
        private securityService: SecurityService,
        private resultService: ResultService,
        private userService: UserService,
        private eventAggregator: EventAggregator,
        private requestService: RequestService,
        private testService: TestService,
    ) {
        this.router = router;
        this.pageContext = pageContext;
        this.dialogPresenter = dialogPresenter;
        this.dialogService = dialogService;

        this.logger = logger;
        this.logger.name = 'status';

        this.securityService = securityService;
        this.resultService = resultService;
        this.userService = userService;
        this.requestService = requestService;
        this.testService = testService;
        this.eventAggregator = eventAggregator;

        this.allSelected = false;
        this.filterText = '';
        this.filterRequestId = null;
        this.filterSampleId = null;
        this.filterPoNumber = null;
        this.filterDescription = null;
        this.filterCustomerRequestStatus = null;
        this.selectedOrganizations = [];

        this.canViewRequests = this.securityService.hasPermission('ViewRequests');
        this.canViewResults = this.securityService.hasPermission('ViewResults');
        this.canViewCoas = this.securityService.hasPermission('ViewCoas');

        this.customCoaSamples = [];

        this.chartViewOptions = [];

        if (this.canViewRequests) this.chartViewOptions.push({ id: 0, name: 'Request Statuses' });

        if (this.canViewResults) this.chartViewOptions.push({ id: 1, name: 'Test Results' });

        this.selectedChartView = this.chartViewOptions[0];

        this.groupingOptions = [
            { id: 0, name: 'No Grouping' },
            { id: 1, name: 'Organization' },
            { id: 2, name: 'Lab' },
            { id: 3, name: 'Request' },
        ];
        this.selectedGroupView = this.groupingOptions[3];

        this.resultsView = [];
        this.mobileResultsView = [];

        this.donut1Data = {
            title: 'Submitted',
            value: 0,
            total: 0,
            fillColor: '#807378',
        };
        this.donut2Data = {
            title: 'Received',
            value: 0,
            total: 0,
            fillColor: '#CC2350',
        };
        this.donut3Data = {
            title: 'Processing',
            value: 0,
            total: 0,
            fillColor: '#9FD0CB',
        };
        this.donut4Data = {
            title: 'Completed',
            value: 0,
            total: 0,
            fillColor: '#25D925',
        };

        this.donutTestData1 = {
            title: 'Incomplete',
            value: 0,
            total: 0,
            fillColor: '#79686d',
        };
        this.donutTestData2 = {
            title: 'Pass',
            value: 0,
            total: 0,
            fillColor: '#25d925',
        };
        this.donutTestData3 = {
            title: 'Fail',
            value: 0,
            total: 0,
            fillColor: '#c00',
        };

        const defaultColumnDefinition = {
            suppressMenu: true,
            comparator: CompareUtility.compareStringsInsensitive,
            suppressSizeToFit: true,
            sortable: true,
            resizable: true,
        };

        this.gridOptions = {
            columnTypes: {
                valueFromArrayColumn: {
                    valueGetter: function (params) {
                        return params.data.c[params.column.colId];
                    },
                },
            },

            defaultColDef: defaultColumnDefinition,
            columnDefs: [
                {
                    template:
                        '<label if.bind="data.canAddToCustomCoa && canViewCoas"><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate:
                        '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected" data-cy="status-select-all-checkbox" /></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true,
                },
                {
                    headerName: 'Request ID',
                    field: 'requestId',
                    sort: 'asc',
                    comparator: CompareUtility.stripCompareIntegers,
                    width: 150,
                    template:
                        '${data.requestId}' +
                        '<div class="item-options">' +
                        '    <a click.delegate="setFilterText(data.requestId)" title="View all samples related to request ${data.requestId}"><i class="fa fa-search-plus"></i> Related</a>' +
                        '    <a if.bind="data.isCoaGenerated && canViewCoas" click.delegate="downloadReport(data.requestId)"><i class="fa fa-file-pdf-o"></i> CoA</a>' +
                        '</div>',
                },
                {
                    headerName: 'Requested',
                    field: 'requestDate',
                    width: 135,
                    comparator: CompareUtility.dateCompare,
                },
                {
                    headerName: 'Status',
                    field: 'customerRequestStatus',
                    width: 135,
                },
                {
                    headerName: 'Sample ID',
                    field: 'sampleId',
                    sort: 'asc',
                    width: 150,
                },
                {
                    headerName: 'Organization',
                    field: 'organization',
                    width: 200,
                },
                { headerName: 'Lab', field: 'lab', width: 150 },
                {
                    headerName: 'Collected',
                    field: 'collectionDate',
                    width: 135,
                    comparator: CompareUtility.dateCompare,
                },
                {
                    headerName: 'Received',
                    field: 'receivedDate',
                    width: 135,
                    comparator: CompareUtility.dateCompare,
                },
                {
                    headerName: 'Description',
                    field: 'sampleDescription',
                    width: 450,
                },
                {
                    headerName: 'Composite',
                    field: 'sampleComposite',
                    width: 135,
                },
            ],
        };

        this.requestGroupGridOptions = {
            defaultColDef: defaultColumnDefinition,
            aggFuncs: {
                all: allFunction,
            },
            columnDefs: [
                {
                    template:
                        '<label if.bind="data.canAddToCustomCoa && canViewCoas"><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate:
                        '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected" data-cy="status-select-all-checkbox"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true,
                    pinned: 'left',
                },
                {
                    headerName: 'Request ID',
                    field: 'requestId',
                    width: 150,
                    sort: 'asc',
                    comparator: CompareUtility.stripCompareIntegers,
                    hide: true,
                    rowGroup: true,
                    groupTemplate:
                        'Request: <span class="number">${node.key} </span>' +
                        '    <span class="item-options"><a click.delegate="setFilterText(node.key)" title="View all samples related to request ${node.key}"><i class="fa fa-search-plus"></i> Related</a></span>' +
                        '    <span if.bind="node.aggData.isCoaGenerated && canViewCoas" class="item-options"><a click.delegate="downloadReport(node.key)"><i class="fa fa-file-pdf-o"></i> CoA</a></span> ',
                },
                {
                    headerName: 'Hidden',
                    field: 'isCoaGenerated',
                    hide: true,
                    aggFunc: 'all',
                },
                {
                    headerName: 'Requested',
                    field: 'requestDate',
                    width: 135,
                    comparator: CompareUtility.dateCompare,
                },
                {
                    headerName: 'Status',
                    field: 'customerRequestStatus',
                    width: 135,
                },
                {
                    headerName: 'Sample ID',
                    field: 'sampleId',
                    sort: 'asc',
                    width: 150,
                    comparator: CompareUtility.stripCompareIntegers,
                },
                {
                    headerName: 'Organization',
                    field: 'organization',
                    width: 200,
                },
                { headerName: 'Lab', field: 'lab', width: 150 },
                {
                    headerName: 'Collected',
                    field: 'collectionDate',
                    width: 135,
                    comparator: CompareUtility.dateCompare,
                },
                {
                    headerName: 'Received',
                    field: 'receivedDate',
                    width: 135,
                    comparator: CompareUtility.dateCompare,
                },
                {
                    headerName: 'Description',
                    field: 'sampleDescription',
                    width: 450,
                },
                {
                    headerName: 'Composite',
                    field: 'sampleComposite',
                    width: 135,
                },
            ],
            groupDisplayType: 'groupRows',
            groupDefaultExpanded: -1,
            groupRowRendererParams: {
                suppressCount: true,
                checkbox: false,
                padding: 20,
            },
        };

        this.organizationGroupGridOptions = {
            defaultColDef: defaultColumnDefinition,
            columnDefs: [
                {
                    template:
                        '<label if.bind="data.canAddToCustomCoa && canViewCoas"><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate:
                        '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected" data-cy="status-select-all-checkbox"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true,
                    pinned: 'left',
                },
                {
                    headerName: 'Organization',
                    field: 'organization',
                    sort: 'asc',
                    hide: true,
                    rowGroup: true,
                    groupTemplate: 'Organization: <span class="number">${node.key}</span>',
                },
                {
                    headerName: 'Request ID',
                    field: 'requestId',
                    width: 150,
                    template:
                        '${data.requestId}' +
                        '<div class="item-options">' +
                        '    <a click.delegate="setFilterText(data.requestId)" title="View all samples related to request ${data.requestId}"><i class="fa fa-search-plus"></i> Related</a>' +
                        '    <a if.bind="data.isCoaGenerated && canViewCoas" click.delegate="downloadReport(data.requestId)"><i class="fa fa-file-pdf-o"></i> CoA</a>' +
                        '</div>',
                },
                {
                    headerName: 'Requested',
                    field: 'requestDate',
                    width: 135,
                    comparator: CompareUtility.dateCompare,
                },
                {
                    headerName: 'Status',
                    field: 'customerRequestStatus',
                    width: 135,
                },
                {
                    headerName: 'Sample ID',
                    field: 'sampleId',
                    sort: 'asc',
                    width: 150,
                },
                { headerName: 'Lab', field: 'lab', width: 150 },
                {
                    headerName: 'Collected',
                    field: 'collectionDate',
                    width: 135,
                    comparator: CompareUtility.dateCompare,
                },
                {
                    headerName: 'Received',
                    field: 'receivedDate',
                    width: 135,
                    comparator: CompareUtility.dateCompare,
                },
                {
                    headerName: 'Description',
                    field: 'sampleDescription',
                    width: 450,
                },
                {
                    headerName: 'Composite',
                    field: 'sampleComposite',
                    width: 135,
                },
            ],
            groupDisplayType: 'groupRows',
            groupDefaultExpanded: -1,
            groupRowRendererParams: {
                suppressCount: true,
                checkbox: false,
                padding: 20,
            },
        };

        this.labGroupGridOptions = {
            defaultColDef: defaultColumnDefinition,
            columnDefs: [
                {
                    template:
                        '<label if.bind="data.canAddToCustomCoa && canViewCoas"><input type="checkbox" checked.bind="data.isSelected" change.delegate="isSelectedChanged()"></label>',
                    headerCellTemplate:
                        '<label click.delegate="allSelectedClicked()"><input type="checkbox" checked.bind="allSelected" data-cy="status-select-all-checkbox"></label>',
                    headerClass: 'checkbox',
                    width: 80,
                    sortable: false,
                    suppressSizeToFit: true,
                    pinned: 'left',
                },
                {
                    headerName: 'Lab',
                    field: 'lab',
                    sort: 'asc',
                    hide: true,
                    rowGroup: true,
                    groupTemplate: 'Lab: <span class="number">${node.key}</span>',
                },
                {
                    headerName: 'Request ID',
                    field: 'requestId',
                    width: 150,
                    template:
                        '${data.requestId}' +
                        '<div class="item-options">' +
                        '    <a click.delegate="setFilterText(data.requestId)" title="View all samples related to request ${data.requestId}"><i class="fa fa-search-plus"></i> Related</a>' +
                        '    <a if.bind="data.isCoaGenerated && canViewCoas" click.delegate="downloadReport(data.requestId)"><i class="fa fa-file-pdf-o"></i> CoA</a>' +
                        '</div>',
                },
                {
                    headerName: 'Requested',
                    field: 'requestDate',
                    width: 135,
                    comparator: CompareUtility.dateCompare,
                },
                {
                    headerName: 'Status',
                    field: 'customerRequestStatus',
                    width: 135,
                },
                {
                    headerName: 'Sample ID',
                    field: 'sampleId',
                    sort: 'asc',
                    width: 150,
                },
                {
                    headerName: 'Organization',
                    field: 'organization',
                    width: 200,
                },
                {
                    headerName: 'Collected',
                    field: 'collectionDate',
                    width: 135,
                    comparator: CompareUtility.dateCompare,
                },
                {
                    headerName: 'Received',
                    field: 'receivedDate',
                    width: 135,
                    comparator: CompareUtility.dateCompare,
                },
                {
                    headerName: 'Description',
                    field: 'sampleDescription',
                    width: 450,
                },
                {
                    headerName: 'Composite',
                    field: 'sampleComposite',
                    width: 135,
                },
            ],
            groupDisplayType: 'groupRows',
            groupDefaultExpanded: -1,
            groupRowRendererParams: {
                suppressCount: true,
                checkbox: false,
                padding: 20,
            },
        };

        this.applyFiltersSubscription = this.eventAggregator.subscribe('filters.apply', () =>
            this.handleApplyFilters(),
        );
        this.clearFiltersSubscription = this.eventAggregator.subscribe('filters.clear', () =>
            this.handleClearFilters(),
        );
        this.statusQueryFilters = new StatusQueryFilters();
    }

    isSelectedChanged() {
        this.allSelected = this.resultsView
            .filter((r) => r.canAddToCustomCoa)
            .every((result) => result.isSelected);
        this.anySelected = this.resultsView
            .filter((r) => r.canAddToCustomCoa)
            .some((result) => result.isSelected);
    }

    allSelectedClicked() {
        if (this.resultsView) {
            this.allSelected = !this.resultsView
                .filter((r) => r.canAddToCustomCoa)
                .every((resultsView) => resultsView.isSelected);
            this.anySelected = this.allSelected;
            for (let result of this.resultsView.filter((r) => r.canAddToCustomCoa))
                result.isSelected = this.allSelected;
        }

        return true;
    }

    activate(params: StatusQuery) {
        this.statusQueryFilters.setFilterValues(params);

        (async () => {
            this.pageContext.isLoading = true;
            try {
                this.organizations = await this.userService.getCurrentUserOrganizations();
                this.getResults();

                this.pageContext.isLoading = false;
                this.initiallyLoaded = true;
            } catch (error) {
                this.logger.error('Error loading results.', error);
                this.pageContext.isLoading = false;
                this.dialogPresenter.showAlert(
                    'Error Loading Results',
                    'An error occurred while loading the results. Please try again later.',
                );
            }
        })();
    }

    unbind() {
        this.applyFiltersSubscription && this.applyFiltersSubscription.dispose();
        this.clearFiltersSubscription && this.clearFiltersSubscription.dispose();
    }

    setFilterText(value) {
        this.filterText = value;
    }

    filterTextChanged() {
        this.updateView();
    }

    updateView() {
        if (!this.results) return;

        var lowerCasedFilterText = this.filterText.toLowerCase();

        this.resultsView = this.results.filter(
            (r) =>
                (r.requestId || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (r.sampleId || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (r.sampleDescription || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (r.sampleComposite || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (r.lab || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (r.organization || '').toLowerCase().indexOf(lowerCasedFilterText) > -1 ||
                (r.customerRequestStatus || '').toLowerCase().indexOf(lowerCasedFilterText) > -1,
        );

        var mobileResults = {};

        for (let r of this.resultsView) mobileResults[r.requestId] = r;

        this.mobileResultsView = Object.values(mobileResults);
    }

    updateRequestStatusCountCharts(requestStatusCounts) {
        var getCount = (requestStatus) => {
            var requestStatusCount = requestStatusCounts.find(
                (sc) => sc.requestStatus === requestStatus,
            );
            return requestStatusCount ? requestStatusCount.count : 0;
        };

        var submittedCount = getCount('Submitted');
        var receivedCount = getCount('Received');
        var processingCount = getCount('Processing');
        var completedCount = getCount('Completed');
        var total = submittedCount + receivedCount + processingCount + completedCount;

        this.donut1Data = {
            title: this.donut1Data.title,
            value: submittedCount,
            total,
            fillColor: this.donut1Data.fillColor,
        };
        this.donut2Data = {
            title: this.donut2Data.title,
            value: receivedCount,
            total,
            fillColor: this.donut2Data.fillColor,
        };
        this.donut3Data = {
            title: this.donut3Data.title,
            value: processingCount,
            total,
            fillColor: this.donut3Data.fillColor,
        };
        this.donut4Data = {
            title: this.donut4Data.title,
            value: completedCount,
            total,
            fillColor: this.donut4Data.fillColor,
        };
    }

    updateTestResultCountCharts(testResultCounts) {
        var getCount = (passed) => {
            var testResultCount = testResultCounts.find((tr) => tr.passed === passed);
            return testResultCount ? testResultCount.count : 0;
        };

        var incompleteCount = getCount(null);
        var passCount = getCount(true);
        var failCount = getCount(false);
        var total = incompleteCount + passCount + failCount;

        this.donutTestData1 = {
            title: this.donutTestData1.title,
            value: incompleteCount,
            total,
            fillColor: this.donutTestData1.fillColor,
        };
        this.donutTestData2 = {
            title: this.donutTestData2.title,
            value: passCount,
            total,
            fillColor: this.donutTestData2.fillColor,
        };
        this.donutTestData3 = {
            title: this.donutTestData3.title,
            value: failCount,
            total,
            fillColor: this.donutTestData3.fillColor,
        };
    }

    handleApplyFilters() {
        const query = this.statusQueryFilters.getQueryParams();
        this.router.navigateToRoute('status', this.statusQueryFilters.getQueryParams());
    }

    handleClearFilters() {
        this.statusQueryFilters.reset();
        this.router.navigateToRoute('status', this.statusQueryFilters.getQueryParams());
    }

    async getResults() {
        this.pageContext.isLoading = true;

        try {
            var filters = this.statusQueryFilters.getQueryParams();
            var results = await Promise.all([
                this.resultService.getResults(filters),
                this.canViewRequests
                    ? this.requestService.getRequestStatusCounts(filters)
                    : Promise.resolve([]),
                this.canViewResults
                    ? this.testService.getTestResultCounts(filters)
                    : Promise.resolve([]),
            ]);

            const rawResults = results[0];
            this.results = rawResults.map((r) => {
                return {
                    requestId: r[0],
                    requestDate: r[1],
                    sampleId: r[2],
                    organization: r[3],
                    lab: r[4],
                    collectionDate: r[5],
                    receivedDate: r[6],
                    sampleDescription: r[7],
                    sampleComposite: r[8],
                    isPool: r[9],
                    customerRequestStatus: r[10],
                    isCoaGenerated: r[11],
                    canAddToCustomCoa: r[10] === 'Completed',
                    isSelected: false,
                };
            });

            this.anySelected = false;
            this.updateView();

            if (this.canViewRequests) this.updateRequestStatusCountCharts(results[1]);

            if (this.canViewResults) this.updateTestResultCountCharts(results[2]);
        } catch (error) {
            this.logger.error('Error loading results.', error);
            this.dialogPresenter.showAlert(
                'Error Loading Results',
                error.apiErrorCode === 5
                    ? 'The query could not execute in a timely manner. Please adjust your filter settings in order to return fewer results.'
                    : 'An error occurred while loading the results. Please try again later.',
            );
        }

        this.pageContext.isLoading = false;
    }

    async downloadResults(format: string) {
        this.pageContext.isLoading = true;
        try {
            this.statusQueryFilters.format = format;
            var url = this.resultService.getResultsUrl(this.statusQueryFilters.getQueryParams());
            window.location.href = url;

            this.pageContext.isLoading = false;
            this.pageContext.showSuccessOverlay('Export started successfully.');
        } catch (error) {
            this.logger.error('Error downloading records.', error);
            this.pageContext.isLoading = false;
            this.dialogPresenter.showAlert(
                'Error Downloading Records',
                'An error occurred while downloading the records. Please try again later.',
            );
        }
    }

    async downloadReport(requestId) {
        this.pageContext.isLoading = true;
        try {
            const url = this.resultService.getCertificateUrl(requestId);
            window.location.href = url;

            this.pageContext.isLoading = false;
            this.pageContext.showSuccessOverlay('Certificate download started successfully.');
        } catch (error) {
            this.logger.error('Error downloading certificate.', error);
            this.pageContext.isLoading = false;
            this.dialogPresenter.showAlert(
                'Error Downloading Certificate',
                'An error occurred while downloading the certificate. Please try again later.',
            );
        }
    }

    /* eslint-disable sonarjs/cognitive-complexity */
    async downloadCustomCertificate() {
        try {
            this.pageContext.isLoading = true;

            const sampleIds = this.results
                .filter((r) => r.canAddToCustomCoa && r.isSelected)
                .map((r) => r.sampleId);

            if (sampleIds.length > 2100) {
                throw new CustomError(
                    `The maximum number of samples allowed is 2100.  You selected '${sampleIds.length}'.  Please try again.`,
                    4,
                );
            }
            const certContact = await this.resultService.getCertificateContact(sampleIds);
            const contact = new UiCertificateContact();
            contact.loadViewFrom(certContact);
            this.pageContext.isLoading = false;

            this.dialogService
                .open({
                    viewModel: PLATFORM.moduleName('status/custom-coa-dialog'),
                    model: { contact },
                })
                .whenClosed((result) => {
                    if (result.wasCancelled) return;

                    try {
                        this.customKeyValues = result.output.values;
                        this.footerNotes = result.output.footerNotes;
                        this.customCoaSamples.splice(0);

                        for (let result of this.results)
                            if (result.canAddToCustomCoa && result.isSelected)
                                this.customCoaSamples.push(result);

                        if (this.customCoaSamples.length > 1024) {
                            throw new CustomError(
                                'The maximum allowed selections for a custom COA is 1024',
                                1,
                            );
                        }
                        setTimeout(() => {
                            submitCustomCertiticateForm(this.customCoaForm);
                        }, 0);
                    } catch (error) {
                        const errorMessage =
                            error.errorCode === 1
                                ? error.message
                                : 'An error occurred while downloading the certificate. Please try again later.';
                        this.logger.error('Error downloading certificate.', error);
                        this.dialogPresenter.showAlert(
                            'Error Downloading Certificate',
                            errorMessage,
                        );
                    }
                });
        } catch (error) {
            this.pageContext.isLoading = false;
            const errorMessage = ValidationErrorFormatter.format(
                'An error occurred while downloading the certificate. Please try again later.',
                error,
            );

            this.dialogPresenter.showAlert('Error Downloading Certificate', errorMessage);
        }
    }
}
