import { computedFrom } from 'aurelia-binding';
import { OrganizationConfigurationSetting } from './organization-configuration-settings';
import OrganizationSetting from './organization-setting';

export default class OrganizationDataTypeSetting implements OrganizationSetting {
    name: string;
    displayName: string;
    description: string;
    value: string;
    moduleType: string;
    dataType: string;

    @computedFrom('value')
    get booleanValue(): boolean {
        return this.stringToBoolean(this.value);
    }

    set booleanValue(value: boolean) {
        if (this.dataType === 'Boolean') {
            this.value = value ? 'True' : 'False';
        }
    }

    get usesLocalStorage(): boolean {
        return this.name === OrganizationConfigurationSetting.ENABLE_BETA_FEATURES;
    }

    stringToBoolean(value: string) {
        switch (value) {
            case 'true':
            case 'yes':
            case '1':
            case 'True':
                return true;
            case 'false':
            case 'no':
            case '0':
            case 'False':
            case null:
                return false;
            default:
                return Boolean(value);
        }
    }
}
