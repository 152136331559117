import { FirstDataRenderedEvent } from 'ag-grid-community';

const expandRowAtIndex = (params: FirstDataRenderedEvent, rowIndex: number) => {
    const displayedRowCount = params.api.getDisplayedRowCount();
    if (displayedRowCount === 0) {
        return;
    }
    if (rowIndex > displayedRowCount) {
        rowIndex = 1;
    }

    const expandRowIndex = displayedRowCount === 1 ? 0 : rowIndex;

    setTimeout(() => {
        const levelOneDisplayedRow = params.api.getDisplayedRowAtIndex(expandRowIndex);
        levelOneDisplayedRow.setExpanded(true);
        if (
            levelOneDisplayedRow.childrenAfterGroup?.length > 0 &&
            levelOneDisplayedRow.childrenAfterGroup[0].group
        ) {
            levelOneDisplayedRow.childrenAfterGroup[0].setExpanded(true);
        }
    }, 0);
};

export { expandRowAtIndex };
