import { inject, bindable, bindingMode } from 'aurelia-framework';
import $ from 'jquery';
import 'foundation-datepicker-js';
import { formatDate, parseDate } from './datepicker-patch';

function UTCDate() {
    return new Date(Date.UTC.apply(Date, arguments));
}

let dates = $.fn.fdatepicker.dates;
let DPGlobal = $.fn.fdatepicker.DPGlobal;

// override for inclusion of AM/PM indicator
DPGlobal.validParts = /hh?|ii?|ss?|dd?|mm?|MM?|yy(?:yy)?|a/g;

DPGlobal.parseDate = parseDate;
DPGlobal.formatDate = formatDate;

// Add initialized property in order to lazy load component (since it normally runs the 'update' method four times unnecessarily).
$.fn.fdatepicker.Constructor.prototype.initialized = false;

$.fn.fdatepicker.Constructor.prototype.click = function(e) {
    e.stopPropagation();
    e.preventDefault();

    if ($(e.target).hasClass('datepicker-close') || $(e.target).parent().hasClass('datepicker-close')) {
        this.hide();
    }

    var target = $(e.target).closest('span, td, th');
    if (target.length == 1) {
        if (target.is('.disabled')) {
            this.element.trigger({
                type: 'outOfRange',
                date: this.viewDate,
                startDate: this.startDate,
                endDate: this.endDate
            });
            return;
        }

        switch (target[0].nodeName.toLowerCase()) {
            case 'th':
                switch (target[0].className) {
                    case 'date-switch':
                        this.showMode(1);
                        break;
                    case 'prev':
                    case 'next':
                        var dir = DPGlobal.modes[this.viewMode].navStep * (target[0].className == 'prev' ? -1 : 1);
                        switch (this.viewMode) {
                            case 0:
                                this.viewDate = this.moveHour(this.viewDate, dir);
                                break;
                            case 1:
                                this.viewDate = this.moveDate(this.viewDate, dir);
                                break;
                            case 2:
                                this.viewDate = this.moveMonth(this.viewDate, dir);
                                break;
                            case 3:
                            case 4:
                                this.viewDate = this.moveYear(this.viewDate, dir);
                                break;
                        }
                        this.fill();
                        break;
                    case 'today':
                        var date = new Date();
                        date = UTCDate(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());

                        this.viewMode = this.startViewMode;
                        this.showMode(0);
                        this._setDate(date);
                        break;
                }
                break;

            case 'span':
                if (!target.is('.disabled')) {
                    if (target.is('.month')) {
                        if (this.minView === 3) {
                            var month = target.parent().find('span').index(target) || 0;
                            var year = this.viewDate.getUTCFullYear(),
                                day = 1,
                                hours = this.viewDate.getUTCHours(),
                                minutes = this.viewDate.getUTCMinutes(),
                                seconds = this.viewDate.getUTCSeconds();
                            this._setDate(UTCDate(year, month, day, hours, minutes, seconds, 0));
                        } else {
                            this.viewDate.setUTCDate(1);
                            let month = target.parent().find('span').index(target);
                            this.viewDate.setUTCMonth(month);
                            this.element.trigger({
                                type: 'changeMonth',
                                date: this.viewDate
                            });
                        }
                    } else if (target.is('.year')) {
                        if (this.minView === 4) {
                            let year = parseInt(target.text(), 10) || 0;
                            let month = 0,
                                day = 1,
                                hours = this.viewDate.getUTCHours(),
                                minutes = this.viewDate.getUTCMinutes(),
                                seconds = this.viewDate.getUTCSeconds();
                            this._setDate(UTCDate(year, month, day, hours, minutes, seconds, 0));
                        } else {
                            this.viewDate.setUTCDate(1);
                            let year = parseInt(target.text(), 10) || 0;
                            this.viewDate.setUTCFullYear(year);
                            this.element.trigger({
                                type: 'changeYear',
                                date: this.viewDate
                            });
                        }
                    } else if (target.is('.hour')) {
                        let hours;
                        if(this.nonMilitaryTime){
                            if (target.text().indexOf("AM") >= 0){
                                hours = parseInt(target.text(), 10) || 0;
                                if (hours === 12)
                                    hours = 0;
                            }
                            else{
                                hours = (parseInt(target.text(), 10) + 12 || 0);
                                if (hours === 24)
                                    hours = 12;
                            }
                        }
                        else{
                            hours = parseInt(target.text(), 10) || 0;
                        }
                        let year = this.viewDate.getUTCFullYear(),
                            month = this.viewDate.getUTCMonth(),
                            day = this.viewDate.getUTCDate(),
                            minutes = this.viewDate.getUTCMinutes(),
                            seconds = this.viewDate.getUTCSeconds();
                        this._setDate(UTCDate(year, month, day, hours, minutes, seconds, 0));
                    } else if (target.is('.minute')) {
                        let minutes = parseInt(target.text().substr(target.text().indexOf(':') + 1), 10) || 0;
                        let year = this.viewDate.getUTCFullYear(),
                            month = this.viewDate.getUTCMonth(),
                            day = this.viewDate.getUTCDate(),
                            hours = this.viewDate.getUTCHours(),
                            seconds = this.viewDate.getUTCSeconds();
                        this._setDate(UTCDate(year, month, day, hours, minutes, seconds, 0));
                    }

                    if (this.viewMode != 0) {
                        let oldViewMode = this.viewMode;
                        this.showMode(-1);
                        this.fill();
                        if (oldViewMode == this.viewMode && this.autoclose) {
                            this.hide();
                        }
                    } else {
                        this.fill();
                        if (this.autoclose) {
                            this.hide();
                        }
                    }
                }
                break;
            case 'td':
                if (target.is('.day') && !target.is('.disabled')) {
                    let day = parseInt(target.text(), 10) || 1;
                    let year = this.viewDate.getUTCFullYear(),
                        month = this.viewDate.getUTCMonth(),
                        hours = this.viewDate.getUTCHours(),
                        minutes = this.viewDate.getUTCMinutes(),
                        seconds = this.viewDate.getUTCSeconds();
                    if (target.is('.old')) {
                        if (month === 0) {
                            month = 11;
                            year -= 1;
                        } else {
                            month -= 1;
                        }
                    } else if (target.is('.new')) {
                        if (month == 11) {
                            month = 0;
                            year += 1;
                        } else {
                            month += 1;
                        }
                    }
                    this._setDate(UTCDate(year, month, day, hours, minutes, seconds, 0));
                }

                var prevViewMode = this.viewMode;

                this.showMode(-1);

                this.fill();
                if (prevViewMode == this.viewMode && this.autoclose) {
                    this.hide();
                }
                break;
        }
    }
}

$.fn.fdatepicker.Constructor.prototype.fill = function () {
    // Short circuit if called prior to the constructor running. (That is performed below in the Datepicker.attached method.)
    if (!this.initialized)
        return;

    if (this.date == null || this.viewDate == null) {
        return;
    }

    var d = new Date(this.viewDate.valueOf()),
        year = d.getUTCFullYear(),
        month = d.getUTCMonth(),
        dayMonth = d.getUTCDate(),
        hours = d.getUTCHours(),
        minutes = d.getUTCMinutes(),
        startYear = this.startDate !== -Infinity ? this.startDate.getUTCFullYear() : -Infinity,
        startMonth = this.startDate !== -Infinity ? this.startDate.getUTCMonth() : -Infinity,
        endYear = this.endDate !== Infinity ? this.endDate.getUTCFullYear() : Infinity,
        endMonth = this.endDate !== Infinity ? this.endDate.getUTCMonth() : Infinity,
        currentDate = this.date && UTCDate(this.date.getUTCFullYear(), this.date.getUTCMonth(), this.date.getUTCDate()).valueOf(),
        today = new Date(),
        titleFormat = dates[this.language].titleFormat || dates['en'].titleFormat;
    // this.picker.find('.datepicker-days thead th.date-switch')
    // 			.text(DPGlobal.formatDate(new UTCDate(year, month), titleFormat, this.language));

    this.picker.find('.datepicker-days thead th:eq(1)')
        .text(dates[this.language].months[month] + ' ' + year);
    this.picker.find('.datepicker-hours thead th:eq(1)')
        .text(dayMonth + ' ' + dates[this.language].months[month] + ' ' + year);
    this.picker.find('.datepicker-minutes thead th:eq(1)')
        .text(dayMonth + ' ' + dates[this.language].months[month] + ' ' + year);


    this.picker.find('tfoot th.today')
        .text(dates[this.language].today)
        .toggle(this.todayBtn !== false);
    this.updateNavArrows();
    this.fillMonths();
    var prevMonth = UTCDate(year, month - 1, 28, 0, 0, 0, 0),
        day = DPGlobal.getDaysInMonth(prevMonth.getUTCFullYear(), prevMonth.getUTCMonth());
    prevMonth.setUTCDate(day);
    prevMonth.setUTCDate(day - (prevMonth.getUTCDay() - this.weekStart + 7) % 7);
    var nextMonth = new Date(prevMonth.valueOf());
    nextMonth.setUTCDate(nextMonth.getUTCDate() + 42);
    nextMonth = nextMonth.valueOf();
    var html = [];
    var clsName;

    let startDate = (() => {
        if (this.startDate === -Infinity)
            return -Infinity;

        let date = new Date(this.startDate.getTime() - this.startDate.getTimezoneOffset() * 60000);
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    })();

    let endDate = (() => {
        if (this.endDate === Infinity)
            return Infinity;

        let date = new Date(this.endDate.getTime() - this.endDate.getTimezoneOffset() * 60000);
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    })();

    while (prevMonth.valueOf() < nextMonth) {
        if (prevMonth.getUTCDay() == this.weekStart) {
            html.push('<tr>');
            if (this.calendarWeeks) {
                // adapted from https://github.com/timrwood/moment/blob/master/moment.js#L128
                var a = new Date(prevMonth.getUTCFullYear(), prevMonth.getUTCMonth(), prevMonth.getUTCDate() - prevMonth.getDay() + 10 - (this.weekStart && this.weekStart % 7 < 5 && 7)),
                    b = new Date(a.getFullYear(), 0, 4),
                    calWeek = ~~((a - b) / 864e5 / 7 + 1.5);
                html.push('<td class="cw">' + calWeek + '</td>');
            }
        }
        clsName = ' ' + this.onRender(prevMonth) + ' ';
        if (prevMonth.getUTCFullYear() < year || (prevMonth.getUTCFullYear() == year && prevMonth.getUTCMonth() < month)) {
            clsName += ' old';
        } else if (prevMonth.getUTCFullYear() > year || (prevMonth.getUTCFullYear() == year && prevMonth.getUTCMonth() > month)) {
            clsName += ' new';
        }
        // Compare internal UTC date with local today, not UTC today
        if (this.todayHighlight &&
            prevMonth.getUTCFullYear() == today.getFullYear() &&
            prevMonth.getUTCMonth() == today.getMonth() &&
            prevMonth.getUTCDate() == today.getDate()) {
            clsName += ' today';
        }
        if (currentDate && prevMonth.valueOf() == currentDate) {
            clsName += ' active';
        }

        var currDate = new Date(prevMonth.getUTCFullYear(), prevMonth.getUTCMonth(), prevMonth.getUTCDate());

        if (currDate < startDate || currDate > endDate ||
            //if (prevMonth.valueOf() < this.startDate || prevMonth.valueOf() > this.endDate ||
            $.inArray(prevMonth.getUTCDay(), this.daysOfWeekDisabled) !== -1 ||
            $.inArray(prevMonth.valueOf(), this.datesDisabled) !== -1) {
            clsName += ' disabled';
        }
        html.push('<td class="day' + clsName + '">' + prevMonth.getUTCDate() + '</td>');
        if (prevMonth.getUTCDay() == this.weekEnd) {
            html.push('</tr>');
        }
        prevMonth.setUTCDate(prevMonth.getUTCDate() + 1);
    }
    this.picker.find('.datepicker-days tbody').empty().append(html.join(''));

    html = [];
    for (let i = 0; i < 24; i++) {
        let actual = UTCDate(year, month, dayMonth, i);
        clsName = '';
        // We want the previous hour for the startDate
        if ((actual.valueOf() + 3600000) < this.startDate || actual.valueOf() > this.endDate) {
            clsName += ' disabled';
        } else if (hours == i) {
            clsName += ' active';
        }
        if(this.nonMilitaryTime){
            let tts = (i%12);
            tts = (tts == 0 ? 12 : tts)
            let ampm = "AM"
            if(i>=12 && i<=23){
                ampm = "PM"
            }
            html.push('<span class="hour nonMilitaryTime' + clsName + '">' + tts + ':00 '+ ampm + '</span>');
        }
        else{
            html.push('<span class="hour' + clsName + '">' + i + ':00</span>');
        }
    }
    this.picker.find('.datepicker-hours td').html(html.join(''));

    html = [];
    for (let i = 0; i < 60; i += this.minuteStep) {
        let actual = UTCDate(year, month, dayMonth, hours, i);
        clsName = '';
        if (actual.valueOf() < this.startDate || actual.valueOf() > this.endDate) {
            clsName += ' disabled';
        } else if (Math.floor(minutes / this.minuteStep) == Math.floor(i / this.minuteStep)) {
            clsName += ' active';
        }
        if(this.nonMilitaryTime){
            let tts = (hours%12);
            tts = (tts == 0 ? 12 : tts)
            let ampm = "AM"
            if(hours>=12 && hours<=23){
                ampm = "PM"
            }
            html.push('<span class="minute nonMilitaryTime' + clsName + '">' + tts + ':'+ (i < 10 ? '0' + i : i) +' '+ ampm + '</span>');
        }
        else{
            html.push('<span class="minute' + clsName + '">' + hours + ':' + (i < 10 ? '0' + i : i) + '</span>');
        }
    }
    this.picker.find('.datepicker-minutes td').html(html.join(''));

    var currentYear = this.date && this.date.getUTCFullYear();
    var months = this.picker.find('.datepicker-months')
        .find('th:eq(1)')
        .text(year)
        .end()
        .find('span').removeClass('active');
    if (currentYear && currentYear == year) {
        months.eq(this.date.getUTCMonth()).addClass('active');
    }
    if (year < startYear || year > endYear) {
        months.addClass('disabled');
    }
    if (year == startYear) {
        months.slice(0, startMonth).addClass('disabled');
    }
    if (year == endYear) {
        months.slice(endMonth + 1).addClass('disabled');
    }

    html = '';
    year = parseInt(year / 10, 10) * 10;
    var yearCont = this.picker.find('.datepicker-years')
        .find('th:eq(1)')
        .text(year + '-' + (year + 9))
        .end()
        .find('td');
    year -= 1;
    for (var i = -1; i < 11; i++) {
        html += '<span class="year' + (i == -1 || i == 10 ? ' old' : '') + (currentYear == year ? ' active' : '') + (year < startYear || year > endYear ? ' disabled' : '') + '">' + year + '</span>';
        year += 1;
    }
    yearCont.html(html);
};


var originalShow = $.fn.fdatepicker.Constructor.prototype.show;
$.fn.fdatepicker.Constructor.prototype.show = function (e) {
    if (this.disabled) {
        this.element[0].blur();
        return;
    }

    originalShow.call(this, e);
}

@inject(Element)
export class Datepicker {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value;
    @bindable disabled;
    @bindable pickTime = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) minSelectableDate = -Infinity;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) maxSelectableDate = Infinity;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) inline = false;

    constructor(element) {
        this.element = element;
        this.element.classList.add('input-icon');
    }

    attached() {
        this.isAttached = true;
        this.ensureInitialized();
    }
    
    handleFocus() {
        this.ensureInitialized(true);
    }

    handleBlur() {
        this.updateValue();
    }

    show() {
        this.ensureInitialized(true);
    }

    ensureInitialized(show) {
        if (!this.isAttached || this.$datepicker) {
            if (show)
                this.$datepicker.data('datepicker').show();

            return;
        }

        this.inputElement = this.element.querySelector('input');
        this.$datepicker = $(this.inputElement);
        this.$datepicker
            .fdatepicker({
                forceParse: false,
                format: this.pickTime ? 'mm/dd/yyyy hh:ii a' : 'mm/dd/yyyy',
                pickTime: this.pickTime,
                startDate: this.minSelectableDate,
                endDate: this.maxSelectableDate,
                nonMilitaryTime: true
            })
            .on('changeDate', this.updateValue.bind(this));

        // Get the datepicker component, once it is initialized.
        // Set the initialized property and call the 'update' method to force the call to the 'fill' method to popluate the UI.
        let datepicker = this.$datepicker.data('datepicker');
        datepicker.initialized = true;

        if (this.value)
            this.setDatePickerValue(new Date(this.value));

        datepicker.update();

        if (show)
            datepicker.show();

        this.$datepicker.disabled = !!this.disabled;
    }

    detached() {
        this.$datepicker && this.$datepicker.fdatepicker('remove');
    }

    valueChanged() {
        this.setDatePickerValue(!this.value ? null : new Date(this.value));
    }

    setDatePickerValue(value) {
        let datepicker = this.$datepicker?.data('datepicker');
        if (!datepicker)
            return;

        if (value) {
            datepicker.setDate(value);
        } else {
            // HACK: Since datepicker doesn't support setting an empty value in setDate method.
            datepicker.element.val('');
        }
    }

    // NOTE: This is used publicly to ensure the value is update prior to destruction in the data grid.
    updateValue() {
        let datepicker = this.$datepicker?.data('datepicker');
        if (!datepicker)
            return;

        function checkValid(value) {
            if (!value)
                return false;

            var dateTimeParts = value.split(' ');
            var dateParts = dateTimeParts[0].split('/');
            if (dateParts.length !== 3 || dateParts[2].length !== 4)
                return false;

            return !isNaN(new Date(value).getTime())
        }

        var stringValue = datepicker.element.val();
        this.value = stringValue && checkValid(stringValue) ?
            datepicker.getDate() :
            // HACK: If this.value is undefined, do not force a null value since it would trigger 
            //       a change on value which would in turn trigger validation.
            this.value === undefined ? undefined : null;
    }

    minSelectableDateChanged() {
        if (!this.$datepicker)
            return;
        this.$datepicker.data().datepicker.setStartDate(this.minSelectableDate);
    }

    maxSelectableDateChanged() {
        if (!this.$datepicker)
            return;
        this.$datepicker.data().datepicker.setEndDate(this.maxSelectableDate);
    }
};
