import { ColDef, ColGroupDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { buildQueryString } from 'aurelia-framework';
import SelectorOption from 'infrastructure/selector-option';
import { AgPlanManagementGridActionRenderer } from './ag-plan-management-grid-action-renderer';
import { ActiveTestMethodPlans } from './contracts/active-test-method-plans';

type ReplaceFunction = (data: ActiveTestMethodPlans) => void;

const generatePlanManagementColumnDefs = (
    canEditPlanManagement: boolean,
    replace: ReplaceFunction,
    selectedViewByOption: SelectorOption,
    isImpersonating: boolean,
): (ColDef | ColGroupDef)[] => [
    {
        headerName: 'Organization',
        field: 'organizationName',
    },
    {
        headerName: 'Test Method Name',
        field: 'testMethodName',
        cellRenderer: 'agGroupCellRenderer',
    },

    {
        headerName: 'Bill Code',
        field: 'testMethodBillCode',
    },
    {
        headerName: 'External Id',
        field: 'testMethodExternalId',
        hide: !isImpersonating
    },
    {
        headerName: 'Specifications',
        cellRenderer: (params: ICellRendererParams) => {
            const { node } = params;
            if (node.group) {
                return;
            }

            const { testMethodName } = params.data;
            const url = `#/specifications?${buildQueryString({ testMethodName })}`;
            const title = 'View affected specifications';
            const cypress = 'view-plan-management-specifications-link';
            return `<a href="${url}" target="_blank" title="${title}" data-cy="${cypress}"><i class="fa fa-external-link" /></a>`;
        },
    },
    {
        pinned: 'right',
        suppressMenu: true,
        sortable: false,
        cellRendererParams: {
            canEditPlanManagement,
            replace,
        },
        cellRendererSelector: (params: ICellRendererParams) => {
            const { node } = params;
            const { value } = selectedViewByOption;
            var component = {
                component: AgPlanManagementGridActionRenderer,
            };
            switch (node.uiLevel) {
                case 0:
                    return value === 'testMethodName' ? component : null;
                case 1:
                    return value === 'organizationName' ? component : null;
                default:
                    return null;
            }
        },
    },
];

const detailColumnDefs: ColDef[] = [
    {
        headerName: 'Map',
        cellRenderer: (params: ICellRendererParams) => {
            const { mapId, mapName } = params.data;
            const url = `#/location-testing/maps/${mapId}/dashboard`;
            const title = 'View map dashboard';
            const cypress = 'view-plan-management-map-dashboard-link';
            return `<a href="${url}" target="_blank" title="${title}" data-cy="${cypress}">${mapName}</a>`;
        },
    },
    {
        headerName: 'Plan',
        field: 'planName',
        cellRenderer: (params: ICellRendererParams) => {
            const { planId, planName } = params.data;
            const url = `#/location-testing/plans/${planId}`;
            const title = 'View affected plan';
            const cypress = 'view-plan-management-plan-detail-link';
            return `<a href="${url}" target="_blank" title="${title}" data-cy="${cypress}">${planName}</a>`;
        },
    },
    {
        headerName: 'Unassigned Tasks',
        cellRenderer: (params: ICellRendererParams) => {
            // NOTE: the plan/map custom select list doesn't parse non array map or plan ids
            const { planId } = params.data;
            const url = `#/location-testing/tasks?${buildQueryString(
                {
                    'planIds[]': [planId],
                    recordStatus: 'Active',
                    isAssigned: false,
                    dateRangeOption: 'custom',
                    onlyAssignedToMe: false,
                },
                true,
            )}`;
            var title = 'View affected unassigned tasks';
            var cypress = 'view-plan-management-unassigned-tasks-link';
            return `<a href="${url}" target="_blank" title="${title}" data-cy="${cypress}"><i class="fa fa-external-link" /></a>`;
        },
    },
];

const generatePlanManagementGridOptions = (
    canEditPlanManagement: boolean,
    replace: ReplaceFunction,
    selectedViewByOption: SelectorOption,
    isImpersonating: boolean,
): GridOptions => {
    return {
        columnDefs: generatePlanManagementColumnDefs(
            canEditPlanManagement,
            replace,
            selectedViewByOption,
            isImpersonating,
        ),
        masterDetail: true,
        detailCellRendererParams: {
            detailGridOptions: {
                columnDefs: detailColumnDefs,
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => params.successCallback(params.data.activeTestMethodPlans),
        },
        defaultColDef: { sortable: true, resizable: true, flex: 1 },
        domLayout: 'autoHeight',
        suppressRowClickSelection: true,
        suppressContextMenu: true,
        rowSelection: 'multiple',
    };
};

export { generatePlanManagementColumnDefs, generatePlanManagementGridOptions };
