import { inject } from 'aurelia-framework';

@inject(Element)
export class KeyholdPreventFocusCustomAttribute {
    constructor(element) {
        this.element = element;
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
    }

    attached() {
        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('keyup', this.handleKeyUp);
    }

    detached() {
        document.removeEventListener('keydown', this.handleKeyDown);
        document.removeEventListener('keyup', this.handleKeyUp);
    }

    handleKeyDown(event) {
        if (this.enabled)
            return;

        if (event.shiftKey || event.ctrlKey)
            this.enable();
    }

    handleKeyUp(event) {
        if (this.enabled && !event.shiftKey && !event.ctrlKey)
            this.disable();
    }

    enable() {
        switch (this.element.tagName) {
            case 'INPUT':
                if (document.activeElement === this.element)
                    return;

                this.oldDisabledValue = this.element.disabled;
                this.element.disabled = true;
                break;

            default:
                this.element.au?.controller?.viewModel?.disableFocus?.();
        }

        this.element.classList.add('keyhold-active');

        this.enabled = true;
    }

    disable() {
        switch (this.element.tagName) {
            case 'INPUT':
                this.element.disabled = this.oldDisabledValue;
                break;

            default:
                this.element.au?.controller?.viewModel?.enableFocus?.();
        }

        this.element.classList.remove('keyhold-active');

        this.enabled = false;
    }
}
