import { autoinject, bindable, BindingEngine, computedFrom, Disposable } from 'aurelia-framework';
import { SelectionFilter, isSelectionFilterDataType } from './filters/selection-filter';
import { BooleanFilter } from './filters/boolean-filter';
import { TextFilter } from './filters/text-filter';
import { DateFilter } from './filters/date-filter';
import { NumberFilter } from './filters/number-filter';

@autoinject
export class AdhocFilter {
    @bindable filterField;

    filterFieldDataTypeChangeSubscription: Disposable;

    constructor(private bindingEngine: BindingEngine) {
    }
    
    @computedFrom('filterField.dataType')
    get filterViewModel() {
        if (!this.filterField)
            return null;

        if (isSelectionFilterDataType(this.filterField.dataType))
            return SelectionFilter;

        switch (this.filterField.dataType) {
            case 'Boolean':
                return BooleanFilter;

            case 'Text':
                return TextFilter;

            case 'Date':
                return DateFilter;

            case 'Number':
                return NumberFilter;
        }

        return null;
    }

    handleFilterFieldDataTypeChange() {
        if (!this.filterField)
            return;

        this.filterField.values = [];
    }

    attached() {
        this.filterFieldDataTypeChangeSubscription = this.bindingEngine
            .propertyObserver(this.filterField, 'dataType')
            .subscribe(this.handleFilterFieldDataTypeChange.bind(this));
    }

    detached() {
        this.filterFieldDataTypeChangeSubscription && this.filterFieldDataTypeChangeSubscription.dispose();
    }
}
