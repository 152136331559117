import { AgPromise, IAfterGuiAttachedParams, ICellEditorParams } from 'ag-grid-community';
import { Container, View, ViewCompiler } from 'aurelia-framework';
import { IAureliaCellEditorComp } from './i-aurelia-cell-editor-comp';

export abstract class AureliaCellEditorCompBase implements IAureliaCellEditorComp {
    container: Container;
    viewCompliler: ViewCompiler;
    view: View;
    rootElement: HTMLElement;

    constructor(template: string) {
        this.container = Container.instance;
        this.viewCompliler = this.container.get(ViewCompiler);
        const viewFactory = this.viewCompliler.compile(`<template>${template}</template>`);
        this.view = viewFactory.create(this.container);
        this.rootElement = document.createElement('div');
    }

    init?(params: ICellEditorParams): void | AgPromise<void> {
        this.view.bind(params);
        this.view.appendNodesTo(this.rootElement);
        this.view.attached();
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
        return;
    }

    getValue() {
        throw new Error('Method not implemented.');
    }

    isPopup?(): boolean {
        return true;
    }

    getPopupPosition?(): string {
        return 'over';
    }

    isCancelBeforeStart?(): boolean {
        return false;
    }

    isCancelAfterEnd?(): boolean {
        return false;
    }

    focusIn?(): void {
        return;
    }

    focusOut?(): void {
        return;
    }

    getFrameworkComponentInstance?() {
        return;
    }

    getGui(): HTMLElement {
        return this.rootElement;
    }

    destroy?(): void {
        this.view.unbind();
        this.view.removeNodes();
        this.view.detached();
    }
}
